import React, { useState } from 'react';
import Button from '../Button';

import classnames from 'classnames';

import styles from './styles.module.css';

const ShowMoreText = ({ text, maxLength, rest, pt }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  const slicedText = text != null && text
    .substring(0, maxLength)
    .split(' ')
    .slice(0, -1)
    .join(' ')
    .concat(' ...');

  return (
    <div className={styles.wrapper} {...rest}>
      <p
        className={classnames(styles.text, {
          [styles.isOpen]: isOpen,
        })}
      >
        {/* Configuração original assumia que o sumário estaria eternamente presente. 3hrs depurando */}
      { text = text ?? ''} 
      {isOpen && text.length > maxLength && text}
      {!isOpen && text.length > maxLength && slicedText}
      {text.length <= maxLength && text}
      </p>
      {text.length > maxLength && (
        <Button
          type="secondary"
          small
          className={styles.button}
          onClick={handleClick}
        >
          {isOpen ? pt ? 'Mostrar menos' : 'Show less' : pt ? ' Mostrar mais' : 'Show more'}
        </Button>
      )}
      
    </div>
  );
};

export default ShowMoreText;
