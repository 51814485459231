import React from 'react';

import classnames from 'classnames'

import styles from './styles.module.css';

const Checkbox = ({ label, value, onChange, className }) => {
  return (
    <label className={classnames(styles.ItemWrapper, className)}>
      <input type="checkbox" checked={value} onChange={onChange} />
      {label}
    </label>
  );
};
export default Checkbox;