import React, { useState } from 'react';
import { Button, Collapse } from 'react-bootstrap';
import GroupedBar from '../../../common/graphs/GroupedBar';
import GroupedBar2 from '../../../common/graphs/GroupedBar2';
import LineChart from '../../../common/graphs/LineChart';
import GraphTabs from '../../../common/graphTabs/GraphTabs';

import './Section.css'

const textEnglish = {
	'human': 'Human Capital',
	'demography': 'Demography',
	'source': 'SOURCE',
	'education': 'Education',
	'gross_enroll': 'Gross Enrollment',
	'gross_enroll_desc': 'Total enrollment in primary, secondary, and tertiary education, regardless of age and sex, expressed as a percentage of the population of official primary, secondary, and tertiary education age.',
	'health': 'Health',
	'labor': 'Labor',
	'historical': 'Historical'
}

const textPortuguese = {
	'human': 'Capital Humano',
	'demography': 'Demografia',
	'source': 'FONTE',
	'education': 'Educação',
	'gross_enroll': 'Matrícula Escolar Bruta',
	'gross_enroll_desc': 'Total de matrículas no ensino fundamental, médio e superior, independentemente de idade e sexo, expressa como porcentagem da população em idade oficial para frequentar os ensinos fundamental, médio e superior.',
	'health': 'Saúde',
	'labor': 'Trabalho',
	'historical': 'Histórico'
}

const HumanCapital = (props) => {
	const [isOpen, setIsOpen] = useState(true);
	const [hasLoaded, setHasLoaded] = useState(false);

	const expandify = (sectionId) => {
		props.expandify(sectionId);
		if (!hasLoaded) {
			setHasLoaded(true);
		}
	};

	let pt = false;
	let text = {};
	if (props.location.pathname.substring(0, 4) !== '/pt/') {
		text = textEnglish;
	} else {
		pt = true;
		text = textPortuguese;
	}

	let buttonText = <i className="fa fa-caret-down plus-icon" />;
	if (isOpen) {
		buttonText = <i className="fa fa-caret-right minus-icon" />;
	}
	let visible = props.visibleSections;

	if (isOpen || hasLoaded) {
		return (
			<div className="section-container" style={visible['section-one'] ? {} : {'display':'none'}}>
				<div className="row">
					<div className="col-xs-12 categoryContent benchmark-section-content">
						<Collapse in={isOpen}>
							<div>
								<div id="section-one-demography" style={visible['section-one-demography'] ? {} : {'display':'none'}}>
									<h2 style={{ fontSize: '32px' }}>{text.demography}</h2>

									<h4 style={{ fontSize: '22px', textTransform: 'initial'  }} className="pad-top">{props.wdi_data[props.country_order[0].iso3].indicators['SP.POP.TOTL'][(pt ? 'name_pt' : 'name')].replace(',', '')}</h4>
									<div className="row">
										<div className="col-xs-12">
											<p className="benchmarking-text">{props.wdi_data[props.country_order[0].iso3].indicators['SP.POP.TOTL'][(pt ? 'source_note_pt' : 'source_note')]}</p>
										</div>
										<div className="col-xs-12">
											<GraphTabs {...props} pt={pt} tab1Name={props.wdi_data[props.country_order[0].iso3].indicators['SP.POP.TOTL'].data[props.wdi_data[props.country_order[0].iso3].indicators['SP.POP.TOTL'].data.length-1].year} tab2Name={text.historical}
												tab1Content={
													<div>
														<GroupedBar2 {...props} pt={pt} wdi_data={props.wdi_data} country_order={props.country_order} field="SP.POP.TOTL" values={1} formatter={'0.0a'} />
														<p className="footnote">{text.source}: {props.wdi_data[props.country_order[0].iso3].indicators['SP.POP.TOTL'][(pt ? 'source_pt' : 'source')]}</p>
													</div>
												}
												tab2Content={
													<div>
														<GroupedBar2 {...props} pt={pt} wdi_data={props.wdi_data} country_order={props.country_order} field="SP.POP.TOTL" values={5} formatter={'0.0a'} />
														<p className="footnote">{text.source}: {props.wdi_data[props.country_order[0].iso3].indicators['SP.POP.TOTL'][(pt ? 'source_pt' : 'source')]}</p>
													</div>
												}
											/>
										</div>
									</div>

									<h4 style={{ fontSize: '22px', textTransform: 'initial'  }} className="pad-top">{props.wdi_data[props.country_order[0].iso3].indicators["SP.POP.GROW"][(pt ? 'name_pt' : 'name')]}</h4>
									<div className="row">
										<div className="col-xs-12">
											<p className="benchmarking-text">{props.wdi_data[props.country_order[0].iso3].indicators["SP.POP.GROW"][(pt ? 'source_note_pt' : 'source_note')]}</p>
										</div>
										<div className="col-xs-12">
											<LineChart {...props} pt={pt} wdi_data={props.wdi_data} country_order={props.country_order} field="SP.POP.GROW" values={20} formatter={'0,0.00'} label={'%'} />
											<br />
											<p className="footnote">{text.source}: {props.wdi_data[props.country_order[0].iso3].indicators["SP.POP.GROW"][(pt ? 'source_pt' : 'source')]}</p>
										</div>
									</div>

									<h4 style={{ fontSize: '22px', textTransform: 'initial'  }} className="pad-top">{props.wdi_data[props.country_order[0].iso3].indicators['SP.DYN.LE00.IN'][(pt ? 'name_pt' : 'name')]}</h4>
									<div className="row">
										<div className="col-xs-12">
											<p className="benchmarking-text">{props.wdi_data[props.country_order[0].iso3].indicators['SP.DYN.LE00.IN'][(pt ? 'source_note_pt' : 'source_note')]}</p>
										</div>
										<div className="col-xs-12">
											<GraphTabs {...props} pt={pt} tab1Name={props.wdi_data[props.country_order[0].iso3].indicators['SP.DYN.LE00.IN'].data[props.wdi_data[props.country_order[0].iso3].indicators['SP.DYN.LE00.IN'].data.length-1].year} tab2Name={text.historical}
												tab1Content={
													<div>
														<GroupedBar {...props} pt={pt} wdi_data={props.wdi_data} country_order={props.country_order} field="SP.DYN.LE00.IN" values={1} formatter={'0,0.00'} beginAtZero={false} />
														<p className="footnote">{text.source}: {props.wdi_data[props.country_order[0].iso3].indicators['SP.DYN.LE00.IN'][(pt ? 'source_pt' : 'source')]}</p>
													</div>
												}
												tab2Content={
													<div>
														<GroupedBar2 {...props} pt={pt} wdi_data={props.wdi_data} country_order={props.country_order} field="SP.DYN.LE00.IN" values={5} formatter={'0,0.00'} beginAtZero={false} />
														<p className="footnote">{text.source}: {props.wdi_data[props.country_order[0].iso3].indicators['SP.DYN.LE00.IN'][(pt ? 'source_pt' : 'source')]}</p>
													</div>
												}
											/>
										</div>
									</div>

									<h4 style={{ fontSize: '22px', textTransform: 'initial'  }} className="pad-top">{props.wdi_data[props.country_order[0].iso3].indicators["SP.DYN.TFRT.IN"][(pt ? 'name_pt' : 'name')]}</h4>
									<div className="row">
										<div className="col-xs-12">
											<p className="benchmarking-text">{props.wdi_data[props.country_order[0].iso3].indicators["SP.DYN.TFRT.IN"][(pt ? 'source_note_pt' : 'source_note')]}</p>
										</div>
										<div className="col-xs-12">
											<GraphTabs {...props} pt={pt} tab1Name={props.wdi_data[props.country_order[0].iso3].indicators["SP.DYN.TFRT.IN"].data[props.wdi_data[props.country_order[0].iso3].indicators["SP.DYN.TFRT.IN"].data.length-1].year} tab2Name={text.historical}
												tab1Content={
													<div>
														<GroupedBar {...props} pt={pt} wdi_data={props.wdi_data} country_order={props.country_order} field="SP.DYN.TFRT.IN" values={1} formatter={'0,0.00'} />
														<p className="footnote">{text.source}: {props.wdi_data[props.country_order[0].iso3].indicators["SP.DYN.TFRT.IN"][(pt ? 'source_pt' : 'source')]}</p>
													</div>
												}
												tab2Content={
													<div>
														<GroupedBar2 {...props} pt={pt} wdi_data={props.wdi_data} country_order={props.country_order} field="SP.DYN.TFRT.IN" values={5} formatter={'0,0.00'} />
														<p className="footnote">{text.source}: {props.wdi_data[props.country_order[0].iso3].indicators["SP.DYN.TFRT.IN"][(pt ? 'source_pt' : 'source')]}</p>
													</div>
												}
											/>
										</div>
									</div>
									<hr />
								</div>

								<div id="section-one-subsection-zero" style={visible['section-one-subsection-zero'] ? {} : {'display':'none'}}>
									<h2 style={{ fontSize: '32px' }}>{text.education}</h2>
									<h4 style={{ fontSize: '22px', textTransform: 'initial'  }} className="pad-top">{text.gross_enroll}</h4>
									<p className="benchmarking-text">{text.gross_enroll_desc}</p>
									<h4 style={{ fontSize: '22px', textTransform: 'initial'  }} className="pad-top">{props.wdi_data[props.country_order[0].iso3].indicators["SE.PRM.ENRR"][(pt ? 'name_pt' : 'name')]}</h4>
									<div className="row">
										<div className="col-xs-12">
											<p className="benchmarking-text">{props.wdi_data[props.country_order[0].iso3].indicators["SE.PRM.ENRR"][(pt ? 'source_note_pt' : 'source_note')]}</p>
										</div>
										<div className="col-xs-12">
											<GraphTabs {...props} pt={pt} activeKey="second" tab1Name={props.wdi_data[props.country_order[0].iso3].indicators["SE.PRM.ENRR"].data[props.wdi_data[props.country_order[0].iso3].indicators["SE.PRM.ENRR"].data.length-1].year} tab2Name={text.historical}
												tab1Content={
													<div>
														<GroupedBar2 {...props} pt={pt} wdi_data={props.wdi_data} country_order={props.country_order} field="SE.PRM.ENRR" values={1} formatter={'0,0.00'} label={'%'} />
														<p className="footnote">{text.source}: {props.wdi_data[props.country_order[0].iso3].indicators["SE.PRM.ENRR"][(pt ? 'source_pt' : 'source')]}</p>
													</div>
												}
												tab2Content={
													<div>
														<GroupedBar {...props} pt={pt} wdi_data={props.wdi_data} country_order={props.country_order} field="SE.PRM.ENRR" values={5} formatter={'0,0.00'} label={'%'} />
														<p className="footnote">{text.source}: {props.wdi_data[props.country_order[0].iso3].indicators["SE.PRM.ENRR"][(pt ? 'source_pt' : 'source')]}</p>
													</div>
												}
											/>
										</div>
									</div>
									<h4 style={{ fontSize: '22px', textTransform: 'initial'  }} className="pad-top">{props.wdi_data[props.country_order[0].iso3].indicators["SE.SEC.ENRR"][(pt ? 'name_pt' : 'name')]}</h4>
									<div className="row">
										<div className="col-xs-12">
											<p className="benchmarking-text">{props.wdi_data[props.country_order[0].iso3].indicators["SE.SEC.ENRR"][(pt ? 'source_note_pt' : 'source_note')]}</p>
										</div>
										<div className="col-xs-12">
											<GraphTabs {...props} pt={pt} tab1Name={props.wdi_data[props.country_order[0].iso3].indicators["SE.SEC.ENRR"].data[props.wdi_data[props.country_order[0].iso3].indicators["SE.SEC.ENRR"].data.length-1].year} tab2Name={text.historical}
												tab1Content={
													<div>
														<GroupedBar {...props} pt={pt} wdi_data={props.wdi_data} country_order={props.country_order} field="SE.SEC.ENRR" values={1} formatter={'0,0.00'} label={'%'} />
														<p className="footnote">{text.source}: {props.wdi_data[props.country_order[0].iso3].indicators["SE.SEC.ENRR"][(pt ? 'source_pt' : 'source')]}</p>
													</div>
												}
												tab2Content={
													<div>
														<GroupedBar {...props} pt={pt} wdi_data={props.wdi_data} country_order={props.country_order} field="SE.SEC.ENRR" values={5} formatter={'0,0.00'} label={'%'} />
														<p className="footnote">{text.source}: {props.wdi_data[props.country_order[0].iso3].indicators["SE.SEC.ENRR"][(pt ? 'source_pt' : 'source')]}</p>
													</div>
												}
											/>
										</div>
									</div>
									<h4 style={{ fontSize: '22px', textTransform: 'initial'  }} className="pad-top">{props.wdi_data[props.country_order[0].iso3].indicators["SE.TER.ENRR"][(pt ? 'name_pt' : 'name')]}</h4>
									<div className="row">
										<div className="col-xs-12">
											<p className="benchmarking-text">{props.wdi_data[props.country_order[0].iso3].indicators["SE.TER.ENRR"][(pt ? 'source_note_pt' : 'source_note')]}</p>
										</div>
										<div className="col-xs-12">
											<GraphTabs {...props} pt={pt} tab1Name={props.wdi_data[props.country_order[0].iso3].indicators["SE.TER.ENRR"].data[props.wdi_data[props.country_order[0].iso3].indicators["SE.TER.ENRR"].data.length-1].year} tab2Name={text.historical}
												tab1Content={
													<div>
														<GroupedBar {...props} pt={pt} wdi_data={props.wdi_data} country_order={props.country_order} field="SE.TER.ENRR" values={1} formatter={'0,0.00'} label={'%'} />
														<p className="footnote">{text.source}: {props.wdi_data[props.country_order[0].iso3].indicators["SE.TER.ENRR"][(pt ? 'source_pt' : 'source')]}</p>
													</div>
												}
												tab2Content={
													<div>
														<GroupedBar {...props} pt={pt} wdi_data={props.wdi_data} country_order={props.country_order} field="SE.TER.ENRR" values={5} formatter={'0,0.00'} label={'%'} />
														<p className="footnote">{text.source}: {props.wdi_data[props.country_order[0].iso3].indicators["SE.TER.ENRR"][(pt ? 'source_pt' : 'source')]}</p>
													</div>
												}
											/>
										</div>
									</div>
									<hr />
								</div>
								<div id="section-one-subsection-one" style={visible['section-one-subsection-one'] ? {} : {'display':'none'}}>
									<h2 style={{ fontSize: '32px' }}>{text.health}</h2>
									<h4 style={{ fontSize: '22px', textTransform: 'initial'  }} className="pad-top">{props.wdi_data[props.country_order[0].iso3].indicators["SH.STA.MMRT.NE"][(pt ? 'name_pt' : 'name')]}</h4>
									<div className="row">
										<div className="col-xs-12">
											<p className="benchmarking-text">{props.wdi_data[props.country_order[0].iso3].indicators["SH.STA.MMRT.NE"][(pt ? 'source_note_pt' : 'source_note')]}</p>
										</div>
										<div className="col-xs-12">
											<GraphTabs {...props} pt={pt} activeKey="second" tab1Name={props.wdi_data[props.country_order[0].iso3].indicators["SH.STA.MMRT.NE"].data[props.wdi_data[props.country_order[0].iso3].indicators["SH.STA.MMRT.NE"].data.length-1].year} tab2Name={text.historical}
												tab1Content={
													<div>
														<GroupedBar2 {...props} pt={pt} wdi_data={props.wdi_data} country_order={props.country_order} field="SH.STA.MMRT.NE" values={1} formatter={'0,0.00'} />
														<p className="footnote">{text.source}: {props.wdi_data[props.country_order[0].iso3].indicators["SH.STA.MMRT.NE"][(pt ? 'source_pt' : 'source')]}</p>
													</div>
												}
												tab2Content={
													<div>
														<GroupedBar {...props} pt={pt} wdi_data={props.wdi_data} country_order={props.country_order} field="SH.STA.MMRT.NE" values={5} formatter={'0,0.00'}/>
														<p className="footnote">{text.source}: {props.wdi_data[props.country_order[0].iso3].indicators["SH.STA.MMRT.NE"][(pt ? 'source_pt' : 'source')]}</p>
													</div>
												}
											/>
										</div>
									</div>

									<h4 style={{ fontSize: '22px', textTransform: 'initial'  }} className="pad-top">{props.wdi_data[props.country_order[0].iso3].indicators["SH.XPD.CHEX.GD.ZS"][(pt ? 'name_pt' : 'name')]}</h4>
									<div className="row">
										<div className="col-xs-12">
											<p className="benchmarking-text">{props.wdi_data[props.country_order[0].iso3].indicators["SH.XPD.CHEX.GD.ZS"][(pt ? 'source_note_pt' : 'source_note')]}</p>
										</div>
										<div className="col-xs-12">
											<GraphTabs {...props} pt={pt} tab1Name={props.wdi_data[props.country_order[0].iso3].indicators["SH.XPD.CHEX.GD.ZS"].data[props.wdi_data[props.country_order[0].iso3].indicators["SH.XPD.CHEX.GD.ZS"].data.length-1].year} tab2Name={text.historical}
												tab1Content={
													<div>
														<GroupedBar {...props} pt={pt} wdi_data={props.wdi_data} country_order={props.country_order} field="SH.XPD.CHEX.GD.ZS" values={1} formatter={'0,0.00'} />
														<p className="footnote">{text.source}: {props.wdi_data[props.country_order[0].iso3].indicators["SH.XPD.CHEX.GD.ZS"][(pt ? 'source_pt' : 'source')]}</p>
													</div>
												}
												tab2Content={
													<div>
														<GroupedBar2 {...props} pt={pt} wdi_data={props.wdi_data} country_order={props.country_order} field="SH.XPD.CHEX.GD.ZS" values={5} formatter={'0,0.00'}/>
														<p className="footnote">{text.source}: {props.wdi_data[props.country_order[0].iso3].indicators["SH.XPD.CHEX.GD.ZS"][(pt ? 'source_pt' : 'source')]}</p>
													</div>
												}
											/>
										</div>
									</div>
									<h4 style={{ fontSize: '22px', textTransform: 'initial'  }} className="pad-top">{props.wdi_data[props.country_order[0].iso3].indicators["SH.XPD.OOPC.CH.ZS"][(pt ? 'name_pt' : 'name')]}</h4>
									<div className="row">
										<div className="col-xs-12">
											<p className="benchmarking-text">{props.wdi_data[props.country_order[0].iso3].indicators["SH.XPD.OOPC.CH.ZS"][(pt ? 'source_note_pt' : 'source_note')]}</p>
										</div>
										<div className="col-xs-12">
											<GraphTabs {...props} pt={pt} tab1Name={props.wdi_data[props.country_order[0].iso3].indicators["SH.XPD.OOPC.CH.ZS"].data[props.wdi_data[props.country_order[0].iso3].indicators["SH.XPD.OOPC.CH.ZS"].data.length-1].year} tab2Name={text.historical}
												tab1Content={
													<div>
														<GroupedBar {...props} pt={pt} wdi_data={props.wdi_data} country_order={props.country_order} field="SH.XPD.OOPC.CH.ZS" values={1} formatter={'0,0.00'} />
														<p className="footnote">{text.source}: {props.wdi_data[props.country_order[0].iso3].indicators["SH.XPD.OOPC.CH.ZS"][(pt ? 'source_pt' : 'source')]}</p>
													</div>
												}
												tab2Content={
													<div>
														<GroupedBar2 {...props} pt={pt} wdi_data={props.wdi_data} country_order={props.country_order} field="SH.XPD.OOPC.CH.ZS" values={5} formatter={'0,0.00'}/>
														<p className="footnote">{text.source}: {props.wdi_data[props.country_order[0].iso3].indicators["SH.XPD.OOPC.CH.ZS"][(pt ? 'source_pt' : 'source')]}</p>
													</div>
												}
											/>
										</div>
									</div>
									<hr />
								</div>
								<div id="section-one-subsection-two" style={visible['section-one-subsection-two'] ? {} : {'display':'none'}}>
									<h2 style={{ fontSize: '32px' }}>{text.labor}</h2>
									<h4 style={{ fontSize: '22px', textTransform: 'initial'  }} className="pad-top">{props.wdi_data[props.country_order[0].iso3].indicators["SL.UEM.NEET.ZS"][(pt ? 'name_pt' : 'name')]}</h4>
									<div className="row">
										<div className="col-xs-12">
											<p className="benchmarking-text">{props.wdi_data[props.country_order[0].iso3].indicators["SL.UEM.NEET.ZS"][(pt ? 'source_note_pt' : 'source_note')]}</p>
										</div>
										<div className="col-xs-12">
											<GraphTabs {...props} pt={pt} tab1Name={props.wdi_data[props.country_order[0].iso3].indicators["SL.UEM.NEET.ZS"].data[props.wdi_data[props.country_order[0].iso3].indicators["SL.UEM.NEET.ZS"].data.length-1].year} tab2Name={text.historical}
												tab1Content={
													<div>
														<GroupedBar {...props} pt={pt} wdi_data={props.wdi_data} country_order={props.country_order} field="SL.UEM.NEET.ZS" values={1} formatter={'0,0.00'} label={'%'} />
														<p className="footnote">{text.source}: {props.wdi_data[props.country_order[0].iso3].indicators["SL.UEM.NEET.ZS"][(pt ? 'source_pt' : 'source')]}</p>
													</div>
												}
												tab2Content={
													<div>
														<GroupedBar2 {...props} pt={pt} wdi_data={props.wdi_data} country_order={props.country_order} field="SL.UEM.NEET.ZS" values={5} formatter={'0,0.00'} label={'%'} />
														<p className="footnote">{text.source}: {props.wdi_data[props.country_order[0].iso3].indicators["SL.UEM.NEET.ZS"][(pt ? 'source_pt' : 'source')]}</p>
													</div>
												}
											/>
										</div>
									</div>
									<h4 style={{ fontSize: '22px', textTransform: 'initial'  }} className="pad-top">{props.wdi_data[props.country_order[0].iso3].indicators["SL.UEM.1524.NE.ZS"][(pt ? 'name_pt' : 'name')]}</h4>
									<div className="row">
										<div className="col-xs-12">
											<p className="benchmarking-text">{props.wdi_data[props.country_order[0].iso3].indicators["SL.UEM.1524.NE.ZS"][(pt ? 'source_note_pt' : 'source_note')]}</p>
										</div>
										<div className="col-xs-12">
											<GraphTabs {...props} pt={pt} tab1Name={props.wdi_data[props.country_order[0].iso3].indicators["SL.UEM.1524.NE.ZS"].data[props.wdi_data[props.country_order[0].iso3].indicators["SL.UEM.1524.NE.ZS"].data.length-1].year} tab2Name={text.historical}
												tab1Content={
													<div>
														<GroupedBar {...props} pt={pt} wdi_data={props.wdi_data} country_order={props.country_order} field="SL.UEM.1524.NE.ZS" values={1} formatter={'0,0.00'} />
														<p className="footnote">{text.source}: {props.wdi_data[props.country_order[0].iso3].indicators["SL.UEM.1524.NE.ZS"][(pt ? 'source_pt' : 'source')]}</p>
													</div>
												}
												tab2Content={
													<div>
														<GroupedBar2 {...props} pt={pt} wdi_data={props.wdi_data} country_order={props.country_order} field="SL.UEM.1524.NE.ZS" values={5} formatter={'0,0.00'}/>
														<p className="footnote">{text.source}: {props.wdi_data[props.country_order[0].iso3].indicators["SL.UEM.1524.NE.ZS"][(pt ? 'source_pt' : 'source')]}</p>
													</div>
												}
											/>
										</div>
									</div>
									<h4 style={{ fontSize: '22px', textTransform: 'initial'  }} className="pad-top">{props.wdi_data[props.country_order[0].iso3].indicators["SL.TLF.TOTL.FE.ZS"][(pt ? 'name_pt' : 'name')]}</h4>
									<div className="row">
										<div className="col-xs-12">
											<p className="benchmarking-text">{props.wdi_data[props.country_order[0].iso3].indicators["SL.TLF.TOTL.FE.ZS"][(pt ? 'source_note_pt' : 'source_note')]}</p>
										</div>
										<div className="col-xs-12">
											<GraphTabs {...props} pt={pt} tab1Name={props.wdi_data[props.country_order[0].iso3].indicators["SL.TLF.TOTL.FE.ZS"].data[props.wdi_data[props.country_order[0].iso3].indicators["SL.TLF.TOTL.FE.ZS"].data.length-1].year} tab2Name={text.historical}
												tab1Content={
													<div>
														<GroupedBar {...props} pt={pt} wdi_data={props.wdi_data} country_order={props.country_order} field="SL.TLF.TOTL.FE.ZS" values={1} formatter={'0,0.00'} />
														<p className="footnote">{text.source}: {props.wdi_data[props.country_order[0].iso3].indicators["SL.TLF.TOTL.FE.ZS"][(pt ? 'source_pt' : 'source')]}</p>
													</div>
												}
												tab2Content={
													<div>
														<GroupedBar2 {...props} pt={pt} wdi_data={props.wdi_data} country_order={props.country_order} field="SL.TLF.TOTL.FE.ZS" values={5} formatter={'0,0.00'}/>
														<p className="footnote">{text.source}: {props.wdi_data[props.country_order[0].iso3].indicators["SL.TLF.TOTL.FE.ZS"][(pt ? 'source_pt' : 'source')]}</p>
													</div>
												}
											/>
										</div>
									</div>

									<h4 style={{ fontSize: '22px', textTransform: 'initial'  }} className="pad-top">{props.wdi_data[props.country_order[0].iso3].indicators["SL.TLF.CACT.MA.NE.ZS"][(pt ? 'name_pt' : 'name')]}</h4>
									<div className="row">
										<div className="col-xs-12">
											<p className="benchmarking-text">{props.wdi_data[props.country_order[0].iso3].indicators["SL.TLF.CACT.MA.NE.ZS"][(pt ? 'source_note_pt' : 'source_note')]}</p>
										</div>
										<div className="col-xs-12">
											<GraphTabs {...props} pt={pt} tab1Name={props.wdi_data[props.country_order[0].iso3].indicators["SL.TLF.CACT.MA.NE.ZS"].data[props.wdi_data[props.country_order[0].iso3].indicators["SL.TLF.CACT.MA.NE.ZS"].data.length-1].year} tab2Name={text.historical}
												tab1Content={
													<div>
														<GroupedBar {...props} pt={pt} wdi_data={props.wdi_data} country_order={props.country_order} field="SL.TLF.CACT.MA.NE.ZS" values={1} formatter={'0,0.00'} />
														<p className="footnote">{text.source}: {props.wdi_data[props.country_order[0].iso3].indicators["SL.TLF.CACT.MA.NE.ZS"][(pt ? 'source_pt' : 'source')]}</p>
													</div>
												}
												tab2Content={
													<div>
														<GroupedBar2 {...props} pt={pt} wdi_data={props.wdi_data} country_order={props.country_order} field="SL.TLF.CACT.MA.NE.ZS" values={5} formatter={'0,0.00'}/>
														<p className="footnote">{text.source}: {props.wdi_data[props.country_order[0].iso3].indicators["SL.TLF.CACT.MA.NE.ZS"][(pt ? 'source_pt' : 'source')]}</p>
													</div>
												}
											/>
										</div>
									</div>

									<h4 style={{ fontSize: '22px', textTransform: 'initial'  }} className="pad-top">{props.wdi_data[props.country_order[0].iso3].indicators["SL.TLF.ACTI.1524.NE.ZS"][(pt ? 'name_pt' : 'name')]}</h4>
									<div className="row">
										<div className="col-xs-12">
											<p className="benchmarking-text">{props.wdi_data[props.country_order[0].iso3].indicators["SL.TLF.ACTI.1524.NE.ZS"][(pt ? 'source_note_pt' : 'source_note')]}</p>
										</div>
										<div className="col-xs-12">
											<GraphTabs {...props} pt={pt} tab1Name={props.wdi_data[props.country_order[0].iso3].indicators["SL.TLF.ACTI.1524.NE.ZS"].data[props.wdi_data[props.country_order[0].iso3].indicators["SL.TLF.ACTI.1524.NE.ZS"].data.length-1].year} tab2Name={text.historical}
												tab1Content={
													<div>
														<GroupedBar {...props} pt={pt} wdi_data={props.wdi_data} country_order={props.country_order} field="SL.TLF.ACTI.1524.NE.ZS" values={1} formatter={'0,0.00'} />
														<p className="footnote">{text.source}: {props.wdi_data[props.country_order[0].iso3].indicators["SL.TLF.ACTI.1524.NE.ZS"][(pt ? 'source_pt' : 'source')]}</p>
													</div>
												}
												tab2Content={
													<div>
														<GroupedBar2 {...props} pt={pt} wdi_data={props.wdi_data} country_order={props.country_order} field="SL.TLF.ACTI.1524.NE.ZS" values={5} formatter={'0,0.00'}/>
														<p className="footnote">{text.source}: {props.wdi_data[props.country_order[0].iso3].indicators["SL.TLF.ACTI.1524.NE.ZS"][(pt ? 'source_pt' : 'source')]}</p>
													</div>
												}
											/>
										</div>
									</div>
									<h4 style={{ fontSize: '22px', textTransform: 'initial'  }} className="pad-top">{props.wdi_data[props.country_order[0].iso3].indicators["SL.UEM.TOTL.NE.ZS"][(pt ? 'name_pt' : 'name')]}</h4>
									<div className="row">
										<div className="col-xs-12">
											<p className="benchmarking-text">{props.wdi_data[props.country_order[0].iso3].indicators["SL.UEM.TOTL.NE.ZS"][(pt ? 'source_note_pt' : 'source_note')]}</p>
										</div>
										<div className="col-xs-12" style={{
											background: '#F2F2F2',
											padding: '15px 50px',
											marginBottom: '20px'
										}}>
											<LineChart {...props} pt={pt} wdi_data={props.wdi_data} country_order={props.country_order} field="SL.UEM.TOTL.NE.ZS" values={20} formatter={'0,0.00'} />
											<br />
											<p className="footnote">{text.source}: {props.wdi_data[props.country_order[0].iso3].indicators["SL.UEM.TOTL.NE.ZS"][(pt ? 'source_pt' : 'source')]}</p>
										</div>
									</div>
								</div>
								<div id="section-one-subsection-three" style={visible['section-one-subsection-three'] ? {} : {'display':'none'}}></div>
							</div>
						</Collapse>
					</div>
				</div>
			</div>
		);
	} else {
		return (
			<div className="section-container" style={visible['section-one'] ? {} : { display: 'none' }}>
				<div className="row">
					<div className="col-md-12">
						<hr />
					</div>
					<div id="section-one" className="col-xs-12 categoryHeader" onClick={() => {expandify('section-one')}}>
						<h3 className="title">{text.human}</h3>
						<Button bsClass="expansion">{buttonText}</Button>
					</div>
				</div>
				<div id="section-one-demography"></div>
				<div id="section-one-subsection-zero"></div>
				<div id="section-one-subsection-one"></div>
				<div id="section-one-subsection-two"></div>
				<div id="section-one-subsection-three"></div>
			</div>
		);
	}
};

export default HumanCapital;
