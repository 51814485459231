import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import { Modal } from 'react-bootstrap';
import modalClose from './img/close-plr-modal.svg';
import externalLinkIcon from '../../../../public/img/external-link.svg';
import axios from 'axios';
import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';
import styles from './tableModal.module.css';
import abbreviateFromMillions from '../../common/utilities';
import {API_URL} from "../../../../config/api";


const textEnglish = {
  ifc_approval : 'IFC Approval',
  company_name : 'Company Name',
  ifc_investment_for_guarantee_1 : 'IFC Investment',
  ifc_investment_for_loan_1 : 'IFC Investment',
  ifc_investment_for_equity_1 : 'IFC Investment',
  ifc_investment_for_guarantee_2 : 'for Guarantee',
  ifc_investment_for_loan_2 : 'for Loan',
  ifc_investment_for_equity_2 : 'for Equity',
  total_investment : 'Total Investment',
  link_to_project : 'Link to Project',
};
  
const textPortuguese = {
  ifc_approval : 'Aprovação IFC',
  company_name : 'Nome da Companhia',
  ifc_investment_for_guarantee_1 : 'IFC – Investimento',
  ifc_investment_for_loan_1 : 'IFC – Investimento',
  ifc_investment_for_equity_1 : 'IFC – Investimento',
  ifc_investment_for_guarantee_2 : 'para Garantia',
  ifc_investment_for_loan_2 : 'para Empréstimo',
  ifc_investment_for_equity_2 : 'em Participação',
  total_investment : 'Total de Investimento',
  link_to_project : 'Link para Projeto',
};

const TableModal = (props) => {
  const [ifcProjects, setIfcProjects] = useState([]);
  const [asOfDate, setAsOfDate] = useState(null);
  const [ifcTotals, setIfcTotals] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const ifcResponse = await axios.get(`${API_URL}/ifc`);
      setIfcProjects(ifcResponse.data);
      setAsOfDate(ifcResponse.data[0].as_of_date);

      const totalsResponse = await axios.get(`${API_URL}/ifc_totals`);
      setIfcTotals(totalsResponse.data[0]);
    } catch (error) {
      // Handle error
    }
  };

  const close = () => {
    props.closeTableModal();
  };

  const dateFormat = 'DD/MM/YYYY';
  const text = props.lang ? textPortuguese : textEnglish;

  return (
    <Modal
      show={props.showing}
      onHide={close}
      id="finance-modal"
      className="container"
    >
      <Modal.Body>
        <div id="finance-table-container" className={styles.modalBodyWrapper}>
          <div id="finance-modal-close" onClick={close}>
            <img alt="" width={12} height={12} src={modalClose} />
          </div>
          {props.table_data && (
            <div>
              <div className="row finance-modal-title-section">
                <div className="finance-modal-small-title">
                  {props.table_data.small_title}
                </div>
                <div className="finance-modal-title">
                  {props.tabletitle.replace('null', props.lang ? dayjs(new Date(asOfDate)).locale('pt-br').format('MMMM YYYY') : dayjs(new Date(asOfDate) ).format('MMMM YYYY'))}
                </div>
                <div className="finance-title-line"></div>
              </div>
              <div
                className="row"
                style={{
                  overflow: 'scroll',
                }}
              >
                <table className={styles.tableWrapper}>
                  <thead className={styles.tableHead}>
                    <tr
                      className={classnames(
                        styles.tableRow,
                        styles.headerRow
                      )}
                    >
                      <td className={styles.tableHeadItem}>{text.ifc_approval}</td>
                      <td className={styles.tableHeadItem}>{text.company_name}</td>
                      <td className={styles.tableHeadItem}>{text.ifc_investment_for_guarantee_1} <br /> {text.ifc_investment_for_guarantee_2}</td>
                      <td className={styles.tableHeadItem}>{text.ifc_investment_for_loan_1} <br /> {text.ifc_investment_for_loan_2}</td>
                      <td className={styles.tableHeadItem}>{text.ifc_investment_for_equity_1}<br /> {text.ifc_investment_for_equity_2}</td>
                      <td className={styles.tableHeadItem}>{text.total_investment}</td>
                      <td className={styles.tableHeadItem}>{text.link_to_project}</td>
                    </tr>
                  </thead>
                  <tbody className={styles.tableBody}>
                  {ifcProjects != null && ifcProjects.map((item, index) => {
                      return (
                        <tr className={classnames(styles.tableRow, styles.rowA)}>
                          <td>{dayjs(new Date(item.ifc_approval_date)).format(dateFormat)}</td>
                          <td>{item.company_name}</td>
                          <td>{item.ifc_investment_for_guarantee_million_usd ? 'US$ '+      abbreviateFromMillions(item.ifc_investment_for_guarantee_million_usd, 2, 2, false) : "-"}</td>
                          <td>{item.ifc_investment_for_loan_million_usd ? 'US$ '+     abbreviateFromMillions(item.ifc_investment_for_loan_million_usd, 2, 2, false) : "-"}</td>
                          <td>{item.ifc_investment_for_equity_million_usd ? 'US$ '+     abbreviateFromMillions(item.ifc_investment_for_equity_million_usd, 2, 2, false) : "-"}</td>
                          <td>{item.total_ifc_investment_as_approved_by_board_million_usd ? 'US$ '+     abbreviateFromMillions(item.total_ifc_investment_as_approved_by_board_million_usd, 2, 2, false) : "-"}</td>
                          <td>
                            {externalLinkIcon && (
                              <a href={item.project_url}>
                                <img
                                  alt=""
                                  style={styles.icon}
                                  src={externalLinkIcon}
                                />
                              </a>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                    <tr className={classnames(styles.tableRow, styles.totalRow)}>
                      <td>Total</td>
                      <td></td>
                      <td>{ifcTotals.ifc_investment_for_guarantee_million_usd ? 'US$ '+              abbreviateFromMillions(ifcTotals.ifc_investment_for_guarantee_million_usd, 2, 2, false) : "-"}</td>
                      <td>{ifcTotals.ifc_investment_for_loan_million_usd ? 'US$ '+                   abbreviateFromMillions(ifcTotals.ifc_investment_for_loan_million_usd, 2, 2, false) : "-"}</td>
                      <td>{ifcTotals.ifc_investment_for_equity_million_usd ? 'US$ '+                 abbreviateFromMillions(ifcTotals.ifc_investment_for_equity_million_usd, 2, 2, false) : "-"}</td>
                      <td>{ifcTotals.total_ifc_investment_as_approved_by_board_million_usd ? 'US$ '+ abbreviateFromMillions(ifcTotals.total_ifc_investment_as_approved_by_board_million_usd, 2, 2, false) : "-"}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default TableModal;
