import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import LazyLoad from "react-lazy-load";
import ProgramMap from "../common/map/ProgramMap";
import StrategyHeader from "../common/strategyheader";
import StrategyCover from "../strategy/img/strategy-cover.png";
import WelcomeFooter from "../menu/WelcomeFooter";
import Navbar from "../common/navbar/NavbarPLR";
import "../story/Story.css";

const textEnglish = {
  login: "Login",
  logout: "Log Out",
  med: "Partnership Framework",
};

const textPortuguese = {
  login: "Login",
  logout: "Log Out",
  med: "Estratégia de Parceria",
};

const Story = (props) => {
  const [currentPage, setCurrentPage] = useState(1);

  const scrollEvent = (e) => {
    const target = e.target;

    if (target.scrollTop > 21480) {
      setCurrentPage(15);
    } else if (target.scrollTop > 20850) {
      setCurrentPage(14);
    } else if (target.scrollTop > 20300) {
      setCurrentPage(13);
    } else if (target.scrollTop > 19700) {
      setCurrentPage(12);
    } else if (target.scrollTop > 15600) {
      setCurrentPage(11);
    } else if (target.scrollTop > 12800) {
      setCurrentPage(10);
    } else if (target.scrollTop > 7550) {
      setCurrentPage(9);
    } else if (target.scrollTop > 4580) {
      setCurrentPage(8);
    } else if (target.scrollTop > 3500) {
      setCurrentPage(7);
    } else if (target.scrollTop > 2880) {
      setCurrentPage(6);
    } else if (target.scrollTop > 2330) {
      setCurrentPage(5);
    } else if (target.scrollTop > 1750) {
      setCurrentPage(4);
    } else if (target.scrollTop > 1100) {
      setCurrentPage(3);
    } else if (target.scrollTop > 480) {
      setCurrentPage(2);
    } else {
      setCurrentPage(1);
    }

    console.log('Current scroll position:', target.scrollTop);
  };

  const handleLogout = () => {
    localStorage.removeItem('auth');
    props.history.push('/');
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const pt = props.location.pathname.substring(0, 4) === '/pt/';
  let text = {};
  let url =
      'https://geowb.maps.arcgis.com/apps/MapJournal/index.html?appid=0ee0b02fd66c47e5aad167d09c794024';
  if (props.location.pathname.substring(0, 4) !== '/pt/') {
    text = textEnglish;
  } else {
    text = textPortuguese;
    url =
        'https://geowb.maps.arcgis.com/apps/MapJournal/index.html?appid=c00235dff5fc48269970c6ee72d49092';
  }

  return (
    <div>
        <div className="container-fluid unpadify">
          <Navbar
              handleLogin={handleLogout}
              handleLogout={handleLogout}
              auth={props.auth}
              isLoggedIn={props.loggedIn}
              checkIsLoggedIn={props.checkIsLoggedIn}
              onChangeLoginState={props.onChangeLoginState}
              {...props}
              currentPage="strategy"
          />
          <StrategyHeader
              title={pt ? "Estratégia" : "Strategy"}
              selectedTab={0}
              tabs={[
                {
                  link: pt ? "/pt/story" : "/story",
                  label: pt ? "Parceria" : "Partnership Framework",
                },
                {
                  link: pt ? "/pt/strategy" : "/strategy",
                  label: pt ? "Áreas de foco" : "Focus Areas",
                },
                {
                  link: pt ? "/pt/leveraging-finance" : "/leveraging-finance",
                  label: pt
                      ? "Alavancando o financiamento"
                      : "Leveraging Finance",
                },
                {
                  link: pt ? "/pt/history" : "/history",
                  label: pt ? "Contando a história" : "Telling the Story",
                },
              ]}
              backgroundPosition="0px 69%"
              backgroundImage={StrategyCover}
          />
          <div id="story-container">
            <div className="title-header">
              <h2>{pt ? "Parceria" : "Partnership"}</h2>
              <div className="divider"></div>
            </div>
            <div style={{display: "flex", gap: "50px"}}>
              <div className="left-menu">
                <span className={currentPage === 1 ? "active" : ""}>
                  <a
                      onClick={() =>
                          document
                              .getElementById("1")
                              .scrollIntoView({behavior: "smooth"})
                      }
                  >
                    {pt ? "Brasil Aberto" : "Open Brazil"}
                  </a>
                </span>

                <span className={currentPage === 2 ? "active" : ""}>
                  <a
                      onClick={() =>
                          document
                              .getElementById("2")
                              .scrollIntoView({behavior: "smooth"})
                      }
                  >
                    {pt ? "Cenário econômico" : "Economic backdrop"}
                  </a>
                </span>

                <span className={currentPage === 3 ? "active" : ""}>
                  <a
                      onClick={() =>
                          document
                              .getElementById("3")
                              .scrollIntoView({behavior: "smooth"})
                      }
                  >
                    {pt ? "Cenário social" : "Social backdrop"}
                  </a>
                </span>

                <span className={currentPage === 4 ? "active" : ""}>
                  <a
                      onClick={() =>
                          document
                              .getElementById("4")
                              .scrollIntoView({behavior: "smooth"})
                      }
                  >
                    {pt ? "Cenário ambiental" : "Environmental backdrop"}
                  </a>
                </span>

                <span className={currentPage === 5 ? "active" : ""}>
                  <a
                      onClick={() =>
                          document
                              .getElementById("5")
                              .scrollIntoView({behavior: "smooth"})
                      }
                  >
                    {pt ? "Cenário fiscal" : "Fiscal backdrop"}
                  </a>
                </span>

                <span className={currentPage === 6 ? "active" : ""}>
                  <a
                      onClick={() =>
                          document
                              .getElementById("6")
                              .scrollIntoView({behavior: "smooth"})
                      }
                  >
                    {pt ? "Foco estratégico" : "Strategic focus"}
                  </a>
                </span>

                <span className={currentPage === 7 ? "active" : ""}>
                  <a
                      onClick={() =>
                          document
                              .getElementById("7")
                              .scrollIntoView({behavior: "smooth"})
                      }
                  >
                    Foco nos pobres e nas áreas que necessitam mais
                    {pt
                        ? "Foco nos pobres e nas áreas que necessitam mais desenvolvimento E na sustentabilidade."
                        : "A focus on the poor and areas lagging in human development outcomes and on sustainability"}
                  </a>
                </span>

                <span className={currentPage === 8 ? "active" : ""}>
                  <a
                      onClick={() =>
                          document
                              .getElementById("8")
                              .scrollIntoView({behavior: "smooth"})
                      }
                  >
                    {pt
                        ? "Destaque 1: Um ajuste justo"
                        : "Highlight 1: A just fiscal adjustment"}
                  </a>
                </span>

                <span className={currentPage === 9 ? "active" : ""}>
                  <a
                      onClick={() =>
                          document
                              .getElementById("9")
                              .scrollIntoView({behavior: "smooth"})
                      }
                  >
                    {pt
                        ? "Destaque 2: Infraestrutura e transporte"
                        : "Highlight 2: Infrastructure and transport"}
                  </a>
                </span>

                <span className={currentPage === 10 ? "active" : ""}>
                  <a
                      onClick={() =>
                          document
                              .getElementById("10")
                              .scrollIntoView({behavior: "smooth"})
                      }
                  >
                    {pt
                        ? "Destaque 3: Proteção da Floresta Amazônica…"
                        : "Highlight 3: Protecting the Amazon rainforest"}
                  </a>
                </span>

                <span className={currentPage === 11 ? "active" : ""}>
                  <a
                      onClick={() =>
                          document
                              .getElementById("11")
                              .scrollIntoView({behavior: "smooth"})
                      }
                  >
                    {pt
                        ? "Destaque 4: Inclusão econômica rural"
                        : "Highlight 4: Rural economic inclusion"}
                  </a>
                </span>

                <span className={currentPage === 12 ? "active" : ""}>
                  <a
                      onClick={() =>
                          document
                              .getElementById("12")
                              .scrollIntoView({behavior: "smooth"})
                      }
                  >
                    {pt
                        ? "Destaque 5: Promoção do Desenvolvimento Humano"
                        : "Highlight 5: Promoting Human Development"}
                  </a>
                </span>

                <span className={currentPage === 13 ? "active" : ""}>
                  <a
                      onClick={() =>
                          document
                              .getElementById("13")
                              .scrollIntoView({behavior: "smooth"})
                      }
                  >
                    {pt ? "Apoio à educação" : "Support to Education"}
                  </a>
                </span>

                <span className={currentPage === 14 ? "active" : ""}>
                  <a
                      onClick={() =>
                          document
                              .getElementById("14")
                              .scrollIntoView({behavior: "smooth"})
                      }
                  >
                    {pt
                        ? "Conhecimento como bem público: implementação de projetos"
                        : "Knowledge as a public good: project implementation"}
                  </a>
                </span>

                <span className={currentPage === 15 ? "active" : ""}>
                  <a
                      onClick={() =>
                          document
                              .getElementById("15")
                              .scrollIntoView({behavior: "smooth"})
                      }
                  >
                    {pt
                        ? "Conhecimento como bem público: avaliações de impacto"
                        : "Knowledge as a public good: impact evaluations"}
                  </a>
                </span>
              </div>
              <div className="story">
                <div className="story-gradient"></div>
                <div className="story-text" onScroll={scrollEvent}>
                  <h3 className="story-title">
                    {pt ? "Contando a história" : "Telling the Story"}
                  </h3>
                  {pt ? (
                      <span>
                      Em julho de 2017, o{" "}
                        <a href="https://www.worldbank.org/en/who-we-are">
                        Grupo Banco Mundial
                      </a>{" "}
                        e o governo do Brasil definiram uma{" "}
                        <a href="http://documents.worldbank.org/curated/en/148141498229092629/pdf/20170619-Brazil-CPF-draft-for-Board-with-CLR-Acknowledgement-Box-06202017.pdf">
                        Estratégia de Parceria com o País
                      </a>{" "}
                        que identifica programas específicos para reduzir a
                      pobreza e aumentar a prosperidade compartilhada no Brasil.
                      O Brasil Aberto oferece uma plataforma interativa para{" "}
                        <a href="https://openplr.couragedev.com/pt/portfolio">
                        monitorar os progressos
                      </a>{" "}
                        e este{" "}
                        <a href="https://openplr.couragedev.com/pt/story">
                        histórico
                      </a>{" "}
                        para resumir alguns dos destaques.
                      <br/>
                      <div className="image-container">
                        <img
                            alt=""
                            src="https://www.arcgis.com/sharing/rest/content/items/c00235dff5fc48269970c6ee72d49092/resources/1__1589514649881__w1920.jpg"
                        />
                      </div>
                      <br/>
                    </span>
                  ) : (
                      <span>
                      In July 2017, the{" "}
                        <a href="https://www.worldbank.org/en/who-we-are">
                        World Bank Group
                      </a>{" "}
                        and the government of Brazil agreed a{" "}
                        <a href="http://documents.worldbank.org/curated/en/148141498229092629/pdf/20170619-Brazil-CPF-draft-for-Board-with-CLR-Acknowledgement-Box-06202017.pdf">
                        Country Partnership Framework
                      </a>{" "}
                        identifying specific programs aimed at reducing poverty
                      and boosting shared prosperity in Brazil. Open Brazil
                      provides an interactive platform to{" "}
                        <a href="https://openplr.couragedev.com/pt/portfolio">
                        monitor progress
                      </a>{" "}
                        and this{" "}
                        <a href="https://openplr.couragedev.com/pt/story">
                        story map
                      </a>{" "}
                        summarizes some of the highlights.
                      <br/>
                      <div className="image-container">
                        <img
                            alt=""
                            src="https://www.arcgis.com/sharing/rest/content/items/c00235dff5fc48269970c6ee72d49092/resources/1__1589514649881__w1920.jpg"
                        />
                      </div>
                      <br/>
                    </span>
                  )}

                  <h3 className="historical-engagement-topic" id="2">
                    {pt ? "Cenário econômico" : "Economic backdrop"}
                  </h3>
                  {pt ? (
                      <span>
                      A Estratégia de Parceria para o Brasil foi definida depois
                      de uma das mais graves crises econômicas da história
                      recente do país, que causou uma profunda recessão da qual
                      o Brasil vem se recuperando lentamente.
                      <br/> <br/>
                      {" "}
                      <a href={pt ? "pt/benchmarking" : "/benchmarking"}>
                        Compare o Brasil com outros países
                      </a>{" "}
                        e grupos de países a partir de diversas variáveis
                      socioeconômicas, ambientais e institucionais.
                      <br/>
                      <div className="image-container">
                        <LazyLoad height={550} offset={300}>
                          <iframe
                              className="embedContainer stretch"
                              data-src="https://brasilaberto.worldbank.org/pt/benchmarking/gdp"
                              data-unload="false"
                              allowFullScreen="1"
                              src="https://brasilaberto.worldbank.org/pt/benchmarking/gdp"
                              width="900px"
                              height="550px"
                          ></iframe>
                        </LazyLoad>
                      </div>
                      <br/>
                    </span>
                  ) : (
                      <span>
                      The Country Partnership Framework was agreed on the heels
                      of one of the most severe economic crises in Brazil’s
                      recent history, with a deep recession, from which the
                      country has only been recovering slowly.
                      <br/> <br/>
                      Benchmark Brazil to other countries and country groupings
                      on several socio-economic, environmental, and
                      institutional variables.
                      <br/>
                      <div className="image-container">
                        <LazyLoad height={550} offset={300}>
                          <iframe
                              className="embedContainer stretch"
                              data-src="https://brasilaberto.worldbank.org/pt/benchmarking/gdp"
                              data-unload="false"
                              allowFullScreen="1"
                              src="https://brasilaberto.worldbank.org/pt/benchmarking/gdp"
                              width="900px"
                              height="550px"
                          ></iframe>
                        </LazyLoad>
                      </div>
                      <br/>
                    </span>
                  )}

                  <h3 className="historical-engagement-topic" id="3">
                    {pt ? "Cenário social" : "Social backdrop"}
                  </h3>
                  {pt ? (
                      <span>
                      Entre 2001 e 2013, quase 25 milhões de brasileiros saíram
                      da pobreza graças a:
                      <ul className="list">
                        <li>
                          Uma economia em ascensão, impulsionada pelos altos
                          preços das matérias-primas, que estimulou a rápida
                          criação de empregos, o aumento da informalidade e a
                          redução da desigualdade salarial;
                        </li>
                        <li>
                          Políticas inclusivas, incluindo programas bem
                          estruturados de transferência condicionada de renda,
                          como o Bolsa Família, em que as famílias mais pobres
                          recebem o benefício sob duas condições: seus filhos
                          devem frequentar a escola e visitar o médico
                          regularmente.
                        </li>
                      </ul>
                      A crise e a lenta recuperação do país ameaçam esses
                      avanços. Cerca de 9,3 milhões de brasileiros ainda vivem
                      com menos de US$ 1,90 por dia e 41,7 milhões vivem com
                      menos de US$ 5,50 por dia. O Brasil continua sendo um dos
                      países mais desiguais do mundo, com um índice de Gini de
                      53,9.
                      <br/>
                      <div className="image-container">
                        <LazyLoad height={550} offset={300}>
                          <iframe
                              className="embedContainer stretch"
                              data-src="https://brasilaberto.worldbank.org/pt/benchmarking/gini"
                              data-unload="false"
                              allowFullScreen="1"
                              src="https://brasilaberto.worldbank.org/pt/benchmarking/gini"
                              width="900px"
                              height="550px"
                          ></iframe>
                        </LazyLoad>
                      </div>
                      <br/>
                    </span>
                  ) : (
                      <span>
                      Between 2001 and 2013, nearly 25 million Brazilians
                      escaped poverty thanks to:
                      <ul className="list">
                        <li>
                          A growing economy, driven by high commodity prices,
                          fueled rapid job creation, increased informality, and
                          a reduction in wage inequality
                        </li>
                        <li>
                          Inclusive policies, including well-structured
                          conditional cash transfer programs such as Bolsa
                          Família, in which the poorest families receive a
                          payment under two conditions: their children must
                          attend school and visit the doctor regularly.
                        </li>
                      </ul>
                      The crisis and slow recovery put these gains at risk.
                      About 9.3 million Brazilians still live on less than
                      US$1.90 per day and 41.7 million live on less than US$5.50
                      per day. Brazil remains one of the most unequal countries
                      in the world with a Gini index of 53.9.
                      <br/>
                      <div className="image-container">
                        <LazyLoad height={550} offset={300}>
                          <iframe
                              className="embedContainer stretch"
                              data-src="https://brasilaberto.worldbank.org/pt/benchmarking/gini"
                              data-unload="false"
                              allowFullScreen="1"
                              src="https://brasilaberto.worldbank.org/pt/benchmarking/gini"
                              width="900px"
                              height="550px"
                          ></iframe>
                        </LazyLoad>
                      </div>
                      <br/>
                    </span>
                  )}
                  <h3 className="historical-engagement-topic" id="4">
                    {pt ? "Cenário ambiental" : "Environmental backdrop"}
                  </h3>
                  {pt ? (
                      <span>
                      Enviar foco do teclado para mídia Em meio a uma economia
                      debilitada, os principais avanços do Brasil na
                      reconciliação do desenvolvimento econômico com a
                      sustentabilidade ambiental ficaram em xeque. Os riscos
                      para o clima decorrentes do maior desmatamento de ativos
                      ambientais críticos, como a Amazônia, teriam consequências
                      graves para o Brasil e o mundo.
                      <br/> <br/>
                      <a href={pt ? "/pt/indicators" : "/indicators"}>
                        Acompanhe a evolução histórica da cobertura florestal no
                        Brasil
                      </a>
                      , comparando a situação hoje com anos anteriores, usando o
                      Google Earth Engine.
                      <br/>
                      <div className="image-container">
                        <LazyLoad height={550} offset={300}>
                          <iframe
                              className="embedContainer stretch"
                              data-src="https://brasilaberto.worldbank.org/pt/benchmarking/gdp"
                              data-unload="false"
                              allowFullScreen="1"
                              src="https://brasilaberto.worldbank.org/pt/benchmarking/gdp"
                              width="900px"
                              height="550px"
                          ></iframe>
                        </LazyLoad>
                      </div>
                      <br/>
                    </span>
                  ) : (
                      <span>
                      As the economy faltered, the major advances Brazil had
                      made in reconciling economic development with
                      environmental sustainability have come under threat. The
                      risks to the climate from greater deforestation of
                      critical environmental assets like the Amazon would have
                      severe consequences for Brazil and the world.
                      <br/> <br/>
                      Trace the historical evolution of forest cover in
                      Brazil, comparing today to past years, drawing on Google
                      Earth Engine.
                      <br/>
                      <div className="image-container">
                        <LazyLoad height={550} offset={300}>
                          <iframe
                              className="embedContainer stretch"
                              data-src="https://brasilaberto.worldbank.org/pt/benchmarking/gdp"
                              data-unload="false"
                              allowFullScreen="1"
                              src="https://brasilaberto.worldbank.org/pt/benchmarking/gdp"
                              width="900px"
                              height="550px"
                          ></iframe>
                        </LazyLoad>
                      </div>
                      <br/>
                    </span>
                  )}

                  <h3 className="historical-engagement-topic" id="5">
                    {pt ? "Cenário fiscal" : "Fiscal backdrop"}
                  </h3>
                  {pt ? (
                      <span>
                      Para tratar das questões de desenvolvimento, agravadas
                      pela crise econômica, o Brasil passou a dispor de menos
                      recursos à medida que a economia debilitada levava a uma
                      situação fiscal vulnerável, dificultando ainda mais os
                      esforços para retomar os avanços sociais, econômicos e
                      ambientais. Em 2017, o Brasil perdeu sua classificação de
                      crédito de grau de investimento. Vários estados apresentam
                      uma situação fiscal tão frágil que não conseguem mais as
                      garantias federais necessárias para a tomada de
                      empréstimos.
                      <br/>
                      <div className="image-container">
                        <ProgramMap
                            location={{
                              pathname: "/pt/map",
                              search: "?layers=capag",
                              hash: "",
                            }}
                            layers={"capag"}
                            bounds={[
                              [-82.079089, -35.275418], // [west, south]
                              [-29.959949, 7.413436], // [east, north]
                            ]}
                        />
                      </div>
                      <br/>
                    </span>
                  ) : (
                      <span>
                      To address its development issues, aggravated by the
                      economic crisis, Brazil had fewer resources at its
                      disposal as a weak economy translated into a fragile
                      fiscal situation, making efforts to reinvigorate social,
                      economic, and environmental progress ever more
                      challenging. In 2017, Brazil lost its investment grade
                      credit rating and 17 states are currently in such a poor
                      fiscal situation that they are not eligible for federal
                      guarantees allowing them to borrow.
                      <br/>
                      <div className="image-container">
                        <LazyLoad height={550} offset={300}>
                          <iframe
                              className="embedContainer stretch"
                              data-src="https://brasilaberto.worldbank.org/pt/map?layers=capag"
                              data-unload="false"
                              allowFullScreen="1"
                              src="https://brasilaberto.worldbank.org/pt/map?layers=capag"
                              width="900px"
                              height="550px"
                          ></iframe>
                        </LazyLoad>
                      </div>
                      <br/>
                    </span>
                  )}

                  <h3 className="historical-engagement-topic" id="6">
                    {pt ? "Foco estratégico" : "Strategic focus"}
                  </h3>
                  <span>
                    To support the government of Brazil, the Country Partnership
                    Framework focuses on:{" "}
                    <a href="https://openplr.couragedev.com/pt/strategy">
                      i) fiscal consolidation and government effectiveness; (ii)
                      private sector investment, and productivity growth; and
                      (iii) inclusive and sustainable development.
                    </a>{" "}
                    As of February 2020, the World Bank’s lending portfolio in
                    support of these areas stood at US$ 2.2 billion, while IFC
                    had a portfolio of US$2.9 billion and MIGA of US$250
                    million.
                    <br/> <br/>
                    Interactively explore the WBG’s portfolio under the three
                    strategic focus areas, including information on{" "}
                    <a href="https://openplr.couragedev.com/pt/projects">
                      projects
                    </a>
                    ,{" "}
                    <a href="https://openplr.couragedev.com/pt/reports">
                      relevant research
                    </a>{" "}
                    and{" "}
                    <a href="https://openplr.couragedev.com/pt/indicators">
                      program impacts
                    </a>
                    .
                    <br/>
                    <div className="image-container">
                      <LazyLoad height={550} offset={300}>
                        <iframe
                            className="embedContainer stretch"
                            data-src="https://brasilaberto.worldbank.org/pt/benchmarking/gdp"
                            data-unload="false"
                            allowFullScreen="1"
                            src="https://brasilaberto.worldbank.org/pt/benchmarking/gdp"
                            width="900px"
                            height="550px"
                        ></iframe>
                      </LazyLoad>
                    </div>
                    <br/>
                  </span>

                  <h3 className="historical-engagement-topic" id="7">
                    {pt
                        ? "Foco nos pobres e nas áreas que necessitam mais desenvolvimento E na sustentabilidade."
                        : "A focus on the poor and areas lagging in human development outcomes and on sustainability"}
                  </h3>
                  {pt ? (
                      <span>
                      Os{" "}
                        <a href="https://openplr.couragedev.com/pt/projects">
                        projetos
                      </a>{" "}
                        do Grupo Banco Mundial concentram-se, principalmente, nas
                      áreas com concentrações de pessoas pobres, especialmente
                      na Região Nordeste do país e em grandes cidades como São
                      Paulo e Rio de Janeiro…
                      <br/> <br/>
                      Analise o portfólio do GBM de acordo com os principais
                      indicadores socioeconômicos em nível estadual, incluindo
                      um{" "}
                        <a href="https://www.worldbank.org/en/publication/human-capital">
                        índice de capital humano
                      </a>{" "}
                        subnacional para o Brasil.
                      <br/>
                      <div className="image-container">
                        <ProgramMap
                          location={{
                            pathname: "/pt/map",
                            search: "?layers=capag",
                            hash: "",
                          }}
                          layers={"fa1,percent_poor"}
                          bounds={[
                            [-82.079089, -35.275418], // [west, south]
                            [-29.959949, 7.413436], // [east, north]
                          ]}
                        />
                      </div>
                      <br />
                      O Banco Mundial também vem dando maior foco às áreas onde
                      os desafios de sustentabilidade, como o desmatamento, são
                      mais urgentes.
                      <br />
                      <br />
                      Analise o portfólio do GBM, fazendo uso dos dados mais
                      recentes, quase em tempo real, sobre desmatamento,
                      fornecidos pelo World Resources Institute e pela Global
                      Forest Watch. O Brasil Aberto também é capaz de mapear
                      dados climatológicos e relativos a incêndios e desastres.
                      <br />
                      <div className="image-container">
                        <ProgramMap
                          location={{
                            pathname: "/pt/map",
                            search: "?layers=capag",
                            hash: "",
                          }}
                          layers={"fa1,forma"}
                          bounds={[
                            [62.96, -17.62],
                            [-49.33, -7.14],
                          ]}
                        />
                      </div>
                      <br />
                      <b>
                        Quais foram os impactos de algumas das intervenções em
                        execução até o momento?
                      </b>
                      <br />
                    </span>
                  ) : (
                      <span>
                      The World Bank Group{" "}
                        <a href="https://openplr.couragedev.com/pt/projects">
                        projects
                      </a>{" "}
                        focus particularly on areas where the poor are
                      concentrated and where availability of quality health and
                      education services is a challenge, especially in the North
                      East region of the country and in big cities like São
                      Paulo and Rio de Janeiro…
                      <br/> <br/>
                      Examine the WBG portfolio according to key
                      socio-economic indicators at the state level, including a
                      sub-national{" "}
                        <a href="https://www.worldbank.org/en/publication/human-capital">
                        human capital index
                      </a>{" "}
                        for Brazil.
                      <br/>
                      <div className="image-container">
                        <LazyLoad height={550} offset={300}>
                          <ProgramMap
                              location={{
                                pathname: "/map",
                                search: "?layers=capag",
                                hash: "",
                              }}
                              layers={"fa1,percent_poor"}
                              bounds={[
                                [-82.079089, -35.275418], // [west, south]
                                [-29.959949, 7.413436], // [east, north]
                              ]}
                          />
                        </LazyLoad>
                      </div>
                      <br/>
                      … and on areas where sustainability challenges, such as
                      deforestation, are particularly pronounced.
                      <br/>
                      <br/>
                      Examine the WBG portfolio, drawing on the latest, nearly
                      real-time, data on deforestation, provided by the World
                      Resources Institute and Global Forest watch. Open Brazil
                      can also map fire, climate, and disaster data.
                      <br/>
                      <div className="image-container">
                        <LazyLoad height={550} offset={300}>
                          <ProgramMap
                              location={{
                                pathname: "/map",
                                search: "?layers=capag",
                                hash: "",
                              }}
                              layers={"fa1,forma"}
                              bounds={[
                                [62.96, -17.62],
                                [-49.33, -7.14],
                              ]}
                          />
                        </LazyLoad>
                      </div>
                      <br/>
                      <b>
                        What impact have some of the ongoing interventions had
                        to date?
                      </b>
                      <br/>
                    </span>
                  )}
                  <h3 className="historical-engagement-topic" id="8">
                    {pt
                        ? "Destaque 1: Um ajuste justo"
                        : "Highlight 1: A just fiscal adjustment"}
                  </h3>
                  {pt ? (
                      <span>
                      <b>Defesa das reformas...</b>
                      <br/>
                      <br/>
                      <br/>
                      Por meio de serviços de pesquisa e consultoria, o Banco
                      Mundial apoiou a agenda federal de reforma fiscal do
                      Brasil em áreas que incluem impostos e despesas com
                      impostos,{" "}
                        <a href="https://www.worldbank.org/en/country/brazil/publication/brazil-expenditure-review-report">
                        equidade dos gastos públicos
                      </a>
                      ,{" "}
                        <a href="http://wrld.bg/htk730pH5yv">gastos salariais</a>,{" "}
                        <a href="http://pubdocs.worldbank.org/en/123871536588944330/4-Pension-Reform-ppt-Policy-Note.pdf">
                        previdência
                      </a>
                      , aquisições,{" "}
                        <a href="https://www.worldbank.org/en/country/brazil/brief/brazil-policy-notes#mercadosdecredito123">
                        crédito subsidiado
                      </a>
                      ,{" "}
                        <a href="https://www.worldbank.org/en/country/brazil/brief/brazil-policy-notes#educacao123">
                        educação
                      </a>
                      ,{" "}
                        <a href="https://www.worldbank.org/en/country/brazil/brief/brazil-policy-notes#saude123">
                        saúde
                      </a>{" "}
                        e proteção social. Com esses estudos, o Banco Mundial
                      ajudou a dar forma ao diálogo político sobre a
                      implementação de um ajuste fiscal justo...
                      <br />
                      <br />
                      Monitore em tempo real o{" "}
                      <a href="https://openplr.couragedev.com/pt/insights">
                        impacto das ações de divulgação do GBM
                      </a>{" "}
                        nas conversas online sobre política fiscal e ambiente de
                      negócios.
                      <br/>
                      <div className="image-container">
                        <LazyLoad height={550} offset={300}>
                          <iframe
                              className="embedContainer stretch"
                              data-src="https://brasilaberto.worldbank.org/pt/indicator/1.1"
                              data-unload="false"
                              allowFullScreen="1"
                              src="https://brasilaberto.worldbank.org/pt/indicator/1.1"
                              width="900px"
                              height="550px"
                          ></iframe>
                        </LazyLoad>
                      </div>
                      <br/>
                      <b>... Apoiando o ajuste...</b>
                      <br/>
                      … Ajudando o governo a reduzir os gastos públicos a níveis
                      mais sustentáveis, fundamentais para os avanços
                      socioeconômicos no futuro. Isso também contribui para
                      aumentar a credibilidade e a aderência ao "teto de gastos"
                      adotado pelo governo em dezembro de 2016 para conter o
                      crescimento insustentável das despesas.
                      <br /> <br />
                      Monitore o impacto das reformas apoiadas pelo GBM com
                      dados atualizados com frequência diária a anual,
                      dependendo da natureza do indicador.
                      <br/>
                      <div className="image-container">
                        <LazyLoad height={550} offset={300}>
                          <iframe
                              className="embedContainer stretch"
                              data-src="https://brasilaberto.worldbank.org/pt/indicator/1"
                              data-unload="false"
                              allowFullScreen="1"
                              src="https://brasilaberto.worldbank.org/pt/indicator/1"
                              width="900px"
                              height="550px"
                          ></iframe>
                        </LazyLoad>
                      </div>
                      <br/>
                      <b>... Incluindo a reforma da previdência...</b>
                      <br/>
                      … Uma reforma-chave, objeto de assistência técnica do
                      Banco Mundial, priorizou um sistema previdenciário mais
                      sustentável e equitativo, levando em consideração a maior
                      longevidade dos brasileiros e reduzindo a pressão
                      previdenciária sobre as gerações futuras, que deverão
                      arcar com essas obrigações. Em 2019, após muitos anos de
                      trabalho nessa agenda, finalmente ocorreram reformas
                      expressivas e abrangentes.
                      <br/>
                      <div className="image-container">
                        <LazyLoad height={550} offset={300}>
                          <iframe
                              className="embedContainer stretch"
                              data-src="https://brasilaberto.worldbank.org/pt/indicator/3.2"
                              data-unload="false"
                              allowFullScreen="1"
                              src="https://brasilaberto.worldbank.org/pt/indicator/3.2"
                              width="900px"
                              height="550px"
                          ></iframe>
                        </LazyLoad>
                      </div>
                      <br/>
                      <b>... A reforma administrativa...</b>
                      <br/>
                      Uma assistência técnica do Banco Mundial ajudou a deixar
                      os gastos mais eficientes - gerando mais valor pelo
                      dinheiro investido -, com foco em compras governamentais,
                      introduzindo sistemas para a reduzir o custo relativo dos
                      contratos públicos.
                      <br />
                      <br />
                      Monitore os avanços das reformas a partir das
                      plataformas de dados abertos do governo brasileiro.
                      <br/>
                      <div className="image-container">
                        <LazyLoad height={550} offset={300}>
                          <iframe
                              className="embedContainer stretch"
                              data-src="https://brasilaberto.worldbank.org/pt/indicator/2.1"
                              data-unload="false"
                              allowFullScreen="1"
                              src="https://brasilaberto.worldbank.org/pt/indicator/2.1"
                              width="900px"
                              height="550px"
                          ></iframe>
                        </LazyLoad>
                      </div>
                      <br/>
                      <b>... E reformas subnacionais.</b>
                      <br/>
                      Por meio de operações e consultorias de políticas de
                      desenvolvimento, o Banco Mundial ajudou a criar uma
                      metodologia para avaliar a saúde fiscal dos estados, por
                      meio da metodologia CAPAG, enquanto apoiava o
                      <a href="https://openplr.couragedev.com/projects">
                        Mato Grosso
                      </a>
                      na adoção de reformas para estruturar as finanças públicas
                      do estado no espaço de alguns anos. Há outras ações de
                      apoio dessa natureza planejadas para os estados de Santa
                      Catarina e Amazonas.
                      <br/>
                      <br/>
                      Legenda: No Mato Grosso, um novo projeto do Banco Mundial
                      busca promover a sustentabilidade fiscal e ambiental. Foto
                      de PCI / Divulgação.
                      <br/>
                      <div className="image-container">
                        <img
                            src="https://www.arcgis.com/sharing/rest/content/items/c00235dff5fc48269970c6ee72d49092/resources/Mato%20Grosso%20picture__1589524783243__w800.jpg"
                            alt=""
                        />
                      </div>
                      <br/>
                    </span>
                  ) : (
                      <span>
                      <b>Advocacy for reform...</b>
                      <br/>
                      <br/>
                      <br/>
                      Through research and advisory services, the World Bank
                      supported Brazil’s federal fiscal reform agenda in areas
                      including taxes and tax expenditures,{" "}
                        <a href="https://www.worldbank.org/en/country/brazil/publication/brazil-expenditure-review-report">
                        equity of public spending
                      </a>
                      , <a href="http://wrld.bg/htk730pH5yv">the wage bill</a>,{" "}
                        <a href="http://pubdocs.worldbank.org/en/123871536588944330/4-Pension-Reform-ppt-Policy-Note.pdf">
                        pensions
                      </a>
                      , procurements,{" "}
                        <a href="https://www.worldbank.org/en/country/brazil/brief/brazil-policy-notes#mercadosdecredito123">
                        subsidized credit
                      </a>
                      ,{" "}
                        <a href="https://www.worldbank.org/en/country/brazil/brief/brazil-policy-notes#educacao123">
                        education
                      </a>
                      ,{" "}
                        <a href="https://www.worldbank.org/en/country/brazil/brief/brazil-policy-notes#saude123">
                        health
                      </a>{" "}
                        and social protection. With these studies, the World Bank
                      helped shape the policy dialogue on how to implement a
                      just fiscal adjustment...
                      <br/>
                      <br/>
                      Monitor in real time the{" "}
                        <a href="https://openplr.couragedev.com/pt/insights">
                        impact of WBG advocacy
                      </a>{" "}
                        on the online discourse on fiscal policy and the business
                      environment.
                      <br/>
                      <div className="image-container">
                        <LazyLoad height={550} offset={300}>
                          <iframe
                              className="embedContainer stretch"
                              data-src="https://brasilaberto.worldbank.org/pt/indicator/1.1"
                              data-unload="false"
                              allowFullScreen="1"
                              src="https://brasilaberto.worldbank.org/pt/indicator/1.1"
                              width="900px"
                              height="550px"
                          ></iframe>
                        </LazyLoad>
                      </div>
                      <br/>
                      <b>... Supporting the adjustment...</b>
                      <br/>
                      …Supporting the government in reducing expenditures to
                      more sustainable levels which are critical for future
                      socio-economic progress. This also contributes to
                      strengthening the credibility and adherence to the
                      government’s expenditure ceiling, the “teto de gastos”
                      which it implemented in 2017 to put the brakes on
                      unsustainably growing spending.
                      <br /> <br />
                       Monitor the impact of WBG-supported reforms with data
                      updated at daily to annual frequencies, depending on the
                      nature of the indicator.
                      <br/>
                      <div className="image-container">
                        <LazyLoad height={550} offset={300}>
                          <iframe
                              className="embedContainer stretch"
                              data-src="https://brasilaberto.worldbank.org/pt/indicator/1"
                              data-unload="false"
                              allowFullScreen="1"
                              src="https://brasilaberto.worldbank.org/pt/indicator/1"
                              width="900px"
                              height="550px"
                          ></iframe>
                        </LazyLoad>
                      </div>
                      <br/>
                      <b>... Including pension reform...</b>
                      <br/>
                      … One key reform, supported by World Bank technical
                      assistance, included a more sustainable and equitable
                      pension system that takes into account longer longevity of
                      Brazilians and reduces pressure on pensions obligations
                      covered by future generations. After many years of
                      tackling this agenda, significant, far-ranging reforms
                      were finally achieved in 2019.
                      <br/>
                      <div className="image-container">
                        <LazyLoad height={550} offset={300}>
                          <iframe
                              className="embedContainer stretch"
                              data-src="https://brasilaberto.worldbank.org/pt/indicator/3.2"
                              data-unload="false"
                              allowFullScreen="1"
                              src="https://brasilaberto.worldbank.org/pt/indicator/3.2"
                              width="900px"
                              height="550px"
                          ></iframe>
                        </LazyLoad>
                      </div>
                      <br/>
                      <b>... Administrative reform...</b>
                      <br/>
                      World Bank technical assistance to help improve more
                      efficient spending, i.e. spending that generates more
                      value for money, also focused on government procurement,
                      introducing systems that help reducing the relative cost
                      of public contracts.
                      <br/>
                      <br/>
                      Monitor reform progress drawing on the Brazilian
                      government’s open data platforms.
                      <br/>
                      <div className="image-container">
                        <LazyLoad height={550} offset={300}>
                          <iframe
                              className="embedContainer stretch"
                              data-src="https://brasilaberto.worldbank.org/pt/indicator/2.1"
                              data-unload="false"
                              allowFullScreen="1"
                              src="https://brasilaberto.worldbank.org/pt/indicator/2.1"
                              width="900px"
                              height="550px"
                          ></iframe>
                        </LazyLoad>
                      </div>
                      <br/>
                      <b>... And subnational reform</b>
                      <br/>
                      Through development policy operations and advisory, the
                      World Bank has also helped create a methodology for
                      assessing the fiscal health of states, through the CAPAG
                      methodology, while supporting
                      <a href="https://openplr.couragedev.com/projects">
                        Mato Grosso
                      </a>
                      in adopting reforms that will restore its public finances
                      within a few years. Further such support is planned in the
                      states of Santa Catarina and Amazonas.
                      <br/>
                      <br/>
                      Photo caption: In Mato Grosso, a new World Bank project
                      seeks to promote fiscal and environmental sustainability.
                      Photo by PCI/Handout.
                      <br/>
                      <div className="image-container">
                        <img
                            src="https://www.arcgis.com/sharing/rest/content/items/c00235dff5fc48269970c6ee72d49092/resources/Mato%20Grosso%20picture__1589524783243__w800.jpg"
                            alt=""
                        />
                      </div>
                      <br/>
                    </span>
                  )}

                  <h3 className="historical-engagement-topic" id="9">
                    {pt
                        ? "Destaque 2: Infraestrutura e transporte"
                        : "Highlight 2: Infrastructure and transport"}
                  </h3>
                  {pt ? (
                      <span>
                      Brazil has an ever expanding roads network. Better
                      transport means more opportunities for Brazilians.
                      <br/>
                      <br/>
                      The World Bank Group is working closely with states and
                      municipalities to improve transport infrastructure and
                      urban mobility. Transport infrastructure connects people
                      to jobs, education, and health services; it enables the
                      supply of goods and services; and allows people to
                      interact and generate the knowledge and solutions that
                      foster long-term growth.
                      <br />
                      <br />
                       Interactively explore the context of the WBG’s
                      transport engagement, drawing on Mapillary, capturing road
                      conditions with on-the-ground pictures and videos.
                      <br/>
                      <div className="image-container">
                        <img
                            src="https://www.arcgis.com/sharing/rest/content/items/c00235dff5fc48269970c6ee72d49092/resources/Mapillary__1600115832140__w682.png"
                            alt=""
                        />
                      </div>
                      <br/>
                      <b>Os esforços do GBM para apoiar os transportes...</b>
                      <br/>
                      Enviar foco do teclado para mídia Com o estado de São
                      Paulo, a MIGA e o Banco Mundial apoiaram a recuperação de
                      cerca de 800 quilômetros de estradas, a reconstrução de
                      duas pontes para melhorar a navegabilidade do complexo do
                      corredor fluvial do Tietê e outros trabalhos para aumentar
                      a segurança rodoviária. Ao fornecer garantias financeiras
                      contra a inadimplência das obrigações soberanas no Projeto
                      de Transporte Sustentável do Estado de São Paulo, a MIGA
                      ajudou a mobilizar US$ 361 milhões em financiamento de
                      longo prazo de um banco comercial internacional, sob
                      condições bastante competitivas.
                      <br/> <br/>
                      Legenda: A MIGA e o Banco Mundial apoiaram o Estado de São
                      Paulo na recuperação de cerca de 800 quilômetros de
                      estradas e outras obras para melhorar a segurança
                      rodoviária. Foto do Governo de São Paulo / Divulgação.
                      <br/>
                      <div className="image-container">
                        <img
                            src="https://www.arcgis.com/sharing/rest/content/items/c00235dff5fc48269970c6ee72d49092/resources/Sao%20Paulo%20road%20rehabilitation%20MIGA__1589581301137__w1920.jpg"
                            alt=""
                        />
                      </div>
                      <br/>
                      <b>...Melhorando as estradas...</b>
                      <br/>
                      Até 2022, o GBM deverá apoiar, conjuntamente, a
                      atualização de 6.600km de infraestrutura rodoviária,
                      inclusive por meio de PPPs, como no projeto de São Paulo.
                      <br/>
                      <div className="image-container">
                        <LazyLoad height={550} offset={300}>
                          <iframe
                              className="embedContainer stretch"
                              data-src="https://brasilaberto.worldbank.org/pt/indicator/12"
                              data-unload="false"
                              allowFullScreen="1"
                              src="https://brasilaberto.worldbank.org/pt/indicator/12"
                              width="900px"
                              height="550px"
                          ></iframe>
                        </LazyLoad>
                      </div>
                      <br/>
                      <b>... Apoiando o transporte público em São Paulo...</b>
                      <br/>
                      Enviar foco do teclado para mídia Em São Paulo, além da
                      modernização das estradas, um novo projeto desenvolverá um
                      dos sistemas mais avançados de transporte rápido de ônibus
                      (BRT, Bus-Rapid System) do Brasil, incluindo sinalizações
                      inteligentes e a avaliação da segurança rodoviária por
                      vídeo, dentre outros recursos. O BRT do corredor de
                      Aricanduva atenderá uma das áreas mais vulneráveis da
                      cidade, com aproximadamente 1,2 milhão de moradores. A
                      iniciativa também ajudará a melhorar o centro municipal de
                      controle operacional dos ônibus, que monitora a frota de
                      15.000 veículos, aumentando a pontualidade e a qualidade
                      dos serviços prestados.
                      <br/>
                      <div className="image-container">
                        <img
                            src="https://www.arcgis.com/sharing/rest/content/items/c00235dff5fc48269970c6ee72d49092/resources/17%20-%20BRT%20Aricanduva%20project%20map__1589581394104__w1920.jpg"
                            alt=""
                        />
                      </div>
                      <br/>
                      <b>… E em Belo Horizonte...</b>
                      <br/>
                      Enviar foco do teclado para mídia Em Belo Horizonte, na
                      Região Sudeste, um novo projeto busca desenvolver um
                      corredor de ônibus de alta qualidade ao longo da Avenida
                      Amazonas, promovendo a melhoria urbana de uma comunidade
                      carente e a mobilidade em vários bairros de baixa renda.
                      <br/> <br/>
                      Legenda: Em Belo Horizonte, um novo projeto promoverá
                      melhorias urbanas em uma comunidade carente e na
                      mobilidade da população. Foto: PBH/Divulgação.
                      <br/>
                      <div className="image-container">
                        <img
                            src="https://www.arcgis.com/sharing/rest/content/items/c00235dff5fc48269970c6ee72d49092/resources/18%20-%20Belo%20Horizonte%20-%20PBH%20handout__1589581436414__w766.jpg"
                            alt=""
                        />
                      </div>
                      <br/>
                      <b>… Fortalecendo Parcerias Público-Privadas…</b>
                      <br/>
                      Iniciativas anteriores promoveram não apenas a
                      conectividade urbana, mas também o investimento do setor
                      privado, possibilitando o crescimento produtivo. O Grupo
                      Banco Mundial já apoiou e continua apoiando muitas
                      parcerias público-privadas (PPPs) em diferentes estágios
                      de elaboração e implementação para contornar alguns dos
                      desafios enfrentados pelos investimentos públicos. A
                      previsão é de que a IFC melhorará o acesso a serviços para
                      mais 8,4 milhões de brasileiros entre 2016 e 2022 por meio
                      de PPPs.
                      <br/>
                      <div className="image-container">
                        <LazyLoad height={550} offset={300}>
                          <iframe
                              className="embedContainer stretch"
                              data-src="https://brasilaberto.worldbank.org/pt/indicator/13"
                              data-unload="false"
                              allowFullScreen="1"
                              src="https://brasilaberto.worldbank.org/pt/indicator/13"
                              width="900px"
                              height="550px"
                          ></iframe>
                        </LazyLoad>
                      </div>
                      <br/>
                      <b>... Com financiamento e conhecimento...</b>
                      <br/>
                      Enviar foco do teclado para mídia Em termos de mobilidade
                      urbana, nos últimos 10 anos, projetos de transporte
                      ferroviário de passageiros - como as linhas 4 e 5 de São
                      Paulo e a SuperVia do Rio de Janeiro - têm fornecido
                      financiamento público e assistência técnica para honrar os
                      compromissos públicos das PPPs.
                      <br/>
                      <br/>
                      Legenda: Os novos trens suburbanos do Rio de Janeiro podem
                      atender 70.200 novos passageiros por dia. Foto: Aline
                      Massuca/Supervia/Divulgação.
                      <br/>
                      <div className="image-container">
                        <img
                            src="https://www.arcgis.com/sharing/rest/content/items/c00235dff5fc48269970c6ee72d49092/resources/19%20-%20Rio%20de%20Janeiro%20trains__1589581525459__w1920.jpg"
                            alt=""
                        />
                      </div>
                      <br/>
                      <b>... Apoiando o transporte ecológico.</b>
                      <br/>
                      Com melhor conectividade urbana, as pessoas mais pobres
                      podem ter acesso melhor e mais rápido a empregos, serviços
                      públicos e oportunidades. Todos os projetos de transporte
                      urbano acima são "ecológicos" porque contribuem para
                      reduzir as emissões e melhorar a qualidade do ar. Além
                      disso, essas iniciativas visam uma melhor integração entre
                      as instituições dos governos municipais, estaduais e
                      federal. Dessa forma, poderão inspirar uma nova geração de
                      projetos de infraestrutura nas cidades brasileiras.
                      <br/>
                      <div className="image-container">
                        <LazyLoad height={550} offset={300}>
                          <iframe
                              className="embedContainer stretch"
                              data-src="https://brasilaberto.worldbank.org/pt/indicator/18"
                              data-unload="false"
                              allowFullScreen="1"
                              src="https://brasilaberto.worldbank.org/pt/indicator/18"
                              width="900px"
                              height="550px"
                          ></iframe>
                        </LazyLoad>
                      </div>
                      <br/>
                    </span>
                  ) : (
                      <span>
                      Brazil has an ever expanding roads network. Better
                      transport means more opportunities for Brazilians.
                      <br/>
                      <br/>
                      The World Bank Group is working closely with states and
                      municipalities to improve transport infrastructure and
                      urban mobility. Transport infrastructure connects people
                      to jobs, education, and health services; it enables the
                      supply of goods and services; and allows people to
                      interact and generate the knowledge and solutions that
                      foster long-term growth.
                      <br />
                      <br />
                       Interactively explore the context of the WBG’s
                      transport engagement, drawing on Mapillary, capturing road
                      conditions with on-the-ground pictures and videos.
                      <br/>
                      <div className="image-container">
                        <img
                            src="https://www.arcgis.com/sharing/rest/content/items/c00235dff5fc48269970c6ee72d49092/resources/Mapillary__1600115832140__w682.png"
                            alt=""
                        />
                      </div>
                      <br/>
                      <b>WBG efforts to support transport...</b>
                      <br/>
                      MIGA and the World Bank jointly supported the State of Sao
                      Paulo in the rehabilitation of about 800 kilometers of
                      roads, reconstruction of two bridges to enhance the
                      navigability of the Tiete inland waterway corridor
                      complex, and other works to improve road safety. By
                      providing non-honoring of sub-sovereign financial
                      obligations guarantee for the Sao Paulo State Sustainable
                      Transport Project, MIGA helped mobilize US$361 million of
                      long-term financing from an international commercial bank
                      on competitive terms.
                      <br/> <br/>
                      Photo caption: MIGA and the World Bank supported the State
                      of Sao Paulo in the rehabilitation of about 800 kilometers
                      of roads and other works to improve road safety. Photo by
                      Governo de Sao Paulo/Handout.
                      <br/>
                      <div className="image-container">
                        <img
                            src="https://www.arcgis.com/sharing/rest/content/items/c00235dff5fc48269970c6ee72d49092/resources/Sao%20Paulo%20road%20rehabilitation%20MIGA__1589581301137__w1920.jpg"
                            alt=""
                        />
                      </div>
                      <br/>
                      <b>… Upgrading roads…</b>
                      <br/>
                      By 2022, the WBG is jointly expected to support the
                      upgrading of 6,600km of road infrastructure, including
                      through PPPs like in the Sao Paulo project.
                      <br/>
                      <div className="image-container">
                        <LazyLoad height={550} offset={300}>
                          <iframe
                              className="embedContainer stretch"
                              data-src="https://brasilaberto.worldbank.org/pt/indicator/12"
                              data-unload="false"
                              allowFullScreen="1"
                              src="https://brasilaberto.worldbank.org/pt/indicator/12"
                              width="900px"
                              height="550px"
                          ></iframe>
                        </LazyLoad>
                      </div>
                      <br/>
                      <b>… Supporting public transport in São Paulo…</b>
                      <br/>
                      In Sao Paulo, beyond the upgrading of roads, a new project
                      will also develop one of the most advanced Bus-Rapid
                      Transport (BRT) systems in Brazil, including smart signals
                      and road safety assessment using video, among other
                      features. The Aricanduva Corridor BRT will cover one of
                      the most vulnerable city areas, which is home to
                      approximately 1,2 million people. The initiative will also
                      help improve the municipal center for operational control
                      of buses, which monitors the fleet of 15,000 vehicles,
                      giving more punctuality and quality to services.
                      <br/>
                      <div className="image-container">
                        <img
                            src="https://www.arcgis.com/sharing/rest/content/items/c00235dff5fc48269970c6ee72d49092/resources/17%20-%20BRT%20Aricanduva%20project%20map__1589581394104__w1920.jpg"
                            alt=""
                        />
                      </div>
                      <br/>
                      <b>… And in Belo Horizonte</b>
                      <br/>
                      In Belo Horizonte, in the Southeast region, a new project
                      seeks to develop a high-quality Bus Corridor along the
                      Amazonas Avenue, while promoting urban upgrading of a slum
                      community and better mobility for several low-income
                      neighborhoods.
                      <br/> <br/>
                      Photo caption: In Belo Horizonte, a new project will
                      promote urban upgrading of a slum community while
                      improving mobility for the population. Photo by
                      PBH/Handout.
                      <br/>
                      <div className="image-container">
                        <img
                            src="https://www.arcgis.com/sharing/rest/content/items/c00235dff5fc48269970c6ee72d49092/resources/18%20-%20Belo%20Horizonte%20-%20PBH%20handout__1589581436414__w766.jpg"
                            alt=""
                        />
                      </div>
                      <br/>
                      <b>… Leveraging Public Private Partnerships…</b>
                      <br/>
                      Previous initiatives have fostered not only urban
                      connectivity, but also private sector investment, enabling
                      productive growth. The World Bank Group has supported and
                      continues supporting many public-private partnerships
                      (PPPs) at different stages of preparation and
                      implementation to bypass some of the challenges faced by
                      public investments. IFC alone is expected to provide
                      better access to services through PPPs to an additional
                      8.4 million Brazilians between 2016 and 2022.
                      <br/>
                      <div className="image-container">
                        <LazyLoad height={550} offset={300}>
                          <iframe
                              className="embedContainer stretch"
                              data-src="https://brasilaberto.worldbank.org/pt/indicator/13"
                              data-unload="false"
                              allowFullScreen="1"
                              src="https://brasilaberto.worldbank.org/pt/indicator/13"
                              width="900px"
                              height="550px"
                          ></iframe>
                        </LazyLoad>
                      </div>
                      <br/>
                      <b>… For greater impact…</b>
                      <br/>
                      On urban mobility, passenger rail projects like Sao
                      Paulo’s lines 4 and 5, and Rio de Janeiro’s Suburban Rail
                      have provided public funding and technical assistance to
                      honor public commitments in PPPs over the last 10 years.
                      <br/>
                      <br/>
                      Photo caption: The new suburban trains of Rio de Janeiro
                      can serve an additional 70,200 passenger per day. Photo by
                      Aline Massuca/ Supervia/ Handout.
                      <br/>
                      <div className="image-container">
                        <img
                            src="https://www.arcgis.com/sharing/rest/content/items/c00235dff5fc48269970c6ee72d49092/resources/19%20-%20Rio%20de%20Janeiro%20trains__1589581525459__w1920.jpg"
                            alt=""
                        />
                      </div>
                      <br/>
                      <b>… Supporting green transport...</b>
                      <br/>
                      With improved urban connectivity, the poorest people can
                      have better and faster access to jobs, public services and
                      opportunities. All the above urban transport projects are
                      “green” because they contribute to reducing emissions and
                      increasing air-quality. Additionally, these initiatives
                      aim to better integrate municipal, state and federal
                      government institutions. Thus, they can inform a new
                      generation of infrastructure projects in Brazilian cities.
                      <br/>
                      <div className="image-container">
                        <LazyLoad height={550} offset={300}>
                          <iframe
                              className="embedContainer stretch"
                              data-src="https://brasilaberto.worldbank.org/pt/indicator/18"
                              data-unload="false"
                              allowFullScreen="1"
                              src="https://brasilaberto.worldbank.org/pt/indicator/18"
                              width="900px"
                              height="550px"
                          ></iframe>
                        </LazyLoad>
                      </div>
                      <br/>
                    </span>
                  )}

                  <h3 className="historical-engagement-topic" id="10">
                    {pt
                        ? "Destaque 3: Proteção da Floresta Amazônica…"
                        : "Highlight 3: Protecting the Amazon rainforest"}
                  </h3>
                  {pt ? (
                      <span>
                      O Brasil abriga 60% da floresta amazônica, por muitos
                      considerada um bem público global. A floresta exerce forte
                      influência no clima em nível regional e global e sequestra
                      aproximadamente 70 bilhões de toneladas de carbono. A
                      Amazônia também abriga pelo menos 10% da biodiversidade
                      conhecida no mundo, incluindo espécies endêmicas e
                      ameaçadas de extinção, além da maior bacia hidrográfica do
                      planeta.
                      <br/> <br/>
                      A conservação dessa região e sua vasta diversidade
                      cultural e biológica, bem como o equilíbrio ecológico que
                      sustenta seu papel importantíssimo de regulação climática,
                      são de extrema importância para o Brasil e para a
                      humanidade. O Grupo Banco Mundial tem um papel fundamental
                      a desempenhar nesse sentido, fornecendo apoio técnico e
                      financeiro para preservá-la.
                      <br/>
                      <div className="image-container">
                        <LazyLoad height={550} offset={300}>
                          <iframe
                              className="embedContainer stretch"
                              data-src="https://brasilaberto.worldbank.org/pt/indicator/14"
                              data-unload="false"
                              allowFullScreen="1"
                              src="https://brasilaberto.worldbank.org/pt/indicator/14"
                              width="900px"
                              height="550px"
                          ></iframe>
                        </LazyLoad>
                      </div>
                      <br/>
                      <b>… Fortalecendo o uso sustentável da terra…</b>
                      <br/>
                      O Projeto de Paisagens Sustentáveis da Amazônia no Brasil
                      apoiou a criação de 863.461 hectares (ha) adicionais de
                      unidades de conservação; o Fundo de Transição atualmente
                      apoia 117 unidades de conservação, ou seja, um total de
                      60,3 milhões de hectares. Juntamente com os esforços para
                      implementar práticas sustentáveis nos setores de florestas
                      e produtos florestais não madeireiros (PFNM), o projeto
                      evitou a emissão de 130 milhões de toneladas de CO2
                      equivalente.
                      <br/>
                      <br/>
                      O projeto também fortaleceu o uso sustentável da terra na
                      Amazônia. No âmbito dessa iniciativa, foram assinados e
                      encontram-se em implementação acordos de cooperação
                      visando a gestão integrada de paisagens nos estados do
                      Amazonas, Pará, Rondônia e Acre. Além disso, o projeto
                      apoia atividades de capacitação em 18 organizações de
                      povos indígenas e comunidades tradicionais em nove estados
                      da Amazônia.
                      <br/>
                      <br/>
                      Legenda: O Projeto Paisagens Sustentáveis da Amazônia vem
                      apoiando a criação de áreas protegidas e fortalecendo o
                      uso sustentável do solo na Amazônia. Foto: Hallie
                      Easley/Banco Mundial.
                      <br/>
                      <div className="image-container">
                        <img
                            src="https://www.arcgis.com/sharing/rest/content/items/c00235dff5fc48269970c6ee72d49092/resources/23%20-%20Brazil%20Amazon%20Sustainable%20Landscapes%20Project__1589581746843__w1920.jpg"
                            alt=""
                        />
                      </div>
                      <br/>
                      <b>... Apoiando uma economia florestal sustentável...</b>
                      <br/>
                      <br/>
                      <br/>
                      O estado do Amazonas abriga 25% de toda a floresta
                      amazônica e 40% da Amazônia brasileira. Um novo projeto do
                      Banco Mundial, o DPF Amazonas Sustentável, busca apoiar o
                      estado na gestão sustentável de seus recursos florestais,
                      preservando a sustentabilidade fiscal. Espera-se que o DPF
                      Amazonas Sustentável reduza a taxa de desmatamento anual
                      no estado em 300 km2 até 2022, conservando o equivalente a
                      quase o dobro da área de Washington (DC) por ano de
                      floresta amazônica.
                      <br/>
                      <div className="image-container">
                        <img
                            src="https://www.arcgis.com/sharing/rest/content/items/c00235dff5fc48269970c6ee72d49092/resources/24%20-%20Amazon%20Sustainable%20Landscapes%20-%20Photo%20by%20Mariana%20Ceratti%20-%20World%20bank__1589581973486__w1200.jpg"
                            alt=""
                        />
                      </div>
                      <br/>
                      <b>... E infraestrutura sustentável.</b>
                      <br/>
                      As{" "}
                        <a href="http://diretrizes-grandesobras.gvces.com.br/">
                        Diretrizes para Grandes Obras de Infraestrutura na
                        Amazônia
                      </a>{" "}
                        foram desenvolvidas pela IFC em parceria com a Fundação
                      Getúlio Vargas (FGV). A publicação traz recomendações de
                      políticas públicas e práticas de negócios voltadas para
                      projetos de infraestrutura e mineração, para que levem em
                      consideração as demandas sociais locais e regionais e uma
                      visão de futuro. O objetivo das diretrizes é promover
                      atividades mais cuidadosas de planejamento, implementação
                      e monitoramento; desenvolver mecanismos para incorporar as
                      lições aprendidas; e evitar problemas recorrentes. Mais de
                      100 instituições da sociedade civil participam desse
                      esforço.
                      <br/>
                      <div className="image-container">
                        <LazyLoad height={550} offset={300}>
                          <iframe
                              className="embedContainer stretch"
                              data-src="https://brasilaberto.worldbank.org/pt/indicator/15.2"
                              data-unload="false"
                              allowFullScreen="1"
                              src="https://brasilaberto.worldbank.org/pt/indicator/15.2"
                              width="900px"
                              height="550px"
                          ></iframe>
                        </LazyLoad>
                      </div>
                      <br/>
                    </span>
                  ) : (
                      <span>
                      Brazil is home to 60 percent of the Amazon forest, which
                      is widely regarded as a global public good. The forest
                      significantly influences regional and global climates and
                      sequester approximately 70 billion tons of carbon. The
                      Amazon also hosts at least 10% of the world’s known
                      biodiversity, including endemic and endangered species,
                      and comprises the largest river basin in the world.
                      <br/> <br/>
                      The conservation of this region and its vast cultural and
                      biological diversity, as well as the ecological balance
                      that underpins its crucial role in climate regulation, are
                      of extreme importance for Brazil and the humankind. The
                      World Bank Group has a crucial role to play in providing
                      technical and financial support to preserve it.
                      <br/>
                      <div className="image-container">
                        <LazyLoad height={550} offset={300}>
                          <iframe
                              className="embedContainer stretch"
                              data-src="https://brasilaberto.worldbank.org/pt/indicator/14"
                              data-unload="false"
                              allowFullScreen="1"
                              src="https://brasilaberto.worldbank.org/pt/indicator/14"
                              width="900px"
                              height="550px"
                          ></iframe>
                        </LazyLoad>
                      </div>
                      <br/>
                      <b>… Strengthening sustainable land use…</b>
                      <br/>
                      The Brazil Amazon Sustainable Landscapes Project has
                      supported the creation of an additional 863,461 ha in
                      protected areas, with the Transition Fund currently
                      supporting 117 protected areas, i.e. a total of 60.3
                      million ha. Along with efforts to the implementation of
                      sustainable practices in the forest and non-timber forest
                      product (NTFP) sectors, this resulted in the avoidance of
                      130 million tons of CO2 equivalent.
                      <br/>
                      <br/>
                      The project has been also strengthening sustainable land
                      use in the Amazon. Under the initiative, cooperation
                      agreements for integrated landscape management in the
                      states of Amazonas, Pará, Rondônia and Acre have been
                      signed and are under implementation. Additionally, the
                      project is supporting capacity building activities in 18
                      Indigenous People and Traditional Communities
                      organizations in nine Amazonian States.
                      <br/>
                      <br/>
                      Photo caption: The Brazil Amazon Sustainable Landscapes
                      Project has been supporting the creation of protected
                      areas and strengthening sustainable land use in the
                      Amazon. Photo by Hallie Easley/World Bank.
                      <br/>
                      <div className="image-container">
                        <img
                            src="https://www.arcgis.com/sharing/rest/content/items/c00235dff5fc48269970c6ee72d49092/resources/23%20-%20Brazil%20Amazon%20Sustainable%20Landscapes%20Project__1589581746843__w1920.jpg"
                            alt=""
                        />
                      </div>
                      <br/>
                      <b>… Supporting a sustainable forest economy…</b>
                      <br/>
                      <br/>
                      <br/>
                      The State of Amazonas comprises 25 percent of the whole
                      Amazon Forest and 40 percent of its Brazilian portion. A
                      new World Bank project, the Sustainable Amazonas DPF,
                      seeks to support the State in sustainably managing its
                      forest resources while maintaining its fiscal
                      sustainability. The Sustainable Amazonas DPF is expected
                      to reduce the annual deforestation in the state by 300 km2
                      by 2022, saving the equivalent of almost twice the area of
                      Washington (DC) a year of Amazon rainforest.
                      <br/>
                      <div className="image-container">
                        <img
                            src="https://www.arcgis.com/sharing/rest/content/items/c00235dff5fc48269970c6ee72d49092/resources/24%20-%20Amazon%20Sustainable%20Landscapes%20-%20Photo%20by%20Mariana%20Ceratti%20-%20World%20bank__1589581973486__w1200.jpg"
                            alt=""
                        />
                      </div>
                      <br/>
                      <b>… And sustainable infrastructure</b>
                      <br/>
                      The{" "}
                        <a href="http://diretrizes-grandesobras.gvces.com.br/">
                        Amazon Guidelines for Large Infrastructure Projects
                      </a>{" "}
                        were developed by the IFC in partnership with Getulio
                      Vargas Foundation (FGV in Portuguese). The publication
                      provides public policy and business practices
                      recommendations aimed at infrastructure and mining
                      projects so that they take into consideration the local
                      and regional social demands and vision of future. The
                      guidelines seek to enable careful planning, implementation
                      and monitoring; develop mechanisms to incorporate lessons
                      learned; and avoid recurring issues. More than a hundred
                      civil society institutions engaged in this effort.
                      <br/>
                      <div className="image-container">
                        <LazyLoad height={550} offset={300}>
                          <iframe
                              className="embedContainer stretch"
                              data-src="https://brasilaberto.worldbank.org/pt/indicator/15.2"
                              data-unload="false"
                              allowFullScreen="1"
                              src="https://brasilaberto.worldbank.org/pt/indicator/15.2"
                              width="900px"
                              height="550px"
                          ></iframe>
                        </LazyLoad>
                      </div>
                      <br/>
                    </span>
                  )}

                  <h3 className="historical-engagement-topic" id="11">
                    {pt
                        ? "Destaque 4: Inclusão econômica rural"
                        : "Highlight 4: Rural economic inclusion"}
                  </h3>
                  {pt ? (
                      <span>
                      A pobreza no Brasil ainda apresenta forte concentração
                      geográfica. As taxas de pobreza nas áreas rurais são o
                      dobro das áreas urbanas e muito mais altas no Norte e
                      Nordeste do que no resto do país. Portanto, fomentar a
                      inclusão econômica rural - especialmente para os 5 milhões
                      de famílias de pequenos agricultores no Brasil - é
                      essencial para reduzir a pobreza e promover a prosperidade
                      compartilhada. Além disso, o Brasil precisa continuar
                      aumentando a sua produção de alimentos de maneira
                      sustentável (social e ambientalmente) para preservar seus
                      vastos recursos florestais.
                      <br/>
                      <div className="image-container">
                        <LazyLoad height={550} offset={300}>
                          <ProgramMap
                              location={{
                                pathname: "/pt/map",
                                search: "?layers=percent_poor",
                                hash: "",
                              }}
                              layers={"percent_poor"}
                              bounds={[
                                [-82.079089, -35.275418], // [west, south]
                                [-29.959949, 7.413436], // [east, north]
                              ]}
                          />
                        </LazyLoad>
                      </div>
                      <br/>
                      <b>Apoiando práticas agrícolas sustentáveis…</b>
                      <br/>
                      O portfólio de projetos de agricultura no Brasil é o maior
                      do Banco Mundial na América Latina, com 10 projetos em
                      andamento e mais de US$ 1,27 bilhão em compromissos. Além
                      disso, três projetos encerrados em julho de 2017
                      movimentaram US$ 276 milhões em compromissos.
                      <br/>
                      <br/>
                      Houve grandes avanços na agenda de desenvolvimento rural
                      para o período de julho de 2017 a junho de 2022. De modo
                      geral, o Banco Mundial tem prestado apoio aos agricultores
                      brasileiros por meio de investimentos produtivos,
                      capacitação e assistência técnica.
                      <br />
                      <br />
                      Explore os impactos agregados do projeto, conectando-se
                      diretamente aos sistemas de monitoramento interno do GBM.
                      <br/>
                      <div className="image-container">
                        <LazyLoad height={550} offset={300}>
                          <iframe
                              className="embedContainer stretch"
                              data-src="https://brasilaberto.worldbank.org/pt/indicator/19.1"
                              data-unload="false"
                              allowFullScreen="1"
                              src="https://brasilaberto.worldbank.org/pt/indicator/19.1"
                              width="900px"
                              height="550px"
                          ></iframe>
                        </LazyLoad>
                      </div>
                      <br/>
                      <b>
                        ... Por meio de programas de capacitação em larga
                        escala.
                      </b>
                      <br/>
                      Mais de 90.000 novos agricultores foram capacitados e
                      agora podem adotar práticas e tecnologias agrícolas
                      sustentáveis. A atual Estratégia de Parceria com o Brasil
                      estipula a meta de 179.000 agricultores até 2022.
                      <br/>
                      <div className="image-container">
                        <img
                            src="https://www.arcgis.com/sharing/rest/content/items/c00235dff5fc48269970c6ee72d49092/resources/28%20-%20farmers%20receive%20training%20-%20photo%20by%20illuminati%20filmes%20-%20handout__1589582138926__w1200.jpg"
                            alt=""
                        />
                      </div>
                      <br/>
                      <b>Supervisão inteligente do impacto...</b>
                      <br/>
                      No Rio Grande do Norte, por exemplo, o Projeto de
                      Desenvolvimento Regional e Governança tem aumentado a
                      segurança alimentar e o acesso à infraestrutura produtiva
                      e aos mercados para a agricultura familiar, beneficiando
                      67.400 famílias (quase 270.000 pessoas).
                      <br/>
                      <br/>
                      Em todo o estado, o projeto está investindo no acesso a
                      água e promovendo tecnologias inovadoras - como a
                      irrigação inteligente e o reúso da água - para
                      possibilitar o uso eficiente desse recurso natural
                      escasso. A iniciativa também está ajudando a aumentar a
                      produtividade da agricultura familiar.
                      <br/>
                      <br/>
                      O projeto conta com o apoio do Smart Supervision*, o novo
                      app que fornece informações granulares e em tempo real em
                      campo, incluindo feedback instantâneo dos beneficiários
                      por meio de telefones celulares fornecidos pelo projeto.
                      <br />
                      <br />
                      Explore informações em nível de projeto, incluindo fotos
                      e vídeos recentes, postados pela equipe do Banco Mundial
                      nos locais de atuação do projeto.
                      <br/>
                      <br/>
                      *A iniciativa do Aplicativo de Supervisão Inteligente
                      (Smart Supervion App) busca oferecer opções eficazes para
                      obter e gerenciar dados de alta qualidade de projetos
                      ativos e permite que as equipes forneçam dados
                      operacionais diretamente aos sistemas de supervisão,
                      fornecendo uma solução integrada para monitoramento e
                      avaliação digital no lado do beneficiário e no lado da
                      supervisão remota.
                      <br/>
                      <div className="image-container">
                        <ProgramMap
                          location={{
                            pathname: "/pt/map",
                            search: "?layers=capag",
                            hash: "",
                          }}
                          layers={"fa1,forma"}
                          bounds={[
                            [-39, -6.88],
                            [-34.82, -4.57],
                          ]}
                        />
                      </div>
                      <br />
                      <b>… Impacto em Lagoa Nova, na prática…</b>
                      <br />
                      Com o apoio do projeto, por exemplo, a Associação
                      Quilombola de Macambira, em Lagoa Nova, conseguiu
                      construir 48 cisternas e passou ter uma fonte confiável de
                      água para uso doméstico e produtivo.
                      <br />
                      <br />
                      Legenda: Com as cisternas construídas por meio do Projeto
                      de Desenvolvimento Regional e Governança, a comunidade tem
                      acesso a água para agricultura e uso doméstico. Foto:
                      Banco Mundial.
                      <br />
                      <div className="image-container">
                        <img
                          alt=""
                          src="https://www.arcgis.com/sharing/rest/content/items/c00235dff5fc48269970c6ee72d49092/resources/31%20-%20cisterns__1589582239682__w1280.jpg"
                        />
                      </div>
                      <br />
                      <b>… Impacto no Canto da Ilha de Cima, na prática.</b>
                      <br />
                      Além disso, a iniciativa está promovendo o
                      empreendedorismo feminino nas áreas rurais. No
                      assentamento rural de Canto da Ilha de Cima, uma
                      associação de mulheres recebeu apoio para reformar sua
                      oficina de costura, visando melhorar os meios de
                      subsistência da comunidade.
                      <br />
                      <br />
                      Legenda: O Projeto de Desenvolvimento Regional e
                      Governança apoia uma série de iniciativas que proporcionam
                      emprego e renda para as mulheres rurais do Rio Grande do
                      Norte. Foto: Banco Mundial.
                      <br />
                      <div className="image-container">
                        <img
                          src="https://www.arcgis.com/sharing/rest/content/items/c00235dff5fc48269970c6ee72d49092/resources/32%20%E2%80%93%20tailoring%20workshop__1589582274513__w945.jpg"
                          alt=""
                        />
                      </div>
                      <br />
                    </span>
                  ) : (
                    <span>
                      Brazilian poverty remains highly concentrated
                      geographically. Poverty rates in rural areas are double
                      those of urban areas and much higher in the North and
                      Northeast than in the rest of the country. Therefore,
                      enabling rural economic inclusion – especially for
                      Brazil’s 5 million smallholder farm families – is key to
                      reducing poverty and promoting shared prosperity.
                      Additionally, Brazil needs to continue increasing its food
                      production in an environmentally and socially sustainable
                      manner to conserve its considerable forest resources.
                      <br />
                      <div className="image-container">
                        <LazyLoad height={550} offset={300}>
                          <ProgramMap
                            location={{
                              pathname: "/pt/map",
                              search: "?layers=percent_poor",
                              hash: "",
                            }}
                            layers={"percent_poor"}
                            bounds={[
                              [-82.079089, -35.275418], // [west, south]
                              [-29.959949, 7.413436], // [east, north]
                            ]}
                          />
                        </LazyLoad>
                      </div>
                      <br/>
                      <b>Supporting sustainable farming practices…</b>
                      <br/>
                      Brazil’s Agriculture portfolio is the World Bank’s largest
                      in Latin America, with 10 ongoing projects totaling over
                      US$ 1.27 billion in commitments. In addition, since July
                      2017, three projects were closed, encompassing US$ 276
                      million in commitments.
                      <br/>
                      <br/>
                      Good progress has been made on the rural development
                      agenda for the period encompassing July 2017 to June 2022.
                      Overall, the World Bank has been providing support to
                      Brazilian farmers through productive investments, training
                      and technical assistance.
                      <br/>
                      <br/>
                      💡Explore aggregated project impacts, directly drawing on
                      the WBG’s internal monitoring systems.
                      <br/>
                      <div className="image-container">
                        <LazyLoad height={550} offset={300}>
                          <iframe
                              className="embedContainer stretch"
                              data-src="https://brasilaberto.worldbank.org/pt/indicator/19.1"
                              data-unload="false"
                              allowFullScreen="1"
                              src="https://brasilaberto.worldbank.org/pt/indicator/19.1"
                              width="900px"
                              height="550px"
                          ></iframe>
                        </LazyLoad>
                      </div>
                      <br/>
                      <b>… Through large scale training programs</b>
                      <br/>
                      Over 90,000 additional farmers were trained and now are
                      able to adopt sustainable agriculture practices and
                      technologies. The current Country Partnership Framework
                      sets a target of 179,000 farmers by 2022.
                      <br/>
                      <div className="image-container">
                        <img
                            src="https://www.arcgis.com/sharing/rest/content/items/c00235dff5fc48269970c6ee72d49092/resources/28%20-%20farmers%20receive%20training%20-%20photo%20by%20illuminati%20filmes%20-%20handout__1589582138926__w1200.jpg"
                            alt=""
                        />
                      </div>
                      <br/>
                      <b>Smart supervision of impact...</b>
                      <br/>
                      In Rio Grande do Norte, for example, the
                      <a href="https://projects.worldbank.org/en/projects-operations/project-detail/P126452?lang=en">
                        Regional Development and Governance Project
                      </a>
                      has been increasing food security, access to productive
                      infrastructure and markets for family agriculture,
                      benefiting 67,400 families or nearly 270,000 people.
                      <br/>
                      <br/>
                      Across the state, the project is investing in water access
                      and promoting innovative technologies – such as smart
                      irrigation and water reuse – to enable efficient use of
                      this scarce natural resource. The initiative is also
                      helping increase family farming productivity.
                      <br/>
                      <br/>
                      The project is supported by the new Smart Supervision
                      App*, providing granular, real-time on the ground
                      information, including instant beneficiary feedback via
                      mobile phones provided by the project.
                      <br/>
                      <br/>
                      💡Explore project-level information, including recent
                      pictures and videos, uploaded by World Bank staff at
                      project sites.
                      <br/>
                      <br/>
                      *Smart Supervision App initiative seeks to offer effective
                      options to obtain and manage high-quality project data and
                      enables teams to directly feed operational data into the
                      supervision systems, providing an integrated solution for
                      digital monitoring and evaluation on beneficiary side and
                      remote supervision side.
                      <br/>
                      <div className="image-container">
                        <LazyLoad height={550} offset={300}>
                          <ProgramMap
                              location={{
                                pathname: "/pt/map",
                                search: "?layers=capag",
                                hash: "",
                              }}
                              layers={"fa1,forma"}
                              bounds={[
                                [-39, -6.88],
                                [-34.82, -4.57],
                              ]}
                          />
                        </LazyLoad>
                      </div>
                      <br/>
                      <b>… On the ground impact in Lagoa Nova…</b>
                      <br/>
                      With project support, the Quilombola Association of
                      Macambira, in Lagoa Nova, was able to build 48 cisterns,
                      and have a reliable source of water for domestic and
                      productive uses, for example.
                      <br/>
                      <br/>
                      Photo caption: With World Bank support, rural dwellers of
                      Lagoa Nova were able to build 48 cisterns. Photo by World
                      Bank.
                      <br/>
                      <div className="image-container">
                        <img
                            alt=""
                            src="https://www.arcgis.com/sharing/rest/content/items/c00235dff5fc48269970c6ee72d49092/resources/31%20-%20cisterns__1589582239682__w1280.jpg"
                        />
                      </div>
                      <br/>
                      <b>… And on the ground impact in Canto da Ilha de Cima</b>
                      <br/>
                      Also, the initiative is fostering female entrepreneurship
                      in rural areas. In the rural settlement Canto da Ilha de
                      Cima, a women’s association received support to remodel
                      its tailoring workshop, which will improve livelihoods in
                      the community.
                      <br/>
                      <br/>
                      Photo caption: In addition to sustainable farming and
                      access to markets, the Rio Grande do Norte Regional
                      Development and Governance Project is fostering female
                      entrepreneurship. Photo by World Bank.
                      <br/>
                      <div className="image-container">
                        <img
                            src="https://www.arcgis.com/sharing/rest/content/items/c00235dff5fc48269970c6ee72d49092/resources/32%20%E2%80%93%20tailoring%20workshop__1589582274513__w945.jpg"
                            alt=""
                        />
                      </div>
                      <br/>
                    </span>
                  )}

                  <h3 className="historical-engagement-topic" id="12">
                    {pt
                        ? "Destaque 5: Promoção do Desenvolvimento Humano"
                        : "Highlight 5: Promoting Human Development"}
                  </h3>
                  {pt ? (
                      <span>
                      O Banco Mundial tem apoiado a implementação do Cadastro
                      Único, um registro reconhecido internacionalmente usado
                      originalmente para avaliar o bem-estar das famílias e para
                      administrar o direcionamento e a implementação do Programa
                      Bolsa Família. Hoje, todos os estados e o Distrito Federal
                      devem usar o Cadastro Único como mecanismo de
                      direcionamento para os programas de transferência de
                      renda, com aproximadamente 75,8 milhões de pessoas
                      registradas (40% da população brasileira). O cadastro
                      também está sendo usado para direcionar a assistência a
                      desastres naturais ou causados pelo homem (como a pandemia
                      de Covid-19).
                      <br/>
                      <div className="image-container">
                        <LazyLoad height={550} offset={300}>
                          <iframe
                              className="embedContainer stretch"
                              data-src="https://brasilaberto.worldbank.org/pt/indicator/4"
                              data-unload="false"
                              allowFullScreen="1"
                              src="https://brasilaberto.worldbank.org/pt/indicator/4"
                              width="900px"
                              height="550px"
                          ></iframe>
                        </LazyLoad>
                      </div>
                      <br/>
                    </span>
                  ) : (
                      <span>
                      The World Bank has been supporting the implementation of
                      the Cadastro Único, an internationally recognized registry
                      originally used to assess household welfare and to
                      administer the targeting and implementation of Bolsa
                      Familia Program. By now, all states and the Federal
                      District to use the Cadastro Único as the targeting
                      mechanism for all income transfer programs, with
                      approximately 75.8 million people registered (40 percent
                      of the Brazilian population). It is also being used to
                      target assistance upon natural and man-made disasters
                      (like, for example, the 2020 Covid-19 pandemic).
                      <br/>
                      On health, The World Bank is also
                      <a href="https://projects.worldbank.org/pt/projects-operations/project-detail/P171977">
                        supporting the Brazilian government in reforming the
                        Sistema Unico de Saude (SUS)
                      </a>
                      financing and organization to improve access, quality and
                      efficiency of public health services, thus helping reduce
                      premature mortality rates.
                      <div className="image-container">
                        <LazyLoad height={550} offset={300}>
                          <iframe
                              className="embedContainer stretch"
                              data-src="https://brasilaberto.worldbank.org/pt/indicator/4"
                              data-unload="false"
                              allowFullScreen="1"
                              src="https://brasilaberto.worldbank.org/pt/indicator/4"
                              width="900px"
                              height="550px"
                          ></iframe>
                        </LazyLoad>
                      </div>
                      <br/>
                    </span>
                  )}
                  <h3 className="historical-engagement-topic" id="13">
                    {pt ? "Apoio à educação" : "Support to Education"}
                  </h3>
                  {!pt ? (
                      <span>
                      The World Bank has also been supporting critical education
                      across the whole country, including an
                      <a href="https://www.worldbank.org/en/news/press-release/2017/12/14/brazil-program-for-results-supports-upper-secondary-education-reform">
                        ambitious upper secondary school reform
                      </a>
                      with the objectives of strengthening the capacity of the
                      state secretariats of education to implement the upper
                      secondary reform, prioritizing vulnerable schools, and
                      increasing the Index of Basic Education Development in
                      targeted full-time upper secondary schools in all
                      Brazilian states.
                      <br/>
                      <div className="image-container">
                        <LazyLoad height={550} offset={300}>
                          <iframe
                              className="embedContainer stretch"
                              data-src="https://brasilaberto.worldbank.org/pt/indicator/6"
                              data-unload="false"
                              allowFullScreen="1"
                              src="https://brasilaberto.worldbank.org/pt/indicator/6"
                              width="900px"
                              height="550px"
                          ></iframe>
                        </LazyLoad>
                      </div>
                      <br/>
                    </span>
                  ) : (
                      <span>
                      O Banco Mundial também tem apoiado a educação em todo o
                      país, inclusive com um projeto que visa a fortalecer a
                      capacidade de as secretarias estaduais de educação
                      implementarem a reforma do ensino médio, priorizando
                      escolas vulneráveis e aumentando o Índice de
                      Desenvolvimento da Educação Básica em escolas de ensino
                      médio em período integral selecionadas em todos os estados
                      brasileiros.
                      <br/>
                      <div className="image-container">
                        <LazyLoad height={550} offset={300}>
                          <iframe
                              className="embedContainer stretch"
                              data-src="https://brasilaberto.worldbank.org/pt/indicator/6"
                              data-unload="false"
                              allowFullScreen="1"
                              src="https://brasilaberto.worldbank.org/pt/indicator/6"
                              width="900px"
                              height="550px"
                          ></iframe>
                        </LazyLoad>
                      </div>
                      <br/>
                    </span>
                  )}
                  <h3 className="historical-engagement-topic" id="14">
                    {pt
                        ? "Conhecimento como bem público: implementação de projetos"
                        : "Knowledge as a public good: project implementation"}
                  </h3>
                  {pt ? (
                      <span>
                      A política baseada em evidências é parte central do
                      trabalho do GBM, no Brasil e além. Por meio de uma análise
                      de milhares de projetos de desenvolvimento, o Grupo de
                      Avaliação Independente do Banco Mundial colheu lições e as
                      disponibilizou na{" "}
                        <a href="http://www.globaldeliveryinitiative.org/">
                        Global Delivery Initiative
                      </a>
                      , que baseia-se em fortes parcerias entre profissionais e
                      instituições de desenvolvimento e acadêmicas com interesse
                      mútuo em compartilhar experiências e lições de
                      desenvolvimento concentradas na implementação e nos
                      resultados. Os insights de projetos do mundo inteiro
                      ajudam a melhorar o impacto do trabalho do GBM no Brasil.
                      <br />
                      <br />
                      Explore lições para uma implementação bem-sucedida de
                      projetos de desenvolvimento, tanto no Brasil quanto no
                      exterior.
                      <br/>
                      <div className="image-container">
                        <LazyLoad height={550} offset={300}>
                          <iframe
                              className="embedContainer stretch"
                              data-src="https://brasilaberto.worldbank.org/pt/gdi"
                              data-unload="false"
                              allowFullScreen="1"
                              src="https://brasilaberto.worldbank.org/pt/gdi"
                              width="900px"
                              height="550px"
                          ></iframe>
                        </LazyLoad>
                      </div>
                      <br/>
                    </span>
                  ) : (
                      <span>
                      Evidence-based policy is at the core of the work of the
                      WBG in Brazil and beyond. Through the analysis of
                      thousands of development projects, the World Bank’s
                      Independent Evaluation Group collected lessons and made
                      them available through the{" "}
                        <a href="http://www.globaldeliveryinitiative.org/">
                        Global Delivery Initiative
                      </a>
                      , which is grounded in strong partnerships between
                      practitioners, development and academic institutions with
                      mutual interests in sharing development experiences and
                      lessons that focus on implementation and results. Insights
                      from projects across the world help improve the impact of
                      the WBG’s work in Brazil.
                      <br />
                      <br />
                      Explore lessons for the successful implementation of
                      development projects, in Brazil and beyond.
                      <br/>
                      <div className="image-container">
                        <LazyLoad height={550} offset={300}>
                          <iframe
                              className="embedContainer stretch"
                              data-src="https://brasilaberto.worldbank.org/pt/gdi"
                              data-unload="false"
                              allowFullScreen="1"
                              src="https://brasilaberto.worldbank.org/pt/gdi"
                              width="900px"
                              height="550px"
                          ></iframe>
                        </LazyLoad>
                      </div>
                      <br/>
                    </span>
                  )}
                  <h3 className="historical-engagement-topic" id="15">
                    {pt
                        ? "Conhecimento como bem público: avaliações de impacto"
                        : "Knowledge as a public good: impact evaluations"}
                  </h3>
                  {pt ? (
                      <span>
                      Reunir os aprendizados das atividades de desenvolvimento é
                      fundamental para a eficácia do trabalho do Grupo Banco
                      Mundial. As avaliações de impacto oferecem informações
                      concretas e transparentes sobre o impacto de atividades
                      específicas do projeto em indicadores sociais ou
                      econômicos. Elas disponibilizam um conjunto de ferramentas
                      que as partes interessadas podem usar para verificar e
                      melhorar a qualidade, eficiência e eficácia de políticas e
                      programas em vários estágios de implementação. Em outras
                      palavras, focar nos resultados. Além disso, ajudam a
                      garantir a implantação de soluções financeiras e técnicas
                      para causar o melhor efeito possível na vida das pessoas,
                      principalmente quando os recursos são escassos.
                      <br/>
                      <br/>
                      Explore insights sobre o impacto das soluções de
                      desenvolvimento no Brasil e no mundo.
                      <br/>
                      <div className="image-container">
                        <LazyLoad height={550} offset={300}>
                          <iframe
                              className="embedContainer stretch"
                              data-src="https://brasilaberto.worldbank.org/pt/impact-eval"
                              data-unload="false"
                              allowFullScreen="1"
                              src="https://brasilaberto.worldbank.org/pt/impact-eval"
                              width="900px"
                              height="550px"
                          ></iframe>
                        </LazyLoad>
                      </div>
                      <br/>
                    </span>
                  ) : (
                      <span>
                      Gathering insights from development interventions is
                      critical for development effectiveness. Impact evaluations
                      provide concrete, attributable information on the impact
                      of specific project activities on social or economic
                      indicators. They provide a set of tools that stakeholders
                      can use to verify and improve the quality, efficiency, and
                      effectiveness of policies and programs at various stages
                      of implementation—or, in other words, to focus on results.
                      Additionally, they help ensure that financing and
                      technical solutions can be deployed to make the best
                      possible effect on people’s lives, especially when few
                      resources are available.
                      <br/>
                      <br/>
                      Explore insights on the impact of development solutions
                      from Brazil and the world.
                      <br/>
                      <div className="image-container">
                        <LazyLoad height={550} offset={300}>
                          <iframe
                              className="embedContainer stretch"
                              data-src="https://brasilaberto.worldbank.org/pt/impact-eval"
                              data-unload="false"
                              allowFullScreen="1"
                              src="https://brasilaberto.worldbank.org/pt/impact-eval"
                              width="900px"
                              height="550px"
                          ></iframe>
                        </LazyLoad>
                      </div>
                      <br/>
                    </span>
                  )}
                  <br/>
                  <br/>
                  <br/>
                  <br/>
                  <br/>
                  <br/>
                  <br/>
                  <br/>
                </div>
              </div>
            </div>
          </div>

          <WelcomeFooter {...props} />
        </div>
    </div>
  );
};

export default withRouter(Story);
