// DOWN HERE
import React, { useState, useEffect } from 'react';

import { withRouter } from 'react-router-dom';
import axios from 'axios';

import { textEnglish, textPortuguese } from './i18n/Welcome';

import UserReviewModal from '../common/modals/UserReviewModal';
import Navbar from '../common/navbar/NavbarPLR';
import Header from '../menu/Header';

import WelcomeCover from '../menu/img/welcome-cover.webp';
import HeaderImage from '../menu/img/welcome-banner.png';
import WelcomeFooter from '../menu/WelcomeFooter';
import '../menu/Welcome.css';
import AltButton from '../../components/AltButton';
import AltCard from '../../components/AltCard';

import { API_URL } from '../../../config/api';
import PureNaturalIcon from '../../../icons/pure-natural.svg'
import ChartLineIcon from '../../../icons/chart-line.svg'
import PaperMoneyIcon from '../../../icons/paper-money-two.svg'
import AltProjectCard from '../../components/AltProjectCard';
import { WelcomeSearchContent } from './WelcomeSearchContent';

function Welcome(props) {
  const [pt, setPt] = useState(props.location.pathname.includes('/pt'));
  const [text, setText] = useState(!pt ? textEnglish : textPortuguese);
  const [latestProjects, setLatestProjects] = useState([])
  const [projects, setProjects] = useState([]);
  const [searchInput, setSearchInput] = useState('');

  const handleLogout = () => {
    localStorage.removeItem('auth');
    props.history.push('/');
  };

  const fetchProjects = async () => {
    const ret = await axios
      .get(`${API_URL}/projects`);
    const result = ret.data.filter(project => {
      if (![project.title, project.title_pt].includes('testpremiersoft')) {
        return project;
      }
    });
    const lastProjects = result.slice(0, 3);
    setLatestProjects(lastProjects);
    setProjects(result);
  }

  const onChangeLoginState = () => {};

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchProjects();
  }, []);

  return (
    <div className="container-fluid unpadify">
      <Navbar
        handleLogin={handleLogout}
        handleLogout={handleLogout}
        auth={props.auth}
        isLoggedIn={props.loggedIn}
        checkIsLoggedIn={props.checkIsLoggedIn}
        onChangeLoginState={onChangeLoginState}
        searchInput={searchInput}
        setSearchInput={setSearchInput}
        shouldRedirectSearch={false}
        {...props}
      />
      {searchInput.length === 0 ? (
        <>
          <Header
            big={text.big}
            desc={text.desc}
            isHomepage={true}
            banner={HeaderImage}
            {...props}
          />
          <WelcomeContent 
            {...props} 
            WelcomeCover={WelcomeCover} 
            projects={projects} 
            pt={pt}
            latestProjects={latestProjects} 
          />
        </>
        ) : <WelcomeSearchContent searchText={searchInput} projects={projects} pt={pt} />
      }
      
      <WelcomeFooter {...props} />
    </div>
  );
}

function WelcomeContent({ WelcomeCover, projects, pt, latestProjects,...props }) {
  const learnMoreFocus = (focus) => {
    localStorage.setItem('strategy_focus_area', focus);
    const path = pt ? "/pt/strategy" : "/strategy"
    props.history.push(path);
  }

  return (
    <>
      {props.checkIsLoggedIn() && (
        <UserReviewModal {...props} />
      )}
      <div className="welcome-projects-container">
        <div className="welcome-projects-content" style={{ backgroundImage: `url(${WelcomeCover})`, height: '395px' }}>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '40%' }}>
            <h2 className="welcome-projects-title">{pt ? 'Apoiando o desenvolvimento' : 'Supporting Development'}</h2>
            <span className="welcome-projects-desc">{pt ? 'O Banco Mundial financia projetos para apoiar governos na redução da pobreza e na promoção da prosperidade compartilhada, de maneira sustentável.' : 'The World Bank finances projects to support governments in reducing poverty and promoting shared prosperity in a sustainable way.'}</span>
            <AltButton style={{ height: '49px', width: '271px', display: 'flex', alignItems: 'center', justifyContent: 'center' }} href={pt ? "/pt/portfolio" : "/portfolio"}>{pt ? "Acompanhe nosso alcance" : "Follow our reach"}</AltButton>
          </div>
          <div>
            <span className='welcome-projects-info-title' style={{marginBottom: '10px', fontSize: '28px'}}>{pt ? 'No Brasil' : 'In Brazil'}</span>
            <br/><br/>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '90px' }}>
              <div style={{ display: 'flex', flexDirection: 'column', gap: '22px' }}>
                <span className="welcome-projects-info-title">{pt ? "Projetos ativos" : "Active projects"}</span>
                <span className="welcome-projects-info-quantity">{projects.filter(project => project.status === "Active").length}</span>
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', gap: '22px' }}>
                <span className="welcome-projects-info-title">{pt ? "Portfólio" : "Portfolio"}</span>
                <span className="welcome-projects-info-quantity">{(() => {
                  let total = 0;
                  projects.map(project => {
                    total += parseFloat(project.focus_area_1_value ?? '0');
                    total += parseFloat(project.focus_area_2_value ?? '0');
                    total += parseFloat(project.focus_area_3_value ?? '0');
                  });
                  return (total/1000000000).toString().substring(0, 4) + ' Bi'
                  })()}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="focus-areas-cards welcome-areas">
        <AltCard 
          icon={PaperMoneyIcon}
          title={pt ? "Área de foco 1" : "Focus Area 1"}
          description={pt ? "Consolidação fiscal e eficácia do governo para prestação de serviços sustentável, inclusiva e eficiente." : "Fiscal Consolidation and Government Effectiveness for Sustainable, Inclusive and Efficient Service Delivery"}
          href={pt ? "/pt/strategy" : "/strategy"}
          onClickButton={() => learnMoreFocus('focus_1')}
          buttonText={pt ? "Saiba mais" : "Learn more"}
        />
        <AltCard 
          icon={ChartLineIcon}
          title={pt ? "Área de foco 2" : "Focus Area 2"}
          description={pt ? "Investimento do setor privado e crescimento produtivo." : "Private Sector Investment and Productive Growth"}
          href={pt ? "/pt/strategy" : "/strategy"}
          onClickButton={() => learnMoreFocus('focus_2')}
          buttonText={pt ? "Saiba mais" : "Learn more"}
        />
        <AltCard 
          icon={PureNaturalIcon}
          title={pt ? "Área de foco 3" : "Focus Area 3"}
          description={pt ? "Desenvolvimento Sustentável e Inclusivo." : "Inclusive and Sustainable Development"}
          href={pt ? "/pt/strategy" : "/strategy"}
          onClickButton={() => learnMoreFocus('focus_3')}
          buttonText={pt ? "Saiba mais" : "Learn more"}
        />
      </div>
      <div className="welcome-last-projects-container">
        <div className="header">
          <span>{pt ? "Últimos projetos" : "Latest projects"}</span>
          <AltButton 
            style={{ width: '139px', height: '49px', display: 'flex', alignItems: 'center', justifyContent: 'center' }} 
            inverse={true} 
            href={pt ? "/pt/projects" : "/projects"}
          >
            {pt ? "Saiba mais" : "Learn more"}
          </AltButton>
        </div>
        {latestProjects.sort(project => project.status === 'Active' ? 1 : 0).map(project => (
          <AltProjectCard
            status={project.status}
            id={project.id}
            pt={pt}
            title={pt ? project.title_pt ?? project.title : project.title ?? project.title_pt}
            description={pt ? project.description_pt ?? project.description : project.description ?? project.description_pt}
            approvalDate={(new Date(project.date_approved)).toISOString().substring(0, 10) || null}
            href={pt ? `/pt/projectdetails/${project.id}` : `/projectdetails/${project.id}`}
          />
        ))}
      </div>
    </>
  );
}

export default withRouter(Welcome);
