import React from 'react';
import styles from './styles.module.css';
import classNames from 'classnames';

const Timeline = ({ items, className, ...props }) => {
  return (
    <div className={classNames(styles.wrapper, className)} {...props}>
      {Array.isArray(items) &&
        items.length > 0 &&
        items.map(({ label, value, circle }, index) => {
          const circleClasses = classNames(styles.circle, {
            [styles.circleInactive]: circle,
          });
          return (
            <div key={index} className={classNames(styles.itemWrapper)}>
              <div className={circleClasses}>{index + 1}</div>
              <span className={classNames(styles.label)}>{label}</span>
              <p className={classNames(styles.value)}>{value}</p>
            </div>
          );
        })}
    </div>
  );
};

export default Timeline;
