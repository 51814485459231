import React, { useState, useEffect } from 'react';
import AltButton from "../../AltButton";

const states = [
  'Acre',
  'Alagoas',
  'Amapá',
  'Amazonas',
  'Bahia',
  'Ceará',
  'Distrito Federal',
  'Espírito Santo',
  'Goiás',
  'Maranhão',
  'Mato Grosso',
  'Mato Grosso do Sul',
  'Minas Gerais',
  'Pará',
  'Paraíba',
  'Paraná',
  'Pernambuco',
  'Piauí',
  'Rio de Janeiro',
  'Rio Grande do Norte',
  'Rio Grande do Sul',
  'Rondônia',
  'Roraima',
  'Santa Catarina',
  'São Paulo',
  'Sergipe',
  'Tocantins',
];

const statesAbbreviation = {
  'Acre': ' (AC)',
  'Alagoas': ' (AL)',
  'Amapá': ' (AP)',
  'Amazonas': ' (AM)',
  'Bahia': ' (BA)',
  'Ceará': ' (CE)',
  'Distrito Federal': ' (DF)',
  'Espírito Santo': ' (ES)',
  'Goiás': ' (GO)',
  'Maranhão': ' (MA)',
  'Mato Grosso': ' (MT)',
  'Mato Grosso do Sul': ' (MS)',
  'Minas Gerais': ' (MG)',
  'Pará': ' (PA)',
  'Paraíba': ' (PB)',
  'Paraná': ' (PR)',
  'Pernambuco': ' (PE)',
  'Piauí': ' (PI)',
  'Rio de Janeiro': ' (RJ)',
  'Rio Grande do Norte': ' (RN)',
  'Rio Grande do Sul': ' (RS)',
  'Rondônia': ' (RO)',
  'Roraima': ' (RR)',
  'Santa Catarina': ' (SC)',
  'São Paulo': ' (SP)',
  'Sergipe': ' (SE)',
  'Tocantins': ' (TO)',
};

export const StateSelector = ({ onSelectState, defaultSelectedStates: selectedStates }) => {
  const handleStateClick = (state) => {
    if (selectedStates.includes(state)) {
      onSelectState(selectedStates.filter((selectedState) => selectedState !== state));
    } else {
      onSelectState([...selectedStates, state]);
    }
  };

  const handleSelectAll = () => {
    if (states.length === selectedStates.length) {
      onSelectState([]);
    } else {
      onSelectState(states);
    }
  }

  return (
    <div style={{
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between'
    }}>
      <div
        style={{
          border: 'solid 1px black',
          padding: '10px',
          display: 'inline-block',
          textAlign: 'left',
          margin: '10px 0',
        }}
      >
        {states.map((state) => (
          <button
            key={state}
            style={{
              backgroundColor: selectedStates.includes(state) ? '#002244' : 'white',
              margin: '5px',
              padding: '5px 15px',
              color: selectedStates.includes(state) ? 'white' : 'black',
            }}
            onClick={() => handleStateClick(state)}
          >
            {`${state}${statesAbbreviation[state]}`}
          </button>
        ))}
      </div>
      <span
        style={{
          color: '#666666'
        }}
      >
        {selectedStates.length} selecionados
      </span>

      <AltButton inverse={true} onClick={handleSelectAll}>
        {states.length === selectedStates.length ? 'Deselecionar todos' : 'Selecionar todos'}
      </AltButton>
    </div>
  );
};
