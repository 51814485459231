import React from 'react';

export const URL_REGEX = /^(http(s):\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/;

export const BRAZILSTATES = [
{ label : "Acre"                  , value :  "Acre"               },  
{ label : "Alagoas"               , value :  "Alagoas"            },  
{ label : "Amapá"                 , value :  "Amapá"              },  
{ label : "Amazonas"              , value :  "Amazonas"           },  
{ label : "Bahia"                 , value :  "Bahia"              },  
{ label : "Ceará"                 , value :  "Ceará"              },  
{ label : "Distrito Federal"      , value :  "Distrito Federal"   },  
{ label : "Espírito Santo"        , value :  "Espírito Santo"     },  
{ label : "Goiás"                   , value :  "Goiás"                },  
{ label : "Maranhão"                , value :  "Maranhão"             },  
{ label : "Mato Grosso"           , value :  "Mato Grosso"        },  
{ label : "Mato Grosso do Sul"    , value :  "Mato Grosso do Sul" },  
{ label : "Minas Gerais"          , value :  "Minas Gerais"       },  
{ label : "Pará"                  , value : "Pará"                 },  
{ label : "Paraíba"               , value : "Paraíba"              },  
{ label : "Paraná"                , value : "Paraná"               },  
{ label : "Pernambuco"            , value : "Pernambuco"           },  
{ label : "Piauí"                 , value : "Piauí"                },  
{ label : "Rio de Janeiro"        , value :  "Rio de Janeiro"     },  
{ label : "Rio Grande do Norte"   , value :  "Rio Grande do Norte"},  
{ label : "Rio Grande do Sul"     , value :  "Rio Grande do Sul"  },  
{ label : "Rondônia"                , value :  "Rondônia"             },  
{ label : "Roraima"                 , value :  "Roraima"              },  
{ label : "Santa Catarina"        , value :  "Santa Catarina"     },  
{ label : "São Paulo"             , value :  "São Paulo"          },  
{ label : "Sergipe"                 , value :  "Sergipe"              },  
{ label : "Tocantins"               , value :  "Tocantins"            }
];

export const mock_reports = [
  {
    id: '1',
    title: 'Report 1',
    href: `http://www.google.com`,
    image: 'https://i.picsum.photos/id/0/5616/3744.jpg?hmac=3GAAioiQziMGEtLbfrdbcoenXoWAW-zlyEAMkfEdBzQ',
    imageFile: '',
  },
  {
    id: '2',
    title: 'Report 2',
    href: `http://www.google.com`,
    image: 'https://i.picsum.photos/id/10/2500/1667.jpg?hmac=J04WWC_ebchx3WwzbM-Z4_KC_LeLBWr5LZMaAkWkF68',
    imageFile: '',
  },
  {
    id: '3',
    title: 'Report 3',
    href: `http://www.google.com`,
    image: 'https://i.picsum.photos/id/1000/5626/3635.jpg?hmac=qWh065Fr_M8Oa3sNsdDL8ngWXv2Jb-EE49ZIn6c0P-g',
    imageFile: '',
  },
  {
    id: '4',
    title: 'Report 4',
    href: `http://www.google.com`,
    image: 'https://i.picsum.photos/id/1001/5616/3744.jpg?hmac=38lkvX7tHXmlNbI0HzZbtkJ6_wpWyqvkX4Ty6vYElZE',
    imageFile: '',
  },
]

export const mock_projects = [
  {
    id: '1000',
    project_name: 'Project 1',
    states: [
      { value: 'CE', label: 'Ceará' },
      { value: 'SP', label: 'São Paulo' },
    ],
    focus_area_1: '1200',
    focus_area_2: '760',
    focus_area_3: '556',
  },
  {
    id: '1001',
    project_name: 'Project 2',
    states: [
      { value: 'RJ', label: 'Rio de Janeiro' },
      { value: 'SC', label: 'Santa Catarina' },
    ],
    focus_area_1: '6534',
    focus_area_2: '2342',
    focus_area_3: '1232',
  },
  {
    id: '1002',
    project_name: 'Project 3',
    states: [
      { value: 'SP', label: 'São Paulo' },
      { value: 'RN', label: 'Rio Grande do Norte' },
    ],
    focus_area_1: '34567',
    focus_area_2: '5462',
    focus_area_3: '7657',
  },
  {
    id: '1003',
    project_name: 'Project 16',
    states: [
      { value: 'RN', label: 'Rio Grande do Norte' },
      { value: 'RS', label: 'Rio Grande do Sul' },
    ],
    focus_area_1: '3342',
    focus_area_2: '784',
    focus_area_3: '2345',
  },
  {
    id: '1004',
    project_name: 'Project 5',
    states: [
      { value: 'MT', label: 'Mato Grosso' },
      { value: 'RS', label: 'Rio Grande do Sul' },
    ],
    focus_area_1: '364',
    focus_area_2: '460',
    focus_area_3: '250',
  },
  {
    id: '1005',
    project_name: 'Project 6',
    states: [
      { value: 'CE', label: 'Ceará' },
      { value: 'MT', label: 'Mato Grosso' },
    ],
    focus_area_1: '1200',
    focus_area_2: '570',
    focus_area_3: '560',
  },
  {
    id: '1014',
    project_name: 'Project 7',
    states: [
      { value: 'SP', label: 'São Paulo' },
      { value: 'SC', label: 'Santa Catarina' },
    ],
    focus_area_1: '645534',
    focus_area_2: '2342',
    focus_area_3: '1232',
  },
  {
    id: '1013',
    project_name: 'Project 13',
    states: [
      { value: 'CE', label: 'Ceará' },
      { value: 'SP', label: 'São Paulo' },
    ],
    focus_area_1: '34567',
    focus_area_2: '5462',
    focus_area_3: '7657',
  },
  {
    id: '1006',
    project_name: 'Project 8',
    states: [
      { value: 'SC', label: 'Santa Catarina' },
      { value: 'RS', label: 'Rio Grande do Sul' },
    ],
    focus_area_1: '345342',
    focus_area_2: '784',
    focus_area_3: '2345',
  },
  {
    id: '1007',
    project_name: 'Project 9',
    states: [
      { value: 'CE', label: 'Ceará' },
      { value: 'RS', label: 'Rio Grande do Sul' },
    ],
    focus_area_1: '35654',
    focus_area_2: '54760',
    focus_area_3: '260',
  },
  {
    id: '1008',
    project_name: 'Project 10',
    states: [
      { value: 'CE', label: 'Ceará' },
      { value: 'RN', label: 'Rio Grande do Norte' },
    ],
    focus_area_1: '1200',
    focus_area_2: '760',
    focus_area_3: '560',
  },
  {
    id: '1009',
    project_name: 'Project 11',
    states: [
      { value: 'RN', label: 'Rio Grande do Norte' },
      { value: 'RJ', label: 'Rio de Janeiro' },
    ],
    focus_area_1: '645534',
    focus_area_2: '2342',
    focus_area_3: '1232',
  },
  {
    id: '1010',
    project_name: 'Project 14',
    states: [
      { value: 'MT', label: 'Mato Grosso' },
      { value: 'RJ', label: 'Rio de Janeiro' },
    ],
    focus_area_1: '367',
    focus_area_2: '5462',
    focus_area_3: '7657',
  },
  {
    id: '1011',
    project_name: 'Project 12',
    states: [
      { value: 'SP', label: 'São Paulo' },
      { value: 'RN', label: 'Rio Grande do Norte' },
    ],
    focus_area_1: '345342',
    focus_area_2: '784',
    focus_area_3: '2345',
  },
  {
    id: '1012',
    project_name: 'Project 15',
    states: [
      { value: 'CE', label: 'Ceará' },
      { value: 'RS', label: 'Rio Grande do Sul' },
    ],
    focus_area_1: '3554',
    focus_area_2: '560',
    focus_area_3: '5560',
  },
];

export const mock_indicators = [
  {
    id: '1',
    indicator_name: 'Indicator 1',
    parent: 'Parent 1',
    unitOfMeasurement: { label: 'Percentage', value: 'percentage' },
    description: 'Description 1',
    status: { label: 'Active', value: 'active' },

    indicators: [
      {
        id: '1',
        type: { label: 'Baseline', value: 'baseline' },
        value: '35',
        date: '2020-01-01',
        comment: '',
      },
      {
        id: '2',
        type: { label: 'Target', value: 'target' },
        value: '40',
        date: '2020-01-01',
        comment: '',
      },
      {
        id: '3',
        type: { label: 'Progress', value: 'progress' },
        value: '24',
        date: '2020-01-01',
        comment: '',
      },
      {
        id: '4',
        type: { label: 'Progress', value: 'progress' },
        value: '36',
        date: '2020-01-01',
        comment: '',
      },
      {
        id: '5',
        type: { label: 'Progress', value: 'progress' },
        value: '32',
        date: '2020-01-01',
        comment: '',
      },
      {
        id: '6',
        type: { label: 'Progress', value: 'progress' },
        value: '44',
        date: '2020-01-01',
        comment: '',
      },
      {
        id: '7',
        type: { label: 'Progress', value: 'progress' },
        value: '75',
        date: '2020-05-01',
        comment: '',
      },
    ],
  },
  {
    id: '2',
    indicator_name: 'Indicator 1',
    parent: 'Parent 1',
    unitOfMeasurement: { label: 'Percentage', value: 'percentage' },
    description: 'Description 1',
    status: { label: 'Active', value: 'active' },

    indicators: [
      {
        id: '1',
        type: { label: 'Baseline', value: 'baseline' },
        value: '35',
        date: '2020-01-01',
        comment: '',
      },
      { id: '2', type: { label: 'Target', value: 'target' }, value: '40', date: '2020-01-01', comment: '' },
      {
        id: '3',
        type: { label: 'Progress', value: 'progress' },
        value: '24',
        date: '2020-01-01',
        comment: '',
      },
      {
        id: '4',
        type: { label: 'Progress', value: 'progress' },
        value: '36',
        date: '2020-01-01',
        comment: '',
      },
      {
        id: '5',
        type: { label: 'Progress', value: 'progress' },
        value: '32',
        date: '2020-01-01',
        comment: '',
      },
      {
        id: '6',
        type: { label: 'Progress', value: 'progress' },
        value: '44',
        date: '2020-01-01',
        comment: '',
      },
    ],
  },
  {
    id: '3',
    indicator_name: 'Indicator 1',
    parent: 'Parent 1',
    unitOfMeasurement: { label: 'Percentage', value: 'percentage' },
    description: 'Description 1',
    status: { label: 'Active', value: 'active' },

    indicators: [
      {
        id: '1',
        type: { label: 'Baseline', value: 'baseline' },
        value: '35',
        date: '2020-01-01',
        comment: '',
      },
      { id: '2', type: 'Target', value: '40', date: '2020-01-01', comment: '' },
      {
        id: '3',
        type: { label: 'Progress', value: 'progress' },
        value: '24',
        date: '2020-01-01',
        comment: '',
      },
      {
        id: '4',
        type: { label: 'Progress', value: 'progress' },
        value: '36',
        date: '2020-01-01',
        comment: '',
      },
    ],
  },
  {
    id: '4',
    indicator_name: 'Indicator 1',
    parent: 'Parent 1',
    unitOfMeasurement: { label: 'Percentage', value: 'percentage' },
    description: 'Description 1',
    status: { label: 'Active', value: 'active' },

    indicators: [
      {
        id: '1',
        type: { label: 'Baseline', value: 'baseline' },
        value: '35',
        date: '2020-01-01',
        comment: '',
      },
      {
        id: '2',
        type: { label: 'Target', value: 'target' },
        value: '40',
        date: '2020-01-01',
        comment: '',
      },
      {
        id: '3',
        type: { label: 'Progress', value: 'progress' },
        value: '24',
        date: '2020-01-01',
        comment: '',
      },
    ],
  },
];


export const currencyMask = (input) => {
  if (!input) {
    return '';
  }
  input = input.replace('.', '').replace(',', '').replace(/\D/g, '');

  const options = { minimumFractionDigits: 2 };
  const result = new Intl.NumberFormat('pt-BR', options).format(
    parseFloat(input) / 100
  );

  return result;
};
