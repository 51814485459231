import React, { useEffect, useRef } from 'react';
import * as d3 from "d3";

import './Gauge.css';

const Gauge = ({ data }) => {
    const nodeRef = useRef(null);

    const percToDeg = (perc) => {
        return perc * 360;
    };

    const degToRad = (deg) => {
        return deg * (Math.PI / 180);
    };

    const percToRad = (perc) => {
        return (perc * (Math.PI / 180)) * 360;
    };

    const updateChart = () => {
		d3.select(nodeRef.current).selectAll("*").remove();
		createChart();
	}

    const createChart = () => {
        const node = nodeRef.current;
        const percent = ((data.percent + 1) / 2) > 1
            ? 1
            : (((data.percent + 1) / 2) < 0
                ? 0
                : ((data.percent + 1) / 2));
        let tempPercentText = parseInt(data.percent * 100) + "%";
        if (data.percent * 100 > 100) {
            tempPercentText = ">100%";
        }
        if (data.percent * 100 === 100) {
            tempPercentText = "";
        }
        if (data.percent * 100 < -100) {
            tempPercentText = "<-100%";
        }
        if (data.percent * 100 === -100) {
            tempPercentText = "";
        }
        const percentText = tempPercentText;
        const numSections = 2;
        const sectionPerc = 1 / numSections / 2;
        const padRad = 0.05;
        const chartInset = 10;
        const maxWidth = 350;
        let totalPercent = .75;
        const margin = { top: 40, right: 55, bottom: 40, left: 40 };
        const width = maxWidth - margin.left - margin.right;
        const height = width * .8 + 50;
        const radius = Math.min(width, height) / 2;
        const barWidth = 20 * width / maxWidth;
        const outerRadius = radius + (barWidth / 2);
        const outerBarWidth = barWidth * 2;

        const svg = d3.select(node)
            .append("svg")
            .attr("width", (false ? d3.select(node).node().getBoundingClientRect().width : maxWidth))
            .attr("height", width * .75)
            .append('g')
            .attr('transform', "translate(" + ((width + margin.left + margin.right) / 2) + ", " + ((height + margin.top) / 2) + ")")
            .attr("class", "chart-gauge");

        var defs = svg.append("defs");

        var gradient = defs.append("linearGradient")
            .attr("id", "svgGradient1")
            .attr("x1", "0%")
            .attr("x2", "100%");
        gradient.append("stop")
            .attr("offset", "0%")
            .attr("stop-color", "#e6201c")
            .attr("stop-opacity", 1);
        gradient.append("stop")
            .attr("offset", "100%")
            .attr("stop-color", "#787878")
            .attr("stop-opacity", 1);

        var gradient2 = defs.append("linearGradient")
            .attr("id", "svgGradient2")
            .attr("x1", "0%")
            .attr("x2", "100%");

        gradient2.append("stop")
            .attr("offset", "0%")
            .attr("stop-color", "#787878")
            .attr("stop-opacity", 1);
        gradient2.append("stop")
            .attr("offset", "100%")
            .attr("stop-color", "#288462")
            .attr("stop-opacity", 1);

        let arcStartRad = percToRad(totalPercent);
        let arcEndRad = arcStartRad + percToRad(0.5);

        for (let _i = 1; _i <= numSections; ++_i) {
            let sectionIndx = _i;
            let arcStartRad = percToRad(totalPercent);
            let arcEndRad = arcStartRad + percToRad(sectionPerc);
            totalPercent += sectionPerc;

            // Leave margin for intermediate sections
            let startPadRad = sectionIndx === 1 ? padRad / 3 : padRad / 6;
            let endPadRad = sectionIndx === numSections ? padRad / 3 : padRad / 6;

            let arc = d3.arc()
                .outerRadius(radius - chartInset)
                .innerRadius(radius - chartInset - barWidth)
                .startAngle(arcStartRad + startPadRad)
                .endAngle(arcEndRad - endPadRad);

            svg.append('path')
                .attr('fill', "url(#svgGradient" + sectionIndx + ")")
                .attr('d', arc);
        }

        svg.append("text")
            .attr("class", "gauge-svg-text")
            .attr("x", -outerRadius + barWidth)
            .attr("y", barWidth / 2 + 5)
            .attr("dy", ".35em")
            .text("-100%");

        svg.append("text")
            .attr("class", "gauge-svg-text")
            .attr("x", (outerRadius - barWidth * 2) - 6)
            .attr("y", barWidth / 2 + 5)
            .attr("dy", ".35em")
            .text("100%");

        var Needle = (function () {
            var recalcPointerPos = function (perc) {
                var centerX, centerY, leftX, leftY, rightX, rightY, thetaRad, topX, topY;
                thetaRad = ((perc / 2) * Math.PI / 180) * 360;
                centerX = 0;
                centerY = 0;
                topX = centerX - this.len * Math.cos(thetaRad);
                topY = centerY - this.len * Math.sin(thetaRad);
                leftX = centerX - this.radius * Math.cos(thetaRad - Math.PI / 2);
                leftY = centerY - this.radius * Math.sin(thetaRad - Math.PI / 2);
                rightX = centerX - this.radius * Math.cos(thetaRad + Math.PI / 2);
                rightY = centerY - this.radius * Math.sin(thetaRad + Math.PI / 2);
                return "M " + leftX + " " + leftY + " L " + topX + " " + topY + " L " + rightX + " " + rightY;
            };

            function Needle(el) {
                this.el = el;
                this.len = width / 2.1;
                this.radius = this.len / 8;
            }

            Needle.prototype.render = function (perc) {
                var thetaRad = ((perc / 2) * Math.PI / 180) * 360;
                var centerX = 0;
                var centerY = 0;
                var topX = centerX - this.len * Math.cos(thetaRad);
                var topY = centerY - this.len * Math.sin(thetaRad);
                var textX = perc >= .5 ? topX + 5 : topX - 50;
                var textY = topY - this.len / 10;
                this.el.append('circle')
                    .attr('class', 'needle-center')
                    .attr('cx', 0)
                    .attr('cy', 0)
                    .attr('r', this.radius);
                this.el.append('path')
                    .attr('class', 'needle')
                    .attr('d', recalcPointerPos.call(this, perc));
                this.el.append("text")
                    .attr("class", "gauge-svg-text-large")
                    .attr("x", textX)
                    .attr("y", textY)
                    .attr("dy", ".35em")
                    .text(percentText);
                return this.el.append('circle')
                    .attr('class', 'needle-center-center')
                    .attr('cx', 0)
                    .attr('cy', 0)
                    .attr('r', this.radius / 2);
            };

            return Needle;
        })();

        let needle = new Needle(svg);
        needle.render(percent);
    }

    useEffect(() => {
        createChart();
        window.addEventListener("resize", updateChart);
        return () => {
            window.removeEventListener("resize", updateChart);
        };
    }, []);

    useEffect(() => {
        updateChart();
    });

    return <div ref={nodeRef}></div>;
};

export default Gauge;
