import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import classnames from 'classnames';
import { textEnglish, textPortuguese } from './i18n/Reports';
import Navbar from '../common/navbar/NavbarPLR';
import WelcomeFooter from '../menu/WelcomeFooter';
import styles from '../../styles/reports.module.css';
import StrategyHeader from '../common/strategyheader';
import ActivitiesCover from '../projects/img/activities-cover.png'
import '../strategy/Strategy.css';
import { API_URL } from '../../../config/api';

function Reports(props) {
  const [reports, setReports] = useState();
  const [pt, setPt] = useState(props.location.pathname.includes('/pt'));
  const [text, setText] = useState(!pt ? textEnglish : textPortuguese);

  async function fetchData() {
    await axios
        .get(`${API_URL}/reports`)
        .then((response) => {
            setReports(
                response.data.filter((e) =>
                    ![
                      'hahahah',
                      'Azzure board',
                      'fefe',
                      'grrgr',
                      'testpremiersoft',
                      'k.k.k.'
                    ].includes(e.title)
                )
            );
        })
        .catch((err) => {});
  }

  useEffect(() => {
    fetchData();
  }, []); 

  const handleLogout = () => {
    localStorage.removeItem('auth');
    props.history.push('/');
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div className="container-fluid unpadify">
        <Navbar
          handleLogin={handleLogout}
          handleLogout={handleLogout}
          auth={props.auth}
          isLoggedIn={props.loggedIn}
          checkIsLoggedIn={props.checkIsLoggedIn}
          onChangeLoginState={props.onChangeLoginState}
          {...props}
          currentPage="program"
        />
        <StrategyHeader
          title={pt ? "Atividades" : "Activities"}
          selectedTab={2}
          tabs={[
            { link: pt ? '/pt/portfolio' : '/portfolio', label: pt ? 'Alcance do portfólio' : 'Portfolio Footprint' },
            { link: pt ? '/pt/projects' : '/projects', label: pt ? 'Projetos' : 'Projects' },
            { link: pt ? '/pt/reports' : '/reports', label: pt ? 'Relatórios' : 'Reports' },
          ]}
          backgroundImage={ActivitiesCover}
          backgroundPosition="0px 68%"
        />
        <div>
          <div>         
            <div id={'strategy-container'}>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <h2>{pt ? 'Relatórios' : 'Reports'}</h2>
                {props.checkIsLoggedIn() && (
                  <>
                  <div style={{postion: 'relative', marginBottom: '32px'}}></div>
                  </>
                )}   
              </div>

              <div className={styles.reportsTextContentDivider}></div>
              <p style={{ color: '#4d4d4d' }}>{text.text_1}</p>
              <p>{text.text_2}</p>
            </div>
              
            <div id={'strategy-container'}>
              {!(reports == null) && (
              <div
                className={classnames('container', styles.reportCardsWrapper)}
              >
                {reports.filter(report => report.title !== 'k.k.k.').map((report, index) => {
                  return (
                    <div className={styles.reportCardWholeWrapper}>
                      <div className={styles.reportCardCategory}>
                        {pt ? report.category_pt : report.category}
                      </div>
                      <div className={classnames(styles.reportCardWrapper)}>
                        <div className={styles.reportCardImageWrapper} style={{
                          height: '300px',
                          width: '214px',
                          background: `url(https://brasilaberto.worldbank.org/img/${report.image_name})`,
                          backgroundSize: 'cover',
                        }}>
                        </div>
                        <div className={styles.reportCardContentWrapper}>
                          <div className={styles.reportCardTitle}>
                            {pt ? report.title_pt : report.title}
                          </div>
                          <a
                            id="loop_alt_button" 
                            href={report.url}
                            className={`lp__btn lp__btn_sm lp__btn_primary`}
                            target="_blank" rel="noopener noreferrer"
                          >
                            {pt ? 'Ver documento' : 'See report'}
                          </a>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              )}
            </div>
          </div>
        </div>

        <div style={{ height: '120px' }}></div>

        <WelcomeFooter {...props} />
      </div>
    </div>
  );
}

export default withRouter(Reports);
