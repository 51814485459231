import axios from "axios";
import {useHistory, withRouter} from "react-router-dom";
import React, {useEffect, useState} from "react";
import Navbar from "../common/navbar/NavbarPLR";
import StrategyHeader from "../common/strategyheader";

import '../editscreen/styles.css';
import Button from "../../components/Button";
import EditIcon from '../../../icons/edit-icon.svg.svg';
import ProjectModal from "../../components/ProjectModal";
import FooterEditScreen from "../../components/EditScreen/FooterEditScreen";
import { API_URL } from "../../../config/api";
import Checkbox from "../../components/Checkbox";
import AlertPopup from "../../components/AlertPopup";
import ConfirmDeleteModal from "../../components/ConfirmDeleteModal";

const textEnglish = {
    delete: 'Delete',
    deleteSucces: 'Project(s) deleted with success!',
    deleteError: 'Error on deleting project(s).',
    errorProjects: 'Error on trying to load projects.'
}

const textPortuguese = {
    delete: 'Excluir',
    deleteSucces: 'Projeto(s) deletados com sucesso!',
    deleteError: 'Erro ao deletar um ou mais projetos.',
    errorProjects: 'Erro ao listar os projetos.'
}

const ProjectsEditScreen = (props) => {
    const [text, setText] = useState(textEnglish);
    const [currentProjects, setCurrentProjects] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [maxPages, setMaxPages] = useState(1);
    const dollarUSLocale = Intl.NumberFormat('en-US');
    const [pt, setPt] = useState(false);
    const [projects, setProjects] = useState([]);
    const [projectsFilter, setProjectsFilter] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [selectedProject, setSelectedProject] = useState(null);
    const [projectsToDelete, setProjectsToDelete] = useState([]);
    const [openConfirmDeleteModal, setOpenConfirmDeleteModal] = useState(false);
    const [alertProps, setAlertProps] = useState(null);

    const handleLogout = () => {};

    const editProject = (project) => {
        setSelectedProject(project);
        setEditMode(true);
        setShowModal(true);
    }

    const addProject = () => {
        setEditMode(false);
        setShowModal(true);
    }

    const closeModal = () => {
        setSelectedProject(null);
        setEditMode(false);
        setShowModal(false);
        loadProjects();
    }

    const openAlertPopup = (props) => {
        setAlertProps(props);
    }

    const searchProject = (e) => {
        const searchText = e.target.value.toLowerCase();
        const searchResult = projects.filter((project) =>
            project.title.toLowerCase().includes(searchText) || project.id.toLowerCase().includes(searchText)
        );
        if(!searchText) return setProjectsFilter(null);
        setProjectsFilter(searchResult);
    }

    const loadProjects = async () => {
        window.scrollTo(0, 0);
        await axios
            .get(`${API_URL}/projects_crud`)
            .then((response) => {
                const result = response.data.filter(project => {
                    if (![project.title, project.title_pt].includes('testpremiersoft')) {
                        return project;
                    }
                }).map((item, index) => ({ defaultIndex: index + 1, ...item }));
                setProjects(result);
            })
            .catch((err) => {
                setAlertProps({
                    type: 'danger',
                    message: text.errorProjects,
                    duration: 3000
                });
            });
    }

    const handleClick = (page) => {
        setCurrentPage(page);
        setProjectsToDelete([]);
    };

    const handleSelectProject = (id) => {
        const alreadyIn = projectsToDelete.find(project => project === id);

        if (alreadyIn) {
            const newList = projectsToDelete.filter(value => id !== value);
            return setProjectsToDelete(newList);
        }

        return setProjectsToDelete([...projectsToDelete, id]);
    }

    const handleSelectAllProjects = () => {
        if (projectsToDelete.length === currentProjects.length) {
            return setProjectsToDelete([]);
        }

        const ids = currentProjects.map(({ id }) => id);
        setProjectsToDelete(ids);
    }

    const deleteProjects = async () => {
        try {
            await axios
                .put(`${API_URL}/projects-delete`, { projects: projectsToDelete });

            openAlertPopup({ 
                type: 'success', 
                message: text.deleteSucces, 
                duration: 3000 
            });

        } catch (error) {
            openAlertPopup({
                type: 'danger',
                message: text.deleteError,
                duration: 3000
            });
        }
    }

    const handleConfirmDeletion = async () => {
        setOpenConfirmDeleteModal(false);
        await deleteProjects();
        setProjectsToDelete([]);
        await loadProjects();
    };

    const renderPagination = () => {
        const pages = [];
        for (let i = 1; i <= maxPages; i++) {
            pages.push(
                <button
                    key={i}
                    className="pagination-button"
                    onClick={() => handleClick(i)}
                    disabled={currentPage === i}
                >
                    {i}
                </button>
            );
        }
        return (
            <div className="pagination-wrapper">
                <button
                    disabled={currentPage < 2}
                    className="pagination-button previous"
                    onClick={() => handleClick(currentPage-1)}
                >Anterior</button>
                {pages}
                <button
                    className="pagination-button next"
                    disabled={currentPage === maxPages}
                    onClick={() => handleClick(currentPage+1)}
                >Próximo</button>
            </div>
        );
    };

    useEffect(() => {
        const projectsOrFilter = projectsFilter?.length > 0 ? projectsFilter : projects;

        if (projectsOrFilter.length) {
            const startIndex = (currentPage - 1) * 10;
            const endIndex = startIndex + 10
            const result = projectsOrFilter.slice(startIndex, endIndex).map((item) => item);
            setCurrentProjects(result);
        }
    }, [maxPages, currentPage, projectsFilter, projects]);

    useEffect(() => {
        if (projects.length) {
            setMaxPages(Math.ceil(projects.length / 10));
        }
    }, [projects])

    useEffect(() => {
        if (props.location.pathname.substring(0, 4) === '/pt/') {
            setPt(true);
            setText(textPortuguese);
        }
        loadProjects();
    }, []);

    return (
        <div className="container-fluid unpadify">
            <Navbar
                hideSubBar={true}
                handleLogin={handleLogout}
                handleLogout={handleLogout}
                auth={props.auth}
                isLoggedIn={props.loggedIn}
                checkIsLoggedIn={props.checkIsLoggedIn}
                onChangeLoginState={props.onChangeLoginState}
                currentPage={'edit-screen'}
                {...props}
            />
            <StrategyHeader
                selectedTab={0}
                tabs={[
                    { link: pt ? '/pt/edit-screen/projects' : '/edit-screen/projects', label: pt ? 'Projetos' : 'Projects' },
                    { link: pt ? '/pt/edit-screen/reports' : '/edit-screen/reports', label: pt ? 'Relatórios' : 'Reports' },
                    { link: pt ? '/pt/edit-screen/indicators' : '/edit-screen/indicators', label: pt ? 'Indicadores' : 'Indicators' },
                ]}
            />
            <div id="projects-container">
                <div className="title-row">
                    <h2>{pt ? "Projetos" : "Projects"}</h2>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '30px' }}>
                        {projectsToDelete.length > 0 && (
                            <button
                                className="delete-button"
                                onClick={() => setOpenConfirmDeleteModal(true)}
                            >
                                {text.delete}
                            </button>
                        )}
                        <Button 
                            onClick={addProject}
                            style={{ width: '177px', height: '49px' }} 
                        >
                            Incluir projeto
                        </Button>
                    </div>
                    {showModal && (
                        <ProjectModal
                            showModal={true}
                            editMode={editMode}
                            selectedProject={selectedProject}
                            closeModal={closeModal}
                            {...props}
                        />
                    )}
                    {openConfirmDeleteModal && (
                        <ConfirmDeleteModal
                            showModal={true}
                            onDelete={handleConfirmDeletion}
                            closeModal={() => setOpenConfirmDeleteModal(false)}
                            {...props}
                        />
                    )}
                    {alertProps && (
                        <AlertPopup onClose={() => setAlertProps(null)} {...alertProps}/>
                    )}
                </div>
                <div className="divider"></div>
                <div className="edit-screen-content">
                    <div style={{ position: 'relative', marginBottom: '16px' }}>
                        <div style={{ position: 'absolute', top: '9px', left: '10px' }}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10 17C13.866 17 17 13.866 17 10C17 6.13401 13.866 3 10 3C6.13401 3 3 6.13401 3 10C3 13.866 6.13401 17 10 17Z" stroke="#CCCCCC" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M15 15L21 21" stroke="#CCCCCC" strokeWidth="2" strokeLinecap="square" strokeLinejoin="round"/>
                            </svg>
                        </div>
                        <input
                            type="text"
                            onChange={searchProject}
                            className="projects-search-filter"
                            placeholder={pt ? "Busca por ID, # e Nome do projeto" : "Search by ID, # and Name of the project"}
                        />
                    </div>

                    <table className="edit-screen-table">
                        <thead>
                            <tr>
                                <td>
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        <Checkbox
                                            onChange={handleSelectAllProjects}
                                            value={projectsToDelete.length === currentProjects.length}
                                        ></Checkbox>
                                    </div>
                                </td>
                                <td>ID</td>
                                <td>#</td>
                                <td>Nome do projeto</td>
                                <td>Área de foco 1</td>
                                <td>Área de foco 2</td>
                                <td>Área de foco 3</td>
                                <td>Estados</td>
                                <td>Editar</td>
                            </tr>
                        </thead>
                        <tbody>
                            {projectsFilter && projectsFilter.length === 0 ? (
                                    <tr>
                                        <td colSpan={9}>Nenhum resultado encontrado.</td>
                                    </tr>
                                ) : (
                                    currentProjects.length > 0 ? (
                                        currentProjects.map((project) => {
                                            return (
                                                <tr key={project.id}>
                                                    <td>
                                                        <Checkbox
                                                            onChange={() => handleSelectProject(project.id)}
                                                            value={projectsToDelete.includes(project.id)}
                                                        ></Checkbox>
                                                    </td>
                                                    <td>{project.id}</td>
                                                    <td>{project.defaultIndex}</td>
                                                    <td className="title-column">{project.title}</td>
                                                    <td>U$ {dollarUSLocale.format(project.focus_area_1_value)}</td>
                                                    <td>U$ {dollarUSLocale.format(project.focus_area_2_value)}</td>
                                                    <td>U$ {dollarUSLocale.format(project.focus_area_3_value)}</td>
                                                    <td className="state-column">{project.states?.join(', ') || '-'}</td>
                                                    <td>
                                                        <Button
                                                            onClick={() => editProject(project)}
                                                            style={{
                                                                height: '32px',
                                                                width: '32px',
                                                                padding: '0'
                                                            }}
                                                        >
                                                            <img
                                                                src={EditIcon}
                                                                alt="Edit"
                                                                style={{ height: '16px', width: '16px' }}
                                                            />
                                                        </Button>
                                                    </td>
                                                </tr>
                                            );
                                        })
                                    ) : (
                                        <tr></tr>
                                    )
                                )}
                        </tbody>
                    </table>
                    {!projectsFilter || projectsFilter.length > 0 ? renderPagination() : <></>}
                </div>
            </div>

            <FooterEditScreen />
        </div>
    );
}

export default withRouter(ProjectsEditScreen);
