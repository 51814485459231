import React, { Component } from 'react';
import { Nav, NavItem, Tab } from 'react-bootstrap';
import './GraphTabs.css';

import _ from 'lodash';

class GraphTabs extends Component {
	constructor(props) {
		super(props);

		let activeKey = this.props.activeKey ? this.props.activeKey : "first";

		let tab1Content = "";
		let tab2Content = "";

		if (activeKey === "first") {
			tab1Content = this.props.tab1Content;
		} else if (activeKey === "second") {
			tab2Content = this.props.tab2Content;
		}

		this.state = {
			activeKey,
			tab1Content,
			tab2Content
		};

		this.changeTabContent = this.changeTabContent.bind(this);
	}
	
	shouldComponentUpdate(nextProps, nextState) {
		if (_.isEqual(this.props, nextProps) && _.isEqual(this.state, nextState)) {
			return false;
		}
		return true;
	}

	changeTabContent(tab) {
		if (tab === "first") {
			if (this.state.tab1Content === "") {
				this.setState({
					tab1Content: this.props.tab1Content
				});
			}
		} else if (tab === "second") {
			if (this.state.tab2Content === "") {
				this.setState({
					tab2Content: this.props.tab2Content
				});
			}
		}
	}

	render() {
		return (
			<Tab.Container id="graphTabContainer" defaultActiveKey={this.state.activeKey} className="graphTabs">
				<div>
					<Nav bsStyle="tabs" className="text-right">
						<li className="graph-tabs-text">{this.props.pt ? "Visualizar" : "View"}:</li>
			        	<NavItem eventKey="first">
			            	<h5 className="tabName">{this.props.tab1Name}</h5>
			        	</NavItem>
			        	<NavItem eventKey="second">
			            	<h5 className="tabName">{this.props.tab2Name}</h5>
			        	</NavItem>
			        </Nav>
			        <Tab.Content animation>
			        	<Tab.Pane eventKey="first" onEnter={() => this.changeTabContent("first")}>
			            	{this.state.tab1Content}
			        	</Tab.Pane>
			        	<Tab.Pane eventKey="second" onEnter={() => this.changeTabContent("second")}>
			            	{this.state.tab2Content}
			          	</Tab.Pane>
			        </Tab.Content>
		        </div>
		    </Tab.Container>
		);
	}
}

export default GraphTabs;
