import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import classNames from 'classnames';
import dayjs from 'dayjs';
import mapboxgl from 'mapbox-gl';
import '../projects/Projects.css';
import '../common/map/Map.css';

import Accordeon from '../../components/Accordeon';
import Card from '../../components/Card';
import FocusAreaCard from '../../components/FocusAreaCard';
import ProgressBar from '../../components/ProgressBar';
import GlobalStyles from '../../styles/global';
import Timeline from '../../components/Timeline';

import ReactLoading from 'react-loading';

import WelcomeFooter from '../menu/WelcomeFooter';
import Navbar from '../common/navbar/NavbarPLR';
import ShowMoreText from '../../components/ShowMoreText';
import HeaderImage from '../projects/img/projects-banner.png';
import IFCHeaderImage from '../projects/img/banner-ifc-1.jpg';
import IBRDHeaderImage from '../projects/img/banner-ibrd-8.png';

import LineGraph from '../common/graphs/LineGraph';
import Gauge from '../common/graphs/Gauge';

import ChevronRightIcon from '../about/img/chevron-right.svg'

import 'react-toggle/style.css';

import styles from '../../styles/projectDetails.module.css';

import abbreviateFromMillions from '../common/utilities';
import ProgramMap from '../common/map/ProgramMap';
import classnames from 'classnames';
import StrategyHeader from '../common/strategyheader';
import ActivitiesCover from '../projects/img/activities-cover.png'
import { API_URL } from '../../../config/api';

mapboxgl.accessToken =
  'pk.eyJ1IjoiZ3NkcG0iLCJhIjoiY2thMmw0djZoMDBoajNnanZjdmo1YmJyZCJ9.dFE9IA22oAMiRu7gA6-JXQ';

const textEnglish = {
  speedometer:
  'The speedometer shows how the indicator has progressed toward its goal since the baseline was established.',
  baseline: 'Baseline',
  progress: 'Progress',
  target: 'Target',
  login: 'Login',
  logout: 'Log Out',
  med: { ifc: 'IFC Projects', ibrd: 'IBRD Projects' },
  filters_title: 'Filter Projects by Focus Area:',
  filters_organization: 'Filter Projects by organization',
  focus_area_1_icon: 'loop loop-financial-5',
  focus_area_2_icon: 'loop loop-financial-3',
  focus_area_3_icon: 'loop loop-biodiversity',
  focus_label_1: 'Focus Area 1',
  focus_title_1:
    'Fiscal Consolidation and Government Effectiveness for Sustainable, Inclusive and Efficient Service Delivery',
  focus_description_1: [
    [
      'Restoring the sustainability of public finances and strengthening the institutional and legal framework for public financial management at all levels of government is critical to expand Brazil’s economic and social gains. The fiscal rule adopted in December 2016 provides the framework for fiscal consolidation but not the mechanisms for determining how expenditures will be cut.',
    ],
  ],
  focus_label_2: 'Focus Area 2',
  focus_title_2: 'Private Sector Investment and Productive Growth',
  focus_description_2: [
    [
      `Unlocking private investment and productivity growth, and better infrastructure provision, are critical to raising employment and incomes in Brazil and highly complementary to fiscal adjustment. Extending the social gains of the past decade now requires increasing market competition to foster productive private sector investment and the creation of good jobs. Past policies aimed at correcting market failures have ended up creating additional distortions and preferential treatment for special interests. Public efforts to catalyze greater investment through flagship programs and the state development banks have had limited results but entailed high fiscal costs.`,
    ],
    [
      `The World Bank Group (WBG) will support the government in seeking to increase market competition and work closely with the national and state development banks to leverage WBG funding and improve the quality and efficiency of overall spending in the infrastructure.`,
    ],
    [
      `Three of the five WBG institutions will be instrumental in achieving this goal: the International Bank for Reconstruction and Development (IBRD) will contribute its experience in supporting improvement in regulatory frameworks; the International Finance Corporation (IFC) will bring its expertise in Public-Private Partnership (PPP) project financing and structuring; the Multilateral Investment Guarantee Agency (MIGA) can offer non-commercial and credit enhancement guarantees that would facilitate private sector participation in infrastructure investments.`,
    ],
  ],
  focus_label_3: 'Focus Area 3',
  focus_title_3: 'Inclusive and Sustainable Development',
  focus_description_3: [
    [
      `Brazil is rich in natural resources, which are an important source of income and a critical input for the country’s economic development. Brazil’s huge reserves of tropical forests and fresh water are especially important for some of the poor, for whom they constitute a significant share of their wealth. Agriculture is the single most important source of income for the rural poor, and makes Brazil a global leader in meat, soy, and other food exports.`,
    ],
    [
      `The current Country Partnership Framework (CPF) is based on the principle that environment and development objectives need not be in conflict if Brazil makes adequate use of existing technologies such as the intensification of production systems, including among smallholder farmers. Hence, the World Bank Group (WBG) will emphasize an integrated approach for promoting economic, environmental, and social sustainability, including resolution of potential conflicts through better governance, as shown in the following four objectives.`,
    ],
  ],
  readmore: 'Read More',
  viewimpact: 'View Impact Evaluation',
  add: 'Add Data',
  add_project: 'Add Project',
  status: 'Status',
  date_approved: 'Date Approved',
  criteria: 'Criteria: ',
  challenge: 'Complexity of the Development Challenge',
  innovation: 'Innovation / Scalability',
  public: 'Provision of Global Public Goods',
  institutions: 'Strengthening Institutions',
  finance: 'Leveraging Private Financing',
  covid_related: 'COVID-19 Related',
  timeline_keydates_ibrd: (
    projectName
  ) => `Once all project details are negotiated and accepted by the government and the World Bank, the project team prepares the Project Appraisal Document  
		(for investment project financing) or the Program Document (for development policy financing), along with other financial and legal documents, for 
		submission to the Bank's Board of Executive Directors for consideration and approval. When funding approval is obtained, conditions for effectiveness are met, 
		and the legal documents are accepted and signed, the implementation phase begins.`,
  timeline_keydates_ifc:
     `All projects are submitted to IFC's Board of Directors for consideration and approval through regular or streamlined procedures. 
     "Streamlined" means that the members of the Board review the documents but don't meet to discuss the project. 
     This option is available to low-risk projects of a small enough size. Certain small projects can be approved by IFC management
     under delegated authority. The Board demands that each investment have economic, financial, and development value and reflects 
     IFC's commitment to sustainability. Key moments in the life of a project are: board approval, IFC approval, signing date, 
     and invested date` ,   
  key_dates:
    ' ',
  lending_instrument_after: `We finance public projects to build physical and social infrastructure, and develop institutional capacity. We also finance government programs to support achievement of their objectives, and support policy and institutional reforms of national and subnational governments by providing budget financing and global expertise. The World Bank offers different lending instruments to meet each of these goals.`,
  summary_status_wb_financing_after: '',
  summary_status_wb_financing_before: (projectName) =>
    `The Bank lends money to middle-income countries at interest rates lower than the rates on loans from commercial banks. In addition, the Bank lends money at no interest to the poorest developing countries, those that often cannot find other sources of loans. Countries that borrow from the Bank also have a much longer period to repay their loans than commercial banks allow and don't have to start repaying for several years. Below, financing details for ${projectName} is shown:`,
  more_ipf:
    'Investment Project Financing provides financing to governments for activities that create the physical/social infrastructure necessary to reduce poverty and create sustainable development.',
  more_dpf:
    'Program-for-Results links disbursement of funds directly to the delivery of defined results, helping countries improve the design and implementation of their own development programs and achieve lasting results by strengthening institutions and building capacity.',
  more_pfor:
    'Program-for-Results links disbursement of funds directly to the delivery of defined results, helping countries improve the design and implementation of their own development programs and achieve lasting results by strengthening institutions and building capacity.',
  borrower_details_section:
    'The Bank works in close collaboration with the borrower and implementation agency to ensure that funds are used only for the purpose for which they were granted. Implementation support includes providing training and advice to the Borrower.',
  implementation_arrangements:
    `A Trust Fund is a financing arrangement set up with contributions from one or more donor(s), and in some
    cases from the World Bank Group. A Trust Fund can be country-specific, regional, or global in its
    geographic scope, and it can be free-standing or programmatic. In fulfilling its responsibilities, the World Bank as Trustee complies with all sanctions applicable to World Bank transactions.`,    
  funding_source: 'Source of Fund',
  borrower_details_section_ifc:
    `We invest exclusively in for-profit projects in developing countries.`,
  finances_before: (projectName) =>
    `The Bank lends only a portion of the money needed for a project. The borrowing government must get the rest from other sources or use its own funds. Below, the financing plan for ${projectName} is displayed:`,
  finances_before_ifc: (projectName) =>
    `Our financial products enable companies to manage risk and expand their access to foreign and domestic capital markets. IFC operates on a commercial basis. `,
  funding_details: (projectName) => `Trust funds are commonly managed under a limited series of Umbrella Programs. Below, trust fund financing amount for ${projectName} is displayed:`,    
  enviromental_before:
    'The Bank undertakes environmental screening of each proposed project to determine the appropriate extent and type of Environmental Assessment. The Bank classifies the proposed project into one of four categories, depending on the type, location, sensitivity, and scale of the project and the nature and magnitude of its potential environmental impacts.',
  env_cat_A:
    "Category A: A proposed project is classified as Category A if it is likely to have significant adverse environmental impacts that are sensitive,12 diverse, or unprecedented. These impacts may affect an area broader than the sites or facilities subject to physical works. EA for a Category A project examines the project's potential negative and positive environmental impacts, compares them with those of feasible alternatives (including the 'without project' situation), and recommends any measures needed to prevent, minimize, mitigate, or compensate for adverse impacts and improve environmental performance. For a Category A project, the borrower is responsible for preparing a report, normally an EIA (or a suitably comprehensive regional or sectoral EA) that includes, as necessary, elements of the other instruments.",
  env_cat_B:
    "Category B: A proposed project is classified as Category B if its potential adverse environmental impacts on human populations or environmentally important areas--including wetlands, forests, grasslands, and other natural habitats--are less adverse than those of Category A projects. These impacts are site-specific; few if any of them are irreversible; and in most cases mitigatory measures can be designed more readily than for Category A projects. The scope of EA for a Category B project may vary from project to project, but it is narrower than that of Category A EA. Like Category A EA, it examines the project's potential negative and positive environmental impacts and recommends any measures needed to prevent, minimize, mitigate, or compensate for adverse impacts and improve environmental performance. The findings and results of Category B EA are described in the project documentation (Project Appraisal Document and Project Information Document). ",
  env_cat_C:
    'Category C: A proposed project is classified as Category C if it is likely to have minimal or no adverse environmental impacts. Beyond screening, no further EA action is required for a Category C project. ',
  env_cat_FI:
    'Category FI: A proposed project is classified as Category FI if it involves investment of Bank funds through a financial intermediary, in subprojects that may result in adverse environmental impacts.',
  title_section_1: 'Program Development Objective (PDO)',
  title_section_2: 'Abstract',
  title_section_3: 'Key Dates',
  title_section_6_ibrd: 'Lending Instrument',
  title_section_7_ibrd: 'Instrument type',
  title_section_8_ibrd: 'Disbursement status',
  title_section_8_card_1_ibrd: 'desembolso',
  title_section_8_card_2_ibrd: 'repayment',
  title_section_4_ibrd: 'Borrower Details',
  title_section_4_loan: 'Implementation arrangements',
  title_section_4_ifc: 'Project Information',
  title_section_4_card_1_ibrd: 'Borrower',
  title_section_4_card_1_loan: 'Grant Recipient',
  title_section_4_card_1_ifc: 'Company Name',
  title_section_4_card_2_ibrd: 'Implementing Agency',
  title_section_4_card_2_loan: 'coordination and supervision',
  title_section_4_card_2_ifc: 'Project Name',
  title_section_5_ibrd: 'Financing',
  title_section_5_loan: 'Project resources',
  title_section_5_ifc: 'Investment',
  title_section_5_card_1_ibrd : 'IBRD',
  title_section_5_card_2_ibrd:  'Borrower',  
  title_section_5_card_3_ibrd: 'Total Project Cost',
  title_section_5_card_1_loan: 'Trust Fund value',
  title_section_5_card_1_ifc: 'Investment for Guarantee',
  title_section_5_card_2_ifc: 'Investment for Loan',
  title_section_5_card_3_ifc: 'Investment for Equity',
  title_section_5_card_4_ifc: 'Total Investment',
  timeline_board_approval: 'Board approval',
  timeline_signing: 'Signing',
  timeline_effectiveness: 'effectiveness',
  timeline_first_repayment_date: 'first repayment date',
  timeline_closing: 'closing',
  timeline_last_repayment_date: 'last repayment date',
  timeline_ifc_board_approval:'Board approval',
  timeline_ifc_approval_date: 'IFC approval date',
  timeline_ifc_signed_date: 'IFC signed date',
  timeline_ifc_invested_date: 'IFC invested date',
  more_about_other_instruments:'More about other instruments',
  env_ifc_A:'Category A: Business activities with potential significant adverse environmental or social risks and/or impacts that are diverse, irreversible, or unprecedented.',
  env_ifc_B:'Category B: Business activities with potential limited adverse environmental or social risks and/or impacts that are few in number, generally site-specific, largely reversible, and readily addressed through mitigation measures',
  env_ifc_C:'Category C: Business activities with minimal or no adverse environmental or social risks and/or impacts.',
  env_ifc_FI:'Business activities involving investments in FIs or through delivery mechanisms involving financial intermediation',
  env_ifc_FI_1:'FI-1: when an FI’s existing or proposed portfolio includes, or is expected to include, substantial financial exposure to business activities with potential significant adverse environmental or social risks or impacts that are diverse, irreversible, or unprecedented.',
  env_ifc_FI_2:'FI-2: when an FI’s existing or proposed portfolio is comprised of, or is expected to be comprised of, business activities that have potential limited adverse environmental or social risks or impacts that are few in number, generally site-specific, largely reversible, and readily addressed through mitigation measures; or includes a very limited number of business activities with potential significant adverse environmental or social risks or impacts that are diverse, irreversible, or unprecedented.',
  env_ifc_FI_3:'FI-3: when an FI’s existing or proposed portfolio includes financial exposure to business activities that predominantly have minimal or no adverse environmental or social impacts.',
  env_assessment: 'Environmental and social sustainability assessment',
  environmental_category_ifc:'Environmental Category',
  env_after_titleIFC: (projectName) => `IFC strives for positive development outcomes in the activities it supports in developing countries. 
    IFC believes that an important component of achieving positive development outcomes is the environmental and social sustainability of these activities, which IFC pursues and expects to achieve through the application of its Policy on Environmental and 
    Social Sustainability and a comprehensive set of environmental and social Performance Standards. Below assessment for ${projectName} is displayed:`,
  env_ifc_category: (category) => `more about this category ${category}`,
  env_ifc_categories: `more about other categories`,
  env_more_about: `More about IPF`,
  env_disbursement_rate: `Disbursement Rate`,
  env_disbursed: ` disbursed`,
  back_all_projects:'Back to all projects',
  project_id: 'Project ID',
};

const textPortuguese = {
  speedometer:
    'O velocímetro mostra como o indicador progrediu em direção à sua meta desde que a linha de base foi estabelecida.',
  baseline: 'Linha de base',
  progress: 'Progresso',
  target: 'Meta',
  login: 'Login',
  logout: 'Log Out',
  med: { ifc: 'Projetos IFC', ibrd: 'Projetos BIRD' },
  filters_title: 'Filtrar projetos por área de foco',
  filters_organization: 'filters_organization',
  focus_icon_1: 'Consolidação Fiscal Icon',
  focus_label_1: 'Área de Foco 1',
  focus_title_1:
    'Consolidação fiscal e eficácia do governo para prestação de serviços sustentável, inclusiva e eficiente',
  focus_icon_2: 'Investimento do Setor Privado Icon',
  focus_label_2: 'Área de Foco 2',
  focus_title_2:
    'Consolidação fiscal e eficácia do governo para prestação de serviços sustentável, inclusiva e eficiente',
  focus_icon_3: 'Desenvolvimento Sustentável e Inclusivo Icon',
  focus_label_3: 'Área de Foco 3',
  focus_title_3: 'Desenvolvimento Sustentável e Inclusivo',
  readmore: 'Leia mais',
  viewimpact: 'viewimpact',
  add: 'add',
  add_project: 'add_project',
  status: 'Status',
  date_approved: 'Data de aprovação',
  criteria: 'Critério: ',
  challenge: 'complexidade do desafio de desenvolvimento',
  innovation: 'inovação / escalabilidade',
  public: 'fornecimento de bens públicos globais',
  institutions: 'fortalecimento',
  finance: 'alavancagem do financiamento privado',
  covid_related: 'covid_related',
  focus_description_1: [
    [
      `Para ampliar os ganhos sociais e econômicos do país, é fundamental restaurar a sustentabilidade das finanças públicas e fortalecer o marco institucional e jurídico que regula a gestão financeira em todas as esferas de governo. A regra fiscal adotada em dezembro de 2016 delineia a estrutura da consolidação fiscal, mas não os mecanismos que definem como as despesas serão cortadas.
    O controle do nível de gastos públicos, a expansão do acesso aos serviços públicos e o aumento da qualidade dos mesmos vão exigir melhorias na eficiência e na eficácia dos gastos em todo o governo. Um foco central dos esforços do Banco será demonstrar como realizar ajustes fiscais sem prejudicar os pobres.
    `,
    ],
  ],
  focus_description_2: [
    [
      `Para ampliar os ganhos sociais e econômicos do país, é fundamental restaurar a sustentabilidade das finanças públicas e fortalecer o marco institucional e jurídico que regula a gestão financeira em todas as esferas de governo. A regra fiscal adotada em dezembro de 2016 delineia a estrutura da consolidação fiscal, mas não os mecanismos que definem como as despesas serão cortadas.
    O controle do nível de gastos públicos, a expansão do acesso aos serviços públicos e o aumento da qualidade dos mesmos vão exigir melhorias na eficiência e na eficácia dos gastos em todo o governo. Um foco central dos esforços do Banco será demonstrar como realizar ajustes fiscais sem prejudicar os pobres.
    `,
    ],
  ],
  focus_description_3: [
    [
      `O Brasil é rico em recursos naturais, que são uma importante fonte de renda e um insumo fundamental para o desenvolvimento econômico do país. As enormes reservas de floresta tropical e água doce do Brasil são particularmente importantes para algumas comunidades pobres, constituindo parte substancial de sua riqueza. A agricultura é a fonte de renda mais importante para a população rural pobre e faz do Brasil um líder global em exportações de carne, soja e outros alimentos.
    A atual Estratégia de Parceria com o Brasil (CPF) baseia-se no princípio de que os objetivos de meio ambiente e de desenvolvimento não precisam entrar em conflito se o Brasil fizer uso adequado das tecnologias existentes, como a intensificação dos sistemas de produção, inclusive entre os pequenos agricultores. O Grupo Banco Mundial (GBM), portanto, enfatizará uma abordagem integrada para promover a sustentabilidade econômica, ambiental e social, incluindo a resolução de potenciais conflitos por meio de melhor governança, conforme ilustrado pelos quatro objetivos a seguir.`,
    ],
  ],
  timeline_keydates_ibrd: (
    projectName
  ) => `Uma vez que todos os detalhes do projeto são negociados e aceitos pelo governo e pelo Banco Mundial, a equipe do projeto prepara o 
        Documento de Avaliação do Projeto (para financiamento do projeto de investimento) ou o Documento do Programa (para financiamento da política de desenvolvimento), 
        junto com outros documentos financeiros e legais, para submissão à Diretoria Executiva do Banco para consideração e aprovação.
        Obtida a aprovação do financiamento, atendidas as condições de efetivação e aceitos e assinados os documentos legais, dá-se início à fase de implantação.`,
timeline_keydates_ifc: `timeline_keydates_ifc` ,   
key_dates:
    'key_dates',
lending_instrument_after: 
    `Financiamos projetos públicos para construir isfraestrutura física e social e desenvolver capacidade institucional. 
    Também financiamos programas governamentais para apoiar governos a atingir seus objetivos, além de apoiar reformas políticas e 
    institucionais de governos nacionais e subnacionais, fornecendo financiamento orçamentário e expertise global. 
    O Banco Mundial oferece diferentes instrumentos de empréstimo para atender a cada um desses objetivos.`,
  summary_status_wb_financing_after: '',
  summary_status_wb_financing_before: (projectName) =>
    `O Banco Mundial empresta dinheiro a países de renda média a taxas de juros mais baixas do que as taxas de 
    empréstimos de bancos comerciais. Além disso, o Banco empresta dinheiro sem juros aos países mais pobres, 
    aqueles que muitas vezes não conseguem encontrar outras fontes de empréstimos. Os países que fazem uso dos 
    financiamentos ofertados pelo Banco Mundial também têm um período muito mais longo para pagar seus empréstimos 
    do que teriam junto aos bancos comerciais. Na maioria dos casos, o mutuário não precisa começar a pagar seus 
    empréstimos por vários anos. Abaixo, os detalhes de financiamento para  ${projectName} são mostrados:`,
  more_ipf:
    'O financiamento para projetos de investimento (IPF) fornece financiamento aos governos para atividades que constróem infraestrutura física / social necessária para reduzir a pobreza e criar um desenvolvimento sustentável.',
  more_dpf:
    'O Financiamento de Políticas de Desenvolvimento fornece apoio orçamentário aos governos ou uma subdivisão política para um programa de políticas e ações institucionais para ajudar a alcançar o crescimento sustentável e compartilhado e a redução da pobreza.',
  more_pfor:
    'O financiamento através do financiamento de Programa para Resultados (PforR) vincula o desembolso de fundos diretamente à entrega dos resultados definidos, ajudando os países a melhorar o desenho e a implementação de seus próprios programas de desenvolvimento e a alcançar resultados duradouros por meio do fortalecimento das instituições e da capacitação.',
  borrower_details_section:
    'O Banco Mundial trabalha em estreita colaboração com o mutuário e a agência de implementação para garantir que os fundos sejam usados apenas para os fins para os quais foram concedidos. O apoio à implementação inclui o fornecimento de treinamento e apoio técnico ao Mutuário.',
  funding_source: 'Fonte do recurso',
  borrower_details_section_ifc:
    `A IFC opera em uma base comercial. Investimos exclusivamente em projetos com fins lucrativos em países em desenvolvimento.`,
  implementation_arrangements: `Doações e Fundos Fiduciários são acordos de financiamento estabelecidos com contribuições de um ou mais doadores, 
      e em alguns casos com contribuições do Grupo Banco Mundial. Doações e Fundos Fiduciários podem ser específico para um país, 
      para uma região ou podem ser globais em seu âmbito geográfico. No cumprimento de suas responsabilidades, o Banco Mundial, 
      como administrador de doações e fundos fiduciários, cumpre todas as sanções aplicáveis às transações do Banco Mundial.`,
  finances_before: (projectName) =>
    `Na maioria dos casos, o Banco empresta apenas uma parte do dinheiro necessário para um projeto. O mutuário deve obter o restante de outras fontes ou usar seus próprios fundos.
    Abaixo, o plano de financiamento para ${projectName} é exibido:`,
  finances_before_ifc: (projectName) =>
    `Nossos produtos financeiros permitem que as empresas gerenciem riscos e expandam seu acesso aos mercados de capitais estrangeiros e domésticos. `,
  funding_details: (projectName) => `As doações e fundos fiduciários são comumente administrados sob programas guarda-chuva. Abaixo, o valor para ${projectName} é exibido:`,
  enviromental_before:
    'The Bank undertakes environmental screening of each proposed project to determine the appropriate extent and type of Environmental Assessment. The Bank classifies the proposed project into one of four categories, depending on the type, location, sensitivity, and scale of the project and the nature and magnitude of its potential environmental impacts.',
  env_cat_A:'env_cat_A',
  env_cat_B:'env_cat_B',
  env_cat_C:'env_cat_C',
  env_cat_FI:'env_cat_FI',
  title_section_1: 'Objetivo de Desenvolvimento do Programa ',
  title_section_2: 'Sumário',
  title_section_3: 'Datas Relevantes',
  title_section_7_ibrd : 'Tipo de instrumento',
  title_section_8_ibrd : 'Status do desembolso',
  title_section_8_card_1_ibrd: 'desembolso',
  title_section_8_card_2_ibrd: 'amortização',
  title_section_6_ibrd: 'Instrumento de financiamento',
  title_section_4_ibrd: 'Informações do Mutuário',
  title_section_4_loan: 'Estrutura de implementação',
  title_section_4_ifc: 'Informações do Projeto',
  title_section_4_card_1_ibrd: 'Mutuário',
  title_section_4_card_1_loan: 'Recebedor dos recursos',
  title_section_4_card_1_ifc: 'companhia',
  title_section_4_card_2_ibrd: 'Agência de implementação',
  title_section_4_card_2_loan: 'Coordenação e supervisão',
  title_section_4_card_2_ifc: 'nome do projeto',
  title_section_5_ibrd: 'Financiamento',
  title_section_5_loan: 'Recursos para o projeto',
  title_section_5_ifc: 'Investimento',
  title_section_5_card_1_ibrd: 'BIRD',
  title_section_5_card_2_ibrd: 'Mutuário',
  title_section_5_card_3_ibrd: 'Custo total do projeto',
  title_section_5_card_1_loan: 'Valor da doação',
  title_section_5_card_1_ifc: 'Investimentos em garantias',
  title_section_5_card_2_ifc: 'Empréstimos e Financiamentos',
  title_section_5_card_3_ifc: 'Investimentos em participação',
  title_section_5_card_4_ifc: 'Investimento total',
  timeline_board_approval: 'Aprovação do conselho',
  timeline_signing: 'assinatura',
  timeline_effectiveness: 'efetividade',
  timeline_first_repayment_date: 'primeira amortização',
  timeline_closing: 'fechamento',
  timeline_last_repayment_date: 'última amortização'    ,
  timeline_ifc_board_approval:'Aprovação da Diretoria executiva',
  timeline_ifc_approval_date: 'aprovação pela IFC',
  timeline_ifc_signed_date: 'assinatura',
  timeline_ifc_invested_date: 'data de investimento',
  more_about_other_instruments:'Mais sobre outros instrumentos',
  env_ifc_A:'Categoria A: Atividades comerciais com potenciais riscos ambientais ou sociais adversos e / ou impactos diversos, irreversíveis ou sem precedentes.',
  env_ifc_B:'Categoria B: atividades de negócios com potenciais riscos e / ou impactos ambientais adversos limitados e / ou sociais que são poucos em número, geralmente específicos do local, amplamente reversíveis e prontamente resolvidos por meio de medidas de mitigação',
  env_ifc_C:'Categoria C: Atividades de negócios com mínimo ou nenhum risco ambiental ou social adverso e / ou impactos.',
  env_ifc_FI:'Atividades envolvendo investimentos em Instituições Financeiras ou através de outras operações envolvendo intermediação financeira.',
  env_ifc_FI_1:'FI-1: quando o portfólio existente ou proposto de uma FI inclui, ou se espera que inclua, exposição financeira substancial a atividades de negócios com potenciais riscos ambientais ou sociais adversos significativos ou impactos que são diversos, irreversíveis ou sem precedentes.',
  env_ifc_FI_2:'FI-2: quando o portfólio existente ou proposto de um FI é composto por, ou espera-se que seja composto por, atividades de negócios que têm potenciais riscos ambientais ou sociais adversos limitados ou impactos que são poucos em número, geralmente específicos do local, amplamente reversíveis, e prontamente tratada por meio de medidas de mitigação; ou inclui um número muito limitado de atividades de negócios com potenciais riscos ou impactos ambientais ou sociais adversos significativos que são diversos, irreversíveis ou sem precedentes.',
  env_ifc_FI_3:'FI-3: quando o portfólio existente ou proposto de um FI inclui exposição financeira a atividades de negócios que têm, predominantemente, impactos ambientais ou sociais negativos mínimos ou nenhum.',
  env_assessment: 'Avaliação de sustentabilidade Ambiental e social',
  environmental_category_ifc:'Categoria' ,
  env_after_titleIFC: (projectName) => `A IFC busca obter resultados de desenvolvimento positivos nas atividades que apóia. 
    A IFC acredita que um componente importante para alcançar resultados positivos de desenvolvimento é a sustentabilidade ambiental e 
    social dos projetos financiados pela instituição. A IFC busca alcançar esses resultados
     por meio da aplicação de sua Política de Sustentabilidade Ambiental e Social e de um conjunto abrangente de Padrões 
     de Desempenho Ambiental e Social. A avaliação abaixo para ${projectName} é exibida:`  ,
  env_ifc_category:  (category) =>  `mais sobre essa categoria ${category}`,
  env_ifc_categories: `mais sobre outras categorias`, 
  env_more_about: `Mais sobre IPF`,    
  env_disbursement_rate: `Desembolsos`,
  env_disbursed: ` desembolsado`,
  back_all_projects:'Voltar a página de projetos',
  project_id: 'ID do Projeto',
};

class ProjectDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: 'focus_area_1',
      projects: [],
      filters: ['IFC', 'IBRD', 'MIGA'],
      projectTab: 'finance',
      responseReady: false,
      isIBRD: false,
      isLoan: false,
      isIFC: false,
      id_from_url: props.match.params.id ?? null,
      isSection1Expanded: false,
      isSection2Expanded: false,
      isSection3Expanded: false,
    };

    this.numeric_types = ['Percentage','Metric ton','Minutes','Megawatt hour(MWh)','Days','Square Meter(m2)','Tones/year','Square kilometer(km2)','Number','Kilometers','Cubic Meter(m3)','Meter(m)','Amount(USD)','Hectare(Ha)']

    this.setProjectTab = this.setProjectTab.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
    this.handleTextKey = this.handleTextKey.bind(this);
    this.handleTextEnvorimentCat = this.handleTextEnvorimentCat.bind(this);
    this.handleIcon = this.handleIcon.bind(this);
    this.handleTitleSection = this.handleTitleSection.bind(this);
  }

  async setProjectTab(projectTab) {
    await this.setState({ projectTab });
  }

  handleLogout() {
    localStorage.removeItem('auth');
    this.props.history.push('/');
  }

  handleTextKey(key) {
    if (this.props.location.pathname.substring(0, 4) !== '/pt/')
      return textEnglish[key];
    else return textPortuguese[key];
  }

  handleTextEnvorimentCat(cat) {
    let categoria = '';
    if (cat == 'B') {
      categoria = 'env_ifc_B';
    } else if (cat === 'A') {
      categoria = 'env_ifc_A';
    } else if (cat == 'C') {
      categoria = 'env_ifc_C';
    } else if (cat == 'FI') {
      categoria = 'env_ifc_FI';
    } else if (cat == 'FI-1') {
      categoria = 'env_ifc_FI_1';
    } else if (cat == 'FI-2') {
      categoria = 'env_ifc_FI_2';
    } else if (cat == 'FI-3') {
      categoria = 'env_ifc_FI_3';

    } else {
      return '';
    }
    if (this.props.location.pathname.substring(0, 4) !== '/pt/')
      return textEnglish[categoria];
    else return textPortuguese[categoria];
  }

  handleIcon(index) {
    let icons = [
      textEnglish['focus_area_1_icon'],
      textEnglish['focus_area_2_icon'],
      textEnglish['focus_area_3_icon'],
    ];
    return icons[index];
  }

  handleTitleSection(section) {
    let text = ''
    if (this.props.location.pathname.substring(0, 4) !== '/pt/'){
      text = textEnglish;
    } else {
      text = textPortuguese;
    }
    return text[section];
  }

  async componentDidMount() {
    window.scrollTo(0, 500);

    const search =this.props.location.search;
    const params = new URLSearchParams(search)
    const searchID = params.get("p")

    let body = { id: (searchID ?? this.props.location.state?.project?.id  ?? this.state.id_from_url) };

    if (body !== null) {
      await axios
      .post(`${API_URL}/project_by_id`, body)
      .then((response) => {
        this.setState({
          project: response.data[0]
        });
      })
      .catch((err) => {});
    }

    let borrower = '';

    await axios
      .post(`${API_URL}/project_details`, body)
      .then((response) => {
          if (this.state) {
            if (this.state?.project) {
              let project_id = this.state.project.id;

              if (response.data.length > 0)
                this.projectDetails = response.data[0];

              if (this.projectDetails?.ibrd_cmt_usd_amt_mil > 0) {
                this.setState({ isLoan: false, isIBRD: true, isIFC: false });
              } else if (this.projectDetails?.ibrd_cmt_usd_amt_mil <= 0) {
                this.setState({ isIBRD: false, isLoan: true, isIFC: false });
              } else if (project_id.charAt(0) !== 'P') {
                this.setState({ isIBRD: false, isLoan: false, isIFC: true });
              }
            }
          }
        if (response.data.length > 0) borrower = response.data[0].borrower;
      })
      .catch((err) => {});

    body = { id: searchID ?? this.state?.project?.id };

    await axios
      .post(`${API_URL}/ifc_details`, body)
      .then((response) => {
        if (response.data.length > 0) {
          this.ifcDetails = response.data[0];
        }

        this.setState({
          responseReady: true,
        });
      })
      .catch((err) => {});

    if (this.state.isIBRD) {
        await axios
          .post(`${API_URL}/pdos_by_id`, body)
          .then((response) => {
            this.projectDetails.pdos = response.data;
            this.setState({
              responseReady: true,
            });
          })
          .catch((err) => {});
      }
  }

  render() {
    let pt = false;
    let text = {};
    if (this.props.location.pathname.substring(0, 4) !== '/pt/') {
      text = textEnglish;
      pt = false;
    } else {
      text = textPortuguese;
      pt = true;
    }

    const status = pt ? this.state.project?.status_pt : this.state.project?.status
    const statusClasses = classNames(styles.status, {
      [styles.activeProject]:    status === 'Active' || status === 'Ativo',
      [styles.inactiveProject]:  status !== 'Active' && status !== 'Ativo'
    })

    let content = (
      <div className="loading-container">
        <ReactLoading type="spinningBubbles" color="#444" />
      </div>
    );
    if (this.state.responseReady === true) {
      let timelineItems = [];

      // Independente se IBRD, LOAN ou IFC first_repayment_date, closingdate , last_repayment_date
      // sao ordenados em ordem cronologica
      if (this.projectDetails?.closingdate) {
        timelineItems.push({
          label: text.timeline_closing,
          value: dayjs(new Date(this.projectDetails?.closingdate)).format(
            'DD/MM/YYYY'
          ),
          circle: new Date(this.projectDetails?.closingdate) > new Date(),
          rawDate: new Date(this.projectDetails?.closingdate),
        });
      }

      if (this.projectDetails?.first_repayment_date) {
        timelineItems.push({
          label: text.timeline_first_repayment_date,
          value: dayjs(
            new Date(this.projectDetails?.first_repayment_date)
          ).format('DD/MM/YYYY'),
          circle:
            new Date(this.projectDetails?.first_repayment_date) > new Date(),
          rawDate: new Date(this.projectDetails?.first_repayment_date),
        });
      }

      if (this.projectDetails?.last_repayment_date) {
        timelineItems.push({
          label: text.timeline_last_repayment_date,
          value: dayjs(
            new Date(this.projectDetails?.last_repayment_date)
          ).format('DD/MM/YYYY'),
          circle:
            new Date(this.projectDetails?.last_repayment_date) > new Date(),
          rawDate: new Date(this.projectDetails?.last_repayment_date),
        });
      }

      if (this.ifcDetails?.ifc_invested_date) {
        timelineItems.push({
          label: text.timeline_ifc_invested_date,
          value: dayjs(new Date(this.ifcDetails?.ifc_invested_date)).format(
            'DD/MM/YYYY'
          ),
          circle: new Date(this.ifcDetails?.ifc_invested_date) > new Date(),
          rawDate: new Date(this.ifcDetails?.ifc_invested_date),
        });
      }

      if (this.ifcDetails?.ifc_approval_date) {
        timelineItems.push({
          label: text.timeline_ifc_approval_date,
          value: dayjs(new Date(this.ifcDetails?.ifc_approval_date)).format(
            'DD/MM/YYYY'
          ),
          circle: new Date(this.ifcDetails?.ifc_approval_date) > new Date(),
          rawDate: new Date(this.ifcDetails?.ifc_approval_date),
        });
      }

      if (this.ifcDetails?.ifc_signed_date) {
        timelineItems.push({
          label: text.timeline_ifc_signed_date,
          value: dayjs(new Date(this.ifcDetails?.ifc_signed_date)).format(
            'DD/MM/YYYY'
          ),
          circle: new Date(this.ifcDetails?.ifc_signed_date) > new Date(),
          rawDate: new Date(this.ifcDetails?.ifc_signed_date),
        });
      }

      timelineItems.sort((a, b) => (b.rawDate > a.rawDate ? -1 : 1));

      // Presentes ou ausentes boardapprovaldate, agreement_signing_date, effective_date_most_recent_
      // sempre sao as 3 primeiras datas exibidas nesta ordem
      // Sempre visiveis para IBRD , Loan e IFC
      if ( this.projectDetails?.boardapprovaldate ) {
        timelineItems.splice(0, 0, {
          label: text.timeline_board_approval,
          value: dayjs(new Date(this.projectDetails?.boardapprovaldate)).format(
            'DD/MM/YYYY'
          ),
          circle: new Date(this.projectDetails?.boardapprovaldate) > new Date(),
        });
      } else if (this.state.isIBRD) {
        timelineItems.splice(0, 0, {
          label: text.timeline_board_approval,
          value: '-',
          circle: true,
        });
      }

      // Sempre visiveis para IBRD , Loan e IFC
      if (this.projectDetails?.agreement_signing_date) {
        let signingDate = this.projectDetails?.agreement_signing_date
          ? this.projectDetails?.agreement_signing_date
          : this.ifcDetails?.ifc_signed_date;
        timelineItems.splice(1, 0, {
          label: this.state.isIFC ? text.timeline_ifc_signed_date : text.timeline_signing,
          value: dayjs(new Date(signingDate)).format('DD/MM/YYYY'),
          circle: new Date(signingDate) > new Date(),
        });
      } else if (this.state.isIBRD) {
        timelineItems.splice(1, 0, {
          label: this.state.isIFC ? text.timeline_ifc_signed_date : text.timeline_signing,
          value: '-',
          circle: true,
        });
      }
      // Sempre visiveis para IBRD , Loan
      if (this.projectDetails?.effective_date_most_recent_) {
        timelineItems.splice(2, 0, {
          label: text.timeline_effectiveness,
          value: dayjs(
            new Date(this.projectDetails?.effective_date_most_recent_)
          ).format('DD/MM/YYYY'),
          circle:
            new Date(this.projectDetails?.effective_date_most_recent_) >
            new Date(),
        });
      } else if (this.state.isIBRD) {
        timelineItems.splice(2, 0, {
          label: text.timeline_effectiveness,
          value: '-',
          circle: true,
        });
      }

      return (
        <div className="container-fluid unpadify">
          <Navbar
            handleLogin={this.handleLogin}
            handleLogout={this.handleLogout}
            auth={this.props.auth}
            isLoggedIn={this.props.loggedIn}
            checkIsLoggedIn={this.props.checkIsLoggedIn}
            onChangeLoginState={this.props.onChangeLoginState}
            {...this.props}
          />
          <StrategyHeader
						title={pt ? "Atividades" : "Activities"}
						selectedTab={1}
						tabs={[
							{ link: pt ? '/pt/portfolio' : '/portfolio', label: pt ? 'Alcance do portfólio' : 'Portfolio Footprint' },
							{ link: pt ? '/pt/projects' : '/projects', label: pt ? 'Projetos' : 'Projects' },
							{ link: pt ? '/pt/reports' : '/reports', label: pt ? 'Relatórios' : 'Reports' },
						]}
            backgroundImage={ActivitiesCover}
					/>

          <GlobalStyles />

          <div id="strategy-container">
            <span className={styles.projectDetailsBreadcrumb}>Projetos / <strong>Projeto BIRD</strong></span>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} className={'project-title'}>
              <h2>Projeto BIRD</h2>
              <span className={statusClasses}>{pt ? this.state.project.status_pt : this.state.project.status}</span>
            </div>
            <div className="divider"></div>
            <div>
              <h3 style={{ textAlign: 'left' }}>Código: <b>{this.state.project.id}</b></h3>
            </div>
            <h3>{this.props.location.pathname.substring(0, 4) !== '/pt/' ? this.state.project.title : this.state.project.title_pt}</h3>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '30px' }}>
              {[
                this.state.project.focus_area_1,
                this.state.project.focus_area_2,
                this.state.project.focus_area_3,
              ].map((focusArea, index) => {
                return (
                  <>
                    {focusArea && (
                      <FocusAreaCard
                        icon={this.handleIcon(index)}
                        title={this.handleTextKey(
                          ['focus_title_1', 'focus_title_2', 'focus_title_3'][
                            index
                          ],
                          'en'
                        )}
                        text={this.handleTextKey(
                          [
                            'focus_description_1',
                            'focus_description_2',
                            'focus_description_3',
                          ][index],
                          'en'
                        )}
                      >
                        <span className="text-left">
                          {this.handleTextKey(
                            ['focus_title_1', 'focus_title_2', 'focus_title_3'][
                              index
                            ],
                            'en'
                          )}
                        </span>
                      </FocusAreaCard>
                    )}
                  </>
                );
              })}
            </div>
            {!this.state.isIFC && (
              <div className="about-accordion-section" style={{ marginTop: '22px' }} onClick={() => this.setState({ isSection1Expanded: !this.state.isSection1Expanded })}>
								<div className="about-text-title">
									<img src={ChevronRightIcon}  alt="Chevron Right" />
									<div className="about-accordion-title">{text.title_section_1}</div>
								</div>
								{this.state.isSection1Expanded && (
									<div className="about-text-subsections">
										{this.props.location.pathname.substring(0, 4) !== '/pt/'
                          ? this.state.project.description
                          : this.state.project.description_pt}
									</div>
                )}
							</div>
            )}

            {this.state.isIFC && (
              <div className={styles.section}>
                {(this.state.project.abstract ||
                  this.state.project.abstract_pt) && (
                  <ShowMoreText
                    maxLength={600}
                    text={
                      this.props.location.pathname.substring(0, 4) !== '/pt/'
                        ? this.state.project.abstract
                        : this.state.project.abstract_pt
                    }
                    pt ={pt}
                  />
                )}
              </div>
            )}
            {!this.state.isIFC && (
              <div
                  className="about-accordion-section"
                  style={{ marginTop: '22px' }}
                  onClick={() => this.setState({ isSection2Expanded: !this.state.isSection2Expanded })}
              >
								<div className="about-text-title">
									<img src={ChevronRightIcon} alt="Chevron Right" />
									<div className="about-accordion-title">{text.title_section_2}</div>
								</div>
								{this.state.isSection2Expanded && (
									<div className="about-text-subsections">
										{this.props.location.pathname.substring(0, 4) !== '/pt/'
                          ? this.state.project.abstract
                          : this.state.project.abstract_pt}
									</div>
                )}
							</div>
            )}
            <div className="about-accordion-section" style={{ marginTop: '22px' }} onClick={() => this.setState({ isSection3Expanded: !this.state.isSection3Expanded })}>
              <div className="about-text-title">
                <img src={ChevronRightIcon} alt="Chevron Right" />
                <div className="about-accordion-title">{text.title_section_3}</div>
              </div>
              {this.state.isSection3Expanded && (
                <div className="about-text-subsections">
                  {this.state.isIBRD && (
                    this.props.location.pathname.substring(0, 4) !== '/pt/'
                    ? textEnglish.timeline_keydates_ibrd(
                        this.projectDetails?.project_name
                      )
                    : textPortuguese.timeline_keydates_ibrd(
                        this.projectDetails?.project_name
                  ))}

                  {this.state.isIFC && (
                    this.props.location.pathname.substring(0, 4) !== '/pt/'
                    ? textEnglish.timeline_keydates_ibrd(
                        this.projectDetails?.project_name
                      )
                    : textPortuguese.timeline_keydates_ibrd(
                        this.projectDetails?.project_name
                  ))}
                  <Timeline items={timelineItems} />
                </div>
              )}
            </div>
            <div>
              <div className="impact-objectives-tab" style={{ marginBottom: '49px' }}>
                <div
                  className={
                    this.state.projectTab === 'finance'
                      ? 'objective-tab active'
                      : 'objective-tab'
                  }
                  onClick={() => this.setProjectTab('finance')}
                >
                  {pt ? 'Finanças' : 'Finances'}
                </div>
              </div>
            <div>
            {/* FINANCE TAB */}
            {this.state.projectTab === 'finance' && (
              <div>
              <div className={styles.section}>
                {/* TitleSection #4  title_section_4_ibrd, title_section_4_loan, title_section_4_ifc */}
                <h5>
                  {this.state.isIBRD ? this.handleTitleSection('title_section_4_ibrd') : this.state.isLoan ? this.handleTitleSection('title_section_4_loan') : this.handleTitleSection('title_section_4_ifc') }
                </h5>
                <p className="text-left">
                  {this.state.isLoan && this.handleTitleSection('implementation_arrangements') }
                  {this.state.isIBRD && this.handleTitleSection('borrower_details_section')}
                </p>
                <div style={{ display: 'flex', gap: '30px' }} className='other-details'>
                  {/* TitleSectionCard #4  title_section_4_card_1_ibrd , title_section_4_card_1_loan, title_section_4_card_1_ifc */}
                  <Card>
                    <span>
                      {this.state.isIBRD
                          ? this.handleTitleSection('title_section_4_card_1_ibrd') :
                            this.state.isLoan ? this.handleTitleSection('title_section_4_card_1_loan') : this.handleTitleSection('title_section_4_card_1_ifc')} </span>
                    <p className="text-left">
                      {!this.state.isIFC &&
                        (this.projectDetails?.borrower? this.projectDetails?.borrower: 'N/A')}
                        {this.state.isIFC && (  this.ifcDetails?.company_name ? this.ifcDetails?.company_name : 'N/A')}
                    </p>
                  </Card>
                  {/* TitleSectionCard #4  title_section_4_card_2_ibrd , title_section_4_card_2_loan, title_section_4_card_2_ifc */}
                  <Card>
                    <span>
                      {
                          this.state.isIBRD ?
                          this.handleTitleSection('title_section_4_card_2_ibrd') :
                          this.state.isLoan ?
                          this.handleTitleSection('title_section_4_card_2_loan') : this.handleTitleSection('title_section_4_card_2_ifc')
                      }
                    </span>
                    <p className="text-left">
                      {!this.state.isIFC &&
                        (this.projectDetails?.impagency
                          ? this.projectDetails?.impagency
                          : 'N/A')}
                      {this.state.isIFC &&
                        (this.ifcDetails?.project_name
                          ? this.ifcDetails?.project_name
                          : 'N/A')}
                    </p>
                  </Card>
                  {this.state.isLoan == true ? (
                    <>
                      <Card>
                        <span>{this.handleTitleSection('funding_source')}</span>
                        <p className="text-left">
                          {this.projectDetails?.fincrname?.at(0) ?? 'N/A'}
                        </p>
                      </Card>
                    </>
                  ) : null}
                </div>
              </div>
              {/* START FINANCES || Funding details SECTION  */}
              <div className={styles.section}>
                {/* TitleSection #5  title_section_5_ibrd, title_section_5_loan */}
                <h5>{
                    !this.state.isLoan && !this.state.isIFC ? this.handleTitleSection('title_section_5_ibrd')
                      : this.state.isIFC ?  this.handleTitleSection('title_section_5_ifc') : this.handleTitleSection('title_section_5_loan')
                  }</h5>
                <p className="text-left">
                  { this.state.isIBRD ? text.finances_before(this.projectDetails?.project_name) : ''}
                  {/*  */}
                  { this.state.isIFC ? text.finances_before_ifc(this.projectDetails?.project_name) : ''}
                  { this.state.isLoan ? text.funding_details(this.projectDetails?.project_name) : '' }

                </p>
                <div>
                  <div style={{ display: 'grid', gap: '30px'}} className='financial-row'>
                    {this.state.isIBRD && (
                      <>
                        <Card largeContent>
                          <span>{this.handleTitleSection('title_section_5_card_1_ibrd')}</span>
                          <p className="card-money-amount">
                            <span>US$</span>
                            {this.projectDetails?.original_principal_amount > 0
                              ? abbreviateFromMillions(this.projectDetails?.original_principal_amount,2,2,false): this.projectDetails?.ibrd_cmt_usd_amt_mil}</p>
                        </Card>
                        <Card largeContent>
                          <span>{this.handleTitleSection('title_section_5_card_2_ibrd')}</span>
                          <p className="card-money-amount"
                          >
                            <span>US$</span>
                            {this.projectDetails?.fincr_usd_amt.at(-1) > 0
                              ? abbreviateFromMillions( this.projectDetails?.fincr_usd_amt.at(-1), 2, 2, false ) : '0.00'} </p>
                        </Card>
                      </>
                    )}
                    {/* TitleSectionCard #5  title_section_5_card_3_ibrd , title_section_5_card_1_loan,title_section_5_card_1_ifc */}
                  {!this.state.isIFC && (
                    <Card largeContent>
                      <span>
                        {this.state.isIBRD && this.handleTitleSection('title_section_5_card_3_ibrd')}
                        {this.state.isLoan && this.handleTitleSection('title_section_5_card_1_loan')}

                      </span>
                      <p className="card-money-amount"                      >
                        <span>US$</span>
                        {/* Alterar logica de exibicao de valores IBRD e TRUST FUND - SEPARAR CALCULO */}
                        {this.state.isIBRD &&
                          abbreviateFromMillions(
                            Number(this.projectDetails?.original_principal_amount) + Number(this.projectDetails?.fincr_usd_amt.at(-1)),2,2,false)}
                        {this.state.isLoan &&
                          abbreviateFromMillions(
                            Number(this.projectDetails?.fincr_usd_amt.at(0)),2,2,false)}

                      </p>
                    </Card>)}

                    {this.state.isIFC && (
                      <>
                        {/* TitleSectionCard #5 title_section_5_card_1_ifc */}
                        {  (this.ifcDetails?.ifc_investment_for_guarantee_million_usd > 0) && (
                          <Card largeContent>
                            <span>
                              {this.handleTitleSection('title_section_5_card_1_ifc')}
                            </span>
                            <p className="card-money-amount">
                              <span>US$</span>
                              {/* Alterar logica de exibicao de valores IBRD e TRUST FUND - SEPARAR CALCULO */}
                              {this.state.isIFC &&
                                (this.ifcDetails
                                  ?.ifc_investment_for_guarantee_million_usd
                                  ? abbreviateFromMillions(
                                      Number( this.ifcDetails ?.ifc_investment_for_guarantee_million_usd  ),  2,  2,  false ) : '0.00')}
                            </p>
                          </Card>)
                        }
                        { (this.ifcDetails?.ifc_investment_for_loan_million_usd > 0) && (
                        <Card largeContent>
                          <span>
                            {this.handleTitleSection(
                              'title_section_5_card_2_ifc'
                            )}
                          </span>
                          <p className="card-money-amount">
                            <span>US$</span>
                            {/* Alterar logica de exibicao de valores IBRD e TRUST FUND - SEPARAR CALCULO */}
                            {this.state.isIFC &&
                              (this.ifcDetails?.ifc_investment_for_loan_million_usd  ?
                                  abbreviateFromMillions(  Number( this.ifcDetails ?.ifc_investment_for_loan_million_usd ), 2, 2, false ) : '0.00')}  </p>
                        </Card>)}

                        {/* TitleSectionCard #5 title_section_5_card_2_ifc */}
                        { (this.ifcDetails?.ifc_investment_for_equity_million_usd > 0) && (
                          <Card largeContent>
                            <span>
                              {this.handleTitleSection(
                                'title_section_5_card_3_ifc'
                              )}
                            </span>
                            <p className="card-money-amount">
                              <span>US$</span>
                              {/* Alterar logica de exibicao de valores IBRD e TRUST FUND - SEPARAR CALCULO */}
                              {this.state.isIFC &&
                                (this.ifcDetails
                                  ?.ifc_investment_for_equity_million_usd
                                  ? abbreviateFromMillions(
                                      Number(this.ifcDetails  ?.ifc_investment_for_equity_million_usd),2,2,false): '0.00')}
                            </p>
                          </Card>)
                        }

                        {/* TitleSectionCard #5 title_section_5_card_2_ifc */}
                        <Card largeContent>
                          <span>
                            {this.handleTitleSection(
                              'title_section_5_card_4_ifc'
                            )}
                          </span>
                          <p
                              style={{
                                marginTop: '20px',
                                textAlign: 'right',
                                color: '#262626',
                                fontFamily: "'Andes', sans-serif"
                              }}
                              className="card-money-amount"
                          >
                            <span style={{ display: 'inline', marginRight: '15px' }}>US$</span>
                            {/* Alterar logica de exibicao de valores IBRD e TRUST FUND - SEPARAR CALCULO */}
                            {this.state.isIFC &&
                              (this.ifcDetails
                                ?.total_ifc_investment_as_approved_by_board_million_usd
                                ? abbreviateFromMillions(
                                    Number( this.ifcDetails?.total_ifc_investment_as_approved_by_board_million_usd),2,2,false) : '0.00'
                              )
                            }
                          </p>
                        </Card>
                      </>
                    )}
                  </div>
                </div>
              </div>
              {/* END FINANCES || Funding details SECTION  */}
              <div>
                {this.state.isIFC && (
                  <>
                    <div className={styles.section}>
                      <h5>{text.env_assessment}</h5>
                      <p className="text-left">
                        {text.env_after_titleIFC(this.ifcDetails?.projectName)}
                      </p>
                      <div className="grid-1-3">
                        <div>
                          <Card largeContent>
                            <span>{this.handleTitleSection('environmental_category_ifc')}</span>
                            <p className="text-left">
                              {this.ifcDetails?.environmental_category ?? 'N/A'}
                            </p>
                          </Card>
                        </div>
                        <div>
                          <Accordeon title={ pt ? textPortuguese.env_ifc_category(this.ifcDetails?.environmental_category) : textEnglish.env_ifc_category(this.ifcDetails?.environmental_category) }>
                            <p className="text-left">
                              {this.handleTextEnvorimentCat(
                                this.ifcDetails?.environmental_category
                              )}
                            </p>
                          </Accordeon>
                          <Accordeon title={this.handleTitleSection('env_ifc_categories') }>
                            {['A', 'B', 'C', 'FI', 'FI-1', 'FI-2', 'FI-3'].map((cat, index) => {
                              return (
                                <>
                                  <p className="text-left">
                                    {this.handleTextEnvorimentCat(cat)}
                                  </p>
                                </>
                              );
                            })}
                          </Accordeon>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
              <div>
                {/* A partir deste ponto retorna exibicao referente somente aos projetos IBRD */}
                {this.state.isIBRD ? (
                  <>
                    <div className={styles.section}>
                      {/* TitleSection #6 */}
                      <h5>{this.handleTitleSection('title_section_6_ibrd')}</h5>
                      <p className="text-left">
                        {this.props.location.pathname.substring(0, 4) !== '/pt/'
                          ? textEnglish.lending_instrument_after
                          : textPortuguese.lending_instrument_after}{' '}
                      </p>
                      {/* TitleSection #7 */}
                      <div className="section" style={{ marginTop: '8px' }}>
                        <div style={{ width: '50%' }}>
                          <Card>
                            <span>{this.handleTitleSection('title_section_7_ibrd')}</span>
                            <p className="text-left">
                              Investment Project Financing
                            </p>
                          </Card>
                        </div>
                        <div style={{ marginTop: '32px' }}>
                          <Accordeon title={text.env_more_about}>
                              {this.props.location.pathname.substring(0, 4) !==
                              '/pt/'
                                ? textEnglish.more_ipf
                                : textPortuguese.more_ipf}{' '}
                          </Accordeon>
                          <Accordeon title={this.handleTitleSection('more_about_other_instruments')}>
                            <p className="text-left">
                              {this.props.location.pathname.substring(0, 4) !==
                              '/pt/'
                                ? textEnglish.more_dpf
                                : textPortuguese.more_dpf}{' '}
                            </p>
                              {this.props.location.pathname.substring(0, 4) !==
                              '/pt/'
                                ? textEnglish.more_pfor
                                : textPortuguese.more_pfor}{' '}
                          </Accordeon>
                        </div>
                      </div>
                    </div>
                    <div className={styles.section}>
                      {/* TitleSection #8 */}
                      <h5>{this.handleTitleSection('title_section_8_ibrd')}</h5>
                      <p className="text-left">
                        {this.props.location.pathname.substring(0, 4) !== '/pt/'
                          ? textEnglish.summary_status_wb_financing_before(
                              this.projectDetails?.project_name
                            )
                          : textPortuguese.summary_status_wb_financing_before(
                              this.projectDetails?.project_name
                            )}{' '}
                      </p>
                      <ProgressBar
                        title={text.env_disbursement_rate}
                        current={this.projectDetails?.disbursed_amount} //, abbreviateFromMillions(this.projectDetails?.disbursed_amount, 2, 2, false) }
                        currentLabel={text.env_disbursed}
                        total={this.projectDetails?.original_principal_amount}
                        totalLabel=" total"
                      />

                      <div style={{ display: 'grid', gap: '30px', gridTemplateColumns: '1fr 1fr 1fr 1fr' }}>
                        <Card largeContent>
                          <span>{this.handleTitleSection('title_section_8_card_1_ibrd')}</span>
                          <p className="card-money-amount">
                            <span>US$</span>
                            {abbreviateFromMillions(this.projectDetails?.disbursed_amount,2,2,false)}
                          </p>
                        </Card>
                        <Card largeContent>
                          <span>{this.handleTitleSection('title_section_8_card_2_ibrd')}</span>
                          <p
                              className="card-money-amount"
                            >
                            <span>US$</span>
                            {abbreviateFromMillions(this.projectDetails?.repayment,2,2,false)}
                          </p>
                        </Card>
                      </div>
                    </div>
                  </>
                ) : null}
              </div>
            </div>
            )}
            {/* INDICATOR TAB */}
            {(this.state.projectTab === 'indicators') && (
              <div className={styles.section}>
                {this.state.isIBRD && this.projectDetails.pdos && Object.keys(this.projectDetails.pdos).map((key, index) => {return(
                  <>
                    <h5>{this.projectDetails.pdos[key].indicator_name}</h5>
                    {this.projectDetails.pdos[key].indicator_uom_name !== 'Text' &&

                      <div className={styles.indicatorTabWrapper}>
                        <div className={styles.indicatorsCopyWrapper}>
                          <div
                              className={classNames(
                                styles.indicatorItem,
                                styles.indicatorItemBase,
                                'impact-indicator-baseline'
                              )}
                            >
                            <p className={classnames(styles.indicatorItemLabel)}>{this.handleTitleSection('baseline') + ' (' + this.projectDetails.pdos[key].baselines.year + '): ' + ' '}</p>
                            <p>{ (this.projectDetails.pdos[key].baselines.value >= 0 && this.numeric_types.includes(this.projectDetails.pdos[key].indicator_uom_name)) ? this.projectDetails.pdos[key].baselines.value : this.projectDetails.pdos[key].baselines.text_baseline !='-' ? this.projectDetails.pdos[key].baselines.text_baseline : '-'}</p>
                          </div>
                          <div
                              className={classNames(
                                styles.indicatorItem,
                                'impact-indicator-progress', {
                                  [styles.indicatorItemOnCourse]: parseInt(this.projectDetails.pdos[key].progress.slice(-1)[0].value >=0 && this.numeric_types.includes(this.projectDetails.pdos[key].indicator_uom_name) ? this.projectDetails.pdos[key].progress.slice(-1)[0].value : this.projectDetails.pdos[key].progress.slice(-1)[0].text_progress) < parseInt(this.projectDetails.pdos[key].targets.value >0 ? this.projectDetails.pdos[key].targets.value : this.projectDetails.pdos[key].targets.text_target) ,
                                  [styles.indicatorItemOverTarget]: parseInt(this.projectDetails.pdos[key].progress.slice(-1)[0].value >=0 && this.numeric_types.includes(this.projectDetails.pdos[key].indicator_uom_name) ? this.projectDetails.pdos[key].progress.slice(-1)[0].value : this.projectDetails.pdos[key].progress.slice(-1)[0].text_progress) > parseInt(this.projectDetails.pdos[key].targets.value >0 ? this.projectDetails.pdos[key].targets.value : this.projectDetails.pdos[key].targets.text_target),
                                }
                              )}
                            >
                            <p className={styles.indicatorItemLabel}>{`${this.handleTitleSection('progress')} (${this.projectDetails.pdos[key].progress.slice(-1)[0].year}):`}</p>
                            <p>{this.projectDetails.pdos[key].progress.slice(-1)[0].value >=0 && this.numeric_types.includes(this.projectDetails.pdos[key].indicator_uom_name) ? this.projectDetails.pdos[key].progress.slice(-1)[0].value : this.projectDetails.pdos[key].progress.slice(-1)[0].text_progress }</p>
                          </div>
                          <div
                              className={classNames(
                                styles.indicatorItem,
                                styles.indicatorItemTarget,
                                'impact-indicator-target'
                              )}
                            >
                            <p className={styles.indicatorItemLabel}>{this.handleTitleSection('target') + ' (' + this.projectDetails.pdos[key].targets.year + '): ' + ' '}</p>
                            <p>{this.projectDetails.pdos[key].targets.value >0 ? this.projectDetails.pdos[key].targets.value : this.projectDetails.pdos[key].targets.text_target }</p>
                          </div>
                        </div>
                        <div className={styles.indicatorsChartsWrapper}>

                          {this.numeric_types.includes(this.projectDetails.pdos[key].indicator_uom_name) && (
                            <div className={styles.indicatorsGauge}>
                              <div className={styles.gauge}>
                              <Gauge
                                    className="gauge"
                                    title={text.speedometer}
                                    data={{ percent:  this.projectDetails.pdos[key].progress.slice(-1)[0].value / this.projectDetails.pdos[key].targets.value }}
                                  />
                              </div>
                            </div>
                          )}
                          {this.numeric_types.includes(this.projectDetails.pdos[key].indicator_uom_name) && (
                            <div className={styles.indicatorsGraph}>
                              <div className={styles.graph}>
                              {this.state.projectTab === 'indicators' && (<LineGraph
                                pt={pt}
                                id={
                                  'line-graph-' +
                                  this.projectDetails.pdos[key].indicator_id +
                                  '-' +
                                  index
                                }
                                trend={'plr'}
                                data={this.projectDetails.pdos[key].progress}
                                target={this.projectDetails.pdos[key].targets.value}
                                target_year={this.projectDetails.pdos[key].targets.year}
                                unit={pt ? '' : ''}
                                isPercentage={this.projectDetails.pdos[key].indicator_uom_name  == 'Percentage'}
                                isArea={false}
                              />)}
                              </div>
                            </div>
                            )}
                        </div>
                      </div>
                    }
                    {this.projectDetails.pdos[key].indicator_uom_name === 'Text' &&
                      <div className={styles.indicatorGridWrapper}>
                        <div
                              className={classNames(
                                styles.indicatorTableItem,
                                styles.indicatorTableItemBase,
                                'impact-indicator-baseline'
                              )}
                            >
                            <p className={classnames(styles.indicatorTableItemLabel)}>{this.handleTitleSection('baseline') + ' (' + this.projectDetails.pdos[key].baselines.year + '): ' + ' '}</p>
                            <p>{ (this.projectDetails.pdos[key].baselines.value >= 0 && this.numeric_types.includes(this.projectDetails.pdos[key].indicator_uom_name)) ? this.projectDetails.pdos[key].baselines.value : this.projectDetails.pdos[key].baselines.text_baseline !='-' ? this.projectDetails.pdos[key].baselines.text_baseline : '-'}</p>
                          </div>
                          {this.projectDetails.pdos[key].progress.map((item, idx) => <div
                              className={classNames(
                                styles.indicatorTableItem,
                                'impact-indicator-progress', {
                                  [styles.indicatorTableItemEven]: idx % 2 === 0,
                                  [styles.indicatorTableItemOdd]: idx % 2 !== 0,
                                }
                              )}
                            >
                            <p className={styles.indicatorTableItemLabel}>{`${this.handleTitleSection('progress')} (${item.year}):`}</p>
                            <p>{(item.text_baseline ?? item.text_progress) !== '' ? (item.text_baseline ?? item.text_progress) : 'N/A'}</p>
                          </div>)}
                          <div
                              className={classNames(
                                styles.indicatorTableItem,
                                styles.indicatorTableItemTarget,
                                'impact-indicator-target'
                              )}
                            >
                            <p className={styles.indicatorTableItemLabel}>{this.handleTitleSection('target') + ' (' + this.projectDetails.pdos[key].targets.year + '): ' + ' '}</p>
                            <p>{this.projectDetails.pdos[key].targets.value >0 ? this.projectDetails.pdos[key].targets.value : this.projectDetails.pdos[key].targets.text_target }</p>
                          </div>
                      </div>
                      }
                  </>
              )
              })}
            </div>
            )}
            {/* SMART SUPERVISION TAB */}
            {(this.state.projectTab === 'smartMonitoring') && (
              <div className={styles.section}>
                <h5 style={{ marginBottom: '32px' }}>Smart supervision</h5>
                {this.state.projectTab === 'smartMonitoring' && (
                  <ProgramMap
                    {...this.props}
                    setStateData={null}
                    setSelectedState={null}
                    passedState={null}
                    projectData={null}
                  />
                )}
              </div>
            )}
            </div>
            </div>
          </div>
          <WelcomeFooter {...this.props} />
        </div>
      );
    } else {
      return content;
    }
  }
}
export default withRouter(ProjectDetails);
