import axios from "axios";
import React, { useEffect, useState } from "react";

import Modal from "../Modal";
import AddProject from "../AddProject";
import TitleSection from "../TitleSection";
import ProjectInfo from "../ProjectInfo";

import "./styles.css";
import "../../styles/modal-form.css";
import moment from "moment";
import {API_URL} from "../../../config/api";

const textPortuguese = {
  addTitle: "Incluir projeto",
  editTitle: "Editar projeto",
  secondTab: "Mais informações",
  save: "Salvar",
};

const textEnglish = {
  title: "Add project",
  addTitle: "Add project",
  editTitle: "Edit project",
  secondTab: "More information",
  save: "Save",
};

const emptyForm = {
  id: null,
  title: null,
  title_pt: null,
  focus_area_1_value: null,
  focus_area_2_value: null,
  focus_area_3_value: null,
  states: [],
  description: null,
  description_pt: null,
  abstract: null,
  abstract_pt: null,
  amount_committed: null,
  disbursements: null,
  date_approved: null,
  date_closed: null,
  link: null,
  link_pt: null,
  image: null,
  image_pt: null,
  organization: null,
};

const validateForm = ({
  id,
  title_pt,
  focus_area_1_value,
  focus_area_2_value,
  focus_area_3_value,
  states,
  link,
  description_pt,
  abstract_pt,
}) => {
  return (
      (id !== null && id !== '') &&
      (title_pt !== null && title_pt !== '') &&
      (focus_area_1_value !== null && isNaN(Number(focus_area_1_value)) === false && Number(focus_area_1_value) > -1) &&
      (focus_area_2_value !== null && isNaN(Number(focus_area_2_value)) === false && Number(focus_area_2_value) > -1) &&
      (focus_area_3_value !== null && isNaN(Number(focus_area_3_value)) === false && Number(focus_area_3_value) > -1) &&
      (states !== null && states.length > 0) &&
      (link !== null && link !== '') &&
      (description_pt !== null && description_pt !== '') &&
      (abstract_pt !== null && abstract_pt !== '')
  );
};

const ProjectModal = (props) => {
  const [tabSelected, setTabSelected] = useState(0);
  const [text, setText] = useState(null);
  const [onLoading, setOnLoading] = useState(false);
  const [project, setProject] = useState(null);

  const [textProject, setTextProject] = useState(null);
  const [formState, setFormState] = useState(emptyForm);
  const [formIsValid, setFormIsValid] = useState(validateForm(formState));

  const resetFormState = () => {
    setFormState(emptyForm);
  };

  const onSelectTab = (tab) => {
    setTabSelected(tab);
  };

  const onProjectSaved = (project) => {
    setOnLoading(true);
    setProject(project);
    setTabSelected(1);
    setOnLoading(false);
  };

  const onInfosSaved = async (infos) => {
    setOnLoading(true);

    try {
      if (!infos.date_approved || infos.date_approved.length > 10) {
        infos.date_approved = null;
      } else {
        infos.date_approved = infos.date_approved.split('/');
        infos.date_approved = `${infos.date_approved[2]}-${infos.date_approved[1]}-${infos.date_approved[0]}-`;
      }

      const focus_area_3 =
          Number(project?.focus_area_3_value ?? 0) > 0
          || Number(project.focus_area_1_value) + Number(project.focus_area_2_value) + Number(project.focus_area_3_value) < 1;

      const body = {
        ...infos,
        title: project.title,
        focus_area_1_value: project.focus_area_1_value,
        focus_area_2_value: project.focus_area_2_value,
        focus_area_3_value: project.focus_area_3_value,
        focus_area_1: Number(project.focus_area_1_value) > 0,
        focus_area_2: Number(project.focus_area_2_value) > 0,
        focus_area_3,
        states: project.states,
        title_pt: project.title_pt,
      };

      const endpoint = props.editMode
        ? `/projects_add_data`
        : `/projects/new_data`;
      await axios.post(`${API_URL}/${endpoint}`, body);
    } catch (e) {
      console.log(e);
    } finally {
      props.closeModal();
      setOnLoading(false);
      window.location.reload();
    }
  };

  useEffect(() => {
    setTabSelected(0);
    if (props.location.pathname.substring(0, 4) === "/pt/") {
      setText(textPortuguese);
    } else {
      setText(textEnglish);
    }

    if (props.editMode && props.selectedProject) {
      props.selectedProject.date_approved = moment(props.selectedProject.date_approved).format('DD/MM/YYYY');
      props.selectedProject.date_closed = props.selectedProject.date_closed ? moment(props.selectedProject.date_closed).format('DD/MM/YYYY') : null;
      setFormState({ ...props.selectedProject });
      setProject(props.selectedProject);
    } else {
      resetFormState();
    }
  }, []);

  return (
    <>
      {props.showModal && (
        <Modal modalVisibility={() => props.showModal}>
          <TitleSection
            onClose={props.closeModal}
            title={
              props.editMode ? text?.editTitle ?? "" : text?.addTitle ?? ""
            }
          />
          <div className="tabs-wrapper">
            <span
              onClick={() => onSelectTab(0)}
              className={`add-project-tab ${
                tabSelected === 0 ? "selected" : ""
              }`}
            >
              {props.editMode ? text?.editTitle ?? "" : text?.addTitle ?? ""}
            </span>

            <span
              onClick={() => onSelectTab(1)}
              className={`add-project-tab ${
                tabSelected === 1 ? "selected" : ""
              }`}
            >
              {text?.secondTab ?? ""}
            </span>
          </div>
          <>
            {tabSelected === 0 ? (
              <AddProject
                onSave={(e) => onProjectSaved(e)}
                validateForm={validateForm}
                text={textProject}
                setText={setTextProject}
                formState={formState}
                setFormState={setFormState}
                formIsValid={formIsValid}
                setFormIsValid={setFormIsValid}
                onLoading={onLoading}
                {...props}
              />
            ) : (
              <></>
            )}
            {tabSelected === 1 ? (
              <ProjectInfo
                onSave={(e) => onInfosSaved(e)}
                validateForm={validateForm}
                text={textProject}
                setText={setTextProject}
                formState={formState}
                setFormState={setFormState}
                formIsValid={formIsValid}
                setFormIsValid={setFormIsValid}
                onLoading={onLoading}
                {...props}
              />
            ) : (
              <></>
            )}
          </>
        </Modal>
      )}
    </>
  );
};

export default ProjectModal;
