import React, { Component } from 'react';
import axios from 'axios';
import mapboxgl from 'mapbox-gl';

import { Tab, Row, Col, Nav, NavItem, Button, OverlayTrigger, Popover } from 'react-bootstrap';
import '../map/Map.css';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt,  faLayerGroup, faChevronLeft, faChevronRight, faChevronUp, faChevronDown, faTimes, faHammer, faFileAlt, faInfo, faBaby} from '@fortawesome/free-solid-svg-icons'
import Loading from '../Loading';
import Toggle from 'react-toggle';
import "react-toggle/style.css";
import $ from 'jquery/src/jquery';

import 'bootstrap/dist/js/bootstrap.min.js';
import {API_URL} from "../../../../config/api";

let MapboxLanguage = require('@mapbox/mapbox-gl-language');

let data;

const textEnglish = {
	"focus_areas": "Focus Areas",
	"wb_miga": "World Bank + MIGA",
	"focus_area_1": "Focus Area 1",
	"focus_area_1_title": "Focus Area 1: Fiscal Consolidation",
	"focus_area_1_title_long": "Focus Area 1: Fiscal Consolidation and Government Effectiveness for Sustainable, Inclusive and Efficient Service Delivery",
	"focus_area_2": "Focus Area 2",
	"focus_area_2_title": "Focus Area 2: Private Sector Investment",
	"focus_area_2_title_long": "Focus Area 2: Private Sector Investment and Productive Growth",
	"focus_area_3": "Focus Area 3",
	"focus_area_3_title": "Focus Area 3: Inclusive and Sustainable Development",
	"focus_area_3_title_long": "Focus Area 3: Inclusive and Sustainable Development",
	"focus_click": "Click on Focus Area to Break Down By State",
	"on": "On",
	"off": "Off",
	"indicator_datasets": "Indicator Datasets",
	"project_monitoring": "Project Monitoring",
	"State Data": "State Data",
	"Human Capital": "Human Capital",
	"Poverty": "Poverty",
	"Climate": "Climate",
	"climate_desc": "The following indicators anticipate climate impacts between 2040 and 2059 under two carbon emission scenarios, called Representative Concentration Pathways (RCP). RCP2.6 represents a strong mitigation scenario of total warming below  2 ̊ C by 2100, while RCP8.5 assumes the continuous increase in greenhouse gas emissions and total warming of 4.3 ̊ C by 2100.",
	"Disasters": "Disasters",
	"disasters_desc": "The following indicators capture material damage resulting from natural disasters between 1995 and 2014.",
	"Fires": "Fires",
	"Deforestation": "Deforestation",
	"smart_supervision": "Smart Supervision Projects",
	"smart_supervision_click" : "Click on Project to See Details",
	"back": "Back",
	"select_focus": "Select A Focus Area",
	"ifc": 'IFC has 61 projects in 37 sectors across all three Focus Areas totaling investments of $3.3 B.  Visit our Project Information & Data Portal at <a href="https://disclosures.ifc.org" target="_blank">https://disclosures.ifc.org</a> to learn more or explore our projects below.'
}

const textPortuguese = {
	"focus_areas": "Áreas de Foco",
	"wb_miga": "Banco Mundial + MIGA",
	"focus_area_1": "Área de Foco 1",
	"focus_area_1_title": "Área de Foco 1: Consolidação Fiscal",
	"focus_area_1_title_long": "Área de Foco 1: Consolidação Fiscal e Eficácia Governamental para uma Prestação de Serviços Sustentável, Inclusiva e Eficiente",
	"focus_area_2": "Área de Foco 2",
	"focus_area_2_title": "Área de Foco 2: Investimento do Setor Privado",
	"focus_area_2_title_long": "Área de Foco 2: Investimento Setor Privado e Crescimento Productivo",
	"focus_area_3": "Área de Foco 3",
	"focus_area_3_title": "Área de Foco 3: Desenvolvimento Sustentável e Inclusivo",
	"focus_area_3_title_long": "Área de Foco 3: Desenvolvimento Sustentável e Inclusivo",
	"focus_click": "Clique na Área de Foco para detalhar por Estado",
	"on": " ",
	"off": " ",
	"indicator_datasets": "Conjuntos de Dados Indicadores",
	"project_monitoring": "Monitoramento do Projeto",
	"State Data": "Dados Estaduais",
	"Human Capital": "Capital Humano",
	"Poverty": "Pobreza",
	"Climate": "Clima",
	"climate_desc": "Os indicadores a seguir antecipam os impactos climáticos entre 2040 e 2059 em dois cenários de emissão de carbono, chamados de Trajetórias Representativas de Concentração (RCP, Representative Concentration Pathways). A RCP2.6 representa um forte cenário de mitigação que mantém o aquecimento total abaixo de 2˚C até 2100; já o RCP8.5 presume o aumento contínuo das emissões de gases de efeito estufa e um aquecimento total de 4,3˚C até 2100.",
	"Disasters": "Desastres",
	"disasters_desc": "Os indicadores a seguir capturam os danos materiais decorrentes de desastres naturais entre 1995 e 2014.",
	"Fires": "Incêndios",
	"Deforestation": "Desmatamento",
	"smart_supervision": "Projetos de Supervisão Inteligente",
	"smart_supervision_click" : "Clique sobre o projeto para ver mais detalhes",
	"back": "Volte",
	"select_focus": "Selecione Uma Área de Foco",
	"ifc": 'O IFC tem 61 projetos em 37 setores nas as três áreas de foco com um investimento de $3.3 Bilhão. Visite nosso Portal de Informações do Projeto e Dados aqui <a href="https://disclosures.ifc.org" target="_blank">https://disclosures.ifc.org</a> para saber mais ou explorar os nossos projetos.'
}

let SPIDERFY_FROM_ZOOM = 10;

let hoveredStateId = null;

const colors = ['#fed976', '#fd8d3c', '#fc4e2a', '#e31a1c'];

let colorRampColors = {
    BuGn: {
        3: ["#e5f5f9", "#99d8c9", "#2ca25f"],
        4: ["#edf8fb", "#b2e2e2", "#66c2a4", "#238b45"],
        5: ["#edf8fb", "#b2e2e2", "#66c2a4", "#2ca25f", "#006d2c"],
        6: ["#edf8fb", "#ccece6", "#99d8c9", "#66c2a4", "#2ca25f", "#006d2c"],
        7: ["#edf8fb", "#ccece6", "#99d8c9", "#66c2a4", "#41ae76", "#238b45", "#005824"],
        8: ["#f7fcfd", "#e5f5f9", "#ccece6", "#99d8c9", "#66c2a4", "#41ae76", "#238b45", "#005824"],
        9: ["#f7fcfd", "#e5f5f9", "#ccece6", "#99d8c9", "#66c2a4", "#41ae76", "#238b45", "#006d2c", "#00441b"]
    },
    BuPu: {
        3: ["#e0ecf4", "#9ebcda", "#8856a7"],
        4: ["#edf8fb", "#b3cde3", "#8c96c6", "#88419d"],
        5: ["#edf8fb", "#b3cde3", "#8c96c6", "#8856a7", "#810f7c"],
        6: ["#edf8fb", "#bfd3e6", "#9ebcda", "#8c96c6", "#8856a7", "#810f7c"],
        7: ["#edf8fb", "#bfd3e6", "#9ebcda", "#8c96c6", "#8c6bb1", "#88419d", "#6e016b"],
        8: ["#f7fcfd", "#e0ecf4", "#bfd3e6", "#9ebcda", "#8c96c6", "#8c6bb1", "#88419d", "#6e016b"],
        9: ["#f7fcfd", "#e0ecf4", "#bfd3e6", "#9ebcda", "#8c96c6", "#8c6bb1", "#88419d", "#810f7c", "#4d004b"]
    },
    GnBu: {
        3: ["#e0f3db", "#a8ddb5", "#43a2ca"],
        4: ["#f0f9e8", "#bae4bc", "#7bccc4", "#2b8cbe"],
        5: ["#f0f9e8", "#bae4bc", "#7bccc4", "#43a2ca", "#0868ac"],
        6: ["#f0f9e8", "#ccebc5", "#a8ddb5", "#7bccc4", "#43a2ca", "#0868ac"],
        7: ["#f0f9e8", "#ccebc5", "#a8ddb5", "#7bccc4", "#4eb3d3", "#2b8cbe", "#08589e"],
        8: ["#f7fcf0", "#e0f3db", "#ccebc5", "#a8ddb5", "#7bccc4", "#4eb3d3", "#2b8cbe", "#08589e"],
        9: ["#f7fcf0", "#e0f3db", "#ccebc5", "#a8ddb5", "#7bccc4", "#4eb3d3", "#2b8cbe", "#0868ac", "#084081"]
    },
    OrRd: {
        3: ["#fee8c8", "#fdbb84", "#e34a33"],
        4: ["#fef0d9", "#fdcc8a", "#fc8d59", "#d7301f"],
        5: ["#fef0d9", "#fdcc8a", "#fc8d59", "#e34a33", "#b30000"],
        6: ["#fef0d9", "#fdd49e", "#fdbb84", "#fc8d59", "#e34a33", "#b30000"],
        7: ["#fef0d9", "#fdd49e", "#fdbb84", "#fc8d59", "#ef6548", "#d7301f", "#990000"],
        8: ["#fff7ec", "#fee8c8", "#fdd49e", "#fdbb84", "#fc8d59", "#ef6548", "#d7301f", "#990000"],
        9: ["#fff7ec", "#fee8c8", "#fdd49e", "#fdbb84", "#fc8d59", "#ef6548", "#d7301f", "#b30000", "#7f0000"]
    },
    PuBu: {
        3: ["#ece7f2", "#a6bddb", "#2b8cbe"],
        4: ["#f1eef6", "#bdc9e1", "#74a9cf", "#0570b0"],
        5: ["#f1eef6", "#bdc9e1", "#74a9cf", "#2b8cbe", "#045a8d"],
        6: ["#f1eef6", "#d0d1e6", "#a6bddb", "#74a9cf", "#2b8cbe", "#045a8d"],
        7: ["#f1eef6", "#d0d1e6", "#a6bddb", "#74a9cf", "#3690c0", "#0570b0", "#034e7b"],
        8: ["#fff7fb", "#ece7f2", "#d0d1e6", "#a6bddb", "#74a9cf", "#3690c0", "#0570b0", "#034e7b"],
    },
    YlGn: {
        3: ["#f7fcb9", "#addd8e", "#31a354"],
        4: ["#ffffcc", "#c2e699", "#78c679", "#238443"],
        5: ["#ffffcc", "#c2e699", "#78c679", "#31a354", "#006837"],
        6: ["#ffffcc", "#d9f0a3", "#addd8e", "#78c679", "#31a354", "#006837"],
        7: ["#ffffcc", "#d9f0a3", "#addd8e", "#78c679", "#41ab5d", "#238443", "#005a32"],
        8: ["#ffffe5", "#f7fcb9", "#d9f0a3", "#addd8e", "#78c679", "#41ab5d", "#238443", "#005a32"],
        9: ["#ffffe5", "#f7fcb9", "#d9f0a3", "#addd8e", "#78c679", "#41ab5d", "#238443", "#006837", "#004529"]
    },
    RdYlGn: {
        3: ["#fc8d59", "#ffffbf", "#91cf60"],
        4: ["#d7191c", "#fdae61", "#a6d96a", "#1a9641"],
        5: ["#d7191c", "#fdae61", "#ffffbf", "#a6d96a", "#1a9641"],
        6: ["#d73027", "#fc8d59", "#fee08b", "#d9ef8b", "#91cf60", "#1a9850"],
        7: ["#d73027", "#fc8d59", "#fee08b", "#ffffbf", "#d9ef8b", "#91cf60", "#1a9850"],
        8: ["#d73027", "#f46d43", "#fdae61", "#fee08b", "#d9ef8b", "#a6d96a", "#66bd63", "#1a9850"],
        9: ["#d73027", "#f46d43", "#fdae61", "#fee08b", "#ffffbf", "#d9ef8b", "#a6d96a", "#66bd63", "#1a9850"],
        10: ["#a50026", "#d73027", "#f46d43", "#fdae61", "#fee08b", "#d9ef8b", "#a6d96a", "#66bd63", "#1a9850", "#006837"],
        11: ["#a50026", "#d73027", "#f46d43", "#fdae61", "#fee08b", "#ffffbf", "#d9ef8b", "#a6d96a", "#66bd63", "#1a9850", "#006837"]
    }
};

const focusAreas = {
					'fa1': {name: "Focus Area 1", column: 'Focus_Area_1_Funding', type: 'geojson', url: '/Brazil_MASTER_Data_For_OpenBrazil_POINTS.geojson', style: {
									'circle-radius': [
										'step',
										['get', 'Focus_Area_1_Funding'],
										0,
										0,
										10,
										2000000,
										20,
										3000000,
										30,
										5000000,
										40,
										7000000,
										50,
										9000000,
										60,
										11000000,
										70,
										13000000,
										80
									],
									'circle-color': 'orange',
									'circle-stroke-width': 1,
									'circle-stroke-color': '#555555',
									'circle-opacity': 0,
									'circle-stroke-opacity': 0
									}}
				};

const catDescriptions = {'Climate': 'The following indicators anticipate climate impacts between 2040 and 2059 under two carbon emission scenarios, called Representative Concentration Pathways (RCP). RCP2.6 represents a strong mitigation scenario of total warming below  2 ̊ C by 2100, while RCP8.5 assumes the continuous increase in greenhouse gas emissions and total warming of 4.3 ̊ C by 2100.',
						'Disasters': 'The following indicators capture material damage resulting from natural disasters between 1995 and 2014.',
						'FIRMS Fire Data': 'Fire Information for Resource Management System (FIRMS) distributes Near Real-Time active fire data as detected by satellite. FIRMS displays hotspots detected within a 375 meter pixel. A single fire may be represented by multiple points on the map.'
						}

const layers = {'State Data' : {
								'pro_state': {name: 'Number of Projects', name_pt: 'Número de Projetos', column: 'Num_Projects', type: 'geojson', layer: 'fill', url: "/Brazil_MASTER_Data_For_OpenBrazil.geojson", style: {
									'fill-color': [
										'interpolate',
										['linear'],
										['get', 'Num_Projects'],
										1,
										'#f7fcfd',
										2,
										'#e5f5f9',
										5,
										'#ccece6',
										7,
										'#99d8c9',
										8,
										'#66c2a4',
										9,
										'#41ae76',
										10,
										'#238b45',
										11,
										'#005824'
									],
									'fill-opacity': 0.75
									},
									description: 'The number of active in a state as of November 2019.',
									description_pt: 'O número de projetos ativos em determinado estado em novembro de 2019.'
								},
								'pop_density': {name: 'Population Density', name_pt: 'Densidade Populacional', type: 'gee', url: '/api/layers/population',
									colors: ['#ffcb00', '#ff2c39', '#ef007b', '#ad00bd', '#5a00ef'],
									values: [0, 5, 10, 15, 20],
									values_pt: [0, 5, 10, 15, 20],
									description: 'The number of people living in a given 100m X 100m area as of 2019.',
									description_pt: 'O número de pessoas que viviam em determinada área de 100m x 100m em 2019.'}
								,
								'learning_poverty': {name: 'Learning Poverty', name_pt: 'Pobreza de aprendizagem', column: 'Learning_Poverty', type: 'geojson', layer: 'fill', url: "/Brazil_MASTER_Data_For_OpenBrazil.geojson", colorramp: 'OrRd',
									description: "Learning poverty means being unable to read and understand a simple text by age 10. This indicator brings together schooling and learning indicators: it begins with the share of children who haven't achieved minimum reading proficiency (as measured in schools) and is adjusted by the proportion of children who are out of school (and are assumed not able to read proficiently). Learning Poverty draws on new data developed in coordination with the UNESCO Institute for Statistics.",
									description_pt: 'Significa ser incapaz de ler e entender um texto simples aos 10 anos de idade. Esse indicador reúne dados de escolaridade e de aprendizagem: começa com a proporção de crianças que não alcançaram o nível mínimo de proficiência em leitura (conforme medido nas escolas) e é ajustado pela parcela de crianças fora da escola (que, presume-se, não são leitores proficientes). A pobreza de aprendizagem baseia-se em novos dados desenvolvidos em coordenação com o Instituto de Estatística da UNESCO.'},
								'capag': {name: 'CAPAG Score', name_pt: 'Classificação CAPAG', column: 'CAPAG_Score', type: 'geojson', layer: 'fill', url: "/Brazil_MASTER_Data_For_OpenBrazil.geojson", colorramp: 'BuGn', style: {
									'fill-color': [
										'match',
										['get', 'CAPAG_Score'],
										"Less than B",
										'#d73027',
										"B or better",
										'#1a9850',
										'#99d8c9'
									],
									'fill-opacity': 0.75
									},
									style_pt: {
									'fill-color': [
										'match',
										['get', 'CAPAG_Score'],
										"Menor que B",
										'#d73027',
										"B ou melhor",
										'#1a9850',
										'#99d8c9'
									],
									'fill-opacity': 0.75
									},
									description: 'Classificação da capacidade de pagamento dos Estado. The Brazilian Treasury’s debt repayment risk classification. States with B grade and above are eligible for federal guarantees to borrow money. Data updated October 2019.',
									description_pt: 'O número de pessoas que viviam em determinada área de 100m x 100m em 2019.'}
							}
				,
				'Human Capital' : {
									
									'hum_cap_index': {name: 'Human Capital Index', name_pt: 'Índice de Capital Humano', column: 'Hum_Cap_Index', type: 'geojson', layer: 'fill', url: "/Brazil_MASTER_Data_For_OpenBrazil.geojson", style: {
										'fill-color': [
											'interpolate',
											['linear'],
											['get', 'Hum_Cap_Index'],
											0.54,
											'#f7fcfd',
											0.55,
											'#e5f5f9',
											0.56,
											'#ccece6',
											0.58,
											'#99d8c9',
											0.59,
											'#66c2a4',
											0.6,
											'#41ae76',
											0.61,
											'#238b45',
											0.62,
											'#005824'
										],
										'fill-opacity': 0.75
										},
									description: 'The index measures the amount of human capital that a child born today can expect to attain by age 18, given the risks of poor health and poor education that prevail in the country where she lives. The Human Capital Index ranges between 0 and 1 with 1 being maximum potential reached. Data updated 2017.',
									description_pt: 'O índice leva em conta os níveis de saúde e educação de cada país para calcular a quantidade de capital humano que uma criança nascida hoje pode esperar adquirir até os 18 anos. O Índice de Capital Humano letia de 0 a 1; 1 significa que o potencial máximo foi alcançado. Dados atualizados em 2017.'
									}
				},
				'Poverty' : {

								'num_poor': {name: 'Number of Poor', name_pt: 'Número de pobres', column: 'Num_poor_190', type: 'geojson', layer: 'fill', url: "/Brazil_MASTER_Data_For_OpenBrazil.geojson", style: {
									'fill-color': [
										'interpolate',
										['linear'],
										['get', 'Num_poor_190'],
										0,
										'#1a9850',
										45998,
										'#66bd63',
										91253,
										'#a6d96a',
										233335,
										'#d9ef8b',
										435296,
										'#fee08b',
										634805,
										'#fdae61',
										1020104,
										'#f46d43',
										1700539,
										'#d73027'
									],
									'fill-opacity': 0.75
									},
									description: 'Number of people living on less than $1.90 per day as of 2017.',
									description_pt: 'Número de pessoas que viviam com menos de US$ 1,90 por dia em 2017.'
								}
							,
								'percent_poor': {name: 'Poverty Rate', name_pt: 'Taxa de pobreza', column: 'Perc_Poor_190', type: 'geojson', layer: 'fill', url: "/Brazil_MASTER_Data_For_OpenBrazil.geojson", style: {
									'fill-color': [
										'interpolate',
										['linear'],
										['get', 'Perc_Poor_190'],
										0,
										'#1a9850',
										2,
										'#66bd63',
										4,
										'#a6d96a',
										6,
										'#d9ef8b',
										8,
										'#fee08b',
										10,
										'#fdae61',
										12,
										'#f46d43',
										14,
										'#d73027'
									],
									'fill-opacity': 0.75
									},
									description: 'Percentage of population living on less than $1.90 per day as of 2017.',
									description_pt: 'Percentual de pessoas que viviam com menos de US$ 1,90 por dia em 2017.'
								}
							
				},
				'Climate' : {
								'RCP26': {name: 'Severe Drought Likelihood RCP2.6', name_pt: 'Probabilidade de Seca Severa RCP2.6', column: 'Ann_SevDrought_Likelihood_RCP26', type: 'geojson', layer: 'fill', url: "/Brazil_MASTER_Data_For_OpenBrazil.geojson", colorramp: 'OrRd',
									description: 'Annual probability of experiencing at least severe drought conditions between 2040 and 2059 under RCP2.6.',
									description_pt: 'Probabilidade anual de ocorrerem, no mínimo, condições severas de seca entre 2040 e 2059 segundo a RCP2.6.'},
								'RCP85': {name: 'Severe Drought Likelihood RCP8.5', name_pt: 'Probabilidade de Seca Severa RCP8.5', column: 'Ann_SevDrought_Likelihood_RCP85', type: 'geojson', layer: 'fill', url: "/Brazil_MASTER_Data_For_OpenBrazil.geojson", colorramp: 'OrRd',
									description: 'Annual probability of experiencing at least severe drought conditions between 2040 and 2059 under RCP 8.5.',
									description_pt: 'Probabilidade anual de ocorrerem, no mínimo, condições severas de seca entre 2040 e 2059 segundo a RCP8.5.'},
								'HI35_RCP26': {name: 'Average Hi RCP2.6', name_pt: 'IC Média RCP2.6', column: 'Avg_Days_HI35_RCP26', type: 'geojson', layer: 'fill', url: "/Brazil_MASTER_Data_For_OpenBrazil.geojson", colorramp: 'OrRd',
									description: 'Average count of days per month or year when the daily Heat Index surpassed 35°C between 2040 and 2059 under RCP 2.6.',
									description_pt: 'Contagem média de dias por mês ou ano em que o Índice de Calor diário ultrapassou 35°C entre 2040 e 2059 segundo a RCP 2.6.'},
								'HI35_RCP85': {name: 'Average Hi RCP8.5', name_pt: 'IC Média RCP8.5', column: 'Avg_Days_HI35_RCP85', type: 'geojson', layer: 'fill', url: "/Brazil_MASTER_Data_For_OpenBrazil.geojson", colorramp: 'OrRd',
									description: 'Average count of days per month or year when the daily Heat Index surpassed 35°C between 2040 and 2059 under RCP 8.5.',
									description_pt: 'Contagem média de dias por mês ou ano em que o Índice de Calor diário ultrapassou 35°C entre 2040 e 2059 segundo a RCP 8.5.'},
								'Rain_5Day_Max_25y_RCP26': {name: 'Rain 5 Day RCP2.6', name_pt: 'Chuva 5 Dias RCP2.6', column: 'Rain_5Day_Max_25y_RCP26', type: 'geojson', layer: 'fill', url: "/Brazil_MASTER_Data_For_OpenBrazil.geojson", colorramp: 'GnBu',
									description: 'Statistical 25-yr return level of the largest 5-day consecutive rainfall sum between 2040 and 2059 under RCP 2.6.',
									description_pt: 'Nível estatístico de retorno de 25 anos da maior soma de chuvas de 5 dias consecutivos entre 2040 e 2059 segundo a RCP 2.6.'},
								'Rain_5Day_Max_25y_RCP85': {name: 'Rain 5 Day RCP8.5', name_pt: 'Chuva 5 Dias RCP8.5', column: 'Rain_5Day_Max_25y_RCP85', type: 'geojson', layer: 'fill', url: "/Brazil_MASTER_Data_For_OpenBrazil.geojson", colorramp: 'GnBu',
									description: 'Statistical 25-yr return level of the largest 5-day consecutive rainfall sum between 2040 and 2059 under RCP 8.5.',
									description_pt: 'Nível estatístico de retorno de 25 anos da maior soma de chuvas de 5 dias consecutivos entre 2040 e 2059 segundo a RCP 8.5.'}
				},
				'Disasters' : {
								'climate': {name: 'Climate Disasters', name_pt: 'Desastres climáticos', column: 'SUM_Climate_Disasters', type: 'geojson', layer: 'fill', url: "/Brazil_MASTER_Data_For_OpenBrazil.geojson", colorramp: 'BuGn',
									description: '$ value of the total material damage and losses resulting from climatological events between 1995 and 2014.',
									description_pt: 'Valor em R$ do total de danos e perdas materiais decorrentes de eventos climatológicos entre 1995 e 2014'},
								'infrastructure': {name: 'Damaged Infrastructure', name_pt: 'Infraestruturas danificadas', column: 'SUM_Damaged_Infrastructure', type: 'geojson', layer: 'fill', url: "/Brazil_MASTER_Data_For_OpenBrazil.geojson", colorramp: 'BuGn',
									description: '$ value of infrastructure damage resulting from all disasters between 1995 and 2014.',
									description_pt: 'Valor em R$ dos danos à infraestrutura decorrentes de todos os desastres entre 1995 e 2014.'},
								'meteorological': {name: 'Meteorological Disasters', name_pt: 'Desastres meteorológicos', column: 'SUM_Meteorological_Disasters', type: 'geojson', layer: 'fill', url: "/Brazil_MASTER_Data_For_OpenBrazil.geojson", colorramp: 'BuGn',
									description: '$ value of total material damage and losses resulting from meteorological disasters, including extreme weather events (e.g. rain, drought,extreme heat or cold, ice, or wind) between 1995 and 2014.',
									description_pt: 'Valor em R$ do total de danos e perdas materiais decorrentes de desastres meteorológicos, incluindo eventos climáticos extremos (por exemplo, chuva, seca, calor ou frio extremo, gelo ou vento) entre 1995 e 2014.'},
								'hydro': {name: 'Hydrological Disasters', name_pt: 'Desastres hidrológicos', column: 'SUM_Hydrological_Disasters', type: 'geojson', layer: 'fill', url: "/Brazil_MASTER_Data_For_OpenBrazil.geojson", colorramp: 'GnBu',
									description: '$ value of total material damage and losses resulting from hydrological events, including floods, between 1995 and 2014.',
									description_pt: 'Valor em R$ do total de danos e perdas materiais decorrentes de eventos hidrológicos, incluindo enchentes, entre 1995 e 2014.'},
								'damaged_houses': {name: 'Damaged Houses', name_pt: 'Casas danificadas', column: 'COUNT_Damaged_Housing', type: 'geojson', layer: 'fill', url: "/Brazil_MASTER_Data_For_OpenBrazil.geojson", colorramp: 'BuGn',
									description: 'Number of homes that have suffered some type of damage resulting from all disaster types between 1995 and 2014.',
									description_pt: 'Número de residências que sofreram algum tipo de dano decorrente de todos os tipos de desastre entre 1995 e 2014.'},
								'damages_losses': {name: 'Damages and Losses', name_pt: 'Danos e prejuízos', column: 'SUM_Damages_and_Losses', type: 'geojson', layer: 'fill', url: "/Brazil_MASTER_Data_For_OpenBrazil.geojson", colorramp: 'BuGn',
									description: '$ value of total material damage and losses resulting from all disaster types between 1995 and 2014.',
									description_pt: 'Valor em R$ do total de danos e prejuízos materiais decorrentes de todos os tipos de desastre entre 1995 e 2014.'},
								'destroyed_houses': {name: 'Destroyed Houses', name_pt: 'Casas destruídas', column: 'COUNT_Destroyed_Housing', type: 'geojson', layer: 'fill', url: "/Brazil_MASTER_Data_For_OpenBrazil.geojson", colorramp: 'BuGn',
									description: ' Number of homes with irreparable damage resulting from all disaster types between 1995 and 2014.',
									description_pt: 'Número de residências com danos irreparáveis decorrentes de todos os tipos de desastre entre 1995 e 2014.'},
								'private_losses': {name: 'Private Losses', name_pt: 'Prejuízos privados:', column: 'SUM_Private_Losses', type: 'geojson', layer: 'fill', url: "/Brazil_MASTER_Data_For_OpenBrazil.geojson", colorramp: 'BuGn',
									description: '$ total value of private losses in agriculture, livestock, industry, and the service provision sector resulting from all disaster types between 1995 and 2014.',
									description_pt: 'Valor em R$ dos prejuízos privados na agricultura, pecuária, indústria e setor de serviços decorrentes de todos os tipos de desastre entre 1995 e 2014.'},
								'private_losses_ag': {name: 'Private Losses Agriculture', name_pt: 'Prejuízos privados na agricultura', column: 'SUM_Private_Losses_Agriculture', type: 'geojson', layer: 'fill', url: "/Brazil_MASTER_Data_For_OpenBrazil.geojson", colorramp: 'BuGn',
									description: '$ value of total agricultural losses resulting from all disaster types between 1995 and 2014.',
									description_pt: 'Valor em R$ do prejuízo total na agricultura, decorrente de todos os tipos de desastre entre 1995 e 2014.'},
								'private_losses_industry': {name: 'Private Losses Industry', name_pt: 'Prejuízos privados na indústria', column: 'SUM_Private_Losses_Industry', type: 'geojson', layer: 'fill', url: "/Brazil_MASTER_Data_For_OpenBrazil.geojson", colorramp: 'BuGn',
									description: '$ value of total industry losses resulting from all disaster types between 1995 and 2015.',
									description_pt: 'Valor em R$ do prejuízo total na indústria, decorrente de todos os tipos de desastre entre 1995 e 2015.'},
								'private_losses_livestock': {name: 'Private Losses Livestock', name_pt: 'Prejuízos privados na pecuária', column: 'SUM_Private_Losses_Livestock', type: 'geojson', layer: 'fill', url: "/Brazil_MASTER_Data_For_OpenBrazil.geojson", colorramp: 'BuGn',
									description: '$ value of total losses in livestock resulting from all disaster types between 1995 and 2014.',
									description_pt: 'Valor em R$ do prejuízo total na pecuária, decorrente de todos os tipos de desastre entre 1995 e 2014.'},
								'private_losses_services': {name: 'Private Losses Services', name_pt: 'Prejuízos privados nos serviços', column: 'SUM_Private_Losses_Services', type: 'geojson', layer: 'fill', url: "/Brazil_MASTER_Data_For_OpenBrazil.geojson", colorramp: 'BuGn',
									description: '$ value of total losses in the service provision sector resulting from all disaster types between 1995 and 2014.',
									description_pt: 'Valor em R$ do prejuízo total no setor de serviços, decorrente de todos os tipos de desastre entre 1995 e 2014.'},
								'public_losses': {name: 'Public Losses', name_pt: 'Prejuízos públicos', column: 'SUM_Public_Losses', type: 'geojson', layer: 'fill', url: "/Brazil_MASTER_Data_For_OpenBrazil.geojson", colorramp: 'BuGn',
									description: '$ value of public losses resulting from all disaster types between 1995 and 2014.',
									description_pt: 'Valor em R$ do prejuízo público total decorrente de todos os tipos de desastre entre 1995 e 2014.'},
								'total_losses': {name: 'Total Losses', name_pt: 'Prejuízos totais', column: 'SUM_Total_Losses', type: 'geojson', layer: 'fill', url: "/Brazil_MASTER_Data_For_OpenBrazil.geojson", colorramp: 'BuGn',
									description: '$ value of total public and private losses resulting from all disaster types between 1995 and 2014.',
									description_pt: 'Valor em R$ do prejuízo total, público e privado, decorrente de todos os tipos de desastre entre 1995 e 2014.'},
								'total_material_damage': {name: 'Total Material Damage', name_pt: 'Dano material total', column: 'SUM_Total_Material_Damage', type: 'geojson', layer: 'fill', url: "/Brazil_MASTER_Data_For_OpenBrazil.geojson", colorramp: 'BuGn',
									description: '$ value of total material damage related to infrastructure, housing, and other facilities resulting from all disaster types between 1995 and 2014.',
									description_pt: 'Valor em R$ do total de danos materiais relativos a infraestruturas, residências e outras instalações, decorrentes de todos os tipos de desastre entre 1995 e 2014.'},
								'value_damaged_housing': {name: 'Value of Damaged Housing', name_pt: 'Valor das casas danificadas', column: 'SUM_Value_of_Damaged_Housing', type: 'geojson', layer: 'fill', url: "/Brazil_MASTER_Data_For_OpenBrazil.geojson", colorramp: 'BuGn',
									description: '$ value of partial and total damage to housing resulting from all disaster types between 1995 and 2014.',
									description_pt: 'Valor total e parcial, em R$, dos danos a residências, decorrentes de todos os tipos de desastre entre 1995 e 2014.'}
				},
				'Fires': {
							'fires_viirs_24': {name: 'VIIRS 24 Hour', name_pt: 'VIIRS 24 horas', type: 'wms', url: "https://firms.modaps.eosdis.nasa.gov/wms/key/dae91e64ca1a7b420ea88bde9936b1c0/?REQUEST=GetMap&srs=EPSG:3857&layers=fires_viirs_24&SYMBOLS=circle,circle&SIZE=50,30&WIDTH=512&HEIGHT=512&BBOX={bbox-epsg-3857}",
									description: 'Hotspots detected over the previous 24 hours. Fire Information for Resource Management System (FIRMS) distributes Near Real-Time active fire data as detected by satellite. FIRMS displays hotspots detected within a 375 meter pixel. A single fire may be represented by multiple points on the map.',
									description_pt: 'Hotspots detectados nas ultimas 24 horas. O Sistema Informações de Incêndios para a Gerencia de Recursos (FIRMS) disponibiliza dados de incêndios ativos detectados por satélite em Tempo Quase Real. FIRMS exibe Hotspots detectados dentro de um pixel de 375 metros. Um único incêndio pode ser representado em vários pontos no mapa.'},
								'fires_viirs_7': {name: 'VIIRS 7 Day', name_pt: 'VIIRS 7 dias', type: 'wms', url: "https://firms.modaps.eosdis.nasa.gov/wms/key/dae91e64ca1a7b420ea88bde9936b1c0/?REQUEST=GetMap&srs=EPSG:3857&layers=fires_viirs_7&SYMBOLS=circle,circle&SIZE=50,30&WIDTH=512&HEIGHT=512&BBOX={bbox-epsg-3857}",
									description: 'Hotspots detected over the previous 7 days. Fire Information for Resource Management System (FIRMS) distributes Near Real-Time active fire data as detected by satellite. FIRMS displays hotspots detected within a 375 meter pixel. A single fire may be represented by multiple points on the map.',
									description_pt: 'Hotspots detectados nas ultimas 7 dias. O Sistema Informações de Incêndios para a Gerencia de Recursos (FIRMS) disponibiliza dados de incêndios ativos detectados por satélite em Tempo Quase Real. FIRMS exibe Hotspots detectados dentro de um pixel de 375 metros. Um único incêndio pode ser representado em vários pontos no mapa.'},
								'terra_14': {name: 'MODIS Fire 14 Day', name_pt: 'MODIS Incêndios 14 Dias', type: 'gee', url: '/api/layers/fire14',
									colors: ['darkred', 'FireBrick', 'crimson'],
									values: ['Possible', 'Probable', 'Definite'],
									values_pt: ['Possível', 'Provável', 'Definido'],
									description: 'The MOD14A1 V6 dataset provides daily fire mask composites at 1km resolution derived from the MODIS 4- and 11-micrometer radiances. The fire detection strategy is based on absolute detection of a fire (when the fire strength is sufficient to detect), and on detection relative to its background (to account for letiability of the surface temperature and reflection by sunlight).',
									description_pt: 'O MOD14A1 V6 conjunto de dados disponibiliza atualizações diárias de compositos de mascaras de incêndios a 1km derivido dos MODIS 4 e 11-micrometro radiações. A estratégia de detecção de incêndios e baseado na detecção absoluta de um incêndio (quando a força de um fogo e suficiente para detectar), e na detecção em relação ao seu plano de fundo (para lelet em conta a letiabilidade da temperatura da superfície e reflexão pela luz solar).'}
							,
								'terra_30': {name: 'MODIS Fire 30 Day', name_pt: 'MODIS Incêndios 30 Dias', type: 'gee', url: '/api/layers/fire30',
									colors: ['darkred', 'FireBrick', 'crimson'],
									values: ['Possible', 'Probable', 'Definite'],
									values_pt: ['Possível', 'Provável', 'Definido'],
									description: 'The MOD14A1 V6 dataset provides daily fire mask composites at 1km resolution derived from the MODIS 4- and 11-micrometer radiances. The fire detection strategy is based on absolute detection of a fire (when the fire strength is sufficient to detect), and on detection relative to its background (to account for letiability of the surface temperature and reflection by sunlight).',
									description_pt: 'O MOD14A1 V6 conjunto de dados disponibiliza atualizações diárias de compositos de mascaras de incêndios a 1km derivido dos MODIS 4 e 11-micrometro radiações. A estratégia de detecção de incêndios e baseado na detecção absoluta de um incêndio (quando a força de um fogo e suficiente para detectar), e na detecção em relação ao seu plano de fundo (para lelet em conta a letiabilidade da temperatura da superfície e reflexão pela luz solar).'}
							}
				,
				'Deforestation': {
								'forma': {name: 'FORMA Deforstation Alerts', name_pt: 'Alertas de Desmatamento FORMA', type: 'gee', url: '/api/layers/deforestation',
									colors: ['gray','blue', 'green', 'yellow', 'orange', 'red'],
									values: [0, 25, 40, 55, 70, 80],
									values_pt: [0, 25, 40, 55, 70, 80],
									description: 'Provided by the World Resources Institute and Global Forest Watch. FORMA alerts are detected using a combination of two MODIS products: NDVI (Normalized Difference Vegetation Index) and FIRMS (Fires Information for Resource Management System). NDVI updates are processed every 16 days, while fire updates are processed daily. Models are developed individually for each ecogroup to relate the two inputs to the area of clearing, using the Hansen annual tree cover loss data to train the model. The minimum threshold to qualify as an alert is 25% of the pixel cleared, though thresholds lety by ecogroup to minimize false positives.',
									description_pt: 'Fornecidos pelo World Resources Institute e a Global Forest Watch. Os alertas FORMA são detectados por uma combinação de dois produtos MODIS: o Índice de Vegetação por Diferenças Normalizadas (NDVI, Normalized Difference Vegetation Index) e o Sistema de Informações sobre Incêndios para Gestão de Recursos (FIRMS, Fire Information for Resource Management System). As atualizações do NDVI são processadas a cada 16 dias e as atualizações de incêndios são processadas diariamente. Os modelos são desenvolvidos individualmente para cada ecogrupo para relacionar os dois insumos à área de desmatamento, usando dados anuais de perda de cobertura arbórea de Hansen para treinar o modelo. O mínimo que se qualifica como alerta é quando 25% do pixel foram limpos, embora os limites letiem de acordo com o ecogrupo para minimizar a probabilidade de falsos positivos.'}
							}
				};

export default class ProgramMap extends Component {
	constructor(props) {
		super(props);

		let focusArea = 'all';
      	if (props.location) {       
      		if (props.location.state) {       
      			if (props.location.state.focusArea) {       
      				focusArea = props.location.state.focusArea;       
      			}       
      		}      
      	}

      	let layers = ['fa1', 'percent_poor'];
		let bounds = [[-82.079089, -35.275418], [-29.959949, 7.413436]];
		if (props.layers)
			layers = props.layers;
		if (props.bounds)
			bounds = props.bounds;

		this.state={
			basemapStyle: 'streets-v11',
			legendShowing: true,
			programLegendShowing: true,
			layersAdded: [],
			focusArea: focusArea,
			faData: null, 
			ssaData: null,
			stateData: null,
			selectedState: 'Acre',
			ifcLayerShowing: false,
			layers: layers,
			bounds: bounds,
			fa_by_state:[],
			dict_faByState:[],
			
		}
		this.addLayer = this.addLayer.bind(this);
		this.addLayerClick = this.addLayerClick.bind(this);
		this.removeLayer = this.removeLayer.bind(this);
		this.changeFocusArea = this.changeFocusArea.bind(this);
		this.getSSAData = this.getSSAData.bind(this);
		this.setSelectedState = this.setSelectedState.bind(this);
		this.toggleSSALayer = this.toggleSSALayer.bind(this);
		this.toggleIfc = this.toggleIfc.bind(this);
		this.fetchFocusAreaByState = this.fetchFocusAreaByState.bind(this);
		//this.blinkLayer = this.blinkLayer(this);

		this.markers = {};
		this.markersOnScreen = {};
	}


	async fetchFocusAreaByState() {
		console.log('Fired ===> fetchFocusAreaByState')
		axios.get(`${API_URL}/projects_fa_by_state`).then(result => {
			this.setState({ fa_by_state: result.data});
			let stateData = new Map();
			for (let value of result.data) {
				let details = {
					estado: value.state,
					focus_area_1_funding: value.focus_area_1_funding,
					focus_area_2_funding: value.focus_area_2_funding,
					focus_area_3_funding: value.focus_area_3_funding
				}

				stateData = stateData.set(value.state, details);
			}
			
			this.setState({dict_faByState:stateData});
		}).catch((error) => {
			return null;
		});
	}

	componentWillReceiveProps(nextProps){
		if (!_.isEqual(this.props, nextProps)){
			if (!_.isEqual(this.props.passedState, nextProps.passedState)){
				this.setSelectedState(nextProps.passedState);
			}
			if (!_.isEqual(this.props.projectData, nextProps.projectData)){
				this.projectData = nextProps.projectData;
				if (this.map && this.map.isStyleLoaded())
					this.addProjectLayer();
			}
			if (!_.isEqual(this.props.refresh, nextProps.refresh)){
				console.log('Fired componentWillReceiveProps');
				this.blinkLayer();
			}
		}
	}

	componentDidMount() {
		this.fetchFocusAreaByState();
		console.log('Fetch From componentDidMount');
		this.createMap();
	}

	numberWithCommas(x) {
		return x.toLocaleString('en-US');
	}

	updateMarkers(remove) {
		if (this.state.layersAdded.indexOf('fa1') >= 0 && this.state.dict_faByState.size > 0) {
			this.fetchFocusAreaByState();
			console.log('Updating MARKERS ');
			let newMarkers = {};
			let features = this.state.faData;
			
			if (features) {
				//reset for change in fa
				if (remove) {
					document.querySelectorAll('.fa-marker').forEach(e => e.remove());
					this.markers = {};
					this.markersOnScreen = {};
				}

				// for every cluster on the screen, create an HTML marker for it (if we didn't yet),
				// and add it to the map if it's not there already
				for (let i = 0; i < features.length; i++) {
					const feature = features[i];
					let coords = feature.geometry.coordinates;
					let props = feature.properties;
					let id = feature.id;


					if (!(this.state.dict_faByState === null && this.state.dict_faByState.size > 0)) {
						const featureStateFA = this.state.dict_faByState.get(feature.properties.ESTADO);

						props.Focus_Area_1_Funding = 0.00;
						props.Focus_Area_2_Funding = 0.00;
						props.Focus_Area_3_Funding = 0.00;

						if (featureStateFA) {
							props.Focus_Area_1_Funding = featureStateFA.focus_area_1_funding === null
								? 0.00
								: Number(featureStateFA.focus_area_1_funding);
							props.Focus_Area_2_Funding = featureStateFA.focus_area_2_funding === null
								? 0.00
								: Number(featureStateFA.focus_area_2_funding);
							props.Focus_Area_3_Funding = featureStateFA.focus_area_3_funding === null
								? 0.00
								: Number(featureStateFA.focus_area_3_funding);
						}
					}
					 
					let marker = this.markers[id];
					if (!marker) {
						let el = this.createDonutChart(props);
						marker = new mapboxgl.Marker({ element: el }).setLngLat(coords);
						this.markers[id] = marker;
					}

					newMarkers[id] = marker;
					 
					if (!this.markersOnScreen[id]) {
						marker.addTo(this.map);
					}
				}

				// for every marker we've added previously, remove those that are no longer visible
				for (const id in this.markersOnScreen) {
					if (!newMarkers[id]) {
						this.markersOnScreen[id].remove();
					}
				}
				this.markersOnScreen = newMarkers;
			}
		}
	}

	abbreviate(number, maxPlaces, forcePlaces, forceLetter) {
	  let abbr = '';
	  number = Number(number);

	  if(number >= 1e12) {
	    abbr = 'T';
	  }
	  else if(number >= 1e9) {
	    abbr = 'B';
	  }
	  else if(number >= 1e6) {
	    abbr = 'M';
	  }
	  else if(number >= 1e3) {
	    abbr = 'K';
	  }
	  else {
	    abbr = '';
	  }

	  return this.annotate(number, maxPlaces, forcePlaces, abbr);
	}

	annotate(number, maxPlaces, forcePlaces, abbr) {
	  // set places to false so will not round
	  let rounded = 0;
	  switch (abbr) {
	    case 'T':
	      rounded = number / 1e12;
	      break;
	    case 'B':
	      rounded = number / 1e9;
	      break;
	    case 'M':
	      rounded = number / 1e6;
	      break;
	    case 'K':
	      rounded = number / 1e3;
	      break;
	    case '':
	      rounded = number;
	      break;
	  }

	  if (maxPlaces !== false) {
	    let test = new RegExp('\\.\\d{' + (maxPlaces + 1) + ',}$');

		if (test.test(('' + rounded))) {
			rounded = rounded.toFixed(maxPlaces);
	    }
	  }

	  if (forcePlaces !== false) {
	    rounded = Number(rounded).toFixed(forcePlaces);
	  }
	  
	  return rounded + abbr;
	}

	// code for creating an SVG donut chart from feature properties
	createDonutChart(props) {
		let offsets = [];
		let counts = [];

		if (this.state.focusArea === 'all') {
			counts = [
				props.Focus_Area_1_Funding,
				props.Focus_Area_2_Funding,
				props.Focus_Area_3_Funding 
			];
		} else if (this.state.focusArea === 'Focus_Area_1_Funding') {
			counts = [props.Focus_Area_1_Funding, 0, 0];
		} else if (this.state.focusArea === 'Focus_Area_2_Funding') {
			counts = [0, props.Focus_Area_2_Funding, 0];
		} else if (this.state.focusArea === 'Focus_Area_3_Funding') {
			counts = [0, 0, props.Focus_Area_3_Funding];
		}

		let total = 0;
		for (let i = 0; i < counts.length; i++) {
			offsets.push(total);
			total += counts[i];
		}
		let fontSize = total >= 500000000 ? 20 :total >= 300000000 ? 18 : total >= 200000000 ? 16 : total >= 100000000 ? 12 : 10;
		let r = total >= 500000000 ? 60 :total >= 300000000 ? 50 : total >= 200000000 ? 40 : total >= 100000000 ? 30 : 25;
		let r0 = Math.round(r * 0.6);
		let w = r * 2;
		 
		let html =
			'<svg class="fa-marker" width="' +
			w +
			'" height="' +
			w +
			'" viewbox="0 0 ' +
			w +
			' ' +
			w +
			'" text-anchor="middle" style="font: ' +
			fontSize +
			'px sans-serif" opacity="0.8" >';
		 
		for (let i = 0; i < counts.length; i++) {
			html += this.donutSegment(
				offsets[i] / total,
				(offsets[i] + counts[i]) / total,
				r,
				r0,
				colors[i]
			);
		}
		html +=
			'<circle cx="' +
			r +
			'" cy="' +
			r +
			'" r="' +
			r0 +
			'" fill="white" /><text dominant-baseline="central" transform="translate(' +
			r +
			', ' +
			r +
			')">$' +
			this.abbreviate(total, 2, false, false) +
			'</text></svg>';
		 
		let el = document.createElement('div');
		el.innerHTML = html;
		return el.firstChild;
	}

	donutSegment(start, end, r, r0, color) {
		if (end - start === 1) end -= 0.00001;
		let a0 = 2 * Math.PI * (start - 0.25);
		let a1 = 2 * Math.PI * (end - 0.25);
		let x0 = Math.cos(a0),
			y0 = Math.sin(a0);
		let x1 = Math.cos(a1),
			y1 = Math.sin(a1);
		let largeArc = end - start > 0.5 ? 1 : 0;
		 
		return [
			'<path d="M',
			r + r0 * x0,
			r + r0 * y0,
			'L',
			r + r * x0,
			r + r * y0,
			'A',
			r,
			r,
			0,
			largeArc,
			1,
			r + r * x1,
			r + r * y1,
			'L',
			r + r0 * x1,
			r + r0 * y1,
			'A',
			r0,
			r0,
			0,
			largeArc,
			0,
			r + r0 * x0,
			r + r0 * y0,
			'" fill="' + color + '" />'
		].join(' ');
	}

	addLayerClick(layerID) {
		let layerRemoved = false;
		//remove all other layers except focus areas
		for (let i = 0; i < this.state.layersAdded.length; i++) {
		    if (this.state.layersAdded[i] != 'fa1' && this.state.layersAdded[i] != 'ssaLayer'){
		    	layerRemoved = true;
		    	let section;
				Object.keys(layers).map((key,index) => {
					if (Object.keys(layers[key]).indexOf(this.state.layersAdded[i]) != -1){
						section = key;
					}
				});

				this.map.removeLayer(this.state.layersAdded[i]);
				
				if (layers[section][this.state.layersAdded[i]].type != 'geojson')
					this.map.removeSource(this.state.layersAdded[i]);
				
				let newLayers = this.state.layersAdded;
		        newLayers = newLayers.filter(e => e !== this.state.layersAdded[i]);
		        this.setState({layersAdded: newLayers}, () => {
		        	//once layer is removed and state is updated add new one.
		        	this.addLayer(layerID);
		        });
			}
		}

		if (!layerRemoved)
			this.addLayer(layerID);
	}

	addLayer(layerID) {
		if (!this.map.getLayer(layerID)){
			let section;
			Object.keys(layers).map((key,index) => {
				if (Object.keys(layers[key]).indexOf(layerID) != -1){
					section = key;
				}
			});

			if (layerID == 'fa1' || layerID == 'fa2' || layerID == 'fa3') {
				//check if source is already there. need to toggle 
				if (focusAreas[layerID].type == 'wms' || focusAreas[layerID].type == 'arcgis' ) {
					this.map.addLayer({
			            "id": layerID,
			            "type": "raster",
			            "minzoom": 0,
			            "maxzoom": 22,
			            "source": {
			                "type": "raster",
			                "tiles": [focusAreas[layerID].url],
			                "tileSize": 512
			            },
			            "paint": {
			            	"raster-opacity": 1.0
			            }
			        })
				}else if (focusAreas[layerID].type == 'geojson') {
					if (!this.map.getSource(focusAreas[layerID].url)) {
						let mag1 = ['get', 'Focus_Area_1_Funding'];
						let mag2 = ['get', 'Focus_Area_2_Funding'];
						let mag3 = ['get', 'Focus_Area_3_Funding'];

						this.map.addSource(focusAreas[layerID].url, {
							'type': 'geojson',
							'data': focusAreas[layerID].url,
							'generateId': true
						});
					}

					this.map.addLayer({
						'id': layerID,
						'type': 'circle',
						'source': focusAreas[layerID].url,
						//'filter': ['!=', 'cluster', true],
						'paint': focusAreas[layerID].style
					});

					let popup = new mapboxgl.Popup({
						closeButton: true,
						closeOnClick: false
					});

					let t = this;

					this.map.on('click', layerID, function(e) {
						e.originalEvent.cancelBubble = true;
						//Change the cursor style as a UI indicator.
						t.map.getCanvas().style.cursor = 'pointer';
						 
						let coordinates = e.features[0].geometry.coordinates.slice();
						let fa1 = 0.00;
						let fa2 = 0.00;
						let fa3 = 0.00;

						if ( ! (t.state.dict_faByState.get(e.features[0].properties.ESTADO) == null ) ) {
							fa1 = t.state.dict_faByState.get(e.features[0].properties.ESTADO).focus_area_1_funding; //e.features[0].properties.Focus_Area_1_Funding;
							fa2 = t.state.dict_faByState.get(e.features[0].properties.ESTADO).focus_area_2_funding;
							fa3 = t.state.dict_faByState.get(e.features[0].properties.ESTADO).focus_area_3_funding;
						}
						let name = e.features[0].properties.ESTADO;
						 
						// Ensure that if the map is zoomed out such that multiple
						// copies of the feature are visible, the popup appears
						// over the copy being pointed to.
						while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
							coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
						}
						
						let text = {};
					    if (t.props.location) { //while we still have pages to translate don't want to break them
				            if (t.props.location.pathname.substring(0,4) !== "/pt/") {
				                text = textEnglish;
				            } else {
				                text = textPortuguese;
				            }
				        } else {
				            text = textEnglish;
				        }
						// Populate the popup and set its coordinates
						// based on the feature found.
						popup.setLngLat(e.lngLat)
						.setHTML('<div> \
									<span style="font-size: 14px; font-weight: bold; text-align: center;">' + name + '</span> \
									<div class="row"><div class="col-md-8"><div class="fa-color" style="background-color:' + colors[0] + ';"></div>' + text.focus_area_1_title + '</div><div class="col-md-4"><span style="font-weight: bold;">$'+ t.abbreviate(fa1, 1, false, false) + '</span></div></div> \
									<div class="row"><div class="col-md-8"><div class="fa-color" style="background-color:' + colors[1] + ';"></div>' + text.focus_area_2_title + '</div><div class="col-md-4"><span style="font-weight: bold;">$'+ t.abbreviate(fa2, 1, false, false) + '</span></div></div> \
									<div class="row"><div class="col-md-8"><div class="fa-color" style="background-color:' + colors[2] + ';"></div>' + text.focus_area_3_title + '</div><div class="col-md-4"><span style="font-weight: bold;">$'+ t.abbreviate(fa3, 1, false, false) + '</span></div></div> \
								 </div>')
						.addTo(t.map);
					});
					 
					// When the mouse leaves the state-fill layer, update the feature state of the
					// previously hovered feature.
					// this.map.on('mouseleave', layerID, function() {
					// 	popup.remove();
					// });


					// Initialize the map
					
					this.map.on('render', async () => {
						
						//if (!(this.map.isSourceLoaded(focusAreas[layerID].url))) return;
						//console.log('Map on Render');
						//const w = await this.fetchFocusAreaByState();
						//this.updateMarkers(true);
						
					});
				}
				

			    let newLayers = this.state.layersAdded;
		        newLayers.push(layerID);
		        this.setState({layersAdded: newLayers}, () => {
		        	this.updateMarkers(true);
		        });
			} else if (layers[section][layerID].type == 'gee') {
				axios.get(`${API_URL}/${layers[section][layerID].url}`).then(result => {
					let layer = {
					    id: layerID,
					    type: 'raster',
					    source: {
					      type: 'raster',
					      tiles: [
					        result.data
					      ],
					      tileSize: 256
					    },
					    paint: {}
					 };

					//see if focus area layer
					let faLayer;
					for (let i = 0; i < this.state.layersAdded.length; i++) {
					    if (this.state.layersAdded[i] == 'fa1' || this.state.layersAdded[i] == 'fa2' || this.state.layersAdded[i] == 'fa3'){
							faLayer = this.state.layersAdded[i];
							break;
						}
					}

				/* 	if (faLayer)
			        	this.map.addLayer(layer, 'admLayer');
			        else
			        	this.map.addLayer(layer, 'admLayer'); */

					this.map.addLayer(layer, 'admLayer');

			        let newLayers = this.state.layersAdded;
			        newLayers.push(layerID);
			        this.setState({layersAdded: newLayers});
				}).catch((error) => {
			    })
			}else{

				let layer;

				if (layers[section][layerID].type == 'wms' || layers[section][layerID].type == 'arcgis' ) {
					layer = {
			            "id": layerID,
			            "type": "raster",
			            "minzoom": 0,
			            "maxzoom": 22,
			            "source": {
			                "type": "raster",
			                "tiles": [layers[section][layerID].url],
			                "tileSize": 512
			            },
			            "paint": {
			            	"raster-opacity": 1.0
			            }
			        }
				}else if (layers[section][layerID].type == 'geojson') {
					if (!this.map.getSource(layers[section][layerID].url)) {
						this.map.addSource(layers[section][layerID].url, {
							'type': 'geojson',
							'data': layers[section][layerID].url
						});
					}

					let style; 
					//if no preset style calculate paint
					if (!layers[section][layerID].style && layers[section][layerID].url == "/Brazil_MASTER_Data_For_OpenBrazil.geojson"){
						function jenksMatrices(data, n_classes) {

					        // in the original implementation, these matrices are referred to
					        // as `LC` and `OP`
					        //
					        // * lower_class_limits (LC): optimal lower class limits
					        // * letiance_combinations (OP): optimal letiance combinations for all classes
					        let lower_class_limits = [],
					            letiance_combinations = [],
					            // loop counters
					            i, j,
					            // the letiance, as computed at each step in the calculation
					            letiance = 0;

					        // Initialize and fill each matrix with zeroes
					        for (let i = 0; i < data.length + 1; i++) {
					            let tmp1 = [], tmp2 = [];
					            for (j = 0; j < n_classes + 1; j++) {
					                tmp1.push(0);
					                tmp2.push(0);
					            }
					            lower_class_limits.push(tmp1);
					            letiance_combinations.push(tmp2);
					        }

					        for (let i = 1; i < n_classes + 1; i++) {
					            lower_class_limits[1][i] = 1;
					            letiance_combinations[1][i] = 0;
					            // in the original implementation, 9999999 is used but
					            // since Javascript has `Infinity`, we use that.
					            for (j = 2; j < data.length + 1; j++) {
					                letiance_combinations[j][i] = Infinity;
					            }
					        }

					        for (let l = 2; l < data.length + 1; l++) {

					            // `SZ` originally. this is the sum of the values seen thus
					            // far when calculating letiance.
					            let sum = 0,
					                // `ZSQ` originally. the sum of squares of values seen
					                // thus far
					                sum_squares = 0,
					                // `WT` originally. This is the number of 
					                w = 0,
					                // `IV` originally
					                i4 = 0;

					            // in several instances, you could say `Math.pow(x, 2)`
					            // instead of `x * x`, but this is slower in some browsers
					            // introduces an unnecessary concept.
					            for (let m = 1; m < l + 1; m++) {

					                // `III` originally
					                let lower_class_limit = l - m + 1,
					                    val = data[lower_class_limit - 1];

					                // here we're estimating letiance for each potential classing
					                // of the data, for each potential number of classes. `w`
					                // is the number of data points considered so far.
					                w++;

					                // increase the current sum and sum-of-squares
					                sum += val;
					                sum_squares += val * val;

					                // the letiance at this point in the sequence is the difference
					                // between the sum of squares and the total x 2, over the number
					                // of samples.
					                letiance = sum_squares - (sum * sum) / w;

					                i4 = lower_class_limit - 1;

					                if (i4 !== 0) {
					                    for (j = 2; j < n_classes + 1; j++) {
					                        if (letiance_combinations[l][j] >=
					                            (letiance + letiance_combinations[i4][j - 1])) {
					                            lower_class_limits[l][j] = lower_class_limit;
					                            letiance_combinations[l][j] = letiance +
					                                letiance_combinations[i4][j - 1];
					                        }
					                    }
					                }
					            }

					            lower_class_limits[l][1] = 1;
					            letiance_combinations[l][1] = letiance;
					        }

					        return {
					            lower_class_limits: lower_class_limits,
					            letiance_combinations: letiance_combinations
					        };
					    };

					    // # [Jenks natural breaks optimization](http://en.wikipedia.org/wiki/Jenks_natural_breaks_optimization)
					    //
					    // Implementations: [1](http://danieljlewis.org/files/2010/06/Jenks.pdf) (python),
					    // [2](https://github.com/vvoovv/djeo-jenks/blob/master/main.js) (buggy),
					    // [3](https://github.com/simogeo/geostats/blob/master/lib/geostats.js#L407) (works)
					    function jenks(data, n_classes) {

					        // sort data in numerical order
					        data = data.slice().sort(function (a, b) { return a - b; });

					        // get our basic matrices
					        let matrices = jenksMatrices(data, n_classes),
					            // we only need lower class limits here
					            lower_class_limits = matrices.lower_class_limits,
					            k = data.length - 1,
					            kclass = [],
					            countNum = n_classes;

					        // the calculation of classes will never include the upper and
					        // lower bounds, so we need to explicitly set them
					        kclass[n_classes] = data[data.length - 1];
					        kclass[0] = data[0];

					        // the lower_class_limits matrix is used as indexes into itself
					        // here: the `k` letiable is reused in each iteration.
					        while (countNum > 1) {
					            kclass[countNum - 1] = data[lower_class_limits[k][countNum] - 1];
					            k = lower_class_limits[k][countNum] - 1;
					            countNum--;
					        }

					        return kclass;
					    };

						if (typeof this.state.stateData[0].properties[layers[section][layerID].column] === 'string'){
							style = {
								'fill-color': [
										'match',
										['get', layers[section][layerID].column]
									],
								'fill-opacity': 0.75
							};

							//get unique values
							let uniques = [];
							for (let i = 0; i < this.state.stateData.length; i++) {
								if (uniques.indexOf(this.state.stateData[i].properties[layers[section][layerID].column]) == -1)
									uniques.push(this.state.stateData[i].properties[layers[section][layerID].column]);
					        }

							for (let i = 0; i <  uniques.length; i++) {
					            style['fill-color'].push( uniques[i]);
					            style['fill-color'].push(colorRampColors[layers[section][layerID].colorramp][7][i + (7 -  uniques.length)]);
					        }
					        style['fill-color'].push(colorRampColors[layers[section][layerID].colorramp][7][0]);
						}else{
							style = {
								'fill-color': [
										'interpolate',
										['linear'],
										['get', layers[section][layerID].column]
									],
								'fill-opacity': 0.75
							};

							//detect whether number or string values
							let arr = []
							for (let i = 0; i < this.state.stateData.length; i++) {
								arr.push(this.state.stateData[i].properties[layers[section][layerID].column]);
					        }

							let length = 6;
							if (arr.length < 6)
								length = arr.length - 1;
							let jenks2 = jenks(arr, length);

							for (let i = 0; i < jenks2.length; i++) {
					            style['fill-color'].push(jenks2[i]);
					            style['fill-color'].push(colorRampColors[layers[section][layerID].colorramp][7][i + (7 - jenks2.length)]);
					        }
						}
						

					}else
						style = layers[section][layerID].style;

					layer = {
				        'id': layerID,
				        'type': layers[section][layerID].layer,
				        'source': layers[section][layerID].url,
				        'paint': style
				    }
				}else if (layers[section][layerID].type == 'image') {
					if (!this.map.getSource(layerID)) {
						this.map.addSource(layerID, {
							"type": "image",
						    "url": layers[section][layerID].url,
						    "coordinates": layers[section][layerID].coordinates
						});
					}

					layer = {
						'id': layerID,
						'type': 'raster',
						'source': layerID
					};
				}
				
				//see if focus area layer
				let faLayer;
				for (let i = 0; i < this.state.layersAdded.length; i++) {
				    if (this.state.layersAdded[i] == 'fa1' || this.state.layersAdded[i] == 'fa2' || this.state.layersAdded[i] == 'fa3'){
						faLayer = this.state.layersAdded[i];
						break;
					}
				}

				/* if (faLayer)
		        	this.map.addLayer(layer, 'admLayer');
		        else
		        	this.map.addLayer(layer, 'admLayer'); */

				this.map.addLayer(layer, 'admLayer');
		        let newLayers = this.state.layersAdded;
		        newLayers.push(layerID);
		        this.setState({layersAdded: newLayers});
			}
		}
	}

	removeLayer(layerID) {
		let source = layerID;
		let section;
		Object.keys(layers).map((key,index) => {
			if (Object.keys(layers[key]).indexOf(layerID) != -1){
				section = key;
			}
		});

		if (this.map.getLayer(layerID)){
			if (layerID == 'fa1' || layerID == 'fa2' || layerID == 'fa3') {
				this.map.removeLayer(layerID);
				document.querySelectorAll('.fa-marker').forEach(e => e.remove());
				if (focusAreas[layerID].type != 'geojson')
					this.map.removeSource(layerID);
			}else{
				this.map.removeLayer(layerID);
				if (layers[section][layerID].type != 'geojson')
					this.map.removeSource(layerID);
			}
			
			let newLayers = this.state.layersAdded;
	        newLayers = newLayers.filter(e => e !== layerID);
	        this.setState({layersAdded: newLayers});
		}
	}

	addProjectLayer() {
		if (!this.ibrd){
			let t = this;
			this.map.addSource('brazil', {
		        "type": "geojson",
		        "data": {type: "FeatureCollection", features: [
				{
					'type': 'Feature',
					'geometry': {
					'type': 'Point',
					'coordinates': [
						-52.284169,
						-10.566898
					]
					},
					'properties': {}
				}]}
		    });

			this.ifc = {
		                id: "ifc",
		                type: "circle",
		                source: 'brazil',
						paint: {
							'circle-color': 'orange',
							'circle-radius': 50,
							'circle-stroke-width': 1,
							'circle-stroke-color': '#555555'
						}
		            };

			this.map.addLayer(this.ifc);
        	
			this.map.setLayoutProperty("ifc", 'visibility', 'none');

        	//click events
        	this.map.on('click', 'ifc', function(e) {
            	e.originalEvent.cancelBubble = true;
				
            	let popup = new mapboxgl.Popup({
						closeButton: true,
						closeOnClick: true
					});

            	let feature = e.features[0];
				let coordinates = feature.geometry.coordinates.slice();
				

				//maybe beneficiary graph??
				 
				// Ensure that if the map is zoomed out such that multiple
				// copies of the feature are visible, the popup appears
				// over the copy being pointed to.
				while (Math.abs(feature.geometry.coordinates[0] - coordinates[0]) > 180) {
				coordinates[0] += feature.geometry.coordinates[0] > coordinates[0] ? 360 : -360;
				}

				let text = {};
				let path = '';
			    if (t.props.location) { //while we still have pages to translate don't want to break them
		            if (t.props.location.pathname.substring(0,4) !== "/pt/") {
		                text = textEnglish;
		            } else {
		                text = textPortuguese;
		                path = '/pt';
		            }
		        } else {
		            text = textEnglish;
		        }
				 
				let focus_area_1_content = '<div id="subprojectContent_1" class="subproject-content"><div class="back">< ' + text.back + '</div><h4>' + text.focus_area_1 + '</h4><div class="list-group">';
				let focus_area_2_content = '<div id="subprojectContent_2" class="subproject-content"><div class="back">< ' + text.back + '</div><h4>' + text.focus_area_2 + '</h4><div class="list-group">';
				let focus_area_3_content = '<div id="subprojectContent_3" class="subproject-content"><div class="back">< ' + text.back + '</div><h4>' + text.focus_area_3 + '</h4><div class="list-group">';
				let subproject_content = '<button id="1" type="button" class="list-group-item">' + text.focus_area_1 + '<i class="fa fa-chevron-right"></i></button> \
										<button id="2" type="button" class="list-group-item">' + text.focus_area_2 + '<i class="fa fa-chevron-right"></i></button> \
										<button id="3" type="button" class="list-group-item">' + text.focus_area_3 + '<i class="fa fa-chevron-right"></i></button>';

				for (let i = 0; i < t.projectData.length; i++) {
					let subproject = t.projectData[i];
					if (subproject.organization == 'IFC'){
						if (subproject.focus_area_1){
							focus_area_1_content += '<button id=1_' + subproject.id + ' type="button" class="focus-area list-group-item">' + (t.props.location.pathname.substring(0,4) !== "/pt/" ? subproject.title : subproject.title_pt) + '<i class="fa fa-chevron-right"></i></button>';
						}
						if (subproject.focus_area_2){
							focus_area_2_content += '<button id=2_' + subproject.id + ' type="button" class="focus-area list-group-item">' + (t.props.location.pathname.substring(0,4) !== "/pt/" ? subproject.title : subproject.title_pt) + '<i class="fa fa-chevron-right"></i></button>';
						}
						if (subproject.focus_area_3){
							focus_area_3_content += '<button id=3_' + subproject.id + ' type="button" class="focus-area list-group-item">' + (t.props.location.pathname.substring(0,4) !== "/pt/" ? subproject.title : subproject.title_pt) + '<i class="fa fa-chevron-right"></i></button>';
						}
					}
				}

				//close divs
				focus_area_1_content += '</div></div>';
				focus_area_2_content += '</div></div>';
				focus_area_3_content += '</div></div>';

				let html = $('<div> \
								<div class="project-content"> \
									' + text.ifc + ' \
									 \
									<div style="font-size: 14px; font-weight: bold; margin-top: 10px; margin-bottom: 10px;">' + text.select_focus + '</div> \
									<div class="sub-projects list-group">'+ subproject_content + '</div> \
								 </div>' + focus_area_1_content + focus_area_2_content + focus_area_3_content + ' \
							</div>');

				html.find('.sub-projects button').click(function() {
					html.find('.project-content').hide();
					html.find('#subprojectContent_' + this.id).show();
		        });

		        html.find('.focus-area').click(function() {
					//somehow go to projects page
					//index of _ get id to right
					let id = this.id.split('_')[1];
					let focus = 'focus_area_' + this.id.split('_')[0];

					window.location = `${path}/projects?project=${id}&focus=${focus}`; //window.open(`/insert/your/path/here/${letiableName}`);
		        });

		        html.find('.back').click(function() {
		        	html.find('.subproject-content').hide();
		        	html.find('.project-content').show();
		        });

				// Populate the popup and set its coordinates
				// based on the feature found.
				popup.setLngLat(feature.geometry.coordinates)
				.setDOMContent(html[0])
				.addTo(t.map);
			});
		}
	}

	toggleIfc() {
		let ifcLayerShowing;
		if (this.map.getLayoutProperty("ifc", 'visibility') == "visible"){
			this.map.setLayoutProperty("ifc", 'visibility', 'none');
			ifcLayerShowing = false;
		}else{
			this.map.setLayoutProperty("ifc", 'visibility', 'visible');
			ifcLayerShowing = true;
		}
		this.setState({ifcLayerShowing});
	}

	blinkLayer() {
		console.log('Fired ==>  Blink layer  |  this.state.layersAdded? ', this.state.layersAdded);

		this.toggleFALayer();
		this.fetchFocusAreaByState();
		this.updateMarkers(true);
	
		
		
	}



	toggleFALayer() {
		console.log('Fired ==>  toggleFALayer ', this.state.layersAdded);
		if (this.state.layersAdded.indexOf('fa1') != -1) {
			this.removeLayer('fa1');
			console.log('Fired ==>  toggleFALayer ', this.state.layersAdded);
		} else {
			console.log('Fired ==>  toggleFALayer ', this.state.layersAdded);
			this.addLayer('fa1');
			console.log('Fired ==>  toggleFALayer ', this.state.layersAdded);
		}

		
		
	}

	toggleSSALayer() {
		if (this.state.layersAdded.indexOf('ssaLayer') != -1) {
			this.map.removeLayer('ssaLayer');
			this.map.removeLayer('photosLayer');
			this.map.removeLayer('cluster-count');
			this.map.removeLayer('unclustered-point');

			let newLayers = this.state.layersAdded;
	        newLayers = newLayers.filter(e => e !== 'ssaLayer');
	        this.setState({layersAdded: newLayers});
		}else{
			this.map.addLayer(this.ssaLayer);
			this.map.addLayer(this.photos);
			this.map.addLayer(this.clusterLayer);
			this.map.addLayer(this.pointLayer);

			let newLayers = this.state.layersAdded;
	        newLayers.push('ssaLayer');
	        this.setState({layersAdded: newLayers});
		}
	}

	changeFocusArea (e) {
		console.log('Change Focus AREA');
		e.preventDefault();
		this.setState({focusArea: e.currentTarget.id}, () => {
			this.updateMarkers(true);
		});
	}

	getSSAPhotos() {
		
		let t = this;
		axios.get(`${API_URL}/ssa/photos`).then((response) => {
			t.ssaPhotoData = response.data;

        	if (!t.photosLayer) {
	    		t.map.addSource('photos', {
			        "type": "geojson",
			        "data": {type: "FeatureCollection", features: []},
			        cluster: true,
					clusterMaxZoom: 12, // Max zoom to cluster points on
					clusterRadius: 40
			    });

				t.photos = {
	                id: "photosLayer",
	                type: "circle",
	                source: "photos",
	                // filter: ["has", "point_count"],
	                filter: ['has', 'point_count'],
					paint: {
					// Use step expressions (https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-step)
					// with three steps to implement three types of circles:
					//   * Blue, 20px circles when point count is less than 100
					//   * Yellow, 30px circles when point count is between 100 and 750
					//   * Pink, 40px circles when point count is greater than or equal to 750
						// 'circle-color': [
						// 	'step',
						// 	['get', 'point_count'],
						// 	'#2286F1',
						// 	50,
						// 	'#EEF122',
						// 	100,
						// 	'#EC3838'
						// ],
						'circle-color': '#2286F1',
						'circle-radius': [
							'step',
							['get', 'point_count'],
							10,
							10,
							20,
							50,
							30,
							100,
							40
						]
					}
	            };
	            t.map.addLayer(t.photos);

	            t.clusterLayer = {
					id: 'cluster-count',
					type: 'symbol',
					source: 'photos',
					filter: ['has', 'point_count'],
					layout: {
						'text-field': '{point_count_abbreviated}',
						'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
						'text-size': 12
					}
				};
	            t.map.addLayer(t.clusterLayer);

	            t.pointLayer = {
					id: 'unclustered-point',
					type: 'circle',
					source: 'photos',
					filter: ['!', ['has', 'point_count']],
					paint: {
						'circle-color': '#11b4da',
						'circle-radius': 6,
						'circle-stroke-width': 1,
						'circle-stroke-color': '#fff'
					}
				};

				t.map.addLayer(t.pointLayer);

				t.map.on('click', 'photosLayer', function(e) {
	            	e.originalEvent.cancelBubble = true;
					
					let popup = new mapboxgl.Popup({
						closeButton: true,
						closeOnClick: true
					});

					let features = t.map.queryRenderedFeatures(e.point, {
			            layers: ['photosLayer']
			          });

					let coordinates = e.features[0].geometry.coordinates.slice();

					while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
					coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
					}

					

					t.map.getSource('photos').getClusterLeaves(
			            features[0].properties.cluster_id,
			            100,
			            0,
			            function(err, leafFeatures){
				            if (err) {
				                return console.error('error while getting leaves of a cluster', err);
				            }
			              
				            let html = '<div class="image-content">';
				            for (let i = 0; i < leafFeatures.length; i++) {
								if (leafFeatures[i].properties.url.indexOf('tmp') < 0) {
				            		html += '<img alt="leaf" style="margin-bottom: 10px;width: 280px;" src="' + leafFeatures[i].properties.url + '"/>'
								}
				            }
				            html += '</div>';

			              	// Populate the popup and set its coordinates
							// based on the feature found.
							popup.setLngLat(e.lngLat)
							.setHTML(html)
							.addTo(t.map);
			            }
			          );

					// if (!t.spiderifier) {
					// 	t.spiderifier = new MapboxglSpiderifier(t.map, {
					// 		animate: true,
     //    					animationSpeed: 200,
				 //          	customPin: true,
     //    					initializeLeg: initializeSpiderLeg
				 //        })

				 //        function initializeSpiderLeg(spiderLeg){
					//         let pinElem = spiderLeg.elements.pin;
					//         let feature = spiderLeg.feature;
					//         let popup;

					//         pinElem.className = pinElem.className + ' fa-stack fa-lg';
					//         pinElem.innerHTML = '<i class="circle-icon fa fa-circle fa-stack-2x"></i>' +
					//                                 '<i class="type-icon fa fa-' + feature.type + ' fa-stack-1x"></i>'
					//         pinElem.style.color = feature.color;

					//         $(pinElem)
					//           .on('mouseenter', function(){
					//             popup = new mapboxgl.Popup({
					//               closeButton: true,
					//               closeOnClick: false,
					//               offset: MapboxglSpiderifier.popupOffsetForSpiderLeg(spiderLeg)
					//             });

					//             popup.setHTML('Icon used is <b>fa-' + feature.type+'</b>')
					//               .addTo(t.map)

					//             spiderLeg.mapboxMarker.setPopup(popup);
					//           })
					//           .on('mouseleave', function(){
					//             if(popup){
					//               popup.remove();
					//             }
					//           });
					//       }
					// }

					// t.spiderifier.unspiderfy();
			  //       if (!features.length) {
			  //         return;
			  //       } else if (t.map.getZoom() < SPIDERFY_FROM_ZOOM) {
			  //         t.map.easeTo({center: e.lngLat, zoom: t.map.getZoom() + 2});
			  //       } else {
			  //         t.map.getSource('photos').getClusterLeaves(
			  //           features[0].properties.cluster_id,
			  //           100,
			  //           0,
			  //           function(err, leafFeatures){
			  //             if (err) {
			  //               return console.error('error while getting leaves of a cluster', err);
			  //             }
			  //             let markers = _.map(leafFeatures, function(leafFeature){
			  //               return leafFeature.properties;
			  //             });
			  //             t.spiderifier.spiderfy(features[0].geometry.coordinates, markers);
			  //           }
			  //         );
			  //       }

				});

	            t.map.on('click', 'unclustered-point', function(e) {
	            	e.originalEvent.cancelBubble = true;
					let popup = new mapboxgl.Popup({
						closeButton: true,
						closeOnClick: true
					});

					let coordinates = e.features[0].geometry.coordinates.slice();
					let url = e.features[0].properties.url;
					let name = e.features[0].properties.name;

					//maybe beneficiary graph??
					 
					// Ensure that if the map is zoomed out such that multiple
					// copies of the feature are visible, the popup appears
					// over the copy being pointed to.
					while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
					coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
					}

					// Populate the popup and set its coordinates
					// based on the feature found.
					popup.setLngLat(e.lngLat)
					.setHTML('<div class="image-content"><div style="font-size: 14px; font-weight: bold; margin-top: 5px;">' + name + '</div> \
						<img alt="map image" style="width: 280px;" src="' + url + '"/></div>')
					.addTo(t.map);

				});

				//zoom to state on click
			    t.map.on('click', 'admLayer', function(e) {
			    	if (e.originalEvent.cancelBubble) {
			            return;
			        }
					if (e.features.length > 0) {
						t.setSelectedState(e.features[0]);
					}
				});
	        }else{
				t.map.getSource("photos").setData(data);
    			t.map.setLayoutProperty("photosLayer", 'visibility', 'visible');
			}
        	
        }).catch((err) => {
            // console.log(err.message);
        });
	}

	selectState(e) {

		let features = this.state.stateData.filter((feature) => {
			return Object.values(feature.properties).indexOf(e) > -1;
		});
		this.setSelectedState(features[0]);
	}

	setSelectedState(feature) {
		let coordinates = [];
		for (let i = 0; i < feature.geometry.coordinates.length; i++) {
			if (feature.geometry.type == "Polygon")
				coordinates.push(...feature.geometry.coordinates[i]);
			else
				coordinates.push(...feature.geometry.coordinates[i][0]);
		}

		let bounds = coordinates.reduce(function(bounds, coord) {
			return bounds.extend(coord);
		}, new mapboxgl.LngLatBounds(coordinates[0], coordinates[0]));
		 
		this.map.fitBounds(bounds, {
			padding: 30
		});

		this.setState({selectedState: feature.properties['ESTADO']});
		if (this.props.setSelectedState)
			this.props.setSelectedState(feature.properties['ESTADO']);
	}

	showProjectPopup(feature) {
		if (this.popup)
			this.popup.remove();

		this.popup = new mapboxgl.Popup({
						closeButton: true,
						closeOnClick: true
					});

		let coordinates = feature.geometry.coordinates.slice();
		let name = feature.properties.name;
		let site = feature.properties.site_url;
		let approvedDate = feature.properties.approved_date;
		let closingDate = feature.properties.closing_date;
		let disburseValue = feature.properties.disburse_value;
		let approved_date = new Date(approvedDate);
		let end_date = new Date(closingDate);
		let subProjects = feature.properties.subprojects;
		if (!Array.isArray(feature.properties.subprojects))
			subProjects = JSON.parse(feature.properties.subprojects);
		let numFamilies = feature.properties.num_families;
		let numFemale = feature.properties.num_female;
		let numYoung = feature.properties.num_young;
		//let description = e.features[0].properties.description;

		//maybe beneficiary graph??
		 
		// Ensure that if the map is zoomed out such that multiple
		// copies of the feature are visible, the popup appears
		// over the copy being pointed to.
		while (Math.abs(feature.geometry.coordinates[0] - coordinates[0]) > 180) {
			coordinates[0] += feature.geometry.coordinates[0] > coordinates[0] ? 360 : -360;
		}
		
		let text = {};
	    if (this.props.location) { //while we still have pages to translate don't want to break them
            if (this.props.location.pathname.substring(0,4) !== "/pt/") {
                text = textEnglish;
            } else {
                text = textPortuguese;
            }
        } else {
            text = textEnglish;
        }

		let subproject_html = '';
		let subproject_content = '';
		for (let i = 0; i < subProjects.length; i++) {
			let subproject = subProjects[i];
			subproject_html += '<button id="' + i + '" type="button" class="list-group-item ssa-project"> \
									<div style="float: left; width: 95%;">' + subproject.name + '</div> \
									<i class="fa fa-chevron-right"></i> \
								</button>'
			subproject_content += '<div id="subprojectContent_' + i + '" class="subproject-content"> \
										<div class="back">< ' + text.back + '</div> \
										<div style="font-size: 14px; font-weight: bold; margin-top: 5px;">' + subproject.name + '</div> \
										<div style="margin-top: 5px;"><span style="font-size: 12px; font-weight: bold; text-align: center;">Description: </span>' + subproject.description + '</div> \
										<div><span style="font-size: 12px; font-weight: bold;">Value: </span>' + this.abbreviate(subproject.financed_value, 2, false, false) + '</div> \
										<div><span style="font-size: 12px; font-weight: bold;">Begin Date: </span>' + new Date(subproject.begin_date).toLocaleDateString() + '</div> \
										<div><span style="font-size: 12px; font-weight: bold;">End Date: </span>' + new Date(subproject.end_date).toLocaleDateString() + '</div> \
										<div><span style="font-size: 12px; font-weight: bold;">Families: </span>' + this.abbreviate(subproject.num_families, 2, false, false) + '</div> \
										<div><span style="font-size: 12px; font-weight: bold;">Female: </span>' + this.abbreviate(subproject.num_female, 2, false, false) + '</div> \
										<div><span style="font-size: 12px; font-weight: bold;">Youth: </span>' + this.abbreviate(subproject.num_young, 2, false, false) + '</div> \
									</div>'
		}

		let html = $('<div> \
						<div class="project-content"> \
							<h4>' + name + '</h4> \
							<div><span style="font-size: 12px; font-weight: bold;">Value: </span>' + this.abbreviate(disburseValue, 2, false, false) + '</div> \
							<div><span style="font-size: 12px; font-weight: bold;">Approved Date: </span>' + approved_date.toLocaleDateString() + '</div> \
							<div><span style="font-size: 12px; font-weight: bold;">End Date: </span>' + end_date.toLocaleDateString() + '</div> \
							<a href="' + site + '" target="_blank">Visit Project Website</a> \
							<div style="font-size: 14px; font-weight: bold; margin-top: 10px;">Beneficiaries</div> \
							<div><span style="font-size: 12px; font-weight: bold;">Families: </span>' + this.abbreviate(numFamilies, 2, false, false) + '</div> \
							<div><span style="font-size: 12px; font-weight: bold;">Female: </span>' + this.abbreviate(numFemale, 2, false, false) + '</div> \
							<div><span style="font-size: 12px; font-weight: bold;">Youth: </span>' + this.abbreviate(numYoung, 2, false, false) + '</div> \
							<div style="font-size: 14px; font-weight: bold; margin-top: 10px; margin-bottom: 10px;">Active Sub-Projects</div> \
							<div class="sub-projects list-group">'+ subproject_html + '</div> \
						 </div>' + subproject_content + ' \
					</div>');

		html.find('.sub-projects button').click(function() {
			html.find('.project-content').hide();
			html.find('#subprojectContent_' + this.id).show();
        });

        html.find('.back').click(function() {
        	html.find('.subproject-content').hide();
        	html.find('.project-content').show();
        });

		// Populate the popup and set its coordinates
		// based on the feature found.
		this.popup.setLngLat(feature.geometry.coordinates)
		.setDOMContent(html[0])
		.addTo(this.map);


		//filter photo data and set source
    	let data = this.ssaPhotoData.features.filter(f => f.properties.project_id == feature.properties.id);
    	this.map.getSource("photos").setData({type: "FeatureCollection", features: data});
		this.map.setLayoutProperty("photosLayer", 'visibility', 'visible');
		this.map.setLayoutProperty("cluster-count", 'visibility', 'visible');
		this.map.setLayoutProperty("unclustered-point", 'visibility', 'visible');
	}

	getSSAData() {
		let t = this;
		axios.get(`${API_URL}/ssa/projects`).then((response) => {
			data = response.data;

        	if (!t.ssaLayer) {
	    		t.map.addSource('ssa', {
			        "type": "geojson",
			        "data": data
			    });

				t.ssaLayer = {
	                id: "ssaLayer",
	                type: "circle",
	                source: "ssa",
	                // filter: ["has", "point_count"],
	                minzoom: 5,
	                paint: {'circle-radius': [
										'step',
										['get', 'funds'],
										5,
										5000000,
										8,
										10000000,
										10,
										30000000,
										12,
										50000000,
										14,
										75000000,
										18,
										100000000,
										20,
										200000000,
										25,
										300000000,
										30
									],
									'circle-color': 'orange',
									'circle-stroke-width': 1,
									'circle-stroke-color': '#555555'}
	            };

	            t.map.on('click', 'ssaLayer', function(e) {
	            	e.originalEvent.cancelBubble = true;
					
	            	t.showProjectPopup(e.features[0]);
				});

	            t.map.addLayer(t.ssaLayer);
	        }else{
				t.map.getSource("ssa").setData(data);
    			t.map.setLayoutProperty("ssaLayer", 'visibility', 'visible');
			}

			let newLayers = this.state.layersAdded;
		    newLayers.push('ssaLayer');
			this.setState({ssaData: data, layersAdded: newLayers});
        	
        }).catch((err) => {
            // console.log(err.message);
        });
	}

	zoomToProject(feature) {
		// console.log('zoom hit');

		//zoom map
		this.map.flyTo({
			center: [parseFloat(feature.geometry.coordinates[0]), parseFloat(feature.geometry.coordinates[1])],
			zoom: 6,
			essential: true // this animation is considered essential with respect to prefers-reduced-motion
		});

		//show popup
		this.showProjectPopup(feature);
	}

    createMap() {
		
		//GET NEW ACCESS TOKEN!!!
		mapboxgl.accessToken = 'pk.eyJ1IjoiZ3NkcG0iLCJhIjoiY2thMmw0djZoMDBoajNnanZjdmo1YmJyZCJ9.dFE9IA22oAMiRu7gA6-JXQ';
		let bounds = [
		  [-110, -50.715772], // [west, south]
		  [-15.550795, 25.043728]  // [east, north]
		];

		this.map = new mapboxgl.Map({
		    container: this.mapContainer, // container id
		    style: 'mapbox://styles/mapbox/' + this.state.basemapStyle, // stylesheet location
		    //center: [-60.822986, -15.318398], // starting position [lng, lat]
		    //zoom: 3, // starting zoom
		    bounds: this.state.bounds,
		    pitch: 0,
		    bearing: 0,
    		preserveDrawingBuffer: true,
    		maxZoom: 19,
    		maxBounds: bounds
		});
		this.map.addControl(new mapboxgl.FullscreenControl({container: document.getElementById('programMap')}), 'top-right');
		this.map.addControl(new mapboxgl.NavigationControl(), 'top-right');
		
		//set basemap language
		// let language = 'en';
		// if (this.props.location.pathname.indexOf('/pt/') != -1)
		// 	language = 'pt';

		this.mapLanguage = new MapboxLanguage();
		this.map.addControl(this.mapLanguage);

		let t = this;
		this.map.on('load', function () {

			// t.map.setLayoutProperty('state-label', 'text-field', [
			// 	'format',
			// 	['get', 'name_en'],
			// 	{ 'font-scale': 1.2 },
			// 	'\n',
			// 	{},
			// 	['get', 'name'],
			// 	{
			// 		'font-scale': 0.8,
			// 		'text-font': [
			// 			'literal',
			// 			['DIN Offc Pro Italic', 'Arial Unicode MS Regular']
			// 		]
			// 	}
			// ]);

			if (!t.buildingsLayer) {
	        	//add 3d buildings
	            t.buildingsLayer = {
			        'id': '3d-buildings',
			        'source': 'composite',
			        'source-layer': 'building',
			        'filter': ['==', 'extrude', 'true'],
			        'type': 'fill-extrusion',
			        'minzoom': 15,
			        'paint': {
			            'fill-extrusion-color': '#aaa',

			            // use an 'interpolate' expression to add a smooth transition effect to the
			            // buildings as the user zooms in
			            'fill-extrusion-height': [
			                "interpolate", ["linear"], ["zoom"],
			                15, 0,
			                15.05, ["get", "height"]
			            ],
			            'fill-extrusion-base': [
			                "interpolate", ["linear"], ["zoom"],
			                15, 0,
			                15.05, ["get", "min_height"]
			            ],
			            'fill-extrusion-opacity': .6
			        }
			    };

				t.map.addLayer(t.buildingsLayer);
	        }

	        //add default focus areas
	        Object.keys(focusAreas).map((layer,index) => {
				if (t.state.layers.indexOf(layer) != -1) {
					t.addLayer(layer);
				}
			})
			
			
			t.getSSAData();
			t.getSSAPhotos();

	        if (!t.admLayer) {
	        	t.map.addSource('adm1', {
					'type': 'geojson',
					'data': '/Brazil_MASTER_Data_For_OpenBrazil.geojson',
					'generateId': true
				});
	        	
	            t.admLayer = {
			        'id': 'admLayer',
			        'type': 'fill',
			        'source': 'adm1',
			        'paint': {
						'fill-color': '#00427c',
						'fill-opacity': [
							'case',
							['boolean', ['feature-state', 'hover'], false],
							0.2,
							0
						]
					}
			    };

				t.map.addLayer(t.admLayer);

				t.admLineLayer = {
			        'id': 'admLineLayer',
			        'type': 'line',
			        'source': 'adm1',
			        'paint': {
						'line-color': '#00427c',
						'line-width': 3
					}
			    };
			    t.map.addLayer(t.admLineLayer);


			    
			    // When the user moves their mouse over the state-fill layer, we'll update the
				// feature state for the feature under the mouse.
				t.map.on('mousemove', 'admLayer', function(e) {
					if (e.features.length > 0) {
						if (hoveredStateId) {
							t.map.setFeatureState(
								{ source: 'adm1', id: hoveredStateId },
								{ hover: false }
							);
						}
						hoveredStateId = e.features[0].id;
						t.map.setFeatureState(
							{ source: 'adm1', id: hoveredStateId },
							{ hover: true }
						);
					}
				});
				 
				// When the mouse leaves the state-fill layer, update the feature state of the
				// previously hovered feature.
				t.map.on('mouseleave', 'admLayer', function() {
					if (hoveredStateId) {
						t.map.setFeatureState(
							{ source: 'adm1', id: hoveredStateId },
							{ hover: false }
						);
					}
					hoveredStateId = null;
				});
	        }

			t.map.on('move', function(e) {
				t.updateMarkers(false);
			});
			t.map.on('moveend', function(e) {
				t.updateMarkers(false);
			});

			//get point data
			axios.get(`${API_URL}/Brazil_MASTER_Data_For_OpenBrazil_POINTS.geojson`).then(result => {
				t.setState({faData: result.data.features}, () => {
					t.updateMarkers(false);
				});
		
			}).catch((error) => {
		    	
		    })

			// t.setState({faData: mapsPoints.features}, () => {
			// 	t.updateMarkers(false);
			// });
		
			// t.setState({stateData: mapsFeats.features}, () => {
			// 	if (t.props.setStateData)
			// 		t.props.setStateData(mapsFeats.features);
			// 	//add default layers after so can paint layers correctly
			// 	Object.keys(layers).map((section,index) => {
			// 		Object.keys(layers[section]).map((layer,index2) => {
			// 			if (t.state.layers.indexOf(layer) != -1) {
			// 				t.addLayer(layer);
			// 			}
			// 		})
			// 	})
			// });
			// console.log('get state data from geojson');
			//t.fetchFocusAreaByState();
		
				//get state data
			axios.get(`${API_URL}/Brazil_MASTER_Data_For_OpenBrazil.geojson`).then(result => {
				t.setState({stateData: result.data.features}, () => {
					if (t.props.setStateData)
						t.props.setStateData(result.data.features);
					//add default layers after so can paint layers correctly
			        Object.keys(layers).map((section,index) => {
						Object.keys(layers[section]).map((layer,index2) => {
							if (t.state.layers.indexOf(layer) != -1) {
								t.addLayer(layer);
							}
						})
					})
				});
				console.log('get state data from geojson');
				//t.fetchFocusAreaByState();
			}).catch((error) => {
		    	
		    })
			//t.fetchFocusAreaByState();
			console.log('Fired CREATE MAP ==> fetchFocusAreaByState ')
			t.fetchFocusAreaByState();

		    if (t.projectData)
		    	t.addProjectLayer()
			
		});

	}

	renderSwitch(layer, layerID) {
		
		let values = [];
		let colors = [];

		let section;
		Object.keys(layers).map((key,index) => {
			if (Object.keys(layers[key]).indexOf(layerID) != -1){
				section = key;
			}
		});

		switch(layer.type) {
		    case 'geojson':
		      switch(layer.layer) {
			    case 'fill':
			    	let style = this.map.getPaintProperty(layerID, "fill-color");
			      return (
			      	<div style={{marginBottom: '5px'}}>
					{
						style instanceof Array ?
							
							style.map((data,index) => {
								
								if (style[0] =='interpolate' && index > 2) {
									if (index % 2 == 0) {
										colors.push(data);
									}else{
										values.push(style[index]);
									}

									if (index == style.length - 1) {
										return (
											<div>
												<div> {
													colors.map((data,index) => {
														return (
													
															<div style={{width: (100/colors.length).toString() + '%', height: '10px', backgroundColor: data, float: 'left'}}/>
															
														)
													})

													

												}</div>

												<div> {
													values.map((data,index) => {
															return (
														
																<div style={{width: (100/colors.length).toString() + '%', height: '10px', float: 'left', marginBottom: '5px'}}>{layerID == 'hum_cap_index' || layerID == 'RCP26' || layerID == 'RCP85' || layerID == 'HI35_RCP26' || layerID == 'HI35_RCP85' ? this.abbreviate(data, 2, false, false) : this.abbreviate(data, 2, false, false)}</div>
																
															)
														})
												}</div>
											</div>
											
										)
									}
								}else if (style[0] =='match' && index > 1) {
									if (index % 2 == 0) {
										if (index != style.length - 1){
											if (this.props.location && this.props.location.pathname.substring(0,4) == "/pt/")
												values.push(layers[section][layerID].style_pt['fill-color'][index]);
											else
												values.push(style[index]);
										}
									}else{
										colors.push(data);
									}

									if (index == style.length - 1) {
										return (
											<div>
												<div> {
													colors.map((data,index) => {
														return (
													
															<div style={{width: (100/colors.length).toString() + '%', height: '10px', backgroundColor: data, float: 'left'}}/>
															
														)
													})

													

												}</div>

												<div> {
													values.map((data,index) => {
															return (
														
																<div style={{width: (100/colors.length).toString() + '%', height: '10px', float: 'left', marginBottom: '5px'}}>{data}</div>
																
															)
														})
												}</div>
											</div>
											
										)
									}
								}
								
							})

							
							

							

						:

							<div style={{width: '25px', height: '25px', backgroundColor: style, marginBottom: '5px'}}></div>

			      	}
					</div>
			      )
			    case 'line':
			      return (
			      	<div style={{width: '25px', height: '25px', borderColor: layer.style['line-color'], borderWidth: layer.style['line-width'], border: 'solid', marginBottom: '5px'}}>

			      	</div>
			      )
			    default:
			      return (
			      	<div>{layer.layer}</div>
			      )
			  }
			case 'wms':
				if (layer.name.indexOf('VIIRS') != -1) {
					return (
				      	<div style={{width: '3px', height: '3px', backgroundColor: 'red'}}></div>
				      )
				}
			case 'image':
				if (layer.colors) {
					return (
								<div>
									<div> {
										layer.colors.map((data,index) => {
											return (
										
												<div style={{width: (100/layer.colors.length).toString() + '%', height: '10px', backgroundColor: data, float: 'left'}}/>
												
											)
										})

										

									}</div>

									<div> {
										layer.values.map((data,index) => {
												return (
											
													<div style={{width: (100/layer.colors.length).toString() + '%', height: '10px', float: 'left', marginBottom: '5px'}}>{this.abbreviate(data, 2, false, false)}</div>
													
												)
											})
									}</div>
								</div>
								
							)
				}else{
					return (
				      	<div style={{float: 'left'}}>{layer.type}</div>
				      )
				}
			case 'gee':
				if (layer.colors) {
					return (
								<div>
									<div> {
										layer.colors.map((data,index) => {
											return (
										
												<div style={{width: (100/layer.colors.length).toString() + '%', height: '10px', backgroundColor: data, float: 'left'}}/>
												
											)
										})

										

									}</div>

									<div> {
										this.props.location && this.props.location.pathname.substring(0,4) !== "/pt/" ?
										layer.values.map((data,index) => {
												return (
											
													<div style={{width: (100/layer.colors.length).toString() + '%', height: '10px', float: 'left', marginBottom: '5px'}}>{data}</div>
													
												)
											})

										:

										layer.values_pt.map((data,index) => {
												return (
											
													<div style={{width: (100/layer.colors.length).toString() + '%', height: '10px', float: 'left', marginBottom: '5px'}}>{data}</div>
													
												)
											})
									}</div>
								</div>
								
							)
				}else{
					return (
				      	<div style={{float: 'left'}}>{layer.type}</div>
				      )
				}
		    default:
		      return (
		      	<div style={{float: 'left'}}>{layer.type}</div>
		      )
		}
	}

    render() {
    	const style = {
	      top: 0,
	      bottom: 0,
	      width: '100%',
	      height: '100%'
	    };

	    let hidden = this.state.legendShowing ? '' : 'go-left';

	    let total = 0, fa1Total = 0, fa2Total = 0, fa3Total = 0;
	    let faText = '';
	    let features;
	    let stateData = [];
	    let states = [];
		
		
	    if (this.state.faData) {
	    	features = this.state.faData;

			// for every cluster on the screen, create an HTML marker for it (if we didn't yet),
			// and add it to the map if it's not there already
			for (let i = 0; i < features.length; i++) {
				let props = features[i].properties;
				let fa1 = 0;//props.Focus_Area_1_Funding;
				let fa2 = 0;//props.Focus_Area_2_Funding;
				let fa3 = 0;//props.Focus_Area_3_Funding;
				let state = props.ESTADO;

				for(let faByStateValue of this.state.fa_by_state) {
					if (faByStateValue.state === state) {
						fa1 = Number(faByStateValue.focus_area_1_funding);
						fa2 = Number(faByStateValue.focus_area_2_funding);
						fa3 = Number(faByStateValue.focus_area_3_funding);
					}
				}

				states.push(state);
				
				fa1Total += fa1;
				fa2Total += fa2;
				fa3Total += fa3;

				if (this.state.focusArea == 'all') {
					total += fa1 + fa2 + fa3;
				} else if (this.state.focusArea == 'Focus_Area_1_Funding') {
					if (fa1 > 0) {
						stateData.push({state: state, value: fa1});
					}

					total += fa1;
					faText = "Focus Area 1";
				} else if (this.state.focusArea == 'Focus_Area_2_Funding') {
					if (fa2 > 0) {
						stateData.push({state: state, value: fa2});
					}

					total += fa2;
					faText = "Focus Area 2";
				} else if (this.state.focusArea == 'Focus_Area_3_Funding') {
					if (fa3 > 0) {
						stateData.push({state: state, value: fa3});
					}
					
					total += fa3;
					faText = "Focus Area 3";
				}
			}
	    }

	    stateData.sort((a, b) => (a.value < b.value) ? 1 : -1)
	    states.sort((a, b) => (b < a) ? 1 : -1)

	    let text = {};
	    let tabStyle = {fontSize: "10px"};
        if (this.props.location) { //while we still have pages to translate don't want to break them
            if (this.props.location.pathname.substring(0,4) !== "/pt/") {
                text = textEnglish;
            } else {
                text = textPortuguese;
                tabStyle = {fontSize: "8px"};
            }
        } else {
            text = textEnglish;
        }

        return (
        	
        	<div id="programMap" className="program-map" ref={el => this.datasetPane = el} >
				<div style={style} ref={el => this.mapContainer = el} />
            	
        		<Tab.Container id="legend" defaultActiveKey="first" className={hidden}>
				  <Row className="clearfix">
				    <Col id="tabs">
				      <Nav stacked>
				        <NavItem eventKey="first">
				        	<FontAwesomeIcon icon={faMapMarkerAlt} />
				        	<div style={tabStyle} className="tab-label">{text.focus_areas}</div>
				        </NavItem>
				        <NavItem eventKey="second">
				        	<FontAwesomeIcon icon={faLayerGroup} />
				        	<div style={tabStyle} className="tab-label">{text.indicator_datasets}</div>
				        </NavItem>
				        <NavItem eventKey="third">
				        	<FontAwesomeIcon icon={faHammer} />
				        	<div style={tabStyle} className="tab-label">{text.project_monitoring}</div>
				        </NavItem>
				      </Nav>
				    </Col>
				    <Col id="tabContent">
				      <Tab.Content animation>
				        <Tab.Pane eventKey="first">
				        	<div className="section-heading">
				        		<div className="section-icon">
				        			<FontAwesomeIcon icon={faMapMarkerAlt} />
				        		</div>	
				        	{text.focus_areas}
				        	</div>
				        	{


							!this.state.faData ? 
								<Loading height="100px" paddingTop='25px' />
							:
								this.state.focusArea == 'all' ?
									<div>
										<div>
											<div className="layer-section">{text.wb_miga}<div className="theme-toggle-bg" style={this.state.layersAdded.indexOf('fa1') != -1 ? {'backgroundColor':'#cd2c0f', 'border':'1px solid #cd2c0f'} : {'backgroundColor':'#b7b7b7','border': '1px solid #b7b7b7'}}>
									        	<Toggle
													className='toggle-style'									  
													checked={this.state.layersAdded.indexOf('fa1') != -1}
													onChange={() => this.toggleFALayer()}
													icons={{
														checked: text.on,
														unchecked: text.off
													}}
												/>
											</div></div>
											<div style={{textAlign: 'left', marginTop: '10px', fontSize: '16px'}}>
												{"Total: "}<span style={{fontWeight: 'bold'}}>${this.abbreviate(total, 2, false, false)}</span>
											</div>
											
											<div id="Focus_Area_1_Funding" title={text.focus_area_1_title_long} onClick={this.changeFocusArea} className="fa-menu"><div className="fa-color" style={{backgroundColor:colors[0]}}></div>{text.focus_area_1}: <span style={{fontWeight: 'bold'}}>${this.abbreviate(fa1Total, 2, false, false)}</span></div>
											<div id="Focus_Area_2_Funding" title={text.focus_area_2_title_long} onClick={this.changeFocusArea} className="fa-menu"><div className="fa-color" style={{backgroundColor:colors[1]}}></div>{text.focus_area_2}: <span style={{fontWeight: 'bold'}}>${this.abbreviate(fa2Total, 2, false, false)}</span></div>
											<div id="Focus_Area_3_Funding" title={text.focus_area_3_title_long} onClick={this.changeFocusArea} className="fa-menu"><div className="fa-color" style={{backgroundColor:colors[2]}}></div>{text.focus_area_3}: <span style={{fontWeight: 'bold'}}>${this.abbreviate(fa3Total, 2, false, false)}</span></div>
											<div style={{textAlign: 'center', marginTop: '10px', fontSize: '10px'}}>{text.focus_click}</div>
										</div>
										<div className="layer-section" style={{marginTop: '20px'}}>IFC
										<div className="theme-toggle-bg" style={this.state.ifcLayerShowing ? {'backgroundColor':'#cd2c0f', 'border':'1px solid #cd2c0f'} : {'backgroundColor':'#b7b7b7','border': '1px solid #b7b7b7'}}>
									        	<Toggle
													className='toggle-style'									  
													checked={this.state.ifcLayerShowing}
													onChange={() => this.toggleIfc()}
													icons={{
														checked: text.on,
														unchecked: text.off
													}}
												/>
											</div>
										</div>
										<div style={{textAlign: 'left', marginTop: '10px', fontSize: '16px'}}>
											{"Total: "}<span style={{fontWeight: 'bold'}}>$3.30B</span>
										</div>
									</div>

								:
									<div style={{margin: '10px 0', fontSize: '16px'}}>
										<div style={{textAlign: 'left', float: "left"}}>{faText + ": "}
											<span style={{fontWeight: 'bold'}}>${this.abbreviate(total, 2, false, false)}</span>
										</div>
										<div id="all" style={{float: "right", marginBottom: "10px"}} onClick={this.changeFocusArea} >{"< Back"}</div>
										{stateData.map((data,index) => {
											return (
												<div className="state-div">
													<div className="state-name">{data.state}</div>
													<div className="state-value">${this.abbreviate(data.value, 2, false, false)}</div>
												</div>
											)

										})}
									</div>
									
							}
				        </Tab.Pane>
				        <Tab.Pane eventKey="second">
				        	<div className="section-heading">
				        		<div className="section-icon">
				        			<FontAwesomeIcon icon={faLayerGroup} />
				        		</div>	
				        	{text.indicator_datasets}
				        	</div>
				        	{Object.keys(layers).map((section,index) => {
								//let sectionName = Object.keys(section)[0];
								return (
									<div>
										{
										section == "Climate" ?
											<div className="layer-section">{text[section]}
													<OverlayTrigger trigger="focus" placement="right" container={this.datasetPane} overlay={
														<Popover data-html={true} id="popover-positioned-scrolling-right" title={text[section]}>
														    {text.climate_desc}
														  </Popover>
													}>
												      <button className="category-info"><FontAwesomeIcon icon={faInfo} /></button>
												    </OverlayTrigger>
												    <a style={{float: 'right', color: '#cd2c0f'}} href="https://climateknowledgeportal.worldbank.org/" target="_blank">CCKP</a>
											</div>
										:
											<div className="layer-section">{text[section]}
												{ section == 'Disasters' &&
													<OverlayTrigger trigger="focus" placement="right" container={this.datasetPane} overlay={
														<Popover data-html={true} id="popover-positioned-scrolling-right" title={text[section]}>
														    {text.disasters_desc}
														  </Popover>
													}>
												      <button className="category-info"><FontAwesomeIcon icon={faInfo} /></button>
												    </OverlayTrigger>
												}
												{ section == 'FIRMS Fire Data' &&
													<OverlayTrigger trigger="focus" placement="right" container={this.datasetPane} overlay={
														<Popover data-html={true} id="popover-positioned-scrolling-right" title={text[section]}>
														    {catDescriptions[section]}
														  </Popover>
													}>
												      <button className="category-info"><FontAwesomeIcon icon={faInfo} /></button>
												    </OverlayTrigger>
												}
											</div>
										}
										{Object.keys(layers[section]).map((layerID,index2) => {
											return (
												<div className="layer" id={"layer-"+layerID} key={"layer-"+layerID}>
													<OverlayTrigger trigger="focus" placement="right" container={this.datasetPane} overlay={
														<Popover data-html={true} id="popover-positioned-scrolling-right" title={this.props.location.pathname.substring(0,4) !== "/pt/" ? layers[section][layerID].name : layers[section][layerID].name_pt} content={true}>
														    {this.props.location.pathname.substring(0,4) !== "/pt/" ? layers[section][layerID].description : layers[section][layerID].description_pt}
														  </Popover>
													}>
												      <button className="layer-info"><FontAwesomeIcon icon={faInfo} /></button>
												    </OverlayTrigger>
													<div className="layer-name" title={this.props.location.pathname.substring(0,4) !== "/pt/" ? layers[section][layerID].name : layers[section][layerID].name_pt}>{this.props.location.pathname.substring(0,4) !== "/pt/" ? layers[section][layerID].name : layers[section][layerID].name_pt}</div>
													{ this.state.layersAdded.indexOf(layerID) != -1 ?
														
														<Button id={layerID} className="remove-btn" onClick={() => this.removeLayer(layerID)}>
													  		<FontAwesomeIcon icon={faTimes} />
													    </Button>

														:

														<Button  id={layerID} className="add-btn" onClick={() => this.addLayerClick(layerID)}>Add</Button>

													}
													
												</div>
											)
										})}
									</div>
								)
							})}
				        </Tab.Pane>
				        <Tab.Pane eventKey="third">
				        	<div style={{height: '60px'}} className="section-heading">
				        		<div className="section-icon">
				        			<FontAwesomeIcon icon={faHammer} />
				        		</div>	
				        		<div style={{maxWidth: '120px', float: 'left', textAlign: 'left', color: '#2f333b', fontSize: this.props.location.pathname.substring(0,4) !== "/pt/" ? '16px' : '14px', fontWeight: 700}}>{text.project_monitoring}</div>
				        		<div className="theme-toggle-bg" style={this.state.layersAdded.indexOf('ssaLayer') != -1 ? {'backgroundColor':'#cd2c0f', 'border':'1px solid #cd2c0f'} : {'backgroundColor':'#b7b7b7','border': '1px solid #b7b7b7'}}>
						        	<Toggle
										className='toggle-style'									  
										checked={this.state.layersAdded.indexOf('ssaLayer') != -1}
										onChange={() => this.toggleSSALayer()}
										icons={{
											checked: text.on,
											unchecked: text.off
										}}
									/>
								</div>
				        	</div>
				        	<div className="layer-section">{text.smart_supervision}</div>
				        	{ 
				        		this.state.ssaData && 
				        		
				        		this.state.ssaData.features.map((feature,index) => {
									return (
										<div style={{marginTop: '10px'}} id="{feature.properties.id}" onClick={() => this.zoomToProject(feature)} className="fa-menu"><span style={{fontWeight: 'bold'}}>{(index + 1).toString() + '. '}</span>{feature.properties.name}</div>
									)
								})
							}
							<div style={{textAlign: 'center', marginTop: '10px', fontSize: '10px'}}>{text.smart_supervision_click}</div>
				        </Tab.Pane>
				      </Tab.Content>
				    </Col>
				    <Button id="legendBtn" onClick={() => this.setState({'legendShowing': !this.state.legendShowing})}>
				  		<FontAwesomeIcon icon={this.state.legendShowing ? faChevronLeft : faChevronRight} />
				    </Button>
				  </Row>
				  
				</Tab.Container>

				<div className={this.state.programLegendShowing ? 'program-legend' : 'program-legend minimized'}>
				    {this.state.programLegendShowing ?
				    	Object.keys(layers).map((section,index) => {
							//let sectionName = Object.keys(section)[0];
							return (
								<div>
									{Object.keys(layers[section]).map((layerID,index2) => {
										//let layerID = Object.keys(layer)[0];
										return (
											<div>
												{ this.state.layersAdded.indexOf(layerID) != -1 &&
													
													<div className="legend-section">
														<div style={{textAlign: 'left', fontSize: '16px', fontWeight: 'bold', marginBottom: '5px'}}>{this.props.location.pathname.substring(0,4) !== "/pt/" ? layers[section][layerID].name : layers[section][layerID].name_pt}</div>
														{this.renderSwitch(layers[section][layerID], layerID)}
													</div>

												}
												
											</div>
										)
									})}
								</div>
							)
						})

						:

						<div style={{fontSize: '16px', fontWeight: 'bold'}}>Legend</div>

					}
					<Button id="programLegendBtn" onClick={() => this.setState({'programLegendShowing': !this.state.programLegendShowing})}>
				  		<FontAwesomeIcon icon={this.state.programLegendShowing ? faChevronDown : faChevronUp} />
				    </Button>
				</div>
				
        	</div>
        );
    }
};
