import React, { useState, useEffect, useRef } from "react";
import { withRouter, useHistory } from "react-router-dom";
import Cookies from "universal-cookie";
import UserReviewModal from "../modals/UserReviewModal";
import "./NavbarPLR.css";
import WbgLogoWhite from "./img/wbg-logo-white.svg";
import AltButton from "../../../components/AltButton";

import SearchIcon from "../../../../icons/search-icon.svg";
import Button from "../../../components/Button";
import LogoutModal from "../../../components/LogoutModal";

const textEnglish = {
  login: "Login",
  logout: "Log Out",
  site: "Open Brazil",
  parent_site: "World Bank Brazil",
  about: "About",
  strategy: "Strategy",
  story: "Partnership Framework",
  finance: "Leveraging Finance",
  focus: "Focus Areas",
  history: "Telling the Story",
  program: "Activities",
  program_page: "Portfolio Footprint",
  how: "How We Work",
  projects: "Projects",
  reports: "Reports",
  impact: "Impact",
  indicators: "Result indicators",
  remote: "Remote Sensing",
  evidence: "Evidence",
  engagement: "Outreach",
  sentiment: "Sentiment Snapshot",
  insights: "Online Media Snapshot",
  benchmark: "Benchmarking",
  editScreen: "Edit Screen",
  english: "English",
  portuguese: "Portuguese",
  follow_us: "Follow us",
  covid: "COVID-19 Monitor",
  covid_landing: "COVID-19 Dashboard",
  covid_story: "COVID-19 StoryMap",
  admin: "Admin",
  contact: "Contact",
};

const textPortuguese = {
  login: "Fazer Log In",
  logout: "Sair",
  site: "Brasil Aberto",
  parent_site: "Banco Mundial Brasil",
  about: "Sobre",
  strategy: "Estratégia",
  story: "Estratégia de Parceria",
  finance: "Alavancando o Financiamento",
  focus: "Áreas de Foco",
  history: "Contando a História",
  program: "Atividades",
  program_page: "Alcance do Portfólio",
  how: "How We Work",
  projects: "Projetos",
  reports: "Relatórios",
  impact: "Impacto",
  indicators: "Indicadores de resultados",
  remote: "Remote Sensing",
  evidence: "Evidências",
  engagement: "Fale Conosco",
  sentiment: "Análise de sentimento",
  insights: "Panorama Online",
  benchmark: "Comparativo Internacional",
  editScreen: "Edições",
  english: "Inglês",
  portuguese: "Português",
  follow_us: "Conecte-se conosco",
  covid: "COVID-19 Monitor",
  covid_landing: "COVID-19 Dashboard",
  covid_story: "COVID-19 StoryMap",
  admin: "Admin",
  contact: "Contato",
};

const NavbarPLR = (props) => {
  const [openMenu, setOpenMenu] = useState(false);
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [toggleSearch, setToggleSearch] = useState(false);
  const [isSearchProjectPage, setIsSearchProjectPage] = useState(false);
  const searchRef = useRef(null);
  const history = useHistory();

  const searchInput = props.searchInput;
  const setSearchInput = props.setSearchInput;
  const shouldRedirectSearch = props.shouldRedirectSearch ?? true;

  useEffect(() => {
    const currentRoute = history.location.pathname;
    setIsSearchProjectPage(currentRoute.includes('/search-project'));
  }, [history.location.pathname]);

  useEffect(() => {
    if (isSearchProjectPage) {
      searchRef.current.focus();
    }
  }, [isSearchProjectPage]);

  const toEnglish = () => {
    if (props.location.state?.project != undefined) {
      history.push({
        pathname: window.location.pathname.replace('/pt', ''),
        state: { project: props.location.state?.project },
      });
    } else if (window.location.pathname === '/pt/') {
      history.push(window.location.pathname.replace('/pt', '/welcome'));
    } else {
      history.push(window.location.pathname.replace('/pt', ''));
    }
  };

  const toPortuguese = () => {
    let prepend = '/pt';
    if (props.location) {
      if (props.location.pathname.substring(0, 4) === '/pt/') {
        prepend = '';
      }
    }
    if (props.location.state?.project != undefined) {
      history.push({
        pathname: prepend + window.location.pathname,
        state: { project: props.location.state.project },
      });
    } else {
      history.push(prepend + window.location.pathname);
    }
  };

  const toProgram = () => {
    const prepend = props.location?.pathname.substring(0, 4) === '/pt/' ? '/pt' : '';
    history.push(prepend + '/portfolio');
  };

  const toStrategy = () => {
    const prepend = props.location?.pathname.substring(0, 4) === '/pt/' ? '/pt' : '';
    history.push(prepend + '/strategy');
  };

  const toHistory = () => {
    const prepend = props.location?.pathname.substring(0, 4) === '/pt/' ? '/pt' : '';
    history.push(prepend + '/history');
  };

  const toProjects = () => {
    const prepend = props.location?.pathname.substring(0, 4) === '/pt/' ? '/pt' : '';
    history.push(prepend + '/projects');
  };

  const toReports = () => {
    const prepend = props.location?.pathname.substring(0, 4) === '/pt/' ? '/pt' : '';
    history.push(prepend + '/reports');
  };

  const toImpact = () => {
    const prepend = props.location?.pathname.substring(0, 4) === '/pt/' ? '/pt' : '';
    history.push(prepend + '/indicators');
  };

  const toInsights = () => {
    const prepend = props.location?.pathname.substring(0, 4) === '/pt/' ? '/pt' : '';
    history.push(prepend + '/insights');
  };

  const toBenchmark = () => {
    const prepend = props.location?.pathname.substring(0, 4) === '/pt/' ? '/pt' : '';
    history.push(prepend + '/benchmark');
  };

  const toFinance = () => {
    const prepend = props.location?.pathname.substring(0, 4) === '/pt/' ? '/pt' : '';
    history.push(prepend + '/leveraging-finance');
  };

  const toAbout = () => {
    const prepend = props.location?.pathname.substring(0, 4) === '/pt/' ? '/pt' : '';
    history.push(prepend + '/about');
  };

  const toStory = () => {
    const prepend = props.location?.pathname.substring(0, 4) === '/pt/' ? '/pt' : '';
    history.push(prepend + '/story');
  };

  const toEditScreen = () => {
    const prepend = props.location?.pathname.substring(0, 4) === '/pt/' ? '/pt' : '';
    history.push(prepend + '/edit-screen/projects');
  };

  const toLogin = () => {
    const prepend = props.location?.pathname.substring(0, 4) === '/pt/' ? '/pt' : '';
    history.push(prepend + '/login');
  };

  const openLogoutModal = () => {
    setShowLogoutModal(true);
  };

  const closeLogoutModal = () => {
    setShowLogoutModal(false);
  };

  const scrollToFooter = () => {
    window.scrollTo(0, 99999);
  };

  const onLogout = () => {
    props.onChangeLoginState();
    const prepend = props.location?.pathname.substring(0, 4) === '/pt/' ? '/pt' : '';
    history.push(prepend + '/');
    setShowLogoutModal(false);
  };

  useEffect(() => {
    const currentRoute = props.history.location.pathname;
    setIsSearchProjectPage(currentRoute.includes('/search-project') ?? false);
  }, []);

  useEffect(() => {
    if (isSearchProjectPage || toggleSearch) {
      searchRef.current.focus();
    }
  }, [isSearchProjectPage, toggleSearch]);

  let dNowDate = new Date();
  let showUserReview = false;

  const cookies = new Cookies();
  let dPlusFive = new Date(cookies.get('navTime'));
  dPlusFive.setTime(dPlusFive.getTime() + 2.5 * 60 * 1000);
  let answered = parseInt(cookies.get('answered'));

  if (dNowDate >= dPlusFive && answered < 1) {
    showUserReview = true;
  }

  let text = props.location?.pathname.substring(0, 4) === '/pt/' ? textPortuguese : textEnglish;

  return (
      <>
        <div className="navbar-plr">
          <h1 className="obr-title" onClick={() => (location.href = "/")}>
            {text.site}
          </h1>
          <ul className="navlist">
            <li
                className={`pointer ${
                    props.currentPage === "about" ? "selected" : ""
                }`}
                onClick={toAbout}
            >
              {text.about}
            </li>
            <li
                className={`strategy-menu ${
                    props.currentPage === "strategy" ? "selected" : ""
                }`}
            >
              {text.strategy}
              <div className="submenu-container strategy-submenu">
                <ul>
                  <li onClick={toStory}>{text.story}</li>
                  <li onClick={toStrategy}>{text.focus}</li>
                  <li onClick={toFinance}>{text.finance}</li>
                  <li onClick={toHistory}>{text.history}</li>
                </ul>
              </div>
            </li>
            <li
                className={`program-menu ${
                    props.currentPage === "program" ? "selected" : ""
                }`}
            >
              {text.program}
              <div className="submenu-container program-submenu">
                <ul>
                  <li onClick={toProgram}>{text.program_page}</li>
                  <li onClick={toProjects}>{text.projects}</li>
                  <li onClick={toReports}>{text.reports}</li>
                </ul>
              </div>
            </li>
            <li
                className={`impact-menu ${
                    props.currentPage === "impact" ? "selected" : ""
                }`}
            >
              {text.impact}
              <div className="submenu-container impact-submenu">
                <ul>
                  <li onClick={toImpact}>{text.indicators}</li>
                  <li onClick={toInsights}>{text.insights}</li>
                </ul>
              </div>
            </li>
            <li
                onClick={toBenchmark}
                className={`pointer ${
                    props.currentPage === "benchmark" ? "selected" : ""
                }`}
            >
              {text.benchmark}
            </li>
            {props.isLoggedIn && (
                <li
                    onClick={toEditScreen}
                    className={`pointer ${
                        props.currentPage === "edit-screen" ? "selected" : ""
                    }`}
                >
                  {text.editScreen}
                </li>
            )}
            <AltButton
                style={{
                  height: "38px",
                  width: "84px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                onClick={scrollToFooter}
                inverse={true}
            >
              {text.contact}
            </AltButton>
            <div style={{ width: "65px" }}>
              {props.isLoggedIn ? (
                  <AltButton
                      type="danger"
                      onClick={openLogoutModal}
                      inverse={true}
                  >
                    {text.logout}
                  </AltButton>
              ) : (
                  <Button
                      onClick={toLogin}
                      style={{
                        height: "38px",
                        width: "84px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                  >
                    Login {props.isLoggedIn}
                  </Button>
              )}
            </div>
          </ul>
          <button
              onClick={() => setOpenMenu(true)}
              className="menu-button"
          >
            <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
              <path
                  d="M3 17H21"
                  stroke="#1A1A1A"
                  strokeWidth="2"
                  strokeLinecap="square"
              />
              <path
                  d="M3 12H21"
                  stroke="#1A1A1A"
                  strokeWidth="2"
                  strokeLinecap="square"
              />
              <path
                  d="M3 7H21"
                  stroke="#1A1A1A"
                  strokeWidth="2"
                  strokeLinecap="square"
              />
            </svg>
          </button>
          <div
              className={
                openMenu ? "side-menu-open" : "side-menu-hidden"
              }
              style={{ transition: "all ease-in-out .5s" }}
          >
            <div className="side-menu">
              <button
                  onClick={() => setOpenMenu(false)}
                  className="menu-button"
              >
                <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                      d="M21 21L3 3"
                      stroke="#4D4D4D"
                      strokeWidth="2"
                      strokeLinecap="square"
                  />
                  <path
                      d="M21.0001 3L3 21.0001"
                      stroke="#4D4D4D"
                      strokeWidth="2"
                      strokeLinecap="square"
                  />
                </svg>
              </button>

              <div>
                <button className="menu-item" onClick={toAbout}>{text.about}</button>
                <span>{text.strategy}</span>
                <button className="menu-item" onClick={toStory}>{text.story}</button>
                <button className="menu-item" onClick={toStrategy}>{text.focus}</button>
                <button className="menu-item" onClick={toFinance}>{text.finance}</button>
                <button className="menu-item" onClick={toHistory}>{text.history}</button>
                <span>{text.program}</span>
                <button className="menu-item" onClick={toProgram}>{text.program_page}</button>
                <button className="menu-item" onClick={toProjects}>{text.projects}</button>
                <button className="menu-item" onClick={toReports}>{text.reports}</button>
                <span>{text.impact}</span>
                <button className="menu-item" onClick={toImpact}>{text.indicators}</button>
                <button className="menu-item" onClick={toInsights}>{text.insights}</button>
                <span></span>
                <button className="menu-item" onClick={toBenchmark}>{text.benchmark}</button>
                <span></span>
                <button className="menu-item" onClick={scrollToFooter}>{text.contact}</button>
              </div>
            </div>
          </div>
          {showUserReview && <UserReviewModal {...props} />}
          {showLogoutModal && (
              <LogoutModal
                  onLogout={onLogout}
                  showModal={showLogoutModal}
                  closeModal={closeLogoutModal}
                  {...props}
              />
          )}
        </div>
        {!props.hideSubBar && (
            <div className="subbar">
              <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
              >
                <img alt="WorldBank" id="wbg-logo" src={WbgLogoWhite} />
                {shouldRedirectSearch ? (
                    <img
                        alt="Search"
                        id="nav-search-icon"
                        src={SearchIcon}
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          const pt =
                              props.history.location.pathname.includes("/pt");
                          props.history.push(
                              pt ? "/pt/search-project" : "/search-project"
                          );
                        }}
                    />
                ) : (
                    <></>
                )}
                {(toggleSearch && !shouldRedirectSearch) ||
                isSearchProjectPage ? (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div
                          style={{
                            width: "1px",
                            height: "24px",
                            backgroundColor: "#B7B7B7",
                            margin: "0 19px",
                          }}
                      ></div>
                      <input
                          onChange={(e) => setSearchInput(e.target.value)}
                          onBlur={() => {
                            const shouldRedirectSearch = isSearchProjectPage;

                            if (shouldRedirectSearch) {
                              props.history.goBack();
                            }

                            if (searchRef.current.value?.length === 0) {
                              setToggleSearch(false);
                            }
                          }}
                          ref={searchRef}
                          type="text"
                          style={{
                            height: "44px",
                            width: "340px",
                            backgroundColor: "white",
                            padding: "0 16px",
                          }}
                      />
                    </div>
                ) : (
                    !shouldRedirectSearch && (
                        <img
                            alt="search"
                            id="nav-search-icon"
                            src={SearchIcon}
                            style={{ cursor: "pointer" }}
                            onClick={() => setToggleSearch(!toggleSearch)}
                        />
                    )
                )}
              </div>
              <div className="language-session">
              <span
                  className={
                    props.location.pathname.substring(0, 4) !== "/pt/"
                        ? ""
                        : "selected"
                  }
                  onClick={() => toPortuguese()}
                  style={{ cursor: "pointer" }}
              >
                {text.portuguese}
              </span>
                <span
                    className={
                      props.location.pathname.substring(0, 4) !== "/pt/"
                          ? "selected"
                          : ""
                    }
                    onClick={() => toEnglish()}
                    style={{ cursor: "pointer" }}
                >
                {text.english}
              </span>
              </div>
            </div>
        )}
      </>
  );
};

export default withRouter(NavbarPLR);
