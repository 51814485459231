import React from 'react';
import ReactLoading from 'react-loading';

const Loading = () => {
    return (
        <div style={{"margin": "auto", "left": "50%", "top": "50%", "position": "absolute", "transform": "translate(-50%, -50%)"}}>
            <ReactLoading delay={1} type="spinningBubbles" color="#444"/>
        </div>
    );
}

export default Loading;
