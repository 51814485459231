import React, { useState } from 'react';
import HumanCapital from './sections/HumanCapital';
import Economy from './sections/Economy';
import Institutions from './sections/Institutions';
import InfrastructureAndEnvironment from './sections/InfrastructureAndEnvironment';
import './BenchmarkingContent.css';

export const BenchmarkingContent = (props) => {
	const [selectedTab, setSelectedTab] = useState(0);

	const expandify = (sectionId) => {
		props.expandify(sectionId);
	};

	let isVisibleCustom = false;
	for (let section in props.visibleSections) {
		if (!props.visibleSections[section]) {
			isVisibleCustom = true;
		}
	}

	let text = "All Themes";
	if (isVisibleCustom) {
		text = "Custom Themes";
	}

	console.log(props.sections)

	return (
		<div className="benchmark-container">
			<div className="benchmark-tabs">
				<span onClick={() => setSelectedTab(0)} className={selectedTab === 0 ? 'active' : ''}>Capital Humano</span>
				<span onClick={() => setSelectedTab(1)} className={selectedTab === 1 ? 'active' : ''}>Economia</span>
				<span onClick={() => setSelectedTab(2)} className={selectedTab === 2 ? 'active' : ''}>Instituições</span>
				<span onClick={() => setSelectedTab(3)} className={selectedTab === 3 ? 'active' : ''}>Infraestrutura e meio ambiente</span>
			</div>
			<div className="categories">
				{(selectedTab === 0) && (
					<HumanCapital
						{...props}
						numCountries={1}
						visibleSections={props.visibleSections}
						country_order={props.country_order}
						wdi_data={props.wdi_data}
						sectionId="section-one"
						isOpen={props.sections['section-one']}
						expandify={expandify}
					/>
				)}
				{(selectedTab === 1) && (
					<Economy
						{...props}
						numCountries={1}
						visibleSections={props.visibleSections}
						country_order={props.country_order}
						wdi_data={props.wdi_data}
						sectionId="section-two"
						isOpen={props.sections['section-two']}
						expandify={expandify}
					/>
				)}
				{(selectedTab === 2) && (
					<Institutions
						{...props}
						numCountries={1}
						visibleSections={props.visibleSections}
						country_order={props.country_order}
						wdi_data={props.wdi_data}
						sectionId="section-three"
						isOpen={props.sections['section-three']}
						expandify={expandify}
					/>
				)}
				{(selectedTab === 3) && (
					<InfrastructureAndEnvironment
						{...props}
						numCountries={1}
						visibleSections={props.visibleSections}
						country_order={props.country_order}
						wdi_data={props.wdi_data}
						sectionId="section-four"
						isOpen={props.sections['section-four']}
						expandify={expandify}
					/>
				)}
			</div>
		</div>
	);
};
