export const SECTION_MAPPINGS_SINGLE = {
	'section-one': {
		name: "Human Capital",
		subSections: [
			{
				'id': 'section-one-demography',
				'name':  "Demography"
			},
			{
				'id': 'section-one-subsection-zero',
				'name':  "Education"
			},
			{
				'id': 'section-one-subsection-one',
				'name':  "Health"
			},
			{
				'id': 'section-one-subsection-two',
				'name':  "Labor"
			},
			{
				'id': 'section-one-subsection-three',
				'name':  "Old-Age Dependency"
			}
		]
	},
	'section-two': {
		name: "Economy",
		subSections: [
			{
				'id': 'section-two-subsection-zero',
				'name':  "Real Sector"
			},
			{
				'id': 'section-two-subsection-two',
				'name':  "External Position"
			},
			{
				'id': 'section-two-subsection-three',
				'name':  "Business Environment"
			},
			{
				'id': 'section-two-subsection-four',
				'name':  "Finance & Investment"
			},
			{
				'id': 'section-two-subsection-five',
				'name':  "Public Finance"
			}
		]
	},
	'section-three': {
		name: "Institutions",
		subSections: [
			{
				'id': 'section-three-subsection-zero',
				'name':  "Government & Public Sector"
			},
			{
				'id': 'section-three-subsection-one',
				'name':  "Gender"
			},
			{
				'id': 'section-three-subsection-two',
				'name':  "Conflict & Fragility"
			}
		]
	},
	'section-four': {
		name: "Infrastructure and Environment",
		subSections: [

		]
	},
	'section-five': {
		name: "Strategic Directions",
		subSections: [
			{
				'id': 'section-five-subsection-zero',
				'name':  "Achieving Economic Diversification and Transformation"
			},
			{
				'id': 'section-five-subsection-one',
				'name':  "Raising Efficiency and Accountability In Public Sector Expenditure and Management"
			},
			{
				'id': 'section-five-subsection-two',
				'name':  "Creating Private Sector Employment Opportunities for Citizens"
			},
			{
				'id': 'section-five-subsection-three',
				'name':  "Unleashing Private Sector Potential"
			},
			{
				'id': 'section-five-subsection-four',
				'name':  "Bolstering SMES and Maximizing Local Content"
			},
			{
				'id': 'section-five-subsection-five',
				'name':  "Renewing the Social Contract"
			},
			{
				'id': 'section-five-subsection-six',
				'name':  "Adopting a Macroeconomic & Policy Framework"
			},
			{
				'id': 'section-five-subsection-seven',
				'name':  "Building a 21st Century Logistical Backbone"
			},
			{
				'id': 'section-five-subsection-eight',
				'name':  "Fostering Human Capital Formation"
			},
			{
				'id': 'section-five-subsection-nine',
				'name':  "Sustainable Management of Environmental and Natural Resources"
			}
		]
	}
}

export const SECTION_MAPPINGS_DOUBLE = {
	'section-one': {
		name: "Human Capital",
		subSections: [
			{
				'id': 'section-one-demography',
				'name':  "Demography"
			},
			{
				'id': 'section-one-subsection-zero',
				'name':  "Education"
			},
			{
				'id': 'section-one-subsection-one',
				'name':  "Health"
			},
			{
				'id': 'section-one-subsection-two',
				'name':  "Labor"
			},
			{
				'id': 'section-one-subsection-three',
				'name':  "Old-Age Dependency"
			}
		]
	},
	'section-two': {
		name: "Economy",
		subSections: [
			{
				'id': 'section-two-subsection-zero',
				'name':  "Real Sector"
			},
			{
				'id': 'section-two-subsection-one',
				'name':  "Trade"
			},
			{
				'id': 'section-two-subsection-two',
				'name':  "External Position"
			},
			{
				'id': 'section-two-subsection-three',
				'name':  "Business Environment"
			},
			{
				'id': 'section-two-subsection-four',
				'name':  "Finance & Investment"
			},
			{
				'id': 'section-two-subsection-five',
				'name':  "Public Finance"
			}
		]
	},
	'section-three': {
		name: "Institutions",
		subSections: [
			{
				'id': 'section-three-subsection-zero',
				'name':  "Governance & Public Sector Institutions"
			},
			{
				'id': 'section-three-subsection-one',
				'name':  "Gender"
			},
			{
				'id': 'section-three-subsection-two',
				'name':  "Conflict & Fragility"
			}
		]
	},
	'section-four': {
		name: "Infrastructure and Environment",
		subSections: [

		]
	},
	'section-five': {
		name: "Strategic Directions",
		subSections: [
			{
				'id': 'section-five-subsection-zero',
				'name':  "Achieving Economic Diversification and Transformation"
			},
			{
				'id': 'section-five-subsection-one',
				'name':  "Raising Efficiency and Accountability In Public Sector Expenditure and Management"
			},
			{
				'id': 'section-five-subsection-two',
				'name':  "Creating Private Sector Employment Opportunities for Citizens"
			},
			{
				'id': 'section-five-subsection-three',
				'name':  "Unleashing Private Sector Potential"
			},
			{
				'id': 'section-five-subsection-four',
				'name':  "Bolstering SMES and Maximizing Local Content"
			},
			{
				'id': 'section-five-subsection-five',
				'name':  "Renewing the Social Contract"
			},
			{
				'id': 'section-five-subsection-six',
				'name':  "Adopting a Macroeconomic & Policy Framework"
			},
			{
				'id': 'section-five-subsection-seven',
				'name':  "Building a 21st Century Logistical Backbone"
			},
			{
				'id': 'section-five-subsection-eight',
				'name':  "Fostering Human Capital Formation"
			},
			{
				'id': 'section-five-subsection-nine',
				'name':  "Sustainable Management of Environmental and Natural Resources"
			}
		]
	}
}

export const SECTION_MAPPINGS_TRIPLE = {
	'section-one': {
		name: "Human Capital",
		subSections: [
			{
				'id': 'section-one-demography',
				'name':  "Demography"
			},
			{
				'id': 'section-one-subsection-zero',
				'name':  "Education"
			},
			{
				'id': 'section-one-subsection-one',
				'name':  "Health"
			},
			{
				'id': 'section-one-subsection-two',
				'name':  "Labor"
			},
			{
				'id': 'section-one-subsection-three',
				'name':  "Old-Age Dependency"
			}
		]
	},
	'section-two': {
		name: "Economy",
		subSections: [
			{
				'id': 'section-two-subsection-zero',
				'name':  "Real Sector"
			},
			{
				'id': 'section-two-subsection-one',
				'name':  "Trade"
			},
			{
				'id': 'section-two-subsection-two',
				'name':  "External Position"
			},
			{
				'id': 'section-two-subsection-three',
				'name':  "Business Environment"
			},
			{
				'id': 'section-two-subsection-four',
				'name':  "Finance & Investment"
			},
			{
				'id': 'section-two-subsection-five',
				'name':  "Public Finance"
			}
		]
	},
	'section-three': {
		name: "Institutions",
		subSections: [
			{
				'id': 'section-three-subsection-zero',
				'name':  "Governance & Public Sector Institutions"
			},
			{
				'id': 'section-three-subsection-one',
				'name':  "Gender"
			},
			{
				'id': 'section-three-subsection-two',
				'name':  "Conflict & Fragility"
			}
		]
	},
	'section-four': {
		name: "Infrastructure and Environment",
		subSections: [
			
		]
	},
	'section-five': {
		name: "Strategic Directions",
		subSections: [
			{
				'id': 'section-five-subsection-zero',
				'name':  "Achieving Economic Diversification and Transformation"
			},
			{
				'id': 'section-five-subsection-one',
				'name':  "Raising Efficiency and Accountability In Public Sector Expenditure and Management"
			},
			{
				'id': 'section-five-subsection-two',
				'name':  "Creating Private Sector Employment Opportunities for Citizens"
			},
			{
				'id': 'section-five-subsection-three',
				'name':  "Unleashing Private Sector Potential"
			},
			{
				'id': 'section-five-subsection-four',
				'name':  "Bolstering SMES and Maximizing Local Content"
			},
			{
				'id': 'section-five-subsection-five',
				'name':  "Renewing the Social Contract"
			},
			{
				'id': 'section-five-subsection-six',
				'name':  "Adopting a Macroeconomic & Policy Framework"
			},
			{
				'id': 'section-five-subsection-seven',
				'name':  "Building a 21st Century Logistical Backbone"
			},
			{
				'id': 'section-five-subsection-eight',
				'name':  "Fostering Human Capital Formation"
			},
			{
				'id': 'section-five-subsection-nine',
				'name':  "Sustainable Management of Environmental and Natural Resources"
			}
		]
	}
}