import React, { useState, useEffect } from 'react';

import CloseIcon from '../../../icons/close-icon.svg';
import AlertIcon from '../../../icons/circle-info.svg';
import SuccessIcon from '../../../icons/circle-check.svg';
import WarningIcon from '../../../icons/triangle-warning.svg';

import './styles.css';

const AlertPopup = ({ message, duration, type, onClose }) => {
  const [show, setShow] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShow(false);
      onClose();
    }, duration);

    return () => {
      clearTimeout(timer);
    };
  }, [duration, onClose]);

  return (
    <div className={`alert-popup ${show ? 'appear' : ''} ${type}`}>
      { type === 'success' && <img className="alert-icon" src={SuccessIcon} alt="" /> }
      { type === 'danger' && <img className="alert-icon" src={WarningIcon} alt="" /> }
      { type === 'info' && <img className="alert-icon" src={AlertIcon} alt="" /> }
      <div className="message">{message}</div>
      <img className='close-icon' src={CloseIcon} alt="" />
    </div>
  );
};

export default AlertPopup;