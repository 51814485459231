import React, { useEffect, useState } from "react";

import "./AddIndicatorModal.css";
import Modal from "../Modal";

const AddIndicatorModal = ({
  isOpen,
  onClose,
  indicators,
  preSelectedIndicatorId,
  onAddIndicatorData,
}) => {
  const [selectedValues, setSelectedValues] = useState({
    indicator_id: "",
    year: "",
    month: "",
    value: "",
  });

  useEffect(() => {
    setSelectedValues({
      ...selectedValues,
      indicator_id: preSelectedIndicatorId,
    });
  }, [preSelectedIndicatorId]);

  const handleSelectChange = (event) => {
    const { name, value } = event.target;
    setSelectedValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setSelectedValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleCancel = () => {
    setSelectedValues({
      indicator_id: "",
      year: "",
      month: "",
      value: "",
    });
    onClose();
  };

  const handleAddIndicator = () => {
    onAddIndicatorData(selectedValues);
    setSelectedValues({
      indicator_id: "",
      year: "",
      month: "",
      value: "",
    });
    onClose();
  };

  if (!isOpen) {
    return null;
  }

  const yearOptions = [];
  const currentYear = new Date().getFullYear();
  for (let year = 1999; year <= currentYear; year++) {
    yearOptions.push(
      <option key={year} value={year}>
        {year}
      </option>
    );
  }

  const monthOptions = [
    <option key="01" value="01">
      Janeiro
    </option>,
    <option key="02" value="02">
      Fevereiro
    </option>,
    <option key="03" value="03">
      Março
    </option>,
    <option key="04" value="04">
      Abril
    </option>,
    <option key="05" value="05">
      Maio
    </option>,
    <option key="06" value="06">
      Junho
    </option>,
    <option key="07" value="07">
      Julho
    </option>,
    <option key="08" value="08">
      Agosto
    </option>,
    <option key="09" value="09">
      Setembro
    </option>,
    <option key="10" value="10">
      Outubro
    </option>,
    <option key="11" value="11">
      Novembro
    </option>,
    <option key="12" value="12">
      Dezembro
    </option>,
  ];

  const indicatorOptions = indicators.map((indicator) => (
    <option
      key={indicator.indicator_id}
      value={indicator.indicator_id}
    >{`${indicator.indicator_id} - ${indicator.title_pt?.slice(0, 20)}...`}</option>
  ));

  return (
    <Modal modalVisibility={() => isOpen}>
      <div className="modal-add-indicator">
        <div className="modal-header-add-indicator">
          <h2>Adicionar dados do indicador</h2>
          <button className="close-button" onClick={onClose}>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18 18L6 6"
                stroke="#4D4D4D"
                stroke-width="2"
                stroke-linecap="square"
              />
              <path
                d="M18 6L6 18"
                stroke="#4D4D4D"
                stroke-width="2"
                stroke-linecap="square"
              />
            </svg>
          </button>
        </div>
        <hr />
        <div className="modal-body">
          <div className="select-row">
            <label htmlFor="indicator">Indicador </label>
            <select
              id="indicator"
              name="indicator_id"
              onChange={handleSelectChange}
              value={selectedValues.indicator_id}
            >
              <option value="">Selecione</option>
              {indicatorOptions}
            </select>
          </div>
          <div className="select-row">
            <label htmlFor="year">
              Ano <span style={{ color: "red" }}>*</span>
            </label>
            <select
              name="year"
              id="year"
              value={selectedValues.year}
              onChange={handleSelectChange}
            >
              <option value="">Selecione</option>
              {yearOptions}
            </select>
          </div>
          <div className="select-row">
            <label htmlFor="month">
              Mês <span style={{ color: "red" }}>*</span>
            </label>
            <select
              name="month"
              id="month"
              value={selectedValues.month}
              onChange={handleSelectChange}
            >
              <option value="">Selecione</option>
              {monthOptions}
            </select>
          </div>
          <div className="select-row">
            <label htmlFor="value">Valor </label>
            <input
              id="value"
              name="value"
              type="number"
              placeholder="0"
              value={selectedValues.value}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className="modal-footer-add-indicator">
          <button className="cancel-button" onClick={handleCancel}>
            Cancelar
          </button>
          <button className="add-indicator-button" onClick={handleAddIndicator}>
            Adicionar Indicador
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default AddIndicatorModal;
