import axios from 'axios';
import React, { useState, useEffect } from 'react';
import ReactLoading from "react-loading";
import { withRouter } from 'react-router-dom';

import One from '../benchmarking/One';
import Two from '../benchmarking/Two';
import Three from '../benchmarking/Three';
import {API_URL} from "../../../config/api";


const iso2toiso3 = {
	'AF':'AFG',
	'AL':'ALB',
	'DZ':'DZA',
	'AS':'ASM',
	'AD':'AND',
	'AO':'AGO',
	'AI':'AIA',
	'AQ':'ATA',
	'AG':'ATG',
	'AR':'ARG',
	'AM':'ARM',
	'AW':'ABW',
	'AU':'AUS',
	'AT':'AUT',
	'AZ':'AZE',
	'BS':'BHS',
	'BH':'BHR',
	'BD':'BGD',
	'BB':'BRB',
	'BY':'BLR',
	'BE':'BEL',
	'BZ':'BLZ',
	'BJ':'BEN',
	'BM':'BMU',
	'BT':'BTN',
	'BO':'BOL',
	'BQ':'BES',
	'BA':'BIH',
	'BW':'BWA',
	'BV':'BVT',
	'BR':'BRA',
	'IO':'IOT',
	'BN':'BRN',
	'BG':'BGR',
	'BF':'BFA',
	'BI':'BDI',
	'CV':'CPV',
	'KH':'KHM',
	'CM':'CMR',
	'CA':'CAN',
	'KY':'CYM',
	'CF':'CAF',
	'TD':'TCD',
	'CL':'CHL',
	'CN':'CHN',
	'CX':'CXR',
	'CC':'CCK',
	'CO':'COL',
	'KM':'COM',
	'CD':'COD',
	'CG':'COG',
	'CK':'COK',
	'CR':'CRI',
	'HR':'HRV',
	'CU':'CUB',
	'CW':'CUW',
	'CY':'CYP',
	'CZ':'CZE',
	'CI':'CIV',
	'DK':'DNK',
	'DJ':'DJI',
	'DM':'DMA',
	'DO':'DOM',
	'EC':'ECU',
	'EG':'EGY',
	'SV':'SLV',
	'GQ':'GNQ',
	'ER':'ERI',
	'EE':'EST',
	'SZ':'SWZ',
	'ET':'ETH',
	'FK':'FLK',
	'FO':'FRO',
	'FJ':'FJI',
	'FI':'FIN',
	'FR':'FRA',
	'GF':'GUF',
	'PF':'PYF',
	'TF':'ATF',
	'GA':'GAB',
	'GM':'GMB',
	'GE':'GEO',
	'DE':'DEU',
	'GH':'GHA',
	'GI':'GIB',
	'GR':'GRC',
	'GL':'GRL',
	'GD':'GRD',
	'GP':'GLP',
	'GU':'GUM',
	'GT':'GTM',
	'GG':'GGY',
	'GN':'GIN',
	'GW':'GNB',
	'GY':'GUY',
	'HT':'HTI',
	'HM':'HMD',
	'VA':'VAT',
	'HN':'HND',
	'HK':'HKG',
	'HU':'HUN',
	'IS':'ISL',
	'IN':'IND',
	'ID':'IDN',
	'IR':'IRN',
	'IQ':'IRQ',
	'IE':'IRL',
	'IM':'IMN',
	'IL':'ISR',
	'IT':'ITA',
	'JM':'JAM',
	'JP':'JPN',
	'JE':'JEY',
	'JO':'JOR',
	'KZ':'KAZ',
	'KE':'KEN',
	'KI':'KIR',
	'KP':'PRK',
	'KR':'KOR',
	'KW':'KWT',
	'KG':'KGZ',
	'LA':'LAO',
	'LV':'LVA',
	'LB':'LBN',
	'LS':'LSO',
	'LR':'LBR',
	'LY':'LBY',
	'LI':'LIE',
	'LT':'LTU',
	'LU':'LUX',
	'MO':'MAC',
	'MG':'MDG',
	'MW':'MWI',
	'MY':'MYS',
	'MV':'MDV',
	'ML':'MLI',
	'MT':'MLT',
	'MH':'MHL',
	'MQ':'MTQ',
	'MR':'MRT',
	'MU':'MUS',
	'YT':'MYT',
	'MX':'MEX',
	'FM':'FSM',
	'MD':'MDA',
	'MC':'MCO',
	'MN':'MNG',
	'ME':'MNE',
	'MS':'MSR',
	'MA':'MAR',
	'MZ':'MOZ',
	'MM':'MMR',
	'NA':'NAM',
	'NR':'NRU',
	'NP':'NPL',
	'NL':'NLD',
	'NC':'NCL',
	'NZ':'NZL',
	'NI':'NIC',
	'NE':'NER',
	'NG':'NGA',
	'NU':'NIU',
	'NF':'NFK',
	'MP':'MNP',
	'NO':'NOR',
	'OM':'OMN',
	'PK':'PAK',
	'PW':'PLW',
	'PS':'PSE',
	'PA':'PAN',
	'PG':'PNG',
	'PY':'PRY',
	'PE':'PER',
	'PH':'PHL',
	'PN':'PCN',
	'PL':'POL',
	'PT':'PRT',
	'PR':'PRI',
	'QA':'QAT',
	'MK':'MKD',
	'RO':'ROU',
	'RU':'RUS',
	'RW':'RWA',
	'RE':'REU',
	'BL':'BLM',
	'SH':'SHN',
	'KN':'KNA',
	'LC':'LCA',
	'MF':'MAF',
	'PM':'SPM',
	'VC':'VCT',
	'WS':'WSM',
	'SM':'SMR',
	'ST':'STP',
	'SA':'SAU',
	'SN':'SEN',
	'RS':'SRB',
	'SC':'SYC',
	'SL':'SLE',
	'SG':'SGP',
	'SX':'SXM',
	'SK':'SVK',
	'SI':'SVN',
	'SB':'SLB',
	'SO':'SOM',
	'ZA':'ZAF',
	'GS':'SGS',
	'SS':'SSD',
	'ES':'ESP',
	'LK':'LKA',
	'SD':'SDN',
	'SR':'SUR',
	'SJ':'SJM',
	'SE':'SWE',
	'CH':'CHE',
	'SY':'SYR',
	'TW':'TWN',
	'TJ':'TJK',
	'TZ':'TZA',
	'TH':'THA',
	'TL':'TLS',
	'TG':'TGO',
	'TK':'TKL',
	'TO':'TON',
	'TT':'TTO',
	'TN':'TUN',
	'TR':'TUR',
	'TM':'TKM',
	'TC':'TCA',
	'TV':'TUV',
	'UG':'UGA',
	'UA':'UKR',
	'AE':'ARE',
	'GB':'GBR',
	'UM':'UMI',
	'US':'USA',
	'UY':'URY',
	'UZ':'UZB',
	'VU':'VUT',
	'VE':'VEN',
	'VN':'VNM',
	'VG':'VGB',
	'VI':'VIR',
	'WF':'WLF',
	'EH':'ESH',
	'YE':'YEM',
	'ZM':'ZMB',
	'ZW':'ZWE',
	'AX':'ALA'
}

const country_translation = {
	'Afghanistan': 'Afeganistão',
	'Albania': 'Albânia',
	'Algeria': 'Argélia',
	'American Samoa': 'Samoa Americana',
	'Andorra': 'Andorra',
	'Angola': 'Angola',
	'Antigua and Barbuda': 'Antígua e Barbuda',
	'Argentina': 'Argentina',
	'Armenia': 'Arménia',
	'Aruba': 'Aruba',
	'Australia': 'Austrália',
	'Austria': 'Áustria',
	'Azerbaijan': 'Azerbaijão',
	'Bahamas': 'Bahamas',
	'Bahrain': 'Bahrein',
	'Bangladesh': 'Bangladesh',
	'Barbados': 'Barbados',
	'Belarus': 'Bielorrússia',
	'Belgium': 'Bélgica',
	'Belize': 'Belize',
	'Benin': 'Benim',
	'Bermuda': 'Bermudas',
	'Bhutan': 'Butão',
	'Bolivia': 'Bolívia',
	'Bosnia and Herzegovina': 'Bósnia e Herzegovina',
	'Botswana': 'Botswana',
	'Brazil': 'Brasil',
	'Brunei Darussalam': 'Brunei',
	'Bulgaria': 'Bulgária',
	'Burkina Faso': 'Burkina Faso',
	'Burundi': 'Burundi',
	'Cambodia': 'Camboja',
	'Cameroon': 'Camarões',
	'Canada': 'Canadá',
	'Cape Verde': 'Cabo Verde',
	'Cayman Islands': 'Ilhas Cayman',
	'Central African Republic': 'República Centro-Africana',
	'Chad': 'Chade',
	'Chile': 'Chile',
	'China': 'China',
	'Colombia': 'Colômbia',
	'Comoros': 'Comores',
	'Congo': 'República do Congo',
	'Congo, The Democratic Republic of the': 'República Democrática do Congo',
	'Costa Rica': 'Costa Rica',
	"Cote D'Ivoire": 'Costa do Marfim',
	'Croatia': 'Croácia',
	'Cuba': 'Cuba',
	'Cyprus': 'Chipre',
	'Czech Republic': 'República Checa',
	'Denmark': 'Dinamarca',
	'Djibouti': 'Djibouti',
	'Dominica': 'Dominica',
	'Dominican Republic': 'República Dominicana',
	'Ecuador': 'Equador',
	'Egypt': 'Egito',
	'El Salvador': 'El Salvador',
	'Equatorial Guinea': 'Guiné Equatorial',
	'Eritrea': 'Eritreia',
	'Estonia': 'Estónia',
	'Ethiopia': 'Etiópia',
	'Faroe Islands': 'Ilhas Faroe',
	'Fiji': 'Fiji',
	'Finland': 'Finlândia',
	'France': 'França',
	'French Polynesia': 'Polinésia Francesa',
	'Gabon': 'Gabão',
	'Gambia': 'Gâmbia',
	'Georgia': 'Geórgia',
	'Germany': 'Alemanha',
	'Ghana': 'Gana',
	'Gibraltar': 'Gibraltar',
	'Greece': 'Grécia',
	'Greenland': 'Gronelândia',
	'Grenada': 'Granada',
	'Guam': 'Guam',
	'Guatemala': 'Guatemala',
	'Guinea': 'Guiné',
	'Guinea-Bissau': 'Guiné-Bissau',
	'Guyana': 'Guiana',
	'Haiti': 'Haiti',
	'Honduras': 'Honduras',
	'Hong Kong': 'Hong Kong',
	'Hungary': 'Hungria',
	'Iceland': 'Islândia',
	'India': 'Índia',
	'Indonesia': 'Indonésia',
	'Iran, Islamic Republic Of': 'Irã',
	'Iraq': 'Iraque',
	'Ireland': 'Irlanda',
	'Isle of Man': 'Ilha de Man',
	'Israel': 'Israel',
	'Italy': 'Itália',
	'Jamaica': 'Jamaica',
	'Japan': 'Japão',
	'Jordan': 'Jordânia',
	'Kazakhstan': 'Cazaquistão',
	'Kenya': 'Quénia',
	'Kiribati': 'Kiribati',
	"Korea, Democratic People'S Republic of": 'República Popular Democrática da Coreia',
	'South Korea': 'República da Coreia',
	'Kuwait': 'Kuwait',
	'Kyrgyzstan': 'Quirguistão',
	"Lao People'S Democratic Republic": 'Laos',
	'Latvia': 'Letónia',
	'Lebanon': 'Líbano',
	'Lesotho': 'Lesoto',
	'Liberia': 'Libéria',
	'Libya': 'Líbia',
	'Liechtenstein': 'Liechtenstein',
	'Lithuania': 'Lituânia',
	'Luxembourg': 'Luxemburgo',
	'Macao': 'Macau',
	'Macedonia, The Former Yugoslav Republic of': 'República da Macedônia do Norte',
	'Madagascar': 'Madagáscar',
	'Malawi': 'Malawi',
	'Malaysia': 'Malásia',
	'Maldives': 'Maldivas',
	'Mali': 'Mali',
	'Malta': 'Malta',
	'Marshall Islands': 'Ilhas Marshall',
	'Mauritania': 'Mauritânia',
	'Mauritius': 'Maurícia',
	'Mexico': 'México',
	'Micronesia, Federated States of': 'Micronésia',
	'Moldova, Republic of': 'Moldávia',
	'Monaco': 'Mónaco',
	'Mongolia': 'Mongólia',
	'Montenegro': 'Montenegro',
	'Morocco': 'Marrocos',
	'Mozambique': 'Moçambique',
	'Myanmar': 'Myanmar',
	'Namibia': 'Namíbia',
	'Nauru': 'Nauru',
	'Nepal': 'Nepal',
	'Netherlands': 'Países Baixos',
	'New Caledonia': 'Nova Caledônia',
	'New Zealand': 'Nova Zelândia',
	'Nicaragua': 'Nicarágua',
	'Niger': 'Níger',
	'Nigeria': 'Nigéria',
	'Northern Mariana Islands': 'Ilhas Marianas do Norte',
	'Norway': 'Noruega',
	'Oman': 'Omã',
	'Pakistan': 'Paquistão',
	'Palau': 'Palau',
	'Palestinian Territory, Occupied': 'Território Palestino, Ocupado',
	'Panama': 'Panamá',
	'Papua New Guinea': 'Papua-Nova Guiné',
	'Paraguay': 'Paraguai',
	'Peru': 'Peru',
	'Philippines': 'Filipinas',
	'Poland': 'Polónia',
	'Portugal': 'Portugal',
	'Puerto Rico': 'Porto Rico',
	'Qatar': 'Catar',
	'Romania': 'Roménia',
	'Russian Federation': 'Rússia',
	'Rwanda': 'Ruanda',
	'Saint Kitts and Nevis': 'São Cristóvão e Nevis',
	'Saint Lucia': 'Santa Lúcia',
	'Saint Martin (French part)': 'São Martinho',
	'Saint Vincent and the Grenadines': 'São Vicente e Granadinas',
	'Samoa': 'Samoa',
	'San Marino': 'San Marino',
	'Sao Tome and Principe': 'São Tomé e Príncipe',
	'Saudi Arabia': 'Arábia Saudita',
	'Senegal': 'Senegal',
	'Serbia': 'Sérvia',
	'Seychelles': 'Seychelles',
	'Sierra Leone': 'Serra Leoa',
	'Singapore': 'Singapura',
	'Slovakia': 'Eslováquia',
	'Slovenia': 'Eslovénia',
	'Solomon Islands': 'Ilhas Salomão',
	'Somalia': 'Somália',
	'South Africa': 'África do Sul',
	'Spain': 'Espanha',
	'Sri Lanka': 'Sri Lanka',
	'Sudan': 'Sudão',
	'Suri"name"': 'Suriname',
	'Swaziland': 'Suazilândia',
	'Sweden': 'Suécia',
	'Switzerland': 'Suíça',
	'Syrian Arab Republic': 'Síria',
	'Tajikistan': 'Tajiquistão',
	'Tanzania, United Republic of': 'Tanzânia',
	'Thailand': 'Tailândia',
	'Timor-Leste': 'Timor-Leste',
	'Togo': 'Togo',
	'Tonga': 'Tonga',
	'Trinidad and Tobago': 'Trinidad e Tobago',
	'Tunisia': 'Tunísia',
	'Turkey': 'Turquia',
	'Turkmenistan': 'Turquemenistão',
	'Turks and Caicos Islands': 'Ilhas Turcas e Caicos',
	'Tuvalu': 'Tuvalu',
	'Uganda': 'Uganda',
	'Ukraine': 'Ucrânia',
	'United Arab Emirates': 'Emirados Árabes Unidos',
	'United Kingdom': 'Reino Unido',
	'United States': 'Estados Unidos',
	'Uruguay': 'Uruguai',
	'Uzbekistan': 'Uzbequistão',
	'Vanuatu': 'Vanuatu',
	'Venezuela': 'Venezuela',
	'Vietnam': 'Vietname',
	'Virgin Islands, British': 'Ilhas Virgens Britânicas',
	'Virgin Islands, U.S.': 'Ilhas Virgens Americanas',
	'Yemen': 'Iémen',
	'Zambia': 'Zâmbia',
	'Zimbabwe': 'Zimbabwe',
};

const textEnglish = {
	"desc": "This page uses World Development Indicators to explore how Brazil compares to other countries across three categories: Human Capital, Economy, and Infrastructure & Environment. The themes were choosen because of their relevance with the focus areas under the Country Partnership Framework (CPF). All categories are important for focus area 1, while economy is especially relevant for focus area 2 and infrastructure and environment for focus areas 2 and 3. To compare up to two additional countries for each indicator, click ADD COUNTRY and select from the list."
}

const textPortuguese = {
	"desc": "Esta página usa Indicadores de Desenvolvimento Mundial para explorar como o Brasil se compara a outros países em três categorias: Capital Humano, Economia e Infraestrutura e Meio Ambiente. Os temas foram escolhidos devido à sua relevância nas áreas de foco do Quadro de Parceria com Países (CPF). Todas as categorias são importantes para a área de foco 1, enquanto a economia é especialmente relevante para a área de foco 2 e a infraestrutura e o ambiente para as áreas de foco 2 e 3. Para comparar até dois países adicionais para cada indicador, clique em ADICIONAR PAÍS e selecione na lista."
}

const Benchmark = (props) => {
	const [dataIsFetched, setDataIsFetched] = useState(false);
	const [countries, setCountries] = useState([]);
	const [wdiData, setWdiData] = useState(null);
	const [countryOrder, setCountryOrder] = useState([]);

	const removeCountry = (countryId) => {
		let updatedCountryOrder = [...countryOrder];
		updatedCountryOrder = updatedCountryOrder.filter((country) => country.iso3 !== countryId);
		let updatedWdiData = {...wdiData};
		delete updatedWdiData[countryId];
		setCountryOrder(updatedCountryOrder);
		setWdiData(updatedWdiData);
	};

	useEffect(() => {
		window.scrollTo(0,0);
		let query = "";
		let country_order = [{iso2:'BR',iso3:'BRA'}];
		if (props.location.state) {
			let countries = props.location.state.countries;
			for (let country in countries) {
				if (query.length === 0) {
					query += "?country=" + countries[country];
				} else {
					query += "&country=" + countries[country];
				}
				country_order.push({ iso2: countries[country], iso3: iso2toiso3[countries[country]] });
			}
		}

		axios.get(`${API_URL}/wdi_data${query}`).then(result => {
			setDataIsFetched(true);
			setWdiData(result.data);
			setCountryOrder(country_order);
		}).catch((error) => {
			if (error.response.status === 401) {
				localStorage.removeItem('auth');
				props.history.push('/');
			}
		});
	}, []);

	useEffect(() => {
		if (props.location.state) {
			setDataIsFetched(false);

			let countries = props.location.state.countries;
			let country_order = [{ iso2: 'BR', iso3: 'BRA' }];
			let query = "";

			for (let country in countries) {
				if (query.length === 0) {
					query += "?country=" + countries[country];
				} else {
					query += "&country=" + countries[country];
				}
				country_order.push({ iso2: countries[country], iso3: iso2toiso3[countries[country]] });
			}

			axios.get(`${API_URL}/wdi_data${query}`).then(result => {
				setDataIsFetched(true);
				setWdiData(result.data);
				setCountryOrder(country_order);
			}).catch((error) => {
		    	if (error.response.status === 401) {
		    		localStorage.removeItem('auth');
					props.history.push('/');
		    	}
		    });
		}
	}, [props]);

	let text = {};
	if (props.location.pathname.substring(0, 4) !== '/pt/') {
		text = textEnglish;
	} else {
		text = textPortuguese;
	}

	return (
		<>
			{dataIsFetched && wdiData ? (
				<>
					{countryOrder.length === 1 && <One
						{...props}
						country_translation={country_translation}
						wdiData={wdiData}
						countryOrder={countryOrder}
						text={text}
					/>}
					{ countryOrder.length === 2 && <Two
						{...props}
						country_translation={country_translation}
						wdiData={wdiData}
						countryOrder={countryOrder}
						removeCountry={removeCountry}
						text={text}
					/> }
					{ countryOrder.length === 3 && <Three
						{...props}
						country_translation={country_translation}
						wdiData={wdiData}
						countryOrder={countryOrder}
						removeCountry={removeCountry}
						text={text}
					/>}
				</>
			) : (
				<div className="loading-container">
					<ReactLoading type="spinningBubbles" color="#444"/>
				</div>
			)}
		</>
	);
}

export default withRouter(Benchmark);
