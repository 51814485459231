import axios from "axios";
import { withRouter } from "react-router-dom";
import React, { useEffect, useState } from "react";

import "../editscreen/styles.css";
import {API_URL} from "../../../config/api";
import Navbar from "../common/navbar/NavbarPLR";
import AltButton from "../../components/AltButton";
import StrategyHeader from "../common/strategyheader";
import InputSearchIcon from "../../../icons/input-search-icon.svg";
import FooterEditScreen from "../../components/EditScreen/FooterEditScreen";
import { IndicatorCard } from "../../components/EditScreen/Indicators/IndicatorCard";
import FilterIndicatorsModal from "../../components/FilterIndicatorsModal/FilterIndicatorsModal";

const allIndicators = [];

const getIndicators = async () => {
  const response = await axios.get(
    `${API_URL}/indicators`
  );

  if (response.status !== 200) {
    return [];
  }

  let groupedIndicators = [];

  for (const objective of Object.values(response.data)) {
    for (const currentIndicator of objective.indicators) {
      const focusId = currentIndicator.indicator_id
        .slice(0, 2)
        .replace(".", "");
      const isAlreadyGrouped = groupedIndicators.find(
        (indicator) =>
          indicator.indicator_id.slice(0, 2).replace(".", "") === focusId
      );

      if (isAlreadyGrouped) {
        const parentIndicator = isAlreadyGrouped;

        if (!parentIndicator.children) {
          parentIndicator.children = [];
        }

        allIndicators.push(currentIndicator);
        parentIndicator.children.push(currentIndicator);
        continue;
      } else {
          allIndicators.push(currentIndicator);
      }

      groupedIndicators.push(currentIndicator);
    }
  }

  return groupedIndicators;
};

const IndicatorsEditScreen = (props) => {
  const [pt, setPt] = useState(false);
  const [indicators, setIndicators] = useState([]);
  const [currentIndicators, setCurrentIndicators] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [maxPages, setMaxPages] = useState(1);
  const [indicatorsFilter, setIndicatorsFilter] = useState([]);
  const [isFilterModalVisible, setIsFilterModalVisible] = useState(false);

  const handleLogout = () => {};

  const handleClick = (page) => {
    setCurrentPage(page);
  };

  const searchIndicators = (e) => {
    const searchText = e.target.value.toLowerCase();
    const searchResult = indicators.filter((indicator) => {
      const children = indicator.children?.filter(
        (children) =>
          children.title.toLowerCase().includes(searchText) ||
          children.title_pt.toLowerCase().includes(searchText)
      );
      return (
        children?.length ||
        indicator.title.toLowerCase().includes(searchText) ||
        indicator.title_pt.toLowerCase().includes(searchText)
      );
    });
    if (!searchText) return setIndicatorsFilter(null);
    setIndicatorsFilter(searchResult);
  };

  const filterIndicators = (focusSelect) => {
    if (!focusSelect) return setIndicatorsFilter(null);

    const searchResult = indicators.filter((indicator) => {
      const children = indicator.children?.filter(
        (child) =>
          child.objective_id.slice(0, 2).replace(".", "") ===
          focusSelect.slice(-1)
      );
      return (
        children?.length ||
        indicator.objective_id.slice(0, 2).replace(".", "") ===
          focusSelect.slice(-1)
      );
    });
    setIndicatorsFilter(searchResult);
  };

  useEffect(() => {
    if (props.location.pathname.substring(0, 4) === "/pt/") {
      setPt(true);
    }

    getIndicators().then((indicators) => {
      if (indicators.length) {
        setMaxPages(Math.ceil(indicators.length / 10));
      }
      setIndicators(indicators);
    });
  }, []);

  useEffect(() => {
    const indicatorsOrFilter =
      indicatorsFilter?.length > 0 ? indicatorsFilter : indicators;

    if (indicatorsOrFilter.length) {
      const startIndex = (currentPage - 1) * 10;
      const endIndex = startIndex + 10;
      const maxPages = Math.ceil(indicatorsOrFilter.length / 10);

      const result = indicatorsOrFilter
        .filter((_, index) => indicatorsOrFilter.hasOwnProperty(index))
        .slice(startIndex, endIndex)
        .map((item) => item);

      setCurrentIndicators(result);
      setMaxPages(maxPages);
    }
  }, [maxPages, currentPage, indicators, indicatorsFilter]);

  const renderPagination = () => {
    const pages = [];
    for (let i = 1; i <= maxPages; i++) {
      pages.push(
        <button
          key={i}
          className="pagination-button"
          onClick={() => handleClick(i)}
          disabled={currentPage === i}
        >
          {i}
        </button>
      );
    }

    const visiblePages = pages.slice(currentPage - 1, currentPage + 4);

    return (
      <div className="pagination-wrapper">
        <button
          disabled={currentPage < 2}
          className="pagination-button previous"
          onClick={() => handleClick(currentPage - 1)}
        >
          Anterior
        </button>
        {visiblePages}
        <button
          className="pagination-button next"
          disabled={currentPage === maxPages}
          onClick={() => handleClick(currentPage + 1)}
        >
          Próximo
        </button>
      </div>
    );
  };

  return (
    <div className="container-fluid unpadify">
      <Navbar
        hideSubBar={true}
        handleLogin={handleLogout}
        handleLogout={handleLogout}
        auth={props.auth}
        isLoggedIn={props.loggedIn}
        checkIsLoggedIn={props.checkIsLoggedIn}
        onChangeLoginState={props.onChangeLoginState}
        currentPage={"edit-screen"}
        {...props}
      />
      <StrategyHeader
        selectedTab={2}
        tabs={[
          {
            link: pt ? "/pt/edit-screen/projects" : "/edit-screen/projects",
            label: pt ? "Projetos" : "Projects",
          },
          {
            link: pt ? "/pt/edit-screen/reports" : "/edit-screen/reports",
            label: pt ? "Relatórios" : "Reports",
          },
          {
            link: pt ? "/pt/edit-screen/indicators" : "/edit-screen/indicators",
            label: pt ? "Indicadores" : "Indicators",
          },
        ]}
      />
      <div id="projects-container">
        <h2>{pt ? "Indicadores" : "Indicators"}</h2>
        <div className="divider"></div>
      </div>
      <div
        id="projects-container"
        style={{
          marginTop: "30px",
          padding: "0px 143px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              position: "relative",
              marginBottom: "16px",
              display: "flex",
            }}
          >
            <div style={{ position: "absolute", top: "9px", left: "10px" }}>
              <img src={InputSearchIcon} alt="" />
            </div>
            <input
              onChange={searchIndicators}
              type="text"
              style={{ marginRight: "16px" }}
              className="projects-search-filter"
              placeholder={pt ? "Busca por indicador" : "Search by indicator"}
            />
          </div>
          <AltButton
            inverse={true}
            onClick={() => setIsFilterModalVisible(true)}
            style={{
              height: "38px",
              width: "84px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {pt ? "Filtrar" : "Filter"}
          </AltButton>
        </div>

        {currentIndicators?.map((indicator) => (
          <IndicatorCard
            key={indicator.indicator_id}
            style={{ marginBottom: "30px" }}
            {...{ ...indicator, children: undefined }} // remove children from props
            pt={pt}
            allIndicators={allIndicators}
            indicators={indicators}
            location={props.location}
          >
            {indicator.children?.map(
              (
                indicatorChild // render children
              ) => (
                <IndicatorCard
                  key={indicatorChild.indicator_id}
                  {...indicatorChild}
                  allIndicators={allIndicators}
                  indicators={indicators}
                  location={props.location}
                  pt={pt}
                />
              )
            )}
          </IndicatorCard>
        ))}
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: "50px",
          }}
        >
          {renderPagination()}
        </div>
      </div>
      <FooterEditScreen />
      <FilterIndicatorsModal
        pt={pt}
        isOpen={isFilterModalVisible}
        filterIndicators={filterIndicators}
        onClose={() => setIsFilterModalVisible(false)}
      />
    </div>
  );
};

export default withRouter(IndicatorsEditScreen);
