import React, { useState } from 'react';
import { Collapse } from 'react-bootstrap';

import GroupedBar from '../../../common/graphs/GroupedBar';
import GroupedBar2 from '../../../common/graphs/GroupedBar2';
import GraphTabs from '../../../common/graphTabs/GraphTabs';

import './Section.css'

const textEnglish = {
	'infrastructure_environment': 'Infrastructure and Environment',
	'source': 'SOURCE',
	'natural': 'Natural Hazards',
	'infrastructure': 'Infrastructure',
	'historical': 'Historical'
}

const textPortuguese = {
	'infrastructure_environment': 'Infraestrutura e Meio Ambiente',
	'source': 'FONTE',
	'natural': 'Riscos Naturais',
	'infrastructure': 'Infraestrutura',
	'historical': 'Histórico'
}

const InfrastructureAndEnvironment = (props) => {
	const [isOpen, setIsOpen] = useState(true);
	const [hasLoaded, setHasLoaded] = useState(false);

	let pt = false;
	let text = {};
	if (props.location.pathname.substring(0, 4) !== '/pt/') {
		text = textEnglish;
	} else {
		pt = true;
		text = textPortuguese;
	}

	let buttonText = <i className="fa fa-caret-down plus-icon"/>;
	if (isOpen) {
		buttonText = <i className="fa fa-caret-right minus-icon"/>;
	}
	let visible = props.visibleSections;

	if (isOpen || hasLoaded) {
		return (
			<div className="section-container" style={visible['section-four'] ? {} : {'display':'none'}}>
				<div className="row">
					<div className="col-md-12 categoryContent benchmark-section-content">
						<Collapse in={isOpen}>
							<div>
								<div id="section-four-subsection-zero" style={visible['section-four-subsection-zero'] ? {} : {'display':'none'}}>
									<h2 style={{ fontSize: '32px' }}>{text.natural}</h2>
									<h4 style={{ fontSize: '22px' }} className="pad-top">
										{props.wdi_data[props.country_order[0].iso3].indicators["SP.URB.TOTL"][(pt ? 'name_pt' : 'name')]}
									</h4>
									<div className="row">
										<div className="col-xs-12">
											<p className="benchmarking-text">
												{props.wdi_data[props.country_order[0].iso3].indicators["SP.URB.TOTL"][(pt ? 'source_note_pt' : 'source_note')]}
											</p>
										</div>
										<div className="col-xs-12">
											<GraphTabs
												{...props}
												pt={pt}
												tab1Name={props.wdi_data[props.country_order[0].iso3].indicators["SP.URB.TOTL"].data[props.wdi_data[props.country_order[0].iso3].indicators["SP.URB.TOTL"].data.length-1].year}
												tab2Name={text.historical}
												tab1Content={
													<div>
														<GroupedBar
															{...props}
															pt={pt}
															wdi_data={props.wdi_data}
															country_order={props.country_order}
															field="SP.URB.TOTL"
															values={1}
															formatter={'0,0.00'}
														/>
														<p className="footnote">
															{text.source}: {props.wdi_data[props.country_order[0].iso3].indicators["SP.URB.TOTL"][(pt ? 'source_pt' : 'source')]}
														</p>
													</div>
												}
												tab2Content={
													<div>
														<GroupedBar2
															{...props}
															pt={pt}
															wdi_data={props.wdi_data}
															country_order={props.country_order}
															field="SP.URB.TOTL"
															values={5}
															formatter={'0,0.00'}
														/>
														<p className="footnote">
															{text.source}: {props.wdi_data[props.country_order[0].iso3].indicators["SP.URB.TOTL"][(pt ? 'source_pt' : 'source')]}
														</p>
													</div>
												}
											/>
										</div>
									</div>
									<h4 style={{ fontSize: '22px' }} className="pad-top">
										{props.wdi_data[props.country_order[0].iso3].indicators["EN.POP.SLUM.UR.ZS"][(pt ? 'name_pt' : 'name')]}
									</h4>
									<div className="row">
										<div className="col-xs-12">
											<p className="benchmarking-text">
												{props.wdi_data[props.country_order[0].iso3].indicators["EN.POP.SLUM.UR.ZS"][(pt ? 'source_note_pt' : 'source_note')]}
											</p>
										</div>
										<div className="col-xs-12">
											<GraphTabs
												{...props}
												pt={pt}
												tab1Name={props.wdi_data[props.country_order[0].iso3].indicators["EN.POP.SLUM.UR.ZS"].data[props.wdi_data[props.country_order[0].iso3].indicators["EN.POP.SLUM.UR.ZS"].data.length-1].year}
												tab2Name={text.historical}
												tab1Content={
													<div>
														<GroupedBar
															{...props}
															pt={pt}
															wdi_data={props.wdi_data}
															country_order={props.country_order}
															field="EN.POP.SLUM.UR.ZS"
															values={1}
															formatter={'0,0.00'}
														/>
														<p className="footnote">
															{text.source}: {props.wdi_data[props.country_order[0].iso3].indicators["EN.POP.SLUM.UR.ZS"][(pt ? 'source_pt' : 'source')]}
														</p>
													</div>
												}
												tab2Content={
													<div>
														<GroupedBar2
															{...props}
															pt={pt}
															wdi_data={props.wdi_data}
															country_order={props.country_order}
															field="EN.POP.SLUM.UR.ZS"
															values={5}
															formatter={'0,0.00'}
														/>
														<p className="footnote">
															{text.source}: {props.wdi_data[props.country_order[0].iso3].indicators["EN.POP.SLUM.UR.ZS"][(pt ? 'source_pt' : 'source')]}
														</p>
													</div>
												}
											/>
										</div>
									</div>
									<h4 style={{ fontSize: '22px' }} className="pad-top">
										{props.wdi_data[props.country_order[0].iso3].indicators["ER.H2O.FWTL.ZS"][(pt ? 'name_pt' : 'name')]}
									</h4>
									<div className="row">
										<div className="col-xs-12">
											<p className="benchmarking-text">
												{props.wdi_data[props.country_order[0].iso3].indicators["ER.H2O.FWTL.ZS"][(pt ? 'source_note_pt' : 'source_note')]}
											</p>
										</div>
										<div className="col-xs-12">
											<GraphTabs
												{...props}
												pt={pt}
												tab1Name={props.wdi_data[props.country_order[0].iso3].indicators["ER.H2O.FWTL.ZS"].data[props.wdi_data[props.country_order[0].iso3].indicators["ER.H2O.FWTL.ZS"].data.length-1].year}
												tab2Name={text.historical}
												tab1Content={
													<div>
														<GroupedBar2
															{...props}
															pt={pt}
															wdi_data={props.wdi_data}
															country_order={props.country_order}
															field="ER.H2O.FWTL.ZS"
															values={1}
															formatter={'0,0.00'}
														/>
														<p className="footnote">
															{text.source}: {props.wdi_data[props.country_order[0].iso3].indicators["ER.H2O.FWTL.ZS"][(pt ? 'source_pt' : 'source')]}
														</p>
													</div>
												}
												tab2Content={
													<div>
														<GroupedBar2
															{...props}
															pt={pt}
															wdi_data={props.wdi_data}
															country_order={props.country_order}
															field="ER.H2O.FWTL.ZS"
															values={5}
															formatter={'0,0.00'}
														/>
														<p className="footnote">
															{text.source}: {props.wdi_data[props.country_order[0].iso3].indicators["ER.H2O.FWTL.ZS"][(pt ? 'source_pt' : 'source')]}
														</p>
													</div>
												}
											/>
										</div>
									</div>
								</div>
								<div id="section-four-subsection-one" style={visible['section-four-subsection-one'] ? {} : {'display':'none'}}>
									<h2 style={{ fontSize: '32px' }}>{text.infrastructure}</h2>
									<h4 style={{ fontSize: '22px' }} className="pad-top">
										{props.wdi_data[props.country_order[0].iso3].indicators["EG.FEC.RNEW.ZS"][(pt ? 'name_pt' : 'name')]}
									</h4>
									<div className="row">
										<div className="col-xs-12">
											<p className="benchmarking-text">
												{props.wdi_data[props.country_order[0].iso3].indicators["EG.FEC.RNEW.ZS"][(pt ? 'source_note_pt' : 'source_note')]}
											</p>
										</div>
										<div className="col-xs-12">
											<GraphTabs
												{...props}
												pt={pt}
												tab1Name={props.wdi_data[props.country_order[0].iso3].indicators["EG.FEC.RNEW.ZS"].data[props.wdi_data[props.country_order[0].iso3].indicators["EG.FEC.RNEW.ZS"].data.length-1].year}
												tab2Name={text.historical}
												tab1Content={
													<div>
														<GroupedBar
															{...props}
															pt={pt}
															wdi_data={props.wdi_data}
															country_order={props.country_order}
															field="EG.FEC.RNEW.ZS"
															values={1}
															formatter={'0,0.00'}
														/>
														<p className="footnote">
															{text.source}: {props.wdi_data[props.country_order[0].iso3].indicators["EG.FEC.RNEW.ZS"][(pt ? 'source_pt' : 'source')]}
														</p>
													</div>
												}
												tab2Content={
													<div>
														<GroupedBar2
															{...props}
															pt={pt}
															wdi_data={props.wdi_data}
															country_order={props.country_order}
															field="EG.FEC.RNEW.ZS"
															values={5}
															formatter={'0,0.00'}
														/>
														<p className="footnote">
															{text.source}: {props.wdi_data[props.country_order[0].iso3].indicators["EG.FEC.RNEW.ZS"][(pt ? 'source_pt' : 'source')]}
														</p>
													</div>
												}
											/>
										</div>
									</div>
									<h4 style={{ fontSize: '22px' }} className="pad-top">
										{props.wdi_data[props.country_order[0].iso3].indicators["EG.USE.ELEC.KH.PC"][(pt ? 'name_pt' : 'name')]}
									</h4>
									<div className="row">
										<div className="col-xs-12">
											<p className="benchmarking-text">
												{props.wdi_data[props.country_order[0].iso3].indicators["EG.USE.ELEC.KH.PC"][(pt ? 'source_note_pt' : 'source_note')]}
											</p>
										</div>
										<div className="col-xs-12">
											<GraphTabs
												{...props}
												pt={pt}
												tab1Name={props.wdi_data[props.country_order[0].iso3].indicators["EG.USE.ELEC.KH.PC"].data[props.wdi_data[props.country_order[0].iso3].indicators["EG.USE.ELEC.KH.PC"].data.length-1].year}
												tab2Name={text.historical}
												tab1Content={
													<div>
														<GroupedBar
															{...props}
															pt={pt}
															wdi_data={props.wdi_data}
															country_order={props.country_order}
															field="EG.USE.ELEC.KH.PC"
															values={1}
															formatter={'0,0.00'}
														/>
														<p className="footnote">
															{text.source}: {props.wdi_data[props.country_order[0].iso3].indicators["EG.USE.ELEC.KH.PC"][(pt ? 'source_pt' : 'source')]}
														</p>
													</div>
												}
												tab2Content={
													<div>
														<GroupedBar2
															{...props}
															pt={pt}
															wdi_data={props.wdi_data}
															country_order={props.country_order}
															field="EG.USE.ELEC.KH.PC"
															values={5}
															formatter={'0,0.00'}
														/>
														<p className="footnote">
															{text.source}: {props.wdi_data[props.country_order[0].iso3].indicators["EG.USE.ELEC.KH.PC"][(pt ? 'source_pt' : 'source')]}
														</p>
													</div>
												}
											/>
										</div>
									</div>
									<h4 style={{ fontSize: '22px' }} className="pad-top">
										{props.wdi_data[props.country_order[0].iso3].indicators["IT.CEL.SETS.P2"][(pt ? 'name_pt' : 'name')]}
									</h4>
									<div className="row">
										<div className="col-xs-12">
											<p className="benchmarking-text">
												{props.wdi_data[props.country_order[0].iso3].indicators["IT.CEL.SETS.P2"][(pt ? 'source_note_pt' : 'source_note')]}
											</p>
										</div>
										<div className="col-xs-12" style={{ marginBottom: '20px' }}>
											<GraphTabs
												{...props}
												pt={pt}
												activeKey="second"
												tab1Name={props.wdi_data[props.country_order[0].iso3].indicators["IT.CEL.SETS.P2"].data[props.wdi_data[props.country_order[0].iso3].indicators["IT.CEL.SETS.P2"].data.length-1].year}
												tab2Name={text.historical}
												tab1Content={
													<div>
														<GroupedBar2
															{...props}
															pt={pt}
															wdi_data={props.wdi_data}
															country_order={props.country_order}
															field="IT.CEL.SETS.P2"
															values={1}
															formatter={'0,0.00'}
														/>
														<p className="footnote">
															{text.source}: {props.wdi_data[props.country_order[0].iso3].indicators["IT.CEL.SETS.P2"][(pt ? 'source_pt' : 'source')]}
														</p>
													</div>
												}
												tab2Content={
													<div>
														<GroupedBar
															{...props}
															pt={pt}
															wdi_data={props.wdi_data}
															country_order={props.country_order}
															field="IT.CEL.SETS.P2"
															values={5}
															formatter={'0,0.00'}
														/>
														<p className="footnote">
															{text.source}: {props.wdi_data[props.country_order[0].iso3].indicators["IT.CEL.SETS.P2"][(pt ? 'source_pt' : 'source')]}
														</p>
													</div>
												}
											/>
										</div>
									</div>
								</div>
							</div>
						</Collapse>
					</div>
				</div>
			</div>
		);
	} else {
		return (
			<div className="section-container" style={visible['section-four'] ? {} : {'display':'none'}}>
				<div className="row">
					<div className="col-md-12"><hr /></div>
				</div>
			</div>
		);
	}
}

export default InfrastructureAndEnvironment;
