import axios from "axios";
import React, {useEffect, useState} from "react";
import {useHistory, withRouter} from "react-router-dom";

import Button from "../../components/Button";
import Navbar from "../common/navbar/NavbarPLR";
import StrategyHeader from "../common/strategyheader";
import ReportModal from "../../components/ReportModal";
import FooterEditScreen from "../../components/EditScreen/FooterEditScreen";

import '../editscreen/styles.css';
import EditIcon from "../../../icons/edit-icon.svg.svg";
import { API_URL, IMG_URL } from "../../../config/api";
import ConfirmDeleteModal from "../../components/ConfirmDeleteModal";
import AlertPopup from "../../components/AlertPopup";
import Checkbox from "../../components/Checkbox";

const textEnglish = {
    delete: 'Delete',
    deleteError: 'Error on deleting report(s).',
    deleteSuccess: 'Report(s) deleted succesfully!',
    errorReports: 'Error on load report(s).' 
}

const textPortuguese = {
    delete: 'Excluir',
    deleteError: 'Erro ao deletar relatório(s).',
    deleteSucces: 'Relatórios deletado(s) com sucesso!',
    errorReports: 'Erro ao listar relatório(s).' 
}

const ReportsEditScreen = (props) => {
    const [text, setText] = useState(textEnglish);
    const [selectedReport, setSelectedReport] = useState(null);
    const [currentReports, setCurrentReports] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [maxPages, setMaxPages] = useState(1);
    const [pt, setPt] = useState(false);
    const [reportsFilter, setReportsFilter] = useState(null);
    const [reports, setReports] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [reportsToDelete, setReportsToDelete] = useState([]);
    const [openConfirmDeleteModal, setOpenConfirmDeleteModal] = useState(false);
    const [alertProps, setAlertProps] = useState(null);

    const handleLogout = () => {};

    const editReport = (report) => {
        setSelectedReport(report);
        setEditMode(true);
        setShowModal(true);
    }

    const addReport = () => {
        setEditMode(false);
        setShowModal(true);
    }

    const closeModal = () => {
        setSelectedReport(null);
        setEditMode(false);
        setShowModal(false);
        loadReports();
    }

    const openAlertPopup = (props) => {
        setAlertProps(props);
    }

    const handleClick = (page) => {
        setCurrentPage(page);
    };

    const handleSelectReport = (url) => {
        const alreadyIn = reportsToDelete.find(project => project === url);

        if (alreadyIn) {
            const newList = reportsToDelete.filter(value => url !== value);
            return setReportsToDelete(newList);
        }

        return setReportsToDelete([...reportsToDelete, url]);
    }

    const handleSelectAllReports = () => {
        if (reportsToDelete.length === currentReports.length) {
            return setReportsToDelete([]);
        }

        const urls = currentReports.map(({ url }) => url);
        setReportsToDelete(urls);
    }

    const deleteReports = async () => {
        try {
            await axios
                .put(`${API_URL}/reports-delete`, { reports: reportsToDelete });

            openAlertPopup({ 
                type: 'success', 
                message: text.deleteSucces, 
                duration: 3000 
            });

        } catch (error) {
            openAlertPopup({
                type: 'danger',
                message: text.deleteError,
                duration: 3000
            });
        }
    }

    const loadReports = async () => {
        window.scrollTo(0, 0);

        await axios
            .get(`${API_URL}/reports`)
            .then((response) => {
                const result = response.data.map((report, index) => ({ id: index+1, ...report }));
                setReports(result);
            })
            .catch((err) => {
                setAlertProps({
                    type: 'danger',
                    message: text.deleteSuccess,
                    duration: 3000
                });
            });
    }

    const handleConfirmDeletion = async () => {
        setOpenConfirmDeleteModal(false);
        await deleteReports();
        setReportsToDelete([]);
        await loadReports();
    }

    const searchReport = (e) => {
        const searchText = e.target.value.toLowerCase();
        const searchResult = reports.filter(
            (project) => project.title.toLowerCase().includes(searchText)
        );
        if(!searchText) return setReportsFilter(null);
        setReportsFilter(searchResult);
    }

    const renderPagination = () => {
        const pages = [];
        for (let i = 1; i <= maxPages; i++) {
            pages.push(
                <button
                    key={i}
                    className="pagination-button"
                    onClick={() => handleClick(i)}
                    disabled={currentPage === i}
                >
                    {i}
                </button>
            );
        }
        return (
            <div className="pagination-wrapper">
                <button
                    disabled={currentPage < 2}
                    className="pagination-button previous"
                    onClick={() => handleClick(currentPage-1)}
                >Anterior</button>
                {pages}
                <button
                    className="pagination-button next"
                    disabled={currentPage === maxPages}
                    onClick={() => handleClick(currentPage+1)}
                >Próximo</button>
            </div>
        );
    };

    useEffect(() => {
        const reportsOrFilter = reportsFilter?.length > 0 ? reportsFilter : reports;

        if (reportsOrFilter.length) {
            const startIndex = (currentPage - 1) * 10;
            const endIndex = startIndex + 10
            const result = reportsOrFilter.slice(startIndex, endIndex).map((item) => item);
            setCurrentReports(result);
        }
    }, [maxPages, currentPage, reportsFilter, reports]);

    useEffect(() => {
        if (reports.length) {
            setMaxPages(Math.ceil(reports.length / 10));
        }
    }, [reports])

    useEffect(() => {
        if (props.location.pathname.substring(0, 4) === '/pt/') {
            setPt(true);
            setText(textPortuguese);
        }

        loadReports();
    }, []);

    return (
        <div className="container-fluid unpadify">
            <Navbar
                hideSubBar={true}
                handleLogin={handleLogout}
                handleLogout={handleLogout}
                auth={props.auth}
                isLoggedIn={props.loggedIn}
                checkIsLoggedIn={props.checkIsLoggedIn}
                onChangeLoginState={props.onChangeLoginState}
                currentPage={'edit-screen'}
                {...props}
            />
            <StrategyHeader
                selectedTab={1}
                tabs={[
                    { link: pt ? '/pt/edit-screen/projects' : '/edit-screen/projects', label: pt ? 'Projetos' : 'Projects' },
                    { link: pt ? '/pt/edit-screen/reports' : '/edit-screen/reports', label: pt ? 'Relatórios' : 'Reports' },
                    { link: pt ? '/pt/edit-screen/indicators' : '/edit-screen/indicators', label: pt ? 'Indicadores' : 'Indicators' },
                ]}
            />
            <div id="projects-container">
                <div className="title-row">
                    <h2>{pt ? "Relatórios" : "Reports"}</h2>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '30px' }}>
                        {reportsToDelete.length > 0 && (
                            <button
                                className="delete-button"
                                onClick={() => setOpenConfirmDeleteModal(true)}
                            >
                                {text.delete}
                            </button>
                        )}
                        <Button onClick={addReport}>Incluir relatório</Button>
                    </div>
                    {showModal && (
                        <ReportModal
                            showModal={true}
                            editMode={editMode}
                            closeModal={closeModal}
                            selectedReport={selectedReport}
                            {...props}
                        />
                    )}
                    {openConfirmDeleteModal && (
                        <ConfirmDeleteModal
                            showModal={true}
                            onDelete={handleConfirmDeletion}
                            closeModal={() => setOpenConfirmDeleteModal(false)}
                            {...props}
                        />
                    )}
                    {alertProps && (
                        <AlertPopup onClose={() => setAlertProps(null)} {...alertProps}/>
                    )}
                </div>
                <div className="divider"></div>
                <div className="edit-screen-content">
                    <div style={{ position: 'relative', marginBottom: '16px' }}>
                        <div style={{ position: 'absolute', top: '9px', left: '10px' }}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10 17C13.866 17 17 13.866 17 10C17 6.13401 13.866 3 10 3C6.13401 3 3 6.13401 3 10C3 13.866 6.13401 17 10 17Z" stroke="#CCCCCC" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M15 15L21 21" stroke="#CCCCCC" strokeWidth="2" strokeLinecap="square" strokeLinejoin="round"/>
                            </svg>
                        </div>
                        <input
                            onChange={searchReport}
                            type="text"
                            style={{ marginRight: '16px' }}
                            className="projects-search-filter"
                            placeholder={pt ? "Busca por ID, # e Nome do relatório" : "Search by ID, # and Name of the report"}
                        />
                    </div>

                    <table className="edit-screen-table">
                        <thead>
                            <tr>
                                <td>
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        <Checkbox
                                            onChange={handleSelectAllReports}
                                            value={reportsToDelete.length === currentReports.length}
                                        ></Checkbox>
                                    </div>
                                </td>
                                <td>ID</td>
                                <td>#</td>
                                <td>Image Title (PT)</td>
                                <td>Title (EN)</td>
                                <td>Editar</td>
                            </tr>
                        </thead>
                        <tbody>
                        {reportsFilter && reportsFilter.length === 0 ? (
                            <tr>
                                <td colSpan={8}>Nenhum resultado encontrado.</td>
                            </tr>
                        ) : (
                            currentReports.length > 0 ? (
                                currentReports.map((report, index) => {
                                    return (
                                        <tr key={report.url}>
                                            <td>
                                                <Checkbox
                                                    onChange={() => handleSelectReport(report.url)}
                                                    value={reportsToDelete.includes(report.url)}
                                                ></Checkbox>
                                            </td>
                                            <td>{report.id}</td>
                                            <td>{report.id}</td>
                                            <td className="title-column reports-title-column">
                                                <img
                                                    alt={report.title}
                                                    src={IMG_URL + report.image_name}
                                                    style={{
                                                        width: '37px',
                                                        height: '54px',
                                                        marginRight: '10px'
                                                    }}
                                                />
                                                {report.title_pt}
                                            </td>
                                            <td className="title-column">{report.title}</td>
                                            <td>
                                                <Button
                                                    onClick={() => editReport(report)}
                                                    style={{
                                                        height: '32px',
                                                        width: '32px',
                                                        padding: '0'
                                                    }}
                                                >
                                                    <img
                                                        src={EditIcon}
                                                        alt="Edit"
                                                        style={{ height: '16px', width: '16px' }}
                                                    />
                                                </Button>
                                            </td>
                                        </tr>
                                    );
                                })
                            ) : (
                                <tr></tr>
                            )
                        )}
                        </tbody>
                    </table>
                    {!reportsFilter || reportsFilter.length > 0 ? renderPagination() : <></>}
                </div>
            </div>

            <FooterEditScreen />
        </div>
    );
}

export default withRouter(ReportsEditScreen);
