import React from 'react';
import './styles.css';
import InputMask from 'react-input-mask';
import {NumericFormat} from "react-number-format";

const Input = ({
    className,
    error= null,
    icon,
    isCurrency,
    ...props
}) => {

    const renderInput = () => {
        if (isCurrency) {
            return (
                <NumericFormat
                    {...props}
                    prefix="$"
                    decimalSeparator="."
                    thousandSeparator=","
                    className={`${className ?? ''} ${error ? 'has-error' : ''}`}
                />
            )
        }

        if (props.mask) {
            return <InputMask {...props} className={`${className ?? ''} ${error ? 'has-error' : ''}`} />
        }

        return <input {...props} className={`${className ?? ''} ${error ? 'has-error' : ''}`} />
    }

    return (
        <div className="input-wrapper">
            {renderInput()}
            { icon && (
                <img
                    alt="Input icon"
                    style={{
                        position: 'absolute',
                        right: '15px',
                        bottom: '19px',
                        height: '24px',
                    }}
                    src={icon}
                />
            )}
            {error && (
                <span className="error">{error}</span>
            )}
        </div>
    );
}

export default Input;
