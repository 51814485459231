import React, { useState } from 'react';

import './FilterIndicatorsModal.css';
import Modal from "../Modal";
import {Radio} from "../Radio/Radio";

const FilterIndicatorsModal = ({ isOpen, onClose, pt, filterIndicators }) => {
  const [selectedFocus, setSelectedFocus] = useState(null);

  const handleCancel = () => {
    onClose();
  };


  if (!isOpen) {
    return null;
  }

  return (
    <Modal modalVisibility={() => isOpen}>
      <div className="modal-add-indicator">
        <div className="modal-header-add-indicator">
          <h2>{pt ? 'Filtrar' : 'Filter'}</h2>
          <button className="close-button" onClick={onClose}>X</button>
        </div>
        <hr />
        <div className="modal-body">
          <div className="select-row">
            <label htmlFor="indicator">{pt ? 'Área de foco' : 'Focus area'}:</label>
          </div>
          <div className="select-row">
            <Radio
                selected={selectedFocus === null}
                handleSelect={() => setSelectedFocus(null)}
            ></Radio>
            <label htmlFor="indicator">{pt ? 'Todos' : 'All'}</label>
          </div>
          <div className="select-row">
            <Radio
                selected={selectedFocus === 'focus_1'}
                handleSelect={() => setSelectedFocus('focus_1')}
            ></Radio>
            <label htmlFor="indicator">{pt ? 'Área de foco 1' : 'Focus area 1'}</label>
          </div>
          <div className="select-row">
            <Radio
                selected={selectedFocus === 'focus_2'}
                handleSelect={() => setSelectedFocus('focus_2')}
            ></Radio>
            <label htmlFor="indicator">{pt ? 'Área de foco 2' : 'Focus area 2'}</label>
          </div>
          <div className="select-row">
            <Radio
                selected={selectedFocus === 'focus_3'}
                handleSelect={() => setSelectedFocus('focus_3')}
            ></Radio>
            <label htmlFor="indicator">{pt ? 'Área de foco 3' : 'Focus area 3'}</label>
          </div>
        </div>
        <div className="modal-footer-add-indicator">
          <button className="cancel-button" onClick={handleCancel}>Cancelar</button>
          <button className="add-indicator-button" onClick={() => {
            filterIndicators(selectedFocus);
            onClose();
          }}>Aplicar filtro</button>
        </div>
      </div>
    </Modal>
  );
};

export default FilterIndicatorsModal;





