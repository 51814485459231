import axios from "axios";
import path from "path";
const CryptoJS = require("crypto-js");
import React, { useEffect, useState } from "react";

import Modal from "../Modal";
import Input from "../Input";
import Button from "../Button";
import TitleSection from "../TitleSection";

import "./styles.css";
import "../../styles/modal-form.css";
import AltButton from "../AltButton";
import { ImageInput } from "../ImageInput";
import {API_URL} from "../../../config/api";

const textPortuguese = {
  addTitle: "Incluir relatório",
  editTitle: "Editar relatório",
  title: "Título do Relatório (EN)",
  title_pt: "Título do Relatório (PT)",
  url: "URL (EN)",
  url_pt: "URL (PT)",
  imageFile: "Imagem (Inglês)",
  imageFilePt: "Imagem (Português)",
  save: "Incluir relatório",
  cancel: "Cancelar",
  edit: "Salvar edição",
};

const textEnglish = {
  title: "Add report",
  editTitle: "Edit report",
  save: "Add report",
  edit: "Save edition",
  cancel: "Cancel",
};

const emptyForm = {
  title: null,
  title_pt: null,
  url: null,
  url_pt: null,
  image_name: null,
  image_name_pt: null,
};

const validateForm = ({ title, title_pt, url, url_pt, image_name, image_name_pt }) => {
  return (
    title !== null && title !== "" &&
    title_pt !== null && title_pt !== "" &&
    url !== null && url !== "" &&
    url_pt !== null && url_pt !== "" &&
    image_name !== null && image_name !== "" &&
    image_name_pt !== null && image_name_pt !== ""
  );
};

const ReportModal = (props) => {
  const [file, setFile] = useState(null);
  const [filePt, setFilePt] = useState(null);
  const [text, setText] = useState(null);
  const [editImagePreview, setEditImagePreview] = useState(null);
  const [editImagePtPreview, setEditImagePtPreview] = useState(null);
  const [onLoading, setOnLoading] = useState(false);
  const [formState, setFormState] = useState(emptyForm);
  const [oldUrl, setOldUrl] = useState(null);
  const [formIsValid, setFormIsValid] = useState(validateForm(formState));

  const resetFormState = () => {
    setFormState(emptyForm);
  };

  const onSave = async () => {
    setOnLoading(true);

    let bodyFormData = new FormData();
    bodyFormData.append("url_name", formState.url);
    bodyFormData.append("url_name", formState.url_pt);
    bodyFormData.append("file", file);
    bodyFormData.append("file", filePt);

    try {
      const response = await axios.post(`${API_URL}/reports/image_upload`, bodyFormData);

      if (!props.editMode) {
        await axios.post(
            `${API_URL}/reports_add_data`,
            {
              title: formState.title,
              title_pt: formState.title_pt,
              url: formState.url,
              url_pt: formState.url_pt,
              image_name: response.data.files[0].file,
              image_name_pt: response.data.files[1].file
            }
        );
      } else {
        await axios.post(
            `${API_URL}/reports_update`,
            {
              old_url: oldUrl,
              title: formState.title,
              title_pt: formState.title_pt,
              url: formState.url,
              url_pt: formState.url_pt,
              image_name: response.data.files[0].file,
              image_name_pt: response.data.files[1].file
            }
        );
      }
    } catch (e) {
      console.error(e);
    } finally {
      props.closeModal();
      setOnLoading(false);
    }
  };

  const handleFormChange = (field) => (value) => {
    setFormState({
      ...formState,
      [field]: value,
    });
  };

  useEffect(() => {
    if (file) {
      handleFormChange("image_name")(file.name);
    }

    if (filePt) {
      handleFormChange("image_name_pt")(filePt.name);
    }
  }, [file, filePt]);

  useEffect(() => {
    setFormIsValid(validateForm(formState));
  }, [formState]);

  useEffect(() => {
    if (props.location.pathname.substring(0, 4) === "/pt/") {
      setText(textPortuguese);
    } else {
      setText(textEnglish);
    }

    if (props.editMode && props.selectedReport) {
      setEditImagePreview(props.selectedReport.image_name);
      setEditImagePtPreview(props.selectedReport.image_name_pt);
      setFormState({ ...props.selectedReport });
      setOldUrl(props.selectedReport.url);
    } else {
      resetFormState();
    }
  }, []);

  return (
    <>
      {props.showModal && (
        <Modal modalVisibility={() => props.showModal}>
          <TitleSection
            onClose={props.closeModal}
            title={
              props.editMode ? text?.editTitle ?? "" : text?.addTitle ?? ""
            }
          />
          <div className="modal-form">
            <div className="modal-control">
              <label>
                {text?.url ?? ""}
                <span>*</span>
              </label>
              <Input
                type="text"
                placeholder="Digite aqui a URL"
                value={formState.url ?? ""}
                onChange={(e) => handleFormChange("url")(e.target.value)}
              />
            </div>
            <div className="modal-control">
              <label>
                {text?.url_pt ?? ""}
                <span>*</span>
              </label>
              <Input
                type="text"
                placeholder="Digite aqui a URL"
                value={formState.url_pt ?? ""}
                onChange={(e) => handleFormChange("url_pt")(e.target.value)}
              />
            </div>
            <div className="modal-control">
              <label>
                {text?.title ?? ""}
                <span>*</span>
              </label>
              <Input
                type="text"
                value={formState.title ?? ""}
                placeholder="Digite aqui o título do relatório"
                onChange={(e) => handleFormChange("title")(e.target.value)}
              />
            </div>
            <div className="modal-control">
              <label>
                {text?.title_pt ?? ""}
                <span>*</span>
              </label>
              <Input
                type="text"
                value={formState.title_pt ?? ""}
                placeholder="Digite aqui o título do relatório"
                onChange={(e) => handleFormChange("title_pt")(e.target.value)}
              />
            </div>
            <div className="modal-row">
              <div className="modal-control">
                <label>
                  {text?.imageFile ?? ""}
                  <span>*</span>
                </label>
                {props.editMode ? (
                  <ImageInput
                    width="100%"
                    editMode={props.editMode}
                    location={props.location}
                    editImage={editImagePreview}
                    setEditImage={setEditImagePreview}
                    name={formState.image_name}
                    uploadFile={(file) => setFile(file)}
                  />
                ) : (
                  <ImageInput
                    width="100%"
                    editMode={props.editMode}
                    location={props.location}
                    name={formState.image_name}
                    uploadFile={(file) => setFile(file)}
                  />
                )}
              </div>
              <div className="modal-control">
                <label>
                  {text?.imageFilePt ?? ""}
                  <span>*</span>
                </label>
                {props.editMode ? (
                  <ImageInput
                    width="100%"
                    editMode={props.editMode}
                    location={props.location}
                    editImage={editImagePtPreview}
                    setEditImage={setEditImagePtPreview}
                    name={formState.image_name_pt}
                    uploadFile={(filePt) => setFilePt(filePt)}
                  />
                ) : (
                  <ImageInput
                    width="100%"
                    editMode={props.editMode}
                    location={props.location}
                    name={formState.image_name_pt}
                    uploadFile={(filePt) => setFilePt(filePt)}
                  />
                )}
              </div>  
            </div>
          </div>
          <div className="ButtonsWrapper">
            <AltButton inverse={true} onClick={props.closeModal}>
              {text?.cancel}
            </AltButton>
            <Button
              onClick={onSave}
              disabled={formIsValid === false || onLoading}
            >
              {!props.editMode ? text?.save ?? "" : text?.edit ?? ""}
            </Button>
          </div>
        </Modal>
      )}
    </>
  );
};

export default ReportModal;
