import React from 'react'
import './styles.css'
import { Link } from 'react-router-dom';

const StrategyHeader = ({ title = null, tabs, selectedTab, backgroundImage = null, backgroundPosition = null }) => {
  const checkSelectedTab = (tab) => {
    return selectedTab === tab ? 'selected' : ''
  }

  return (
    <div
        className={`story-header ${title === null ? 'no-background' : ''}`}
        style={{
          backgroundImage: backgroundImage ? `url(${backgroundImage})` : '',
          backgroundPosition: backgroundPosition ?? 'center'
        }}
    >
      {title && <h1>{title}</h1>}
      {tabs && (
        <div className="story-header-menu">
          {tabs.map((tab, index) => (
            <span key={index} className={checkSelectedTab(index)}><Link to={tab.link}>{tab.label}</Link></span>
          ))}
        </div>
      )}
    </div>
  )
}

export default StrategyHeader
