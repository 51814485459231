import React, { Component } from 'react';
import { Bar, HorizontalBar } from 'react-chartjs-2';
import numeral from 'numeral';
import _ from 'lodash';
import { COLORS, BORDER_COLORS } from './graphColors';
import LazyLoad from 'react-lazy-load';
import Loading from '../Loading';
import './GraphFonts.css';
import './GraphLegend.css';

const textEnglish = {
	'brazil': 'Brazil',
	'g20': 'G20 Average',
	'global': 'Global Average'
}

const textPortuguese = {
	'brazil': 'Brasil',
	'g20': 'Média do G20',
	'global': 'Média Global'
}

export default class GroupedBarChart extends Component {
	constructor(props) {
		super(props);

		this.state={
			graphLoaded: false,
			br: false,
			country1: false,
			country2: false,
			g20: false,
			global: false
		}

		this.updateLoading = this.updateLoading.bind(this);
		this.updateDataset = this.updateDataset.bind(this);
	}

	shouldComponentUpdate(nextProps, nextState) {
		if (_.isEqual(this.props, nextProps) && _.isEqual(this.state, nextState)) {
			return false;
		}
		return true;
	}

	componentDidMount() {
		this.forceUpdate();
	}

	updateDataset(e, index, label) {
		let current = this.state[label];
		this.setState({
			[label] : !current
		});
    };

	updateLoading() {
		this.setState({
			graphLoaded: true
		});
	}
	
	render() {
		let pt = this.props.pt;
		let text = null;
		if (pt) {
			text = textPortuguese;
		} else {
			text = textEnglish;
		}
		
		let height = this.props.height ? this.props.height : 300;
		let width = this.props.width ? this.props.width : '100%';
		let options = this.props.options ? this.props.options : {};
		let numValues = this.props.values ? this.props.values : 1;
		let formatter = this.props.formatter;
		let horizontal = this.props.horizontal ? this.props.horizontal : false;
		let beginAtZero = this.props.beginAtZero != undefined ? this.props.beginAtZero : true;
		let label = this.props.label ? this.props.label : '';
		let displayLabel = this.props.label ? true : false;
		let G20_AVGS = this.props.wdi_data['g20'];
		let GLOBAL_AVGS = this.props.wdi_data['global'];
		options['maintainAspectRatio'] = false;
		options['scales'] = {
            yAxes: [{
                ticks: {
                    beginAtZero:beginAtZero,
                    callback: function(value, index, values) {
                    	let v = value;
                    	if (formatter) {
                    		v = numeral(value).format(formatter);
                    		if (!isNaN(Number(v))) {
                    			v = Number(v).toFixed(1).replace(".0", '');
                    		} else {
                    			if (v.includes(',')) {
                    				v = v.split('.')[0];
                    			}
                    		}
                    	}
                        return '     ' + v;
                    }
                },
                scaleLabel: { display: displayLabel, labelString: label }
            }]
        };
        options['tooltips'] = {
        	bodyFontSize: 14,
        	backgroundColor: '#393d41',
        	opacity: 1,
        	callbacks: {
        		title: function() {
        			return null;
        		},
                label: function(tooltipItem, data) {
	                let label = data.datasets[tooltipItem.datasetIndex].label;
	                let value = tooltipItem.yLabel;
	                if (formatter) {
	                	value = numeral(value).format(formatter);
	                }
	                return label + ": " + value;
                }
            }
        };
        // options['onHover'] = () => {
        
        // };
		
		let country0 = this.props.wdi_data[this.props.country_order[0].iso3];
		let groupLabels = [];
		for (let i=country0.indicators[this.props.field].data.length-numValues; i<country0.indicators[this.props.field].data.length; i++) {
			groupLabels.push(country0.indicators[this.props.field].data[i].year);
		}
		// for (let year in this.props.countries[0].indicators[this.props.field].values) {
		// 	groupLabels.push(year);
		// }

		let datasets = [];
		let compareData = []
		let count = 0;
		for (let country in this.props.country_order) {
			let dataValues = [];
			let c = this.props.wdi_data[this.props.country_order[country].iso3];
			
			
			if (c.indicators[this.props.field] && numValues > c.indicators[this.props.field].data.length) {
				numValues = c.indicators[this.props.field].data.length;
			} 
			for (let i=c.indicators[this.props.field].data.length-numValues; i<c.indicators[this.props.field].data.length; i++) {
				dataValues.push(c.indicators[this.props.field].data[i].value);
			}
			if ((count === 0 && !this.state.br) || (count === 1 && !this.state.country1) || (count === 2 && !this.state.country2)) {
				datasets.push({
		            label: (pt ? this.props.country_translation[c.country_name] : c.country_name),
		            backgroundColor: COLORS[country],
		            hoverBackgroundColor: BORDER_COLORS[country],
		            data: dataValues
		        });
			}
			compareData.push({
	            label: (pt ? this.props.country_translation[c.country_name] : c.country_name),
	            backgroundColor: COLORS[country],
	            hoverBackgroundColor: BORDER_COLORS[country],
	            data: dataValues
	        });
			count += 1;
		}


		// let g20Data = [];
		// for (let year in G20_AVGS.g20.indicators[this.props.field].values) {
		// 	g20Data.push(G20_AVGS.g20.indicators[this.props.field].values[year]);
		// }
		let g20Data = [];
		numValues = G20_AVGS.indicators[this.props.field].data.length;
		for (let i=G20_AVGS.indicators[this.props.field].data.length-numValues; i<G20_AVGS.indicators[this.props.field].data.length; i++) {
			g20Data.push(G20_AVGS.indicators[this.props.field].data[i].value);
		}

		if (!this.state.g20) {
			datasets.push(
				{
					label: text.g20,
		            backgroundColor: COLORS[this.props.country_order.length],
		            hoverBackgroundColor: BORDER_COLORS[this.props.country_order.length],
		            data: g20Data
		        }
			);
		}
		compareData.push(
			{
				label: text.g20,
	            backgroundColor: COLORS[this.props.country_order.length],
	            hoverBackgroundColor: BORDER_COLORS[this.props.country_order.length],
	            data: g20Data
	        }
		);

		let globalData = [];
		numValues = GLOBAL_AVGS.indicators[this.props.field].data.length;
		for (let i=GLOBAL_AVGS.indicators[this.props.field].data.length-numValues; i<GLOBAL_AVGS.indicators[this.props.field].data.length; i++) {
			globalData.push(GLOBAL_AVGS.indicators[this.props.field].data[i].value);
		}

		if (!this.state.global) {
			datasets.push(
				{
					label: text.global,
					backgroundColor: COLORS[this.props.country_order.length + 1],
		            hoverBackgroundColor: BORDER_COLORS[this.props.country_order.length + 1],
		            data: globalData
		        }
			);
		}
		compareData.push(
			{
				label: text.global,
				backgroundColor: COLORS[this.props.country_order.length + 1],
	            hoverBackgroundColor: BORDER_COLORS[this.props.country_order.length + 1],
	            data: globalData
	        }
		);

		options['animation'] = {
        	'duration': 250,
        	'onComplete': function() {
	        	let chartElement = this.chart;
	        	let ctx = chartElement.ctx;
	        	this.data.datasets.forEach(function (dataset, i) {
	                let meta = chartElement.controller.getDatasetMeta(i);
	                meta.data.forEach(function (bar, index) {
	                    let data = dataset.data[index]; 
	                    if (!data) {
	                    	if (numValues >=5) {
	                    		ctx.font = "10px Lato";
	                    		ctx.fillText("No", bar._model.x - 6, height/2 + 15);
	                    		ctx.fillText("Data", bar._model.x - 10, height/2 + 27);
                    		} else {
                    			ctx.font = "16px Lato";
                    			ctx.fillText("No", bar._model.x - 8, height/2 + 10);
	                    		ctx.fillText("Data", bar._model.x - 14, height/2 + 28);
                    		}
	                    }                        
	                });
	            });
	        },
	    };
	    options['legend'] = {
        	display: false
        }

		let data = {
		    labels: groupLabels,
		    datasets: datasets
		};

		let loadingBar = <Loading />;
		if (this.state.graphLoaded) {
			loadingBar = "";
		}

		let legend = (
			<div className="groupedbar-custom-legend">
				<div className={this.state.br ? "groupedbar-legend-div transparent" : "groupedbar-legend-div"} onClick={() => this.updateDataset(this, 0, 'br')}>
				<div title={text.brazil} style={{backgroundColor:COLORS[0]}} className="groupedbar-legend-option"></div>
				<div className="groupedbar-legend-text">{text.brazil}</div>
				</div>

				<div className={this.state.g20 ? "groupedbar-legend-div transparent" : "groupedbar-legend-div"} onClick={() => this.updateDataset(this, 3, 'g20')}>
				<div title={text.g20} style={{backgroundColor:COLORS[1]}} className="groupedbar-legend-option"></div>
				<div className="groupedbar-legend-text">{text.g20}</div>
				</div>

				<div className={this.state.global ? "groupedbar-legend-div transparent" : "groupedbar-legend-div"} onClick={() => this.updateDataset(this, 4, 'global')}>
				<div title={text.global} style={{backgroundColor:COLORS[2]}} className="groupedbar-legend-option"></div>
		    	<div className="groupedbar-legend-text">{text.global}</div>
		    	</div>
		    </div>
		);

		if (this.props.country_order.length === 2) {
			legend = (
				<div className="groupedbar-custom-legend">
					<div className={this.state.br ? "groupedbar-legend-div transparent" : "groupedbar-legend-div"} onClick={() => this.updateDataset(this, 0, 'br')}>
					<div title={text.brazil} style={{backgroundColor:COLORS[0]}} className="groupedbar-legend-option"></div>
					<div className="groupedbar-legend-text">{text.brazil}</div>
					</div>

					<div className={this.state.country1 ?"groupedbar-legend-div transparent" : "groupedbar-legend-div"} onClick={() => this.updateDataset(this, 1, 'country1')}>
					<div title={pt ? this.props.country_translation[this.props.wdi_data[this.props.country_order[1].iso3].country_name] : this.props.wdi_data[this.props.country_order[1].iso3].country_name} style={{backgroundColor:COLORS[1]}} className= "groupedbar-legend-option"></div>
					<div className="groupedbar-legend-text">{pt ? this.props.country_translation[this.props.wdi_data[this.props.country_order[1].iso3].country_name] : this.props.wdi_data[this.props.country_order[1].iso3].country_name}</div>
					</div>

					<div className={this.state.g20 ? "groupedbar-legend-div transparent" : "groupedbar-legend-div"} onClick={() => this.updateDataset(this, 3, 'g20')}>
					<div title={text.g20} style={{backgroundColor:COLORS[2]}} className="groupedbar-legend-option"></div>
					<div className="groupedbar-legend-text">{text.g20}</div>
					</div>

					<div className={this.state.global ? "groupedbar-legend-div transparent" : "groupedbar-legend-div"} onClick={() => this.updateDataset(this, 4, 'global')}>
					<div title={text.global} style={{backgroundColor:COLORS[3]}} className="groupedbar-legend-option"></div>
			    	<div className="groupedbar-legend-text">{text.global}</div>
			    	</div>
			    </div>
			);
		}

		if (this.props.country_order.length === 3) {
			legend = (
				<div className="groupedbar-custom-legend">
					<div className={this.state.br ? "groupedbar-legend-div transparent" : "groupedbar-legend-div"} onClick={() => this.updateDataset(this, 0, 'br')}>
					<div title={text.brazil} style={{backgroundColor:COLORS[0]}} className="groupedbar-legend-option"></div>
					<div className="groupedbar-legend-text">{text.brazil}</div>
					</div>

					<div className={this.state.country1 ? "groupedbar-legend-div transparent" : "groupedbar-legend-div"} onClick={() => this.updateDataset(this, 1, 'country1')}>
					<div title={pt ? this.props.country_translation[this.props.wdi_data[this.props.country_order[1].iso3].country_name] : this.props.wdi_data[this.props.country_order[1].iso3].country_name} style={{backgroundColor:COLORS[1]}} className="groupedbar-legend-option"></div>
					<div className="groupedbar-legend-text">{pt ? this.props.country_translation[this.props.wdi_data[this.props.country_order[1].iso3].country_name] : this.props.wdi_data[this.props.country_order[1].iso3].country_name}</div>
					</div>

					<div className={this.state.country2 ? "groupedbar-legend-div transparent" : "groupedbar-legend-div"} onClick={() => this.updateDataset(this, 2, 'country2')}>
					<div title={pt ? this.props.country_translation[this.props.wdi_data[this.props.country_order[2].iso3].country_name] : this.props.wdi_data[this.props.country_order[2].iso3].country_name} style={{backgroundColor:COLORS[2]}} className="groupedbar-legend-option"></div>
					<div className="groupedbar-legend-text">{pt ? this.props.country_translation[this.props.wdi_data[this.props.country_order[2].iso3].country_name] : this.props.wdi_data[this.props.country_order[2].iso3].country_name}</div>
					</div>

					<div className={this.state.g20 ? "groupedbar-legend-div transparent" : "groupedbar-legend-div"} onClick={() => this.updateDataset(this, 3, 'g20')}>
					<div title={text.g20} style={{backgroundColor:COLORS[3]}} className="groupedbar-legend-option"></div>
					<div className="groupedbar-legend-text">{text.g20}</div>
					</div>

					<div className={this.state.global ? "groupedbar-legend-div transparent" : "groupedbar-legend-div"} onClick={() => this.updateDataset(this, 4, 'global')}>
					<div title={text.global} style={{backgroundColor:COLORS[4]}} className="groupedbar-legend-option"></div>
			    	<div className="groupedbar-legend-text">{text.global}</div>
			    	</div>
			    </div>
			);
		}

		let bar = (<Bar
					    data={data}
					    height={height}
					    options={options}
					/>);

		if (numValues === 1) { //remove this check if we want historical to also be horizontal
			for (let i = 0; i < 5; i++) {
				let compare = [];
				for (let j = 0; j < compareData.length; j++) {
					if (compareData[j].data[i] != undefined && compareData[j].data[i] != null && !(isNaN(Number(compareData[j].data[i]))) ) {
						compare.push(Number(compareData[j].data[i]));
					}
				}
				if (compare.length > 0) {
					let max = Math.max(...compare);
					let min = Math.min(...compare);
					if (max > (min * 3)) {
						horizontal = true;
					}
				}
			}
		}

		if (horizontal) {
			options['animation'] = {
	        	'duration': 250,
	        	'onComplete': function() {
		        	let chartElement = this.chart;
		        	let ctx = chartElement.ctx;
		        	this.data.datasets.forEach(function (dataset, i) {
		                let meta = chartElement.controller.getDatasetMeta(i);
		                meta.data.forEach(function (bar, index) {
		                    let data = dataset.data[index]; 
		                    if (!data) {
		                    	if (numValues >=5) {
		                    		ctx.font = "10px Lato";
		                    		ctx.fillText("No Data", 50, bar._model.y + 3);
	                    		} else {
	                    			ctx.font = "16px Lato";
	                    			ctx.fillText("No Data", 50, bar._model.y + 5);
	                    		}
		                    }                        
		                });
		            });
		        }
	        }
			options['scales'] = {
	            xAxes: [{
	                ticks: {
	                    beginAtZero:beginAtZero,
	                    callback: function(value, index, values) {
	                    	let v = value;
	                    	if (formatter) {
	                    		v = numeral(value).format(formatter);
	                    		if (!isNaN(Number(v))) {
	                    			v = Number(v).toFixed(1).replace(".0", '');
	                    		} else {
	                    			if (v.includes(',')) {
	                    				v = v.split('.')[0];
	                    			}
	                    		}
	                    	}
	                        return '     ' + v;
	                    }
	                },
                	scaleLabel: { display: displayLabel, labelString: label }
	            }]
	        };
	        options['tooltips'] = {
	        	bodyFontSize: 14,
	        	backgroundColor: '#393d41',
	        	opacity: 1,
	        	callbacks: {
	        		title: function() {
	        			return null;
	        		},
	                label: function(tooltipItem, data) {
		                let label = data.datasets[tooltipItem.datasetIndex].label;
		                let value = tooltipItem.xLabel;
		                if (formatter) {
		                	value = numeral(value).format(formatter);
		                }
		                return label + ": " + value;
	                }
	            }
	        };
			bar = (<HorizontalBar
					    data={data}
					    height={height}
					    options={options}
					/>);
		}

		return (
			<div className="custom-font" style={{'height': height, 'width': width}}>
				{legend}
				<LazyLoad offsetVertical={500} throttle={400} debounce={false} onContentVisible={this.updateLoading}>
					{bar}
				</LazyLoad>
				{loadingBar}
			</div>
		);
	}
};
