import React, { useState, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';

export function WelcomeSearchContent({ searchText, projects, pt }) {
  const [currentPage, setCurrentPage] = useState(1);
  const [maxPages, setMaxPages] = useState(1);
  const [currentProjects, setCurrentProjects] = useState([]);


  const projectsFiltered = useMemo(() => {
    return projects.filter((project) => {
      return (
        project.title?.toLowerCase().includes(searchText.toLowerCase()) ||
        project.description?.toLowerCase().includes(searchText.toLowerCase())
      );
    });
  }, [projects, searchText]);

  useEffect(() => {
    if (projectsFiltered) {
      setMaxPages(Math.ceil(projectsFiltered.length / 10));
    }
  }, [projectsFiltered]);

  useEffect(() => {
    if (projectsFiltered.length) {
        const startIndex = (currentPage - 1) * 10;
        const endIndex = startIndex + 10
        const result = projectsFiltered.slice(startIndex, endIndex).map((item) => item);
        setCurrentProjects(result);
    } else {
        setCurrentProjects([]);
    }
}, [maxPages, currentPage, projectsFiltered]);

  const handleClick = (page) => {
    setCurrentPage(page);
  };

  const renderPagination = () => {
      const pages = [];
      for (let i = 1; i <= maxPages; i++) {
          pages.push(
              <button
                  key={i}
                  className="pagination-button"
                  onClick={() => handleClick(i)}
                  disabled={currentPage === i}
              >
                  {i}
              </button>
          );
      }

      const visiblePages = pages.slice(currentPage - 1, currentPage + 4);

      return (
          <div style={{ marginBottom: '20px' }} className="pagination-wrapper">
              <button
                  disabled={currentPage < 2}
                  className="pagination-button previous"
                  onClick={() => handleClick(currentPage-1)}
              >Anterior</button>
              {visiblePages}
              <button
                  className="pagination-button next"
                  disabled={currentPage === maxPages}
                  onClick={() => handleClick(currentPage+1)}
              >Próximo</button>
          </div>
      );
  };


  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'start',
      padding: '20px 115px',
    }}>
      <h3 style={{ fontSize: '36px' }}>"{searchText}"</h3>
      <div
        style={{
          width: '82px',
          height: '1.4px',
          backgroundColor: '#CDE7F9',
        }}
      ></div>
      <div style={{
        margin: '52px',
      }}>
        {currentProjects.length ? currentProjects.map((project, index) => (
          <Link to={pt ? `/pt/projectdetails/${project.id}` : `/projectdetails/${project.id}`}>
            <div style={{
              fontSize: '28px',
              color: '#002244',
              textAlign: 'start',
              marginTop: '32px',
            }}>
              {project.title}
            </div>
            <div
              style={{
                fontSize: '18px',
                color: '#666666',
                textAlign: 'start',
                padding: '16px 0',
                opacity: '0.6',
                borderBottom: currentProjects.length - 1 !== index ? '1px solid #B4B4B4' : '',
              }}
            >
              {project.description}
            </div>
          </Link>
        )) : (
            <h3 style={{ fontSize: '36px' }}>Nenhum resultado encontrado.</h3>
        )}
      </div>
      {currentProjects.length ? renderPagination() : (<></>)}
    </div>
  );
}
