import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom'; 
import {  DropdownButton, MenuItem, ListGroup } from 'react-bootstrap';
import axios from 'axios';

import '../program/Program.css'
import '../strategy/Strategy.css'
import WelcomeFooter from '../menu/WelcomeFooter';
import Navbar from '../common/navbar/NavbarPLR';
import Linkify from 'react-linkify';
import ProgramMap from '../common/map/ProgramMap';
import StrategyHeader from '../common/strategyheader';
import ActivitiesCover from '../projects/img/activities-cover.png'
import {API_URL} from "../../../config/api";

const textEnglish = {
	"big": "Portfolio Footprint",
	"subtitle": "This page maps active projects in the World Bank Group’s (WBG’s) portfolio, displaying geo-referenced committed loans data. For an increasing number of projects, these data are collected in real time from government open data systems. Portfolio information can be overlaid with key state/municipal, socio-economic indicators that can visually provide additional information on the motivation for specific projects. More information on specific projects can be obtained on the ",
	"projects_page": "projects page",
	"subtitle2": " while information on impact can be found on the ",
	"results_pages": "results pages",
	"subtitle3": " To explore more World Bank maps and data, ",
	"click_here": "click here",
	"subtitle4": "Using the menu on the left side of the map, select a Focus Area to see specific commitment data and Dataset Indicators to explore indicators.",
	"state_data": "State Data",
	"state_click": "Click on State in Map or Select from Dropdown",
	"state_projects_in": "Projects in",
	"climate_disasters": "Climate Disasters",
	"damaged_infrastructure": "Damaged Infrastructure",
	"damages_losses": "Damages and Losses",
	"hydrological_disasters": "Hydrological Disasters",
	"meteorological_disasters": "Meteorological Disasters",
	"private_losses": "Private Losses",
	"private_losses_agriculture": "Private Losses Agriculture",
	"private_losses_industry": "Private Losses Industry",
	"private_losses_livestock": "Private Losses Livestock",
	"private_losses_services": "Private Losses Services",
	"public_losses": "Public Losses",
	"total_losses": "Total Losses",
	"total_material_damage": "Total Material Damage",
	"value_damaged_housing": "Value of Damaged Housing",
	"number_poor": "Number of Poor",
	"number_projects": "Number of Projects",
	"poverty_rate": "Poverty Rate",
	"human_capital_index": "Human Capital Index",
	"focus_area_1_funding": "Focus Area 1 Funding",	
	"focus_area_2_funding": "Focus Area 2 Funding",
	"focus_area_3_funding": "Focus Area 3 Funding",
	"poverty_country_comparison": "Poverty Country Comparison",
	"severe_drought_likelihood_RCP26": "Severe Drought Likelihood RCP26",
	"severe_drought_likelihood_RCP85": "Severe Drought Likelihood RCP85",
	"average_hi_RCP26": "Average Hi RCP26",
	"average_hi_RCP85": "Average Hi RCP85",
	"rain_5_Day_RCP26": "Rain 5 Day RCP26",
	"rain_5_Day_RCP85": "Rain 5 Day RCP85",
	"damaged_houses": "Damaged Houses",
	"destroyed_houses": "Destroyed Houses",
	"indicators": "Indicator Datasets"
}

const textPortuguese = {
	"big": "Alcance do Portfólio",
	"subtitle": "Esta página mapeia os projetos ativos no portfólio do Grupo Banco Mundial (GBM), exibindo dados de desembolso atrelados a referências geográficas. Em um número crescente de projetos, esses dados são coletados em tempo real a partir de sistemas de dados abertos do governo. \
As informações do portfólio podem ser sobrepostas com os principais indicadores socioeconômicos subnacionais, que agregam informações visuais adicionais sobre o que motivou projetos específicos. Mais informações sobre projetos específicos estão disponíveis na ",
	"projects_page": "página de projetos",
	"subtitle2": " e as informações sobre os impactos estão disponíveis nas  ",
	"results_pages": "páginas de resultados",
	"subtitle3": " Para explorar mais mapas e dados do Banco Mundial, ",
	"click_here": "clique aqui (página em inglês)",
	"subtitle4": "Usando o menu do lado esquerdo do mapa, selecione uma Área de Foco para ver dados sobre compromissos específicos e os Conjuntos de Dados Indicadores para explorar os indicadores.",
	"state_data": "Dados Estaduais",
	"state_click": "Clique em Estado no mapa ou selecione no menu suspenso",
	"state_projects_in": "Projetos no",
	"climate_disasters": "Desastres climáticos",
	"damaged_infrastructure": "Infraestruturas danificadas",
	"damages_losses": "Danos e prejuízos",
	"hydrological_disasters": "Desastres hidrológicos",
	"meteorological_disasters": "Desastres meteorológicos",
	"private_losses": "Prejuízos privados",
	"private_losses_agriculture": "Prejuízos privados na agricultura",
	"private_losses_industry": "Prejuízos privados na indústria",
	"private_losses_livestock": "Prejuízos privados na pecuária",
	"private_losses_services": "Prejuízos privados nos serviços",
	"public_losses": "Prejuízos públicos",
	"total_losses": "Prejuízos totais",
	"total_material_damage": "Dano material total",
	"value_damaged_housing": "Valor das casas danificadas",
	"number_poor": "Número de pobres",
	"number_projects": "Número de Projetos",
	"poverty_rate": "Taxa de pobreza",
	"human_capital_index": "Índice de Capital Humano",
	"focus_area_1_funding": "Área de Foco 1 Financiamento",
	"focus_area_2_funding": "Área de Foco 2 Financiamento",
	"focus_area_3_funding": "Área de Foco 3 Financiamento",
	"poverty_country_comparison": "Comparação da pobreza por país",
	"severe_drought_likelihood_RCP26": "Probabilidade de Seca Severa RCP26",
	"severe_drought_likelihood_RCP85": "Probabilidade de Seca Severa RCP85",
	"average_hi_RCP26": "IC Média RCP26",
	"average_hi_RCP85": "IC Média RCP85",
	"rain_5_Day_RCP26": "Chuva 5 Dias RCP26",
	"rain_5_Day_RCP85": "Chuva 5 Dias RCP85",
	"damaged_houses": "Casas danificadas",
	"destroyed_houses": "Casas destruídas",
	"indicators": "Conjuntos de dados do indicador"
}

const br_states = {
	"Federal": "Federal",
    "Acre": "Acre",
    "Alagoas": "Alagoas",
    "Amapa": "Amapá",
    "Amazonas": "Amazonas",
    "Bahia": "Bahia",
    "Ceara": "Ceará",
    "Distrito Federal": "Distrito Federal",
    "Espirito Santo": "Espírito Santo",
    "Goias": "Goiás",
    "Maranhao": "Maranhão",
    "Mato Grosso": "Mato Grosso",
    "Mato Grosso do Sul": "Mato Grosso do Sul",
    "Minas Gerais": "Minas Gerais",
    "Para": "Pará",
    "Paraiba": "Paraíba",
    "Parana": "Paraná",
    "Pernambuco": "Pernambuco",
    "Piaui": "Piauí",
    "Rio de Janeiro": "Rio de Janeiro",
    "Rio Grande do Norte": "Rio Grande do Norte",
    "Rio Grande do Sul": "",
    "Rondonia": "Rondônia",
    "Roraima": "Roraima",
    "Santa Catarina": "Santa Catarina",
    "Sao Paulo": "São Paulo",
    "Sergipe": "Sergipe",
    "Tocantins": "Tocantins"
};

const br_states_list = [
	"Federal",
    "Acre",
    "Alagoas",
    "Amapá",
    "Amazonas",
    "Bahia",
    "Ceará",
    "Distrito Federal",
    "Espírito Santo",
    "Goiás",
    "Maranhão",
    "Mato Grosso",
    "Mato Grosso do Sul",
    "Minas Gerais",
    "Pará",
    "Paraíba",
    "Paraná",
    "Pernambuco",
    "Piauí",
    "Rio de Janeiro",
    "Rio Grande do Norte",
    "Rio Grande do Sul",
    "Rondônia",
    "Roraima",
    "Santa Catarina",
    "São Paulo",
    "Sergipe",
    "Tocantins"
];

class Program extends Component {
	constructor(props) {
		super(props);

		this.state = {
			stateData: null,
			projectData: null,
			selectedState: 'Acre',
			passedState: null,
			refresh: true,
		}

        this.handleLogout = this.handleLogout.bind(this);
        this.setStateData = this.setStateData.bind(this);
        this.setSelectedState = this.setSelectedState.bind(this);
		this.resetProgram = this.resetProgram.bind(this);
    }

	handleLogout() {
		localStorage.removeItem('auth');
		this.props.history.push('/');
	}

	componentDidMount(){
		console.log("Fired ===> Program  componentDidMount from Program")
	    axios.get(`${API_URL}/projects`).then(result => {
			this.setState({projectData: result.data});
			console.log(result.data);
		}).catch(() => {})
	}

	resetProgram() {
		this.setState({refresh:!this.state.refresh});
	}

	setStateData(stateData) {
		this.setState({stateData});
	}

	setSelectedState(selectedState) {
		this.setState({selectedState});
	}

	selectState(e) {
		this.setState({ selectedState: e })
	}

	abbreviate(number, maxPlaces, forcePlaces, forceLetter) {
	  number = Number(number);
	  forceLetter = forceLetter || false;
	  var abbr;
	  if(number >= 1e12) {
	    abbr = 'T';
	  }
	  else if(number >= 1e9) {
	    abbr = 'B';
	  }
	  else if(number >= 1e6) {
	    abbr = 'M';
	  }
	  else if(number >= 1e3) {
	    abbr = 'K';
	  }
	  else {
	    abbr = '';
	  }
	  return this.annotate(number, maxPlaces, forcePlaces, abbr);
	}

	annotate(number, maxPlaces, forcePlaces, abbr) {
	  // set places to false to not round
	  var rounded = 0;
	  switch(abbr) {
	    case 'T':
	      rounded = number / 1e12;
	      break;
	    case 'B':
	      rounded = number / 1e9;
	      break;
	    case 'M':
	      rounded = number / 1e6;
	      break;
	    case 'K':
	      rounded = number / 1e3;
	      break;
	    case '':
	      rounded = number;
	      break;
	  }
	  if(maxPlaces !== false) {
	    var test = new RegExp('\\.\\d{' + (maxPlaces + 1) + ',}$');
	    if(test.test(('' + rounded))) {
	      rounded = rounded.toFixed(maxPlaces);
	    }
	  }
	  if(forcePlaces !== false) {
	    rounded = Number(rounded).toFixed(forcePlaces);
	  }
	  return rounded + abbr;
	}

	render() {
		if (!this.state.refresh) {
			this.resetProgram();
		}

		let states = [];
	    if (this.state.stateData) {
	    	let features = this.state.stateData;
				 
			for (var i = 0; i < features.length; i++) {
				var props = features[i].properties;
				states.push(props.ESTADO);
			}
	    }

	    states.sort((a, b) => (b < a) ? 1 : -1);

		const pt = this.props.location.pathname.substring(0,4) == "/pt/"

	    let text = {};
        if (this.props.location) { //while we still have pages to translate don't want to break them
            if (this.props.location.pathname.substring(0,4) !== "/pt/") {
                text = textEnglish;
            } else {
                text = textPortuguese;
            }
        } else {
            text = textEnglish;
        }

		return (
			<div>
				<div className="container-fluid unpadify">
				<Navbar handleLogin={this.handleLogin} handleLogout={this.handleLogout} auth={this.props.auth} isLoggedIn={this.props.loggedIn} checkIsLoggedIn={this.props.checkIsLoggedIn} onChangeLoginState={this.props.onChangeLoginState} {...this.props} currentPage="program" />
					<StrategyHeader
						title={pt ? "Atividades" : "Activities"}
						selectedTab={0}
						tabs={[
							{ link: pt ? '/pt/portfolio' : '/portfolio', label: pt ? 'Alcance do portfólio' : 'Portfolio Footprint' },
							{ link: pt ? '/pt/projects' : '/projects', label: pt ? 'Projetos' : 'Projects' },
							{ link: pt ? '/pt/reports' : '/reports', label: pt ? 'Relatórios' : 'Reports' },
						]}
						backgroundImage={ActivitiesCover}
						backgroundPosition="0px 68%"
					/>
					<div id="strategy-container">
						<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
							<h2 style={{ color: '#1A1A1A' }}>{pt ? "Alcance do portfólio" : "Portfolio Footprint"}</h2>
							{this.props.checkIsLoggedIn() && (
						<>
							<div style={{postion: 'relative', marginBottom: '32px'}}>
							</div>
						</>
					)}
						</div>
						<div className="divider"></div>
						<div id="program-summary" style={{ marginBottom: '80px', color: '#4D4D4D' }}>
							<Linkify>
								<p style={{ color: '#4D4D4D' }}>
									{text.subtitle}
									<a href={this.props.location.pathname.substring(0,4) !== "/pt/" ? '/projects' : '/pt/projects'}>
										{text.projects_page}
									</a>
									{text.subtitle2}
									<a href={this.props.location.pathname.substring(0,4) !== "/pt/" ? '/indicators': '/pt/indicators'}>
										{text.results_pages}.
									</a>
									{text.subtitle3}
									<a href='https://maps.worldbank.org/' target='_blank' style={{ textDecoration: 'underline' }}>{text.click_here}</a>.
								</p>
							</Linkify>
							<p style={{ color: '#4D4D4D' }}>{text.subtitle4}</p>
						</div>
					</div>
					<div className="program-map-content" style={{marginBottom: '40px'}}>
        				<div id="programMapContainer" className="col-md-9 col-sm-12" style={{ marginBottom: '144px' }}>
							{ this.state.refresh && <ProgramMap {...this.props} setStateData={this.setStateData} setSelectedState={this.setSelectedState} passedState={this.state.passedState} projectData={this.state.projectData} refresh={this.state.refresh}/> }
						</div>
						<div id="stateColumn" className="col-md-3 col-sm-12">
			        		<div id="video-title">{text.state_data}</div>
			        		<div style={{textAlign: 'center', marginTop: '5px', fontSize: '10px'}}>{text.state_click}</div>
			        		<div style={{marginTop: '10px', marginBottom: '20px'}} className="">
								<DropdownButton
								    drop={'down'}
								    title={this.state.selectedState === null ? "Select State" : this.state.selectedState}
								    id={'state-selector'}
								    key={'state-selector'}
								    onSelect={(e) => this.selectState(e)}
								>
								   	{br_states_list.map((state,index) => {
										return <MenuItem key={state} eventKey={state}>{state}</MenuItem>
									})}
								</DropdownButton>
							</div>
							<ListGroup style={{marginBottom: '20px'}}>
								<div style={{marginTop: '10px', marginBottom: '10px', textAlign: 'left', fontWeight: 'bold'}}>{text.state_projects_in} {this.state.selectedState}</div>
								{
									(this.state.projectData && Array.isArray(this.state.projectData)) &&
									this.state.projectData.map((project, index) => {
										if (project.states) {
											//correct spelling mismatches
											project.states.map((state,index2) => {
												if (br_states[state]){
													project.states[index2] = br_states[state];
												}
											})
										}

										if (this.state.selectedState === 'Federal' && project.states.length > 26) {
											return (
												<Link
													className="list-group-item project-link"
													to={{
														pathname: this.props.location.pathname.substring(0,4) !== "/pt/" ? '/projectdetails' : '/pt/projectdetails',
														state: { project: project }
													}}
												>
													{this.props.location.pathname.substring(0,4) !== "/pt/" ? project.title : project.title_pt}
													<i className="fa fa-chevron-right"></i>
												</Link>
											);
										}

										if (project.states && project.states.length < 27 && project.states.indexOf(this.state.selectedState) > -1){
											let focus = '';
											if (project.focus_area_1)
												focus = 'focus_area_1';
											else if (project.focus_area_2)
												focus = 'focus_area_2';
											else if (project.focus_area_3)
												focus = 'focus_area_3';

											return (
												<Link className="list-group-item project-link" to={{pathname: this.props.location.pathname.substring(0,4) !== "/pt/" ? '/projectdetails' : '/pt/projectdetails', state: { project: project }}}>{this.props.location.pathname.substring(0,4) !== "/pt/" ? project.title : project.title_pt}<i className="fa fa-chevron-right"></i></Link>
											)
										}
									})
								}
							</ListGroup>
							{
								this.state.stateData &&

								this.state.stateData.map((feature,index) => {
									if (Object.values(feature.properties).indexOf(this.state.selectedState) > -1){
										return (
											
											<div>
												<span style={{fontWeight: "bold", fontSize: "18px"}}>{text.indicators}</span>
												{
												Object.keys(feature.properties).map((key,index) => {
													if (key == "Hum_Cap_Index"){
														return (
															<div style={{marginTop: '10px', textAlign: 'left'}}><span style={{fontWeight: 'bold'}}>{text.human_capital_index}: </span>{feature.properties[key]}</div>
														)
													}else if (key == "Perc_Poor_190"){
														return (
															<div style={{marginTop: '10px', textAlign: 'left'}}><span style={{fontWeight: 'bold'}}>{text.poverty_rate}: </span>{this.abbreviate(feature.properties[key], 1, false, false)}%</div>
														)
													}else if (key == "Num_poor_190"){
														return (
															<div style={{marginTop: '10px', textAlign: 'left'}}><span style={{fontWeight: 'bold'}}>{text.number_poor}: </span>{this.abbreviate(feature.properties[key], 1, false, false)}</div>
														)
													}else if (key == "Num_Projects"){
														return (
															<div style={{marginTop: '10px', textAlign: 'left'}}><span style={{fontWeight: 'bold'}}>{text.number_projects}: </span>{this.abbreviate(feature.properties[key], 1, false, false)}</div>
														)
													}else if (key == "Pov190_Country_Comp"){
														return (
															<div style={{marginTop: '10px', textAlign: 'left'}}><span style={{fontWeight: 'bold'}}>{text.poverty_country_comparison}: </span>{feature.properties[key]}</div>
														)
													}else if (key == "Focus_Area_1_Funding"){
														return (
															<div style={{marginTop: '10px', textAlign: 'left'}}><span style={{fontWeight: 'bold'}}>{text.focus_area_1_funding}: </span>${this.abbreviate(feature.properties[key], 1, false, false)}</div>
														)
													}else if (key == "Focus_Area_2_Funding"){
														return (
															<div style={{marginTop: '10px', textAlign: 'left'}}><span style={{fontWeight: 'bold'}}>{text.focus_area_2_funding}: </span>${this.abbreviate(feature.properties[key], 1, false, false)}</div>
														)
													}else if (key == "Focus_Area_3_Funding"){
														return (
															<div style={{marginTop: '10px', textAlign: 'left'}}><span style={{fontWeight: 'bold'}}>{text.focus_area_3_funding}: </span>${this.abbreviate(feature.properties[key], 1, false, false)}</div>
														)
													}else if (key == "Ann_SevDrought_Likelihood_RCP26"){
														return (
															<div style={{marginTop: '10px', textAlign: 'left'}}><span style={{fontWeight: 'bold'}}>{text.severe_drought_likelihood_RCP26}: </span>{this.abbreviate(feature.properties[key], 2, false, false)}</div>
														)
													}else if (key == "Ann_SevDrought_Likelihood_RCP85"){
														return (
															<div style={{marginTop: '10px', textAlign: 'left'}}><span style={{fontWeight: 'bold'}}>{text.severe_drought_likelihood_RCP85}: </span>{this.abbreviate(feature.properties[key], 2, false, false)}</div>
														)
													}else if (key == "Avg_Days_HI35_RCP26"){
														return (
															<div style={{marginTop: '10px', textAlign: 'left'}}><span style={{fontWeight: 'bold'}}>{text.average_hi_RCP26}: </span>{this.abbreviate(feature.properties[key], 2, false, false)}</div>
														)
													}else if (key == "Avg_Days_HI35_RCP85"){
														return (
															<div style={{marginTop: '10px', textAlign: 'left'}}><span style={{fontWeight: 'bold'}}>{text.average_hi_RCP85}: </span>{this.abbreviate(feature.properties[key], 2, false, false)}</div>
														)
													}else if (key == "Rain_5Day_Max_25y_RCP26"){
														return (
															<div style={{marginTop: '10px', textAlign: 'left'}}><span style={{fontWeight: 'bold'}}>{text.rain_5_Day_RCP26}: </span>{this.abbreviate(feature.properties[key], 1, false, false)}</div>
														)
													}else if (key == "Rain_5Day_Max_25y_RCP85"){
														return (
															<div style={{marginTop: '10px', textAlign: 'left'}}><span style={{fontWeight: 'bold'}}>{text.rain_5_Day_RCP85}: </span>{this.abbreviate(feature.properties[key], 1, false, false)}</div>
														)
													}else if (key == "SUM_Climate_Disasters"){
														return (
															<div style={{marginTop: '10px', textAlign: 'left'}}><span style={{fontWeight: 'bold'}}>{text.climate_disasters}: </span>${this.abbreviate(feature.properties[key], 1, false, false)}</div>
														)
													}else if (key == "SUM_Damaged_Infrastructure"){
														return (
															<div style={{marginTop: '10px', textAlign: 'left'}}><span style={{fontWeight: 'bold'}}>{text.damaged_infrastructure}: </span>${this.abbreviate(feature.properties[key], 1, false, false)}</div>
														)
													}else if (key == "SUM_Meteorological_Disasters"){
														return (
															<div style={{marginTop: '10px', textAlign: 'left'}}><span style={{fontWeight: 'bold'}}>{text.meteorological_disasters}: </span>${this.abbreviate(feature.properties[key], 1, false, false)}</div>
														)
													}else if (key == "SUM_Hydrological_Disasters"){
														return (
															<div style={{marginTop: '10px', textAlign: 'left'}}><span style={{fontWeight: 'bold'}}>{text.hydrological_disasters}: </span>${this.abbreviate(feature.properties[key], 1, false, false)}</div>
														)
													}else if (key == "COUNT_Damaged_Houses"){
														return (
															<div style={{marginTop: '10px', textAlign: 'left'}}><span style={{fontWeight: 'bold'}}>{text.damaged_houses}: </span>{this.abbreviate(feature.properties[key], 1, false, false)}</div>
														)
													}else if (key == "SUM_Damages_and_Losses"){
														return (
															<div style={{marginTop: '10px', textAlign: 'left'}}><span style={{fontWeight: 'bold'}}>{text.damages_losses}: </span>${this.abbreviate(feature.properties[key], 1, false, false)}</div>
														)
													}else if (key == "COUNT_Destroyed_Houses"){
														return (
															<div style={{marginTop: '10px', textAlign: 'left'}}><span style={{fontWeight: 'bold'}}>{text.destroyed_houses}: </span>{this.abbreviate(feature.properties[key], 2, false, false)}</div>
														)
													}else if (key == "SUM_Private_Losses"){
														return (
															<div style={{marginTop: '10px', textAlign: 'left'}}><span style={{fontWeight: 'bold'}}>{text.private_losses_services}: </span>${this.abbreviate(feature.properties[key], 2, false, false)}</div>
														)
													}else if (key == "SUM_Private_Losses_Agriculture"){
														return (
															<div style={{marginTop: '10px', textAlign: 'left'}}><span style={{fontWeight: 'bold'}}>{text.private_losses_agriculture}: </span>${this.abbreviate(feature.properties[key], 2, false, false)}</div>
														)
													}else if (key == "SUM_Private_Losses_Industry"){
														return (
															<div style={{marginTop: '10px', textAlign: 'left'}}><span style={{fontWeight: 'bold'}}>{text.private_losses_industry}: </span>${this.abbreviate(feature.properties[key], 2, false, false)}</div>
														)
													}else if (key == "SUM_Private_Losses_Livestock"){
														return (
															<div style={{marginTop: '10px', textAlign: 'left'}}><span style={{fontWeight: 'bold'}}>{text.private_losses_livestock}: </span>${this.abbreviate(feature.properties[key], 1, false, false)}</div>
														)
													}else if (key == "SUM_Private_Losses_Services"){
														return (
															<div style={{marginTop: '10px', textAlign: 'left'}}><span style={{fontWeight: 'bold'}}>{text.private_losses_services}: </span>${this.abbreviate(feature.properties[key], 1, false, false)}</div>
														)
													}else if (key == "SUM_Public_Losses"){
														return (
															<div style={{marginTop: '10px', textAlign: 'left'}}><span style={{fontWeight: 'bold'}}>{text.public_losses}: </span>${this.abbreviate(feature.properties[key], 1, false, false)}</div>
														)
													}else if (key == "SUM_Total_Losses"){
														return (
															<div style={{marginTop: '10px', textAlign: 'left'}}><span style={{fontWeight: 'bold'}}>{text.total_losses}: </span>${this.abbreviate(feature.properties[key], 1, false, false)}</div>
														)
													}else if (key == "SUM_Total_Material_Damage"){
														return (
															<div style={{marginTop: '10px', textAlign: 'left'}}><span style={{fontWeight: 'bold'}}>{text.total_material_damage}: </span>${this.abbreviate(feature.properties[key], 1, false, false)}</div>
														)
													}else if (key == "SUM_Value_of_Damaged_Housing"){
														return (
															<div style={{marginTop: '10px', textAlign: 'left'}}><span style={{fontWeight: 'bold'}}>{text.value_damaged_housing}: </span>${this.abbreviate(feature.properties[key], 1, false, false)}</div>
														)
													}
												})}
											</div>
										)
									}
								})
							}
			        	</div>
					</div>
					<WelcomeFooter {...this.props}/>
				</div>
			</div>
		);
	}
}
export default withRouter(Program);
