/* a modal that has a form with user info and review of website */
import React, { useState, useEffect } from 'react';

import Button from '../../../components/Button';
import Modal from '../../../components/Modal';
import TitleSection from '../../../components/TitleSection';
import Checkbox from '../../../components/Checkbox';
import TextInput from '../../../components/TextInput';
import Select from 'react-select';
import classnames from 'classnames';
import styles from './UserReviewModal.module.css';
import axios from 'axios';
import Cookies from 'universal-cookie';

const textEnglish = {
  title:'User Review',
  question_1:'Are you Brazilian ?',
  question_2:'What is your gender ?',
  question_3:'What is your age group ?',
  question_4:'What is your current occupation ?',
  sub_question_4: 'Please specify your occupation',
  question_5:'Why did you visit OpenBrazil ?',
  sub_question_5:'Please specify your reason',
  question_6:'Do you think the platform should have information/features that it does not currently have ?',
  sub_question_6:'If yes, what would that be ?',
  question_7:'Did the platform enhance your knowledge about the World Bank Group in Brazil ?',
  question_8:'After navigating the platform, do you have a better understanding of the current strategy of the World Bank Group for Brazil ?',
  question_9:'Do you have any other comments/opinions about the platform you would like to share ?',
  question_10:'',
  gender_female:'Female',
  gender_male:'Male',
  gender_ni:' ',
  label_yes: 'Yes',
  label_no: 'No',
  label_under_18: 'under 18',
  label_18_34: '18-34',
  label_35_50: '35-50',
  label_50: '50+',  
  label_private_sector_worker: 'Private sector worker',
  label_public_sector_worker: 'Public sector worker',
  label_non_profit_worker: 'Non-profit worker',
  label_politician: 'Politician' ,
  label_researcher: 'Researcher' ,
  label_world_bank_employee: 'World Bank employee',
  label_other: 'Other' ,
  label_find_data_about_brazil: 'Find data about Brazil',
  label_other_specify: 'Other (specify)', 
  whydidyouvisit_a: 'Learn about the World Bank Group&apos;s portfolio and operations in Brazil',
  whydidyouvisit_b:  'Find the latest World Bank Group research and analytical work on Brazil',
  whydidyouvisit_c: 'Find data about Brazil',
  whydidyouvisit_d: 'Engage with the World Bank Group on its engagement in Brazil',
  whydidyouvisit_e:  'Other (specify)'  ,
}

const textPortuguese = {
  title:'Feedback do usuário',
  question_1:'Você é Brasiliero(a) ?',
  question_2:'Qual o seu gênero ?',
  question_3:'Faixa etária',
  question_4:'Qual sua área de atuação ?',
  sub_question_4: 'Por favor, especifique',
  question_5:'Por que você visitou o site Brasil Aberto ?',
  sub_question_5:'Por favor, especifique',
  question_6:'Você acha que a Plataforma deveria ter informações/ recursos adicionais ?',
  sub_question_6:'Se sim, qual seria ?',
  question_7:'A Plataforma aumentou seu conhecimento sobre o trabalho do Grupo Banco Mundial no Brasil ?',
  question_8:'Depois de navegar na plataforma, você possui um melhor entendimento da atual estratégia de parceria do Banco Mundial para o Brasil ?',
  question_9:'Você teria outros comentários que gostaria de compartilhar ?',
  question_10:'',  
  gender_female:'Feminino',
  gender_male:'Masculino',
  gender_ni:'Prefiro não dizer',
  label_yes: 'Sim',
  label_no: 'Não',
  label_under_18: 'até 18 anos',
  label_18_34: 'entre 18 e 34 anos',
  label_35_50: 'entre 35 e 50 anos',
  label_50: 'mais que 50 anos',
  label_studend: 'Estudante',
  label_private_sector_worker: 'Setor privado',
  label_public_sector_worker: 'Setor público',
  label_non_profit_worker: 'Organismo internacional (Banco Mundial)',
  label_politician: 'Política' ,
  label_researcher: 'Pesquisador' ,
  label_world_bank_employee: 'Organismo internacional (Banco Mundial)',
  label_other: 'Outros' ,
  label_find_data_about_brazil: 'Find data about Brazil',
  label_other_specify: 'Outro (especifique)',  
  whydidyouvisit_a: 'Para saber mais sobre o portfolio e as operações do Grupo Banco Mundial no Brasil ',
  whydidyouvisit_b: 'Para saber mais sobre os últimos estudos e relatórios analíticos relacionados ao Brasil',
  whydidyouvisit_c: 'Buscar dados sobre o Brasil',
  whydidyouvisit_d: 'Conhecer mais sobre o engajamento do Grupo Banco Mundial no Brasil',
  whydidyouvisit_e: 'Outro (especifique)'  
}


const emptyForm = {
  brazillian: { value: '', label: 'Selecione..' },
  gender: { value: '', label: 'Selecione..' },
  ageGroup: { value: '', label: 'Selecione..' },
  whyDidYouVisit: { value: '', label: 'Selecione..' },
  whyDidYouVisitOther: '',
  occupation: { value: '', label: 'Selecione..' },
  occupationOther: '',
  didPlatformHelp: { value: '', label: 'Selecione..' },
  wasInformationHelpful: { value: '', label: 'Selecione..' },
  doYouHaveSuggestions: { value: '', label: 'Selecione..' },
  suggestions: '',
  otherComments: '',
  label_yes: 'Sim',
  label_no: 'Não',
  label_under_18: 'até 18 anos',
  label_18_34: 'entre 18 e 34 anos',
  label_35_50: 'entre 35 e 50 anos',
  label_50: 'mais que 50 anos',
  label_studend: 'Estudante',
  label_private_sector_worker: 'Setor privado',
  label_public_sector_worker: 'b.	Setor público',
  label_non_profit_worker: 'e.	Organizações sem fins lucrativos',
  label_politician: 'Política' ,
  label_researcher: 'Pesquisador' ,
  label_world_bank_employee: 'Organismo internacional (Banco Mundial)',
  label_other: 'Organismo internacional (Outros)' ,
  label_find_data_about_brazil: 'Find data about Brazil',
  label_other_specify: 'Outro (especifique)'  
};

const validateForm = ({
  brazillian,
  gender,
  ageGroup,
  whyDidYouVisit,
  occupation,
  didPlatformHelp,
  wasInformationHelpful,
  doYouHaveSuggestions,
}) => {
  return (
    brazillian.value !== '' &&
    gender.value !== '' &&
    ageGroup.value !== '' &&
    whyDidYouVisit.value !== '' &&
    occupation.value !== '' &&
    didPlatformHelp.value !== '' &&
    wasInformationHelpful.value !== '' &&
    wasInformationHelpful.value !== '' &&
    doYouHaveSuggestions !== ''
  );
};


const UserReviewModal = (props) => {
  const [formState, setFormState] = useState(emptyForm);

  const [modalVisibility, setModalVisibility] = useState(true);

  const [formIsValid, setFormIsValid] = useState(validateForm(formState));

  const handleModalVisibility = () => setModalVisibility(!modalVisibility);

  const [pt, setPt] = useState(props.location.pathname.includes('/pt'));
  
  const [text, setText] = useState(!pt ? textEnglish : textPortuguese);



  const setCookie = () => {
    let d = new Date()
    const cookies = new Cookies();
    let maxage =33600 * 24 * 30 * 12 * 300
    d.setTime(d.getTime() + maxage)
    cookies.set('answered', 1, { path: '/' , expires: d});
  }
  
  const updateReview = () => {
    let d = new Date()
    let maxage =33600 * 24 * 30 * 12 * 300
    const cookies = new Cookies();
    d.setTime(d.getTime() + maxage)
    cookies.set('answered', 1, { path: '/' , expires: d});

    console.log(formState);
    sendUpdateData( formState );
  }

  
  async function sendUpdateData( body ) {
    await axios
    .post(`/api/new_review`, body)
    .then((done) => {
      let cookies = new Cookies();      
      cookies.set('answered', "true", { path: '/', expires: 2500000 });
    })
    .catch((err) => {});
  }


  const resetFormState = () => {
    setFormState(emptyForm);
  };

  const handleFormChange = (field) => (value) => {
    setFormState({
      ...formState,
      [field]: value,
    });
  };

  console.log({ styles });

  useEffect(() => {
    resetFormState();
  }, []);

  useEffect(() => {
    setFormIsValid(validateForm(formState));
  }, [formState]);

  return (

    <>
      {modalVisibility && (
        <Modal modalVisibility={() => modalsVisibility}>
          <TitleSection 
            title={text.title}  
            className={styles.Title} 
            onClose={() => {
              setCookie();
              handleModalVisibility('userReview')
            }} 
          />

          <div className={styles.Form}>
            <div className={styles.UserReviewRow}>
              <div className={styles.QuestionWrapper}>
                <span className={styles.UserReviewLabel}>
                  {text.question_1}
                </span>
                <div
                  className={classnames(
                    styles.InputWrapper,
                    styles.InputWrapperSmall
                  )}
                >
                  <Select
                    name="brazillian"
                    value={formState.brazillian}
                    onChange={(e) => handleFormChange('brazillian')(e)}
                    options={[
                      {
                        value: 'yes',
                        label: text.label_yes,
                      },
                      {
                        value: 'no',
                        label: text.label_no,
                      },
                    ]}
                    defaultValue={emptyForm.brazillian}
                    searchable={false}
                    clearable={false}
                  />
                </div>
              </div>
              <div className={styles.QuestionWrapper}>
                <span className={styles.UserReviewLabel}>
                {text.question_2}
                </span>
                <div
                  className={classnames(
                    styles.InputWrapper,
                    styles.InputWrapperSmall
                  )}
                >
                  <Select
                    name="gender"
                    value={formState.gender}
                    onChange={(e) => handleFormChange('gender')(e)}
                    options={[
                      {
                        value: 'female',
                        label: text.gender_female ,
                      },
                      {
                        value: 'male',
                        label: text.gender_male,
                      },
                    ]}
                    searchable={false}
                    clearable={false}
                  />
                </div>
              </div>
              <div className={styles.QuestionWrapper}>
                <span className={styles.UserReviewLabel}>
                {text.question_3}
                </span>
                <div
                  className={classnames(
                    styles.InputWrapper,
                    styles.InputWrapperSmall
                  )}
                >
                  <Select
                    name="ageGroup"
                    value={formState.ageGroup}
                    onChange={(e) => handleFormChange('ageGroup')(e)}
                    options={[
                      {
                        value: 'under 18',
                        label: text.label_under_18,
                      },
                      {
                        value: '18-34',
                        label: text.label_18_34,
                      },
                      {
                        value: '35-50',
                        label: text.label_35_50,
                      },
                      {
                        value: '50+',
                        label: text.label_50,
                      },
                    ]}
                    searchable={false}
                    clearable={false}
                  />
                </div>
              </div>
              <div className={styles.QuestionWrapper}>
                <span className={styles.UserReviewLabel}>
                {text.question_4}
                </span>
                <div className={styles.InputWrapper}>
                  <Select
                    name="occupation"
                    value={formState.occupation}
                    onChange={(e) => handleFormChange('occupation')(e)}
                    options={[
                      { value: 'student', label: text.label_studend },
                      {
                        value: 'private-sector-worker',
                        label: text.label_private_sector_worker,
                      },
                      {
                        value: 'public-sector-worker',
                        label: text.label_public_sector_worker,
                      },
                      {
                        value: 'non-profit-worker',
                        label: text.label_non_profit_worker,
                      },
                      { value: 'politician', label: text.label_politician },
                      { value: 'researcher', label: text.label_researcher },
                      {
                        value: 'world-bank-employee',
                        label: text.label_world_bank_employee,
                      },
                      { value: 'other', label: text.label_other },
                    ]}
                    searchable={false}
                    clearable={false}
                  />
                </div>
              </div>
            </div>
            {formState.occupation.value === 'other' && (
              <div className={styles.QuestionWrapper}>
                <span className={styles.UserReviewLabel}>
                {text.sub_question_4}
                </span>
                <div
                  className={(styles.InputWrapper, styles.InputWrapperLarge)}
                >
                  <TextInput
                    name="occupationOther"
                    value={formState.occupationOther}
                    onChange={(e) => handleFormChange('occupationOther')(e)}
                  />
                </div>
              </div>
            )}
            <div className={styles.QuestionWrapper}>
              <span className={styles.UserReviewLabel}>
              {text.question_5}
              </span>
              <div
                className={classnames(
                  styles.InputWrapper,
                  styles.InputWrapperLarge
                )}
              >
                <Select
                  name="whyDidYouVisit"
                  value={formState.whyDidYouVisit}
                  onChange={(e) => handleFormChange('whyDidYouVisit')(e)}
                  options={[
                    {
                      value: 'a',
                      label:
                        text.whydidyouvisit_a,
                    },
                    {
                      value: 'b',
                      label:
                        text.whydidyouvisit_b,
                    },
                    {
                      value: 'c',
                      label: text.whydidyouvisit_c,
                    },
                    {
                      value: 'd',
                      label:
                        text.whydidyouvisit_d,
                    },
                    {
                      value: 'e',
                      label: text.whydidyouvisit_e,
                    },
                  ]}
                  searchable={false}
                  clearable={false}
                />
              </div>
            </div>
            {formState.whyDidYouVisit.value === 'e' && (
              <div className={styles.QuestionWrapper}>
                <span className={styles.UserReviewLabel}>
                {text.sub_question_5}
                </span>
                <div
                  className={classnames(
                    styles.InputWrapper,
                    styles.InputWrapperFull
                  )}
                >
                  <TextInput
                    name="whyDidYouVisitOther"
                    value={formState.whyDidYouVisitOther}
                    onChange={(e) => handleFormChange('whyDidYouVisitOther')(e)}
                    type="textarea"
                  />
                </div>
              </div>
            )}
            <div className={styles.QuestionWrapper}>
              <span className={styles.UserReviewLabel}>
              {text.question_6}
              </span>
              <div
                className={classnames(
                  styles.InputWrapper,
                  styles.InputWrapperSmall
                )}
              >
                <Select
                  name="doYouHaveSuggestions"
                  value={formState.doYouHaveSuggestions}
                  onChange={(e) => handleFormChange('doYouHaveSuggestions')(e)}
                  options={[
                    { value: 'yes', label: text.label_yes },
                    { value: 'no', label: text.label_no },
                  ]}
                  searchable={false}
                  clearable={false}
                />
              </div>
            </div>
            {formState.doYouHaveSuggestions.value === 'yes' && (
              <div className={styles.QuestionWrapper}>
                <span className={styles.UserReviewLabel}>
                {text.sub_question_6}
                </span>
                <div
                  className={classnames(
                    styles.InputWrapper,
                    styles.InputWrapperFull
                  )}
                >
                  <TextInput
                    name="suggestions"
                    value={formState.suggestions}
                    onChange={(e) => handleFormChange('suggestions')(e)}
                    type="textarea"
                  />
                </div>
              </div>
            )}
            <div className={styles.UserReviewRow}>
              <div className={styles.QuestionWrapper}>
                <span className={styles.UserReviewLabel}>
                {text.question_7}
                </span>
                <div
                  className={classnames(
                    styles.InputWrapper,
                    styles.CheckboxWrapper
                  )}
                >
                  <Checkbox
                    name="didPlatformEnhanceKnowledge"
                    label={<span>😠</span>}
                    className={styles.CheckboxItem}
                    value={formState.didPlatformHelp.value === '1'}
                    onChange={() =>
                      formState.didPlatformHelp.value === '1'
                        ? setFormState({ ...formState, didPlatformHelp: {} })
                        : handleFormChange('didPlatformHelp')({
                            value: '1',
                            label: <span>😠</span>,
                          })
                    }
                  />

                  <Checkbox
                    name="didPlatformEnhanceKnowledge"
                    label={<span>🙁</span>}
                    className={styles.CheckboxItem}
                    value={formState.didPlatformHelp.value === '2'}
                    onChange={() =>
                      formState.didPlatformHelp.value === '2'
                        ? setFormState({ ...formState, didPlatformHelp: {} })
                        : handleFormChange('didPlatformHelp')({
                            value: '2',
                            label: <span>🙁</span>,
                          })
                    }
                  />
                  <Checkbox
                    name="didPlatformEnhanceKnowledge"
                    label={<span>😐</span>}
                    className={styles.CheckboxItem}
                    value={formState.didPlatformHelp.value === '3'}
                    onChange={() =>
                      formState.didPlatformHelp.value === '3'
                        ? setFormState({ ...formState, didPlatformHelp: {} })
                        : handleFormChange('didPlatformHelp')({
                            value: '3',
                            label: <span>😐</span>,
                          })
                    }
                  />
                  <Checkbox
                    name="didPlatformEnhanceKnowledge"
                    label={<span>🙂</span>}
                    className={styles.CheckboxItem}
                    value={formState.didPlatformHelp.value === '4'}
                    onChange={() =>
                      formState.didPlatformHelp.value === '4'
                        ? setFormState({ ...formState, didPlatformHelp: {} })
                        : handleFormChange('didPlatformHelp')({
                            value: '4',
                            label: <span>🙂</span>,
                          })
                    }
                  />
                  <Checkbox
                    name="didPlatformEnhanceKnowledge"
                    label={<span>😀</span>}
                    className={styles.CheckboxItem}
                    value={formState.didPlatformHelp.value === '5'}
                    onChange={() =>
                      formState.didPlatformHelp.value === '5'
                        ? setFormState({ ...formState, didPlatformHelp: {} })
                        : handleFormChange('didPlatformHelp')({
                            value: '5',
                            label: <span>😀</span>,
                          })
                    }
                  />
                </div>
              </div>
              <div className={styles.QuestionWrapper}>
                <span className={styles.UserReviewLabel}>
                {text.question_8}
                </span>
                <div
                  className={classnames(
                    styles.InputWrapper,
                    styles.CheckboxWrapper
                  )}
                >
                  <Checkbox
                    name="didPlatformEnhanceKnowledge"
                    label={<span>😠</span>}
                    className={styles.CheckboxItem}
                    value={formState.wasInformationHelpful.value === '1'}
                    onChange={() =>
                      formState.wasInformationHelpful.value === '1'
                        ? setFormState({
                            ...formState,
                            wasInformationHelpful: {},
                          })
                        : handleFormChange('wasInformationHelpful')({
                            value: '1',
                            label: <span>😠</span>,
                          })
                    }
                  />
                  <Checkbox
                    name="didPlatformEnhanceKnowledge"
                    label={<span>🙁</span>}
                    className={styles.CheckboxItem}
                    value={formState.wasInformationHelpful.value === '2'}
                    onChange={() =>
                      formState.wasInformationHelpful.value === '2'
                        ? setFormState({
                            ...formState,
                            wasInformationHelpful: {},
                          })
                        : handleFormChange('wasInformationHelpful')({
                            value: '2',
                            label: <span>🙁</span>,
                          })
                    }
                  />
                  <Checkbox
                    name="didPlatformEnhanceKnowledge"
                    label={<span>😐</span>}
                    className={styles.CheckboxItem}
                    value={formState.wasInformationHelpful.value === '3'}
                    onChange={() =>
                      formState.wasInformationHelpful.value === '3'
                        ? setFormState({
                            ...formState,
                            wasInformationHelpful: {},
                          })
                        : handleFormChange('wasInformationHelpful')({
                            value: '3',
                            label: <span>😐</span>,
                          })
                    }
                  />
                  <Checkbox
                    name="didPlatformEnhanceKnowledge"
                    label={<span>🙂</span>}
                    className={styles.CheckboxItem}
                    value={formState.wasInformationHelpful.value === '4'}
                    onChange={() =>
                      formState.wasInformationHelpful.value === '4'
                        ? setFormState({
                            ...formState,
                            wasInformationHelpful: {},
                          })
                        : handleFormChange('wasInformationHelpful')({
                            value: '4',
                            label: <span>🙂</span>,
                          })
                    }
                  />
                  <Checkbox
                    name="didPlatformEnhanceKnowledge"
                    label={<span>😀</span>}
                    className={styles.CheckboxItem}
                    value={formState.wasInformationHelpful.value === '5'}
                    onChange={() =>
                      formState.wasInformationHelpful.value === '5'
                        ? setFormState({
                            ...formState,
                            wasInformationHelpful: {},
                          })
                        : handleFormChange('wasInformationHelpful')({
                            value: '5',
                            label: <span>5 😀</span>,
                          })
                    }
                  />
                </div>
              </div>
            </div>

            <div className={styles.QuestionWrapper}>
              <span className={styles.UserReviewLabel}>
                {text.question_9}
              </span>
              <div
                className={classnames(
                  styles.InputWrapper,
                  styles.InputWrapperFull
                )}
              >
                <TextInput
                  name="otherComments"
                  value={formState.otherComments}
                  onChange={(e) => handleFormChange('otherComments')(e)}
                  type="textarea"
                />
              </div>
            </div>
          </div>
          <div className={styles.ButtonsWrapper}>
            <Button
              onClick={() => {
                setCookie();
                handleModalVisibility('userReview')
              }}
              className={styles.CancelButton}
            >
              Cancelar
            </Button>
            <Button
              onClick={() => {
                updateReview();
                handleModalVisibility('userReview')
              }}
              className={styles.Button}
              disabled={!formIsValid}
            >
              Enviar
            </Button>
          </div>
        </Modal>
      )}
    </>
  );
};

export default UserReviewModal;
