import dayjs from 'dayjs';
import axios from 'axios';
import 'dayjs/locale/pt-br';
import classnames from 'classnames';
import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';

import Button from '../../components/Button';
import Navbar from '../common/navbar/NavbarPLR';
import WelcomeFooter from '../menu/WelcomeFooter';
import TableModal from '../common/modals/TableModal';

import '../finance/Finance.css';
import '../strategy/Strategy.css';
import StrategyHeader from '../common/strategyheader';
import abbreviateFromMillions from '../common/utilities';
import StrategyCover from '../strategy/img/strategy-cover.png'
import styles from '../../styles/leveraging-finance.module.css';
import externalLinkIcon from '../../../public/img/external-link.svg';
import {API_URL} from "../../../config/api";

const textEnglish = {
  login: 'Login',
  logout: 'Log Out',
  med: 'Leveraging Finance',
  page_desc_0: [],
  page_desc_0_bold: [],
  page_desc_1_title: 'Maximizing Finance for Development',
  page_desc_1: [
    'Under the ',
    {
      text: 'Maximizing Finance for Development',
      url:
        'https://www.worldbank.org/en/about/partners/maximizing-finance-for-development',
    },
    ' approach, the WBG already has developed experience in Brazil in combining resources from the International Bank for Reconstruction and Development (IBRD, or World Bank), the International Finance Corporation (IFC) and the Multilateral Investment Guarantee Agency (MIGA).',
  ],
  page_desc_2_title: 'IFC mobilization of private sector financing',
  page_desc_2_start:
    `IFC alone has financed about US$3.34 billion in investments and leveraged additional private sector financing, through equity and debt, of about US$638.76 million (Table 1).`,
  page_desc_2_mid_1: [
    'In recent years IFC and IBRD have started to work together to maximize finance for development in joint integrated projects such as in the water and energy sectors. In the water sector, IFC is advising the São Paulo state’s water utility, ',
    { text: 'SABESP', url: 'http://site.sabesp.com.br/site/Default.aspx' },
    ' - the largest in the Americas - to increase its investment capacity by structuring a transaction to raise private capital through both equity and lending (and including a corporate governance component).  The IFC project team includes IBRD’s sector specialists.',
  ],
  page_desc_2_mid_2: [
    'The IBRD is also providing a loan to SABESP to increase access of vulnerable people to water services and to contribute to the reduction of water losses and pollution loads in the Metropolitan Region of São Paulo. In the energy sector, IFC is: (i) helping to develop a pipeline of high-quality subprojects, a proposal of regulatory adjustments to develop the street lighting PPP market and a capacity building program in project preparation for ',
    {
      text: 'Caixa',
      url:
        'http://www.caixa.gov.br/site/english/About-Caixa/Paginas/default.aspx',
    },
    '; and (ii) has supported through an advisory component the structuring and implementation of the first ever Waste to Energy project in the country, the Barueri project in São Paulo State – which should open this market and lead the way for these critical environmental projects in Brazil.',
  ],
  page_desc_2_end: [
    'This work is also leveraging a US$ 0.5 million grant from the ',
    {
      text: 'Global Infrastructure Facility',
      url: 'https://www.globalinfrafacility.org/',
    },
    ' and expected US$ 400 million private commercial financing. In the transport sector, as highlighted above, close partnership between the Bank and IFC is nothing new, both on advisory and investment; through PPP and concession structuring, partnerships cover both logistics infrastructure (highways), and urban mobility services (metros, Bus rapid Transit). Going forward, in addition to continued collaboration in infrastructure, there is good potential for increased IFC-IBRD collaboration in new areas, such as for example in developing PPPs in the health and education sectors, where a consolidated offering could include IBRD support for developing the right policy framework and IFC support for project structuring, debt or equity investments. A joint implementation plan could facilitate collaboration of the two institutions at the sector level.',
  ],

  page_desc_3_title: 'Collaboration with MIGA',
  page_desc_3: [
    'One WBG collaboration with MIGA has also been successful.  By providing non-honoring of sub-sovereign financial obligations guarantee for the ',
    {
      text: 'Sao Paulo State Sustainable Transport Project',
      url: 'https://www.miga.org/project/sao-paulo-sustainable-transport',
    },
    ', MIGA helped mobilize US$361 million of long-term financing from an international commercial bank on competitive terms. Going forward, MIGA will explore opportunities to deploy its political risk insurance and credit enhancement instruments to de-risk potential private sector foreign investments in Brazil with a special focus at the sub-national level in the sectors of social infrastructure and support to development banks. As the authorities aim to increase private investment in infrastructure, MIGA’s various credit enhancement offerings would become attractive and could complement IBRD and IFC’s work on improving the regulatory environment and developing new domestic capital market instruments.',
  ],
  modal_title: (
    asofdate
  ) => `IFC mobilization of private sector financing as of ${asofdate}` ,
  view_total: 'view total',
  table_1: {
    view: 'View Table 1',
    small_title: 'Table 1:',
    title: 'IFC mobilization of private sector financing as of January 2020',
    totals: ';;;;Totals;$2,974,869,227.74;$751,300,714.74',
    header_labels: ';Product Category;Measures;;;;',
    table_headers:
      'Institution;Equity IFC;Equity Mobilization;Loan IFC;Loan Mobilization;Total IFC;Total Mobilization',
    table: [ ],
  },
  ifc_approval : 'IFC Approval',
  company_name : 'Company Name',
  ifc_investment_for_guarantee_1 : 'IFC Investment',
  ifc_investment_for_loan_1 : 'IFC Investment',
  ifc_investment_for_equity_1 : 'IFC Investment',
  ifc_investment_for_guarantee_2 : 'for Guarantee',
  ifc_investment_for_loan_2 : 'for Loan',
  ifc_investment_for_equity_2 : 'for Equity',
  total_investment : 'Total Investment',
  link_to_project : 'Link to Project',
};

const textPortuguese = {
  login: 'Login',
  logout: 'Log Out',
  med: 'Alavancando o Financiamento',

  page_desc_0: [
    'Para aumentar o impacto no desenvolvimento, o Grupo Banco Mundial (GBM) está sempre em busca de novas maneiras de alavancar seu próprio financiamento para mobilizar recursos adicionais. No Brasil e em outros países, o GBM fará uma transição gradual para um modelo de plataforma. Nele, a principal fonte de financiamento é o próprio orçamento do governo, o que melhora a qualidade alocativa e técnica das despesas, bem como a transparência no uso de todos os recursos envolvidos no projeto. A plataforma ajuda os países que empregam essa abordagem a enfrentar os desafios de capacidade, principalmente em relação ao planejamento, orçamento, coordenação e implementação.',
  ],
  page_desc_0_bold: [
    'Ao melhorar a qualidade das grandes despesas do governo, ela apoia o impacto sustentado, sistêmico e transformacional do desenvolvimento. Por meio dessa abordagem de plataforma, o GBM continuará a mobilizar recursos de outros parceiros de desenvolvimento e do setor privado.',
  ],

  page_desc_1_title: 'Maximizando o Financiamento para o Desenvolvimento',
  page_desc_1: [
    'Com base na abordagem de ',
    {
      text: 'Maximizar o Financiamento para o Desenvolvimento',
      url:
        'https://www.worldbank.org/en/about/partners/maximizing-finance-for-development',
    },
    ', o GBM já tem experiência, no Brasil, na combinação de recursos do Banco Internacional para Reconstrução e Desenvolvimento (BIRD, ou "Banco Mundial"), da da IFC (International Finance Corporation) e da Agência Multilateral de Garantia de Investimentos (MIGA).',
  ],
  modal_title: (
    asofdate
  ) => `Mobilização de financiamento do setor privado por parte da IFC em ${asofdate}` ,
  page_desc_2_title:
    'Mobilização de financiamento do setor privado por parte da IFC',
  page_desc_2_start:
    'A IFC financiou, sozinha, cerca de US$ 3.34 bilhões em investimentos e alavancou financiamentos adicionais do setor privado por meio de capital e dívidas, totalizando aproximadamente US$638.76 milhões (Tabela 1).',
  page_desc_2_mid_1: [
    'Nos últimos anos, a IFC e o BIRD começaram a trabalhar juntos para maximizar o financiamento para o desenvolvimento em projetos integrados e conjuntos, como nos setores de água e energia. No setor de água, a IFC está assessorando a concessionária de água do estado de São Paulo, ',
    { text: 'SABESP', url: 'http://site.sabesp.com.br/site/Default.aspx' },
    ' (a maior das Américas), para aumentar sua capacidade de investimento, estruturando uma transação para aumentar o capital privado por meio de equity e empréstimos (e incluindo um componente de governança corporativa). A equipe de projeto da IFC inclui especialistas do BIRD no setor.',
  ],
  page_desc_2_mid_2: [
    'O BIRD também está concedendo um empréstimo à SABESP para aumentar o acesso de pessoas vulneráveis aos serviços hídricos e contribuir para reduzir as perdas de água e as cargas de poluição na Região Metropolitana de São Paulo. ',
    'No setor de energia, a IFC: (i) está ajudando a desenvolver um pipeline de subprojetos de alta qualidade, uma proposta de ajustes regulatórios para desenvolver o mercado de PPP para fins de iluminação pública e um programa de capacitação na elaboração de projetos para a ',
    {
      text: 'Caixa',
      url: 'https://www.caixa.gov.br/sobre-a-caixa/Paginas/default.aspx',
    },
    '; e (ii) apoiou, por meio de um componente de assessoramento, a estruturação e implementação do primeiro projeto Waste to Energy ("do resíduo à energia") no país, o projeto Barueri, no estado de São Paulo, que deve abrir esse mercado e trilhar o caminho para esses projetos ambientais de suma importância para o Brasil.',
  ],
  page_desc_2_end: [
    'Este trabalho também está mobilizando uma doação de US$ 0,5 milhão da ',
    {
      text: 'Global Infrastructure Facility',
      url: 'https://www.globalinfrafacility.org/',
    },
    ' e prevê US$ 400 milhões em financiamento comercial privado. No setor de transporte, como destacado acima, a estreita parceria entre o Banco e a IFC não é novidade, tanto em termos de assessoramento quanto de investimento. Por meio de PPPs e estruturação das concessões, as parcerias abrangem tanto a infraestrutura logística (rodovias) quanto os serviços de mobilidade urbana (metrôs, Bus-Rapid Transit). ',
    'No futuro, além de colaboração contínua em matéria de infraestrutura, existe grande potencial para o aumento da colaboração entre a IFC e o BIRD em novas áreas. Por exemplo, no desenvolvimento de PPPs nos setores de saúde e educação, nos quais uma oferta consolidada poderia incluir o apoio do BIRD ao desenvolvimento de uma estrutura de políticas adequada e o apoio da IFC à estruturação de projetos, investimentos de capital ou dívidas. Um plano conjunto de implementação poderia facilitar a colaboração das duas instituições em nível setorial.',
  ],

  page_desc_3_title: 'Colaboração com a MIGA',
  page_desc_3: [
    'O GBM também participou de uma colaboração bem-sucedida com a MIGA. Ao fornecer garantias financeiras contra a inadimplência em obrigações subssoberanas no ',
    {
      text: 'Projeto de Transporte Sustentável do Estado de São Paulo',
      url: 'https://www.miga.org/project/sao-paulo-sustainable-transport',
    },
    ', a MIGA ajudou a mobilizar US$ 361 milhões em financiamento de longo prazo de um banco comercial internacional, sob condições bastante competitivas. ',
    'No futuro, a MIGA explorará oportunidades para implantar seus instrumentos de seguro de risco político e aprimoramento de crédito para reduzir o risco de potenciais investimentos do setor privado estrangeiro no Brasil, com foco subnacional nos setores de infraestrutura social e apoio aos bancos de desenvolvimento. ',
    'À medida que as autoridades buscam aumentar os investimentos privados em infraestrutura, as diversas ofertas de melhoria de crédito da MIGA passam a ser atraentes e podem complementar o trabalho do BIRD e da IFC para melhorar o ambiente regulatório e desenvolver novos instrumentos para o mercado de capital do país.',
  ],
  view_total: 'Ver total',
  table_1: {
    view: 'Ver total',
    small_title: 'Tabela 1:',
    title:
      'Mobilização de financiamento do setor privado por parte da IFC em janeiro de 2020',
    totals: ';;;;Totais;$3,008,348,472.34;$662,930,815.71',
    header_labels: ';Categoria de Produto;Medidas;;;;',
    table_headers:
      'Instituição;Capital da IFC;Mobilização de Capital;Empréstimo IFC;Mobilização de Empréstimos;Total IFC;Mobilização Total',
    table: [
    ],
  },

  ifc_approval : 'Aprovação IFC',
  company_name : 'Nome da Companhia',
  ifc_investment_for_guarantee_1 : 'IFC – Investimento',
  ifc_investment_for_loan_1 : 'IFC – Investimento',
  ifc_investment_for_equity_1 : 'IFC – Investimento',
  ifc_investment_for_guarantee_2 : 'para Garantia',
  ifc_investment_for_loan_2 : 'para Empréstimo',
  ifc_investment_for_equity_2 : 'em Participação',
  total_investment : 'Total de Investimento',
  link_to_project : 'Link para Projeto',
};

const Risk = (props) => {
  const [showTable, setShowTable] = useState(false);
  const [activeTable, setActiveTable] = useState(null);
  const [ifcProjects, setIfcProjects] = useState([]);
  const [ifcTotals, setIfcTotals] = useState([]);
  const [tableTitle, setTableTitle] = useState(null);
  const [lang, setLang] = useState(null);
  const [asOfDate, setAsOfDate] = useState(null);

  const handleLogout = () => {
    localStorage.removeItem('auth');
    props.history.push('/');
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${API_URL}/ifc`);
        setIfcProjects(response.data);
        setAsOfDate(response.data[0].as_of_date);
      } catch (err) {
        // Handle error
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchTotals = async () => {
      try {
        const response = await axios.get(`${API_URL}/ifc_totals`);
        setIfcTotals(response.data[0]);
        console.log(response.data[0]);
      } catch (err) {
        // Handle error
      }
    };

    fetchTotals();
  }, []);

  const openTableModal = (table, asOfDate) => {
    let text = {};
    let dateFormat = 'DD/MM/YYYY';
    let pt = false;
    if (props.history.location.pathname.substring(0, 4) !== '/pt/') {
      text = textEnglish;
      dateFormat = 'MM-DD-YYYY';
      pt = false;
    } else {
      text = textPortuguese;
      dateFormat = 'DD/MM/YYYY';
      pt = true;
    }

    setActiveTable(text[table]);
    setTableTitle(text.modal_title);
    setShowTable(true);
    setLang(pt);
  };

  const closeTableModal = () => {
    setShowTable(false);
    setActiveTable(null);
  };

  let text = {};
  let pt = false;
  let dateFormat = 'DD/MM/YYYY';
  if (props.location.pathname.substring(0, 4) !== '/pt/') {
    text = textEnglish;
    dateFormat = 'MM-DD-YYYY';
    pt = false;
  } else {
    text = textPortuguese;
    dateFormat = 'DD/MM/YYYY';
    pt = true;
  }

  let ifc_investment_for_guarantee_million_usd = 0;
  let ifc_investment_for_loan_million_usd = 0;
  let ifc_investment_for_equity_million_usd = 0;
  let total_ifc_investment_as_approved_by_board_million_usd = 0;

  return (
    <div>
      <div className="container-fluid unpadify">
        <Navbar
          handleLogin={handleLogout}
          handleLogout={handleLogout}
          auth={props.auth}
          isLoggedIn={props.loggedIn}
          checkIsLoggedIn={props.checkIsLoggedIn}
          onChangeLoginState={props.onChangeLoginState}
          {...props}
          currentPage="strategy"
        />
        <StrategyHeader
          title={pt ? "Estratégia" : "Strategy"}
          selectedTab={2}
          tabs={[
              { link: pt ? '/pt/story' : '/story', label: pt ? 'Parceria' : "Partnership Framework" },
              { link: pt ? '/pt/strategy' : '/strategy', label: pt ? 'Áreas de foco' : 'Focus Areas' },
              { link: pt ? '/pt/leveraging-finance' : '/leveraging-finance', label: pt ? 'Alavancando o financiamento' : 'Leveraging Finance' },
              { link: pt ? '/pt/history' : '/history', label: pt ? 'Contando a história' : 'Telling the Story' },
          ]}
          backgroundImage={StrategyCover}
          backgroundPosition="0px 69%"
        />
        <div id="strategy-container">
          <h2 style={{ fontSize: '40px' }}>{pt ? "Alavancando o financiamento" : "Leveraging Finance"}</h2>
          <div className="divider"></div>
          <div>
            <h3 style={{
              fontFamily: 'Andes, sans-serif',
              display: 'flex',
              justifyContent: 'start',
              alignItems: 'start',
              fontSize: '32px',
              lineHeight: '32px',
              fontWeight: 400,
              color: '#1A1A1A',
              margin: '0px',
              marginBottom: '24px',
              marginTop: '40px',
            }}>{text.page_desc_1_title}</h3>
            <div className="finance-desc">
              {text.page_desc_1.map((content, index) => {
                if (content.text) {
                  return (
                    <a href={content.url} target="_blank">
                      {content.text}
                    </a>
                  );
                } else {
                  return <span>{content}</span>;
                }
              })}
            </div>
            <div>
              <h3 style={{
                fontFamily: 'Andes, sans-serif',
                display: 'flex',
                justifyContent: 'start',
                alignItems: 'start',
                fontSize: '32px',
                lineHeight: '32px',
                fontWeight: 400,
                color: '#1A1A1A',
                margin: '0px',
                marginBottom: '24px',
                marginTop: '40px',
              }}>{text.page_desc_2_title}</h3>
              <div className="finance-desc">{text.page_desc_2_start}</div>
            </div>
            <div>
            <p
              style={{
                textAlign: 'end',
                fontWeight: '400',
                display: 'inline-block',
                padding: '10px'
              }}
              className={styles.asOfDate}
            >
              { pt ? 'Em ' : 'As of '}
              {pt ? dayjs(new Date(asOfDate)).locale('pt-br').format('MMMM YYYY') : dayjs(new Date(asOfDate)).format('MMMM YYYY')}
            </p>
              <div style={{overflowX: 'auto'}}>
                <table className={styles.tableWrapper}>
                  <thead className={styles.tableHead}>
                    <tr
                      className={classnames(
                        styles.tableRow,
                        styles.headerRow
                      )}
                    >
                      <td className={styles.tableHeadItem}>{text.ifc_approval}</td>
                      <td className={styles.tableHeadItem}>{text.company_name}</td>
                      <td className={styles.tableHeadItem}>{text.ifc_investment_for_guarantee_1} <br /> {text.ifc_investment_for_guarantee_2}</td>
                      <td className={styles.tableHeadItem}>{text.ifc_investment_for_loan_1} <br /> {text.ifc_investment_for_loan_2}</td>
                      <td className={styles.tableHeadItem}>{text.ifc_investment_for_equity_1}<br /> {text.ifc_investment_for_equity_2}</td>
                      <td className={styles.tableHeadItem}>{text.total_investment}</td>
                      <td className={styles.tableHeadItem}>{text.link_to_project}</td>
                    </tr>
                  </thead>
                  <tbody className={styles.tableBody}>
                    {(ifcProjects != null && Array.isArray(ifcProjects)) && ifcProjects.slice(0,3).map((item, index) => {
                      ifc_investment_for_guarantee_million_usd +=   Number(item.ifc_investment_for_guarantee_million_usd) ?? 0
                      ifc_investment_for_loan_million_usd +=  Number(item.ifc_investment_for_loan_million_usd) ?? 0
                      ifc_investment_for_equity_million_usd +=  Number(item.ifc_investment_for_equity_million_usd) ?? 0
                      total_ifc_investment_as_approved_by_board_million_usd +=  Number(item.total_ifc_investment_as_approved_by_board_million_usd) ?? 0
                      return (
                        <tr className={classnames(styles.tableRow, styles.rowA)}>
                          <td>{dayjs(new Date(item.ifc_approval_date)).format(dateFormat)}</td>
                          <td>{item.company_name}</td>
                          <td>{item.ifc_investment_for_guarantee_million_usd ? 'US$ '+      abbreviateFromMillions(item.ifc_investment_for_guarantee_million_usd, 2, 2, false) : "-"}</td>
                          <td>{item.ifc_investment_for_loan_million_usd ? 'US$ '+     abbreviateFromMillions(item.ifc_investment_for_loan_million_usd, 2, 2, false) : "-"}</td>
                          <td>{item.ifc_investment_for_equity_million_usd ? 'US$ '+     abbreviateFromMillions(item.ifc_investment_for_equity_million_usd, 2, 2, false) : "-"}</td>
                          <td>{item.total_ifc_investment_as_approved_by_board_million_usd ? 'US$ '+     abbreviateFromMillions(item.total_ifc_investment_as_approved_by_board_million_usd, 2, 2, false) : "-"}</td>
                          <td>
                            {externalLinkIcon && (
                              <a href={item.project_url}>
                                <img
                                  styles={styles.icon}
                                  src={externalLinkIcon}
                                />
                              </a>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                    <tr className={classnames(styles.tableRow, styles.totalRow)}>
                    <td>Total</td>
                      <td></td>
                      <td>{ifc_investment_for_guarantee_million_usd ? 'US$ '+              abbreviateFromMillions(ifc_investment_for_guarantee_million_usd, 2, 2, false) : "-"}</td>
                      <td>{ifc_investment_for_loan_million_usd ? 'US$ '+                   abbreviateFromMillions(ifc_investment_for_loan_million_usd, 2, 2, false) : "-"}</td>
                      <td>{ifc_investment_for_equity_million_usd ? 'US$ '+                 abbreviateFromMillions(ifc_investment_for_equity_million_usd, 2, 2, false) : "-"}</td>
                      <td>{total_ifc_investment_as_approved_by_board_million_usd ? 'US$ '+ abbreviateFromMillions(total_ifc_investment_as_approved_by_board_million_usd, 2, 2, false) : "-"}</td>
                    </tr>
                  </tbody>
                </table>
                </div>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                  <Button
                    style={{ fontWeight: 400 }}
                    className={styles.modalButton}
                    onClick={() => openTableModal('table_1')}
                  >
                    {text.view_total}
                  </Button>
                </div>
            </div>
            <div className="finance-desc" style={{ marginTop: '32px' }}>
              <div>
                {text.page_desc_2_mid_1.map((content, index) => {
                  if (content.text) {
                    return (
                      <a href={content.url} target="_blank">
                        {content.text}
                      </a>
                    );
                  } else {
                    return <span>{content}</span>;
                  }
                })}
              </div>
            </div>
            <div className="finance-desc">
              <div>
                {text.page_desc_2_mid_2.map((content, index) => {
                  if (content.text) {
                    return (
                      <a key={index} href={content.url} target="_blank">
                        {content.text}
                      </a>
                    );
                  } else {
                    return <span key={index}>{content}</span>;
                  }
                })}
              </div>
            </div>
            <div className="finance-desc">
              <div>
                {text.page_desc_2_end.map((content, index) => {
                  if (content.text) {
                    return (
                      <a href={content.url} target="_blank">
                        {content.text}
                      </a>
                    );
                  } else {
                    return <span>{content}</span>;
                  }
                })}
              </div>
            </div>
            <div style={{
              fontFamily: 'Andes, sans-serif',
              display: 'flex',
              justifyContent: 'start',
              alignItems: 'start',
              fontSize: '32px',
              lineHeight: '32px',
              fontWeight: 400,
              color: '#1A1A1A',
              margin: '0px',
              marginBottom: '24px',
              marginTop: '40px',
            }}>
              <div>
                <div>{text.page_desc_3_title}</div>
              </div>
            </div>
            <div className="finance-desc">
              <div>
                {text.page_desc_3.map((content, index) => {
                  if (content.text) {
                    return (
                      <a href={content.url} target="_blank">
                        {content.text}
                      </a>
                    );
                  } else {
                    return <span>{content}</span>;
                  }
                })}
              </div>
            </div>
          </div>
        </div>
        { (tableTitle && showTable) && (
          <TableModal
            showing={showTable}
            table_data={activeTable}
            tabletitle={tableTitle}
            closeTableModal={closeTableModal}
            lang={pt}
          />
        )}
        <div style={{ height: '100px', width: '1px' }} />
        <WelcomeFooter {...props} />
      </div>
    </div>
  );
}

export default withRouter(Risk);
