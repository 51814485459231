import React, { useState } from 'react';

import './WelcomeFooter.css';
import wbgLogo from './img/logo-worldbank.svg';

import fbIcon from './img/facebook-icon.svg';
import twIcon from './img/twitter-icon.svg';
import igIcon from './img/instagram-icon.svg';
import ytIcon from './img/youtube-icon.svg';
import lkIcon from './img/linkedin-icon.svg';

import MigaIcon from './img/miga-icon.svg';
import IfcLogo from './img/ifc-logo.svg';
import ChevronDownIcon from './img/chevron-down.svg';
import LogoWorldBank from './img/logo-world-bank.svg';

const textEnglish = {
    'social':'Stay Connected With Our Work in Brazil',
    'legal':'Legal',
    'access':'Access to Information',
    'jobs':'Jobs',
    'contact':'Contact',
    'report':'Report Fraud or Corruption',
    'worldbank':'THE WORLD BANK',
    'ibrd':'IBRD',
    'ida':'IDA',
    'ifc':'IFC',
    'miga':'MIGA',
    'icsid':'ICSID',
    'copyright':'2020 The World Bank Group, All Rights Reserved.',
    'feedback':'Feedback Survey',
    follow_us: 'Follow World Bank'
}

const textPortuguese = {
    'social':'Fique conectado com o nosso trabalho no Brasil',
    'legal':'Legal',
    'access':'Access to Information',
    'jobs':'Jobs',
    'contact':'Contact',
    'report':'Report Fraud or Corruption',
    'worldbank':'THE WORLD BANK',
    'ibrd':'IBRD',
    'ida':'IDA',
    'ifc':'IFC',
    'miga':'MIGA',
    'icsid':'ICSID',
    'copyright':'2020 The World Bank Group, todos os direitos reservados',
    'feedback':'Feedback Survey',
    follow_us: 'Siga o Banco Mundial'
}

const WelcomeFooter = (props) => {
    const [ifcExpanded, setIfcExpanded] = useState(false);
    const [migaExpanded, setMigaExpanded] = useState(false);

    const redirect = (url) => {
        window.open(url, '_blank');
    };

    const pt = props.location.pathname.substring(0, 4) === '/pt/';
    let text = {};
    if (props.location) {
        text = pt ? textPortuguese : textEnglish;
    } else {
        text = textEnglish;
    }

    return (
        <div id="welcome-footer" className="welcome-footer">
            <div className="welcome-top">
                <div className="welcome-top-top">
                    <div className="welcome-footer-about">
                        <img alt="WorldBank" src={LogoWorldBank} style={{ marginBottom: '40px' }} />
                        <span>{text.follow_us}</span>
                        <div style={{ display: 'flex', gap: '16px' }}>
                            <img alt="Facebook" src={fbIcon} style={{ cursor: 'pointer' }} onClick={() => redirect('https://www.facebook.com/BancoMundialBrasil/')} />
                            <img alt="Twitter" src={twIcon} style={{ cursor: 'pointer' }} onClick={() => redirect('https://twitter.com/bancomundialbr')} />
                            <img alt="Instagram" src={igIcon} style={{ cursor: 'pointer' }} onClick={() => redirect('https://www.instagram.com/bancomundial/')} />
                            <img alt="Youtube" src={ytIcon} style={{ cursor: 'pointer' }} onClick={() => redirect('https://www.youtube.com/user/alcregion2010')} />
                        </div>
                    </div>
                    <div className="welcome-footer-country">
                        <p>{pt ? "Brasil" : "Brazil"}</p>
                        <span>+55 61 3329-1000</span>
                        <span>informacao@worldbank.org</span>
                        <span>SCES Trecho 03, Lote 05, Polo 8, S/N,</span>
                        <span>Brasília, CEP 70200-003</span>
                    </div>
                    <div className="welcome-footer-country">
                        <p>{pt ? "EUA" : "USA"}</p>
                        <span>+1 202 473-1000</span>
                        <span>informacao@worldbank.org</span>
                        <span>1818 H Street NW,</span>
                        <span>Washington, DC 20433</span>
                    </div>
                </div>
                <div className="welcome-top-bottom" style={{ marginBottom: '32px' }}>
                    <div className="welcome-top-bottom-header">
                        <img alt="IFC" src={IfcLogo} />
                        <img
                            alt="Ch"
                            src={ChevronDownIcon}
                            style={{cursor: 'pointer' }}
                            onClick={() => setIfcExpanded(!ifcExpanded)}
                            className={ifcExpanded ? "icon-flipped" : ''}
                        />
                    </div>
                    {ifcExpanded && (
                        <div className="welcome-top-bottom-expanded-content">
                            <div className="welcome-footer-country">
                                <p>{pt ? "Corporação Financeira Internacional" : "International Finance Corporation"}</p>
                                <span>+1 (202) 473-1000</span>
                                <span>2121 Pennsylvania Avenue, NW</span>
                                <span>Washington, DC 20433 USA</span>
                            </div>
                            <div className="welcome-footer-country">
                                <p>{pt ? "Assessoria de comunicação" : "External Relations"}</p>
                                <span><strong>Mame Annan-Brown</strong></span>
                                <span>{pt ? "Gerente" : "Manager"}</span>
                                <span>+1 (202) 458-1055</span>
                                <span><strong>Pierre Mejlak</strong></span>
                                <span>{pt ? "Diretor de relações com a mídia" : "Principal, Media Relations"}</span>
                                <span>+1 (202) 458-2278</span>
                            </div>
                            <div className="connect-social-media">
                                <span>{pt ? "Conecte-se" : "Connect with us"}</span>
                                <div style={{ display: 'flex', gap: '16px', marginTop: '16px' }}>
                                    <img alt="Facebook" src={fbIcon} style={{ cursor: 'pointer' }} onClick={() => redirect('https://www.facebook.com/IFCwbg')} />
                                    <img alt="Twitter" src={twIcon} style={{ cursor: 'pointer' }} onClick={() => redirect('https://twitter.com/IFC_org')} />
                                    <img alt="Instagram" src={igIcon} style={{ cursor: 'pointer' }} onClick={() => redirect('https://www.instagram.com/ifc_org/')} />
                                    <img alt="Youtube" src={ytIcon} style={{ cursor: 'pointer' }} onClick={() => redirect('https://www.youtube.com/user/IFCvideocasts')} />
                                    <img alt="Linkedin" src={lkIcon} style={{ cursor: 'pointer' }} onClick={() => redirect('https://www.linkedin.com/company/ifclinkedin/')} />
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <div className="welcome-top-bottom">
                    <div className="welcome-top-bottom-header">
                        <img alt="Miga" src={MigaIcon} />
                        <img
                            alt="Chevron Down"
                            src={ChevronDownIcon}
                            style={{cursor: 'pointer' }}
                            onClick={() => setMigaExpanded(!migaExpanded)}
                            className={migaExpanded ? "icon-flipped" : ''}
                        />
                    </div>
                    {migaExpanded && (
                        <div className="welcome-top-bottom-expanded-content">
                            <div className="welcome-footer-country">
                                <p>{pt ? "Grupo Banco Mundial" : "World Bank Group"}</p>
                                <span>migainquiry@worldbank.org</span>
                                <span>1818 H Street, NW</span>
                                <span>Washington, DC 20433 USA</span>
                            </div>
                            <div className="connect-social-media">
                                <span>{pt ? "Conecte-se" : "Connect with us"}</span>
                                <div style={{ display: 'flex', gap: '16px', marginTop: '16px' }}>
                                    <img alt="Twitter" src={twIcon} style={{ cursor: 'pointer' }} onClick={() => redirect('https://twitter.com/miga')} />
                                    <img alt="Linkedin" src={lkIcon} style={{ cursor: 'pointer' }} onClick={() => redirect('https://www.linkedin.com/company/wb-miga/')} />
                                    <img alt="Youtube" src={ytIcon} style={{ cursor: 'pointer' }} onClick={() => redirect('https://www.youtube.com/user/MIGAWorldBank')} />
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div className="welcome-base">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <span className="logo"><a href="http://www.worldbank.org/" target="_blank"><img alt="WorldBank" className="welcome-footer-logo" src={wbgLogo}></img></a></span>
                    <span className="links separator"><a href="http://www.worldbank.org/en/who-we-are/ibrd" target="_blank">{text.ibrd}</a></span>
                    <span className="links separator"><a href="http://www.worldbank.org/ida" target="_blank">{text.ida}</a></span>
                    <span className="links separator"><a href="http://www.ifc.org/" target="_blank">{text.ifc}</a></span>
                    <span className="links separator"><a href="http://www.miga.org/" target="_blank">{text.miga}</a></span>
                    <span className="links"><a href="http://icsid.worldbank.org/" target="_blank">{text.icsid}</a></span>
                </div>
                <div>
                    <span className="copyright-text">{text.copyright}</span>
                </div>
            </div>
        </div>
    );
}

export default WelcomeFooter;
