import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styles from './styles.module.css';
import { currencyMask } from '../../../utils/constants';
function TextField({
  className = '',
  wrapperClassName = '',
  error = '',
  id = '',
  label = '',
  subLabel = '',
  toggle = true,
  type = 'text',
  value = '',
  onChange = () => {},
  ...inputProps
}) {
  // regex function that replaces money with currency value and two decimal digits even if its ,00

  const now = new Date().getTime();
  const elementId = id || label.replace(/[\W_]+/g, '') + now;
  const [fieldToggled, setFieldToggled] = useState(false);
  const [stateValue, setStateValue] = useState('');
  const [maskedValue, setMaskedValue] = useState(currencyMask(value));

  const handleFieldChange = (evt) => {
    evt.preventDefault();
    const newValue =
      type === 'currency'
        ? evt.target.value.replace(/\D/g, '')
        : evt.target.value;
    onChange(newValue, evt.target);
    setStateValue(newValue);
    setMaskedValue(currencyMask(newValue));
  };

  const handleFieldToggle = () => {
    setFieldToggled(!fieldToggled);
  };

  const inputClasses = classNames(styles.Input, className, {
    [styles.InputError]: error,
  });

  const toggleClasses = classNames(styles.Toggle, {
    'ts-textfield__toggle--toggled': fieldToggled,
  });

  const _type = () => {
    switch (type) {
      case 'currency':
        return 'text';
      case 'number':
        return 'number';
      case 'password':
        return fieldToggled ? 'text' : 'password';
      case 'file':
        return 'file';
      case 'textarea':
        return 'textarea';
      default:
        return 'text';
    }
  };

  const inputValue = value || stateValue;

  return (
    <div className={classNames(styles.InputWrapper, wrapperClassName)}>
      {label && (
        <label className={styles.LabelElement} htmlFor={elementId}>
          {label}
        </label>
      )}
      {subLabel && <p className={styles.SubLabelElement}>{subLabel}</p>}
      <div className={styles.TextInputContainer}>
        {type === 'textarea' ? (
          <textarea
            id={elementId}
            tabIndex={0}
            {...inputProps}
            className={inputClasses}
            type={_type()}
            value={type !== 'currency' ? inputValue : maskedValue}
            onChange={handleFieldChange}
          />
        ) : (
          <input
            id={elementId}
            tabIndex={0}
            {...inputProps}
            className={inputClasses}
            type={_type()}
            value={type !== 'currency' ? inputValue : maskedValue}
            onChange={handleFieldChange}
          />
        )}
        {type === 'currency' && (
          <span className={styles.CurrencySpan}>USD</span>
        )}
        {type === 'password' && toggle && (
          <button
            type="button"
            className={toggleClasses}
            onClick={handleFieldToggle}
          >
            {fieldToggled ? (
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.05184 5.83655C9.21726 5.32383 10.532 5.00012 11.9998 5.00012C14.955 5.00012 17.3087 6.31539 19.0602 7.86435C20.8157 9.41683 21.9258 11.1705 22.3673 11.9445C22.379 11.9651 22.3828 11.9834 22.3827 11.9993C22.3827 12.0155 22.3786 12.0357 22.3654 12.059C22.0995 12.5247 21.588 13.3515 20.8352 14.2768C20.5738 14.5981 20.6224 15.0705 20.9437 15.3319C21.265 15.5933 21.7374 15.5447 21.9988 15.2234C22.8155 14.2196 23.3722 13.3209 23.6681 12.8025C23.9511 12.3067 23.9567 11.7037 23.6702 11.2013C23.1854 10.3513 21.9795 8.44363 20.0539 6.74071C18.1243 5.03428 15.4317 3.50012 11.9998 3.50012C10.3045 3.50012 8.78491 3.8753 7.4478 4.46355C7.06865 4.63035 6.89652 5.07293 7.06332 5.45207C7.23012 5.83122 7.6727 6.00335 8.05184 5.83655Z"
                  fill="#141E35"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M19.1659 17.9873C17.3277 19.3788 14.9329 20.5001 11.9998 20.5001C8.56794 20.5001 5.87534 18.966 3.94574 17.2595C2.02012 15.5566 0.814241 13.6488 0.32943 12.7989C0.0432711 12.2972 0.0477581 11.6949 0.331127 11.1984C0.790619 10.3933 1.8964 8.64044 3.64775 7.01109L1.31672 5.36231C0.978548 5.12312 0.898312 4.65507 1.13751 4.3169C1.3767 3.97873 1.84475 3.89849 2.18292 4.13769L22.6829 18.6377C23.0211 18.8769 23.1013 19.3449 22.8621 19.6831C22.6229 20.0213 22.1549 20.1015 21.8167 19.8623L19.1659 17.9873ZM4.9016 7.89796C3.17101 9.43933 2.07409 11.1706 1.63389 11.9419C1.62066 11.9651 1.61674 11.985 1.6167 12.0007C1.61666 12.0162 1.62036 12.0346 1.63238 12.0557C2.07386 12.8297 3.18393 14.5834 4.93944 16.1359C6.69098 17.6848 9.04471 19.0001 11.9998 19.0001C14.3335 19.0001 16.2905 18.1805 17.8735 17.0732L14.3578 14.5865C13.7357 15.1539 12.9081 15.5 11.9998 15.5C10.0668 15.5 8.49976 13.933 8.49976 12C8.49976 11.5167 8.59771 11.0563 8.77484 10.6376L4.9016 7.89796Z"
                  fill="#141E35"
                />
              </svg>
            ) : (
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15.5 12C15.5 13.933 13.933 15.5 12 15.5C10.067 15.5 8.5 13.933 8.5 12C8.5 10.067 10.067 8.5 12 8.5C13.933 8.5 15.5 10.067 15.5 12Z"
                  fill="#141E35"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M12.0001 3.50012C8.56823 3.50012 5.87563 5.03429 3.94603 6.74074C2.0204 8.44368 0.814528 10.3514 0.329717 11.2014C0.0450429 11.7005 0.045042 12.2998 0.329716 12.7989C0.814527 13.6488 2.0204 15.5566 3.94603 17.2595C5.87563 18.966 8.56822 20.5001 12.0001 20.5001C15.432 20.5001 18.1246 18.9659 20.0542 17.2595C21.9798 15.5565 23.1857 13.6488 23.6705 12.7988C23.9552 12.2997 23.9552 11.7004 23.6705 11.2013C23.1857 10.3513 21.9798 8.44363 20.0542 6.74071C18.1246 5.03428 15.432 3.50012 12.0001 3.50012ZM1.63266 11.9446C2.07414 11.1706 3.18421 9.41688 4.93973 7.86438C6.69127 6.3154 9.04499 5.00012 12.0001 5.00012C14.9553 5.00012 17.309 6.31539 19.0605 7.86435C20.816 9.41683 21.9261 11.1705 22.3675 11.9445C22.3807 11.9675 22.384 11.9861 22.384 12.0001C22.384 12.014 22.3807 12.0326 22.3675 12.0556C21.9261 12.8296 20.816 14.5833 19.0605 16.1358C17.309 17.6848 14.9553 19.0001 12.0001 19.0001C9.04499 19.0001 6.69127 17.6848 4.93973 16.1359C3.18421 14.5834 2.07414 12.8297 1.63266 12.0557C1.61955 12.0327 1.61621 12.0141 1.61621 12.0001C1.61621 11.9861 1.61955 11.9676 1.63266 11.9446Z"
                  fill="#141E35"
                />
              </svg>
            )}
          </button>
        )}
        <div className="ts-textfield__highlight" />
      </div>
      {error && <div className={styles.Error}>{error}</div>}
    </div>
  );
}

TextField.propTypes = {
  className: PropTypes.string,
  wrapperClassName: PropTypes.string,
  error: PropTypes.string || PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  subLabel: PropTypes.string,
  toggle: PropTypes.bool,
  type: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default React.memo(TextField);
