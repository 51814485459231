import axios from "axios";
import React, { useEffect, useState } from "react";

import AlertPopup from "../../AlertPopup";
import { API_URL } from "../../../../config/api";
import ConfirmDeleteModal from "../../ConfirmDeleteModal";

const textEnglish = {
  deleteError: 'Error on deleting indicator.',
  deleteSuccess: 'Indicator deleted successfully!'
};

const textPortuguese = {
  deleteError: 'Erro ao deletar indicador.',
  deleteSuccess: 'Indicador deletado com sucesso!'
};

export const CardContent = ({ graphs, pt, isExpanded, onDelete, ...props }) => {
  const [data, setData] = useState([]);
  const [text, setText] = useState(textEnglish);
  const [alertProps, setAlertProps] = useState(null);
  const [indicatorSelected, setIndicatorSelected] = useState(null);
  const [onTrack, setOnTrack] = useState(props.on_track ?? false);
  const [showIndicator, setShowIndicator] = useState(props.show_progress ?? false);
  const [openConfirmDeleteModal, setOpenConfirmDeleteModal] = useState(false);

  const openAlertPopup = (props) => {
    setAlertProps(props);
  }

  const handleOpenConfirmDeleteModal = (row) => {
    setIndicatorSelected(row);
    setOpenConfirmDeleteModal(true);
  }

  const handleCloseConfirmDeleteModal = () => {
    setIndicatorSelected(null);
    setOpenConfirmDeleteModal(false);
  }

  const handleDelete = async (row) => {
    try {
      const indicatorId = String(row.indicator_id).replace(/IN/g, "");
      await axios.post(
        `${API_URL}/indicators/remove_data?id=${indicatorId}&year=${row.year}&month=${row.month}&category=${row.category}`
      );

      onDelete(row);
      
      handleCloseConfirmDeleteModal();

      openAlertPopup({
        type: 'success',
        message: text.deleteSuccess,
        duration: 3000
      });
    } catch (error) {
      openAlertPopup({
        type: 'danget',
        message: text.deleteError,
        duration: 3000
      });
    }
  }

  const handleSetShowIndicator = async (value, id) => {
    await axios.post(
      `${API_URL}/indicators/setshowprogress?bool=${value}&id=${id}`
    );
  };
  const handleSetOnTrack = async (value, id) => {
    await axios.post(
      `${API_URL}/indicators/setontrack?bool=${value}&id=${id}`
    );
  };

  useEffect(() => {
    if (pt) {
      setText(textPortuguese);
    }
  }, [pt])

  useEffect(() => {
    const sortedData = graphs.sort((a, b) => Number(b.year) - Number(a.year));
    setData(sortedData);
  }, [graphs]);

  const DeleteIcon = ({ color }) => (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 10L14 17"
        stroke={color && "white"}
        strokeWidth="2"
        strokeLinecap="square"
      />
      <path
        d="M10 10L10 17"
        stroke={color && "white"}
        strokeWidth="2"
        strokeLinecap="square"
      />
      <path
        d="M18 6H6V20C6 20.5523 6.44772 21 7 21H17C17.5523 21 18 20.5523 18 20V6Z"
        stroke={color && "white"}
        strokeWidth="2"
        strokeLinecap="square"
      />
      <path
        d="M4 6H20"
        stroke={color && "white"}
        strokeWidth="2"
        strokeLinecap="square"
      />
      <path
        d="M15 3H9C8.44772 3 8 3.44772 8 4V6H16V4C16 3.44772 15.5523 3 15 3Z"
        stroke={color && "white"}
        strokeWidth="2"
        strokeLinecap="square"
      />
    </svg>
  );

  return (
    <div
      style={{
        display: isExpanded ? "flex" : "none",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
        height: "100%",
        width: "100%",
      }}
    >
      {alertProps && (
        <AlertPopup onClose={() => setAlertProps(null)} {...alertProps}/>
      )}
      {openConfirmDeleteModal && (
        <ConfirmDeleteModal
          showModal={true}
          onDelete={() => handleDelete(indicatorSelected)}
          closeModal={handleCloseConfirmDeleteModal}
          {...props}
        />
      )}
      <div
        style={{
          maxHeight: "340px",
          overflowY: "auto",
          marginBottom: "26px",
          width: "100%",
          paddingRight: "20px",
        }}
      >
        <table
          className="indicators-table"
          style={{
            display: isExpanded ? "table" : "none",
            borderCollapse: "collapse",
            width: "100%",
            opacity: isExpanded ? 1 : 0,
            transition: "opacity 0.3s ease-in-out",
          }}
        >
          <thead>
            <tr>
              <th style={{ height: "50px" }}>{pt ? "Ano" : "Year"}</th>
              <th style={{ height: "50px", textAlign: "center" }}>
                {pt ? "Mês" : "Month"}
              </th>
              <th style={{ height: "50px", textAlign: "end" }}>
                {pt ? "Valor" : "Value"}
              </th>
              <th style={{ textAlign: "end", height: "50px" }}>
                {pt ? "Deletar" : "Delete"}
              </th>
            </tr>
          </thead>
          <tbody>
            {data.map((row, index) => (
              <tr key={index} style={{ marginTop: "10px", height: "38px" }}>
                <td>{row.year}</td>
                <td style={{ textAlign: "center" }}>{row.month}</td>
                <td style={{ textAlign: "end" }}>
                  {row.value?.toLocaleString("pt-BR", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </td>
                <td
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    paddingRight: "20px",
                  }}
                >
                  <button
                    style={{
                      width: "32px",
                      height: "32px",
                      backgroundColor: "#009FDA",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      border: "none",
                      cursor: "pointer",
                    }}
                    onClick={() => handleOpenConfirmDeleteModal(row)}
                  >
                    <DeleteIcon color={"white"} />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "26px",
          width: "100%",
          opacity: isExpanded ? 1 : 0,
          transition: "opacity 0.3s ease-in-out",
        }}
      >
        {/* <div style={{
                display: 'flex',
                alignItems: 'center',
            }}>
                <div style={{ display: 'flex', alignItems: 'center', }}>
                    <ToggleButton
                        value={showIndicator} 
                        onChange={
                            (value) => {
                                handleSetShowIndicator(value, props.id);
                            }
                        } 
                    />
                    <span style={{ marginLeft: '10px' }}>Indicador visível</span>
                </div>
                <div style={{
                    width: '1px',
                    height: '24px',
                    backgroundColor: '#B4B4B4',
                    opacity: '0.6',
                    margin: '0 10px',
                }}></div>
                <div style={{ display: 'flex', alignItems: 'center', }}>
                    <ToggleButton
                        value={onTrack}
                        onChange={
                            (value) => {
                                handleSetOnTrack(value, props.id);
                            }
                    } />
                    <span style={{ marginLeft: '10px' }}>Progresso visível</span>
                </div>
            </div> */}
        <div></div>

        <button
          style={{
            width: "95px",
            height: "38px",
            backgroundColor: "white",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            border: "1px solid #009FDA",
            cursor: "pointer",
            color: "#009FDA",
          }}
          onClick={() => props.setIsModalOpen(true)}
        >
          {pt ? "Adicionar" : "Add"}
        </button>
      </div>
    </div>
  );
};