import React from 'react'
import propTypes from 'prop-types'
import styles from './styles.module.css'
import {currencyMask} from "../../../utils/constants";

const ProgressBar = ({
  current,
  currentLabel,
  title,
  total,
  totalLabel,
  children,
}) => {
  return (
    <div className={styles.wrapper} style={{ width: '100%', margin: '0 0 20px' }}>
      <p>{title}</p>
      <div className={styles.progressWrapper}>
        <div
          className={styles.progress}
          style={{ width: `${(Number(current) * 100) / total}%` }}
        />
      </div>
      <div className={styles.detail}>
        <p>
          <span>{`$${currencyMask(current)}`}</span>
          {currentLabel}/
        </p>
        <p>
          <span>{`$${currencyMask(total)}`}</span>
          {totalLabel}
        </p>
      </div>
      {children}
    </div>
  )
}

ProgressBar.propTypes = {
  children: propTypes.node,
  current: propTypes.number.isRequired,
  currentLabel: propTypes.string.isRequired,
  total: propTypes.number.isRequired,
  totalLabel: propTypes.string.isRequired,
  title: propTypes.string.isRequired,
}

export default ProgressBar
