import React, { useState } from 'react';
import { Collapse } from 'react-bootstrap';

import GroupedBar  from '../../../common/graphs/GroupedBar';
import GroupedBar2  from '../../../common/graphs/GroupedBar2';
import GraphTabs from '../../../common/graphTabs/GraphTabs';

import './Section.css'

const textEnglish = {
	'institutions': 'Institutions',
	'gender': 'Gender',
	'source': 'SOURCE',
	'historical': 'Historical'
}

const textPortuguese = {
	'institutions': 'Instituições',
	'gender': 'Gênero',
	'source': 'FONTE',
	'historical': 'Histórico'
}

const Institutions = (props) => {
	const [isOpen, setIsOpen] = useState(true);
	const [hasLoaded, setHasLoaded] = useState(false);

	let pt = false;
	let text = {};
	if (props.location.pathname.substring(0, 4) !== '/pt/') {
		text = textEnglish;
	} else {
		pt = true;
		text = textPortuguese;
	}

	let buttonText = <i className="fa fa-caret-down plus-icon" />;
	if (isOpen) {
		buttonText = <i className="fa fa-caret-right minus-icon" />;
	}
	let visible = props.visibleSections;

	if (isOpen || hasLoaded) {
		return (
			<div className="section-container" style={visible['section-three'] ? {} : { display: 'none' }}>
				<div className="row">
					<div className="col-md-12 categoryContent benchmark-section-content">
						<Collapse in={isOpen}>
							<div>
								<div
									id="section-three-subsection-zero"
									style={visible['section-three-subsection-zero'] ? {} : { display: 'none' }}
								></div>
								<div
									id="section-three-subsection-one"
									style={visible['section-three-subsection-one'] ? {} : { display: 'none' }}
								>
									<h2 style={{ fontSize: '32px' }}>{text.gender}</h2>
									<h4 style={{ fontSize: '22px' }} className="pad-top">
										{
											props.wdi_data[props.country_order[0].iso3].indicators['SG.GEN.PARL.ZS'][
												pt ? 'name_pt' : 'name'
												]
										}
									</h4>
									<div className="row">
										<div className="col-xs-12 col-lg-12">
											<p className="benchmarking-text">
												{
													props.wdi_data[props.country_order[0].iso3].indicators[
														'SG.GEN.PARL.ZS'
														][pt ? 'source_note_pt' : 'source_note']
												}
											</p>
										</div>
										<div className="col-xs-12">
											<GraphTabs
												{...props}
												pt={pt}
												tab1Name={
													props.wdi_data[props.country_order[0].iso3].indicators[
														'SG.GEN.PARL.ZS'
														].data[
													props.wdi_data[props.country_order[0].iso3].indicators[
														'SG.GEN.PARL.ZS'
														].data.length - 1
														].year
												}
												tab2Name={text.historical}
												tab1Content={
													<div>
														<GroupedBar
															{...props}
															pt={pt}
															wdi_data={props.wdi_data}
															country_order={props.country_order}
															field="SG.GEN.PARL.ZS"
															values={1}
															formatter={'0,0.00'}
														/>
														<p className="footnote">
															{text.source}:{' '}
															{
																props.wdi_data[props.country_order[0].iso3]
																	.indicators['SG.GEN.PARL.ZS'][
																	pt ? 'source_pt' : 'source'
																	]
															}
														</p>
													</div>
												}
												tab2Content={
													<div>
														<GroupedBar2
															{...props}
															pt={pt}
															wdi_data={props.wdi_data}
															country_order={props.country_order}
															field="SG.GEN.PARL.ZS"
															values={5}
															formatter={'0,0.00'}
														/>
														<p className="footnote">
															{text.source}:{' '}
															{
																props.wdi_data[props.country_order[0].iso3]
																	.indicators['SG.GEN.PARL.ZS'][
																	pt ? 'source_pt' : 'source'
																	]
															}
														</p>
													</div>
												}
											/>
										</div>
									</div>
									<hr />
								</div>
								<div
									id="section-three-subsection-two"
									style={visible['section-three-subsection-two'] ? {} : { display: 'none' }}
								></div>
							</div>
						</Collapse>
					</div>
				</div>
			</div>
		);
	} else {
		return (
			<div className="section-container" style={visible['section-three'] ? {} : { display: 'none' }}>
				<div className="row">
					<div className="col-md-12">
						<hr />
					</div>
				</div>
				<div id="section-three-subsection-zero"></div>
				<div id="section-three-subsection-one"></div>
				<div id="section-three-subsection-two"></div>
			</div>
		);
	}
};

export default Institutions;
