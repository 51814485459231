import React from 'react'

import TimesIcon from './img/times-icon.svg'
import './FilterProjectsModal.css'
import AltButton from '../../../components/AltButton'
import {  DropdownButton, MenuItem, ListGroup } from 'react-bootstrap';

const br_states = [
    "Acre",
    "Alagoas",
    "Amapá",
    "Amazonas",
    "Bahia",
    "Ceará",
    "Distritfo Federal",
    "Espírito Santo",
    "Goiás",
    "Maranhão",
    "Mato Grosso",
    "Mato Grosso do Sul",
    "Minas Gerais",
    "Pará",
    "Paraíba",
    "Paraná",
    "Pernambuco",
    "Piauí",
    "Rio de Janeiro",
    "Rio Grande do Norte",
    "Rio Grande do Sul",
    "Rondônia",
    "Roraima",
    "Santa Catarina",
    "São Paulo",
    "Sergipe",
    "Tocantins"
];

const FilterProjectsModal = ({ onClose, handleChange, filter, isVisible, pt }) => {
  const [state, setState] = React.useState();
  const [date, setDate] = React.useState();
  const [ibrd, setIbrd] = React.useState(true);
  const [ifc, setIfc] = React.useState(true);
  const [miga, setMiga] = React.useState(true);
  const [status, setStatus] = React.useState(1);

  const cleanFilters = () => {
    setState(undefined);
    setIbrd(true)
    setIfc(true)
    setMiga(true)
    setStatus(1)
    setDate('')
  }

  const handleFilterChange = () => {
    handleChange({
      status, ibrd, ifc, miga, state, date
    })
  };

  React.useEffect(() => {
    handleFilterChange();
  }, [status, ibrd, ifc, miga, state, date])
  const handleSearch = () => {
    onClose();
    filter();
  }

  return (
    <div className="filter-projects-modal" style={isVisible ? {} : {display: 'none'}}>
      <div className="filter-projects-modal-container">
        <div className="filter-projects-modal-header">
          <span className="filter-projects-modal-title">{ pt ? 'Filtrar' : 'Filter'}</span>
          <img alt="times" src={TimesIcon} onClick={onClose}  style={{ cursor: 'pointer' }} />
        </div>
        <div className="filter-projects-modal-content">
          <div style={{ display: 'flex', justifyContent: 'space-between' }} className='line'>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span className="filter-field-label">{pt ? 'Instituições' : 'Institutions'}:</span>
              <div className="checkbox-container" style={{ marginRight: '40px' }}>
                <input id="ibrd" type="checkbox" value="ibrd" name="ibrd" checked={ibrd} onChange={() => setIbrd(!ibrd)} />
                <label htmlFor="ibrd">IBRD</label>
              </div>
              <div className="checkbox-container" style={{ marginRight: '40px' }}>
                <input id="ifc" type="checkbox" value="ifc" name="ifc" checked={ifc} onChange={() => setIfc(!ifc)} />
                <label htmlFor="ifc">IFC</label>
              </div>
              <div className="checkbox-container">
                <input id="miga" type="checkbox" value="miga" name="miga" checked={miga} onChange={() => setMiga(!miga)} />
                <label htmlFor="miga">MIGA</label>
              </div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', width: '45%', justifyContent: 'left' }} className='date'>
              <span className="filter-field-label">{pt ? 'Data de aprovação' : 'Approved date'}: </span>
              <input type='date' className="filter-field-value input-date" value={date} onChange={(e) => setDate(e.target.value)} />

            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '32px' }} className='line'>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span className="filter-field-label">Status:</span>
              <div className="checkbox-container" style={{ marginRight: '40px' }}>
                <input id="all" type="radio" value="all" name="all" checked={status === 1} onClick={() => setStatus(1)}/>
                <label htmlFor="all">{ pt ? 'Todos' : 'All' }</label>
              </div>
              <div className="checkbox-container" style={{ marginRight: '40px' }}>
                <input id="active" type="radio" value="Active" name="active" checked={status === 2} onClick={() => setStatus(2)}/>
                <label htmlFor="active">{ pt ? 'Ativos' : 'Active'}</label>
              </div>
              <div className="checkbox-container">
                <input id="inactive" type="radio" value="Inactive" name="inactive" checked={status === 3} onClick={() => setStatus(3)}/>
                <label htmlFor="inactive">{ pt? 'Inativos' : 'Inactive' }</label>
              </div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', width: '45%', justifyContent: 'left' }} className='date'>
              <span className="filter-field-label">{pt ? 'Estado' : 'State'}: </span>
              <DropdownButton
                drop={'down'}
                title={state || 'Selecionar'}
                id={'state-selector'}
                key={'state-selector'}
                onSelect={(e) => setState(e)}
                style={{border: 'none'}}
                className='filter-field-value'
              >
                {br_states.map((state,index) => {
                    return <MenuItem key={state} eventKey={state}>{state}</MenuItem>
                })}
              </DropdownButton>
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '90px' }} className='button'>
            <AltButton inverse={true} style={{ fontSize: '18px' }} onClick={cleanFilters}>{ pt ? 'Limpar filtro' : 'Clean filter'}</AltButton>
            <AltButton style={{ fontSize: '18px'  }} onClick={handleSearch}>{ pt ? 'Aplicar filtro' : 'Apply filter'}</AltButton>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FilterProjectsModal
