import React, { useEffect, useState } from "react";

import { IMG_URL } from "../../../config/api";
import CloseIcon from '../../../icons/close-image.svg';

import './style.css';

const textPortuguese = {
    addButton: 'Adicione',
    description: ' a imagem aqui',
    preview: 'Previsão da imagem',
    remove: 'Remover imagem',
};

const textEnglish = {
    addButton: 'Add',
    description: ' an image here',
    preview: 'Image preview',
    remove: 'Remove image'
};

export const ImageInput = ({
    width,
    editImage = null,
    setEditImage = null,
    uploadFile,
    location,
    name,
    editMode = false,
}) => {
    const [imageServerPreview, setImageServerPreview] = useState(null);
    const [imagePreview, setImagePreview] = useState(null);
    const [text, setText] = useState(null);
    const inputRef = React.useRef(null);

    const onClickToAddImage = () => {
        inputRef.current?.click();
    }

    const handleUploadImage = (event) => {
        if (event.target.files?.length) {
            const file = event.target.files[0];
            if (!file || !file.type.includes("image")) {
                return;
            }

            const reader = new FileReader();

            reader.readAsDataURL(file);

            reader.addEventListener('load', () => {
                uploadFile(file);
                setImagePreview(reader.result);
            });
        }
    }

    const toDataUrl = (url) => {
        return fetch(url).then(response => response.blob())
            .then(blob => new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onloadend = () => resolve(reader.result);
                reader.onerror = reject;
                reader.readAsDataURL(blob);
            }))
    }

    const fetchImageAsFile = async (imageUrl) => {
        try {
            const response = await fetch(imageUrl, { headers: { 'Content-Type': 'image/png' } });
            const blob = await response.blob();

            // Create a File object from the Blob
            const file = new File([blob], name, { type: 'image' });
            uploadFile(file);
        } catch (error) {
            console.error('Error fetching image:', error);
        }
    };

    const dataURLToFile = (dataURL, fileName) => {
        const array = dataURL.split(',');
        const mime = array[0].match(/:(.*?);/)[1];
        const bstr = atob(array[1]);
        let n = bstr.length;
        const u8array = new Uint8Array(n);
        while (n--) {
            u8array[n] = bstr.charCodeAt(n);
        }
        return u8array;
    }

    const onRemoveImage = () => {
        setImagePreview(null);
        setImageServerPreview(null);
        if (setEditImage !== null) {
            setEditImage(null);
        }
        inputRef.current.value = '';
    }

    useEffect(() => {
        if (editMode && editImage) {
            setImageServerPreview(`${IMG_URL}${editImage}`);
        }
    }, [editMode, editImage]);

    useEffect(() => {
        if (imageServerPreview && editImage) {
            fetchImageAsFile(imageServerPreview);
            setImagePreview(imageServerPreview);
            // toDataUrl(imageServerPreview).then(dataURL => {
            //     const fileData = dataURLToFile(dataURL, editImage);
            //     uploadFile(fileData);
            //     setImagePreview(imageServerPreview);
            // });
        }
    }, [imageServerPreview, editImage])

    useEffect(() => {
        if (location.pathname.substring(0, 4) === '/pt/') {
            setText(textPortuguese);
        } else {
            setText(textEnglish);
        }
    }, []);

    return (
        <div className="image-upload-wrapper" style={{ width: width }}>
            <input
                type="file"
                ref={inputRef}
                accept="image/*"
                onChange={handleUploadImage}
            />
            {imagePreview && (
                <div className="image-preview-container">
                    <div className="image-preview-wrapper">
                        <img
                            src={imagePreview}
                            alt={text?.preview}
                            className="image-preview"
                        />
                        <span className="remove-image">
                            <img onClick={onRemoveImage} src={CloseIcon} alt={text?.remove} />
                        </span>
                    </div>
                    <p className="image-name">{name}</p>
                </div>
            )}
            { (!imagePreview && !imageServerPreview) && (
                <div className="description">
                    <a onClick={onClickToAddImage} className="upload-button">{text?.addButton}</a>
                    <span>{text?.description}</span>
                </div>
            ) }
        </div>
    );
}
