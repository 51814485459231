import React, { useState, useEffect } from 'react';

import CoinsIcon from './img/coins.svg'
import PureNaturalIcon from './img/pure-natural.svg'
import ChevronDownIcon from './img/chevron-down.svg'

import './styles.css';

const FocusAreaCard = ({ icon, children, title, text, ...props }) => {
  const [isVisible, setIsVisible] = useState(false);

  const handleModalVisibility = () => setIsVisible(!isVisible);

  useEffect(() => {
    console.log(icon)
  }, [])

  return (
    <>
      <div
        className="focus-area-card"
        target="_blank"
        rel="noreferrer"
        onClick={() => setIsVisible(!isVisible)}
      >
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', gap: '16px', alignItems: 'center' }}>
            {(icon === 'loop loop-financial-5') && <img src={CoinsIcon} alt="Coins" />}
            {(icon === 'loop loop-biodiversity') && <img src={PureNaturalIcon} alt="Pure Natural" />}
            <span>{children}</span>
          </div>
          <img alt="Chevron Down" className={isVisible ? 'icon-flipped' : ''} onClick={() => setIsVisible(!isVisible)} src={ChevronDownIcon} />
        </div>
        {isVisible && (
          <div>
            {
              text.map((txt, index) => {
                return (
                  <p key={index}> 
                    {txt}
                  </p>
                )
              })
            }
          </div>
        )}
      </div>
    </>
  );
};

export default FocusAreaCard;
