import axios from "axios";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { ProgressBar } from "react-bootstrap";

import "../impact/Impact.css";
import "../strategy/Strategy.css";
import SwiperMap from "./SwiperMap";
import classnames from "classnames";
import Gauge from "../common/graphs/Gauge";
import ImpactMap from "../impact/ImpactMap";
import Button from "../../components/Button";
import { API_URL } from "../../../config/api";
import AltCard from "../../components/AltCard";
import Navbar from "../common/navbar/NavbarPLR";
import BarGraph from "../common/graphs/BarGraph";
import WelcomeFooter from "../menu/WelcomeFooter";
import LineGraph from "../common/graphs/LineGraph";
import styles from "../../styles/impact.module.css";
import StrategyHeader from "../common/strategyheader";
import ChartLineIcon from "../impact/img/chart-line.svg";
import ImpactCover from "../impact/img/impact-cover.png";
import PureNaturalIcon from "../impact/img/pure-natural.svg";
import PaperMoneyTwoIcon from "../impact/img/paper-money-two.svg";

const textEnglish = {
  login: "Login",
  logout: "Log Out",
  med: "CPF Indicators",
  desc: "This page provides information about concrete results achieved under the three Country Partnership Framework (CPF) focus areas, measured by quantitative indicators",
  page_desc_1:
    "For a narrative on the World Bank Group’s engagement in Brazil, putting results in perspective, also visit our ",
  page_desc_link_text: "story map",
  page_desc_link: "/story",
  page_desc_2: ".",
  page_desc_2_asterisk:
    "*indicators that were added after the initial CPF have different baselines",
  page_desc_3:
    "Select a Focus Area and an Objective to explore our results below.",
  add: "Add Data",

  focus_icon_1: "Fiscal Consolidation Icon",
  focus_label_1: "Focus Area 1",
  focus_title_1:
    "Fiscal Consolidation and Government Effectiveness for Sustainable, Inclusive and Efficient Service Delivery",
  focus_icon_2: "Private Sector Investment Icon",
  focus_label_2: "Focus Area 2",
  focus_title_2: "Private Sector Investment and Productive Growth",
  focus_icon_3: "Inclusive and Sustainable Development Icon",
  focus_label_3: "Focus Area 3",
  focus_title_3: "Inclusive and Sustainable Development",
  ontrack: "On Track",
  notontrack: "Not On Track",
  objective: "Objective",
  indicator_label: "Indicator",
  baseline: "Baseline",
  progress: "Progress",
  target: "Target",
  toggle: "Change Status",
  toggle_progress: "Toggle Progress Gauge (Unavailable if Target TBD)",
  toggle_indicator: "Toggle Visibility of Indicator",
  none: "None",

  source: "Source",
  show_ontrack: "Turn On Ontrack Display",
  hide_ontrack: "Turn Off Ontrack Display",
  speedometer:
    "The speedometer shows how the indicator has progressed toward its goal since the baseline was established.",
  mapillary_1: "Click ",
  mapillary_2: "here",
  mapillary_3:
    " to explore ground-level user-submitted images and videos of Brazil's road conditions, provided by Mapillary (link will open a new tab outside the World Bank domain).",
  mapillary_link:
    "https://www.mapillary.com/app/?lat=-15.668370748887682&lng=-40.29616715476902&z=3.1194423285961776&focus=map",
  edit_button: "Edit page",
};

const textPortuguese = {
  login: "Login",
  logout: "Log Out",
  med: "Indicadores de resultados do CPF",
  desc: "Esta página fornece informações sobre resultados concretos alcançados nas três áreas de enfoque da Estrutura de Parceria com Países (CPF), medidas por indicadores quantitativos",
  page_desc_1:
    "Para uma narrativa sobre o envolvimento do Grupo Banco Mundial no Brasil, colocando os resultados em perspectiva, visite também nossa ",
  page_desc_link_text: "estratégia",
  page_desc_link: "/pt/story",
  page_desc_2: ".",
  page_desc_2_asterisk:
    "*Indicadores que foram adicionados depois do lançamento da CPF têm linhas de base diferentes.",
  page_desc_3:
    "Selecione uma Área de Foco e um Objetivo para explorar os nossos resultados abaixo.",
  add: "Add Data",

  focus_icon_1: "Consolidação Fiscal Icon",
  focus_label_1: "Área de Foco 1",
  focus_title_1:
    "Consolidação fiscal e eficácia do governo para prestação de serviços sustentável, inclusiva e eficiente",
  focus_icon_2: "Investimento do setor privado e crescimento produtivo",
  focus_label_2: "Área de Foco 2",
  focus_title_2: "Investimento do Setor Privado",
  focus_icon_3: "Desenvolvimento Sustentável e Inclusivo Icon",
  focus_label_3: "Área de Foco 3",
  focus_title_3: "Desenvolvimento Sustentável e Inclusivo",
  ontrack: "Em dia",
  notontrack: "Atrasado",
  objective: "Objetivo",
  indicator_label: "Indicador",
  baseline: "Linha de base",
  progress: "Progresso",
  target: "Meta",
  toggle: "Change Status",
  toggle_progress: "Toggle Progress Gauge (Unavailable if Target TBD)",
  toggle_indicator: "Toggle Visibility of Indicator",
  none: "Nenhum",

  source: "Fonte",
  show_ontrack: "Turn On Ontrack Display",
  hide_ontrack: "Turn Off Ontrack Display",
  speedometer:
    "O velocímetro mostra como o indicador progrediu em direção à sua meta desde que a linha de base foi estabelecida.",
  mapillary_1: "Click ",
  mapillary_2: "here",
  mapillary_3:
    " para explorar imagens e vídeos enviados pelo usuário no nível do solo das condições das estradas no Brasil, fornecidos pela Mapillary (o link abrirá uma nova guia fora do domínio do Banco Mundial).",
  mapillary_link:
    "https://www.mapillary.com/app/?lat=-15.668370748887682&lng=-40.29616715476902&z=3.1194423285961776&focus=map",
  edit_button: "Editar página",
};

class Impact extends Component {
  constructor(props) {
    super(props);

    let objective = "1.1";
    if (props.location) {
      if (props.location.state) {
        if (props.location.state.objective) {
          objective = props.location.state.objective;
        }
      }
    }

    const selected = localStorage.getItem("strategy_focus_area") ?? "focus_1";

    if (selected === "focus_1") {
      objective = "1.1";
    } else if (selected === "focus_2") {
      objective = "2.1";
    } else if (selected === "focus_3") {
      objective = "3.1";
    }

    this.state = {
      selected: selected,
      objective: objective,
      leftVisible: false,
      rightVisible: true,
      objectives_data: null,
      showAddData: false,
      showStatus: false,
    };

    this.handleLogout = this.handleLogout.bind(this);
    this.selectFocus = this.selectFocus.bind(this);
    this.selectObjective = this.selectObjective.bind(this);
    this.scrollLeft = this.scrollLeft.bind(this);
    this.scrollRight = this.scrollRight.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.toggleObjectiveOntrack = this.toggleObjectiveOntrack.bind(this);
    this.toggleIndicatorOntrack = this.toggleIndicatorOntrack.bind(this);
    this.toggleIndicatorVisible = this.toggleIndicatorVisible.bind(this);
    this.toggleShowOntrack = this.toggleShowOntrack.bind(this);
    this.toggleIndicatorShowProgress = this.toggleIndicatorShowProgress.bind(this);
    this.toEditScreen = this.toEditScreen.bind(this);
    this.sendData = this.sendData.bind(this);
    this.deleteData = this.deleteData.bind(this);
  }

  toEditScreen() {
    const prepend = this.props.location?.pathname.substring(0, 4) === "/pt/" ? "/pt" : "";
    this.props.history.push(prepend + "/edit-screen/indicators");
  }

  handleLogout() {
    localStorage.removeItem("auth");
    this.props.history.push("/");
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.loadIndicators();
  }

  loadIndicators() {
    axios
    .get(`${API_URL}/indicators`)
    .then((response) => {
      this.setState({
        objectives_data: response.data,
      });
    })
    .catch((err) => {});
  }

  toggleObjectiveOntrack(id, bool) {
    axios
      .post(`${API_URL}/objectives/setontrack?id=${id}&bool=${bool}`)
      .then((done) => {
        this.loadIndicators();
      })
      .catch((err) => {});
  }

  toggleIndicatorOntrack(id, bool) {
    axios
      .post(`${API_URL}/indicators/setontrack?id=${id}&bool=${bool}`)
      .then((done) => {
        this.loadIndicators();
      })
      .catch((err) => {});
  }

  toggleIndicatorVisible(id, bool) {
    axios
      .post(`${API_URL}/indicators/showindicator?id=${id}&bool=${bool}`)
      .then((done) => {
        this.loadIndicators();
      })
      .catch((err) => {});
  }

  toggleShowOntrack(bool) {
    axios
      .post(`${API_URL}/objectives/showontrack?bool=${bool}`)
      .then((done) => {
        this.loadIndicators();
      })
      .catch((err) => {});
  }

  toggleIndicatorShowProgress(id, bool) {
    axios
      .post(`${API_URL}/indicators/setshowprogress?id=${id}&bool=${bool}`)
      .then((done) => {
        this.loadIndicators();
      })
      .catch((err) => {});
  }

  sendData(id, year, month, value, ontrack, category) {
    axios
      .post(
        `${API_URL}/indicators/add_data?id=${id}&year=${year}&month=${month}&value=${value}&ontrack=${ontrack}&category=${category}`
      )
      .then((done) => {
        this.loadIndicators();
      })
      .catch((err) => {});
  }

  deleteData(id, year, month, category) {
    axios
      .post(
        `${API_URL}/indicators/remove_data?id=${id}&year=${year}&month=${month}&category=${category}`
      )
      .then((done) => {
        this.loadIndicators();
      })
      .catch((err) => {});
  }

  selectFocus(selection) {
    let current = this.state.selected;
    let objective = null;

    if (selection === current) {
      selection = null;
    }

    if (selection === "focus_1") {
      objective = "1.1";
    } else if (selection === "focus_2") {
      objective = "2.1";
    } else if (selection === "focus_3") {
      objective = "3.1";
    }

    localStorage.setItem("strategy_focus_area", selection);

    this.setState(
      {
        selected: selection,
        objective: objective,
      },
      () => {}
    );
  }

  selectObjective(key) {
    this.setState({ objective: key }, () => {});
  }

  scrollLeft() {
    let scrollbar = this.refs.scrollbars;
    scrollbar.scrollLeft(
      scrollbar.getScrollLeft() - scrollbar.getClientWidth() / 4 + 1
    );
  }

  scrollRight() {
    let scrollbar = this.refs.scrollbars;
    scrollbar.scrollLeft(
      scrollbar.getScrollLeft() + scrollbar.getClientWidth() / 4
    );
  }

  handleScroll() {
    let scrollbar = this.refs.scrollbars;
    let left = scrollbar.getScrollLeft() + scrollbar.getClientWidth();
    let max = scrollbar.getScrollWidth();
    if (scrollbar.getScrollLeft() > 0) {
      //left visible
      if (left >= max) {
        //right hidden
        this.setState({ rightVisible: false, leftVisible: true });
      } else {
        //right visible
        this.setState({ rightVisible: true, leftVisible: true });
      }
    } else {
      //left hidden
      if (left >= max) {
        //right hidden
        this.setState({ rightVisible: false, leftVisible: false });
      } else {
        //right visible
        this.setState({ rightVisible: true, leftVisible: false });
      }
    }
  }

  render() {
    let pt = false;
    let text = {};

    if (this.props.location.pathname.substring(0, 4) !== "/pt/") {
      text = textEnglish;
    } else {
      text = textPortuguese;
      pt = true;
    }

    let active_focus_objectives = {};
    let showontrack = false;

    if (this.state.objectives_data) {
      let keys = Object.keys(this.state.objectives_data);

      if (this.state.objectives_data[keys[0]].ontrack_visible) {
        showontrack = this.state.objectives_data[keys[0]].ontrack_visible;
      }

      if (this.state.selected === "focus_1") {
        for (let i = 0; i < keys.length; i++) {
          if (keys[i].startsWith("1.")) {
            active_focus_objectives[keys[i]] =
              this.state.objectives_data[keys[i]];
          }
        }
      } else if (this.state.selected === "focus_2") {
        for (let i = 0; i < keys.length; i++) {
          if (keys[i].startsWith("2.")) {
            active_focus_objectives[keys[i]] =
              this.state.objectives_data[keys[i]];
          }
        }
      } else if (this.state.selected === "focus_3") {
        for (let i = 0; i < keys.length; i++) {
          if (keys[i].startsWith("3.")) {
            active_focus_objectives[keys[i]] =
              this.state.objectives_data[keys[i]];
          }
        }
      }
    }

    return (
      <div>
        <div className="container-fluid unpadify">
          <Navbar
            handleLogin={this.handleLogin}
            handleLogout={this.handleLogout}
            auth={this.props.auth}
            isLoggedIn={this.props.loggedIn}
            checkIsLoggedIn={this.props.checkIsLoggedIn}
            onChangeLoginState={this.props.onChangeLoginState}
            {...this.props}
            currentPage="impact"
          />
          <StrategyHeader
            title={pt ? "Impacto" : "Impact"}
            selectedTab={0}
            tabs={[
              {
                link: pt ? "/pt/indicators" : "/indicators",
                label: pt ? "Indicadores de resultado" : "Result indicators",
              },
              {
                link: pt ? "/pt/insights" : "/insights",
                label: pt ? "Panorama online" : "Online Media Snapshot",
              },
            ]}
            backgroundImage={ImpactCover}
            backgroundPosition="0px 25%"
          />
          {this.state.objectives_data && (
            <div id="impact-container" className="impact-page">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <h2>
                  {pt
                    ? "Indicadores de resultados do CPF"
                    : "Result indicators"}
                </h2>
                {this.props.checkIsLoggedIn() ? (
                  <div
                    style={{
                      height: "100%",
                      display: "flex",
                    }}
                  >
                    <button
                      onClick={() =>
                        this.setState({
                          showStatus: !this.state.showStatus,
                        })
                      }
                      style={{
                        all: "unset",
                        height: "48px",
                        padding: "0 20px",
                        border: "1px solid #009FDA",
                        marginRight: "15px",
                        color: "#009FDA",
                        boxShadow: "none",
                        cursor: "pointer",
                      }}
                    >
                      {`${
                        !this.state.showStatus
                          ? pt
                            ? "Exibir"
                            : "Show"
                          : pt
                          ? "Esconder"
                          : "Hide"
                      }`}
                      {pt ? " status" : "state"}
                    </button>
                    <Button onClick={this.toEditScreen}>
                      {pt
                        ? textPortuguese.edit_button
                        : textEnglish.edit_button}
                    </Button>
                  </div>
                ) : (
                  <></>
                )}
              </div>

              <div className="divider"></div>
              {false ? (
                <div className="row">
                  <div className="col-sm-8" id="impact-summary">
                    <span>{text.page_desc_1}</span>
                    <span>
                      <a href={text.page_desc_link}>
                        {text.page_desc_link_text}
                      </a>
                    </span>
                    <span>{text.page_desc_2}</span>
                    <div>{text.page_desc_3}</div>
                  </div>
                  <div className="col-sm-4">
                    <div
                      className="btn impact-add-data-button"
                      onClick={() => this.toggleShowOntrack(!showontrack)}
                    >
                      {showontrack ? text.hide_ontrack : text.show_ontrack}
                    </div>
                  </div>
                </div>
              ) : (
                <div id="impact-summary" style={{ textAlign: "start" }}>
                  <h3>{text.desc}</h3>
                  <span>
                    {text.page_desc_1}{" "}
                    <a href={text.page_desc_link}>{text.page_desc_link_text}</a>
                    {text.page_desc_2}
                  </span>
                  <span>{text.page_desc_3}</span>
                </div>
              )}
              <div className="focus-areas-cards">
                <AltCard
                  icon={PaperMoneyTwoIcon}
                  title={text.focus_label_1}
                  description={text.focus_title_1}
                  inverse={this.state.selected === "focus_1"}
                  onClick={() => this.selectFocus("focus_1")}
                  style={{ cursor: "pointer" }}
                  showButton={false}
                />
                <AltCard
                  icon={ChartLineIcon}
                  title={text.focus_label_2}
                  description={text.focus_title_2}
                  buttonText="Veja o programa"
                  inverse={this.state.selected === "focus_2"}
                  onClick={() => this.selectFocus("focus_2")}
                  style={{ cursor: "pointer" }}
                  showButton={false}
                />
                <AltCard
                  icon={PureNaturalIcon}
                  title={text.focus_label_3}
                  description={text.focus_title_3}
                  buttonText="Veja o programa"
                  inverse={this.state.selected === "focus_3"}
                  onClick={() => this.selectFocus("focus_3")}
                  style={{ cursor: "pointer" }}
                  showButton={false}
                />
              </div>
              <div>
                <div className="impact-objectives-tab">
                  {this.state.selected &&
                    Object.keys(active_focus_objectives).map((key, index) => {
                      let objective = active_focus_objectives[key];
                      return (
                        <div
                          key={key}
                          className={
                            this.state.objective === key
                              ? "objective-tab active"
                              : "objective-tab"
                          }
                          onClick={() => this.selectObjective(key)}
                        >
                          {text.objective} {key}
                        </div>
                      );
                    })}
                </div>
              </div>
              {this.state.selected && (
                <div>
                  <div className="impact-indicator-text">
                    <h4>
                      {pt
                        ? active_focus_objectives[this.state.objective]
                            ?.title_pt ?? ""
                        : active_focus_objectives[this.state.objective]
                            ?.title ?? ""}
                    </h4>
                    <span>
                      {pt
                        ? active_focus_objectives[this.state.objective]
                            ?.description_pt ?? ""
                        : active_focus_objectives[this.state.objective]
                            ?.description ?? ""}
                    </span>
                  </div>
                  <div className="col-sm-12">
                    {active_focus_objectives[this.state.objective] &&
                      active_focus_objectives[
                        this.state.objective
                      ].indicators.map((item, index) => {
                        if (!item.indicator_visible) {
                          if (this.props.checkIsLoggedIn()) {
                            return (
                              <div key={index}>
                                <div className="impact-indicator-header">
                                  <span className="impact-indicator-code">
                                    {text.indicator_label} {item.indicator_id}{" "}
                                  </span>
                                  <i
                                    className="fa fa-toggle-on toggle-ontrack"
                                    style={{
                                      display: this.state.showStatus
                                        ? "block"
                                        : "none",
                                    }}
                                    onClick={() =>
                                      this.toggleIndicatorVisible(
                                        item.indicator_id,
                                        !item.indicator_visible
                                      )
                                    }
                                    title={text.toggle_indicator}
                                  />
                                </div>

                                {/*  */}
                                {index <
                                  active_focus_objectives[this.state.objective]
                                    .indicators.length -
                                    1 && (
                                  <div className="impact-horizontal-line" />
                                )}
                                {/*  */}
                              </div>
                            );
                          }
                        } else {
                          let graph_data = null;
                          item.graph_data.sort(function (a, b) {
                            if (a.month < b.month) return -1;
                            if (a.month > b.month) return 1;
                            return 0;
                          });
                          item.graph_data.sort(function (a, b) {
                            if (a.year < b.year) return -1;
                            if (a.year > b.year) return 1;
                            return 0;
                          });
                          for (let i = 0; i < item.graph_data.length; i++) {
                            let data_point = item.graph_data[i];
                            if (data_point) {
                              if (data_point.category === "Main") {
                                if (graph_data === null) {
                                  graph_data = [];
                                }
                                graph_data.push(data_point);
                              } else {
                                if (graph_data === null) {
                                  graph_data = {};
                                }
                                let category = pt
                                  ? data_point.category_pt
                                  : data_point.category;
                                if (
                                  Object.keys(graph_data).indexOf(category) < 0
                                ) {
                                  graph_data[category] = [];
                                  graph_data[category].push(data_point);
                                } else {
                                  graph_data[category].push(data_point);
                                }
                              }
                            }
                          }
                          //time for a lot of data processing because we've got a lot of indicators and a lot have variations that we have to account for
                          //sorry for the mess but it has to be done
                          let target_data = null;
                          let targets = {};
                          let display_unit = pt
                            ? item.unit_pt
                                .replace("Número de ", "")
                                .replace("N° de ", "")
                            : item.unit.replace("# of ", "");
                          if (
                            display_unit === "Idade" ||
                            display_unit === "Diferença de idade (em anos)"
                          ) {
                            display_unit = "Anos";
                          }
                          if (
                            display_unit === "Age" ||
                            display_unit === "Difference in Age (in years)"
                          ) {
                            display_unit = "Years";
                          }
                          if (display_unit === "Sectors") {
                            display_unit = "Sector(s)";
                          }
                          if (display_unit === "Setores") {
                            display_unit = "Setor(es)";
                          }
                          if (display_unit === "Mechanisms") {
                            display_unit = "Mechanism(s)";
                          }
                          if (display_unit === "Mecanismos") {
                            display_unit = "Mecanismo(s)";
                          }
                          for (let i = 0; i < item.targets.length; i++) {
                            let data_point = item.targets[i];
                            if (data_point) {
                              if (data_point.category === "Main") {
                                target_data = data_point;
                                targets["Main"] =
                                  text.target +
                                  " (" +
                                  data_point.year +
                                  "): " +
                                  (data_point.value
                                    ? data_point.value.toLocaleString(
                                        pt ? "pt-BR" : "en-US"
                                      )
                                    : pt
                                    ? "a definir"
                                    : "TBD") +
                                  " " +
                                  (data_point.value ? display_unit : "");
                                if (
                                  display_unit === "Nota" ||
                                  display_unit === "Função Custo" ||
                                  display_unit === "R$" ||
                                  display_unit === "US$"
                                ) {
                                  //gotta swap the order
                                  targets["Main"] =
                                    text.target +
                                    " (" +
                                    data_point.year +
                                    "): " +
                                    (data_point.value ? display_unit : "") +
                                    " " +
                                    (data_point.value
                                      ? data_point.value.toLocaleString(
                                          pt ? "pt-BR" : "en-US"
                                        )
                                      : pt
                                      ? "a definir"
                                      : "TBD");
                                }
                              } else {
                                if (!target_data) {
                                  target_data = {};
                                }
                                let category = pt
                                  ? data_point.category_pt
                                  : data_point.category;
                                target_data[category] = data_point;
                                targets[category] =
                                  text.target +
                                  " (" +
                                  data_point.year +
                                  "): " +
                                  (data_point.value
                                    ? data_point.value.toLocaleString(
                                        pt ? "pt-BR" : "en-US"
                                      )
                                    : pt
                                    ? "a definir"
                                    : "TBD") +
                                  " " +
                                  (item.type === "dual_scale"
                                    ? category
                                    : display_unit);
                                if (
                                  display_unit === "Nota" ||
                                  display_unit === "Função Custo" ||
                                  display_unit === "R$" ||
                                  display_unit === "US$"
                                ) {
                                  //gotta swap the order
                                  targets[category] =
                                    text.target +
                                    " (" +
                                    data_point.year +
                                    "): " +
                                    (item.type === "dual_scale"
                                      ? category
                                      : display_unit) +
                                    " " +
                                    (data_point.value
                                      ? data_point.value.toLocaleString(
                                          pt ? "pt-BR" : "en-US"
                                        )
                                      : pt
                                      ? "a definir"
                                      : "TBD");
                                }
                              }
                            }
                          }

                          let baselines = {};
                          let progress = {};
                          let percentage = null;
                          if (graph_data) {
                            if (Array.isArray(graph_data)) {
                              if (graph_data.length === 1) {
                                baselines["Main"] =
                                  text.baseline +
                                  " (" +
                                  graph_data[0].year +
                                  "): " +
                                  graph_data[0].value.toLocaleString(
                                    pt ? "pt-BR" : "en-US"
                                  ) +
                                  " " +
                                  display_unit;
                                percentage = 0;
                                if (
                                  display_unit === "Nota" ||
                                  display_unit === "Função Custo" ||
                                  display_unit === "R$" ||
                                  display_unit === "US$"
                                ) {
                                  //gotta swap the order
                                  baselines["Main"] =
                                    text.baseline +
                                    " (" +
                                    graph_data[0].year +
                                    "): " +
                                    display_unit +
                                    " " +
                                    graph_data[0].value.toLocaleString(
                                      pt ? "pt-BR" : "en-US"
                                    );
                                }
                              } else {
                                baselines["Main"] =
                                  text.baseline +
                                  " (" +
                                  graph_data[0].year +
                                  "): " +
                                  graph_data[0].value.toLocaleString(
                                    pt ? "pt-BR" : "en-US"
                                  ) +
                                  " " +
                                  display_unit;
                                progress["Main"] =
                                  text.progress +
                                  " (" +
                                  graph_data[graph_data.length - 1].year +
                                  "): " +
                                  graph_data[
                                    graph_data.length - 1
                                  ].value.toLocaleString(
                                    pt ? "pt-BR" : "en-US"
                                  ) +
                                  " " +
                                  display_unit;
                                let change =
                                  graph_data[graph_data.length - 1].value -
                                  graph_data[0].value;
                                let targetVBaseline =
                                  item.targets[0].value - graph_data[0].value;
                                if (targetVBaseline === 0) {
                                  percentage = 0;
                                } else {
                                  percentage = change / targetVBaseline;
                                }
                                if (
                                  display_unit === "Nota" ||
                                  display_unit === "Função Custo" ||
                                  display_unit === "R$" ||
                                  display_unit === "US$"
                                ) {
                                  //gotta swap the order
                                  baselines["Main"] =
                                    text.baseline +
                                    " (" +
                                    graph_data[0].year +
                                    "): " +
                                    display_unit +
                                    " " +
                                    graph_data[0].value.toLocaleString(
                                      pt ? "pt-BR" : "en-US"
                                    );
                                  progress["Main"] =
                                    text.progress +
                                    " (" +
                                    graph_data[graph_data.length - 1].year +
                                    "): " +
                                    display_unit +
                                    " " +
                                    graph_data[
                                      graph_data.length - 1
                                    ].value.toLocaleString(
                                      pt ? "pt-BR" : "en-US"
                                    );
                                }
                                // // console.log(item.indicator_id, change, targetVBaseline, percentage);
                              }
                            } else {
                              for (
                                let i = 0;
                                i < Object.keys(graph_data).length;
                                i++
                              ) {
                                let key = Object.keys(graph_data)[i];
                                if (graph_data[key].length === 1) {
                                  baselines[key] =
                                    text.baseline +
                                    " (" +
                                    graph_data[key][0].year +
                                    "): " +
                                    graph_data[key][0].value.toLocaleString(
                                      pt ? "pt-BR" : "en-US"
                                    ) +
                                    " " +
                                    display_unit;
                                  if (
                                    display_unit === "Nota" ||
                                    display_unit === "Função Custo" ||
                                    display_unit === "R$" ||
                                    display_unit === "US$"
                                  ) {
                                    //gotta swap the order
                                    baselines[key] =
                                      text.baseline +
                                      " (" +
                                      graph_data[key][0].year +
                                      "): " +
                                      display_unit +
                                      " " +
                                      graph_data[key][0].value.toLocaleString(
                                        pt ? "pt-BR" : "en-US"
                                      );
                                  }
                                } else {
                                  baselines[key] =
                                    text.baseline +
                                    " (" +
                                    graph_data[key][0].year +
                                    "): " +
                                    graph_data[key][0].value.toLocaleString(
                                      pt ? "pt-BR" : "en-US"
                                    ) +
                                    " " +
                                    (item.type === "dual_scale"
                                      ? key
                                      : display_unit);
                                  progress[key] =
                                    text.progress +
                                    " (" +
                                    graph_data[key][graph_data[key].length - 1]
                                      .year +
                                    "): " +
                                    graph_data[key][
                                      graph_data[key].length - 1
                                    ].value.toLocaleString(
                                      pt ? "pt-BR" : "en-US"
                                    ) +
                                    " " +
                                    (item.type === "dual_scale"
                                      ? key
                                      : display_unit);
                                  if (
                                    display_unit === "Nota" ||
                                    display_unit === "Função Custo" ||
                                    display_unit === "R$" ||
                                    display_unit === "US$"
                                  ) {
                                    //gotta swap the order
                                    baselines[key] =
                                      text.baseline +
                                      " (" +
                                      graph_data[key][0].year +
                                      "): " +
                                      (item.type === "dual_scale"
                                        ? key
                                        : display_unit) +
                                      " " +
                                      graph_data[key][0].value.toLocaleString(
                                        pt ? "pt-BR" : "en-US"
                                      );
                                    progress[key] =
                                      text.progress +
                                      " (" +
                                      graph_data[key][
                                        graph_data[key].length - 1
                                      ].year +
                                      "): " +
                                      (item.type === "dual_scale"
                                        ? key
                                        : display_unit) +
                                      " " +
                                      graph_data[key][
                                        graph_data[key].length - 1
                                      ].value.toLocaleString(
                                        pt ? "pt-BR" : "en-US"
                                      );
                                  }
                                }
                              }
                            }
                          }

                          if (
                            item.targets[0].value === null ||
                            !item.show_progress
                          ) {
                            percentage = null;
                          }

                          let list = null;

                          let states_highrank_list_baseline = [];
                          let bank_states_highrank_list_baseline = [];
                          let states_highrank_list = [];
                          let bank_states_highrank_list = [];
                          let baseline_year = null;
                          let current_year = null;
                          if (item.maps && item.maps[0]) {
                            let maps_data = {}; //format data
                            for (let i = 0; i < item.maps.length; i++) {
                              if (maps_data[item.maps[i].year] === undefined) {
                                maps_data[item.maps[i].year] = [];
                                maps_data[item.maps[i].year].push(item.maps[i]);
                              } else {
                                maps_data[item.maps[i].year].push(item.maps[i]);
                              }
                            }

                            if (Object.keys(maps_data).length > 1) {
                              let order = Object.keys(maps_data);
                              order.sort(function (a, b) {
                                //make sure keys are sorted
                                if (parseInt(a) < parseInt(b)) return -1;
                                if (parseInt(a) > parseInt(b)) return 1;
                                return 0;
                              });
                              let baseline_map_data = maps_data[order[0]];
                              for (
                                let i = 0;
                                i < baseline_map_data.length;
                                i++
                              ) {
                                if (baseline_map_data[i].value <= "B") {
                                  if (baseline_map_data[i].bank_supported) {
                                    bank_states_highrank_list_baseline.push(
                                      baseline_map_data[i]
                                    );
                                  }
                                  states_highrank_list_baseline.push(
                                    baseline_map_data[i]
                                  );
                                }
                              }
                              baseline_year = order[0];
                              let current_map_data =
                                maps_data[order[order.length - 1]];
                              for (
                                let i = 0;
                                i < current_map_data.length;
                                i++
                              ) {
                                if (current_map_data[i].value <= "B") {
                                  if (current_map_data[i].bank_supported) {
                                    bank_states_highrank_list.push(
                                      current_map_data[i]
                                    );
                                  }
                                  states_highrank_list.push(
                                    current_map_data[i]
                                  );
                                }
                              }
                              current_year = order[order.length - 1];
                            } else {
                              let order = Object.keys(maps_data);
                              list = {};
                              list.title = pt
                                ? item.unit_pt
                                    .replace("Número de ", "")
                                    .replace("N° de ", "")
                                : item.unit.replace("# of ", "");
                              list.list = [];
                              for (
                                let i = 0;
                                i < maps_data[order[0]].length;
                                i++
                              ) {
                                if (maps_data[order[0]][i].value <= "B") {
                                  if (maps_data[order[0]][i].bank_supported) {
                                    bank_states_highrank_list.push(
                                      maps_data[order[0]][i]
                                    );
                                  }
                                  states_highrank_list.push(
                                    maps_data[order[0]][i]
                                  );
                                  list.list.push(
                                    maps_data[order[0]][i].state_name
                                  );
                                }
                              }
                              current_year = order[0];
                            }
                            if (baseline_year) {
                              baselines["Main"] =
                                text.baseline +
                                " (" +
                                baseline_year +
                                "): " +
                                bank_states_highrank_list_baseline.length +
                                " " +
                                display_unit;
                            }
                            if (current_year) {
                              progress["Main"] =
                                text.progress +
                                " (" +
                                current_year +
                                "): " +
                                bank_states_highrank_list.length +
                                " " +
                                display_unit;
                            }
                          }

                          if (item.lists && item.lists[0]) {
                            list = item.lists[0];
                          }

                          const indicatorsWithoutHorizontalLine = [
                            "2.1",
                            "4.1",
                            "6.2",
                            "8",
                            "9.1",
                            "11",
                            "13.1",
                            "15.1",
                            "16.1",
                            "17",
                            "17.1",
                            "17.2",
                            "17.5",
                            "18.1",
                            "19.1",
                            "19.3",
                            "19.4",
                            "19.5",
                            "20",
                          ];

                          return (
                            <div
                              key={item.indicator_id}
                              className={
                                item.indicator_id.indexOf(".") > -1
                                  ? "row impact-indicator-section supplementary"
                                  : "row impact-indicator-section"
                              }
                              id={
                                "indicator-" +
                                this.state.objective.replace(".", "_") +
                                "-" +
                                index
                              }
                            >
                              {showontrack ? (
                                <div className="impact-indicator-header">
                                  <span className="impact-indicator-code">
                                    {text.indicator_label} {item.indicator_id}{" "}
                                  </span>

                                  {/*  */}
                                  {this.props.checkIsLoggedIn() && (
                                    <i
                                      className="fa fa-toggle-on toggle-ontrack"
                                      style={{
                                        display: this.state.showStatus
                                          ? "block"
                                          : "none",
                                      }}
                                      onClick={() =>
                                        this.toggleIndicatorVisible(
                                          item.indicator_id,
                                          !item.indicator_visible
                                        )
                                      }
                                      title={text.toggle_indicator}
                                    />
                                  )}
                                  {/*  */}

                                  <span
                                    className={
                                      item.ontrack
                                        ? "impact-ontrack-indicator"
                                        : "impact-ontrack-indicator"
                                    }
                                  >
                                    <span className="circle"></span>
                                    {item.ontrack
                                      ? text.ontrack
                                      : text.notontrack}
                                  </span>

                                  {/*  */}
                                  {this.props.checkIsLoggedIn() && (
                                    <i
                                      className="fa fa-toggle-on toggle-ontrack"
                                      style={{
                                        display: this.state.showStatus
                                          ? "block"
                                          : "none",
                                      }}
                                      onClick={() =>
                                        this.toggleIndicatorOntrack(
                                          item.indicator_id,
                                          !item.ontrack
                                        )
                                      }
                                      title={text.toggle}
                                    />
                                  )}
                                  {/*  */}
                                </div>
                              ) : (
                                <div className="impact-indicator-header">
                                  <span className="impact-indicator-code">
                                    {text.indicator_label} {item.indicator_id}{" "}
                                  </span>
                                  {/*  */}
                                  {this.props.checkIsLoggedIn() && (
                                    <i
                                      className="fa fa-toggle-on toggle-ontrack"
                                      style={{
                                        display: this.state.showStatus
                                          ? "block"
                                          : "none",
                                      }}
                                      onClick={() =>
                                        this.toggleIndicatorVisible(
                                          item.indicator_id,
                                          !item.indicator_visible
                                        )
                                      }
                                      title={text.toggle_indicator}
                                    />
                                  )}
                                </div>
                              )}
                              {percentage ? (
                                <div>
                                  <div>
                                    <h5 className="indicator-title">
                                      {pt ? item.title_pt : item.title}
                                    </h5>
                                  </div>
                                </div>
                              ) : (
                                <div>
                                  <div>
                                    <h5 className="indicator-title">
                                      {pt ? item.title_pt : item.title}
                                    </h5>
                                    {this.props.checkIsLoggedIn() && (
                                      <i
                                        className="fa fa-toggle-on toggle-ontrack"
                                        style={{
                                          display: this.state.showStatus
                                            ? "block"
                                            : "none",
                                        }}
                                        onClick={() =>
                                          this.toggleIndicatorShowProgress(
                                            item.indicator_id,
                                            !item.show_progress
                                          )
                                        }
                                        title={text.toggle_progress}
                                      />
                                    )}
                                  </div>
                                </div>
                              )}
                              <div
                                className={classnames(
                                  styles.row,
                                  styles.rowCenter
                                )}
                              >
                                {Object.keys(targets).map((key, index) => {
                                  return (
                                    <div className={styles.column} key={index}>
                                      <p className={styles.columnItemTitle}>
                                        {key !== "Main" ? key + ": " : ""}
                                      </p>
                                      {Object.keys(baselines).length > 0 && (
                                        <p
                                          className={classnames(
                                            styles.columnItem,
                                            "impact-indicator-baseline"
                                          )}
                                        >
                                          {baselines[key]}
                                        </p>
                                      )}
                                      {Object.keys(progress).length > 0 && (
                                        <p
                                          className={classnames(
                                            styles.columnItem,
                                            "impact-indicator-progress"
                                          )}
                                        >
                                          {progress[key]}
                                        </p>
                                      )}
                                      <p
                                        className={classnames(
                                          styles.columnItem,
                                          "impact-indicator-target"
                                        )}
                                      >
                                        {targets[key]}
                                      </p>
                                    </div>
                                  );
                                })}

                                {/* Speedometer */}
                                <div
                                  className="col-lg-6"
                                  title={text.speedometer}
                                >
                                  <Gauge
                                    className="gauge"
                                    title={text.speedometer}
                                    data={{ percent: percentage }}
                                  />
                                  {this.props.checkIsLoggedIn() && (
                                    <i
                                      className="fa fa-toggle-on toggle-ontrack"
                                      style={{
                                        display: this.state.showStatus
                                          ? "block"
                                          : "none",
                                      }}
                                      onClick={() =>
                                        this.toggleIndicatorShowProgress(
                                          item.indicator_id,
                                          !item.show_progress
                                        )
                                      }
                                      title={text.toggle_progress}
                                    />
                                  )}
                                </div>
                              </div>
                              <div className={styles.row}>
                                <div className="impact-indicator-description">
                                  {pt ? item.description_pt : item.description}
                                </div>
                                {item.graphic && (
                                  <div className="row col-sm-6 impact-indicator-data">
                                    <img alt="Graphic" src={item.graphic} />
                                  </div>
                                )}
                              </div>

                              {/* Inclusao do grafico de linhas do indicador 1.1 */}
                              {item.indicator_id === "1.1" ||
                              item.indicator_id === "9.1" ? (
                                <>
                                  <div className="col-lg-12 impact-indicator-data">
                                    <div className="col-lg-8 col-lg-offset-2 col-sm-12">
                                      <LineGraph
                                        pt={pt}
                                        id={
                                          "line-graph-" +
                                          this.state.objective.replace(
                                            ".",
                                            "_"
                                          ) +
                                          "-" +
                                          index
                                        }
                                        trend={"plr"}
                                        data={graph_data}
                                        target={target_data.value}
                                        target_year={target_data.year}
                                        unit={pt ? item.unit_pt : item.unit}
                                        isPercentage={false}
                                        isArea={false}
                                      />
                                    </div>
                                  </div>
                                  {item.source_name && item.source_link && (
                                    <div
                                      className={
                                        item.indicator_id.indexOf(".") > -1
                                          ? "col-sm-12 impact-source-link supplementary"
                                          : "col-sm-12 impact-source-link"
                                      }
                                    >
                                      {text.source}:{" "}
                                      <a
                                        href={item.source_link}
                                        target="_blank"
                                      >
                                        {pt
                                          ? item.source_name_pt
                                          : item.source_name}
                                      </a>
                                    </div>
                                  )}
                                  {item.source_name && !item.source_link && (
                                    <div
                                      className={
                                        item.indicator_id.indexOf(".") > -1
                                          ? "col-sm-12 impact-source-link supplementary"
                                          : "col-sm-12 impact-source-link"
                                      }
                                    >
                                      {text.source}:{" "}
                                      {pt
                                        ? item.source_name_pt
                                        : item.source_name}
                                    </div>
                                  )}
                                  {!indicatorsWithoutHorizontalLine.includes(
                                    item.indicator_id
                                  ) && (
                                    <div className="col-sm-12 impact-horizontal-line" />
                                  )}
                                </>
                              ) : item.type && item.type === "line" ? (
                                list ? (
                                  <>
                                    <div
                                      className={classnames(
                                        styles.row,
                                        styles.rowCenter,
                                        "impact-indicator-data"
                                      )}
                                    >
                                      <div className="col-lg-6">
                                        <LineGraph
                                          pt={pt}
                                          id={
                                            "line-graph-" +
                                            this.state.objective.replace(
                                              ".",
                                              "_"
                                            ) +
                                            "-" +
                                            index
                                          }
                                          trend={"plr"}
                                          data={graph_data}
                                          target={target_data.value}
                                          target_year={target_data.year}
                                          unit={pt ? item.unit_pt : item.unit}
                                          isPercentage={false}
                                          isArea={false}
                                        />
                                      </div>
                                      <div className="col-lg-6 impact-indicator-list-section line">
                                        <div className="impact-indicator-list-title">
                                          <a href={list.source} target="_blank">
                                            {pt ? list.title_pt : list.title}
                                          </a>
                                        </div>
                                        {Array.isArray(list.list) && (
                                          <ul
                                            className={classnames(
                                              "impact-indicator-list-wrapper"
                                            )}
                                          >
                                            {list.list.map(
                                              (list_item, index) => {
                                                return (
                                                  <li
                                                    id={`list-item-${index}`}
                                                    className={classnames(
                                                      "impact-indicator-list-item"
                                                    )}
                                                  >
                                                    <p>{list_item}</p>
                                                  </li>
                                                );
                                              }
                                            )}
                                          </ul>
                                        )}
                                        {/*  */}
                                        {list.list.length === 0 && (
                                          <div
                                            id={"list-item-0"}
                                            className="impact-indicator-list-item impact-indicatior-list-empty"
                                          >
                                            <p>{text.none}</p>
                                          </div>
                                        )}
                                        {/*  */}
                                      </div>
                                    </div>
                                    {/*  */}
                                    {item["indicator_id"] != "15.1" &&
                                      item["indicator_id"] != "17.1" &&
                                      item["indicator_id"] != "17.2" &&
                                      item["indicator_id"] != "19.1" &&
                                      item.source_name &&
                                      item.source_link && (
                                        <div
                                          className={
                                            item.indicator_id.indexOf(".") > -1
                                              ? "col-sm-12 impact-source-link supplementary"
                                              : "col-sm-12 impact-source-link"
                                          }
                                        >
                                          {text.source}:{" "}
                                          <a
                                            href={item.source_link}
                                            target="_blank"
                                          >
                                            {pt
                                              ? item.source_name_pt
                                              : item.source_name}
                                          </a>
                                        </div>
                                      )}
                                    {/*  */}

                                    {/*  */}
                                    {item.source_name && !item.source_link && (
                                      <div
                                        className={
                                          item.indicator_id.indexOf(".") > -1
                                            ? "col-sm-12 impact-source-link supplementary"
                                            : "col-sm-12 impact-source-link"
                                        }
                                      >
                                        {text.source}:{" "}
                                        {pt
                                          ? item.source_name_pt
                                          : item.source_name}
                                      </div>
                                    )}
                                    {/*  */}
                                    {![
                                      "2.1",
                                      "4.1",
                                      "6.2",
                                      "8",
                                      "9.1",
                                      "11",
                                      "13.1",
                                      "15.1",
                                      "17",
                                      "17.1",
                                      "17.2",
                                      "17.5",
                                      "19.1",
                                      "19.3",
                                      "19.4",
                                      "19.5",
                                    ].includes(item.indicator_id) && (
                                      <div className="col-sm-12 impact-horizontal-line" />
                                    )}
                                  </>
                                ) : (
                                  <>
                                    <div className="col-lg-12 impact-indicator-data">
                                      <div className="col-lg-8 col-lg-offset-2 col-sm-12">
                                        <LineGraph
                                          pt={pt}
                                          id={
                                            "line-graph-" +
                                            this.state.objective.replace(
                                              ".",
                                              "_"
                                            ) +
                                            "-" +
                                            index
                                          }
                                          trend={"plr"}
                                          data={graph_data}
                                          target={target_data.value}
                                          target_year={target_data.year}
                                          unit={pt ? item.unit_pt : item.unit}
                                          isPercentage={false}
                                          isArea={false}
                                        />
                                      </div>
                                    </div>
                                    {/*  */}
                                    {item["indicator_id"] != "15.1" &&
                                      item["indicator_id"] != "17.1" &&
                                      item["indicator_id"] != "17.2" &&
                                      item["indicator_id"] != "19.1" &&
                                      item.source_name &&
                                      item.source_link && (
                                        <div
                                          className={
                                            item.indicator_id.indexOf(".") > -1
                                              ? "col-sm-12 impact-source-link supplementary"
                                              : "col-sm-12 impact-source-link"
                                          }
                                        >
                                          {text.source}:{" "}
                                          <a
                                            href={item.source_link}
                                            target="_blank"
                                          >
                                            {pt
                                              ? item.source_name_pt
                                              : item.source_name}
                                          </a>
                                        </div>
                                      )}
                                    {/*  */}

                                    {/*  */}
                                    {item["indicator_id"] != "15.1" &&
                                      item["indicator_id"] != "17.1" &&
                                      item["indicator_id"] != "17.2" &&
                                      item["indicator_id"] != "19.1" &&
                                      item["indicator_id"] != "19.4" &&
                                      item["indicator_id"] != "19.5" &&
                                      item["indicator_id"] != "19.3" &&
                                      item.source_name &&
                                      !item.source_link && (
                                        <div
                                          className={
                                            item.indicator_id.indexOf(".") > -1
                                              ? "col-sm-12 impact-source-link supplementary"
                                              : "col-sm-12 impact-source-link"
                                          }
                                        >
                                          {text.source}:{" "}
                                          {pt
                                            ? item.source_name_pt
                                            : item.source_name}
                                        </div>
                                      )}
                                    {/*  */}
                                    {![
                                      "2.1",
                                      "4.1",
                                      "6.2",
                                      "8",
                                      "9.1",
                                      "11",
                                      "13.1",
                                      "15.1",
                                      "17",
                                      "17.1",
                                      "17.2",
                                      "17.5",
                                      "19.1",
                                      "19.3",
                                      "19.4",
                                      "19.5",
                                    ].includes(item.indicator_id) && (
                                      <div className="col-sm-12 impact-horizontal-line" />
                                    )}
                                  </>
                                )
                              ) : item.type && item.type === "bar" ? (
                                <>
                                  <div className="col-lg-12 impact-indicator-data">
                                    <div className="col-lg-8 col-lg-offset-2 col-sm-12">
                                      <BarGraph
                                        pt={pt}
                                        id={
                                          "bar-graph-" +
                                          this.state.objective.replace(
                                            ".",
                                            "_"
                                          ) +
                                          "-" +
                                          index
                                        }
                                        type="single"
                                        trend={"plr"}
                                        data={graph_data}
                                        target={target_data.value}
                                        target_year={target_data.year}
                                        unit={pt ? item.unit_pt : item.unit}
                                      />
                                    </div>
                                  </div>
                                  {/*  */}
                                  {item.indicador_id != "15.1" &&
                                    item.indicador_id != "17.1" &&
                                    item.indicador_id != "17.2" &&
                                    item.source_name &&
                                    item.source_link && (
                                      <div
                                        className={
                                          item.indicator_id.indexOf(".") > -1
                                            ? "col-sm-12 impact-source-link supplementary"
                                            : "col-sm-12 impact-source-link"
                                        }
                                      >
                                        {text.source}:{" "}
                                        <a
                                          href={item.source_link}
                                          target="_blank"
                                        >
                                          {pt
                                            ? item.source_name_pt
                                            : item.source_name}
                                        </a>
                                      </div>
                                    )}
                                  {/*  */}

                                  {/*  */}
                                  {item["indicator_id"] != "15.1" &&
                                    item["indicator_id"] != "17.1" &&
                                    item["indicator_id"] != "17.2" &&
                                    item.source_name &&
                                    !item.source_link && (
                                      <div
                                        className={
                                          item.indicator_id.indexOf(".") > -1
                                            ? "col-sm-12 impact-source-link supplementary"
                                            : "col-sm-12 impact-source-link"
                                        }
                                      >
                                        {text.source}:{" "}
                                        {pt
                                          ? item.source_name_pt
                                          : item.source_name}
                                      </div>
                                    )}
                                  {/*  */}
                                  {!indicatorsWithoutHorizontalLine.includes(
                                    item.indicator_id
                                  ) && (
                                    <div className="col-sm-12 impact-horizontal-line" />
                                  )}
                                </>
                              ) : item.type && item.type === "bar_grouped" ? (
                                <>
                                  <div className="col-lg-12 impact-indicator-data">
                                    <div className="col-lg-8 col-lg-offset-2 col-sm-12">
                                      <BarGraph
                                        pt={pt}
                                        id={
                                          "bar-graph-" +
                                          this.state.objective.replace(
                                            ".",
                                            "_"
                                          ) +
                                          "-" +
                                          index
                                        }
                                        type={"grouped"}
                                        trend={"plr"}
                                        data={graph_data}
                                        unit={pt ? item.unit_pt : item.unit}
                                        targets={target_data}
                                      />
                                    </div>
                                  </div>
                                  {/*  */}
                                  {item["indicator_id"] != "15.1" &&
                                    item["indicator_id"] != "17.1" &&
                                    item["indicator_id"] != "17.2" &&
                                    item["indicator_id"] != "19.1" &&
                                    item.source_name &&
                                    item.source_link && (
                                      <div
                                        className={
                                          item.indicator_id.indexOf(".") > -1
                                            ? "col-sm-12 impact-source-link supplementary"
                                            : "col-sm-12 impact-source-link"
                                        }
                                      >
                                        {text.source}:{" "}
                                        <a
                                          href={item.source_link}
                                          target="_blank"
                                        >
                                          {pt
                                            ? item.source_name_pt
                                            : item.source_name}
                                        </a>
                                      </div>
                                    )}
                                  {/*  */}

                                  {/*  */}
                                  {item.source_name && !item.source_link && (
                                    <div
                                      className={
                                        item.indicator_id.indexOf(".") > -1
                                          ? "col-sm-12 impact-source-link supplementary"
                                          : "col-sm-12 impact-source-link"
                                      }
                                    >
                                      {text.source}:{" "}
                                      {pt
                                        ? item.source_name_pt
                                        : item.source_name}
                                    </div>
                                  )}
                                  {/*  */}
                                  {!indicatorsWithoutHorizontalLine.includes(
                                    item.indicator_id
                                  ) && (
                                    <div className="col-sm-12 impact-horizontal-line" />
                                  )}
                                </>
                              ) : item.type && item.type === "dual_scale" ? (
                                <>
                                  <div className="col-lg-12 impact-indicator-data">
                                    <div className="col-lg-8 col-lg-offset-2 col-sm-12">
                                      <BarGraph
                                        pt={pt}
                                        id={
                                          "bar-graph-" +
                                          this.state.objective.replace(
                                            ".",
                                            "_"
                                          ) +
                                          "-" +
                                          index
                                        }
                                        type={"dual_scale"}
                                        trend={"plr"}
                                        data={graph_data}
                                        unit={pt ? item.unit_pt : item.unit}
                                        targets={target_data}
                                      />
                                    </div>
                                  </div>
                                  {/*  */}
                                  {item["indicator_id"] != "15.1" &&
                                    item["indicator_id"] != "17.1" &&
                                    item["indicator_id"] != "17.2" &&
                                    item["indicator_id"] != "19.1" &&
                                    item.source_name &&
                                    item.source_link && (
                                      <div
                                        className={
                                          item.indicator_id.indexOf(".") > -1
                                            ? "col-sm-12 impact-source-link supplementary"
                                            : "col-sm-12 impact-source-link"
                                        }
                                      >
                                        {text.source}:{" "}
                                        <a
                                          href={item.source_link}
                                          target="_blank"
                                        >
                                          {pt
                                            ? item.source_name_pt
                                            : item.source_name}
                                        </a>
                                      </div>
                                    )}
                                  {/*  */}

                                  {/*  */}
                                  {item["indicator_id"] != "11" &&
                                    item.source_name &&
                                    !item.source_link && (
                                      <div
                                        className={
                                          item.indicator_id.indexOf(".") > -1
                                            ? "col-sm-12 impact-source-link supplementary"
                                            : "col-sm-12 impact-source-link"
                                        }
                                      >
                                        {text.source}:{" "}
                                        {pt
                                          ? item.source_name_pt
                                          : item.source_name}
                                      </div>
                                    )}
                                  {/*  */}
                                  {/*  */}
                                  {item.source_name && item.source_link && (
                                    <div
                                      className={
                                        item.indicator_id.indexOf(".") > -1
                                          ? "col-sm-12 impact-source-link supplementary"
                                          : "col-sm-12 impact-source-link"
                                      }
                                    >
                                      {text.source}:{" "}
                                      <a
                                        href={item.source_link}
                                        target="_blank"
                                      >
                                        {pt
                                          ? item.source_name_pt
                                          : item.source_name}
                                      </a>
                                    </div>
                                  )}
                                  {/*  */}

                                  {/*  */}
                                  {item.source_name && !item.source_link && (
                                    <div
                                      className={
                                        item.indicator_id.indexOf(".") > -1
                                          ? "col-sm-12 impact-source-link supplementary"
                                          : "col-sm-12 impact-source-link"
                                      }
                                    >
                                      {text.source}:{" "}
                                      {pt
                                        ? item.source_name_pt
                                        : item.source_name}
                                    </div>
                                  )}
                                  {/*  */}
                                  {!indicatorsWithoutHorizontalLine.includes(
                                    item.indicator_id
                                  ) && (
                                    <div className="col-sm-12 impact-horizontal-line" />
                                  )}
                                </>
                              ) : item.type && item.type === "progress_bar" ? (
                                Array.isArray(graph_data) ? (
                                  <>
                                    <div className="col-lg-12 impact-indicator-data">
                                      <div className="col-lg-8 col-lg-offset-2 col-sm-12">
                                        <ProgressBar
                                          id={
                                            "progress-graph-" +
                                            this.state.objective.replace(
                                              ".",
                                              "_"
                                            ) +
                                            "-" +
                                            index
                                          }
                                          className="impact-progress-bar"
                                          now={
                                            (graph_data[graph_data.length - 1]
                                              .value /
                                              target_data.value) *
                                            100
                                          }
                                          label={
                                            <span>
                                              {graph_data[
                                                graph_data.length - 1
                                              ].value.toLocaleString(
                                                pt ? "pt-BR" : "en-US"
                                              ) +
                                                " " +
                                                (pt
                                                  ? item.unit_pt
                                                      .replace("Número de ", "")
                                                      .replace("N° de ", "")
                                                  : item.unit.replace(
                                                      "# of ",
                                                      ""
                                                    )) +
                                                " as of " +
                                                graph_data[
                                                  graph_data.length - 1
                                                ].year}
                                            </span>
                                          }
                                        />
                                      </div>
                                    </div>
                                    {/*  */}
                                    {item.source_name && item.source_link && (
                                      <div
                                        className={
                                          item.indicator_id.indexOf(".") > -1
                                            ? "col-sm-12 impact-source-link supplementary"
                                            : "col-sm-12 impact-source-link"
                                        }
                                      >
                                        {text.source}:{" "}
                                        <a
                                          href={item.source_link}
                                          target="_blank"
                                        >
                                          {pt
                                            ? item.source_name_pt
                                            : item.source_name}
                                        </a>
                                      </div>
                                    )}
                                    {/*  */}

                                    {/*  */}
                                    {item.source_name && !item.source_link && (
                                      <div
                                        className={
                                          item.indicator_id.indexOf(".") > -1
                                            ? "col-sm-12 impact-source-link supplementary"
                                            : "col-sm-12 impact-source-link"
                                        }
                                      >
                                        {text.source}:{" "}
                                        {pt
                                          ? item.source_name_pt
                                          : item.source_name}
                                      </div>
                                    )}
                                    {/*  */}
                                    {![
                                      "2.1",
                                      "4.1",
                                      "6.2",
                                      "8",
                                      "9.1",
                                      "11",
                                      "13.1",
                                      "15.1",
                                      "17",
                                      "17.1",
                                      "17.2",
                                      "17.5",
                                      "19.1",
                                      "19.3",
                                      "19.4",
                                      "19.5",
                                    ].includes(item.indicator_id) && (
                                      <div className="col-sm-12 impact-horizontal-line" />
                                    )}
                                  </>
                                ) : (
                                  Object.keys(graph_data).map((key, i) => {
                                    let currentData = graph_data[key];
                                    let returnValue = (
                                      <div className="col-lg-12 impact-indicator-data">
                                        <div className="col-lg-8 col-lg-offset-2 col-sm-12">
                                          <ProgressBar
                                            id={
                                              "progress-graph-" +
                                              this.state.objective.replace(
                                                ".",
                                                "_"
                                              ) +
                                              "-" +
                                              index +
                                              "-" +
                                              i
                                            }
                                            className="impact-progress-bar"
                                            now={
                                              (currentData[
                                                currentData.length - 1
                                              ].value /
                                                target_data[key].value) *
                                              100
                                            }
                                            label={
                                              <span>
                                                {currentData[
                                                  currentData.length - 1
                                                ].value.toLocaleString(
                                                  pt ? "pt-BR" : "en-US"
                                                ) +
                                                  " " +
                                                  key +
                                                  " as of " +
                                                  currentData[
                                                    currentData.length - 1
                                                  ].year}
                                              </span>
                                            }
                                          />
                                        </div>
                                      </div>
                                    );
                                    return returnValue;
                                  })
                                )
                              ) : item.type && item.type === "area" ? (
                                <>
                                  <div className="col-lg-12 impact-indicator-data">
                                    <div className="col-lg-8 col-lg-offset-2 col-sm-12">
                                      <LineGraph
                                        pt={pt}
                                        id={`line-graph-${this.state.objective.replace(
                                          ".",
                                          "_"
                                        )}-${index}`}
                                        trend={"plr"}
                                        data={graph_data}
                                        target={target_data.value}
                                        target_year={target_data.year}
                                        unit={pt ? item.unit_pt : item.unit}
                                        isPercentage={false}
                                        isArea={true}
                                      />
                                    </div>
                                  </div>
                                  {["15.1", "17.1", "17.2", "19.1"].includes(
                                    item["indicator_id"]
                                  ) === false &&
                                    item.source_name &&
                                    item.source_link && (
                                      <div
                                        className={
                                          item.indicator_id.indexOf(".") > -1
                                            ? "col-sm-12 impact-source-link supplementary"
                                            : "col-sm-12 impact-source-link"
                                        }
                                      >
                                        {text.source}:{" "}
                                        <a
                                          href={item.source_link}
                                          target="_blank"
                                        >
                                          {pt
                                            ? item.source_name_pt
                                            : item.source_name}
                                        </a>
                                      </div>
                                    )}
                                  {item["indicator_id"] !== "17.5" &&
                                    item.source_name &&
                                    !item.source_link && (
                                      <div
                                        className={
                                          item.indicator_id.indexOf(".") > -1
                                            ? "col-sm-12 impact-source-link supplementary"
                                            : "col-sm-12 impact-source-link"
                                        }
                                      >
                                        {text.source}:{" "}
                                        {pt
                                          ? item.source_name_pt
                                          : item.source_name}
                                      </div>
                                    )}
                                  {!indicatorsWithoutHorizontalLine.includes(
                                    item.indicator_id
                                  ) && (
                                    <div className="col-sm-12 impact-horizontal-line" />
                                  )}
                                </>
                              ) : item.type && item.type === "map" ? (
                                <div className="col-lg-12 impact-indicator-data">
                                  <div className="col-sm-8">
                                    <ImpactMap
                                      id={`impact-map-${this.state.objective.replace(
                                        ".",
                                        "_"
                                      )}-${index}`}
                                      data={states_highrank_list}
                                      bank_supported={bank_states_highrank_list}
                                    />
                                  </div>
                                  <div className="col-sm-4" />
                                  <div className="col-sm-4 impact-indicator-list-section map">
                                    <div className="impact-indicator-list-title">
                                      <a href={list.source} target="_blank">
                                        {list.title}
                                      </a>
                                    </div>
                                    {bank_states_highrank_list.map(
                                      (list_item, index) => {
                                        return (
                                          <div
                                            id={"list-item-" + index}
                                            className="impact-indicator-list-item"
                                          >
                                            {list_item}
                                          </div>
                                        );
                                      }
                                    )}
                                    {Array.isArray(bank_states_highrank_list) &&
                                      bank_states_highrank_list.length ===
                                        0 && (
                                        <div
                                          id={"list-item-0"}
                                          className="impact-indicator-list-item"
                                        >
                                          {text.none}
                                        </div>
                                      )}
                                  </div>
                                </div>
                              ) : null}
                              {item.iframes[0] && (
                                <>
                                  <div className="col-lg-12 col-sm-12">
                                    <div>
                                      <iframe
                                        width="600"
                                        height="400"
                                        frameBorder="0"
                                        scrolling="no"
                                        allowFullScreen
                                        src={item.iframes[0].url}
                                      ></iframe>
                                    </div>
                                    {item.iframes[0].description && (
                                      <div className="impact-indicator-iframe-desc">
                                        {(pt
                                          ? item.iframes[0].description_pt
                                          : item.iframes[0].description
                                        )
                                          .split(/[[\]]/)
                                          .map((content, index) => {
                                            if (index === 1) {
                                              return (
                                                <span
                                                  key={"impact-iframe-" + index}
                                                >
                                                  <a
                                                    href={
                                                      item.iframes[0]
                                                        .description_url
                                                    }
                                                    target="_blank"
                                                  >
                                                    {content}
                                                  </a>
                                                </span>
                                              );
                                            } else if (index === 3) {
                                              return (
                                                <span
                                                  key={"impact-iframe-" + index}
                                                >
                                                  <a
                                                    href={
                                                      item.iframes[0]
                                                        .metadata_url
                                                    }
                                                    target="_blank"
                                                  >
                                                    {content}
                                                  </a>
                                                </span>
                                              );
                                            } else {
                                              return (
                                                <span
                                                  key={"impact-iframe-" + index}
                                                >
                                                  {content}
                                                </span>
                                              );
                                            }
                                          })}
                                      </div>
                                    )}
                                  </div>
                                  {["15.1", "17.1", "17.2", "19.1"].includes(
                                    item["indicator_id"]
                                  ) === false &&
                                    item.source_name &&
                                    item.source_link && (
                                      <div
                                        className={
                                          item.indicator_id.indexOf(".") > -1
                                            ? "col-sm-12 impact-source-link supplementary"
                                            : "col-sm-12 impact-source-link"
                                        }
                                      >
                                        {text.source}:{" "}
                                        <a
                                          href={item.source_link}
                                          target="_blank"
                                        >
                                          {pt
                                            ? item.source_name_pt
                                            : item.source_name}
                                        </a>
                                      </div>
                                    )}
                                  {item.source_name && !item.source_link && (
                                    <div
                                      className={
                                        item.indicator_id.indexOf(".") > -1
                                          ? "col-sm-12 impact-source-link supplementary"
                                          : "col-sm-12 impact-source-link"
                                      }
                                    >
                                      {text.source}:{" "}
                                      {pt
                                        ? item.source_name_pt
                                        : item.source_name}
                                    </div>
                                  )}
                                  <div className="col-sm-12 impact-horizontal-line" />
                                </>
                              )}
                              {/* INDICADOR 15.1 */}
                              {item.indicator_id === "15.1" && (
                                <>
                                  <div className="col-lg-12 impact-indicator-data">
                                    <div
                                      className="col-md-6 col-md-offset-3 col-sm-12"
                                      style={{ height: "350px" }}
                                    >
                                      <SwiperMap />
                                    </div>
                                  </div>
                                  {item.source_name && item.source_link && (
                                    <div
                                      className={
                                        item.indicator_id.indexOf(".") > -1
                                          ? "col-sm-12 impact-source-link supplementary"
                                          : "col-sm-12 impact-source-link"
                                      }
                                    >
                                      {text.source}:{" "}
                                      <a
                                        href={item.source_link}
                                        target="_blank"
                                      >
                                        {pt
                                          ? item.source_name_pt
                                          : item.source_name}
                                      </a>
                                    </div>
                                  )}
                                  {item.source_name && !item.source_link && (
                                    <div
                                      className={
                                        item.indicator_id.indexOf(".") > -1
                                          ? "col-sm-12 impact-source-link supplementary"
                                          : "col-sm-12 impact-source-link"
                                      }
                                    >
                                      {text.source}:{" "}
                                      {pt
                                        ? item.source_name_pt
                                        : item.source_name}
                                    </div>
                                  )}
                                  <div className="col-sm-12 impact-horizontal-line" />
                                </>
                              )}
                              {/* indicador 12 e 19.3 */}
                              {(item.indicator_id === "12" ||
                                item.indicator_id === "19.3") && (
                                <div className="col-lg-12 impact-indicator-data">
                                  {item.source_name && item.source_link && (
                                    <div
                                      className={
                                        item.indicator_id.indexOf(".") > -1
                                          ? "col-sm-12 impact-source-link supplementary"
                                          : "col-sm-12 impact-source-link"
                                      }
                                    >
                                      {text.source}:{" "}
                                      <a
                                        href={item.source_link}
                                        target="_blank"
                                      >
                                        {pt
                                          ? item.source_name_pt
                                          : item.source_name}
                                      </a>
                                    </div>
                                  )}
                                  {item.source_name && !item.source_link && (
                                    <div
                                      className={
                                        item.indicator_id.indexOf(".") > -1
                                          ? "col-sm-12 impact-source-link supplementary"
                                          : "col-sm-12 impact-source-link"
                                      }
                                    >
                                      {text.source}:{" "}
                                      {pt
                                        ? item.source_name_pt
                                        : item.source_name}
                                    </div>
                                  )}
                                  {index <
                                    active_focus_objectives[
                                      this.state.objective
                                    ].indicators.length -
                                      1 && (
                                    <div className="col-sm-12 impact-horizontal-line" />
                                  )}
                                </div>
                              )}
                            </div>
                          );
                        }
                      })}
                  </div>
                </div>
              )}
            </div>
          )}
          <div></div>
          <div style={{ height: "144px" }}></div>
          <WelcomeFooter {...this.props} />
        </div>
      </div>
    );
  }
}
export default withRouter(Impact);
