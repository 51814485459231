import React, { useState, useEffect } from 'react';
import moment from 'moment';
import 'moment/locale/pt-br';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import '../strategy/Strategy.css';

import '../insights/Insights.css'

import WelcomeFooter from '../menu/WelcomeFooter';
import Navbar from '../common/navbar/NavbarPLR';
import StrategyHeader from '../common/strategyheader';

import ImpactCover from '../impact/img/impact-cover.png'
import { API_URL } from '../../../config/api';

const textEnglish = {
	"med": "Online Media Snapshot",
	"and": " and ",
	"desc1": `This page allows users to explore additional information about the impact the World Bank Group has on the online
		 environment when it comes to Tax Reform and Business Environment Reform. The WBG has continually supported 
		 changes in these two sectors, and demographic information on the audiences that are discussing each topic is available below.
		To understand the relationship between these indicators and the Country Partnership Framework, you can also visit`,
	"desc2": "",
	"link1": "Indicator 1.1 (Fiscal Reform)",
	"link2": "Indicator 9.1 (Business Environment Reform)",

	"select": "Select a Theme",
	"fiscal": "Fiscal Policy",
	"business": "Business Environment",

	"media_type_title": "Share of Media Type",
	"gender_title": "Gender",
	"themes_title": "Themes",
	"age_title": "Age",
	"map_title":"Engagement",

	"media": "Media Snapshot",
	"media_desc": "Explore how media outlets talk about the World Bank. Sorted by date and time published, clicking an article title will open a new tab on an external site.",
	"show": "Show More",
	"others": "Others"
}

const textPortuguese = {
	"med": "Panorama Online",
	"and": " e ",
	"desc1": `Esta página permite aos usuários explorar informações adicionais sobre o impacto que o 
		Grupo Banco Mundial tem no ambiente online quando o assunto é Reforma Fiscal e Reforma no
		 Ambiente de Negócios. O GBM tem apoiado continuamente mudanças nesses dois setores, e 
		  informações demográficas sobre os públicos que estão discutindo cada tópico estão disponíveis abaixo.
		Para entender a relação entre esses indicadores e a Estratégia de Parceria para o País, visite também `,
	"desc2": "",
	"link1": "indicadores 1.1 (Reforma Fiscal)",
	"link2": "9.1 (Reforma do Ambiente de Negócios)",

	"select": "Select a Theme",
	"fiscal": "Política fiscal",
	"business": "Ambiente de negócios",

	"media_type_title": "Tipo de mídia",
	"gender_title": "Gênero",
	"themes_title": "Temas",
	"age_title": "Idade",
	"map_title": "Potential reach",

	"media": "Panorama na mídia",
	"media_desc": "Veja notícias sobre o Banco Mundial na mídia, classificadas por data e hora de publicação. Clicar no título de um artigo abrirá uma nova guia em um site externo.",
	"show": "Leia mais",
	"others": "Others"
}

const SentimentSnapshot = (props) => {
	const [timeframe, setTimeframe] = useState('week');
	const [media, setMedia] = useState([]);
	const [show, setShow] = useState(6);

	const handleLogout = () => {
		localStorage.removeItem('auth');
		props.history.push('/');
	};

	useEffect(() => {
		window.scrollTo(0, 0);
		getNews();
	}, []);

	const getNews = () => {
		axios
			.get(`${API_URL}/newsclip/${timeframe}`)
			.then((response) => {
				setMedia(response.data);
			})
			.catch(() => {
			});
	};

	const pt = props.location.pathname.substring(0, 4) === '/pt/';

	let text = {};
	if (props.location) {
		if (props.location.pathname.substring(0, 4) !== '/pt/') {
			text = textEnglish;
		} else {
			text = textPortuguese;
		}
	} else {
		text = textEnglish;
	}

	function capitalizeFirstLetter(str) {
		return str.charAt(0).toUpperCase() + str.slice(1);
	}

	return (
		<div>
			<div className="container-fluid unpadify">
				<Navbar {...props} handleLogout={handleLogout} isHomepage={false} isLoggedIn={props.loggedIn} currentPage="impact" />
				<StrategyHeader
					title={pt ? "Impacto" : "Impact"}
					selectedTab={1}
					tabs={[
						{ link: pt ? '/pt/indicators' : '/indicators', label: pt ? 'Indicadores de resultado' : 'Result indicators' },
						{ link: pt ? '/pt/insights' : '/insights', label: pt ? 'Panorama online' : 'Online Media Snapshot' }
					]}
					backgroundImage={ImpactCover}
					backgroundPosition="0px 25%"
				/>
				<div id="strategy-container">
					<div style={{
						display: 'flex',
						justifyContent: 'start',
						alignItems: 'center',
						marginBottom: '10px',
						fontFamily: 'Andes',
						fontSize: '40px',
					}}  className="media-title">
						{text.media}
					</div>
					<div className="divider"></div>
					<div className="media-desc" style={{ marginBottom: '40px', fontSize: '22px', display: 'flex', textAlign: 'start',  alignItems: 'center', justifyContent: 'start', color: '#1a1a1a' }}>
						{text.media_desc}
					</div>
					<div className="row media-container">
						<div className="col-md-12 media-feed">
							<div className="row media-content">
								{media.slice(0,show).map((article,index) => {
									let date = new Date(article.publication_date);

									const formatted = moment(date).locale(pt ? 'pt-br' : null).format(pt ? 'MMM DD, YYYY | hh:mm' : 'MMM DD, YYYY | hh:mm a');

									return (
										<div className="col-md-4 col-sm-6 media-object" key={"article-"+index} id={"article-"+index}>
											<a target="_blank" href={article.url}>
												<div className="media-article">
													<div className="media-text">
														<div style={{textTransform: 'initial'}} className="media-article-date">{capitalizeFirstLetter(formatted)}{pt && 'h'}</div>
														<div className="media-article-title">{article.title}</div>
													</div>
												</div>
											</a>
										</div>
									)
								})}
							</div>
						</div>
					</div>
				</div>
				<WelcomeFooter {...props}/>
			</div>
		</div>
	);
}

export default withRouter(SentimentSnapshot);
