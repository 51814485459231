import React, { useState } from 'react';
import { Collapse } from 'react-bootstrap';

import GroupedBar from '../../../common/graphs/GroupedBar';
import GroupedBar2 from '../../../common/graphs/GroupedBar2';
import LineChart from '../../../common/graphs/LineChart';
import GraphTabs from '../../../common/graphTabs/GraphTabs';

import './Section.css'

const textEnglish = {
	'source': 'SOURCE',
	'economy': 'Economy',
	'inequality': 'Inequality and Unemployment',
	'real': 'Real Sector',
	'external': 'External Position',
	'business': 'Business Environment',
	'finance': 'Finance & Investment',
	'public': 'Public Finance',
	'historical': 'Historical'
}

const textPortuguese = {
	'source': 'FONTE',
	'economy': 'Economia',
	'inequality': 'Desigualdade e Desemprego',
	'real': 'Setor Real',
	'external': 'Posição Externa',
	'business': 'Ambiente de Negócios',
	'finance': 'Finanças & Investimentos',
	'public': 'Finanças Públicas',
	'historical': 'Histórico'
}

const Economy = (props) => {
	const [isOpen, setIsOpen] = useState(true);
	const [hasLoaded, setHasLoaded] = useState(false);

	let pt = false;
	let text = {};
	if (props.location.pathname.substring(0, 4) !== "/pt/") {
		text = textEnglish;
	} else {
		pt = true;
		text = textPortuguese;
	}

	let buttonText = <i className="fa fa-caret-down plus-icon" />;
	if (isOpen) {
		buttonText = <i className="fa fa-caret-right minus-icon" />;
	}

	let visible = props.visibleSections;

	if (isOpen || hasLoaded) {
		return (
			<div className="section-container" style={visible['section-two'] ? {} : {'display':'none'}}>
				<div className="row">
					<div className="col-md-12 categoryContent benchmark-section-content">
						<Collapse in={isOpen}>
							<div>
								<div id="section-two-subsection-zero" style={visible['section-two-subsection-zero'] ? {} : {'display':'none'}}>
									<h2 style={{ fontSize: '32px' }}>{text.inequality}</h2>
									<h4
										style={{ fontSize: '22px' }}
										className="pad-top"
									>
										{props.wdi_data[props.country_order[0].iso3].indicators["SI.POV.UMIC"][(pt ? 'name_pt' : 'name')]}
									</h4>
									<div className="row">
										<div className="col-xs-12">
											<p className="benchmarking-text">
												{props.wdi_data[props.country_order[0].iso3].indicators["SI.POV.UMIC"][(pt ? 'source_note_pt' : 'source_note')]}
											</p>
										</div>
										<div className="col-xs-12">
											<GraphTabs
												{...props}
												pt={pt}
												tab1Name={props.wdi_data[props.country_order[0].iso3].indicators["SI.POV.UMIC"].data[props.wdi_data[props.country_order[0].iso3].indicators["SI.POV.UMIC"].data.length-1].year}
												tab2Name={text.historical}
												tab1Content={
													<div>
														<GroupedBar2
															{...props}
															pt={pt}
															wdi_data={props.wdi_data}
															country_order={props.country_order}
															field="SI.POV.UMIC"
															values={1}
															formatter={'0.0a'}
														/>
														<p className="footnote">
															{text.source}: {props.wdi_data[props.country_order[0].iso3].indicators["SI.POV.UMIC"][(pt ? 'source_pt' : 'source')]}
														</p>
													</div>
												}
												tab2Content={
													<div>
														<GroupedBar2
															{...props}
															pt={pt}
															wdi_data={props.wdi_data}
															country_order={props.country_order}
															field="SI.POV.UMIC"
															values={5}
															formatter={'0.0a'}
														/>
														<p className="footnote">
															{text.source}: {props.wdi_data[props.country_order[0].iso3].indicators["SI.POV.UMIC"][(pt ? 'source_pt' : 'source')]}
														</p>
													</div>
												}
											/>
										</div>
									</div>
									<h4 style={{ fontSize: '22px' }} className="pad-top">
										{props.wdi_data[props.country_order[0].iso3].indicators["SL.UEM.TOTL.NE.ZS"][(pt ? 'name_pt' : 'name')]}
									</h4>
									<div className="row">
										<div className="col-xs-12">
											<p className="benchmarking-text">
												{props.wdi_data[props.country_order[0].iso3].indicators["SL.UEM.TOTL.NE.ZS"][(pt ? 'source_note_pt' : 'source_note')]}
											</p>
										</div>
										<div className="col-xs-12">
											<GraphTabs
												{...props}
												pt={pt}
												tab1Name={props.wdi_data[props.country_order[0].iso3].indicators["SL.UEM.TOTL.NE.ZS"].data[props.wdi_data[props.country_order[0].iso3].indicators["SL.UEM.TOTL.NE.ZS"].data.length-1].year}
												tab2Name={text.historical}
												tab1Content={
													<div>
														<GroupedBar2
															{...props}
															pt={pt}
															wdi_data={props.wdi_data}
															country_order={props.country_order}
															field="SL.UEM.TOTL.NE.ZS"
															values={1}
															formatter={'0.0a'}
														/>
														<p className="footnote">
															{text.source}: {props.wdi_data[props.country_order[0].iso3].indicators["SL.UEM.TOTL.NE.ZS"][(pt ? 'source_pt' : 'source')]}
														</p>
													</div>
												}
												tab2Content={
													<div>
														<GroupedBar2
															{...props}
															pt={pt}
															wdi_data={props.wdi_data}
															country_order={props.country_order}
															field="SL.UEM.TOTL.NE.ZS"
															values={5}
															formatter={'0.0a'}
														/>
														<p className="footnote">
															{text.source}: {props.wdi_data[props.country_order[0].iso3].indicators["SL.UEM.TOTL.NE.ZS"][(pt ? 'source_pt' : 'source')]}
														</p>
													</div>
												}
											/>
										</div>
									</div>
									<h4 style={{ fontSize: '22px' }} className="pad-top">
										{props.wdi_data[props.country_order[0].iso3].indicators["SI.POV.GINI"][(pt ? 'name_pt' : 'name')]}
									</h4>
									<div className="row">
										<div className="col-xs-12">
											<p className="benchmarking-text">
												{props.wdi_data[props.country_order[0].iso3].indicators["SI.POV.GINI"][(pt ? 'source_note_pt' : 'source_note')]}
											</p>
										</div>
										<div className="col-xs-12">
											<GraphTabs
												{...props}
												pt={pt}
												tab1Name={props.wdi_data[props.country_order[0].iso3].indicators["SI.POV.GINI"].data[props.wdi_data[props.country_order[0].iso3].indicators["SI.POV.GINI"].data.length-1].year}
												tab2Name={text.historical}
												tab1Content={
													<div>
														<GroupedBar2
															{...props}
															pt={pt}
															wdi_data={props.wdi_data}
															country_order={props.country_order}
															field="SI.POV.GINI"
															values={1}
															formatter={'0.0a'}
														/>
														<p className="footnote">
															{text.source}: {props.wdi_data[props.country_order[0].iso3].indicators["SI.POV.GINI"][(pt ? 'source_pt' : 'source')]}
														</p>
													</div>
												}
												tab2Content={
													<div>
														<GroupedBar2
															{...props}
															pt={pt}
															wdi_data={props.wdi_data}
															country_order={props.country_order}
															field="SI.POV.GINI"
															values={5}
															formatter={'0.0a'}
														/>
														<p className="footnote">
															{text.source}: {props.wdi_data[props.country_order[0].iso3].indicators["SI.POV.GINI"][(pt ? 'source_pt' : 'source')]}
														</p>
													</div>
												}
											/>
										</div>
									</div>
									<h4 style={{ fontSize: '22px' }} className="pad-top">
										{props.wdi_data[props.country_order[0].iso3].indicators["SI.DST.02ND.20"][(pt ? 'name_pt' : 'name')]}
									</h4>
									<div className="row">
										<div className="col-xs-12">
											<p className="benchmarking-text">
												{props.wdi_data[props.country_order[0].iso3].indicators["SI.DST.02ND.20"][(pt ? 'source_note_pt' : 'source_note')]}
											</p>
										</div>
										<div className="col-xs-12">
											<GraphTabs
												{...props}
												pt={pt}
												tab1Name={props.wdi_data[props.country_order[0].iso3].indicators["SI.DST.02ND.20"].data[props.wdi_data[props.country_order[0].iso3].indicators["SI.DST.02ND.20"].data.length-1].year}
												tab2Name={text.historical}
												tab1Content={
													<div>
														<GroupedBar2
															{...props}
															pt={pt}
															wdi_data={props.wdi_data}
															country_order={props.country_order}
															field="SI.DST.02ND.20"
															values={1}
															formatter={'0.0a'}
														/>
														<p className="footnote">
															{text.source}: {props.wdi_data[props.country_order[0].iso3].indicators["SI.DST.02ND.20"][(pt ? 'source_pt' : 'source')]}
														</p>
													</div>
												}
												tab2Content={
													<div>
														<GroupedBar2
															{...props}
															pt={pt}
															wdi_data={props.wdi_data}
															country_order={props.country_order}
															field="SI.DST.02ND.20"
															values={5}
															formatter={'0.0a'}
														/>
														<p className="footnote">
															{text.source}: {props.wdi_data[props.country_order[0].iso3].indicators["SI.DST.02ND.20"][(pt ? 'source_pt' : 'source')]}
														</p>
													</div>
												}
											/>
										</div>
									</div>
									<hr />
								</div>


								<div id="section-two-subsection-one" style={visible['section-two-subsection-one'] ? {} : {'display':'none'}}>
									<h2 style={{ fontSize: '32px' }}>{text.real}</h2>
									<h4 style={{ fontSize: '22px' }} className="pad-top">
										{props.wdi_data[props.country_order[0].iso3].indicators["NY.GDP.MKTP.CD"][(pt ? 'name_pt' : 'name')]}
									</h4>
									<div className="row">
										<div className="col-xs-12">
											<p className="benchmarking-text">
												{props.wdi_data[props.country_order[0].iso3].indicators["NY.GDP.MKTP.CD"][(pt ? 'source_note_pt' : 'source_note')]}
											</p>
										</div>
										<div className="col-xs-12">
											<GraphTabs
												{...props}
												pt={pt}
												tab1Name={props.wdi_data[props.country_order[0].iso3].indicators["NY.GDP.MKTP.CD"].data[props.wdi_data[props.country_order[0].iso3].indicators["NY.GDP.MKTP.CD"].data.length-1].year}
												tab2Name={text.historical}
												tab1Content={
													<div>
														<GroupedBar2
															{...props}
															pt={pt}
															wdi_data={props.wdi_data}
															country_order={props.country_order}
															field="NY.GDP.MKTP.CD"
															values={1}
															formatter={'0.0a'}
														/>
														<p className="footnote">
															{text.source}: {props.wdi_data[props.country_order[0].iso3].indicators["NY.GDP.MKTP.CD"][(pt ? 'source_pt' : 'source')]}
														</p>
													</div>
												}
												tab2Content={
													<div>
														<GroupedBar2
															{...props}
															pt={pt}
															wdi_data={props.wdi_data}
															country_order={props.country_order}
															field="NY.GDP.MKTP.CD"
															values={5}
															formatter={'0.0a'}
														/>
														<p className="footnote">
															{text.source}: {props.wdi_data[props.country_order[0].iso3].indicators["NY.GDP.MKTP.CD"][(pt ? 'source_pt' : 'source')]}
														</p>
													</div>
												}
											/>
										</div>
									</div>
									<h4 style={{ fontSize: '22px' }} className="pad-top">
										{props.wdi_data[props.country_order[0].iso3].indicators["NY.GDP.PCAP.CD"][(pt ? 'name_pt' : 'name')]}
									</h4>
									<div className="row">
										<div className="col-xs-12">
											<p className="benchmarking-text">
												{props.wdi_data[props.country_order[0].iso3].indicators["NY.GDP.PCAP.CD"][(pt ? 'source_note_pt' : 'source_note')]}
											</p>
										</div>
										<div className="col-xs-12">
											<GraphTabs
												{...props}
												pt={pt}
												activeKey="second"
												tab1Name={props.wdi_data[props.country_order[0].iso3].indicators["NY.GDP.PCAP.CD"].data[props.wdi_data[props.country_order[0].iso3].indicators["NY.GDP.PCAP.CD"].data.length-1].year}
												tab2Name={text.historical}
												tab1Content={
													<div>
														<GroupedBar2
															{...props}
															pt={pt}
															wdi_data={props.wdi_data}
															country_order={props.country_order}
															field="NY.GDP.PCAP.CD"
															values={1}
															formatter={'0,0.00'}
														/>
														<p className="footnote">
															{text.source}: {props.wdi_data[props.country_order[0].iso3].indicators["NY.GDP.PCAP.CD"][(pt ? 'source_pt' : 'source')]}
														</p>
													</div>
												}
												tab2Content={
													<div>
														<GroupedBar
															{...props}
															pt={pt}
															wdi_data={props.wdi_data}
															country_order={props.country_order}
															field="NY.GDP.PCAP.CD"
															values={5}
															formatter={'0,0.00'}
														/>
														<p className="footnote">
															{text.source}: {props.wdi_data[props.country_order[0].iso3].indicators["NY.GDP.PCAP.CD"][(pt ? 'source_pt' : 'source')]}
														</p>
													</div>
												}
											/>
										</div>
									</div>
									<h4 style={{ fontSize: '22px' }} className="pad-top">
										{props.wdi_data[props.country_order[0].iso3].indicators["NY.GDP.MKTP.KD.ZG"][(pt ? 'name_pt' : 'name')]}
									</h4>
									<div className="row">
										<div className="col-xs-12">
											<p className="benchmarking-text">
												{props.wdi_data[props.country_order[0].iso3].indicators["NY.GDP.MKTP.KD.ZG"][(pt ? 'source_note_pt' : 'source_note')]}
											</p>
										</div>
										<div className="col-xs-12">
											<LineChart
												{...props}
												pt={pt}
												wdi_data={props.wdi_data}
												country_order={props.country_order}
												field="NY.GDP.MKTP.KD.ZG"
												values={20}
											/>
											<br />
											<p className="footnote">
												{text.source}: {props.wdi_data[props.country_order[0].iso3].indicators["NY.GDP.MKTP.KD.ZG"][(pt ? 'source_pt' : 'source')]}
											</p>
										</div>
									</div>
									<h4 style={{ fontSize: '22px' }} className="pad-top">
										{props.wdi_data[props.country_order[0].iso3].indicators["FP.CPI.TOTL.ZG"][(pt ? 'name_pt' : 'name')]}
									</h4>
									<div className="row">
										<div className="col-xs-12">
											<p className="benchmarking-text">
												{props.wdi_data[props.country_order[0].iso3].indicators["FP.CPI.TOTL.ZG"][(pt ? 'source_note_pt' : 'source_note')]}
											</p>
										</div>
										<div className="col-xs-12">
											<GraphTabs
												{...props}
												pt={pt}
												tab1Name={props.wdi_data[props.country_order[0].iso3].indicators["FP.CPI.TOTL.ZG"].data[props.wdi_data[props.country_order[0].iso3].indicators["FP.CPI.TOTL.ZG"].data.length-1].year}
												tab2Name={text.historical}
												tab1Content={
													<div>
														<GroupedBar
															{...props}
															pt={pt}
															wdi_data={props.wdi_data}
															country_order={props.country_order}
															field="FP.CPI.TOTL.ZG"
															values={1}
															formatter={'0,0.00'}
														/>
														<p className="footnote">
															{text.source}: {props.wdi_data[props.country_order[0].iso3].indicators["FP.CPI.TOTL.ZG"][(pt ? 'source_pt' : 'source')]}
														</p>
													</div>
												}
												tab2Content={
													<div>
														<GroupedBar2
															{...props}
															pt={pt}
															wdi_data={props.wdi_data}
															country_order={props.country_order}
															field="FP.CPI.TOTL.ZG"
															values={5}
															formatter={'0,0.00'}
														/>
														<p className="footnote">
															{text.source}: {props.wdi_data[props.country_order[0].iso3].indicators["FP.CPI.TOTL.ZG"][(pt ? 'source_pt' : 'source')]}
														</p>
													</div>
												}
											/>
										</div>
									</div>
									<h4 style={{ fontSize: '22px' }} className="pad-top">
										{props.wdi_data[props.country_order[0].iso3].indicators["NY.GDP.PETR.RT.ZS"][(pt ? 'name_pt' : 'name')]}
									</h4>
									<div className="row">
										<div className="col-xs-12">
											<p className="benchmarking-text">
												{props.wdi_data[props.country_order[0].iso3].indicators["NY.GDP.PETR.RT.ZS"][(pt ? 'source_note_pt' : 'source_note')]}
											</p>
										</div>
										<div className="col-xs-12">
											<GraphTabs
												{...props}
												pt={pt}
												activeKey="second"
												tab1Name={props.wdi_data[props.country_order[0].iso3].indicators["NY.GDP.PETR.RT.ZS"].data[props.wdi_data[props.country_order[0].iso3].indicators["NY.GDP.PETR.RT.ZS"].data.length-1].year}
												tab2Name={text.historical}
												tab1Content={
													<div>
														<GroupedBar2
															{...props}
															pt={pt}
															wdi_data={props.wdi_data}
															country_order={props.country_order}
															field="NY.GDP.PETR.RT.ZS"
															values={1}
															formatter={'0,0.00'}
														/>
														<p className="footnote">
															{text.source}: {props.wdi_data[props.country_order[0].iso3].indicators["NY.GDP.PETR.RT.ZS"][(pt ? 'source_pt' : 'source')]}
														</p>
													</div>
												}
												tab2Content={
													<div>
														<GroupedBar
															{...props}
															pt={pt}
															wdi_data={props.wdi_data}
															country_order={props.country_order}
															field="NY.GDP.PETR.RT.ZS"
															values={5}
															formatter={'0,0.00'}
														/>
														<p className="footnote">
															{text.source}: {props.wdi_data[props.country_order[0].iso3].indicators["NY.GDP.PETR.RT.ZS"][(pt ? 'source_pt' : 'source')]}
														</p>
													</div>
												}
											/>
										</div>
									</div>
									<hr />
								</div>

								<div id="section-two-subsection-two" style={visible['section-two-subsection-two'] ? {} : {'display':'none'}}>
									<h2 style={{ fontSize: '32px' }}>{text.external}</h2>
									<h4 style={{ fontSize: '22px' }} className="pad-top">
										{props.wdi_data[props.country_order[0].iso3].indicators["BN.CAB.XOKA.GD.ZS"][(pt ? 'name_pt' : 'name')]}
									</h4>
									<div className="row">
										<div className="col-xs-12">
											<p className="benchmarking-text">
												{props.wdi_data[props.country_order[0].iso3].indicators["BN.CAB.XOKA.GD.ZS"][(pt ? 'source_note_pt' : 'source_note')]}
											</p>
										</div>
										<div className="col-xs-12">
											<GraphTabs
												{...props}
												pt={pt}
												tab1Name={props.wdi_data[props.country_order[0].iso3].indicators["BN.CAB.XOKA.GD.ZS"].data[props.wdi_data[props.country_order[0].iso3].indicators["BN.CAB.XOKA.GD.ZS"].data.length-1].year}
												tab2Name={text.historical}
												tab1Content={
													<div>
														<GroupedBar
															{...props}
															pt={pt}
															wdi_data={props.wdi_data}
															country_order={props.country_order}
															field="BN.CAB.XOKA.GD.ZS"
															values={1}
															formatter={'0,0.00'}
														/>
														<p className="footnote">
															{text.source}: {props.wdi_data[props.country_order[0].iso3].indicators["BN.CAB.XOKA.GD.ZS"][(pt ? 'source_pt' : 'source')]}
														</p>
													</div>
												}
												tab2Content={
													<div>
														<GroupedBar2
															{...props}
															pt={pt}
															wdi_data={props.wdi_data}
															country_order={props.country_order}
															field="BN.CAB.XOKA.GD.ZS"
															values={5}
															formatter={'0,0.00'}
														/>
														<p className="footnote">
															{text.source}: {props.wdi_data[props.country_order[0].iso3].indicators["BN.CAB.XOKA.GD.ZS"][(pt ? 'source_pt' : 'source')]}
														</p>
													</div>
												}
											/>
										</div>
									</div>
									<hr />
								</div>
								<div id="section-two-subsection-four" style={visible['section-two-subsection-four'] ? {} : {'display':'none'}}>
									<h2 style={{ fontSize: '32px' }}>{text.finance}</h2>
									<h4 style={{ fontSize: '22px' }} className="pad-top">
										{props.wdi_data[props.country_order[0].iso3].indicators["FB.AST.NPER.ZS"][(pt ? 'name_pt' : 'name')]}
									</h4>
									<div className="row">
										<div className="col-xs-12">
											<p className="benchmarking-text">
												{props.wdi_data[props.country_order[0].iso3].indicators["FB.AST.NPER.ZS"][(pt ? 'source_note_pt' : 'source_note')]}
											</p>
										</div>
										<div className="col-xs-12">
											<GraphTabs
												{...props}
												pt={pt}
												tab1Name={props.wdi_data[props.country_order[0].iso3].indicators["FB.AST.NPER.ZS"].data[props.wdi_data[props.country_order[0].iso3].indicators["FB.AST.NPER.ZS"].data.length-1].year}
												tab2Name={text.historical}
												tab1Content={
													<div>
														<GroupedBar
															{...props}
															pt={pt}
															wdi_data={props.wdi_data}
															country_order={props.country_order}
															field="FB.AST.NPER.ZS"
															values={1}
															formatter={'0,0.00'}
														/>
														<p className="footnote">
															{text.source}: {props.wdi_data[props.country_order[0].iso3].indicators["FB.AST.NPER.ZS"][(pt ? 'source_pt' : 'source')]}
														</p>
													</div>
												}
												tab2Content={
													<div>
														<GroupedBar2
															{...props}
															pt={pt}
															wdi_data={props.wdi_data}
															country_order={props.country_order}
															field="FB.AST.NPER.ZS"
															values={5}
															formatter={'0,0.00'}
														/>
														<p className="footnote">
															{text.source}: {props.wdi_data[props.country_order[0].iso3].indicators["FB.AST.NPER.ZS"][(pt ? 'source_pt' : 'source')]}
														</p>
													</div>
												}
											/>
										</div>
									</div>
									<hr />
								</div>
								<div id="section-two-subsection-five" style={visible['section-two-subsection-five'] ? {} : {'display':'none'}}>
									<h2 style={{ fontSize: '32px' }}>{text.public}</h2>
									<h4 style={{ fontSize: '22px' }} className="pad-top">
										{props.wdi_data[props.country_order[0].iso3].indicators["GC.NLD.TOTL.GD.ZS"][(pt ? 'name_pt' : 'name')]}
									</h4>
									<div className="row">
										<div className="col-xs-12">
											<p className="benchmarking-text">
												{props.wdi_data[props.country_order[0].iso3].indicators["GC.NLD.TOTL.GD.ZS"][(pt ? 'source_note_pt' : 'source_note')]}
											</p>
										</div>
										<div className="col-xs-12">
											<GraphTabs
												{...props}
												pt={pt}
												tab1Name={props.wdi_data[props.country_order[0].iso3].indicators["GC.NLD.TOTL.GD.ZS"].data[props.wdi_data[props.country_order[0].iso3].indicators["GC.NLD.TOTL.GD.ZS"].data.length-1].year}
												tab2Name={text.historical}
												tab1Content={
													<div>
														<GroupedBar
															{...props}
															pt={pt}
															wdi_data={props.wdi_data}
															country_order={props.country_order}
															field="GC.NLD.TOTL.GD.ZS"
															values={1}
															formatter={'0,0.00'}
														/>
														<p className="footnote">
															{text.source}: {props.wdi_data[props.country_order[0].iso3].indicators["GC.NLD.TOTL.GD.ZS"][(pt ? 'source_pt' : 'source')]}
														</p>
													</div>
												}
												tab2Content={
													<div>
														<GroupedBar2
															{...props}
															pt={pt}
															wdi_data={props.wdi_data}
															country_order={props.country_order}
															field="GC.NLD.TOTL.GD.ZS"
															values={5}
															formatter={'0,0.00'}
														/>
														<p className="footnote">
															{text.source}: {props.wdi_data[props.country_order[0].iso3].indicators["GC.NLD.TOTL.GD.ZS"][(pt ? 'source_pt' : 'source')]}
														</p>
													</div>
												}
											/>
										</div>
									</div>
									<h4 style={{ fontSize: '22px' }} className="pad-top">
										{props.wdi_data[props.country_order[0].iso3].indicators["GC.REV.XGRT.GD.ZS"][(pt ? 'name_pt' : 'name')]}
									</h4>
									<div className="row">
										<div className="col-xs-12">
											<p className="benchmarking-text">
												{props.wdi_data[props.country_order[0].iso3].indicators["GC.REV.XGRT.GD.ZS"][(pt ? 'source_note_pt' : 'source_note')]}
											</p>
										</div>
										<div className="col-xs-12">
											<GraphTabs
												{...props}
												pt={pt}
												activeKey="second"
												tab1Name={props.wdi_data[props.country_order[0].iso3].indicators["GC.REV.XGRT.GD.ZS"].data[props.wdi_data[props.country_order[0].iso3].indicators["GC.REV.XGRT.GD.ZS"].data.length-1].year}
												tab2Name={text.historical}
												tab1Content={
													<div>
														<GroupedBar2
															{...props}
															pt={pt}
															wdi_data={props.wdi_data}
															country_order={props.country_order}
															field="GC.REV.XGRT.GD.ZS"
															values={1}
															formatter={'0,0.00'}
														/>
														<p className="footnote">
															{text.source}: {props.wdi_data[props.country_order[0].iso3].indicators["GC.REV.XGRT.GD.ZS"][(pt ? 'source_pt' : 'source')]}
														</p>
													</div>
												}
												tab2Content={
													<div>
														<GroupedBar
															{...props}
															pt={pt}
															wdi_data={props.wdi_data}
															country_order={props.country_order}
															field="GC.REV.XGRT.GD.ZS"
															values={5}
															formatter={'0,0.00'}
														/>
														<p className="footnote">
															{text.source}: {props.wdi_data[props.country_order[0].iso3].indicators["GC.REV.XGRT.GD.ZS"][(pt ? 'source_pt' : 'source')]}
														</p>
													</div>
												}
											/>
										</div>
									</div>
									<h4 style={{ fontSize: '22px' }} className="pad-top">
										{props.wdi_data[props.country_order[0].iso3].indicators["NE.CON.GOVT.ZS"][(pt ? 'name_pt' : 'name')]}
									</h4>
									<div className="row">
										<div className="col-xs-12">
											<p className="benchmarking-text">
												{props.wdi_data[props.country_order[0].iso3].indicators["NE.CON.GOVT.ZS"][(pt ? 'source_note_pt' : 'source_note')]}
											</p>
										</div>
										<div className="col-xs-12">
											<GraphTabs
												{...props}
												pt={pt}
												tab1Name={props.wdi_data[props.country_order[0].iso3].indicators["NE.CON.GOVT.ZS"].data[props.wdi_data[props.country_order[0].iso3].indicators["NE.CON.GOVT.ZS"].data.length-1].year}
												tab2Name={text.historical}
												tab1Content={
													<div>
														<GroupedBar
															{...props}
															pt={pt}
															wdi_data={props.wdi_data}
															country_order={props.country_order}
															field="NE.CON.GOVT.ZS"
															values={1}
															formatter={'0,0.00'}
														/>
														<p className="footnote">
															{text.source}: {props.wdi_data[props.country_order[0].iso3].indicators["NE.CON.GOVT.ZS"][(pt ? 'source_pt' : 'source')]}
														</p>
													</div>
												}
												tab2Content={
													<div>
														<GroupedBar2
															{...props}
															pt={pt}
															wdi_data={props.wdi_data}
															country_order={props.country_order}
															field="NE.CON.GOVT.ZS"
															values={5}
															formatter={'0,0.00'}
														/>
														<p className="footnote">
															{text.source}: {props.wdi_data[props.country_order[0].iso3].indicators["NE.CON.GOVT.ZS"][(pt ? 'source_pt' : 'source')]}
														</p>
													</div>
												}
											/>
										</div>
									</div>
									<h4 style={{ fontSize: '22px' }} className="pad-top">
										{props.wdi_data[props.country_order[0].iso3].indicators["GC.DOD.TOTL.GD.ZS"][(pt ? 'name_pt' : 'name')]}
									</h4>
									<div className="row">
										<div className="col-xs-12">
											<p className="benchmarking-text">
												{props.wdi_data[props.country_order[0].iso3].indicators["GC.DOD.TOTL.GD.ZS"][(pt ? 'source_note_pt' : 'source_note')]}
											</p>
										</div>
										<div className="col-xs-12">
											<GraphTabs
												{...props}
												pt={pt}
												tab1Name={props.wdi_data[props.country_order[0].iso3].indicators["GC.DOD.TOTL.GD.ZS"].data[props.wdi_data[props.country_order[0].iso3].indicators["GC.DOD.TOTL.GD.ZS"].data.length-1].year}
												tab2Name={text.historical}
												tab1Content={
													<div>
														<GroupedBar
															{...props}
															pt={pt}
															wdi_data={props.wdi_data}
															country_order={props.country_order}
															field="GC.DOD.TOTL.GD.ZS"
															values={1}
															formatter={'0,0.00'}
														/>
														<p className="footnote">
															{text.source}: {props.wdi_data[props.country_order[0].iso3].indicators["GC.DOD.TOTL.GD.ZS"][(pt ? 'source_pt' : 'source')]}
														</p>
													</div>
												}
												tab2Content={
													<div>
														<GroupedBar2
															{...props}
															pt={pt}
															wdi_data={props.wdi_data}
															country_order={props.country_order}
															field="GC.DOD.TOTL.GD.ZS"
															values={5}
															formatter={'0,0.00'}
														/>
														<p className="footnote">
															{text.source}: {props.wdi_data[props.country_order[0].iso3].indicators["GC.DOD.TOTL.GD.ZS"][(pt ? 'source_pt' : 'source')]}
														</p>
													</div>
												}
											/>
										</div>
									</div>
									<hr />
								</div>
							</div>
						</Collapse>
					</div>
				</div>
			</div>
		);
	} else {
		return (
			<div className="section-container" style={visible['section-two'] ? {} : {'display':'none'}}>
				<div className="row">
					<div className="col-md-12"><hr /></div>
				</div>
				<div id="section-two-subsection-zero"></div>
				<div id="section-two-subsection-one"></div>
				<div id="section-two-subsection-two"></div>
				<div id="section-two-subsection-three"></div>
				<div id="section-two-subsection-four"></div>
				<div id="section-two-subsection-five"></div>
			</div>
		);
	}
};

export default Economy;
