import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import mapboxgl from 'mapbox-gl';
import Compare from 'mapbox-gl-compare';

import '../common/map/Map.css';
import {API_URL} from "../../../config/api";

const textEnglish = {
	'title': 'Deforestation Comparison',
	'subtitle': 'Landsat Bands 6,5,4 Courtesy of Google Earth Engine'
}

const textPortuguese = {
	'title': 'Comparação do desmatamento',
	'subtitle': 'Bandas Landsat 6,5,4 Cortesia de Google Earth Engine'
}

const SwiperMap = (props) => {
	useEffect(() => {
		createSwiperMap();
	}, []);

	const createSwiperMap = () => {
		mapboxgl.accessToken = 'pk.eyJ1IjoiZ3NkcG0iLCJhIjoiY2thMmw0djZoMDBoajNnanZjdmo1YmJyZCJ9.dFE9IA22oAMiRu7gA6-JXQ';

		const beforeMap = new mapboxgl.Map({
			container: 'before',
			style: 'mapbox://styles/mapbox/streets-v11',
			center: [-59.87, -6.97],
			zoom: 10,
			attributionControl: false
		});

		beforeMap.addControl(new mapboxgl.FullscreenControl({ container: document.getElementById('swiper-wrapper') }), 'top-right');
		beforeMap.addControl(new mapboxgl.NavigationControl(), 'top-right');

		const afterMap = new mapboxgl.Map({
			container: 'after',
			style: 'mapbox://styles/mapbox/streets-v11',
			center: [-59.87, -6.97],
			zoom: 10,
			attributionControl: false
		});

		afterMap.addControl(new mapboxgl.FullscreenControl({ container: document.getElementById('swiper-wrapper') }), 'top-right');
		afterMap.addControl(new mapboxgl.NavigationControl(), 'top-right');

		beforeMap.on('load', function () {
			axios.get(`${API_URL}/layers/landsat1`).then(result => {
				const layer = {
					id: 'before_deforestation',
					type: 'raster',
					source: {
						type: 'raster',
						tiles: [
							result.data
						],
						tileSize: 256
					},
					paint: {}
				};

				beforeMap.addLayer(layer);
			}).catch((error) => { });

			afterMap.on('load', function () {
				axios.get(`${API_URL}/layers/landsat2`).then(result => {
					const layer = {
						id: 'after_deforestation',
						type: 'raster',
						source: {
							type: 'raster',
							tiles: [
								result.data
							],
							tileSize: 256
						},
						paint: {}
					};

					afterMap.addLayer(layer);
				}).catch((error) => { });
			});

			const compareMap = new Compare(beforeMap, afterMap, '#swiper-wrapper', {
				// Set this to enable comparing two maps by mouse movement:
				// mousemove: true
			});
		});
	};

	let text = {};
	if (props.location) {
		if (props.location.pathname.substring(0, 4) !== '/pt/') {
			text = textEnglish;
		} else {
			text = textPortuguese;
		}
	} else {
		text = textEnglish;
	}

	return (
		<div style={{height: '100%'}}>
			<div style={{height: '100%',width: '100%'}} className="col-lg-12">
				<div style={{textAlign: 'center', fontWeight: 'bold', height: '20px'}} className="row">{text.title}</div>
				<div style={{textAlign: 'center', height: '20px'}} className="row">{text.subtitle}</div>
				<div style={{height: 'calc(100% - (60px))',width: '100%'}} className="row">
					<div style={{height: '100%',width: '100%'}} id="swiper-wrapper">
						<div id="before" className="swiper-map"></div>
						<div id="after" className="swiper-map"></div>
					</div>
				</div>
				<div style={{height: '20px', bottom: '0'}} className="row">
					<div style={{textAlign: 'center'}} className="col-xs-6">2014</div>
					<div style={{textAlign: 'center'}} className="col-xs-6">2020</div>
				</div>
			</div>
		</div>
	);
}

export default withRouter(SwiperMap);
