import React from 'react'
import { Link } from 'react-router-dom'
import './styles.css'

const AltButton = ({ onClick, inverse, children, href, style, type = 'primary' }) => {
  return (
    <Link 
      id="loop_alt_button" 
      to={href ? href : '#'}
      onClick={onClick}
      className={`lp__btn lp__btn_sm lp__btn_${type}${inverse ? '_inverse' : ''}`}
      style={{ ...style }}
    >
      {children}
    </Link>
  )
}

export default AltButton
