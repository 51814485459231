import React, { Component } from 'react';
import _ from 'lodash';
import BenchmarkingHeaderTriple from './benchmarkingHeader/BenchmarkingHeaderTriple';
import BenchmarkingContentTriple from './benchmarkingContent/BenchmarkingContentTriple';
import BenchmarkingFooter from '../menu/WelcomeFooter';
import NavbarPLR from '../common/navbar/NavbarPLR';
import { SECTION_MAPPINGS_TRIPLE } from './SectionMappings';

const visibleDefault = {'section-two': true,'section-two-subsection-zero': true, 'section-two-subsection-one': true,
'section-two-subsection-two': true,'section-two-subsection-three': true,'section-two-subsection-four': true,'section-two-subsection-five': true,
'section-one': true,'section-one-demography': true,'section-one-subsection-zero': true,'section-one-subsection-one': true,'section-one-subsection-two': true, 'section-one-subsection-three': true,
'section-three': true,'section-three-subsection-zero': true,'section-three-subsection-one': true,'section-three-subsection-two': true,
'section-four': true, 'section-four-subsection-zero': true, 'section-four-subsection-one': true };

export default class Three extends Component {
	constructor(props) {
		super(props);
		let mainSection = 'section-one';
		let subSection = null;
		let sections = {};
		for (let section in SECTION_MAPPINGS_TRIPLE) {
			sections[section] = false;
		}

		let isMobile = window.innerWidth < 768;

		this.state = {
			mainSection: mainSection,
			subSection: subSection,
			navbarType: 'navbar-alt',
			sections: sections,
			isMobile: isMobile,
			showThemesModal: false,
			visibleSections: visibleDefault,
			lastVisible: 'section-four'
		}

		this.removeCountry = this.removeCountry.bind(this);
		this.expandify = this.expandify.bind(this);
		this.handleScroll = this.handleScroll.bind(this);
		this.goToSection = this.goToSection.bind(this);
		this.goToSectionDeux = this.goToSectionDeux.bind(this);
		this.updateDimensions = this.updateDimensions.bind(this);
		this.openThemesModal = this.openThemesModal.bind(this);
		this.closeThemesModal = this.closeThemesModal.bind(this);
		this.setVisible = this.setVisible.bind(this);
		this.scrollOptimize = this.scrollOptimize.bind(this);
		
	}

	setVisible(visible) {
		let lastVisible = 'section-one';
		if (visible['section-five'] === true) {
			lastVisible = 'section-five';
		} else if (visible['section-four'] === true) {
			lastVisible = 'section-four';
		} else if (visible['section-three'] === true) {
			lastVisible = 'section-three';
		} else if (visible['section-two'] === true) {
			lastVisible = 'section-two';
		}
		this.setState({ 'visibleSections': visible, lastVisible });
	}

	openThemesModal() {
		this.setState({ 'showThemesModal': true });
	}

	closeThemesModal() {
        this.setState({ 'showThemesModal': false });
	}

	removeCountry(countryId) {
		this.props.removeCountry(countryId);
	}

	expandify(sectionId) {
		let sections = {...this.state.sections};
		sections[sectionId] = !this.state.sections[sectionId];
		this.setState({ sections });
	}

	updateDimensions() {
		let isMobile = window.innerWidth < 768;
		if (this.state.isMobile !== isMobile) {
			this.setState({ isMobile: isMobile });
		}
	}

	componentDidMount() {
		window.scrollTo(0,0);
	    window.addEventListener('scroll', this.scrollOptimize);
	    window.addEventListener("resize", this.updateDimensions);
	}

	componentWillUnmount() {
	    window.removeEventListener('scroll', this.scrollOptimize);
	    window.removeEventListener("resize", this.updateDimensions);
	}

	findPos(elem) {
	    var curtop = 0;
	    if (elem.offsetParent) {
	        do {
	            curtop += elem.offsetTop;
	        } while (elem = elem.offsetParent);
	    	return [curtop];
	    }
	}

	goToSection(section) {
		let sections = {...this.state.sections}
		sections[section] = true;
		this.setState({ sections });

		// Have to account for the delay in expanding a section, otherwise will not scroll to bottom of page
		// (for items that are at the bottom of the content and therefore cannot be aligned at the top of the page)
		let topHeight = this.state.isMobile ? 118 : 63;
		setTimeout(()=> {
			window.scroll(0, this.findPos(document.getElementById(section))-topHeight)
		}, 250);
	}

	goToSectionDeux(section, parent) {
		let sections = {...this.state.sections}
		sections[section] = true;
		sections[parent] = true;
		this.setState({ sections });

		// Have to account for the delay in expanding a section, otherwise will not scroll to bottom of page
		// (for items that are at the bottom of the content and therefore cannot be aligned at the top of the page)
		let topHeight = this.state.isMobile ? 118 : 63;
		setTimeout(()=> {
			window.scroll(0, this.findPos(document.getElementById(section))-topHeight)
		}, 250);
	}

	scrollOptimize() {
    	window.requestAnimationFrame(() => {
      		this.handleScroll()
    	});
	}

	handleScroll() {
		// Distance in px that we have scrolled down the page
		let top = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0; //top of the scroll bar (all the way up is 0)
		// if page is scrolled to within 1 fixed navbar (65px or 120px) of the bottom of the navigation (nav is 750px), then we used the sticky navbar
		let navHeight = this.state.isMobile ? 120 : 65;

		let headerHeight = 1026;

		if (document.documentElement.clientHeight < 850) {
			headerHeight = 716;
		} else if (document.documentElement.clientHeight < 1024) {
			headerHeight = 756;
		}

		if (top > headerHeight - navHeight) {
			// html id of the current main section
			let mainSection = this.state.mainSection;
			let currentSection = null;
			let currentSectionValue = null;
			let sectionValue = -9999;

			// html id of the current sub section
			let subSection = this.state.subSection;
			let currentSubsection = null;
			let currentSubsectionValue = null;
			let subsectionValue = -9999;

			for (let s in SECTION_MAPPINGS_TRIPLE) {
				if (this.state.visibleSections[s]) {
					currentSection = s;
					currentSectionValue = document.getElementById(s)?.getBoundingClientRect()?.top;
					if (currentSectionValue && currentSectionValue <= navHeight) {
						mainSection = s;
						if (SECTION_MAPPINGS_TRIPLE[mainSection]['subSections'].length > 0) {
							subSection = SECTION_MAPPINGS_TRIPLE[mainSection]['subSections'][0].name;
						};
						if (mainSection == this.state.lastVisible) {
							if (SECTION_MAPPINGS_TRIPLE[mainSection]['subSections'].length > 0) {
								for (let subsection in SECTION_MAPPINGS_TRIPLE[mainSection]['subSections']) {
									if (this.state.visibleSections[SECTION_MAPPINGS_TRIPLE[mainSection]['subSections'][subsection].id]) {
										currentSubsection = SECTION_MAPPINGS_TRIPLE[mainSection]['subSections'][subsection].id;
										currentSubsectionValue = document.getElementById(currentSubsection).getBoundingClientRect().top;
										if (((parseInt(subsection) === 0 && currentSectionValue <= navHeight) || currentSubsectionValue <= navHeight) && currentSubsectionValue > subsectionValue) {
											subSection = SECTION_MAPPINGS_TRIPLE[mainSection]['subSections'][subsection].name;
											subsectionValue = currentSubsectionValue;
										}
									}
									if (subsection == SECTION_MAPPINGS_TRIPLE[mainSection]['subSections'].length-1) {
										if (!_.isEqual(this.state.navbarType, "navbar-main") || !_.isEqual(this.state.mainSection, mainSection) || !_.isEqual(this.state.subSection, subSection)) {
											this.setState({
												navbarType: "navbar-main",
												mainSection,
												subSection
											});
										}
									}
								}
							} else {
								if (!_.isEqual(this.state.navbarType, "navbar-main") || !_.isEqual(this.state.mainSection, mainSection) || !_.isEqual(this.state.subSection, subSection)) {
									this.setState({
										navbarType: "navbar-main",
										mainSection,
										subSection
									});
								}
							}
						}
					} else {
						if (SECTION_MAPPINGS_TRIPLE[mainSection]['subSections'].length > 0) {
							for (let subsection in SECTION_MAPPINGS_TRIPLE[mainSection]['subSections']) {
								if (this.state.visibleSections[SECTION_MAPPINGS_TRIPLE[mainSection]['subSections'][subsection].id]) {
									currentSubsection = SECTION_MAPPINGS_TRIPLE[mainSection]['subSections'][subsection].id;
									currentSubsectionValue = document.getElementById(currentSubsection).getBoundingClientRect().top;
									if (((parseInt(subsection) === 0 && currentSectionValue <= navHeight) || currentSubsectionValue <= navHeight) && currentSubsectionValue > subsectionValue) {
										subSection = SECTION_MAPPINGS_TRIPLE[mainSection]['subSections'][subsection].name;
										subsectionValue = currentSubsectionValue;
									}
								}
								if (subsection == SECTION_MAPPINGS_TRIPLE[mainSection]['subSections'].length-1) {
									if (!_.isEqual(this.state.navbarType, "navbar-main") || !_.isEqual(this.state.mainSection, mainSection) || !_.isEqual(this.state.subSection, subSection)) {
										this.setState({
											navbarType: "navbar-main",
											mainSection,
											subSection
										});
									}
								}
							}
						} else {
							if (!_.isEqual(this.state.navbarType, "navbar-main") || !_.isEqual(this.state.mainSection, mainSection)) {
								this.setState({
									navbarType: "navbar-main",
									mainSection
								});
							}
						}
						break;
					}
				}
			}

		// otherwise we use the absolutely positioned navbar without the dropdowns and export button
		} else {
			if (!_.isEqual(this.state.navbarType, "navbar-alt")) {
				this.setState({
					navbarType: "navbar-alt"
				});
			}
		}
	}

	render() {
		return (
			<div className="container-fluid unpadify" style={{ backgroundImage: 'linear-gradient(180deg, #f0f0f0 0%, #ffffff 100%)' }}>
				<NavbarPLR isHomepage={false} {...this.props} />
				<BenchmarkingHeaderTriple
					{...this.props}
					removeCountry={this.removeCountry}
					countries={this.props.countries}
					wdiData={this.props.wdiData}
					countryOrder={this.props.countryOrder}
				/>
				<BenchmarkingContentTriple
					{...this.props}
					text={this.props.text}
					countries={this.props.countries}
					country_order={this.props.countryOrder}
					wdi_data={this.props.wdiData}
					sections={this.state.sections}
					expandify={this.expandify}
					openThemesModal={this.openThemesModal}
					visibleSections={this.state.visibleSections}
				/>
				<BenchmarkingFooter {...this.props} />
			</div>
		);
	}
};
