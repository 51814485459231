import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { ProgressBar } from 'react-bootstrap';

import axios from 'axios';

import '../impact/Impact.css';

import LineGraph from '../common/graphs/LineGraph';
import BarGraph from '../common/graphs/BarGraph';
import ImpactMap from '../impact/ImpactMap';

import mapboxgl from 'mapbox-gl';
import Compare from 'mapbox-gl-compare';
import {API_URL} from "../../../config/api";

const textEnglish = {
	"login": "Login",
	"logout": "Log Out",
	"med": "Indicators",
	"page_desc_1": "This page provides information about concrete results achieved under the three Country Partnership Framework (CPF) ",
	"page_desc_link_text": "focus areas",
	"page_desc_link": "/strategy",
	"page_desc_2": ", measured by quantitative indicators. The data measuring these indicators leverage Big Data, Open Data, social media, remote sensing (e.g. satellite images), and internal World Bank Group monitoring systems. All indicators have baseline numbers associated with the beginning of the Country Partnership Framework (2016) and target numbers foreseen for the end (2024).",
	"page_desc_3": "Select a Focus Area and an Objective to explore our results below.",
	"add": "Add Data",
	"focus_icon_1": "Fiscal Consolidation Icon",
	"focus_label_1": "Focus Area 1",
	"focus_title_1": "Fiscal Consolidation and Government Effectiveness for Sustainable, Inclusive and Efficient Service Delivery",
	"focus_icon_2": "Private Sector Investment Icon",
	"focus_label_2": "Focus Area 2",
	"focus_title_2": "Private Sector Investment and Productive Growth",
	"focus_icon_3": "Inclusive and Sustainable Development Icon",
	"focus_label_3": "Focus Area 3",
	"focus_title_3": "Inclusive and Sustainable Development",
	"ontrack": "On Track",
	"notontrack": "Not On Track",
	"objective": "Objective",
	"indicator_label": "Indicator",
	"baseline": "Baseline",
	"progress": "Progress",
	"target": "Target",
	"toggle": "Change Status",
	"toggle_progress": "Toggle Progress Gauge (Unavailable if Target TBD)",
	"none": "None",
	"source": "Source"
}

const textPortuguese = {
	"login": "Login",
	"logout": "Log Out",
	"med": "Indicadores",
	"page_desc_1": "Esta página apresenta informações sobre os resultados concretos alcançados no âmbito das três ",
	"page_desc_link_text": "áreas de foco",
	"page_desc_link": "/strategy",
	"page_desc_2": " da Estratégia de Parceria com o País (CPF), medidos por indicadores quantitativos. Os dados que medem esses indicadores utilizam Big Data, Open Data, mídias sociais, sensoriamento remoto (por exemplo, imagens de satélite) e sistemas internos de monitoramento do Grupo Banco Mundial. Todos os indicadores têm números de linha de base associados ao início da Estrutura de Parceria de País (2016) e metas previstas para o final (2024).",
	"page_desc_3": "Selecione uma Área de Foco e um Objetivo para explorar os nossos resultados abaixo.",
	"add": "Add Data",
	"focus_icon_1": "Consolidação Fiscal Icon",
	"focus_label_1": "Área de Foco 1",
	"focus_title_1": "Consolidação Fiscal",
	"focus_icon_2": "Investimento do Setor Privado Icon",
	"focus_label_2": "Área de Foco 2",
	"focus_title_2": "Investimento do Setor Privado",
	"focus_icon_3": "Desenvolvimento Sustentável e Inclusivo Icon",
	"focus_label_3": "Área de Foco 3",
	"focus_title_3": "Desenvolvimento Sustentável e Inclusivo",
	"ontrack": "Em dia",
	"notontrack": "Atrasado",
	"objective": "Objetivo",
	"indicator_label": "Indicador",
	"baseline": "Linha de base",
	"progress": "Progresso",
	"target": "Meta",
	"toggle": "Change Status",
	"toggle_progress": "Toggle Progress Gauge (Unavailable if Target TBD)",
	"none": "Nenhum",
	"source": "Fonte"
}


class Impact extends Component {
	constructor(props) {
		super(props);

		this.state = {
			indicator: null,
			data_loaded: false,
			showontrack: true
		};

        this.handleLogout = this.handleLogout.bind(this);
    }

	handleLogout() {
		localStorage.removeItem('auth');
		this.props.history.push('/');
	}

	componentDidMount(){
		window.scrollTo(0,0);
		let indicator_id = this.props.location.pathname.split('/')[this.props.location.pathname.split('/').length - 1];
		axios.get(`${API_URL}/indicators`).then((response) => {
			let indicator = null;
			let ontrack = true;
			for (let i in Object.keys(response.data)) {
				let indicators = response.data[Object.keys(response.data)[i]].indicators;
				ontrack = response.data[Object.keys(response.data)[i]].ontrack_visible;
				for (let j in indicators) {
					if (indicators[j].indicator_id === indicator_id) {
						indicator = indicators[j];
					}
				}
			}
			this.setState({
				indicator: indicator,
				showontrack: ontrack,
				data_loaded: true
			})
		}).catch(() => {});
	}

	createSwiperMap() {
		mapboxgl.accessToken = 'pk.eyJ1IjoibWFwYm94IiwiYSI6ImNpejY4NXVycTA2emYycXBndHRqcmZ3N3gifQ.rJcFIG214AriISLbB6B5aw';
		let beforeMap = new mapboxgl.Map({
			container: 'before',
			style: 'mapbox://styles/mapbox/streets-v10',
			center: [-59.87, -6.97],
			zoom: 10
		});

		let afterMap = new mapboxgl.Map({
			container: 'after',
			style: 'mapbox://styles/mapbox/streets-v10',
			center: [-59.87, -6.97],
			zoom: 10
		});

		beforeMap.on('load', function() {
			beforeMap.addSource('before_deforestation', {
				"type": "image",
				"url": "/data/Deforestation_13July2019.jpg",
				"coordinates": [
					[-60.320337, -6.429236],
					[-59.449016, -6.429236],
					[-59.449016, -7.548859],
					[-60.320337, -7.548859]
				]
			});

			beforeMap.addLayer({
				'id': 'before_deforestation',
				'type': 'raster',
				'source': 'before_deforestation'
			});
		});

		afterMap.on('load', function() {
			afterMap.addSource('after_deforestation', {
				"type": "image",
				"url": "/data/Deforestation_15July2014.jpg",
				"coordinates": [
					[-60.320337, -6.429236],
					[-59.449016, -6.429236],
					[-59.449016, -7.548859],
					[-60.320337, -7.548859]
				]
			});

			afterMap.addLayer({
				'id': 'after_deforestation',
				'type': 'raster',
				'source': 'after_deforestation'
			});
		});

		this.compareMap = new Compare(beforeMap, afterMap, '#wrapper', {});
	}

	render() {
		let pt = false;
		let text = {};
		if (this.props.location.pathname.substring(0,4) !== "/pt/") {
			text = textEnglish;
		} else {
			text = textPortuguese;
			pt = true;
		}

		return (
			<div>
				<div className="container-indicator-graph unpadify">
				{this.state.data_loaded && this.state.indicator !== null && this.state.indicator.indicator_visible ? 
					<div id="content-container" className="impact-page">
						<div className="col-md-12">
							<div className="col-md-12">
								{[this.state.indicator].map((item, index) => {
									let graph_data = null;
									item.graph_data.sort(function(a, b){
										if(a.month < b.month) return -1;
										if(a.month > b.month) return 1;
										return 0;
									});
									item.graph_data.sort(function(a, b){
										if(a.year < b.year) return -1;
										if(a.year > b.year) return 1;
										return 0;
									});
									for (let i = 0; i < item.graph_data.length; i++) {
										let data_point = item.graph_data[i];
										if (data_point !== null) {
											if (data_point.category === "Main") {
												if (graph_data === null) {
													graph_data = [];
												}
												graph_data.push(data_point);
											} else {
												if (graph_data === null) {
													graph_data = {};
												}
												let category = pt ? data_point.category_pt : data_point.category;
												if (Object.keys(graph_data).indexOf(category) < 0) {
													graph_data[category] = [];
													graph_data[category].push(data_point);
												} else {
													graph_data[category].push(data_point);
												}
											}
										}
									}
									let target_data = null;
									let targets = {};
									for (let i = 0; i < item.targets.length; i++) {
										let data_point = item.targets[i];
										if (data_point !== null) {
											if (data_point.category === "Main") {
												target_data = data_point;
												targets['Main'] = text.target + ' (' + data_point.year + '): ' + (data_point.value !== null ? data_point.value.toLocaleString(pt ? 'pt-BR':'en-US') : (pt ? 'a definir' : 'TBD')) + ' ' + (item.unit !== null && data_point.value !== null ? (pt ? item.unit_pt.replace('Número de ','').replace('N° de ','') : item.unit.replace('# of ','')) : '');
											} else {
												if (target_data === null) {
													target_data = {};
												} 
												let category = pt ? data_point.category_pt : data_point.category
												target_data[category] = data_point;
												targets[category] = text.target + ' (' + data_point.year + '): ' + (data_point.value !== null ? data_point.value.toLocaleString(pt ? 'pt-BR':'en-US') : (pt ? 'a definir' : 'TBD')) + ' ' + (item.type === "dual_scale" ? category : item.unit !== null ? (pt ? item.unit_pt.replace('Número de ','').replace('N° de ','') : item.unit.replace('# of ','')) : '');
											}
										}
									}

									let baselines = {};
									let progress = {};
									let percentage = null;
									if (graph_data !== null) {
										if (Array.isArray(graph_data)) {
											if (graph_data.length === 1) {
												baselines['Main'] = text.baseline + ' (' + graph_data[0].year + '): ' + graph_data[0].value.toLocaleString(pt ? 'pt-BR':'en-US') + ' ' + (item.unit !== null ? (pt ? item.unit_pt.replace('Número de ','').replace('N° de ','') : item.unit.replace('# of ','')) : '');
												percentage = 0;
											} else {
												baselines['Main'] = text.baseline + ' (' + graph_data[0].year + '): ' + graph_data[0].value.toLocaleString(pt ? 'pt-BR':'en-US') + ' ' + (item.unit !== null ?(pt ? item.unit_pt.replace('Número de ','').replace('N° de ','') : item.unit.replace('# of ','')) : '');
												progress['Main'] = text.progress + ' (' + graph_data[graph_data.length - 1].year + '): ' + graph_data[graph_data.length - 1].value.toLocaleString(pt ? 'pt-BR':'en-US') + ' ' + (item.unit !== null ? (pt ? item.unit_pt.replace('Número de ','').replace('N° de ','') : item.unit.replace('# of ','')) : '');
												let change = graph_data[graph_data.length - 1].value - graph_data[0].value;
												let targetVBaseline = item.targets[0].value - graph_data[0].value;
												if (targetVBaseline === 0) {
													percentage = 0;
												} else {
													percentage = change / targetVBaseline;
												}
											}
										} else {
											for (let i = 0; i < Object.keys(graph_data).length; i++) {
												let key = Object.keys(graph_data)[i];
												if (graph_data[key].length === 1) {
													baselines[key] = text.baseline + ' (' + graph_data[key][0].year + '): ' + graph_data[key][0].value.toLocaleString(pt ? 'pt-BR':'en-US') + ' ' + (item.unit !== null ? (pt ? item.unit_pt.replace('Número de ','').replace('N° de ','') : item.unit.replace('# of ','')) : '');
												} else {
													baselines[key] = text.baseline + ' (' + graph_data[key][0].year + '): ' + graph_data[key][0].value.toLocaleString(pt ? 'pt-BR':'en-US') + ' ' + (item.type === "dual_scale" ? key : item.unit !== null ? (pt ? item.unit_pt.replace('Número de ','').replace('N° de ','') : item.unit.replace('# of ','')) : '');
													progress[key] = text.progress + ' (' + graph_data[key][graph_data[key].length - 1].year + '): ' + graph_data[key][graph_data[key].length - 1].value.toLocaleString(pt ? 'pt-BR':'en-US') + ' ' + (item.type === "dual_scale" ? key : item.unit !== null ? (pt ? item.unit_pt.replace('Número de ','').replace('N° de ','') : item.unit.replace('# of ','')) : '');
												}
											}
										}
									}

									if (item.targets[0].value === null || !item.show_progress) {
										percentage = null;
									}

									let list = null;

									let states_highrank_list_baseline = [];
									let bank_states_highrank_list_baseline = [];
									let states_highrank_list = [];
									let bank_states_highrank_list = [];
									let baseline_year = null;
									let current_year = null;
									if (item.maps && item.maps[0] !== null) {
										let maps_data = {}; //format data
										for (let i = 0; i < item.maps.length; i++) {
											if (maps_data[item.maps[i].year] === undefined) {
												maps_data[item.maps[i].year] = [];
												maps_data[item.maps[i].year].push(item.maps[i]);
											} else {
												maps_data[item.maps[i].year].push(item.maps[i]);
											}
										}

										if (Object.keys(maps_data).length > 1) {
											let order = Object.keys(maps_data);
											order.sort(function(a, b){ //make sure keys are sorted
												if(parseInt(a) < parseInt(b)) return -1;
												if(parseInt(a) > parseInt(b)) return 1;
												return 0;
											});
											let baseline_map_data = maps_data[order[0]];
											for (let i = 0; i < baseline_map_data.length; i++) {
												if (baseline_map_data[i].value <= "B") {
													if (baseline_map_data[i].bank_supported) {
														bank_states_highrank_list_baseline.push(baseline_map_data[i]);
													}
													states_highrank_list_baseline.push(baseline_map_data[i]);
												}
											}
											baseline_year = order[0];
											let current_map_data = maps_data[order[order.length - 1]];
											for (let i = 0; i < current_map_data.length; i++) {
												if (current_map_data[i].value <= "B") {
													if (current_map_data[i].bank_supported) {
														bank_states_highrank_list.push(current_map_data[i]);
													}
													states_highrank_list.push(current_map_data[i]);
												}
											}
											current_year = order[order.length - 1];
										} else {
											let order = Object.keys(maps_data);
											list = {};
											list.title = (pt ? item.unit_pt.replace('Número de ','').replace('N° de ','') : item.unit.replace('# of ',''));
											list.list = [];
											for (let i = 0; i < maps_data[order[0]].length; i++) {
												if (maps_data[order[0]][i].value <= "B") {
													if (maps_data[order[0]][i].bank_supported) {
														bank_states_highrank_list.push(maps_data[order[0]][i]);
													}
													states_highrank_list.push(maps_data[order[0]][i]);
													list.list.push(maps_data[order[0]][i].state_name);
												}
											}
											current_year = order[0];
										}
										if (baseline_year !== null) {
											baselines['Main'] = text.baseline + ' (' + baseline_year + '): ' + bank_states_highrank_list_baseline.length + ' ' + (item.unit !== null ? (pt ? item.unit_pt.replace('Número de ','').replace('N° de ','') : item.unit.replace('# of ','')) : '');
										}
										if (current_year !== null) {
											progress['Main'] = text.progress + ' (' + current_year + '): ' + bank_states_highrank_list.length + ' ' + (item.unit !== null ? (pt ? item.unit_pt.replace('Número de ','').replace('N° de ','') : item.unit.replace('# of ','')) : '');
										}
									}

									if (item.lists && item.lists[0] !== null) {
										list = item.lists[0];
									}

									return (
										<div className={"row impact-indicator-section"} id={"indicator-"+"-"+index}>
											{this.state.showontrack ? 
												<div className="col-lg-12 impact-indicator-header">
													<span className="impact-indicator-code">{text.indicator_label} {item.indicator_id} </span> 
													<span className={item.ontrack ? "impact-ontrack-indicator" : "impact-notontrack-indicator"}>
														<span className="circle"></span>
														{item.ontrack ? text.ontrack : text.notontrack}
													</span>
												</div>
													:
												<div className="col-lg-12 impact-indicator-header">
													<span className="impact-indicator-code">{text.indicator_label} {item.indicator_id} </span> 
												</div>
											}
											{
												<div className={item.indicator_id.indexOf('.') > -1 ? "col-lg-12 impact-indicator-section-title-sup" : "col-lg-12 impact-indicator-section-title"}>
													{pt ? item.title_pt : item.title}
												</div>
											}
												{
													Object.keys(targets).map((key, index) => {
														return (
															<div className="col-sm-12 impact-indicator-progress-section">
																{key !== 'Main' ? key + ': ' : ''}{Object.keys(baselines).length > 0 ? <div className="impact-indicator-baseline">{baselines[key]}</div> : null}
																{Object.keys(progress).length > 0 ? <div className="impact-indicator-progress">{progress[key]}</div> : null}
																<div className="impact-indicator-target">{targets[key]}</div>
															</div>
														)
													})
												}
											<div className="col-sm-12 impact-indicator-description">{pt ? item.description_pt : item.description}</div>
											{
												item.graphic ?
												<div className="row impact-indicator-data">
													<div className="col-sm-3"></div>
													<div className="col-sm-6">
														<img alt="Graphic" src={item.graphic}/>
													</div>
													<div className="col-sm-3"></div>
												</div>
												:
												null
											}
											{item.type && item.type === "line" ? 
												list !== null ? 
													<div className="row impact-indicator-data">
														<div className="col-lg-6">
															<LineGraph pt={pt} id={"line-graph-"+index} trend={'plr'} data={graph_data} target={target_data.value} target_year={target_data.year} unit={pt ? item.unit_pt : item.unit} isPercentage={false} isArea={false} />
														</div>
														<div className="col-lg-6 impact-indicator-list-section line">
															<div className="impact-indicator-list-title"><a href={list.source} target="_blank">{pt ? list.title_pt : list.title}</a></div>
															{list.list.map((list_item,index) =>{
																return <div id={"list-item-"+index} className="impact-indicator-list-item">{list_item}</div>
															})}
															{list.list.length === 0 ? <div id={"list-item-0"} className="impact-indicator-list-item">{text.none}</div> : null}
														</div>
													</div>
												:

													<div className="row impact-indicator-data">
														<div className="col-lg-3"></div>
														<div className="col-lg-6">
															<LineGraph pt={pt} id={"line-graph-"+index} trend={'plr'} data={graph_data} target={target_data.value} target_year={target_data.year} unit={pt ? item.unit_pt : item.unit} isPercentage={false} isArea={false} />
														</div>
														<div className="col-lg-3"></div>
													</div>
											: item.type && item.type === "bar" ? 
												<div className="row impact-indicator-data">
													<div className="col-lg-3"></div>
													<div className="col-lg-6">
														<BarGraph pt={pt} id={"bar-graph-"+index} type="single" trend={'plr'} data={graph_data} target={target_data.value} target_year={target_data.year} unit={pt ? item.unit_pt : item.unit} />
													</div>
													<div className="col-lg-3"></div>
												</div>
											: item.type && item.type === "bar_grouped" ? 
												<div className="row impact-indicator-data">
													<div className="col-lg-3"></div>
													<div className="col-lg-6">
														<BarGraph pt={pt} id={"bar-graph-"+index} 
															type={'grouped'}
															trend={'plr'} 
															data={graph_data} 
															unit={pt ? item.unit_pt : item.unit} 
															targets={target_data}
														/>
													</div>
													<div className="col-lg-3"></div>
												</div>
											: item.type && item.type === "dual_scale" ? 
												<div className="row impact-indicator-data">
													<div className="col-lg-3"></div>
													<div className="col-lg-6">
														<BarGraph pt={pt} id={"bar-graph-"+index} 
															type={'dual_scale'}
															trend={'plr'} 
															data={graph_data} 
															unit={pt ? item.unit_pt : item.unit} 
															targets={target_data}
														/>
													</div>
													<div className="col-lg-3"></div>
												</div>
											: item.type && item.type === "progress_bar" ? 
												Array.isArray(graph_data) ? 
													<div className="row impact-indicator-data">
														<div className="col-md-3"></div>
														<div className="col-md-6">
															<ProgressBar id={"progress-graph-"+index} className="impact-progress-bar" now={(graph_data[graph_data.length-1].value / target_data.value) * 100} label={<span>{graph_data[graph_data.length-1].value.toLocaleString(pt ? 'pt-BR':'en-US') + " " + (pt ? item.unit_pt.replace('Número de ','').replace('N° de ','') : item.unit.replace('# of ','')) + " as of " + graph_data[graph_data.length-1].year}</span>} />
														</div>
														<div className="col-md-3"></div>
													</div>
												: Object.keys(graph_data).map((key, i) => { 
													let currentData = graph_data[key];
													let returnValue = (<div className="row impact-indicator-data">
															<div className="col-md-3"></div>
															<div className="col-md-6">
																<ProgressBar id={"progress-graph-"+index+"-"+i} className="impact-progress-bar" now={(currentData[currentData.length-1].value / target_data[key].value) * 100} label={<span>{currentData[currentData.length-1].value.toLocaleString(pt ? 'pt-BR':'en-US') + " " + key + " as of " + currentData[currentData.length-1].year}</span>} />
															</div>
															<div className="col-md-3"></div>
														</div>)
													return returnValue
												})
											: item.type && item.type === "area" ? 
												<div className="row impact-indicator-data">
													<div className="col-lg-3"></div>
													<div className="col-lg-6">
														<LineGraph pt={pt} id={"line-graph-"+index} trend={'plr'} data={graph_data} target={target_data.value} target_year={target_data.year} unit={pt ? item.unit_pt : item.unit} isPercentage={false} isArea={true} />
													</div>
													<div className="col-lg-3"></div>
												</div>
											: item.type && item.type === "map" ? 
												<div className="row impact-indicator-data">
													<div className="col-sm-8">
														<ImpactMap id={"impact-map-"+index} data={states_highrank_list} bank_supported={bank_states_highrank_list} />
													</div>
													<div className="col-sm-4"></div>
													<div className="col-sm-4 impact-indicator-list-section map">
														<div className="impact-indicator-list-title"><a href={list.source} target="_blank">{list.title}</a></div>
														{bank_states_highrank_list.map((list_item,index) =>{
															return <div id={"list-item-"+index} className="impact-indicator-list-item">{list_item}</div>
														})}
														{bank_states_highrank_list.length === 0 ? <div id={"list-item-0"} className="impact-indicator-list-item">{text.none}</div> : null}
													</div>
												</div>
											: null}
											{item.source_name !== null && item.source_link !== null ? <div className="col-sm-12 impact-source-link">{text.source}: <a href={item.source_link} target="_blank">{pt ? item.source_name_pt : item.source_name}</a></div> : null}
										</div>
									)
								})}
							</div>
						</div>
	                </div>
	            : this.state.data_loaded && this.state.indicator === null ?
	            	<div>
					    <h2>404</h2>
					    <h3>Page Not Found</h3>
				    </div>
	        	: null}
				</div>
			</div>
		);
	}
}
export default withRouter(Impact);
