import React from 'react';
import './Radio.css';

export const Radio = ({ selected, handleSelect }) => {
    return (
        <div className="radio-wrapper" onClick={handleSelect}>
            <div className={`radio-circle ${selected ? 'selected' : ''}`}></div>
        </div>
    );
}
