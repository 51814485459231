import axios from "axios";
import React, { useEffect, useState } from "react";

import { CardContent } from "./ContentCard";
import { API_URL } from "../../../../config/api";
import ChevronDownIcon from "../../../../icons/chevron-down.svg";
import AddIndicatorModal from "../../AddIndicatorModal/AddIndicatorModal";
import AlertPopup from "../../AlertPopup";

const textEnglish = {
  addError: 'Error on adding indicator.',
  addSuccess: 'Indicator added successfully!'
}

const textPortuguese = {
  addError: 'Erro na adição de indicador.',
  addSuccess: 'Indicador adicionado com sucesso!'
}

export const IndicatorCard = ({ pt, children, style, allIndicators, location, ...props }) => {
  const [text, setText] = useState(textEnglish);
  const [childrenData, setChildrenData] = useState([]);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [alertProps, setAlertProps] = useState(null);

  const handleCardClick = () => {
    setIsExpanded(!isExpanded);
  };

  const openAlertPopup = (props) => {
    setAlertProps(props);
  }

  const onDelete = (row) => {
    const newChildren = childrenData.filter(
      (child) => 
        Number(child.indicator_id) !== Number(row.indicator_id) ||
        child.year !== row.year ||
        child.month !== row.month ||
        child.category !== row.category
    );

    setChildrenData(newChildren);
  }

  const handleAddIndicatorData = async (data) => {
    try {
      setIsModalOpen(false);
      const indicatodId = data.indicator_id;
      await axios.post(
        `${API_URL}/indicators/add_data?id=${indicatodId}&year=${data.year}&month=${data.month}&value=${data.value}&category=Main&ontrack=true`
      );

      openAlertPopup({
        type: 'success',
        message: text.addSuccess,
        duration: 3000
      });

      const newIndicator = {
        category: 'Main',
        category_pt: 'Main',
        indicator_id: indicatodId,
        month: Number(data.month),
        value: Number(data.value),
        year: data.year
      };

      setChildrenData((prevState) => [...prevState, newIndicator]);

    } catch (error) {
      openAlertPopup({
        type: 'danger',
        message: text.addError,
        duration: 3000
      });
    }
  };

  useEffect(() => {
    setChildrenData(props.graph_data);
  }, [props.graph_data]);

  useEffect(() => {
    if (pt) {
      setText(textPortuguese);
    }
  }, [pt])

  return (
    <div style={style}>
      <AddIndicatorModal
        indicators={allIndicators}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onAddIndicatorData={(data) => handleAddIndicatorData(data)}
        preSelectedIndicatorId={props.indicator_id}
      />
      {alertProps && (
        <AlertPopup onClose={() => setAlertProps(null)} {...alertProps}/>
      )}
      <div
        style={{
          width: "100%",
          height: isExpanded ? "515px" : "60px",
          boxShadow: children ? "0px 2px 4px rgba(0, 0, 0, 0.25)" : "none",
          display: "flex",
          flexDirection: "column",
          alignItems: isExpanded ? "start" : "center",
          padding: "15px 20px",
          backgroundColor: "white",
          transition: "height 0.3s ease-in-out",
          cursor: "pointer",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
          onClick={handleCardClick}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <div className={`indicator-card ${isExpanded ? "expanded" : ""}`}>
              {`${props.indicator_id} - ${
                props.pt ? props.title_pt : props.title
              }`}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              transition: "transform 0.3s ease-in-out",
              transform: isExpanded ? "rotate(180deg)" : "rotate(0deg)",
            }}
          >
            <img src={ChevronDownIcon} alt={props.pt ? "Expandir" : "Expand"} />
          </div>
        </div>
        <div
          style={{
            flex: 1,
            flexDirection: "column",
            width: "100%",
            opacity: isExpanded ? 1 : 0,
            transition: "opacity 0.3s ease-in-out",
            display: isExpanded ? "flex" : "none",
          }}
        >
          <CardContent
            pt={pt}
            graphs={childrenData}
            isExpanded={isExpanded}
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            onDelete={onDelete}
            location={location}
            {...props}
          />
        </div>
      </div>
      <div
        style={{
          background: "white",
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
        }}
      >
        {React.Children.map(children, (child) => (
          <>
            {!!child && (
              <div
                style={{
                  borderBottom: "1px solid #B4B4B4",
                  opacity: "0.6",
                  width: "98%",
                  margin: "0 auto",
                }}
              ></div>
            )}
            {child}
          </>
        ))}
      </div>
    </div>
  );
};