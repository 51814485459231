import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';

import ProgramMap from '../common/map/ProgramMap';

const Map = (props) => {
	const [layers, setLayers] = useState(['fa1', 'percent_poor']);
	const [bounds, setBounds] = useState([
		[-82.079089, -35.275418], // [west, south]
		[-29.959949, 7.413436]  // [east, north]
	]);

	useEffect(() => {
		if (props.location && props.location.search) {
			const params = queryString.parse(props.location.search);
			if (params.layers) {
				setLayers(params.layers.split(','));
			}
			if (params.bounds) {
				const coords = params.bounds.split(',');
				setBounds([[coords[0], coords[1]], [coords[2], coords[3]]]);
			}
		}
	}, [props.location]);

	return (
		<div style={{ height: '100%' }}>
			<ProgramMap location={props.location} layers={layers} bounds={bounds} />
		</div>
	);
};

export default withRouter(Map);
