import React from 'react'
import AltButton from '../AltButton'
import './styles.css'

const AltCard = ({
    icon,
    title,
    description,
    buttonText,
    inverse,
    onClick,
    style,
    href,
    showButton,
    titleFontSize,
    descriptionFontSize,
    buttonFontSize,
    onClickButton,
}) => {
  return (
    <div className={`lp_alt_card ${inverse ? 'inverse' : ''}`} onClick={onClick} style={style}>
      <img src={icon} alt={title} />
      <h3 style={titleFontSize ? { fontSize: titleFontSize } : {}}>{title}</h3>
      <span style={descriptionFontSize ? { fontSize: descriptionFontSize } : {}}>{description}</span>
      <div className="button">

      </div>
      {(showButton !== false) && (
          <AltButton
              href={href}
              onClick={onClickButton}
              inverse={inverse ?? false}
              style={buttonFontSize ? { fontSize: buttonFontSize } : {}}
          >
              {buttonText ?? "Saiba mais"}
          </AltButton>
      )}
    </div>
  )
}

export default AltCard
