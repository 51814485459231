import React, { Component } from "react";
import { Redirect } from "react-router";
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from "react-router-dom";

import "./App.css";

import EnWelcome from "./en/views/Welcome";
import EnBenchmark from "./en/views/Benchmark";
import EnStrategy from "./en/views/Strategy";
import EnProjects from "./en/views/Projects";

import EnFinance from "./en/views/Finance";

import EnImpact from "./en/views/Impact";
import EnProgram from "./en/views/Program";
import EnAbout from "./en/views/About";
import EnStory from "./en/views/Story";
import EnReports from "./en/views/Reports";
import EnBenchmarkEmbed from "./en/views/BenchmarkEmbed";
import EnIndicatorGraph from "./en/views/IndicatorGraph";
import EnInsights from "./en/views/Insights";
import EnEvidenceEmbed from "./en/views/EvidenceEmbed";
import ProjectDetails from "./en/views/ProjectDetails";
import HistoricalEngagement from "./en/views/HistoricalEngagement";
import Cookies from "universal-cookie";
import NewLogin from "./en/views/NewLogin";
import EnMap from "./en/views/Map";
import EditScreen from "./en/views/ProjectsEditScreen";
import ProjectsEditScreen from "./en/views/ProjectsEditScreen";
import ReportsEditScreen from "./en/views/ReportsEditScreen";
import IndicatorsEditScreen from "./en/views/IndicatorsEditScreen";
import SearchProjectsPage from "./en/views/SearchProjectsPage";

const missing = () => {
  return (
    <div>
      <h2>404</h2>
      <h3>Page Not Found</h3>
    </div>
  );
};

class App extends Component {
  constructor(props) {
    super(props);

    let isLoggedIn = localStorage.getItem("auth");
    if (isLoggedIn) isLoggedIn = JSON.parse(isLoggedIn);

    this.state = {
      currentUserAttempts: 0,
      loggedIn: !!isLoggedIn,
      auth: null,
      project: null,
    };

    global.nc = [0];
    global.nc[0] = 1;
    global.cnonce = [0];
    global.cnonce[0] = this.generateCnonce();
    global.qop = [null];

    global.nonce = [null];

    let d = new Date();

    const cookies = new Cookies();
    cookies.set("navTime", d, { path: "/" });
    let maxage = 3600 * 24 * 30 * 10 * 30;
    d.setTime(d.getTime() + maxage);
    let answered = cookies.get("answered");
    if (!answered) {
      cookies.set("answered", 0, { path: "/", expires: d });
    }
  }

  checkIsLoggedIn = () => {
    return this.state.loggedIn;
  };

  resetAttempts = () => {
    this.setState({ currentUserAttempts: 0 });
  };

  onFailedAttempt = () => {
    this.setState((prevState) => ({
      currentUserAttempts: ++prevState.currentUserAttempts,
    }));
  };

  onChangeLoginState = () => {
    if (this.state.loggedIn == true) {
      localStorage.clear();
      sessionStorage.clear();
    }
    this.setState((prevState) => ({ loggedIn: !prevState.loggedIn }));
  };

  onChangeAuthState = (newAuth) => {
    this.setState((prevState) => ({ auth: newAuth }));
  };

  generateCnonce = function () {
    const crypto = window.crypto || window.msCrypto;

    let carray = new Uint32Array(1);
    return carray;
  };

  render() {
    return (
      <Router onUpdate={() => window.scrollTo(0, 0)}>
        <div className="App">
          <Switch>
            <Route exact path={"/"}>
              <Redirect to="/pt/" />
            </Route>
            <Route exact path={"/pt/welcome"}>
              <Redirect to="/pt/" />
            </Route>

            <Route
              path={["/projectdetails/:id", "/pt/projectdetails/:id"]}
              component={() => (
                <ProjectDetails
                  loggedIn={this.state.loggedIn}
                  auth={this.state.auth}
                  onChangeLoginState={this.onChangeLoginState}
                  checkIsLoggedIn={this.checkIsLoggedIn}
                  project_id={this.state.project}
                />
              )}
            />

            <Route
              exact
              path={"/welcome"}
              component={() => (
                <EnWelcome
                  loggedIn={this.state.loggedIn}
                  auth={this.state.auth}
                  onChangeLoginState={this.onChangeLoginState}
                  checkIsLoggedIn={this.checkIsLoggedIn}
                />
              )}
            />

            <Route
              exact
              path={"/login"}
              component={
                () => (
                  <NewLogin
                    attemps={this.state.currentUserAttempts}
                    onChangeAuthState={this.onChangeAuthState}
                    onFailedAttempt={this.onFailedAttempt}
                    onChangeLoginState={this.onChangeLoginState}
                    resetAttempts={this.resetAttempts}
                    loggedIn={this.state.loggedIn}
                    checkIsLoggedIn={this.checkIsLoggedIn}
                  />
                )
              }
            />
            <Route
              exact
              path={"/projectdetails"}
              component={() => (
                <ProjectDetails
                  loggedIn={this.state.loggedIn}
                  auth={this.state.auth}
                  onChangeLoginState={this.onChangeLoginState}
                  checkIsLoggedIn={this.checkIsLoggedIn}
                  project_id={this.state.project}
                />
              )}
            />

            <Route
              exact
              path={"/benchmark"}
              component={() => (
                <EnBenchmark
                  loggedIn={this.state.loggedIn}
                  auth={this.state.auth}
                  onChangeLoginState={this.onChangeLoginState}
                  checkIsLoggedIn={this.checkIsLoggedIn}
                />
              )}
            />

            <Route
              exact
              path={"/edit-screen"}
              component={() => (
                <EditScreen
                  loggedIn={this.state.loggedIn}
                  auth={this.state.auth}
                  onChangeLoginState={this.onChangeLoginState}
                  checkIsLoggedIn={this.checkIsLoggedIn}
                />
              )}
            />
            <Route
              exact
              path={"/strategy"}
              component={() => (
                <EnStrategy
                  loggedIn={this.state.loggedIn}
                  auth={this.state.auth}
                  onChangeLoginState={this.onChangeLoginState}
                  checkIsLoggedIn={this.checkIsLoggedIn}
                />
              )}
            />
            <Route
              exact
              path={"/projects"}
              component={() => (
                <EnProjects
                  loggedIn={this.state.loggedIn}
                  auth={this.state.auth}
                  onChangeLoginState={this.onChangeLoginState}
                  checkIsLoggedIn={this.checkIsLoggedIn}
                />
              )}
            />

            <Route
              exact
              path={"/leveraging-finance"}
              component={() => (
                <EnFinance
                  loggedIn={this.state.loggedIn}
                  auth={this.state.auth}
                  onChangeLoginState={this.onChangeLoginState}
                  checkIsLoggedIn={this.checkIsLoggedIn}
                />
              )}
            />

            <Route
              exact
              path={"/portfolio"}
              component={() => (
                <EnProgram
                  loggedIn={this.state.loggedIn}
                  auth={this.state.auth}
                  onChangeLoginState={this.onChangeLoginState}
                  checkIsLoggedIn={this.checkIsLoggedIn}
                />
              )}
            />

            <Route
              exact
              path={"/indicators"}
              component={() => (
                <EnImpact
                  loggedIn={this.state.loggedIn}
                  auth={this.state.auth}
                  onChangeLoginState={this.onChangeLoginState}
                  checkIsLoggedIn={this.checkIsLoggedIn}
                />
              )}
            />
            <Route
              exact
              path={"/about"}
              component={() => (
                <EnAbout
                  loggedIn={this.state.loggedIn}
                  auth={this.state.auth}
                  onChangeLoginState={this.onChangeLoginState}
                  checkIsLoggedIn={this.checkIsLoggedIn}
                />
              )}
            />
            <Route
              exact
              path={"/story"}
              component={() => (
                <EnStory
                  loggedIn={this.state.loggedIn}
                  auth={this.state.auth}
                  onChangeLoginState={this.onChangeLoginState}
                  checkIsLoggedIn={this.checkIsLoggedIn}
                />
              )}
            />
            <Route
              exact
              path={"/history"}
              component={() => (
                <HistoricalEngagement
                  loggedIn={this.state.loggedIn}
                  auth={this.state.auth}
                  onChangeLoginState={this.onChangeLoginState}
                  checkIsLoggedIn={this.checkIsLoggedIn}
                />
              )}
            />
            <Route
              exact
              path={"/pt/history"}
              component={() => (
                <HistoricalEngagement
                  loggedIn={this.state.loggedIn}
                  auth={this.state.auth}
                  onChangeLoginState={this.onChangeLoginState}
                  checkIsLoggedIn={this.checkIsLoggedIn}
                />
              )}
            />
            <Route
              exact
              path={"/reports"}
              component={() => (
                <EnReports
                  loggedIn={this.state.loggedIn}
                  auth={this.state.auth}
                  onChangeLoginState={this.onChangeLoginState}
                  checkIsLoggedIn={this.checkIsLoggedIn}
                />
              )}
            />
            <Route exact path={"/indicator/:id"} component={EnIndicatorGraph} />
            <Route
              exact
              path={"/insights"}
              component={() => (
                <EnInsights
                  loggedIn={this.state.loggedIn}
                  auth={this.state.auth}
                  onChangeLoginState={this.onChangeLoginState}
                  checkIsLoggedIn={this.checkIsLoggedIn}
                />
              )}
            />
            <Route
              exact
              path={"/pt/"}
              component={() => (
                <EnWelcome
                  loggedIn={this.state.loggedIn}
                  auth={this.state.auth}
                  onChangeLoginState={this.onChangeLoginState}
                  checkIsLoggedIn={this.checkIsLoggedIn}
                />
              )}
            />

            <Route
              exact
              path={"/pt/"}
              component={() => (
                <EnWelcome
                  loggedIn={this.state.loggedIn}
                  auth={this.state.auth}
                  onChangeLoginState={this.onChangeLoginState}
                  checkIsLoggedIn={this.checkIsLoggedIn}
                />
              )}
            />

            <Route
              exact
              path={"/pt/search-project"}
              component={() => (
                <SearchProjectsPage
                  loggedIn={this.state.loggedIn}
                  auth={this.state.auth}
                  onChangeLoginState={this.onChangeLoginState}
                  checkIsLoggedIn={this.checkIsLoggedIn}
                  pt={true}
                />
              )}
            />

            <Route
              exact
              path={"/search-project"}
              component={() => (
                <SearchProjectsPage
                  loggedIn={this.state.loggedIn}
                  auth={this.state.auth}
                  onChangeLoginState={this.onChangeLoginState}
                  checkIsLoggedIn={this.checkIsLoggedIn}
                  pt={false}
                />
              )}
            />

            <Route
              exact
              path={"/pt/login"}
              component={
                () => (
                  <NewLogin
                    attemps={this.state.currentUserAttempts}
                    onChangeAuthState={this.onChangeAuthState}
                    onFailedAttempt={this.onFailedAttempt}
                    onChangeLoginState={this.onChangeLoginState}
                    resetAttempts={this.resetAttempts}
                    loggedIn={this.state.loggedIn}
                    checkIsLoggedIn={this.checkIsLoggedIn}
                  />
                )
              }
            />
            <Route
              exact
              path={"/pt/benchmark"}
              component={() => (
                <EnBenchmark
                  loggedIn={this.state.loggedIn}
                  auth={this.state.auth}
                  onChangeLoginState={this.onChangeLoginState}
                  checkIsLoggedIn={this.checkIsLoggedIn}
                />
              )}
            />
            <Route
              exact
              path={"/pt/edit-screen/projects"}
              component={() => (
                <ProjectsEditScreen
                  loggedIn={this.state.loggedIn}
                  auth={this.state.auth}
                  onChangeLoginState={this.onChangeLoginState}
                  checkIsLoggedIn={this.checkIsLoggedIn}
                />
              )}
            />

            <Route
                exact
                path={"/edit-screen/projects"}
                component={() => (
                    <ProjectsEditScreen
                        loggedIn={this.state.loggedIn}
                        auth={this.state.auth}
                        onChangeLoginState={this.onChangeLoginState}
                        checkIsLoggedIn={this.checkIsLoggedIn}
                    />
                )}
            />

            <Route
              exact
              path={"/pt/edit-screen/reports"}
              component={() => (
                <ReportsEditScreen
                  loggedIn={this.state.loggedIn}
                  auth={this.state.auth}
                  onChangeLoginState={this.onChangeLoginState}
                  checkIsLoggedIn={this.checkIsLoggedIn}
                />
              )}
            />

            <Route
                exact
                path={"/edit-screen/reports"}
                component={() => (
                    <ReportsEditScreen
                        loggedIn={this.state.loggedIn}
                        auth={this.state.auth}
                        onChangeLoginState={this.onChangeLoginState}
                        checkIsLoggedIn={this.checkIsLoggedIn}
                    />
                )}
            />

            <Route
              exact
              path={"/pt/edit-screen/indicators"}
              component={() => (
                <IndicatorsEditScreen
                  loggedIn={this.state.loggedIn}
                  auth={this.state.auth}
                  onChangeLoginState={this.onChangeLoginState}
                  checkIsLoggedIn={this.checkIsLoggedIn}
                />
              )}
            />

            <Route
                exact
                path={"/edit-screen/indicators"}
                component={() => (
                    <IndicatorsEditScreen
                        loggedIn={this.state.loggedIn}
                        auth={this.state.auth}
                        onChangeLoginState={this.onChangeLoginState}
                        checkIsLoggedIn={this.checkIsLoggedIn}
                    />
                )}
            />

            <Route
              exact
              path={"/pt/strategy"}
              component={() => (
                <EnStrategy
                  loggedIn={this.state.loggedIn}
                  auth={this.state.auth}
                  onChangeLoginState={this.onChangeLoginState}
                  checkIsLoggedIn={this.checkIsLoggedIn}
                />
              )}
            />
            <Route
              exact
              path={"/pt/projects"}
              component={() => (
                <EnProjects
                  loggedIn={this.state.loggedIn}
                  auth={this.state.auth}
                  onChangeLoginState={this.onChangeLoginState}
                  checkIsLoggedIn={this.checkIsLoggedIn}
                />
              )}
            />
            <Route
              exact
              path={"/pt/projectdetails"}
              component={() => (
                <ProjectDetails
                  loggedffIn={this.state.loggedIn}
                  auth={this.state.auth}
                  onChangeLoginState={this.onChangeLoginState}
                  checkIsLoggedIn={this.checkIsLoggedIn}
                  project_id={this.state.project}
                />
              )}
            />
            <Route
              exact
              path={"/pt/leveraging-finance"}
              component={() => (
                <EnFinance
                  loggedIn={this.state.loggedIn}
                  auth={this.state.auth}
                  onChangeLoginState={this.onChangeLoginState}
                  checkIsLoggedIn={this.checkIsLoggedIn}
                />
              )}
            />
            <Route
              exact
              path={"/pt/portfolio"}
              component={() => (
                <EnProgram
                  loggedIn={this.state.loggedIn}
                  auth={this.state.auth}
                  onChangeLoginState={this.onChangeLoginState}
                  checkIsLoggedIn={this.checkIsLoggedIn}
                />
              )}
            />
            <Route
              exact
              path={"/pt/indicators"}
              component={() => (
                <EnImpact
                  loggedIn={this.state.loggedIn}
                  auth={this.state.auth}
                  onChangeLoginState={this.onChangeLoginState}
                  checkIsLoggedIn={this.checkIsLoggedIn}
                />
              )}
            />

            <Route
              exact
              path={"/pt/about"}
              component={() => (
                <EnAbout
                  loggedIn={this.state.loggedIn}
                  auth={this.state.auth}
                  onChangeLoginState={this.onChangeLoginState}
                  checkIsLoggedIn={this.checkIsLoggedIn}
                />
              )}
            />
            <Route
              exact
              path={"/pt/story"}
              component={() => (
                <EnStory
                  loggedIn={this.state.loggedIn}
                  auth={this.state.auth}
                  onChangeLoginState={this.onChangeLoginState}
                  checkIsLoggedIn={this.checkIsLoggedIn}
                />
              )}
            />
            <Route
              exact
              path={"/pt/reports"}
              component={() => (
                <EnReports
                  loggedIn={this.state.loggedIn}
                  auth={this.state.auth}
                  onChangeLoginState={this.onChangeLoginState}
                  checkIsLoggedIn={this.checkIsLoggedIn}
                />
              )}
            />
            <Route
              exact
              path={"/pt/indicator/:id"}
              component={EnIndicatorGraph}
            />
            <Route
              exact
              path={"/pt/insights"}
              component={() => (
                <EnInsights
                  loggedIn={this.state.loggedIn}
                  auth={this.state.auth}
                  onChangeLoginState={this.onChangeLoginState}
                  checkIsLoggedIn={this.checkIsLoggedIn}
                />
              )}
            />
            <Route
                exact
                path={"/benchmarking/gdp"}
                component={EnBenchmarkEmbed}
            />
            <Route
                exact
                path={"/benchmarking/gini"}
                component={EnBenchmarkEmbed}
            />
            <Route
                exact
                path={"/pt/benchmarking/gdp"}
                component={EnBenchmarkEmbed}
            />
            <Route
                exact
                path={"/pt/benchmarking/gini"}
                component={EnBenchmarkEmbed}
            />
            <Route exact path={"/pt/impact-eval"} component={EnEvidenceEmbed} />
            <Route exact path={"/impact-eval"} component={EnEvidenceEmbed} />
            <Route exact path={"/pt/gdi"} component={EnEvidenceEmbed} />
            <Route exact path={"/gdi"} component={EnEvidenceEmbed} />
            <Route exact path={"/pt/map"} component={EnMap} />
            <Route exact path={"/map"} component={EnMap} />
            <Route component={missing} />
          </Switch>
        </div>
      </Router>
    );
  }
}

export default App;
