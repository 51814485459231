import React, { Component } from 'react';
import { Line } from 'react-chartjs-2';
import numeral from 'numeral';
import { COLORS, BORDER_COLORS } from './graphColors';
import LazyLoad from 'react-lazy-load';
import _ from 'lodash';
import Loading from '../Loading';
import './LineChart.css';
import './GraphFonts.css';
import './GraphLegend.css';

const textEnglish = {
	'brazil': 'Brazil',
	'g20': 'G20 Average',
	'global': 'Global Average'
}

const textPortuguese = {
	'brazil': 'Brasil',
	'g20': 'Média do G20',
	'global': 'Média Global'
}

export default class LineChart extends Component {
	constructor(props) {
		super(props);

		this.state = {
			graphLoaded: false,
			br: false,
			country1: false,
			country2: false,
			g20: false,
			global: false
		}

		this.updateLoading = this.updateLoading.bind(this);
		this.updateDataset = this.updateDataset.bind(this);
	}

	shouldComponentUpdate(nextProps, nextState) {
		if (_.isEqual(this.props, nextProps) && _.isEqual(this.state, nextState)) {
			return false;
		}
		return true;
	}

	componentDidMount() {
		this.forceUpdate();
	}

	updateDataset(e, index, label) {
		let current = this.state[label];
		this.setState({
			[label] : !current
		});
    };

	updateLoading() {
		this.setState({
			graphLoaded: true
		})
	}

	render() {
		let pt = this.props.pt;
		let text = null;
		if (pt) {
			text = textPortuguese;
		} else {
			text = textEnglish;
		}
		
		let height = this.props.height ? this.props.height : 300;
		let width = this.props.width ? this.props.width : "100%";
		let options = this.props.options ? this.props.options : {};
		let numValues = this.props.values ? this.props.values : 10;
		let omitAvgs = this.props.omitAvgs ? this.props.omitAvgs : false;
		let formatter = this.props.formatter;
		let label = this.props.label ? this.props.label : '';
		let displayLabel = this.props.label ? true : false;
		let G20_AVGS = this.props.wdi_data['g20'];
		let GLOBAL_AVGS = this.props.wdi_data['global'];
		options['maintainAspectRatio'] = false;
		options['animation'] = {'duration': 250};
		options['scales'] = {
            yAxes: [{
                ticks: {
                    callback: function(value, index, values) {
                    	let v = value;
                    	if (formatter) {
                    		v = numeral(value).format(formatter);
                    		if (!isNaN(Number(v))) {
                    			v = Number(v).toFixed(1).replace(".0", '');
                    		} else {
                    			if (v.includes(',')) {
                    				v = v.split('.')[0];
                    			}
                    		}
                    	}
                        return '     ' + v;
                    }
                },
                scaleLabel: { display: displayLabel, labelString: label }
            }]
        };

        if (this.props.min) {
        	options.scales.yAxes[0].ticks['min'] = this.props.min;
        }

        if (this.props.max) {
        	options.scales.yAxes[0].ticks['max'] = this.props.max;
        }

        if (this.props.beginAtZero) {
        	options.scales.yAxes[0].ticks['beginAtZero'] = this.props.beginAtZero;
        }

        options['tooltips'] = {
        	bodyFontSize: 14,
        	backgroundColor: '#393d41',
        	opacity: 1,
        	callbacks: {
        		title: function() {
        			return null;
        		},
                label: function(tooltipItem, data) {
	                let label = data.datasets[tooltipItem.datasetIndex].label;
	                let value = tooltipItem.yLabel;
	                if (formatter) {
	                	value = numeral(value).format(formatter);
	                }
	                return label + ": " + value;
                }
            }
        };
        options['legend'] = {
        	display: false
        }

		let datasets = [];
		let yearLabels = [];
		let count = 0;
		for (let country in this.props.country_order) {
			let dataValues = [];
			let c = this.props.wdi_data[this.props.country_order[country].iso3];
			if (numValues > c.indicators[this.props.field].data.length) {
				numValues = c.indicators[this.props.field].data.length;
			}
			for (let i=c.indicators[this.props.field].data.length-numValues; i<c.indicators[this.props.field].data.length; i++) {
				dataValues.push(c.indicators[this.props.field].data[i].value);
				if (country == 0) {
					yearLabels.push(c.indicators[this.props.field].data[i].year);
				}
			}
			if ((count === 0 && !this.state.br) || (count === 1 && !this.state.country1) || (count === 2 && !this.state.country2)) {
				datasets.push(
					{
					    label: (pt ? this.props.country_translation[c.country_name] : c.country_name),
					    fill: false,
					    lineTension: 0.1,
					    backgroundColor: COLORS[country],
					    borderColor: BORDER_COLORS[country], // The main line color
					    // borderCapStyle: 'square',
					    // borderDash: [], // try [5, 15] for instance
					    // borderDashOffset: 0.0,
					    // borderJoinStyle: 'miter',
					    // pointBorderColor: "black",
					    // pointBackgroundColor: "white",
					    // pointBorderWidth: 1,
					    // pointHoverRadius: 8,
					    // pointHoverBackgroundColor: "yellow",
					    // pointHoverBorderColor: "brown",
					    // pointHoverBorderWidth: 2,
					    // pointRadius: 4,
					    // pointHitRadius: 10,
					    // notice the gap in the data and the spanGaps: true
					    data: dataValues,
					    spanGaps: false,
					}
				);
			}
			count += 1;
		}

		if (!omitAvgs) {
			let g20Data = [];
			numValues = G20_AVGS.indicators[this.props.field].data.length;
			for (let i=G20_AVGS.indicators[this.props.field].data.length-numValues; i<G20_AVGS.indicators[this.props.field].data.length; i++) {
				g20Data.push(G20_AVGS.indicators[this.props.field].data[i].value);
			}
			if (!this.state.g20) {
				datasets.push(
					{
					    label: text.g20,
					    fill: false,
					    lineTension: 0.1,
					    backgroundColor: COLORS[this.props.country_order.length],
					    borderColor: BORDER_COLORS[this.props.country_order.length], // The main line color
					    data: g20Data,
					    spanGaps: false,
				    }
			    );
			}

			let globalData = [];
			numValues = GLOBAL_AVGS.indicators[this.props.field].data.length;
			for (let i=GLOBAL_AVGS.indicators[this.props.field].data.length-numValues; i<GLOBAL_AVGS.indicators[this.props.field].data.length; i++) {
				globalData.push(GLOBAL_AVGS.indicators[this.props.field].data[i].value);
			}
			if (!this.state.global) {
				datasets.push(
					{
					    label: text.global,
					    fill: false,
					    lineTension: 0.1,
					    backgroundColor: COLORS[this.props.country_order.length + 1],
					    borderColor: BORDER_COLORS[this.props.country_order.length + 1], // The main line color
					    data: globalData,
					    spanGaps: false,
				    }
			    );
			}
		}

		let data = {
			labels: yearLabels,
			datasets: datasets
		}

		let loadingBar = <Loading />;
		if (this.state.graphLoaded) {
			loadingBar = "";
		}

		let legend = (
			<div className="groupedbar-custom-legend">
				<div className={this.state.br ? "groupedbar-legend-div transparent" : "groupedbar-legend-div"} onClick={() => this.updateDataset(this, 0, 'br')}>
				<div title={text.brazil} style={{backgroundColor:COLORS[0]}} className="groupedbar-legend-option"></div>
				<div className="groupedbar-legend-text">{text.brazil}</div>
				</div>
		    </div>
	    );
		    

		if (!omitAvgs) {
			legend = (
				<div className="groupedbar-custom-legend">
					<div className={this.state.br ? "groupedbar-legend-div transparent" : "groupedbar-legend-div"} onClick={() => this.updateDataset(this, 0, 'br')}>
					<div title={text.brazil} style={{backgroundColor:COLORS[0]}} className="groupedbar-legend-option"></div>
					<div className="groupedbar-legend-text">{text.brazil}</div>
					</div>

					<div className={this.state.g20 ? "groupedbar-legend-div transparent" : "groupedbar-legend-div"} onClick={() => this.updateDataset(this, 3, 'g20')}>
					<div title={text.g20} style={{backgroundColor:COLORS[1]}} className="groupedbar-legend-option"></div>
					<div className="groupedbar-legend-text">{text.g20}</div>
					</div>

					<div className={this.state.global ? "groupedbar-legend-div transparent" : "groupedbar-legend-div"} onClick={() => this.updateDataset(this, 4, 'global')}>
					<div title={text.global} style={{backgroundColor:COLORS[2]}} className="groupedbar-legend-option"></div>
			    	<div className="groupedbar-legend-text">{text.global}</div>
			    	</div>
			    </div>
			);
		}

		if (this.props.country_order.length === 2) {
			if (!omitAvgs) {
				legend = (
					<div className="groupedbar-custom-legend">
						<div className={this.state.br ? "groupedbar-legend-div transparent" : "groupedbar-legend-div"} onClick={() => this.updateDataset(this, 0, 'br')}>
						<div title={text.brazil} style={{backgroundColor:COLORS[0]}} className="groupedbar-legend-option"></div>
						<div className="groupedbar-legend-text">{text.brazil}</div>
						</div>

						<div className={this.state.country1 ?"groupedbar-legend-div transparent" : "groupedbar-legend-div"} onClick={() => this.updateDataset(this, 1, 'country1')}>
						<div title={pt ? this.props.country_translation[this.props.wdi_data[this.props.country_order[1].iso3].country_name] : this.props.wdi_data[this.props.country_order[1].iso3].country_name} style={{backgroundColor:COLORS[1]}} className= "groupedbar-legend-option"></div>
						<div className="groupedbar-legend-text">{pt ? this.props.country_translation[this.props.wdi_data[this.props.country_order[1].iso3].country_name] : this.props.wdi_data[this.props.country_order[1].iso3].country_name}</div>
						</div>

						<div className={this.state.g20 ? "groupedbar-legend-div transparent" : "groupedbar-legend-div"} onClick={() => this.updateDataset(this, 3, 'g20')}>
						<div title={text.g20} style={{backgroundColor:COLORS[2]}} className="groupedbar-legend-option"></div>
						<div className="groupedbar-legend-text">{text.g20}</div>
						</div>

						<div className={this.state.global ? "groupedbar-legend-div transparent" : "groupedbar-legend-div"} onClick={() => this.updateDataset(this, 4, 'global')}>
						<div title={text.global} style={{backgroundColor:COLORS[3]}} className="groupedbar-legend-option"></div>
				    	<div className="groupedbar-legend-text">{text.global}</div>
				    	</div>
				    </div>
				);
			} else {
				legend = (
					<div className="groupedbar-custom-legend">
						<div className={this.state.br ? "groupedbar-legend-div transparent" : "groupedbar-legend-div"} onClick={() => this.updateDataset(this, 0, 'br')}>
						<div title={text.brazil} style={{backgroundColor:COLORS[0]}} className="groupedbar-legend-option"></div>
						<div className="groupedbar-legend-text">{text.brazil}</div>
						</div>

						<div className={this.state.country1 ?"groupedbar-legend-div transparent" : "groupedbar-legend-div"} onClick={() => this.updateDataset(this, 1, 'country1')}>
						<div title={pt ? this.props.country_translation[this.props.wdi_data[this.props.country_order[1].iso3].country_name] : this.props.wdi_data[this.props.country_order[1].iso3].country_name} style={{backgroundColor:COLORS[1]}} className= "groupedbar-legend-option"></div>
						<div className="groupedbar-legend-text">{pt ? this.props.country_translation[this.props.wdi_data[this.props.country_order[1].iso3].country_name] : this.props.wdi_data[this.props.country_order[1].iso3].country_name}</div>
						</div>
				    </div>
				);
			}
		}

		if (this.props.country_order.length === 3) {
			if (!omitAvgs) {
				legend = (
					<div className="groupedbar-custom-legend">
						<div className={this.state.br ? "groupedbar-legend-div transparent" : "groupedbar-legend-div"} onClick={() => this.updateDataset(this, 0, 'br')}>
						<div title={text.brazil} style={{backgroundColor:COLORS[0]}} className="groupedbar-legend-option"></div>
						<div className="groupedbar-legend-text">{text.brazil}</div>
						</div>

						<div className={this.state.country1 ? "groupedbar-legend-div transparent" : "groupedbar-legend-div"} onClick={() => this.updateDataset(this, 1, 'country1')}>
						<div title={pt ? this.props.country_translation[this.props.wdi_data[this.props.country_order[1].iso3].country_name] : this.props.wdi_data[this.props.country_order[1].iso3].country_name} style={{backgroundColor:COLORS[1]}} className="groupedbar-legend-option"></div>
						<div className="groupedbar-legend-text">{pt ? this.props.country_translation[this.props.wdi_data[this.props.country_order[1].iso3].country_name] : this.props.wdi_data[this.props.country_order[1].iso3].country_name}</div>
						</div>

						<div className={this.state.country2 ? "groupedbar-legend-div transparent" : "groupedbar-legend-div"} onClick={() => this.updateDataset(this, 2, 'country2')}>
						<div title={pt ? this.props.country_translation[this.props.wdi_data[this.props.country_order[2].iso3].country_name] : this.props.wdi_data[this.props.country_order[2].iso3].country_name} style={{backgroundColor:COLORS[2]}} className="groupedbar-legend-option"></div>
						<div className="groupedbar-legend-text">{pt ? this.props.country_translation[this.props.wdi_data[this.props.country_order[2].iso3].country_name] : this.props.wdi_data[this.props.country_order[2].iso3].country_name}</div>
						</div>

						<div className={this.state.g20 ? "groupedbar-legend-div transparent" : "groupedbar-legend-div"} onClick={() => this.updateDataset(this, 3, 'g20')}>
						<div title={text.g20} style={{backgroundColor:COLORS[3]}} className="groupedbar-legend-option"></div>
						<div className="groupedbar-legend-text">{text.g20}</div>
						</div>

						<div className={this.state.global ? "groupedbar-legend-div transparent" : "groupedbar-legend-div"} onClick={() => this.updateDataset(this, 4, 'global')}>
						<div title={text.global} style={{backgroundColor:COLORS[4]}} className="groupedbar-legend-option"></div>
				    	<div className="groupedbar-legend-text">{text.global}</div>
				    	</div>
				    </div>
				);
			} else {
				legend = (
					<div className="groupedbar-custom-legend">
						<div className={this.state.br ? "groupedbar-legend-div transparent" : "groupedbar-legend-div"} onClick={() => this.updateDataset(this, 0, 'br')}>
						<div title={text.brazil} style={{backgroundColor:COLORS[0]}} className="groupedbar-legend-option"></div>
						<div className="groupedbar-legend-text">{text.brazil}</div>
						</div>

						<div className={this.state.country1 ? "groupedbar-legend-div transparent" : "groupedbar-legend-div"} onClick={() => this.updateDataset(this, 1, 'country1')}>
						<div title={pt ? this.props.country_translation[this.props.wdi_data[this.props.country_order[1].iso3].country_name] : this.props.wdi_data[this.props.country_order[1].iso3].country_name} style={{backgroundColor:COLORS[1]}} className="groupedbar-legend-option"></div>
						<div className="groupedbar-legend-text">{pt ? this.props.country_translation[this.props.wdi_data[this.props.country_order[1].iso3].country_name] : this.props.wdi_data[this.props.country_order[1].iso3].country_name}</div>
						</div>

						<div className={this.state.country2 ? "groupedbar-legend-div transparent" : "groupedbar-legend-div"} onClick={() => this.updateDataset(this, 2, 'country2')}>
						<div title={pt ? this.props.country_translation[this.props.wdi_data[this.props.country_order[2].iso3].country_name] : this.props.wdi_data[this.props.country_order[2].iso3].country_name} style={{backgroundColor:COLORS[2]}} className="groupedbar-legend-option"></div>
						<div className="groupedbar-legend-text">{pt ? this.props.country_translation[this.props.wdi_data[this.props.country_order[2].iso3].country_name] : this.props.wdi_data[this.props.country_order[2].iso3].country_name}</div>
						</div>
				    </div>
				);
			}
		}

		return (
			<div className="custom-font" style={{'height': height, 'width': width}}>
				{legend}
				<LazyLoad offsetVertical={100} throttle={400} debounce={false} onContentVisible={this.updateLoading}>
					<Line
					    data={data}
					    height={height}
					    options={options}
					/>
				</LazyLoad>
				{loadingBar}
			</div>
		);
	}
};
