import React, { Component } from 'react';
import './CountryModal.css';

import { Link } from 'react-router-dom'; 
import { Modal, Button, ButtonGroup } from 'react-bootstrap';

import { Scrollbars } from 'react-custom-scrollbars';

import modalClose from './img/close-plr-modal.svg';
import divider from './img/two-country-divider.svg';

const availableCountriesWithNames = [ 
	{'code': 'AF', 'name': 'Afghanistan', 'name_pt': 'Afeganistão'},
	{'code': 'AL', 'name': 'Albania', 'name_pt': 'Albânia'},
	{'code': 'DZ', 'name': 'Algeria', 'name_pt': 'Argélia'},
	{'code': 'AS', 'name': 'American Samoa', 'name_pt': 'Samoa Americana'},
	{'code': 'AD', 'name': 'Andorra', 'name_pt': 'Andorra'},
	{'code': 'AO', 'name': 'Angola', 'name_pt': 'Angola'},
	{'code': 'AG', 'name': 'Antigua and Barbuda', 'name_pt': 'Antígua e Barbuda'},
	{'code': 'AR', 'name': 'Argentina', 'name_pt': 'Argentina'},
	{'code': 'AM', 'name': 'Armenia', 'name_pt': 'Arménia'},
	{'code': 'AW', 'name': 'Aruba', 'name_pt': 'Aruba'},
	{'code': 'AU', 'name': 'Australia', 'name_pt': 'Austrália'},
	{'code': 'AT', 'name': 'Austria', 'name_pt': 'Áustria'},
	{'code': 'AZ', 'name': 'Azerbaijan', 'name_pt': 'Azerbaijão'},
	{'code': 'BS', 'name': 'Bahamas', 'name_pt': 'Bahamas'},
	{'code': 'BH', 'name': 'Bahrain', 'name_pt': 'Bahrein'},
	{'code': 'BD', 'name': 'Bangladesh', 'name_pt': 'Bangladesh'},
	{'code': 'BB', 'name': 'Barbados', 'name_pt': 'Barbados'},
	{'code': 'BY', 'name': 'Belarus', 'name_pt': 'Bielorrússia'},
	{'code': 'BE', 'name': 'Belgium', 'name_pt': 'Bélgica'},
	{'code': 'BZ', 'name': 'Belize', 'name_pt': 'Belize'},
	{'code': 'BJ', 'name': 'Benin', 'name_pt': 'Benim'},
	{'code': 'BM', 'name': 'Bermuda', 'name_pt': 'Bermudas'},
	{'code': 'BT', 'name': 'Bhutan', 'name_pt': 'Butão'},
	{'code': 'BO', 'name': 'Bolivia', 'name_pt': 'Bolívia'},
	{'code': 'BA', 'name': 'Bosnia and Herzegovina', 'name_pt': 'Bósnia e Herzegovina'},
	{'code': 'BW', 'name': 'Botswana', 'name_pt': 'Botswana'},
	{'code': 'BN', 'name': 'Brunei Darussalam', 'name_pt': 'Brunei'},
	{'code': 'BG', 'name': 'Bulgaria', 'name_pt': 'Bulgária'},
	{'code': 'BF', 'name': 'Burkina Faso', 'name_pt': 'Burkina Faso'},
	{'code': 'BI', 'name': 'Burundi', 'name_pt': 'Burundi'},
	{'code': 'KH', 'name': 'Cambodia', 'name_pt': 'Camboja'},
	{'code': 'CM', 'name': 'Cameroon', 'name_pt': 'Camarões'},
	{'code': 'CA', 'name': 'Canada', 'name_pt': 'Canadá'},
	{'code': 'CV', 'name': 'Cape Verde', 'name_pt': 'Cabo Verde'},
	{'code': 'KY', 'name': 'Cayman Islands', 'name_pt': 'Ilhas Cayman'},
	{'code': 'CF', 'name': 'Central African Republic', 'name_pt': 'República Centro-Africana'},
	{'code': 'TD', 'name': 'Chad', 'name_pt': 'Chade'},
	{'code': 'CL', 'name': 'Chile', 'name_pt': 'Chile'},
	{'code': 'CN', 'name': 'China', 'name_pt': 'China'},
	{'code': 'CO', 'name': 'Colombia', 'name_pt': 'Colômbia'},
	{'code': 'KM', 'name': 'Comoros', 'name_pt': 'Comores'},
	{'code': 'CG', 'name': 'Congo', 'name_pt': 'República do Congo'},
	{'code': 'CD', 'name': 'Congo, The Democratic Republic of the', 'name_pt': 'República Democrática do Congo'},
	{'code': 'CR', 'name': 'Costa Rica', 'name_pt': 'Costa Rica'},
	{'code': 'CI', 'name': "Cote D'Ivoire", 'name_pt': 'Costa do Marfim'},
	{'code': 'HR', 'name': 'Croatia', 'name_pt': 'Croácia'},
	{'code': 'CU', 'name': 'Cuba', 'name_pt': 'Cuba'},
	{'code': 'CY', 'name': 'Cyprus', 'name_pt': 'Chipre'},
	{'code': 'CZ', 'name': 'Czech Republic', 'name_pt': 'República Checa'},
	{'code': 'DK', 'name': 'Denmark', 'name_pt': 'Dinamarca'},
	{'code': 'DJ', 'name': 'Djibouti', 'name_pt': 'Djibouti'},
	{'code': 'DM', 'name': 'Dominica', 'name_pt': 'Dominica'},
	{'code': 'DO', 'name': 'Dominican Republic', 'name_pt': 'República Dominicana'},
	{'code': 'EC', 'name': 'Ecuador', 'name_pt': 'Equador'},
	{'code': 'EG', 'name': 'Egypt', 'name_pt': 'Egito'},
	{'code': 'SV', 'name': 'El Salvador', 'name_pt': 'El Salvador'},
	{'code': 'GQ', 'name': 'Equatorial Guinea', 'name_pt': 'Guiné Equatorial'},
	{'code': 'ER', 'name': 'Eritrea', 'name_pt': 'Eritreia'},
	{'code': 'EE', 'name': 'Estonia', 'name_pt': 'Estónia'},
	{'code': 'ET', 'name': 'Ethiopia', 'name_pt': 'Etiópia'},
	{'code': 'FO', 'name': 'Faroe Islands', 'name_pt': 'Ilhas Faroe'},
	{'code': 'FJ', 'name': 'Fiji', 'name_pt': 'Fiji'},
	{'code': 'FI', 'name': 'Finland', 'name_pt': 'Finlândia'},
	{'code': 'FR', 'name': 'France', 'name_pt': 'França'},
	{'code': 'PF', 'name': 'French Polynesia', 'name_pt': 'Polinésia Francesa'},
	{'code': 'GA', 'name': 'Gabon', 'name_pt': 'Gabão'},
	{'code': 'GM', 'name': 'Gambia', 'name_pt': 'Gâmbia'},
	{'code': 'GE', 'name': 'Georgia', 'name_pt': 'Geórgia'},
	{'code': 'DE', 'name': 'Germany', 'name_pt': 'Alemanha'},
	{'code': 'GH', 'name': 'Ghana', 'name_pt': 'Gana'},
	{'code': 'GI', 'name': 'Gibraltar', 'name_pt': 'Gibraltar'},
	{'code': 'GR', 'name': 'Greece', 'name_pt': 'Grécia'},
	{'code': 'GL', 'name': 'Greenland', 'name_pt': 'Gronelândia'},
	{'code': 'GD', 'name': 'Grenada', 'name_pt': 'Granada'},
	{'code': 'GU', 'name': 'Guam', 'name_pt': 'Guam'},
	{'code': 'GT', 'name': 'Guatemala', 'name_pt': 'Guatemala'},
	{'code': 'GN', 'name': 'Guinea', 'name_pt': 'Guiné'},
	{'code': 'GW', 'name': 'Guinea-Bissau', 'name_pt': 'Guiné-Bissau'},
	{'code': 'GY', 'name': 'Guyana', 'name_pt': 'Guiana'},
	{'code': 'HT', 'name': 'Haiti', 'name_pt': 'Haiti'},
	{'code': 'HN', 'name': 'Honduras', 'name_pt': 'Honduras'},
	{'code': 'HK', 'name': 'Hong Kong', 'name_pt': 'Hong Kong'},
	{'code': 'HU', 'name': 'Hungary', 'name_pt': 'Hungria'},
	{'code': 'IS', 'name': 'Iceland', 'name_pt': 'Islândia'},
	{'code': 'IN', 'name': 'India', 'name_pt': 'Índia'},
	{'code': 'ID', 'name': 'Indonesia', 'name_pt': 'Indonésia'},
	{'code': 'IR', 'name': 'Iran, Islamic Republic Of', 'name_pt': 'Irã'},
	{'code': 'IQ', 'name': 'Iraq', 'name_pt': 'Iraque'},
	{'code': 'IE', 'name': 'Ireland', 'name_pt': 'Irlanda'},
	{'code': 'IM', 'name': 'Isle of Man', 'name_pt': 'Ilha de Man'},
	{'code': 'IL', 'name': 'Israel', 'name_pt': 'Israel'},
	{'code': 'IT', 'name': 'Italy', 'name_pt': 'Itália'},
	{'code': 'JM', 'name': 'Jamaica', 'name_pt': 'Jamaica'},
	{'code': 'JP', 'name': 'Japan', 'name_pt': 'Japão'},
	{'code': 'JO', 'name': 'Jordan', 'name_pt': 'Jordânia'},
	{'code': 'KZ', 'name': 'Kazakhstan', 'name_pt': 'Cazaquistão'},
	{'code': 'KE', 'name': 'Kenya', 'name_pt': 'Quénia'},
	{'code': 'KI', 'name': 'Kiribati', 'name_pt': 'Kiribati'},
	{'code': 'KP', 'name': "Korea, Democratic People'S Republic of", 'name_pt': 'República Popular Democrática da Coreia'},
	{'code': 'KR', 'name': 'South Korea', 'name_pt': 'República da Coreia'},
	{'code': 'KW', 'name': 'Kuwait', 'name_pt': 'Kuwait'},
	{'code': 'KG', 'name': 'Kyrgyzstan', 'name_pt': 'Quirguistão'},
	{'code': 'LA', 'name': "Lao People'S Democratic Republic", 'name_pt': 'Laos'},
	{'code': 'LV', 'name': 'Latvia', 'name_pt': 'Letónia'},
	{'code': 'LB', 'name': 'Lebanon', 'name_pt': 'Líbano'},
	{'code': 'LS', 'name': 'Lesotho', 'name_pt': 'Lesoto'},
	{'code': 'LR', 'name': 'Liberia', 'name_pt': 'Libéria'},
	{'code': 'LY', 'name': 'Libya', 'name_pt': 'Líbia'},
	{'code': 'LI', 'name': 'Liechtenstein', 'name_pt': 'Liechtenstein'},
	{'code': 'LT', 'name': 'Lithuania', 'name_pt': 'Lituânia'},
	{'code': 'LU', 'name': 'Luxembourg', 'name_pt': 'Luxemburgo'},
	{'code': 'MO', 'name': 'Macao', 'name_pt': 'Macau'},
	{'code': 'MK', 'name': 'Macedonia, The Former Yugoslav Republic of', 'name_pt': 'República da Macedônia do Norte'},
	{'code': 'MG', 'name': 'Madagascar', 'name_pt': 'Madagáscar'},
	{'code': 'MW', 'name': 'Malawi', 'name_pt': 'Malawi'},
	{'code': 'MY', 'name': 'Malaysia', 'name_pt': 'Malásia'},
	{'code': 'MV', 'name': 'Maldives', 'name_pt': 'Maldivas'},
	{'code': 'ML', 'name': 'Mali', 'name_pt': 'Mali'},
	{'code': 'MT', 'name': 'Malta', 'name_pt': 'Malta'},
	{'code': 'MH', 'name': 'Marshall Islands', 'name_pt': 'Ilhas Marshall'},
	{'code': 'MR', 'name': 'Mauritania', 'name_pt': 'Mauritânia'},
	{'code': 'MU', 'name': 'Mauritius', 'name_pt': 'Maurícia'},
	{'code': 'MX', 'name': 'Mexico', 'name_pt': 'México'},
	{'code': 'FM', 'name': 'Micronesia, Federated States of', 'name_pt': 'Micronésia'},
	{'code': 'MD', 'name': 'Moldova, Republic of', 'name_pt': 'Moldávia'},
	{'code': 'MC', 'name': 'Monaco', 'name_pt': 'Mónaco'},
	{'code': 'MN', 'name': 'Mongolia', 'name_pt': 'Mongólia'},
	{'code': 'ME', 'name': 'Montenegro', 'name_pt': 'Montenegro'},
	{'code': 'MA', 'name': 'Morocco', 'name_pt': 'Marrocos'},
	{'code': 'MZ', 'name': 'Mozambique', 'name_pt': 'Moçambique'},
	{'code': 'MM', 'name': 'Myanmar', 'name_pt': 'Myanmar'},
	{'code': 'NA', 'name': 'Namibia', 'name_pt': 'Namíbia'},
	{'code': 'NR', 'name': 'Nauru', 'name_pt': 'Nauru'},
	{'code': 'NP', 'name': 'Nepal', 'name_pt': 'Nepal'},
	{'code': 'NL', 'name': 'Netherlands', 'name_pt': 'Países Baixos'},
	{'code': 'NC', 'name': 'New Caledonia', 'name_pt': 'Nova Caledônia'},
	{'code': 'NZ', 'name': 'New Zealand', 'name_pt': 'Nova Zelândia'},
	{'code': 'NI', 'name': 'Nicaragua', 'name_pt': 'Nicarágua'},
	{'code': 'NE', 'name': 'Niger', 'name_pt': 'Níger'},
	{'code': 'NG', 'name': 'Nigeria', 'name_pt': 'Nigéria'},
	{'code': 'MP', 'name': 'Northern Mariana Islands', 'name_pt': 'Ilhas Marianas do Norte'},
	{'code': 'NO', 'name': 'Norway', 'name_pt': 'Noruega'},
	{'code': 'OM', 'name': 'Oman', 'name_pt': 'Omã'},
	{'code': 'PK', 'name': 'Pakistan', 'name_pt': 'Paquistão'},
	{'code': 'PW', 'name': 'Palau', 'name_pt': 'Palau'},
	{'code': 'PS', 'name': 'Palestinian Territory, Occupied', 'name_pt': 'Território Palestino, Ocupado'},
	{'code': 'PA', 'name': 'Panama', 'name_pt': 'Panamá'},
	{'code': 'PG', 'name': 'Papua New Guinea', 'name_pt': 'Papua-Nova Guiné'},
	{'code': 'PY', 'name': 'Paraguay', 'name_pt': 'Paraguai'},
	{'code': 'PE', 'name': 'Peru', 'name_pt': 'Peru'},
	{'code': 'PH', 'name': 'Philippines', 'name_pt': 'Filipinas'},
	{'code': 'PL', 'name': 'Poland', 'name_pt': 'Polónia'},
	{'code': 'PT', 'name': 'Portugal', 'name_pt': 'Portugal'},
	{'code': 'PR', 'name': 'Puerto Rico', 'name_pt': 'Porto Rico'},
	{'code': 'QA', 'name': 'Qatar', 'name_pt': 'Catar'},
	{'code': 'RO', 'name': 'Romania', 'name_pt': 'Roménia'},
	{'code': 'RU', 'name': 'Russian Federation', 'name_pt': 'Rússia'},
	{'code': 'RW', 'name': 'Rwanda', 'name_pt': 'Ruanda'},
	{'code': 'KN', 'name': 'Saint Kitts and Nevis', 'name_pt': 'São Cristóvão e Nevis'},
	{'code': 'LC', 'name': 'Saint Lucia', 'name_pt': 'Santa Lúcia'},
	{'code': 'MF', 'name': 'Saint Martin (French part)', 'name_pt': 'São Martinho'},
	{'code': 'VC', 'name': 'Saint Vincent and the Grenadines', 'name_pt': 'São Vicente e Granadinas'},
	{'code': 'WS', 'name': 'Samoa', 'name_pt': 'Samoa'},
	{'code': 'SM', 'name': 'San Marino', 'name_pt': 'San Marino'},
	{'code': 'ST', 'name': 'Sao Tome and Principe', 'name_pt': 'São Tomé e Príncipe'},
	{'code': 'SA', 'name': 'Saudi Arabia', 'name_pt': 'Arábia Saudita'},
	{'code': 'SN', 'name': 'Senegal', 'name_pt': 'Senegal'},
	{'code': 'RS', 'name': 'Serbia', 'name_pt': 'Sérvia'},
	{'code': 'SC', 'name': 'Seychelles', 'name_pt': 'Seychelles'},
	{'code': 'SL', 'name': 'Sierra Leone', 'name_pt': 'Serra Leoa'},
	{'code': 'SG', 'name': 'Singapore', 'name_pt': 'Singapura'},
	{'code': 'SK', 'name': 'Slovakia', 'name_pt': 'Eslováquia'},
	{'code': 'SI', 'name': 'Slovenia', 'name_pt': 'Eslovénia'},
	{'code': 'SB', 'name': 'Solomon Islands', 'name_pt': 'Ilhas Salomão'},
	{'code': 'SO', 'name': 'Somalia', 'name_pt': 'Somália'},
	{'code': 'ZA', 'name': 'South Africa', 'name_pt': 'África do Sul'},
	{'code': 'ES', 'name': 'Spain', 'name_pt': 'Espanha'},
	{'code': 'LK', 'name': 'Sri Lanka', 'name_pt': 'Sri Lanka'},
	{'code': 'SD', 'name': 'Sudan', 'name_pt': 'Sudão'},
	{'code': 'SR', 'name': 'Suri"name"', 'name_pt': 'Suriname'},
	{'code': 'SZ', 'name': 'Swaziland', 'name_pt': 'Suazilândia'},
	{'code': 'SE', 'name': 'Sweden', 'name_pt': 'Suécia'},
	{'code': 'CH', 'name': 'Switzerland', 'name_pt': 'Suíça'},
	{'code': 'SY', 'name': 'Syrian Arab Republic', 'name_pt': 'Síria'},
	{'code': 'TJ', 'name': 'Tajikistan', 'name_pt': 'Tajiquistão'},
	{'code': 'TZ', 'name': 'Tanzania, United Republic of', 'name_pt': 'Tanzânia'},
	{'code': 'TH', 'name': 'Thailand', 'name_pt': 'Tailândia'},
	{'code': 'TL', 'name': 'Timor-Leste', 'name_pt': 'Timor-Leste'},
	{'code': 'TG', 'name': 'Togo', 'name_pt': 'Togo'},
	{'code': 'TO', 'name': 'Tonga', 'name_pt': 'Tonga'},
	{'code': 'TT', 'name': 'Trinidad and Tobago', 'name_pt': 'Trinidad e Tobago'},
	{'code': 'TN', 'name': 'Tunisia', 'name_pt': 'Tunísia'},
	{'code': 'TR', 'name': 'Turkey', 'name_pt': 'Turquia'},
	{'code': 'TM', 'name': 'Turkmenistan', 'name_pt': 'Turquemenistão'},
	{'code': 'TC', 'name': 'Turks and Caicos Islands', 'name_pt': 'Ilhas Turcas e Caicos'},
	{'code': 'TV', 'name': 'Tuvalu', 'name_pt': 'Tuvalu'},
	{'code': 'UG', 'name': 'Uganda', 'name_pt': 'Uganda'},
	{'code': 'UA', 'name': 'Ukraine', 'name_pt': 'Ucrânia'},
	{'code': 'AE', 'name': 'United Arab Emirates', 'name_pt': 'Emirados Árabes Unidos'},
	{'code': 'GB', 'name': 'United Kingdom', 'name_pt': 'Reino Unido'},
	{'code': 'US', 'name': 'United States', 'name_pt': 'Estados Unidos'},
	{'code': 'UY', 'name': 'Uruguay', 'name_pt': 'Uruguai'},
	{'code': 'UZ', 'name': 'Uzbekistan', 'name_pt': 'Uzbequistão'},
	{'code': 'VU', 'name': 'Vanuatu', 'name_pt': 'Vanuatu'},
	{'code': 'VE', 'name': 'Venezuela', 'name_pt': 'Venezuela'},
	{'code': 'VN', 'name': 'Vietnam', 'name_pt': 'Vietname'},
	{'code': 'VG', 'name': 'Virgin Islands, British', 'name_pt': 'Ilhas Virgens Britânicas'},
	{'code': 'VI', 'name': 'Virgin Islands, U.S.', 'name_pt': 'Ilhas Virgens Americanas'},
	{'code': 'YE', 'name': 'Yemen', 'name_pt': 'Iémen'},
	{'code': 'ZM', 'name': 'Zambia', 'name_pt': 'Zâmbia'},
	{'code': 'ZW', 'name': 'Zimbabwe', 'name_pt': 'Zimbabwe'},
];

const textEnglish = {
	'select': 'Select up to two countries to benchmark against the Federative Republic of Brazil.',
	'brazil': 'View Brazil Only',
	'all': 'ALL',
	'compare': 'Compare',
	'title': 'Country Selector'
}

const textPortuguese = {
	'select': 'Selecione não mais que dois países de referência para comparar com a República Federativa do Brasil',
	'brazil': 'Exibir apenas o Brasil',
	'all': 'Todos', 
	'compare': 'Comparar',
	'title': 'Seletor de país'
}

export default class CountryModal extends Component {
    constructor(props) {
        super(props);
        let country1 = this.props.country1 ? this.props.country1 : "";
        let country2 = this.props.country2 ? this.props.country2 : "";

        this.state = {
            country1: country1,
            country2: country2,
            selectedAlpha: 'all',
            searchTerm: '',
            searchOpen: false,
            leftVisible: false,
            rightVisible: true
        };
        this.back = this.back.bind(this);
        this.close = this.close.bind(this);
        this.handleAlpha = this.handleAlpha.bind(this);
       	this.handleSearchOpen = this.handleSearchOpen.bind(this);
       	this.handleSearchClose = this.handleSearchClose.bind(this);
       	this.handleChange = this.handleChange.bind(this);
        this.removeCountry1 = this.removeCountry1.bind(this);
        this.removeCountry2 = this.removeCountry2.bind(this);
        this.scrollLeft = this.scrollLeft.bind(this);
        this.scrollRight = this.scrollRight.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
        this.handleLink = this.handleLink.bind(this);
    }

    handleLink() {
    	let country1 = this.props.country1 ? this.props.country1 : "";
        let country2 = this.props.country2 ? this.props.country2 : "";
    	// if (this.state.country1 === country1 && this.state.country2 === country2) {
    		this.close();
    	// }
    }

    handleSearchOpen() {
    	this.setState({searchTerm:'',searchOpen:true,selectedAlpha:'all'});
    }

    handleSearchClose() {
		this.setState({searchTerm:'',searchOpen:false,selectedAlpha:'all'});
    }

    handleChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    scrollLeft() {
    	let scrollbar = this.refs.scrollbars;
		scrollbar.scrollLeft(scrollbar.getScrollLeft() - (scrollbar.getClientWidth() / 4) + 1);
    }

    scrollRight() {
    	let scrollbar = this.refs.scrollbars;
		scrollbar.scrollLeft(scrollbar.getScrollLeft() + (scrollbar.getClientWidth() / 4));
    }

    handleScroll() {
    	let scrollbar = this.refs.scrollbars;
    	let left = scrollbar.getScrollLeft() + scrollbar.getClientWidth();
    	let max = scrollbar.getScrollWidth();
        if (scrollbar.getScrollLeft() > 0) { //left visible
        	if (left >= max) { //right hidden
	        	this.setState({rightVisible: false, leftVisible: true});
	        } else { //right visible
	        	this.setState({rightVisible: true, leftVisible: true});
	        }
    	} else { //left hidden
    		if (left >= max) { //right hidden
	        	this.setState({rightVisible: false, leftVisible: false});
	        } else { //right visible
	        	this.setState({rightVisible: true, leftVisible: false});
	        }
    	}
    }

    handleCountry1(e) {
        this.setState({country1: e.target.value});
    }

    removeCountry1(e) {
    	if (this.state.country2.length > 0) {
			this.setState({country1: this.state.country2, country2: ''});
    	} else {
    		this.setState({country1: ""});
    	}
    }

    handleCountry2(e) {
        this.setState({country2: e.target.value});
    }

    removeCountry2(e) {
    	this.setState({country2: ""});
    }

    handleAlpha(e) {
    	let id = e.target.id;
    	if (id === 'all-alpha') {
    		id = 'all';
    	}
    	if (this.refs.scrollbars) {
    	    this.refs.scrollbars.scrollLeft(0);
		}
    	this.setState({selectedAlpha: id, leftVisible: false, rightVisible: true});
    }

    handleCountry(country) {
    	let clicked = country;
    	let country1 = this.state.country1;
    	let country2 = this.state.country2;
    	if (clicked === country1) { //unselect. keep things in order by shifting country2 to country1.
    		this.setState({country1: country2, country2: ''});
    	} else if (clicked === country2) { //unselect. no need to reorder.
    		this.setState({country2: ''});
    	} else if (country1.length === 0) { //first selection
			this.setState({country1: clicked});
    	} else if (country2.length === 0) { //second selection
    		this.setState({country2: clicked});
    	}
    }

    back() {
    	let notNavbar = this.props.notNavbar ? this.props.notNavbar : false;
		this.props.closeCountryModal();
    	/* if (notNavbar) {
    		this.props.closeCountryModal();
    	} else {
    		this.props.closeCountryModal();
        	// this.props.openBenchmarkingModal();
        } */
    }

    close() {
        this.props.closeCountryModal();
    }

    render() {
    	let pt = false;
		let text = {};
		if (this.props.location.pathname.substring(0,4) !== "/pt/") {
			text = textEnglish;
		} else {
			pt = true;
			text = textPortuguese;
		}
    	// // console.log(this.state);
        let countriesList = availableCountriesWithNames;
        let selectedCountrySection = (<div></div>);

        if (this.state.country1.length === 0 && this.state.country2.length === 0) { //none selected
        	selectedCountrySection = (
	        		<div>
	                    <div className="row">
	                    	<div className="selection-text">
	                        {text.select}
	                        </div>
	                    </div>

	                    <div className="row">
		                    <Link onClick={this.handleLink} className="country-buttons" id="just-br-button" to={{
                              pathname: (pt ? '/pt/benchmark' : '/benchmark'),
                              state: { countries: [] }
                            }}>{text.brazil}
                            </Link>
		                </div>
		            </div>
	            );
	    } else if (this.state.country1.length > 0 && this.state.country2.length === 0) { //one selected
	    	let name = "";
	    	for (let i = 0; i < availableCountriesWithNames.length; i++){
	    		if (availableCountriesWithNames[i].code === this.state.country1){
	    			name = pt ? availableCountriesWithNames[i].name_pt : availableCountriesWithNames[i].name;
	    		}
	    	}
        	selectedCountrySection = (
	        		<div>
	                    <div className="row">
	                    	<div className="one-country">
	                    		<div id="one-country-close" onClick={this.removeCountry1}><i className="fa fa-close"></i></div>
	                    		<div className={"flag-icon header-flag-icon-triple flag-icon-" + this.state.country1.toLowerCase()}></div>
								<div id="one-country-name">{name}</div>
	                    	</div>
	                    </div>

	                    <div className="row">
	                        <Link onClick={this.handleLink} className="country-buttons" id="compare-button" to={{
                              pathname: (pt ? '/pt/benchmark' : '/benchmark'),
                              state: { countries: [this.state.country1] }
                            }}>{text.compare}
                            </Link>
	                	</div>
		            </div>
	            );
	    } else {
	    	let name1 = "";
	    	let name2 = "";
	    	for (let i = 0; i < availableCountriesWithNames.length; i++){
	    		if (availableCountriesWithNames[i].code === this.state.country1){
	    			name1 = availableCountriesWithNames[i].name;
	    		}
	    		if (availableCountriesWithNames[i].code === this.state.country2){
	    			name2 = availableCountriesWithNames[i].name;
	    		}
	    	}
        	selectedCountrySection = (
	        		<div>
	                    <div className="row">
	                    	<div className="two-country-one">
	                    		<div id="two-country-one-close" onClick={this.removeCountry1}><i className="fa fa-close"></i></div>
	                    		<div className={"flag-icon header-flag-icon-triple flag-icon-" + this.state.country1.toLowerCase()}></div>
								<div id="two-country-one-name">{name1}</div>
	                    	</div>
	                    	<img id="two-country-divider" src={divider} />
	                    	<div className="two-country-two">
	                    		<div id="two-country-two-close" onClick={this.removeCountry2}><i className="fa fa-close"></i></div>
	                    		<div className={"flag-icon header-flag-icon-triple flag-icon-" + this.state.country2.toLowerCase()}></div>
								<div id="two-country-two-name">{name2}</div>
	                    	</div>
	                    </div>

	                    <div className="row">
	                        <Link onClick={this.handleLink} className="country-buttons" id="compare-button" to={{
                              pathname: (pt ? '/pt/benchmark' : '/benchmark'),
                              state: { countries: [this.state.country1, this.state.country2] }
                            }}>{text.compare}
                            </Link>
	                	</div>
		            </div>
	            );
	    }

	    let filterSection = (
			<div className="row">
            	<div id="add-title">{text.title}</div>
            	<div id="add-line"></div>
            	<ButtonGroup id="alpha-filters">
            		<Button className={this.state.selectedAlpha === "all" ? "alpha-filter selected" : "alpha-filter"} id="all-alpha" onClick={this.handleAlpha}>{text.all}</Button>
                	<Button className={this.state.selectedAlpha === "ae" ? "alpha-filter selected" : "alpha-filter"} id="ae" onClick={this.handleAlpha}>A - E</Button>
                	<Button className={this.state.selectedAlpha === "fj" ? "alpha-filter selected" : "alpha-filter"} id="fj" onClick={this.handleAlpha}>F - J</Button>
                	<Button className={this.state.selectedAlpha === "kp" ? "alpha-filter selected" : "alpha-filter"} id="kp" onClick={this.handleAlpha}>K - P</Button>
                	<Button className={this.state.selectedAlpha === "qu" ? "alpha-filter selected" : "alpha-filter"} id="qu" onClick={this.handleAlpha}>Q - U</Button>
                	<Button className={this.state.selectedAlpha === "vz" ? "alpha-filter selected" : "alpha-filter"} id="vz" onClick={this.handleAlpha}>V - Z</Button>
            	</ButtonGroup>
            	<i onClick={this.handleSearchOpen} id="modal-magnify" className="fa fa-search"></i>
            </div>
	    );

	    if (this.state.searchOpen) {
	    	filterSection = (
			<div className="row">
            	<div id="add-title">{text.title}</div>
            	<div id="add-line"></div>
            	
            	<input type="text" id="country-filter" name="searchTerm" value={this.state.searchTerm} onChange={this.handleChange} placeholder="Search Countries" title="Type in a country name" />
            	<i id="country-filter-close" onClick={this.handleSearchClose} className="fa fa-close" />
            </div>
	    	);
	    }

	    let countryClass = "country";
	    if (this.state.country1.length > 0 && this.state.country2.length > 0) {
	    	countryClass = "country inactive"
	    }
	    let noResults = true;
	    let count = 0;
	    let countries = (
	    	<div>
	            <i id="left" className="fa fa-angle-left fa-3x" onClick={this.scrollLeft} aria-hidden="true" style={this.state.leftVisible ? {visibility: 'visible'} : {visibility: 'hidden'}} />
	            <Scrollbars id="countriesScrollbar" style={{ width: 715, height: 200 }} ref="scrollbars"
					renderTrackHorizontal={props => <div {...props} className="track-horizontal"/>}
			        renderThumbHorizontal={props => <div {...props} className="thumb-horizontal"/>}
			        //onScroll={this.handleScroll}
	            >
	                <div className="countries">
	                	{countriesList.map((country, idx)=> {
	                		let name = pt ? country.name_pt : country.name;
	                		if (this.state.selectedAlpha === 'ae') {
	                    		if (name >= 'A' && name < 'F') {
	                    			noResults = false;
	                    			count += 1;
	                    			return <div title={(countryClass != "country") ? "May only select up to 2 countries" : name.toUpperCase()} key={country.code} className={(this.state.country1 === country.code || this.state.country2 === country.code) ? "country selected" : countryClass} id={country.code} onClick={()=> {this.handleCountry(country.code)}}><div className={"flag-icon header-flag-icon-triple flag-icon-" + (country.code).toLowerCase()}></div><br />{name}</div>
	                    		}
	                		} else if (this.state.selectedAlpha === 'fj') {
	                    		if (name >= 'F' && name < 'K') {
	                    			noResults = false;
	                    			count += 1;
	                    			return <div title={(countryClass != "country") ? "May only select up to 2 countries" : name.toUpperCase()} key={country.code} className={(this.state.country1 === country.code || this.state.country2 === country.code) ? "country selected" : countryClass} id={country.code} onClick={()=> {this.handleCountry(country.code)}}><div className={"flag-icon header-flag-icon-triple flag-icon-" + (country.code).toLowerCase()}></div><br />{name}</div>
	                    		}
	                		} else if (this.state.selectedAlpha === 'kp') {
	                    		if (name >= 'K' && name < 'Q') {
	                    			noResults = false;
	                    			count += 1;
	                    			return <div title={(countryClass != "country") ? "May only select up to 2 countries" : name.toUpperCase()} key={country.code} className={(this.state.country1 === country.code || this.state.country2 === country.code) ? "country selected" : countryClass} id={country.code} onClick={()=> {this.handleCountry(country.code)}}><div className={"flag-icon header-flag-icon-triple flag-icon-" + (country.code).toLowerCase()}></div><br />{name}</div>
	                    		}
	                		} else if (this.state.selectedAlpha === 'qu') {
	                    		if (name >= 'Q' && name < 'V') {
	                    			noResults = false;
	                    			count += 1;
	                    			return <div title={(countryClass != "country") ? "May only select up to 2 countries" : name.toUpperCase()} key={country.code} className={(this.state.country1 === country.code || this.state.country2 === country.code) ? "country selected" : countryClass} id={country.code} onClick={()=> {this.handleCountry(country.code)}}><div className={"flag-icon header-flag-icon-triple flag-icon-" + (country.code).toLowerCase()}></div><br />{name}</div>
	                    		}
	                		} else if (this.state.selectedAlpha === 'vz') {
	                    		if (name >= 'V') {
	                    			noResults = false;
	                    			count += 1;
	                    			return <div title={(countryClass != "country") ? "May only select up to 2 countries" : name.toUpperCase()} key={country.code} className={(this.state.country1 === country.code || this.state.country2 === country.code) ? "country selected" : countryClass} id={country.code} onClick={()=> {this.handleCountry(country.code)}}><div className={"flag-icon header-flag-icon-triple flag-icon-" + (country.code).toLowerCase()}></div><br />{name}</div>
	                    		}
	                		} else {
	                			if (name.toUpperCase().indexOf(this.state.searchTerm.toUpperCase()) > -1) {
	                				noResults = false;
	                				count += 1;
	                				return <div title={(countryClass != "country") ? "May only select up to 2 countries" : name.toUpperCase()} key={country.code} className={(this.state.country1 === country.code || this.state.country2 === country.code) ? "country selected" : countryClass} id={country.code} onClick={()=> {this.handleCountry(country.code)}}><div className={"flag-icon header-flag-icon-triple flag-icon-" + (country.code).toLowerCase()}></div><br />{name}</div>
	                			}
	                		}
	                	})}     
	                </div>
	            </Scrollbars>
	            <i id="right" className="fa fa-angle-right fa-3x" onClick={this.scrollRight} aria-hidden="true" style={(this.state.rightVisible && count > 4) ? {visibility: 'visible'} : {visibility: 'hidden'}} />
	        </div>
        );

		if (noResults) {
			countries = (<div id="no-country-results">No Countries Found</div>);
		}

        return (
            <Modal show={this.props.showing} onHide={this.close} id="country-modal">
                <Modal.Body>
                    <div id="country-container">
	                    {/*<div id="modal-back" onClick={this.back}><img src={modalBack} /></div>*/}
	                    <div id="country-modal-close" onClick={this.close}><img src={modalClose} /></div>
	                    <div id="country-options-section">
		                    {selectedCountrySection}
		                    {filterSection}
		                    {countries}
	                    </div>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
};

