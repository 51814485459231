import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Collapse } from "react-bootstrap";
import "../about/About.css";
import WelcomeFooter from "../menu/WelcomeFooter";
import Navbar from "../common/navbar/NavbarPLR";
import ChevronRightIcon from "../about/img/chevron-right.svg";
import StrategyHeader from "../common/strategyheader";
import AboutCover from "../about/img/about-cover.png";
import { WelcomeSearchContent } from "./WelcomeSearchContent";
import axios from "axios";
import { API_URL } from "../../../config/api";

const textEnglish = {
  login: "Login",
  logout: "Log Out",
  med: "About Open Brazil",

  about_title: "Introduction",
  about_section: [
    "The Country Partnership Framework, which was ",
    {
      text: "updated in 2020",
      url: "http://documents.worldbank.org/curated/en/148141498229092629/Brazil-Country-partnership-framework-for-the-period-FY18-FY23",
    }, //link to plr doc
    " , lays out three strategic focus areas for WBG activities (or work) in Brazil, pursued in close partnership with the government, the private sector, civil society, the international community, and other stakeholders: (i) fiscal consolidation and government effectiveness; (ii) private sector investment, infrastructure, and productivity growth; and (iii) inclusive and sustainable development. According to the ",
    {
      text: "2016 Systematic Country Diagnostic",
      url: "http://documents.worldbank.org/curated/en/180351467995438283/Brazil-Systematic-country-diagnostic",
    },
    ", these efforts are critical for eliminating extreme poverty and boosting shared prosperity, the WBG’s corporate mission (or the so-called twin goals).",
  ],

  how_to: "How to navigate Open Brazil",

  overview_title: "Overview",
  overview_section: [
    "Open Brazil is organized around the three focus areas of the CPF, which are described in more detail ",
    {
      text: "here",
      url: "http://documents.worldbank.org/curated/en/148141498229092629/Brazil-Country-partnership-framework-for-the-period-FY18-FY23",
    }, //link to cpf pillars
    ". You can easily obtain an overview of Open Brazil through our ",
    { text: "story map", url: "/story" },
    ", which provides information on the rationale underlying the WBG’s engagement model in Brazil and will walk you through the key results achieved to date. The story map intuitively summarizes the key information contained on Open Brazil.",
  ],

  tutorial_title: "Tutorial",

  portfolio_title: "Portfolio footprint",
  portfolio_section: [
    "Where does the World Bank Group operate in Brazil? Generally, the WBG works at the national, state, and municipal level. For state and municipal projects, Open Brazil’s ",
    { text: "portfolio footprint", url: "/portfolio" },
    " allows you to geo-locate them and investigate some basic socio-economic indicators in the relevant regions. This will allow you to investigate whether WBG projects focus on areas where there is real need, for example, where many poor people live or where environmental sustainability is threatened. For an increasing number of projects, the disbursement data underlying the portfolio footprint are sourced in real time from the Brazilian government’s open data system.",
  ],

  projects_title: "Projects",
  projects_section: [
    [
      "To learn about specific WBG projects you can visit our projects’ sites, either through the portfolio footprint or, more directly, by clicking ",
      { text: "here", url: "/projects" },
      ". For selected projects, such as ",
      {
        text: "Rio Grande do Norte: Regional Development and Governance",
        url: "/projects",
        focus: "focus_area_1",
        project: "P126452",
      }, //not sure how we're linking this???{"text": "delivery challenges", "url": '/evidence', "selected_tab": 1},
      " and ",
      {
        text: "Pernambuco Rural Economic Inclusion",
        url: "/projects",
        focus: "focus_area_3",
        project: "P120139",
      },
      " you will be able to see additional information collected by the World Bank’s new ",
      {
        text: "Smart Supervision App",
        url: "https://olc.worldbank.org/system/files/Big-Data-Case-Study-Harnessing-Data.pdf",
      },
      " , which not only collects real-time disbursement information but also allows World Bank teams to interact with project beneficiaries and upload additional information on project progress, such as pictures from project sites.",
    ],
  ],

  impact_title: "Impact",
  impact_section: [
    [
      "What results does the WBG achieve? All WBG projects and strategies are accompanied by results frameworks laying out quantitative baselines and targets. Open Brazil constitutes a major innovation in the way indicators are captured, leveraging technology. For example, to estimate the impact of WBG research on the national dialogue around ",
      { text: "fiscal policy", url: "/indicators" }, //objective 1.1
      " or ",
      { text: "business regulations", url: "/indicators", objective: "2.1" }, //objective 2.1
      " Open Brazil analyzes social media data.",
    ],
    [
      "To visually showcase the impact of WBG-supported ",
      { text: "preservation policies", url: "/indicators", objective: "3.1" }, //objective 3.1
      " on deforestation, Open Brazil leverages satellite images. Open Brazil directly communicates with government systems to update on progress on the ",
      { text: "fiscal sustainability of Brazilian states", url: "/indicators" }, //objective 1.1
      " and the ",
      {
        text: "quality of the Brazilian procurement system",
        url: "/indicators",
      }, //objective 1.1
      ". Many other indicators directly link to internal WBG systems and are updated in real-time during project supervision.",
    ],
    [
      "To draw lessons from development initiatives, Open Brazil places particular emphasis on impact evaluations. Projects accompanied by impact evaluations are tagged on the individual project pages in Open Brazil. Results from impact evaluations from Brazil and beyond, supported by the WBG or not, are available on Open Brazil’s ",
      { text: "results pages", url: "/evidence" }, //what works in brazil
      ". These pages also provide information on lessons on ",
      { text: "delivery challenges", url: "/evidence", selected_tab: 1 }, //decode
      ", drawn from thousands of assessments of the WBG’s Independent Evaluation Group.",
    ],
  ],

  sentiment_title: "Online Media Snapshot",
  sentiment_section: [
    "How do WBG interventions align with the national dialogue in Brazil? To answer this question, we invite you to visit our ",
    { text: "Online Media Snapshot pages", url: "/insights" },
    ", which provide real time information on social media discussions in Brazil around policy areas relevant to the three focus areas of the CPF.",
  ],

  more_info: "Further information and contact",
  more_section: [
    "Further information on the World Bank in Brazil, including a macroeconomic and poverty background, can be obtained from the dedicated ",
    {
      text: "country page",
      url: "https://www.worldbank.org/en/country/brazil",
    },
    ". Contact with WBG teams can also be established via the country page, ",
    {
      text: "Facebook",
      url: "https://www.linkedin.com/showcase/ifc-am%C3%A9rica-latina-y-el-caribe/",
    },
    " and ",
    { text: "Twitter", url: "http://www.twitter.com/bancomundialbr" },
    ". ",
    "To contact IFC Latin America you can visit IFC’s ",
    {
      text: "website",
      url: "https://www.ifc.org/wps/wcm/connect/region__ext_content/IFC_External_Corporate_Site/latin+america+and+the+caribbean",
    },
    ", ",
    {
      text: "Linkedin",
      url: "https://www.linkedin.com/showcase/ifc-am%C3%A9rica-latina-y-el-caribe/",
    },
    " and ",
    { text: "Twitter", url: "https://twitter.com/IFC_LAC" },
    ". ",
  ],
};

const textPortuguese = {
  login: "Login",
  logout: "Log Out",
  med: "Sobre o Brasil Aberto",

  about_title: "Introdução",
  about_section: [
    "A Estratégia de Parceria com o País (em inglês, Country Partnership Framework ou CPF) define três áreas de foco estratégicas para as atividades (ou trabalho) do Grupo Banco Mundial (GBM) no Brasil. ",
    /* 		{"text": "atualizada em 2020", "url": 'http://documents.worldbank.org/curated/en/148141498229092629/Brazil-Country-partnership-framework-for-the-period-FY18-FY23'}, //link to plr doc
		", define três áreas de foco estratégicas para as atividades (ou trabalho) do GBM no Brasil, realizadas em estreita parceria com o governo, o setor privado, a sociedade civil, a comunidade internacional e outras partes interessadas: (i) consolidação fiscal e eficácia de governo; (ii) investimento, infraestrutura e crescimento da produtividade do setor privado; e (iii) desenvolvimento inclusivo e sustentável. De acordo com o ",
		{"text": "Diagnóstico Sistemático de País de 2016", "url": "http://documents.worldbank.org/curated/en/180351467995438283/Brazil-Systematic-country-diagnostic"},
		", esses esforços são fundamentais para eliminar a pobreza extrema e aumentar a prosperidade compartilhada (os chamados \"objetivos gêmeos\") que compõem a missão corporativa do GBM."
 */
  ],

  how_to: "Como navegar no Brasil Aberto",

  overview_title: "Visão Geral",
  overview_section: [
    "O Open Brasil foi organizado em torno das três áreas de foco da Estratégia de Parceria para o País, descritas em mais detalhes ",
    {
      text: "aqui",
      url: "http://documents.worldbank.org/curated/en/148141498229092629/Brazil-Country-partnership-framework-for-the-period-FY18-FY23",
    }, //link to cpf pillars
    ". É fácil ter acesso a uma visão geral do Brasil Aberto por meio do ",
    { text: "histórico", url: "/pt/story" },
    ", que contém informações sobre a lógica subjacente ao modelo de engajamento do GBM no Brasil e um guia com os principais resultados alcançados até o momento. O histórico resume, intuitivamente, as principais informações contidas no Brasil Aberto.",
  ],

  tutorial_title: "Tutorial",

  portfolio_title: "Alcance do Portfólio",
  portfolio_section: [
    "Onde o Grupo Banco Mundial opera no Brasil? De modo geral, o GBM trabalha nos níveis nacional, estadual e municipal. A página de ",
    { text: "alcance do portfólio", url: "/pt/portfolio" },
    " do Brasil Aberto permite situar geograficamente os projetos estaduais e municipais e consultar alguns indicadores socioeconômicos básicos nas regiões relevantes. Assim, você poderá investigar se os projetos do GBM se concentram em áreas com necessidades reais; por exemplo, regiões que concentram muitas pessoas pobres ou onde a sustentabilidade ambiental está ameaçada. Em um número crescente de projetos, os dados de desembolso que demonstram o alcance do portfólio são provenientes, em tempo real, do sistema de dados abertos do governo brasileiro.",
  ],

  projects_title: "Projetos",
  projects_section: [
    [
      "Para saber mais sobre projetos específicos do GBM, você pode visitar os sites de nossos projetos através da página de alcance do portfólio ou, mais diretamente, clicando ",
      { text: "aqui", url: "/pt/projects" },
      ". Em projetos selecionados, como o ",
      {
        text: "Rio Grande do Norte: Desenvolvimento Regional e Governança",
        url: "/pt/projects",
        focus: "focus_area_1",
        project: "P126452",
      }, //not sure how we're linking this???{"text": "delivery challenges", "url": '/evidence', "selected_tab": 1},
      " e o de ",
      {
        text: "Inclusão Econômica Rural de Pernambuco",
        url: "/pt/projects",
        focus: "focus_area_3",
        project: "P120139",
      },
      ", você poderá ver informações adicionais reunidas pelo novo app ",
      {
        text: "Smart Supervision",
        url: "https://olc.worldbank.org/system/files/Big-Data-Case-Study-Harnessing-Data.pdf",
      },
      " do Banco Mundial, que não apenas coleta informações de desembolso em tempo real, mas também permite que as equipes do Banco Mundial interajam com os beneficiários do projeto e enviem informações adicionais sobre o andamento do projeto (por exemplo, fotos dos locais do projeto).",
    ],
    // [
    // 	"Com vistas a melhorar a seletividade e aumentar o impacto, a Revisão de Desempenho e Aprendizagem de 2020 introduziu novos critérios na seleção de projetos. Marcados no Brasil Aberto por ícones intuitivos, esses critérios são: inovação / escalabilidade ",
    // 	{"icon": "loop loop-electricity-1 about-text-icon"}, //icon1
    // 	", complexidade do desafio de desenvolvimento ",
    // 	{"icon": "loop loop-regional about-text-icon"}, //icon2
    // 	", provisão de bens públicos globais ",
    // 	{"icon": "loop loop-road about-text-icon"}, //icon3
    // 	", fortalecimento de instituições ",
    // 	{"icon": "loop loop-government about-text-icon"}, //icon4
    // 	" e, quando relevante, mobilização de financiamento privado",
    // 	{"icon": "loop loop-money about-text-icon"}, //icon5
    // 	". Sobre os projetos previstos para 2020 e 2021, preparamos ",
    // 	{"text": "vídeos curtos", "url": null}, //don't have videos yet
    // 	" que descrevem as motivações e a lógica do projeto.",
    // ]
  ],

  impact_title: "Impacto",
  impact_section: [
    [
      "Quais são os resultados alcançados pelo GBM? Todos os projetos e estratégias do GBM são acompanhados por estruturas de resultados que definem linhas de base e metas quantitativas. O Brasil Aberto representa uma grande inovação na forma como os indicadores são capturados, usando diversas tecnologias. Por exemplo, para estimar o impacto das pesquisas do GBM no diálogo nacional sobre ",
      { text: "política fiscal", url: "/pt/indicators" }, //objective 1.1
      " e ",
      {
        text: "regulamentos de negócios",
        url: "/pt/indicators",
        objective: "2.1",
      }, //objective 2.1
      ", o Brasil Aberto analisa dados de mídias sociais.",
    ],
    [
      "Para ilustrar visualmente o impacto das ",
      {
        text: "políticas de preservação",
        url: "/pt/indicators",
        objective: "3.1",
      }, //objective 3.1
      " em matéria de desmatamento apoiadas pelo GBM, o Brasil Aberto faz uso de imagens de satélite. O Brasil Aberto se comunica diretamente com os sistemas de governo para atualizar os avanços na ",
      {
        text: "sustentabilidade fiscal dos estados brasileiros",
        url: "/pt/indicators",
      }, //objective 1.1
      " e a ",
      {
        text: "qualidade do sistema de aquisições no Brasil",
        url: "/pt/indicators",
      }, //objective 1.1
      ". Vários outros indicadores estão ligados diretamente aos sistemas internos do GBM e são atualizados em tempo real durante a supervisão do projeto.",
    ],
    [
      "Para extrair lições das iniciativas de desenvolvimento, o Brasil Aberto enfatiza principalmente as avaliações de impacto. Os projetos acompanhados de avaliações de impacto são marcados nas páginas individuais do projeto no Brasil Aberto. Os resultados das avaliações de impacto no Brasil e no exterior, com ou sem o apoio do GBM, estão disponíveis nas ",
      { text: "páginas de resultados", url: "/pt/evidence" }, //what works in brazil
      " do Brasil Aberto. Essas páginas também apresentam informações com lições sobre os ",
      {
        text: "desafios de implementação",
        url: "/pt/evidence",
        selected_tab: 1,
      }, //decode
      ", extraídas de milhares de avaliações do Grupo de Avaliação Independente do GBM.",
    ],
  ],

  sentiment_title: "Panorama Online",
  sentiment_section: [
    "Como as intervenções do GBM se alinham com o diálogo nacional no Brasil? Para responder a esta pergunta, convidamos você a visitar as nossas ",
    { text: "páginas de Panorama Online", url: "/pt/insights" },
    ", que trazem informações em tempo real sobre discussões nas mídias sociais no Brasil sobre áreas de política relevantes para as três áreas de foco da CPF.",
  ],

  more_info: "Mais informações e contato",
  more_section: [
    "Informações adicionais sobre o Banco Mundial no Brasil, incluindo um histórico macroeconômico sobre a pobreza, estão disponíveis na ",
    {
      text: "página do país",
      url: "https://www.worldbank.org/pt/country/brazil",
    },
    ". Também é possível entrar em contato com as equipes do GBM pela página do ",
    { text: "Banco Mundial no Brasil", url: "http://www.bancomundial.org.br/" },
    ", pelo ",
    { text: "Facebook", url: "http://www.facebook.com/bancomundialbrasil" },
    " e pelo ",
    { text: "Twitter", url: "http://www.twitter.com/bancomundialbr" },
    ". ",
    "Para contactar o IFC na América Latina, visite seu ",
    {
      text: "website",
      url: "https://www.ifc.org/wps/wcm/connect/region__ext_content/IFC_External_Corporate_Site/latin+america+and+the+caribbean",
    },
    ", ",
    {
      text: "Linkedin",
      url: "https://www.linkedin.com/showcase/ifc-am%C3%A9rica-latina-y-el-caribe/",
    },
    " e ",
    { text: "Twitter", url: "https://twitter.com/IFC_LAC" },
    ". ",
  ],
};

class About extends Component {
  constructor(props) {
    super(props);

    this.state = {
      portOpen: false,
      projOpen: false,
      impactOpen: false,
      sentimentOpen: false,
      moreOpen: false,
      searchInput: "",
			projects: [],
    };

    this.handleLogout = this.handleLogout.bind(this);
    this.toggleSection = this.toggleSection.bind(this);
  }

  handleLogout() {
    localStorage.removeItem("auth");
    this.props.history.push("/");
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  toggleSection(section) {
    this.setState({
      [section]: !this.state[section],
    });
  }

	fetchProjects = async () => {
    const ret = await axios
      .get(`${API_URL}/projects`);

    this.setState({ projects: ret.data });
  }

  componentDidMount() {
    this.fetchProjects();
  }
  
  render() {
    console.log(this.state.searchInput.length);

    const pt = this.props.location.pathname.substring(0, 4) == "/pt/";
    let text = {};
    if (this.props.location.pathname.substring(0, 4) !== "/pt/") {
      text = textEnglish;
    } else {
      text = textPortuguese;
    }
    return (
      <div>
        <div className="container-fluid unpadify">
          <Navbar
            handleLogin={this.handleLogin}
            handleLogout={this.handleLogout}
            auth={this.props.auth}
            isLoggedIn={this.props.loggedIn}
            checkIsLoggedIn={this.props.checkIsLoggedIn}
            onChangeLoginState={this.props.onChangeLoginState}
						searchInput={this.state.searchInput}
        		setSearchInput={(v) => this.setState({searchInput: v})}
            {...this.props}
            currentPage="about"
          />
          {this.state.searchInput.length === 0 ? (
            <>
              <StrategyHeader
                title={pt ? "Sobre o Brasil Aberto" : "About Open Brazil"}
                backgroundImage={AboutCover}
              />
              <div className="about-content-container">
                <div className="about-title">{text.about_title}</div>
                <div className="about-divider"></div>
                <div className="about-text-sections">
                  {text.about_section.map((content, index) => {
                    if (content.text) {
                      return (
                        <span
                          key={"about-" + index}
                          className="about-text-body"
                        >
                          <a
                            href={content.url}
                            target={
                              content.url !== null
                                ? content.url.indexOf("http") > -1
                                  ? "_blank"
                                  : null
                                : null
                            }
                          >
                            {content.text}
                          </a>
                        </span>
                      );
                    } else {
                      return (
                        <span
                          key={"about-" + index}
                          className="about-text-body"
                        >
                          {content}
                        </span>
                      );
                    }
                  })}
                </div>
                <div className="about-how-to">{text.how_to}</div>
                <div className="about-text-sections">
                  <div className="about-text-overview">
                    {text.overview_title}
                  </div>
                  {text.overview_section.map((content, index) => {
                    if (content.text) {
                      return (
                        <span
                          key={"overview-" + index}
                          className="about-text-body"
                        >
                          <a
                            href={content.url}
                            target={
                              content.url !== null
                                ? content.url.indexOf("http") > -1
                                  ? "_blank"
                                  : null
                                : null
                            }
                          >
                            {content.text}
                          </a>
                        </span>
                      );
                    } else {
                      return (
                        <span
                          key={"overview-" + index}
                          className="about-text-body"
                        >
                          {content}
                        </span>
                      );
                    }
                  })}
                </div>
                <div
                  className="about-accordion-section"
                  style={{ marginTop: "22px" }}
                >
                  <div
                    className="about-text-title"
                    onClick={() => {
                      this.toggleSection("portOpen");
                    }}
                  >
                    <img src={ChevronRightIcon} />
                    <div className="about-accordion-title">
                      {text.portfolio_title}
                    </div>
                  </div>
                  <Collapse in={this.state.portOpen}>
                    <div className="about-text-subsections">
                      {text.portfolio_section.map((content, index) => {
                        if (content.text) {
                          return (
                            <span
                              key={"portfolio-" + index}
                              className="about-text-body"
                            >
                              <a
                                href={content.url}
                                target={
                                  content.url !== null
                                    ? content.url.indexOf("http") > -1
                                      ? "_blank"
                                      : null
                                    : null
                                }
                              >
                                {content.text}
                              </a>
                            </span>
                          );
                        } else {
                          return (
                            <span
                              key={"portfolio-" + index}
                              className="about-text-body"
                            >
                              {content}
                            </span>
                          );
                        }
                      })}
                    </div>
                  </Collapse>
                </div>
                <div className="about-accordion-section">
                  <div
                    className="about-text-title"
                    onClick={() => {
                      this.toggleSection("projOpen");
                    }}
                  >
                    <img src={ChevronRightIcon} />
                    <div className="about-accordion-title">
                      {text.projects_title}
                    </div>
                  </div>
                  <Collapse in={this.state.projOpen}>
                    <div>
                      {text.projects_section.map((section, index) => {
                        let section_content = section.map((content, index) => {
                          if (content.project) {
                            return (
                              <span
                                key={"proj-" + index}
                                className="about-text-body"
                              >
                                <Link
                                  to={{
                                    pathname: content.url,
                                    state: {
                                      project: content.project,
                                      focus: content.focus,
                                    },
                                  }}
                                >
                                  {content.text}
                                </Link>
                              </span>
                            );
                          } else if (content.text) {
                            return (
                              <span
                                key={"proj-" + index}
                                className="about-text-body"
                              >
                                <a
                                  href={content.url}
                                  target={
                                    content.url !== null
                                      ? content.url.indexOf("http") > -1
                                        ? "_blank"
                                        : null
                                      : null
                                  }
                                >
                                  {content.text}
                                </a>
                              </span>
                            );
                          } else if (content.icon !== undefined) {
                            return (
                              <span key={"proj-" + index}>
                                <i
                                  className={content.icon}
                                  aria-hidden="true"
                                ></i>
                              </span>
                            );
                          } else {
                            return (
                              <span
                                key={"proj-" + index}
                                className="about-text-body"
                              >
                                {content}
                              </span>
                            );
                          }
                        });
                        return (
                          <div
                            className="about-text-subsections"
                            key={"proj-section-" + index}
                          >
                            {section_content}
                          </div>
                        );
                      })}
                    </div>
                  </Collapse>
                </div>
                <div className="about-accordion-section">
                  <div
                    className="about-text-title"
                    onClick={() => {
                      this.toggleSection("impactOpen");
                    }}
                  >
                    <img src={ChevronRightIcon} />
                    <div className="about-accordion-title">
                      {text.impact_title}
                    </div>
                  </div>
                  <Collapse in={this.state.impactOpen}>
                    <div>
                      {text.impact_section.map((section, index) => {
                        let section_content = section.map((content, index) => {
                          if (content.text) {
                            if (content.objective) {
                              return (
                                <span
                                  key={"impact-" + index}
                                  className="about-text-body"
                                >
                                  <Link
                                    to={{
                                      pathname: content.url,
                                      state: { objective: content.objective },
                                    }}
                                  >
                                    {content.text}
                                  </Link>
                                </span>
                              );
                            } else if (content.selected_tab) {
                              return (
                                <span
                                  key={"impact-" + index}
                                  className="about-text-body"
                                >
                                  <Link
                                    to={{
                                      pathname: content.url,
                                      state: {
                                        selected_tab: content.selected_tab,
                                      },
                                    }}
                                  >
                                    {content.text}
                                  </Link>
                                </span>
                              );
                            } else {
                              return (
                                <span
                                  key={"impact-" + index}
                                  className="about-text-body"
                                >
                                  <a
                                    href={content.url}
                                    target={
                                      content.url !== null
                                        ? content.url.indexOf("http") > -1
                                          ? "_blank"
                                          : null
                                        : null
                                    }
                                  >
                                    {content.text}
                                  </a>
                                </span>
                              );
                            }
                          } else {
                            return (
                              <span
                                key={"impact-" + index}
                                className="about-text-body"
                              >
                                {content}
                              </span>
                            );
                          }
                        });
                        return (
                          <div
                            className="about-text-subsections"
                            key={"impact-section-" + index}
                          >
                            {section_content}
                          </div>
                        );
                      })}
                    </div>
                  </Collapse>
                </div>
                <div className="about-accordion-section">
                  <div
                    className="about-text-title"
                    onClick={() => {
                      this.toggleSection("sentimentOpen");
                    }}
                  >
                    <img src={ChevronRightIcon} />
                    <div className="about-accordion-title">
                      {text.sentiment_title}
                    </div>
                  </div>
                  <Collapse in={this.state.sentimentOpen}>
                    <div className="about-text-subsections">
                      {text.sentiment_section.map((content, index) => {
                        if (content.text) {
                          return (
                            <span
                              key={"sentiment-" + index}
                              className="about-text-body"
                            >
                              <a
                                href={content.url}
                                target={
                                  content.url !== null
                                    ? content.url.indexOf("http") > -1
                                      ? "_blank"
                                      : null
                                    : null
                                }
                              >
                                {content.text}
                              </a>
                            </span>
                          );
                        } else {
                          return (
                            <span
                              key={"sentiment-" + index}
                              className="about-text-body"
                            >
                              {content}
                            </span>
                          );
                        }
                      })}
                    </div>
                  </Collapse>
                </div>
                <div className="about-accordion-section">
                  <div
                    className="about-text-title"
                    onClick={() => {
                      this.toggleSection("moreOpen");
                    }}
                  >
                    <img src={ChevronRightIcon} />
                    <div className="about-accordion-title">
                      {text.more_info}
                    </div>
                  </div>
                  <Collapse in={this.state.moreOpen}>
                    <div className="about-text-subsections">
                      {text.more_section.map((content, index) => {
                        if (content.text) {
                          return (
                            <span
                              key={"more-" + index}
                              className="about-text-body"
                            >
                              <a
                                href={content.url}
                                target={
                                  content.url !== null
                                    ? content.url.indexOf("http") > -1
                                      ? "_blank"
                                      : null
                                    : null
                                }
                              >
                                {content.text}
                              </a>
                            </span>
                          );
                        } else {
                          return (
                            <span
                              key={"more-" + index}
                              className="about-text-body"
                            >
                              {content}
                            </span>
                          );
                        }
                      })}
                    </div>
                  </Collapse>
                </div>
              </div>
              <WelcomeFooter {...this.props} />
            </>
          ) : <WelcomeSearchContent projects={this.state.projects} pt={pt} searchText={this.state.searchInput} />}
        </div>
      </div>
    );
  }
}
export default withRouter(About);
