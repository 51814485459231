export const textEnglish = {
	"login": "Login",
	"logout": "Log Out",
	"med": "Reports",
	"add_report": "Add Report",
	"tab_1": "Focus Area 1",
	"tab_2": "Focus Area 2",
	"tab_3": "Focus Area 3",
	"focus_1":"Fiscal Consolidation and Government Effectiveness for Sustainable, Inclusive and Efficient Service Delivery",
	"focus_2":"Private Sector Investment and Productive Growth",
	"focus_3":"Inclusive and Sustainable Development",
	"services": "Knowledge Services",
	"id": "Associated Project ID",
	"add_services": "Add New Knowledge Service",
	"delete_services": "Delete Existing Knowledge Service",
	text_1:`Through research and analysis, we offer support to countries in designing or implementing better policies, strengthening institutions, building capacity, informing strategies or operations, and contributing to the global development agenda. 
	The impactful research is funded via Advisory Services and Analytics (ASA) projects, which are non-lending activities that help external clients or audiences advance a development objective. 
	`,
	text_2:`In Brazil, the World Bank provides ASA to support design or implementation of better policies and inform policy makers. ASA outputs include analytical reports, policy notes, 
	hands-on advice, and knowledge-sharing workshops or training programs. ASA related to private sector development are also prepared jointly between IBRD, IFC and MIGA.`
}

export const textPortuguese = {
	"login": "Login",
	"logout": "Log Out",
	"med": "Relatórios",
	"add_report": "Add Report",
	"tab_1": "Área de Foco 1",
	"tab_2": "Área de Foco 2",
	"tab_3": "Área de Foco 3",
	"focus_1":"Consolidação fiscal e eficácia do governo para prestação de serviços sustentável, inclusiva e eficiente",
	"focus_2":"Investimento do setor privado e crescimento produtivo",
	"focus_3":"Desenvolvimento Sustentável e Inclusivo",
	"services": "Knowledge Services",
	"id": "Associated Project ID",
	"add_services": "Add New Knowledge Service",
	"delete_services": "Delete Existing Knowledge Service",
	text_1:`Por meio de pesquisas e estudos analíticos, oferecemos apoio aos países na formulação ou implementação de políticas públicas, fortalecimento de instituições, capacitação, informação de estratégias ou operações,  com o objetivo de contribuir para a agenda de desenvolvimento global.`,
	text_2:`No Brasil, nossos estudos são financiados por meio de projetos de Serviços de Consultoria e Análise (ASA), que são atividades não reembolsáveis que apoiam clientes ou públicos externos a avançar um objetivo de desenvolvimento. Os resultados dos ASAs incluem relatórios analíticos, notas sobre políticas públicas, consultoria e workshops ou programas de treinamento para compartilhamento de conhecimento. Os ASAs relacionados ao desenvolvimento do setor privado também são preparados em conjunto entre o BIRD, IFC e MIGA.`
}