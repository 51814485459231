import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import LazyLoad from 'react-lazy-load';

import "../story/Story.css";

import WelcomeFooter from "../menu/WelcomeFooter";
import Navbar from "../common/navbar/NavbarPLR";
import StrategyHeader from "../common/strategyheader";
import StrategyCover from "../strategy/img/strategy-cover.png";

const textEnglish = {
  login: "Login",
  logout: "Log Out",
  med: "Telling the Story",
};

const textPortuguese = {
  login: "Login",
  logout: "Log Out",
  med: "Contando a História",
};

class HistoricalEngagement extends Component {
  constructor(props) {
    super(props);

    this.handleLogout = this.handleLogout.bind(this);
    this.state = { currentPage: 0 };
  }

  scrollEvent = (e) => {
    const target = e.target;
    const line = target.scrollHeight / 160;

    if (target.scrollTop > 9050) {
      this.setState({ currentPage: 7 });
    } else if (target.scrollTop > 7000) {
      this.setState({ currentPage: 6 });
    } else if (target.scrollTop > 6000) {
      this.setState({ currentPage: 5 });
    } else if (target.scrollTop > 5300) {
      this.setState({ currentPage: 4 });
    } else if (target.scrollTop > 3200) {
      this.setState({ currentPage: 3 });
    } else if (target.scrollTop > 1400) {
      this.setState({ currentPage: 2 });
    } else if (target.scrollTop > 550) {
      this.setState({ currentPage: 1 });
    } else {
      this.setState({ currentPage: 0 });
    }

    console.log("Current scroll position:", target.scrollTop);
  };

  handleLogout() {
    localStorage.removeItem("auth");
    this.props.history.push("/");
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const pt = this.props.location.pathname.substring(0, 4) == "/pt/";
    let text = {};
    let url =
      "https://geowb.maps.arcgis.com/apps/MapJournal/index.html?appid=7f1a678f17784bd2aeb9ba6de41950e2";
    if (this.props.location.pathname.substring(0, 4) !== "/pt/") {
      text = textEnglish;
    } else {
      text = textPortuguese;
      url =
        "https://geowb.maps.arcgis.com/apps/MapJournal/index.html?appid=bb3371fd321544929d1e67d69cd6e20d";
    }

    return (
      <div>
        <div className="container-fluid unpadify">
          <Navbar
            handleLogin={this.handleLogin}
            handleLogout={this.handleLogout}
            auth={this.props.auth}
            isLoggedIn={this.props.loggedIn}
            checkIsLoggedIn={this.props.checkIsLoggedIn}
            onChangeLoginState={this.props.onChangeLoginState}
            {...this.props}
            currentPage="strategy"
          />
          <StrategyHeader
            title={pt ? "Estratégia" : "Strategy"}
            selectedTab={3}
            tabs={[
              {
                link: pt ? "/pt/story" : "/story",
                label: pt ? "Parceria" : "Partnership Framework",
              },
              {
                link: pt ? "/pt/strategy" : "/strategy",
                label: pt ? "Áreas de foco" : "Focus Areas",
              },
              {
                link: pt ? "/pt/leveraging-finance" : "/leveraging-finance",
                label: pt
                  ? "Alavancando o financiamento"
                  : "Leveraging Finance",
              },
              {
                link: pt ? "/pt/history" : "/history",
                label: pt ? "Contando a história" : "Telling the Story",
              },
            ]}
            backgroundImage={StrategyCover}
            backgroundPosition="0px 69%"
          />
          <div id="story-container">
            <div className="title-header">
              <h2>{pt ? "Contando a história" : "Telling the Story"}</h2>
              <div className="divider"></div>
            </div>
            <div style={{ display: "flex", gap: "50px" }}>
              <div className="left-menu">
                <span className={this.state.currentPage === 0 ? "active" : ""}>
                  <a
                    onClick={() =>
                      document
                        .getElementById("fundacao")
                        .scrollIntoView({ behavior: "smooth" })
                    }
                  >
                    A fundação do Banco Mundial e FMI
                  </a>
                </span>

                <span className={this.state.currentPage === 1 ? "active" : ""}>
                  <a
                    onClick={() =>
                      document
                        .getElementById("brasil-ingressa")
                        .scrollIntoView({ behavior: "smooth" })
                    }
                  >
                    Brasil ingressa no Banco Mundial
                  </a>
                </span>

                <span className={this.state.currentPage === 2 ? "active" : ""}>
                  <a
                    onClick={() =>
                      document
                        .getElementById("primeiro-projeto")
                        .scrollIntoView({ behavior: "smooth" })
                    }
                  >
                    O Primeiro Projeto do Banco Mundial no Brasil
                  </a>
                </span>

                <span className={this.state.currentPage === 3 ? "active" : ""}>
                  <a
                    onClick={() =>
                      document
                        .getElementById("reconstrucao-ao-desenvolvimento")
                        .scrollIntoView({ behavior: "smooth" })
                    }
                  >
                    Da Reconstrução ao Desenvolvimento
                  </a>
                </span>

                <span className={this.state.currentPage === 4 ? "active" : ""}>
                  <a
                    onClick={() =>
                      document
                        .getElementById("nascimento-ifc")
                        .scrollIntoView({ behavior: "smooth" })
                    }
                  >
                    O Nascimento do IFC
                  </a>
                </span>

                <span className={this.state.currentPage === 5 ? "active" : ""}>
                  <a
                    onClick={() =>
                      document
                        .getElementById("ifc-ajudando-mercados-emergentes")
                        .scrollIntoView({ behavior: "smooth" })
                    }
                  >
                    IFC ajudando mercados emergentes a ter sucesso
                  </a>
                </span>

                <span className={this.state.currentPage === 6 ? "active" : ""}>
                  <a
                    onClick={() =>
                      document
                        .getElementById("diversificando-o-portfolio")
                        .scrollIntoView({ behavior: "smooth" })
                    }
                  >
                    Diversificando o portfólio e apoiando os clientes para
                    cumprir seus objetivos
                  </a>
                </span>

                <span className={this.state.currentPage === 7 ? "active" : ""}>
                  <a
                    onClick={() =>
                      document
                        .getElementById("erradicacao-da-pobreza-extrema")
                        .scrollIntoView({ behavior: "smooth" })
                    }
                  >
                    Erradicação da pobreza extrema e promoção da prosperidade
                    compartilhada
                  </a>
                </span>
              </div>
              <div className="story">
                <div className="story-gradient"></div>
                <div className="story-text" onScroll={this.scrollEvent}>
                  <h3 className="story-title">
                    {pt
                      ? "A História do Grupo Banco Mundial no Brasil"
                      : "The World Bank Group History in Brazil"}
                  </h3>
                  <h3 className="historical-engagement-topic" id="fundacao">
                    {pt
                      ? "A fundação do Banco Mundial e FMI"
                      : "The founding of the World Bank and IMF"}
                  </h3>
                  <span>
                    {pt
                      ? "Em julho de 1944 - um ano antes do fim da Segunda Guerra Mundial - representantes de 44 países se reuniram para a Conferência Monetária e Financeira das Nações Unidas (United Nations Monetary and Financial Conference), que teve como objetivo criar a estrutura para a cooperação e reconstrução  econômica internacional do pós-guerra. Nos últimos dias da conferência, foram redigidos os artigos de acordo do que hoje é o Banco Mundial."
                      : "In July 1944 – one year before the end of World War II – delegates from 44 countries met for the United Nations Monetary and Financial Conference which aimed to create the framework for post-war international economic cooperation and reconstruction. In the last few days of the conference, the articles of agreement of what today is the World Bank were drafted."}
                  </span>
                  <div className='image-container'><img
                    src="https://www.arcgis.com/sharing/rest/content/items/bb3371fd321544929d1e67d69cd6e20d/resources/bretton_woods_created_1__1632256921734__w763.jpg"
                    alt=""
                  />
                  </div>
                  <span className="photo-credit">
                    {pt
                      ? "Foto : Bretton Woods, 1944"
                      : "Photo: Bretton Woods, 1944"}
                  </span>
                  <h3
                    className="historical-engagement-topic"
                    id="brasil-ingressa"
                  >
                    {pt
                      ? "Brasil ingressa no Banco Mundial"
                      : "Brazil joins the World Bank"}
                  </h3>
                  {pt ? (
                    <span>
                      Em 14 de janeiro de 1946, o Brasil passou a fazer parte do
                      que hoje é o Grupo Banco Mundial, apenas um ano após a
                      instituição ter sido formalmente estabelecida. Em 1947,
                      ocorreu a primeira missão técnica ao Brasil e, em 1949, o
                      Brasil teve sua primeira operação de empréstimo aprovada.
                    </span>
                  ) : (
                    <span>
                      In January 14, 1946, Brazil became a member of what is
                      today the World Bank Group, only a year after the
                      institution was formally established. In 1947, the first
                      technical mission to Brazil took place and in 1949, the
                      first lending operation to the Government of Brazil was
                      approved.
                    </span>
                  )}
                  <span>&nbsp;</span>
                  {pt ? (
                    <span>
                      💡
                      <a href="https://documents1.worldbank.org/curated/en/358441468915685474/pdf/634610NEWS0Ban00BOX309620B001-01-50.pdf">
                        Aqui
                      </a>{" "}
                      você pode ler o relato de um funcionário do Banco Mundial
                      sobre sua visita ao estado de Goiás em 1949.
                    </span>
                  ) : (
                    <span>
                      💡
                      <a href="https://documents1.worldbank.org/curated/en/358441468915685474/pdf/634610NEWS0Ban00BOX309620B001-01-50.pdf">
                        Here
                      </a>{" "}
                      you can read a personal account from a visit to the state
                      of Goiás in 1949.
                    </span>
                  )}
                  <div className='image-container'><img
                    src="https://www.arcgis.com/sharing/rest/content/items/bb3371fd321544929d1e67d69cd6e20d/resources/Brazilfeb1948__1632258943366__w604.png"
                    alt=""
                  />
                  </div>
                  <span className="photo-credit">
                    {pt ? "Foto" : "Photo"}: Brazil Country Study, February 1948
                  </span>
                  <h3
                    className="historical-engagement-topic"
                    id="primeiro-projeto"
                  >
                    {pt
                      ? "O Primeiro Projeto do Banco Mundial no Brasil"
                      : "The First World Bank Project in Brazil"}
                  </h3>
                  {pt ? (
                    <span>
                      O primeiro projeto do Banco Mundial no Brasil foi um
                      empréstimo à Brazilian Traction, Light & Power Company
                      Limited, uma holding canadense que operava no Brasil. A
                      empresa produzia e distribuía mais de 60% da energia total
                      disponível e fornecia cerca de 75% dos serviços
                      telefônicos no país.{" "}
                      <a href="http://documents1.worldbank.org/curated/en/163181468228903474/pdf/Loan-0011-Brazil-Brazilian-Traction-Light-And-Power-Company-Ltd-Loan-Agreement-1.pdf">
                        O contrato de empréstimo
                      </a>{" "}
                      foi assinado em janeiro de 1949, no valor de US $ 75
                      milhões (a preços correntes). O projeto teve como foco
                      principal o aumento da capacidade das usinas de energia
                      elétrica e da rede telefônica existentes no país.
                    </span>
                  ) : (
                    <span>
                      The World Bank’s first project in Brazil was a loan to the
                      Brazilian Traction, Light & Power Company Limited, a
                      Canadian holding corporation that operated in Brazil. The
                      company produced and distributed over 60% of the total
                      power available and supplied about 75% of the telephone
                      services.
                      <a href="http://documents1.worldbank.org/curated/en/163181468228903474/pdf/Loan-0011-Brazil-Brazilian-Traction-Light-And-Power-Company-Ltd-Loan-Agreement-1.pdf">
                        The loan agreement
                      </a>{" "}
                      was signed in January 1949, worth USD 75 million dollars
                      (in current prices). The project mainly focused on
                      increasing the capacity of existing electric power plants
                      and telephone network in the country.
                    </span>
                  )}
                  <div className='image-container'><img
                    src="https://www.arcgis.com/sharing/rest/content/items/bb3371fd321544929d1e67d69cd6e20d/resources/LightPower__1632259239771__w855.png"
                    alt=""
                  />
                  </div>
                  <span className="photo-credit">
                    {pt ? "Foto" : "Photo"}: Brazilian Traction Light And Power
                    Company Ltd – Staff Appraisal Report, 1948
                  </span>
                  <span>&nbsp;</span>
                  {pt ? (
                    <span>
                      A escala do projeto foi significativa. Na época, foi a
                      terceira maior operação realizada pelo Banco Mundial. As
                      duas únicas operações maiores foram os empréstimos à
                      França (US $ 250 milhões) e à Holanda (US $ 195 milhões),
                      ambos projetos de reconstrução do pós-guerra.
                    </span>
                  ) : (
                    <span>
                      The project scale was significant. At the time, it was the
                      third largest operation that the World Bank engaged in.
                      The only two other operations that were larger were the
                      loans to France (USD 250 million) and to the Netherlands
                      (USD 195 million), which were both post-war reconstruction
                      projects.
                    </span>
                  )}
                  <span>&nbsp;</span>
                  {pt && (
                    <span>
                      A usina Cubatão nas montanhas ao leste de São Paulo, no
                      Brasil, era uma das cinco estações geradoras de energia da
                      Brazilian Traction Light And Power Company Ltd.
                    </span>
                  )}
                  <div className='image-container'><img
                    src="https://www.arcgis.com/sharing/rest/content/items/bb3371fd321544929d1e67d69cd6e20d/resources/Cubataopower__1632259377138__w686.jpg"
                    alt=""
                  />
                  </div>
                  {pt ? (
                    <span className="photo-credit">
                      Foto : World Bank Archives
                    </span>
                  ) : (
                    <span className="photo-credit">
                      Photo: The Cubatao Power plant in the mountains east of
                      Sao Paulo in Brazil is one of five Brazilian Traction
                      generating stations. World Bank Archives
                    </span>
                  )}

                  <h3
                    className="historical-engagement-topic"
                    id="reconstrucao-ao-desenvolvimento"
                  >
                    {pt
                      ? "Da Reconstrução ao Desenvolvimento"
                      : "From Reconstruction to Development"}
                  </h3>
                  {pt ? (
                    <span>
                      Embora inicialmente os empréstimos do Banco Mundial
                      ajudassem a reconstruir países devastados pela Segunda
                      Guerra, com o passar do tempo o foco mudou da reconstrução
                      para o desenvolvimento, com grande ênfase em
                      infraestrutura, como represas, redes elétricas, sistemas
                      de irrigação e estradas. Nos primeiros anos, o Banco
                      apoiou a implementação de vários projetos de
                      infraestrutura no Brasil.
                    </span>
                  ) : (
                    <span>
                      While initially World Bank loans helped rebuild countries
                      devastated by World War II, overtime the focus shifted
                      from reconstruction to development, with a heavy emphasis
                      on infrastructure such as dams, electrical grids,
                      irrigation systems, and roads. In the early years, the
                      Bank supported the implementation of various
                      infra-structure projects in Brazil.
                    </span>
                  )}
                  <div className='image-container'><img
                    src="https://www.arcgis.com/sharing/rest/content/items/bb3371fd321544929d1e67d69cd6e20d/resources/paper__1632259511233__w829.png"
                    alt=""
                  />
                  </div>
                  <span>&nbsp;</span>
                  {pt ? (
                    <span>
                      <a href="https://projects.worldbank.org/pt/projects-operations/project-detail/P006225">
                        O Projeto Hidrelétrico de Furnas
                      </a>
                      , aprovado pela Board em 1958 (US $ 73 milhões) foi o
                      maior projeto hidrelétrico até então realizado na América
                      Latina. Outros projetos do período incluem:{" "}
                      <a href="https://projects.worldbank.org/en/projects-operations/project-detail/P006221">
                        Projeto Hidroelétrico Salto Grande
                      </a>
                      , aprovado pelo Conselho em 1953 (US $ 10 milhões) e o{" "}
                      <a href="https://projects.worldbank.org/en/projects-operations/project-detail/P006222">
                        Piratininga Electric Power Project
                      </a>
                      , aprovado pelo Board em 1954 (US $ 18,8)
                    </span>
                  ) : (
                    <span>
                      <a href="https://projects.worldbank.org/pt/projects-operations/project-detail/P006225">
                        The Furnas Hydroelectric Project
                      </a>
                      , approved by the board in 1958 (USD 73 million) was the
                      largest hydroelectric project ever undertaken in Latin
                      America. Other projects from the period include:{" "}
                      <a href="https://projects.worldbank.org/en/projects-operations/project-detail/P006221">
                        Salto Grande Hydroeletric Project
                      </a>
                      , approved by the Board in 1953 (USD 10 million) and the{" "}
                      <a href="https://projects.worldbank.org/en/projects-operations/project-detail/P006222">
                        Piratininga Electric Power Project
                      </a>
                      , approved by the Board in 1954 (USD 18.8)
                    </span>
                  )}
                  <div className='image-container'><img
                    src="https://www.arcgis.com/sharing/rest/content/items/bb3371fd321544929d1e67d69cd6e20d/resources/furnas__1632259564068__w736.jpg"
                    alt=""
                  />
                  </div>
                  <span className="photo-credit">
                    Photo: Furnas Aerial view, World Bank Archives
                  </span>
                  <h3
                    className="historical-engagement-topic"
                    id="nascimento-ifc"
                  >
                    {pt ? "O Nascimento do IFC" : "The birth of IFC"}
                  </h3>
                  {pt ? (
                    <span>
                      Em 1957, apenas um ano depois de ser formalmente
                      estabelecido, o primeiro investimento do IFC foi no
                      Brasil: um empréstimo de US $ 2 milhões para a Siemens do
                      Brasil Companhia de Eletricidade.
                    </span>
                  ) : (
                    <span>
                      In 1957, only a year after being formally established,
                      IFC’s first investment worldwide took place in Brazil: a
                      USD 2 million loan to Siemens do Brasil Companhia de
                      Eletricidade.
                    </span>
                  )}
                  <span>&nbsp;</span>
                  {pt ? (
                    <span>
                      O investimento do IFC, junto com o equivalente de US $ 8,5
                      milhões sendo investido pela Siemens da Alemanha, foi
                      usado para expandir a fabricação de equipamentos de
                      geração de eletricidade, acessórios para motores de grande
                      porte para utilidades e aplicações industriais, entre
                      outros.
                    </span>
                  ) : (
                    <span>
                      IFC’s investment, together with the equivalent of USD 8.5
                      million being invested by Siemens of Germany, was used to
                      expand the manufacture of electricity generating
                      equipment, large motors accessories for utility and
                      industrial application among others.
                    </span>
                  )}

                  <div className='image-container'><img
                    src="https://www.arcgis.com/sharing/rest/content/items/bb3371fd321544929d1e67d69cd6e20d/resources/siemens__1632259805248__w761.jpg"
                    alt=""
                  />
                  </div>
                  {pt ? (
                    <span className="photo-credit">
                      Foto: Siemens Factory in Brazil, World Bank Archives
                    </span>
                  ) : (
                    <span className="photo-credit">
                      Photo: Siemens Factory in Brazil, World Bank Archives{" "}
                    </span>
                  )}
                  <h3
                    className="historical-engagement-topic"
                    id="ifc-ajudando-mercados-emergentes"
                  >
                    {pt
                      ? "IFC ajudando mercados emergentes a ter sucesso"
                      : "IFC helping Emerging Markets to Succeed"}
                  </h3>
                  <span>
                    {pt
                      ? "Em 1972, a IFC lançou seus serviços de consultoria para o Brasil."
                      : "In 1972, IFC launched its advisory services for Brazil."}
                  </span>
                  <span>&nbsp;</span>
                  {pt ? (
                    <span>
                      Em 1980, a IFC começou a monitorar e analisar dados de
                      retorno financeiro de diversas companhias para 10 mercados
                      nacionais (incluindo o Brasil) o que levou a IFC a cunhar
                      o termo "mercados emergentes", mudando a percepção do
                      mundo financeiro sobre os países em desenvolvimento e
                      definindo uma nova classe de ativos.
                    </span>
                  ) : (
                    <span>
                      In 1980, IFC began tracking total return data since 1976
                      for 10 markets (including Brazil) and in the early 80’s,
                      IFC coined the term “emerging markets”, changing the
                      financial world’s perception of developing countries and
                      defining a new asset class.
                    </span>
                  )}
                  <span>&nbsp;</span>
                  {pt ? (
                    <span>
                      Esse esforço catalítico ajudou muito a amadurecer os
                      mercados de capital locais, permitindo-lhes fazer mais do
                      que nunca para financiar a criação de empregos, criar
                      mercados e reduzir a pobreza em todo o mundo.
                    </span>
                  ) : (
                    <span>
                      This catalytic effort did much to help local capital
                      markets mature, allowing them to do more than ever before
                      to finance job creation, create markets, and reduce
                      poverty around the world.
                    </span>
                  )}
                  <div className='image-container'><img
                    src="https://www.arcgis.com/sharing/rest/content/items/bb3371fd321544929d1e67d69cd6e20d/resources/map_emerging_markets__1632260117832__w912.png"
                    alt=""
                  />
                  </div>
                  <span>
                    <a href="https://documents1.worldbank.org/curated/en/716501566409192581/pdf/Emerging-Stock-Markets-Factbook-1986.pdf">
                      {pt
                        ? "Foto: Mercados de Capital Emergentes Factbook, 1986"
                        : "Emerging Stock Markets Factbook, 1986"}
                    </a>
                  </span>
                  <h3
                    className="historical-engagement-topic"
                    id="diversificando-o-portfolio"
                  >
                    Diversificando o portfólio e apoiando os clientes para
                    cumprir seus objetivos
                  </h3>
                  {pt ? (
                    <span>
                      Entre as décadas de 1960 e 90, o Banco Mundial
                      diversificou progressivamente seus empréstimos em vários
                      setores. Além de apoiar projetos de infraestrutura, os
                      empréstimos do Banco Mundial também financiaram a{" "}
                      <a href="https://projects.worldbank.org/en/projects-operations/project-detail/P006243">
                        construção de escolas
                      </a>
                      , o aumento da cobertura,
                      <a href="https://projects.worldbank.org/en/projects-operations/project-detail/P006305">
                        qualidade e eficiência dos serviços básicos educação,{" "}
                      </a>
                      <a href="https://projects.worldbank.org/en/projects-operations/project%20-detail/P006322?lang=en">
                        melhoria da infraestrutura e serviços básicos de saúde
                      </a>{" "}
                      e uma série de projetos de desenvolvimento rural (
                      <a href="http://projects.worldbank.org/P006268/null?lang=en">
                        Rio Grande do Norte
                      </a>
                      ,{" "}
                      <a href="http://projects.worldbank.org/P006286/null?lang=en">
                        Ceará
                      </a>
                      ,
                      <a href="http://projects.worldbank.org/P006287/null?lang=en">
                        Bahia
                      </a>
                      ,{" "}
                      <a href="http://projects.worldbank.org/P006278/null?lang=en">
                        Minas Gerais
                      </a>{" "}
                      e outros).
                    </span>
                  ) : (
                    <span>
                      Between the 1960’s and the 90’s the World Bank
                      progressively diversified its lending across various
                      sectors. In addition to support infrastructure projects,
                      World Bank lending also financed the{" "}
                      <a href="https://projects.worldbank.org/en/projects-operations/project-detail/P006243">
                        construction of schools
                      </a>
                      , the increase in coverage,
                      <a href="https://projects.worldbank.org/en/projects-operations/project-detail/P006305">
                        quality and efficiency of basic education,{" "}
                      </a>
                      <a href="https://projects.worldbank.org/en/projects-operations/project%20-detail/P006322?lang=en">
                        {" "}
                        improvement of basic health infrastructure and services
                      </a>{" "}
                      and a series of rural development projects ( (
                      <a href="http://projects.worldbank.org/P006268/null?lang=en">
                        Rio Grande do Norte
                      </a>
                      ,{" "}
                      <a href="http://projects.worldbank.org/P006286/null?lang=en">
                        Ceará
                      </a>
                      ,
                      <a href="http://projects.worldbank.org/P006287/null?lang=en">
                        Bahia
                      </a>
                      ,{" "}
                      <a href="http://projects.worldbank.org/P006278/null?lang=en">
                        Minas Gerais
                      </a>{" "}
                      and others).
                    </span>
                  )}
                  <div className='image-container'><img
                    src="https://www.arcgis.com/sharing/rest/content/items/bb3371fd321544929d1e67d69cd6e20d/resources/hay__1632263373577__w849.jpg"
                    alt=""
                  />
                  </div>
                  <span className="photo-credit">
                    {pt
                      ? "Foto: Colheita de Arroz no Brasil, Piauí Rural Development Project - World Bank Archives"
                      : "Photo: Harvest Ricing in Brazil, Piauí Rural Development Project - World Bank Archives"}
                  </span>
                  <span>&nbsp;</span>
                  {pt ? (
                    <span>
                      Do início de 1990 até o início de 2000, o Banco Mundial
                      apoiou operações importantes junto ao governo Brasileiro,
                      que contribuíram para a implementação de reformas
                      institucionais e programas sociais que se tornaram exemplo
                      para outros países.
                    </span>
                  ) : (
                    <span>
                      From early 1990’s until early 2000’s, the World Bank
                      shifted its strategic emphasis and accordingly engaged in
                      important operations with the Government of Brazil, which
                      supported the implementation of key institutional reforms
                      and social programs.
                    </span>
                  )}
                  <div className='image-container'><img
                    src="https://www.arcgis.com/sharing/rest/content/items/bb3371fd321544929d1e67d69cd6e20d/resources/1810588_001__1632263839885__w1920.jpeg"
                    alt=""
                  />
                  </div>
                  <span className="photo-credit">
                    {!pt &&
                      "Photo: Annual Meetings, Rio de Janeiro, Brazil - World Bank Archives"}
                  </span>

                  <span>&nbsp;</span>
                  {pt ? (
                    <span>
                      Em 2004, o Banco apoiou a{" "}
                      <a href="http://projects.worldbank.org/P087713/br-bolsa-familia-1st-apl?lang=en">
                        implementação do Bolsa Família
                      </a>{" "}
                      através do First Adaptable Loan (US $ 572,2 milhões). A
                      operação apoiou a consolidação de programas de
                      transferência de renda e a redução de lacunas e
                      duplicações de cobertura, o fortalecimento do sistema de
                      identificação da população-alvo, o desenvolvimento de um
                      sistema de monitoramento e avaliação do Bolsa Família e o
                      fortalecimento do funcionamento institucional básico do
                      programa.
                    </span>
                  ) : (
                    <span>
                      Among projects of this phase, the Bank financed a{" "}
                      <a href="http://projects.worldbank.org/P060575/programatic-fiscal-reform?lang=en">
                        programmatic fiscal reform package
                      </a>{" "}
                      (USD 757.58) in 2001.
                      <br />
                      <br />
                      The programmatic fiscal reform package was disbursed
                      against prior policy actions, such as the Fiscal
                      Sustainability Law, the fiscal Crimes law and the
                      introduction of the multi-annual budgeting framework (the
                      PPA).
                      <br /> <br />
                      The loan also supported the revamped version of SIAFI
                      (Brazil’s Integrated Fiscal Management System).
                      <br />
                      <br />
                      The{" "}
                      <a href="http://projects.worldbank.org/P087713/br-bolsa-familia-1st-apl?lang=en">
                        Bolsa Família 1st Adaptable Program Loan
                      </a>{" "}
                      (USD 572.2 million) in 2004, supported the consolidation
                      of conditional cash transfer programs and reductions in
                      gaps and duplications in coverage, the strengthening of
                      the system for identifying the target population, the
                      development of a monitoring and evaluation system for the
                      Bolsa Família and the strengthening the basic
                      institutional functioning of the program.
                    </span>
                  )}
                  <div className='image-container'><img
                    src="https://www.arcgis.com/sharing/rest/content/items/bb3371fd321544929d1e67d69cd6e20d/resources/bolsa%20familia__1632264009301__w1170.jpg"
                    alt=""
                  />
                  </div>
                  <h3
                    className="historical-engagement-topic"
                    id="erradicacao-da-pobreza-extrema"
                  >
                    {pt
                      ? "Erradicação da pobreza extrema e promoção da prosperidade compartilhada"
                      : "Eradicating Extreme Poverty and Promoting Shared Prosperity"}
                  </h3>
                  {pt ? (
                    <span>
                      No início dos anos 2000, o Grupo Banco Mundial (WBG)
                      desenvolveu uma nova Estratégia com foco nas metas
                      ambiciosas de erradicar a pobreza extrema e promover a
                      prosperidade compartilhada. Também reafirmou seu
                      compromisso em ajudar os países a alcançar essas metas de
                      maneira sustentável.
                    </span>
                  ) : (
                    <span>
                      In the early 2000s, the World Bank Group (WBG) developed a
                      new Strategy focusing on the ambitious goals of ending
                      extreme poverty and promoting shared prosperity. It also
                      re-affirmed its committed to helping countries reach these
                      goals in a sustainable manner.
                    </span>
                  )}
                  <div className='image-container'><img
                    src="https://www.arcgis.com/sharing/rest/content/items/bb3371fd321544929d1e67d69cd6e20d/resources/end%20poverty__1632407777709__w1073.png"
                    alt=""
                  />
                  </div>
                  <span></span>
                  <span>&nbsp;</span>
                  {pt ? (
                    <span>
                      O modelo para implementar a Estratégia tem três elementos
                      principais:
                      <ul>
                        <li>
                          <a href="https://documents1.worldbank.org/curated/en/180351467995438283/pdf/101431-REVISED-SCD-Brazil-SCD-Final-version-May-6-2016.pdf">
                            Diagnóstico Sistemático para o País
                          </a>
                        </li>
                        <li>
                          <a href="https://documents1.worldbank.org/curated/en/148141498229092629/pdf/20170619-Brazil-CPF-draft-for-Board-with-CLR-Acknowledgement-Box-06202017.pdf">
                            Estratégia de Parceria para o País
                          </a>
                        </li>
                        <li>Revisão e Aperfeiçoamento da Estratégia</li>
                      </ul>
                    </span>
                  ) : (
                    <span>
                      The model to implement the Strategy has three main
                      elements:
                      <ul>
                        <li>
                          <a href="https://documents1.worldbank.org/curated/en/180351467995438283/pdf/101431-REVISED-SCD-Brazil-SCD-Final-version-May-6-2016.pdf">
                            A Systematic Country Diagnosis
                          </a>
                        </li>
                        <li>
                          <a href="https://documents1.worldbank.org/curated/en/148141498229092629/pdf/20170619-Brazil-CPF-draft-for-Board-with-CLR-Acknowledgement-Box-06202017.pdf">
                            A Country Partnership Framework
                          </a>
                        </li>
                        <li>Performance and Learning Review</li>
                      </ul>
                    </span>
                  )}
                  <div className='image-container'><img
                    alt=""
                    src="https://www.arcgis.com/sharing/rest/content/items/bb3371fd321544929d1e67d69cd6e20d/resources/infographic__1632265489600__w300.png"
                  />
                  </div>
                  <span>&nbsp;</span>
                  {pt ? (
                    <span>
                      Em 2013, o IFC trabalhou com o Itaú Unibanco para ajudar a
                      expandir seu Programa de Mulheres Empreendedoras, um
                      programa único que apoia o acesso a financiamento para
                      mulheres empresárias no Brasil.
                    </span>
                  ) : (
                    <span>
                      In 2013, IFC worked with Itaú Unibanco to help expand its
                      Programa de Mulheres Empreendedoras, a unique program that
                      supports access to finance for women entrepreneurs in
                      Brazil.
                    </span>
                  )}

                  <span>&nbsp;</span>
                  {pt ? (
                    <span>
                      O programa de consultoria técnica do IFC com o Itaú
                      Unibanco foi seguido por um investimento de US $ 470
                      milhões (ou quase R $ 1 bilhão) feito para ajudar o banco
                      a expandir seu programa de pequenas e médias empresas
                      (PMEs) de propriedade de mulheres. Esse foi o primeiro
                      investimento da IFC no Programa Banking on Women na
                      América Latina e o maior em todo o mundo.
                    </span>
                  ) : (
                    <span>
                      IFC’s advisory program with Itaú Unibanco followed a $470
                      million (or almost BRL 1 billion) investment made to help
                      the bank expand its women-owned small and medium
                      enterprises (SME) program. That was IFC’s first investment
                      in the Banking on Women Program in Latin America and the
                      largest worldwide.
                    </span>
                  )}

                  <div className='image-container'><img
                    src="https://www.arcgis.com/sharing/rest/content/items/bb3371fd321544929d1e67d69cd6e20d/resources/womentotebag__1632265603343__w1280.jpg"
                    alt=""
                  />
                  </div>
                  <span className="photo-credit">
                    {pt ? "Foto por" : "Photo by"}: Elaine Coutrin
                  </span>
                  <span>&nbsp;</span>
                  {pt ? (
                    <span>
                      Desde 2007, o Banco Mundial tem estreitado sua parceria
                      com municípios a fim de apoiar os governos locais no
                      atendimento às crescentes demandas de suas populações. As
                      principais áreas de apoio incluem a melhoria do acesso ao
                      saneamento básico e transporte público, bem como o aumento
                      da qualidade da prestação de serviços públicos em saúde e
                      educação (empréstimos para governos municipais incluem{" "}
                      <a href="https://projects.worldbank.org/en/projects-opera%C3%A7%C3%B5es/detalhedoprojeto/P089011">
                        Uberaba
                      </a>
                      ,
                      <a href="https://projects.worldbank.org/en/projects-operations/project-detail/P094315">
                        {" "}
                        São Luís do Maranhão{" "}
                      </a>
                      (
                      <a href="https://www.worldbank.org/pt/news/feature/2013/06/06/Brasil-S%C3%A3o-Luis-%C3%A1gua-saneamento-qualidade-vida-bairros-pobres">
                        Em São Luís, rede de água e obras contra inundações
                        levarão qualidade de vida a 45 bairros pobres
                      </a>
                      )
                      <a href="https://projects.worldbank.org/en/projects-operations/project-detail/P088966">
                        , Teresina
                      </a>
                      ,{" "}
                      <a href="https://projects.worldbank.org/en/projects-operations/project-detail/P104995">
                        Santos
                      </a>
                      ,
                      <a href="https://projects.worldbank.org/en/projects-operations/project-detail/P126749">
                        {" "}
                        Belo Horizonte
                      </a>
                      ,{" "}
                      <a href="https://projects.worldbank.org/en/projects-operations/project-detail/P153012">
                        Fortaleza
                      </a>
                      ,
                      <a href="https://projects.worldbank.org/en/projects-operations/project-detail/P153203">
                        {" "}
                        Manaus
                      </a>
                      ,{" "}
                      <a href="https://geowb.maps.arcgis.com/apps/MapJournal/index.html?appid=bb3371fd321544929d1e67d69cd6e20d">
                        Salvador
                      </a>{" "}
                      e muitos outros.
                    </span>
                  ) : (
                    <span>
                      Since 2007, the World Bank has been engaging with
                      municipalities in order to support local governments in
                      meeting the evolving needs of their populations.
                      <br />
                      Key areas of support have included improving access to
                      basic sanitation and public transit as well as increasing
                      the quality of public service delivery in health and
                      education (lending to municipal governments include
                      <a href="https://projects.worldbank.org/en/projects-opera%C3%A7%C3%B5es/detalhedoprojeto/P089011">
                        Uberaba
                      </a>
                      ,
                      <a href="https://projects.worldbank.org/en/projects-operations/project-detail/P094315">
                        {" "}
                        São Luís do Maranhão{" "}
                      </a>
                      (
                      <a href="https://www.worldbank.org/pt/news/feature/2013/06/06/Brasil-S%C3%A3o-Luis-%C3%A1gua-saneamento-qualidade-vida-bairros-pobres">
                        Em São Luís, rede de água e obras contra inundações
                        levarão qualidade de vida a 45 bairros pobres
                      </a>
                      )
                      <a href="https://projects.worldbank.org/en/projects-operations/project-detail/P088966">
                        , Teresina
                      </a>
                      ,{" "}
                      <a href="https://projects.worldbank.org/en/projects-operations/project-detail/P104995">
                        Santos
                      </a>
                      ,
                      <a href="https://projects.worldbank.org/en/projects-operations/project-detail/P126749">
                        {" "}
                        Belo Horizonte
                      </a>
                      ,{" "}
                      <a href="https://projects.worldbank.org/en/projects-operations/project-detail/P153012">
                        Fortaleza
                      </a>
                      ,
                      <a href="https://projects.worldbank.org/en/projects-operations/project-detail/P153203">
                        {" "}
                        Manaus
                      </a>
                      ,{" "}
                      <a href="https://geowb.maps.arcgis.com/apps/MapJournal/index.html?appid=bb3371fd321544929d1e67d69cd6e20d">
                        Salvador
                      </a>{" "}
                      and many others.
                    </span>
                  )}
                  <div className='image-container'><img
                    src="https://www.arcgis.com/sharing/rest/content/items/bb3371fd321544929d1e67d69cd6e20d/resources/controladores__1632265681380__w985.jpg"
                    alt=""
                  />
                  </div>
                  <span className="photo-credit">
                    Foto: controladores monitoram a segurança e os padrões de
                    tráfego dos veículos em projeto financiado pelo Banco
                    Mundial. Mariana Kaipper Ceratti/Banco Mundial.
                  </span>
                  <span>&nbsp;</span>
                  {pt ? (
                    <span>
                      Desde seu primeiro acordo de empréstimo até março de 2021,
                      o Banco Mundial contabilizava operações em 24 dos 27
                      estados do país (Mato Grosso do Sul, Roraima e Rondônia
                      são os únicos estados que ainda não receberam
                      financiamento do Banco Mundial).
                    </span>
                  ) : (
                    <span>
                      As of March 2021, the World Bank has had operations in 24
                      out of the 27 states in the country (Mato Grosso do Sul,
                      Roraima and Rondônia are the only states that have not yet
                      received World Bank financing).
                    </span>
                  )}
                  <div className='image-container'><LazyLoad height={550} offset={300}>
                    <iframe
                    width='900px'
                    height={'550px'}
                    className="iframe"
                    src="https://flo.uri.sh/visualisation/5625096/embed"
                  ></iframe>
                  </LazyLoad>
                  </div>
                  <span>&nbsp;</span>
                  {pt ? (
                    <span>
                      Em 2009, o Board aprovou o{" "}
                      <a href="http://projects.worldbank.org/P095205/first-programmatic-development-policy-loan-sustainable-environmental-management?lang=pt">
                        Primeiro Empréstimo para Política de Desenvolvimento de
                        Gestão Ambiental Sustentável Programática
                      </a>
                      (SEM DPL), com um envelope de US $ 1,3 bilhão.
                    </span>
                  ) : (
                    <span>
                      In 2009, the board approved the
                      <a href="http://projects.worldbank.org/P095205/first-programmatic-development-policy-loan-sustainable-environmental-management?lang=pt">
                        First Programmatic Sustainable Environmental Management
                        Development Policy Loan
                      </a>
                      (SEM DPL), with an envelope of USD 1.3 billion.
                    </span>
                  )}
                  <div className='image-container'><img
                  style={{width: 670, height: 400}}
                    src="https://www.arcgis.com/sharing/rest/content/items/bb3371fd321544929d1e67d69cd6e20d/resources/amazon__1632268622969__w337.jpg"
                    alt=""
                  />
                  </div>
                  <span>&nbsp;</span>
                  {pt ? (
                    <span>
                      O empréstimo apoiou o Governo do Brasil na melhoria da
                      sustentabilidade da gestão dos recursos naturais, por meio
                      do fortalecimento da estrutura legal florestal, apoio à
                      melhoria do planejamento regional da Amazônia para o
                      desenvolvimento sustentável e redução do desmatamento, por
                      meio da aprovação do Programa Nacional da Amazônia
                      Sustentável (PAS), e conclusão do Zoneamento Ecológico
                      Econômico da Amazônia.
                    </span>
                  ) : (
                    <span>
                      The loan supported the Government of Brazil in improving
                      the sustainability of natural resource management, through
                      strengthening of the forest legal framework, support for
                      improving Amazon regional planning for sustainable
                      development and reduced deforestation, through approval of
                      the National Sustainable Amazon Program (PAS), and
                      completion of the Ecological Economic Zoning of the Amazon
                      Region.
                    </span>
                  )}
                  <span>&nbsp;</span>
                  {pt ? (
                    <span>
                      Desde 2010, a IFC assessorou diversos estados e empresas
                      estatais em vários projetos de PPP, incluindo o Estado da
                      Bahia na primeira PPP de saúde no Brasil e também na
                      primeira PPP para reabilitação e manutenção de estradas do
                      país. A IFC também apoiou tecnicamente a concessão de
                      novas estradas do Estado de São Paulo em 2017, que atraiu
                      investidores internacionais e novos tipos de players para
                      o país.
                    </span>
                  ) : (
                    <span>
                      Since 2010, IFC has advised various states and SOEs on
                      several PPP projects, including Bahia State on the first
                      health PPP in Brazil, the Sao Paulo State New Road
                      concession in 2017, which attracted international
                      investors and new type of players to the country, and
                      Bahia state roads in 2018, the first PPP for
                      rehabilitation and maintenance of roads in Brazil.
                    </span>
                  )}
                  <div className='image-container'><img
                    src="https://www.arcgis.com/sharing/rest/content/items/bb3371fd321544929d1e67d69cd6e20d/resources/9444991611_306a8fdd5e_k__1632269118665__w1920.jpg"
                    alt=""
                  />
                  </div>
                  <span className="photo-credit">
                    {pt ? "Foto" : "Photo"} : Hospital do Subúrbio. Mariana
                    Ceratti/World Bank
                  </span>
                  <span>&nbsp;</span>
                  {pt ? (
                    <span>
                      Em 2020, o Banco Mundial assinou uma grande operação com o
                      governo do Brasil para fornecer alívio econômico para os
                      segmentos mais pobres da população que foram impactados
                      pela pandemia COVID-19.
                    </span>
                  ) : (
                    <span>
                      In 2020, the World Bank signed a large operation with the
                      government of Brazil to provide economic relief for the
                      poorest segments of population that were impacted by
                      COVID-19 pandemic (to see what the Bank’s response to the
                      pandemic in Brazil, you can go here).
                    </span>
                  )}
                  <div className='image-container'><LazyLoad height={550} offset={300}>
                    <iframe
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/dWDP4iLw3E8"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  ></iframe>
                  </LazyLoad>
                  </div>
                  <span>&nbsp;</span>
                  {pt ? (
                    <span>
                      Durante a pandemia, o IFC concentrou seus esforços em
                      ajudar o setor privado do Brasil a superar os impactos
                      econômicos negativos decorrentes da crise sanitária. Entre
                      as operações estão um investimento na Jalles Machado para
                      intensificar a{" "}
                      <a href="https://disclosures.ifc.org/project-detail/ESRS/44203/rse-covid-jalles">
                        produção de produtos saneantes
                      </a>
                      , investimento no{" "}
                      <a href="https://disclosures.ifc.org/project-detail/SII/42711/wcs-covid-santander-brasil">
                        Santander
                      </a>{" "}
                      e{" "}
                      <a href="https://disclosures.ifc.org/project-detail/SII/43220/wcs-covid-daycoval">
                        Daycoval
                      </a>
                      para apoiar as PMEs, muitas das quais foram impactadas
                      negativamente pela pandemia.
                    </span>
                  ) : (
                    <span>
                      During the pandemic, IFC has focused its efforts on
                      helping Brazil’s private sector mitigate the economic
                      fallout, with an investment in Jalles Machado to step up
                      the
                      <a href="https://disclosures.ifc.org/project-detail/ESRS/44203/rse-covid-jalles">
                        production of sanitizing products
                      </a>
                      , investment in
                      <a href="https://disclosures.ifc.org/project-detail/SII/42711/wcs-covid-santander-brasil">
                        Santander
                      </a>{" "}
                      and{" "}
                      <a href="https://disclosures.ifc.org/project-detail/SII/43220/wcs-covid-daycoval">
                        Daycoval
                      </a>
                      to support SMEs, most of which were negatively impacted by
                      the pandemic.
                    </span>
                  )}
                  <div className='image-container'><img
                    src="https://www.arcgis.com/sharing/rest/content/items/bb3371fd321544929d1e67d69cd6e20d/resources/sewingmachine__1632269228343__w929.jpg"
                    alt=""
                  />
                  </div>
                  <span className="photo-credit">
                    {pt ? "Foto" : "Photo"}: Santander
                  </span>
                  <span>&nbsp;</span>
                  {pt ? (
                    <span>
                      Nos últimos anos, o IFC também tem trabalhado para tornar
                      a sustentabilidade um motivador de negócios no Brasil. O
                      green loan do IFC ao Sicredi em 2021 representa a primeira
                      transação de uma instituição financeira cooperativa no
                      Brasil a receber a certificação emitida pela Climate Bonds
                      Initiative (CBI), com foco em projetos de energia
                      fotovoltaica (PV) no Brasil.
                    </span>
                  ) : (
                    <span>
                      In the last few years, IFC has also been working to make
                      sustainability a business driver in Brazil. IFC’s green
                      loan to Sicredi in 2021 represents the first transaction
                      of a Brazilian cooperative financial institution to
                      receive the certification issued by the Climate Bonds
                      Initiative (CBI), with a focus on photovoltaic (PV) energy
                      projects in Brazil.
                    </span>
                  )}
                  <span>&nbsp;</span>
                  {pt ? (
                    <span>
                      No mesmo ano, o IFC firmou parceria com o Itaú BBA para
                      estimular e desenvolver oportunidades de construção verde
                      no país, com treinamento e orientação para a Certificação
                      EDGE. Outros projetos incluem um pacote de empréstimo de
                      US $ 100 milhões à Desenvolve SP para limpar o rio
                      Pinheiros e um acordo para ajudar a Corsan (Companhia
                      Riograndense de Saneamento) a reduzir as perdas de água.
                    </span>
                  ) : (
                    <span>
                      In the same year, IFC partnered with Itaú BBA to stimulate
                      and develop green construction opportunities in the
                      country, with training and guidance for the EDGE
                      Certification. Other projects include a USD 100 million
                      loan package to Desenvolve SP to clean up the Pinheiros
                      river and an agreement to help Corsan (Companhia
                      Riograndense de Saneamento) to reduce water losses.{" "}
                    </span>
                  )}
                  <div className='image-container'><img
                    src="https://www.arcgis.com/sharing/rest/content/items/bb3371fd321544929d1e67d69cd6e20d/resources/corsan__1632269420692__w851.jpg"
                    alt=""
                  />
                  </div>
                  <span className="photo-credit">
                    {pt ? "Foto" : "Photo"} : Corsan
                  </span>
                  <span>&nbsp;</span>
                  {pt ? (
                    <span>
                      Para monitoramento em tempo real da carteira atual do
                      Grupo Banco Mundial no Brasil, você pode visitar nossa
                      página com informações atualizados sobre nosso portfolio,
                      distribuídas de acordo com as áreas de foco definidas na
                      Estratégia de Parceria para o País.
                    </span>
                  ) : (
                    <span>
                      For real-time monitoring of the current World Bank Group
                      portfolio in Brazil you can visit our portfolio footprint
                      page, which displays the WBG portfolio according to the
                      focus areas laid out in the Country Partnership Framework.
                    </span>
                  )}
                  <div className='image-container'><LazyLoad height={550} offset={300}>
                    <iframe src="https://brasilaberto.worldbank.org/pt/map?layers=fa1,percent_poor"></iframe>
                    </LazyLoad>
                    </div>
                  <span>&nbsp;</span>
                  {pt ? (
                    <span>
                      Convidamos você a explorar a OpenBrazil e entender como os
                      projetos do Grupo Banco Mundial, seu portfólio, pesquisa e
                      policy advocacy estão contribuindo para tornar o Brasil
                      uma sociedade mais próspera, sustentável e justa.
                    </span>
                  ) : (
                    <span>
                      We invite you to explore OpenBrazil and understand how the
                      World Bank Group operations, portfolio footprint, research
                      and policy advocacy are contributing to make Brazil a more
                      prosperous, green and fair society.
                    </span>
                  )}

                  <div className='image-container'><img
                    style={{ maxWidth: "50%" }}
                    src="https://www.arcgis.com/sharing/rest/content/items/bb3371fd321544929d1e67d69cd6e20d/resources/1716767-0018-c__1632339524345__w1920.jpeg"
                    alt=""
                  />
                  </div>
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                </div>
              </div>
            </div>
          </div>

          <WelcomeFooter {...this.props} />
        </div>
      </div>
    );
  }
}
export default withRouter(HistoricalEngagement);
