import React, { useEffect, useState } from 'react'
import './styles.css'
import ProgressBar from '../../components/ProgressBar';
import axios from 'axios';

import ChevronDownIcon from '../../../icons/chevron-down.svg'
import ChevronRightIcon from '../../../icons/chevron-right.svg'

import PaperMoneyTwoWhiteIcon from '../../../icons/paper-money-two-white.svg'
import PureNaturalWhiteIcon from '../../../icons/pure-natural-white.svg'

import PaperMoneyTwoBlueIcon from './img/paper-money-two.svg'
import PureNaturalBlueIcon from './img/pure-natural.svg'
import { Link } from 'react-router-dom'
import { API_URL } from '../../../config/api';

const portugueseText = {
  date_approved: 'Data de aprovação',
  active: 'Ativo',
  inactive: 'Inativo',
  see_project: 'Ver projeto'
}

const englishText = {
  date_approved: 'Date approved',
  active: 'Active',
  inactive: 'Inactive',
  see_project: 'View more'
}

const AltProjectCard = ({ title, description, approvalDate, href, pt, id, status }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isDevelopmentGoalsModalOpen, setIsDevelopmentGoalsModalOpen] = useState(false)
  const [isMoreInfoModalOpen, setIsMoreInfoModalOpen] = useState(false)
  const [projectInfo, setProjectInfo] = useState();
  const [projectInfo2, setProjectInfo2] = useState();
  const text = pt ? portugueseText : englishText

  const formatDate = (x) => {
    return `${x.split('-')[2]}/${x.split('-')[1]}/${x.split('-')[0]}`
  }

  const fetchInfos = async () => {
    await axios
        .post(`${API_URL}/project_details`, { id })
        .then((response) => {
          if (response.data.length > 0) {
            setProjectInfo(response.data[0]);
          }
      })
        .catch((err) => {});
        
    await axios
    .post(`${API_URL}/project_by_id`, { id })
    .then((response) => {
      if (response.data.length > 0) {
        const project = response.data[0];
        project.abstract = project.abstract ? project.abstract.replace(/[{}"\\]/g, '') : 'Dados não disponíveis';
        project.abstract_pt = project.abstract_pt ? project.abstract_pt.replace(/[{}"\\]/g, '') : 'Dados não disponíveis';
        setProjectInfo2(project);
      }
  })
    .catch((err) => {});
  }
  
  useEffect(() => {
    fetchInfos();
  }, []);

  return (
    <div className="lp_alt_project_card">
      <div className="project-container">
        <div
          className="header"
          style={{cursor: 'pointer'}}
          onClick={() => setIsModalOpen(!isModalOpen)}
          >
          <span style={{cursor: 'pointer', fontSize: '28px' }}>{title}</span>
          <img 
            alt="Chevron down"
            src={ChevronDownIcon}
            className={isModalOpen ? 'icon-flipped' : ''}
          />
        </div>
        <span style={{ fontSize: '18px', color: '#262626' }} className="description">{description}</span>
        {isModalOpen && (
          <div className="project-expanded-content">
            <ProgressBar
              title={'Desembolsos'} 
              current={projectInfo.disbursed_amount ?? "0"} //, abbreviateFromMillions(projectInfo.disbursed_amount, 2, 2, false) }
              currentLabel={' desembolsado'}
              total={projectInfo.original_principal_amount ?? "0"}
              totalLabel=" milhões comprometidos"
            />
            <span className="focus-areas">{pt ? 'Área(s) de foco' : 'Focus areas'}</span>
            <span className="focus-area">
              <img src={PaperMoneyTwoBlueIcon} alt="Paper money" /><strong>{pt ? 'Área de foco 1' : 'Focus area 1'}:</strong> {pt ? 'Consolidação fiscal' : 'Fiscal consolidation'}
            </span>
            <span className="focus-area">
              <img src={PureNaturalBlueIcon} alt="Pure natural" /><strong>{pt ? 'Área de foco 2' : 'Focus area 2'}:</strong> {pt ? 'Desenvolvimento sustentável e inclusivo' : 'Sustainable and inclusive development'}
            </span>
            <div className="modal-title" style={{cursor: 'pointer'}}
              onClick={() => setIsDevelopmentGoalsModalOpen(!isDevelopmentGoalsModalOpen)}>
              <img 
                src={ChevronDownIcon}
                alt="Chevron Down"
                className={isDevelopmentGoalsModalOpen ? 'icon-flipped' : ''} 
              />
              <span style={{ fontWeight: '400' }}>{pt ? 'Objetivo de desenvolvimento do programa' : 'Program development objective'}</span>
            </div>
            {isDevelopmentGoalsModalOpen && (
              <div className="project-modal-content">
                <span>{projectInfo2.description_pt || projectInfo2.description}</span>
              </div>
            )}
            <div className="modal-title" style={{cursor: 'pointer'}}
            onClick={() => setIsMoreInfoModalOpen(!isMoreInfoModalOpen)}>
              <img 
                src={ChevronDownIcon}
                alt="Chevron Down"
                className={isMoreInfoModalOpen ? 'icon-flipped' : ''} 
              />
              <span style={{ fontWeight: '400' }}>{pt ? 'Mais informações sobre este projeto' : 'More information about this project'}</span>
            </div>
            {isMoreInfoModalOpen && (
              <div className="project-modal-content">
                <span>{projectInfo2.abstract_pt || projectInfo2.abstract}</span>
              </div>
            )}
          </div>
        )}
      </div>
      <div className="project-footer">
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: '16px', flexGrow: 1 }}>
          <div>
            <img src={PaperMoneyTwoWhiteIcon} alt="Paper Money" />
            <img src={PureNaturalWhiteIcon} alt="Pure Natural" />
          </div>
          <span style={{ fontSize: '18px' }} className="divider">Status: {status === 'Active' ? text.active : text.inactive}</span>
          <span style={{ fontSize: '18px' }} >{text.date_approved}: {approvalDate ? formatDate(approvalDate) : '01/12/2022'}</span>
        </div>
        <Link className="see-project-container" style={{ display: 'flex', alignItems: 'center' }} to={href || ""}>
          <span className="see-project">{text.see_project}</span>
          <img src={ChevronRightIcon} alt="Chevron Right" />
        </Link>
      </div>
    </div>
  )
}

export default AltProjectCard
