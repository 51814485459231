import React, { Component } from 'react';
import * as d3 from "d3";

import './BarGraph.css';

export default class BarGraph extends Component {
	constructor() {
		super();

		this.createChart = this.createChart.bind(this);
		this.updateChart = this.updateChart.bind(this);
	}

	componentDidMount() {
		this.createChart();
		window.addEventListener("resize", this.updateChart);
	}

	componentDidUpdate() {
		this.updateChart();
	}

	componentWillUnmount() {
	    window.removeEventListener("resize", this.updateChart);
	}

	updateChart() {
		d3.select(this.node).selectAll("*").remove();
		this.createChart();
	}

	createChart() {
		const node = this.node;
		const data = this.props.data;
		const yAxesLabel = this.props.unit ? this.props.unit : '';
		const pt = this.props.pt;

		let color = "#002245";

		const margin = {top: 50, right: 125, bottom: 50, left: 100};
		const width = d3.select(node).node().getBoundingClientRect().width - margin.left - margin.right;
		const height = width * 0.45;

		let keys = null;
		let x = null;
		let y = null;
		let y0 = null;
		let y1 = null;
		let targets = null;
		let target_year = this.props.target_year ? this.props.target_year : null;
		let grouped_data = [];
		let ticks = 5;

		if (this.props.type === "grouped") {
			keys = Object.keys(data);
			targets = [this.props.targets[keys[0]].value, this.props.targets[keys[1]].value];
			target_year = this.props.targets[keys[0]].year;
			grouped_data[0] = data[keys[0]];
			grouped_data[1] = data[keys[1]];
			
			x = d3.scaleBand().rangeRound([0, width]).padding(0.1);
			if (this.props.reverse) {
				y = d3.scaleLinear().rangeRound([0, height]);
			} else {
				y = d3.scaleLinear().rangeRound([height, 0]);
			}
			
			x.domain(grouped_data[0].map(val => val.year));

			let maxTarget = Math.max(targets[0], targets[1]);
			const dataMax = Math.max(d3.max(data[keys[0]].map(val => val.value)), d3.max(data[keys[1]].map(val => val.value)));
			const max = (maxTarget > dataMax) ? maxTarget*1.25 : dataMax*1.25
			const dataMin = Math.min(d3.min(data[keys[0]].map(val => val.value)), d3.min(data[keys[1]].map(val => val.value)));
			const min = (0 < dataMin) ? 0 : dataMin*1.25;
			if (max - min < ticks) {
				ticks = max - min > 3 ? max - min : 3;
			}
			y.domain([min, max]);
			
		} else if (this.props.type === "dual_scale") {
			
			keys = Object.keys(data);
			targets = [this.props.targets[keys[0]].value, this.props.targets[keys[1]].value];
			target_year = this.props.targets[keys[0]].year;
			grouped_data[0] = data[keys[0]];
			grouped_data[1] = data[keys[1]];
			
			x = d3.scaleBand().rangeRound([0, width]).padding(0.1);
			if (this.props.reverse) {
				y0 = d3.scaleLinear().rangeRound([0, height]);
				y1 = d3.scaleLinear().rangeRound([0, height]);
			} else {
				y0 = d3.scaleLinear().rangeRound([height, 0]);
				y1 = d3.scaleLinear().rangeRound([height, 0]);
			}
			
			x.domain(grouped_data[0].map(val => val.year));

			const dataMax0 = d3.max(data[keys[0]].map(val => val.value));
			const max0 = (this.props.targets[keys[0]].value > dataMax0) ? this.props.targets[keys[0]].value*1.25 : dataMax0*1.25
			const dataMin0 = d3.min(data[keys[0]].map(val => val.value));
			const min0 = (0 < dataMin0) ? 0 : dataMin0*1.25;
			y0.domain([min0, max0]);

			const dataMax1 = d3.max(data[keys[1]].map(val => val.value));
			const max1 = (this.props.targets[keys[1]].value > dataMax1) ? this.props.targets[keys[1]].value*1.25 : dataMax1*1.25
			const dataMin1 = d3.min(data[keys[1]].map(val => val.value));
			const min1 = (0 < dataMin1) ? 0 : dataMin1*1.25;
			y1.domain([min1, max1]);
			if (max0 - min0 < ticks && max1 - min1 < ticks) {
				if (max0 - min0 > max1 - min1) {
					ticks = max0 - min0 > 3 ? max0 - min0 : 3;
				} else {
					ticks = max1 - min1 > 3 ? max1 - min1 : 3;
				}
			}
		} else { //default
			x = d3.scaleBand().rangeRound([0, width]).padding(0.1);
			if (this.props.reverse) {
				y = d3.scaleLinear().rangeRound([0, height]);
			} else {
				y = d3.scaleLinear().rangeRound([height, 0]);
			}
			
			x.domain(data.map(val => val.year));

			//if the target is > than the max value we need to modify the domain
			if (this.props.target !== undefined && this.props.target !==null) {
				const dataMax = d3.max(data.map(val => val.value));
				const max = (this.props.target > dataMax) ? this.props.target*1.25 : dataMax*1.25
				const dataMin = d3.min(data.map(val => val.value));
				const min = (0 < dataMin) ? 0 : dataMin*1.25;
				if (max - min < ticks) {
					ticks = max - min > 3 ? max - min : 3;
				}
				y.domain([min, max]);
			} else {
				const dataMin = d3.min(data.map(val => val.value));
				const min = (0 < dataMin) ? 0 : dataMin*1.25;
				const max = d3.max(data.map(val => val.value*1.1));
				if (max - min < ticks) {
					ticks = max - min > 3 ? max - min : 3;
				}
				y.domain([min, max]);
			}
		}
		
		let svgHeight = 1;
		let strokeWidth = 4;
		let textClass = "barGraphTargetText7";
		let graphLabelsClass = "barGraph7";
		let lineTranslation = 'translate(' + (12 * 3) + ', 0)';

		if (d3.select(node).node().getBoundingClientRect().width > 340) {
			strokeWidth = 5;
			svgHeight = .78;
			textClass = "barGraphTargetText7";
			graphLabelsClass = "barGraph7";
			lineTranslation = 'translate(' + (18 * 3) + ', 0)';
		}
		if (d3.select(node).node().getBoundingClientRect().width > 380) {
			strokeWidth = 6;
			svgHeight = .74;
			textClass = "barGraphTargetText6";
			graphLabelsClass = "barGraph6";
			lineTranslation = 'translate(' + (22 * 3) + ', 0)';
		}
		if (d3.select(node).node().getBoundingClientRect().width > 450) {
			strokeWidth = 6;
			svgHeight = .7;
			textClass = "barGraphTargetText5";
			graphLabelsClass = "barGraph5";
			lineTranslation = 'translate(' + (25 * 3) + ', 0)';
		}
		if (d3.select(node).node().getBoundingClientRect().width > 540) {
			strokeWidth = 7;
			svgHeight = .65;
			textClass = "barGraphTargetText4";
			graphLabelsClass = "barGraph4";
			lineTranslation = 'translate(' + (29 * 3) + ', 0)';
		}
		if (d3.select(node).node().getBoundingClientRect().width > 590) {
			strokeWidth = 8;
			svgHeight = .62;
			textClass = "barGraphTargetText3";
			graphLabelsClass = "barGraph3";
			lineTranslation = 'translate(' + (32 * 3) + ', 0)';
		} 
		if (d3.select(node).node().getBoundingClientRect().width > 640) {
			strokeWidth = 9;
			svgHeight = .6;
			textClass = "barGraphTargetText2";
			graphLabelsClass = "barGraph2";
			lineTranslation = 'translate(' + (35 * 3) + ', 0)';
		}
		if (d3.select(node).node().getBoundingClientRect().width > 700) {
			strokeWidth = 10;
			svgHeight = .5;
			textClass = "barGraphTargetText";
			graphLabelsClass = "barGraph";
			lineTranslation = 'translate(' + (38 * 3) + ', 0)';
		}
		svgHeight = svgHeight*1.15;
		const svg = d3.select(node).append("svg")
			.attr("width", d3.select(node).node().getBoundingClientRect().width)
            .attr("height", width * svgHeight + margin.bottom)
			.append("g")
			.attr("transform", `translate(${margin.left}, ${margin.top})`)
			.attr("class", graphLabelsClass);

		svg.append("g")
			.attr("transform", `translate(0,${height})`)
			.attr("class", "barGraphAxis")
			.call(d3.axisBottom(x));

		


		if (this.props.type === "dual_scale") {
			svg.append("g")
				.attr("class", "barGraphGrid")
				.attr("transform", "translate(0,0)")
				.call(d3.axisLeft(y0).ticks(ticks).tickSize(-(width-10)).tickFormat(function(d) { let formatValue = d3.format("~s"); return formatValue(d).replace('G', 'B'); }))
				.append("text")
				.attr("y", 6)
				.attr("dy", "-2em")
				.style("text-anchor", "end")
				.text(keys[0]);

			svg.append("g")
				.attr("class", "barGraphGrid2")
				.attr("transform", "translate(" + (width) + ",0)")
				.call(d3.axisRight(y1).ticks(ticks).tickSize(-(width-10)).tickFormat(function(d) { let formatValue = d3.format("~s"); return formatValue(d).replace('G', 'B'); }))
				.append("text")
				.attr("y", 6)
				.attr("dy", "-2em")
				.attr("dx", "2em")
				.style("text-anchor", "end")
				.text(keys[1]);
		} else {
			svg.append("g")
			.call(d3.axisLeft(y).ticks(ticks).tickSize(-(width-10)).tickFormat(function(d) { let formatValue = d3.format("~s"); return formatValue(d).replace('G', 'B'); }))
			.attr("class", "barGraphGrid")
			.append("text")
			.attr("y", '-4em')
			.attr("x", '-1em')
			.attr("text-anchor", "end")
			.attr("transform", "rotate(-90)")
			.text(yAxesLabel);
		}

		let gradient = svg.append("linearGradient")
		   .attr("id", "bar-gradient")
		   .attr("x1", "0%")
		   .attr("x2", "0%")
		   .attr("y1", "0%")
		   .attr("y2", "100%");

		gradient.append("stop")
		   .attr('class', 'start')
		   .attr("offset", "0%")
		   .attr("stop-color", "#028294")
		   .attr("stop-opacity", 1);

		gradient.append("stop")
		   .attr('class', 'end')
		   .attr("offset", "100%")
		   .attr("stop-color", "#002245")
		   .attr("stop-opacity", 1);

		let gradient2 = svg.append("linearGradient")
		   .attr("id", "bar-gradient-2")
		   .attr("x1", "0%")
		   .attr("x2", "0%")
		   .attr("y1", "0%")
		   .attr("y2", "100%");

		gradient2.append("stop")
		   .attr('class', 'start')
		   .attr("offset", "0%")
		   .attr("stop-color", "#4cbb88")
		   .attr("stop-opacity", 1);

		gradient2.append("stop")
		   .attr('class', 'end')
		   .attr("offset", "100%")
		   .attr("stop-color", "#028294")
		   .attr("stop-opacity", 1);

		if (!d3.select("#impact-tooltip").node()) {
			var div = d3.select("body").append("div")	
			    .attr("class", "tooltip")
			    .attr("id", "impact-tooltip")				
			    .style("opacity", 0);
		} else {
			var div = d3.select("#impact-tooltip");
		}

		if (this.props.type === "grouped") {
			svg.selectAll(".bar")
			    .data(grouped_data[0])
			    .enter().append("rect")
				.attr("class", graphLabelsClass)
				.attr("x", function(d) { return x(d.year) + x.bandwidth() / 4; })
				.attr("width", x.bandwidth() / 2)
				.attr("y", function(d) { return y(d.value); })
				.attr("height", function(d) { return height - y(d.value); })
				.attr("fill", "url(#bar-gradient)")
				.attr("transform", `translate(${x.bandwidth() / -4}, 0)`)
				.on("mouseover", function(d) {
		            div.transition()		
		                .duration(200)		
		                .style("opacity", .9);		
		            div	.html((pt ? d.category_pt : d.category) + ": " + d.value.toLocaleString(pt ? 'pt-BR':'en-US') + ' (' + d.year + ')')	
		                .style("left", (d3.event.pageX) + "px")		
		                .style("top", (d3.event.pageY - 28) + "px");	
		            })					
		        .on("mouseout", function(d) {		
		            div.transition()		
		                .duration(500)		
		                .style("opacity", 0);	
		        });
			svg.selectAll(".bar")
			    .data(grouped_data[1])
			    .enter().append("rect")
				.attr("class", graphLabelsClass)
				.attr("x", function(d) { return x(d.year) + x.bandwidth() / 4; })
				.attr("width", x.bandwidth() / 2)
				.attr("y", function(d) { return y(d.value); })
				.attr("height", function(d) { return height - y(d.value); })
				.attr("fill", "url(#bar-gradient-2)")
				.attr("transform", `translate(${x.bandwidth() / 4}, 0)`)
				.on("mouseover", function(d) {
		            div.transition()		
		                .duration(200)		
		                .style("opacity", .9);		
		            div	.html((pt ? d.category_pt : d.category) + ": " + d.value.toLocaleString(pt ? 'pt-BR':'en-US') + ' (' + d.year + ')')	
		                .style("left", (d3.event.pageX) + "px")		
		                .style("top", (d3.event.pageY - 28) + "px");	
		            })					
		        .on("mouseout", function(d) {		
		            div.transition()		
		                .duration(500)		
		                .style("opacity", 0);	
		        });

			
			let color = d3.scaleOrdinal().domain(keys).range(["url(#bar-gradient)","url(#bar-gradient-2)"])
			var legend = svg.selectAll(".legend")
			      .data(keys)
			  	  .enter().append("g")
			      .attr("class", "indicator-legend")
			      .attr("transform", function(d,i) { return "translate(0, " + i * -25 + ")"; })
			      .style("opacity","100");

			  legend.append("rect")
			      .attr("x", width - 18)
			      .attr("width", 18)
			      .attr("height", 18)
			      .style("fill", function(d){ return color(d)});

			  legend.append("text")
			  	.attr("class", "indicator-legend-text")
			      .attr("x", width - 24)
			      .attr("y", 9)
			      .attr("dy", ".35em")
			      .style("text-anchor", "end")
			      .text(function(d){return d});

		} else if (this.props.type === "dual_scale") {

			svg.selectAll(".bar")
			    .data(grouped_data[0])
			    .enter().append("rect")
				.attr("class", "graphLabelsClass")
				.attr("x", function(d) { return x(d.year) + x.bandwidth() / 4; })
				.attr("width", x.bandwidth()/2)
				.attr("y", function(d) { return y0(d.value); })
				.attr("height", function(d) { return height - y0(d.value); })
				.attr("fill", "url(#bar-gradient)")
				.attr("transform", `translate(${x.bandwidth() / -4}, 0)`)
				.on("mouseover", function(d) {
		            div.transition()		
		                .duration(200)		
		                .style("opacity", .9);		
		            div	.html((pt ? d.category_pt : d.category) + ": " + d.value.toLocaleString(pt ? 'pt-BR':'en-US') + ' (' + d.year + ')')	
		                .style("left", (d3.event.pageX) + "px")		
		                .style("top", (d3.event.pageY - 28) + "px");	
		            })					
		        .on("mouseout", function(d) {		
		            div.transition()		
		                .duration(500)		
		                .style("opacity", 0);	
		        }); 

			svg.selectAll(".bar")
			    .data(grouped_data[1])
			    .enter().append("rect")
				.attr("class", "graphLabelsClass")
				.attr("x", function(d) { return x(d.year) + x.bandwidth() / 4; })
				.attr("width", x.bandwidth() / 2)
				.attr("y", function(d) { return y1(d.value); })
				.attr("height", function(d) { return height - y1(d.value); })
				.attr("fill", "url(#bar-gradient-2)")
				.attr("transform", `translate(${x.bandwidth() / 4}, 0)`)
				.on("mouseover", function(d) {
		            div.transition()		
		                .duration(200)		
		                .style("opacity", .9);		
		            div	.html((pt ? d.category_pt : d.category) + ": " + d.value.toLocaleString(pt ? 'pt-BR':'en-US') + ' (' + d.year + ')')	
		                .style("left", (d3.event.pageX) + "px")		
		                .style("top", (d3.event.pageY - 28) + "px");	
		            })					
		        .on("mouseout", function(d) {		
		            div.transition()		
		                .duration(500)		
		                .style("opacity", 0);	
		        });

			let color = d3.scaleOrdinal().domain(keys).range(["url(#bar-gradient)","url(#bar-gradient-2)"])
			var legend = svg.selectAll(".legend")
			      .data(keys)
			  	  .enter().append("g")
			      .attr("class", "indicator-legend")
			      .attr("transform", function(d,i) { return "translate(" + (keys.length - i) * -75 + ", -25)"; })
			      .style("opacity","100");

			  legend.append("rect")
			      .attr("x", width - 18)
			      .attr("width", 18)
			      .attr("height", 18)
			      .style("fill", function(d){ return color(d)});

			  legend.append("text")
			  	.attr("class", "indicator-legend-text")
			      .attr("x", width - 24)
			      .attr("y", 9)
			      .attr("dy", ".35em")
			      .style("text-anchor", "end")
			      .text(function(d){return d});

		} else { //default
			svg.selectAll(".bar")
			    .data(data)
			    .enter().append("rect")
				.attr("class", graphLabelsClass)
				.attr("x", function(d) { return x(d.year) + x.bandwidth() / 4; })
				.attr("width", x.bandwidth() / 2)
				.attr("y", function(d) { return y(d.value); })
				.attr("height", function(d) { return height - y(d.value); })
				.attr("fill", "url(#bar-gradient)")
				.on("mouseover", function(d) {
		            div.transition()		
		                .duration(200)		
		                .style("opacity", .9);		
		            div	.html(d.value.toLocaleString(pt ? 'pt-BR':'en-US') + ' (' + d.year + ')')	
		                .style("left", (d3.event.pageX) + "px")		
		                .style("top", (d3.event.pageY - 28) + "px");	
		            })					
		        .on("mouseout", function(d) {		
		            div.transition()		
		                .duration(500)		
		                .style("opacity", 0);	
		        });
		}

		
		//if there is a target value, add the indicator line
		if (this.props.target !== undefined && this.props.target !==null) {
		    svg.append('g')
				.append('line')
				.attr("x1", 10)
				.attr("y1", y(this.props.target))
				.attr("x2", width)
				.attr("y2", y(this.props.target))
				.attr("stroke", color)
				.attr("stroke-width", 5)
				.attr("stroke-dasharray", ("1, 15"))
				.attr("stroke-linecap", "round")
			

				svg.append("text")
					.attr("class", textClass)
					.attr("y", y(this.props.target) - 10)
					.attr("x", width + 5)
					.attr("dy", "0.71em")
					.attr("text-anchor", "start")
					.style("fill", color)
					.text((this.props.pt ? "Meta " : "Target ")+target_year);

					
		};
		//multiple targets
		if (targets !== null && this.props.type === "grouped") {
		    svg.append('g')
				.append('line')
				.attr("x1", 10)
				.attr("y1", y(targets[0]))
				.attr("x2", width)
				.attr("y2", y(targets[0]))
				.attr("stroke", color)
				.attr("stroke-width", 5)
				.attr("stroke-dasharray", ("1, 15"))
				.attr("stroke-linecap", "round")
			
			svg.append("text")
				.attr("class", textClass)
				.attr("y", y(targets[0]) - 10)
				.attr("x", width + 5)
				.attr("dy", "0.71em")
				.attr("text-anchor", "start")
				.style("fill", color)
				.text((this.props.pt ? "Meta " : "Target ")+target_year);

			svg.append('g')
				.append('line')
				.attr("x1", 10)
				.attr("y1", y(targets[1]))
				.attr("x2", width)
				.attr("y2", y(targets[1]))
				.attr("stroke", "#028294")
				.attr("stroke-width", 5)
				.attr("stroke-dasharray", ("1, 15"))
				.attr("stroke-linecap", "round")
			
			svg.append("text")
				.attr("class", textClass + " secondBar")
				.attr("y", y(targets[1]) - 10)
				.attr("x", width + 5)
				.attr("dy", "0.71em")
				.attr("text-anchor", "start")
				.style("fill", "#028294")
				.text((this.props.pt ? "Meta " : "Target ")+target_year);
		} else if (targets !== null && this.props.type === "dual_scale") {
		    svg.append('g')
				.append('line')
				.attr("x1", 10)
				.attr("y1", y0(targets[0]))
				.attr("x2", width)
				.attr("y2", y0(targets[0]))
				.attr("stroke", color)
				.attr("stroke-width", 5)
				.attr("stroke-dasharray", ("1, 15"))
				.attr("stroke-linecap", "round")
			
			svg.append("text")
				.attr("class", textClass)
				.attr("y", y0(targets[0]) - 10)
				.attr("x", width + 45)
				.attr("dy", "0.71em")
				.attr("text-anchor", "start")
				.style("fill", color)
				.text(target_year);

			svg.append('g')
				.append('line')
				.attr("x1", 10)
				.attr("y1", y1(targets[1]))
				.attr("x2", width)
				.attr("y2", y1(targets[1]))
				.attr("stroke", "#028294")
				.attr("stroke-width", 5)
				.attr("stroke-dasharray", ("1, 15"))
				.attr("stroke-linecap", "round")
			
			svg.append("text")
				.attr("class", textClass + " secondBar")
				.attr("y", y1(targets[1]) - 10)
				.attr("x", width + 45)
				.attr("dy", "0.71em")
				.attr("text-anchor", "start")
				.style("fill", "#028294")
				.text(target_year);
		};
	}

	render() {
		return <div ref={node => this.node = node}></div>
	}
}
