import React, {useEffect, useState} from "react";
import Input from "../Input";
import Button from "../Button";
import { StateSelector } from "./components/stateComponent";
import PaperMoney from '../../../icons/paper-money-input.svg'
import ChartLine from '../../../icons/chart-line-input.svg'
import PureNatural from '../../../icons/pure-natural-input.svg'

const textPortuguese = {
    id: 'ID',
    title: 'Nome do projeto (Inglês)',
    title_pt: 'Nome do projeto (Português)',
    focus_area_1: 'Área de foco 1',
    focus_area_2: 'Área de foco 2',
    focus_area_3: 'Área de foco 3',
    states: 'Estados',
    save: 'Salvar e continuar'
}

const textEnglish = {
    id: 'ID',
    title: 'Title (English)',
    title_pt: 'Title (Portuguese)',
    focus_area_1: 'Focus area 1',
    focus_area_2: 'Focus area 2',
    focus_area_3: 'Focus area 3',
    states: 'States',
    save: 'Save and continue'
}

const validateForm = ({
    id,
    title_pt,
    focus_area_1_value,
    focus_area_2_value,
    focus_area_3_value,
    states
}) => {
    return (
        (id !== null && id !== '') &&
        (title_pt !== null && title_pt !== '') &&
        (focus_area_1_value !== null && isNaN(Number(focus_area_1_value)) === false && Number(focus_area_1_value) > -1) &&
        (focus_area_2_value !== null && isNaN(Number(focus_area_2_value)) === false && Number(focus_area_2_value) > -1) &&
        (focus_area_3_value !== null && isNaN(Number(focus_area_3_value)) === false && Number(focus_area_3_value) > -1) &&
        (states !== null && states.length > 0)
    );
};

const AddProject = (props) => {
    const handleFormChange = (field) => (value) => {
        props.setFormState({
            ...props.formState,
            [field]: value,
        });
    };

    useEffect(() => {
        props.setFormIsValid(validateForm(props.formState));
    }, [props.formState]);

    useEffect(() => {
        if (props.location.pathname.substring(0, 4) === '/pt/') {
            props.setText(textPortuguese);
        } else {
            props.setText(textEnglish);
        }
    }, [])

    return (
        <>
            <div className="modal-form">
                <div style={{
                    display: 'flex',
                    width: '100%',
                    gap: '10px',
                }}>
                    <div
                        style={{
                            width: '30%'
                        }}
                        className="modal-control"
                    >
                        <label>{props.text?.id ?? ''}<span>*</span></label>
                        <Input
                            type="text"
                            placeholder="0"
                            value={props.formState.id ?? ''}
                            onChange={(e) => handleFormChange('id')(e.target.value)}
                        />
                    </div>
                    <div
                        style={{
                            width: '100%',
                        }}
                        className="modal-control"
                    >
                        <label>{props.text?.title ?? ''}</label>
                        <Input
                            type="text"
                            disabled={true}
                            placeholder="Digite aqui"
                            value={props.formState.title ?? ''}
                            onChange={(e) => handleFormChange('title')(e.target.value)}
                        />
                    </div>
                    <div
                        style={{
                            width: '100%',
                        }}
                        className="modal-control"
                    >
                        <label>{props.text?.title_pt ?? ''}<span>*</span></label>
                        <Input
                            type="text"
                            placeholder="Digite aqui"
                            value={props.formState.title_pt ?? ''}
                            onChange={(e) => handleFormChange('title_pt')(e.target.value)}
                        />
                    </div>
                </div>
                <div
                    style={{
                        display: 'flex',
                        width: '100%',
                        gap: '10px',
                    }}
                >
                    <div
                        style={{ width: '100%' }}
                        className="modal-control"
                    >
                        <label>{props.text?.focus_area_1 ?? ''}<span>*</span></label>
                        <Input
                            icon={PaperMoney}
                            type="text"
                            placeholder="$0"
                            isCurrency={true}
                            value={props.formState.focus_area_1_value ?? ''}
                            onChange={(e) => handleFormChange('focus_area_1_value')(e.target.value.replace(/[$,]/g, ''))}
                        />
                    </div>
                    <div style={{ width: '100%' }} className="modal-control">
                        <label>{props.text?.focus_area_2 ?? ''}<span>*</span></label>
                        <Input
                            icon={ChartLine}
                            type="text"
                            placeholder="$0"
                            isCurrency={true}
                            value={props.formState.focus_area_2_value ?? ''}
                            onChange={(e) => handleFormChange('focus_area_2_value')(e.target.value.replace(/[$,]/g, ''))}
                        />
                    </div>
                    <div style={{ width: '100%' }} className="modal-control">
                        <label>{props.text?.focus_area_3 ?? ''}<span>*</span></label>
                        <Input
                            icon={PureNatural}
                            type="text"
                            placeholder="$0"
                            isCurrency={true}
                            value={props.formState.focus_area_3_value ?? ''}
                            onChange={(e) => handleFormChange('focus_area_3_value')(e.target.value.replace(/[$,]/g, ''))}
                        />
                    </div>
                </div>
                <div className="modal-control">
                    <label>{props.text?.states ?? ''}<span>*</span></label>
                    <StateSelector
                        defaultSelectedStates={props.formState.states}
                        onSelectState={(selectedStates) => {
                            handleFormChange('states')(selectedStates);
                        }} 
                    />
                </div>
            </div>
            <div className="ButtonsWrapper" style={{ justifyContent: 'flex-end' }}>
                <Button
                    disabled={props.formIsValid === false || props.onLoading}
                    onClick={(e) => props.onSave(props.formState)}
                >{props.text?.save ?? ''}</Button>
            </div>
        </>
    );
}

export default AddProject;
