import React from 'react'
import { useState } from 'react'
import propTypes from 'prop-types'
import classNames from 'classnames'
import styles from './styles.module.css'
import Chevron from './img/chevron-down.svg'

const Accordeon = ({
  defaultStatus = false,
  className,
  children,
  title,
  ...props
}) => {
  const [active, setActive] = useState(defaultStatus)

  const handleClick = () => {
    setActive(!active)
  }

  const accordeonTitleClasses = classNames(styles.accordeonTitle, {
    [styles.accordeonTitleActive]: active,
  })

  const accordeonContentClasses = classNames(styles.accordeonContent, {
    [styles.accordeonContentActive]: active,
  })

  return (
    <div className={styles.accordeonWrapper} {...props}>
      <div className={accordeonTitleClasses} onClick={handleClick}>
        <span>{title}</span>
        <img src={Chevron} className={active ? styles.accordeonFlippedIcon : ''} alt="Chevron" />
      </div>
      <div className={accordeonContentClasses}>{children}</div>
    </div>
  )
}

Accordeon.propTypes = {
  defaultStatus: propTypes.bool,
  className: propTypes.string,
  children: propTypes.node.isRequired,
  title: propTypes.string.isRequired,
}

export default Accordeon
