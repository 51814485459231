import React from 'react';
import classnames from 'classnames';
import styles from './styles.module.css';

const Modal = ({
  modalVisibility,
  handleModalVisibility,
  contentClassNames,
  className,
  children,
  ...rest
}) => {
  const modalWrapperClasses = classnames(
    styles.modalWrapper,
    className,
    {
      'hidden': !modalVisibility,
      [styles.visible]: modalVisibility,
    }
  );

  const modalClasses = classnames(styles.modal);

  const modalContentClasses = classnames(styles.modalContent, contentClassNames);

  const modalCloseClasses = classnames(
    'w-full h-full absolute',
    styles.modalCloseButton
  );

  return (
    <div className={modalWrapperClasses} {...rest}>
      <div className={modalClasses}>
        <div className={modalContentClasses}>{children}</div>
        <button className={modalCloseClasses} onClick={handleModalVisibility} />
      </div>
    </div>
  );
};

export default Modal;
