import axios from 'axios';
import ReactLoading from "react-loading";
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import LineChart from '../common/graphs/LineChart';
import GroupedBar2 from '../common/graphs/GroupedBar2';
import GraphTabs from '../common/graphTabs/GraphTabs';

import '../benchmarking/benchmarkingContent/BenchmarkingContent.css';
import {API_URL} from "../../../config/api";

const country_translation = {
	'Afghanistan': 'Afeganistão',
	'Albania': 'Albânia',
	'Algeria': 'Argélia',
	'American Samoa': 'Samoa Americana',
	'Andorra': 'Andorra',
	'Angola': 'Angola',
	'Antigua and Barbuda': 'Antígua e Barbuda',
	'Argentina': 'Argentina',
	'Armenia': 'Arménia',
	'Aruba': 'Aruba',
	'Australia': 'Austrália',
	'Austria': 'Áustria',
	'Azerbaijan': 'Azerbaijão',
	'Bahamas': 'Bahamas',
	'Bahrain': 'Bahrein',
	'Bangladesh': 'Bangladesh',
	'Barbados': 'Barbados',
	'Belarus': 'Bielorrússia',
	'Belgium': 'Bélgica',
	'Belize': 'Belize',
	'Benin': 'Benim',
	'Bermuda': 'Bermudas',
	'Bhutan': 'Butão',
	'Bolivia': 'Bolívia',
	'Bosnia and Herzegovina': 'Bósnia e Herzegovina',
	'Botswana': 'Botswana',
	'Brazil': 'Brasil',
	'Brunei Darussalam': 'Brunei',
	'Bulgaria': 'Bulgária',
	'Burkina Faso': 'Burkina Faso',
	'Burundi': 'Burundi',
	'Cambodia': 'Camboja',
	'Cameroon': 'Camarões',
	'Canada': 'Canadá',
	'Cape Verde': 'Cabo Verde',
	'Cayman Islands': 'Ilhas Cayman',
	'Central African Republic': 'República Centro-Africana',
	'Chad': 'Chade',
	'Chile': 'Chile',
	'China': 'China',
	'Colombia': 'Colômbia',
	'Comoros': 'Comores',
	'Congo': 'República do Congo',
	'Congo, The Democratic Republic of the': 'República Democrática do Congo',
	'Costa Rica': 'Costa Rica',
	"Cote D'Ivoire": 'Costa do Marfim',
	'Croatia': 'Croácia',
	'Cuba': 'Cuba',
	'Cyprus': 'Chipre',
	'Czech Republic': 'República Checa',
	'Denmark': 'Dinamarca',
	'Djibouti': 'Djibouti',
	'Dominica': 'Dominica',
	'Dominican Republic': 'República Dominicana',
	'Ecuador': 'Equador',
	'Egypt': 'Egito',
	'El Salvador': 'El Salvador',
	'Equatorial Guinea': 'Guiné Equatorial',
	'Eritrea': 'Eritreia',
	'Estonia': 'Estónia',
	'Ethiopia': 'Etiópia',
	'Faroe Islands': 'Ilhas Faroe',
	'Fiji': 'Fiji',
	'Finland': 'Finlândia',
	'France': 'França',
	'French Polynesia': 'Polinésia Francesa',
	'Gabon': 'Gabão',
	'Gambia': 'Gâmbia',
	'Georgia': 'Geórgia',
	'Germany': 'Alemanha',
	'Ghana': 'Gana',
	'Gibraltar': 'Gibraltar',
	'Greece': 'Grécia',
	'Greenland': 'Gronelândia',
	'Grenada': 'Granada',
	'Guam': 'Guam',
	'Guatemala': 'Guatemala',
	'Guinea': 'Guiné',
	'Guinea-Bissau': 'Guiné-Bissau',
	'Guyana': 'Guiana',
	'Haiti': 'Haiti',
	'Honduras': 'Honduras',
	'Hong Kong': 'Hong Kong',
	'Hungary': 'Hungria',
	'Iceland': 'Islândia',
	'India': 'Índia',
	'Indonesia': 'Indonésia',
	'Iran, Islamic Republic Of': 'Irã',
	'Iraq': 'Iraque',
	'Ireland': 'Irlanda',
	'Isle of Man': 'Ilha de Man',
	'Israel': 'Israel',
	'Italy': 'Itália',
	'Jamaica': 'Jamaica',
	'Japan': 'Japão',
	'Jordan': 'Jordânia',
	'Kazakhstan': 'Cazaquistão',
	'Kenya': 'Quénia',
	'Kiribati': 'Kiribati',
	"Korea, Democratic People'S Republic of": 'República Popular Democrática da Coreia',
	'South Korea': 'República da Coreia',
	'Kuwait': 'Kuwait',
	'Kyrgyzstan': 'Quirguistão',
	"Lao People'S Democratic Republic": 'Laos',
	'Latvia': 'Letónia',
	'Lebanon': 'Líbano',
	'Lesotho': 'Lesoto',
	'Liberia': 'Libéria',
	'Libya': 'Líbia',
	'Liechtenstein': 'Liechtenstein',
	'Lithuania': 'Lituânia',
	'Luxembourg': 'Luxemburgo',
	'Macao': 'Macau',
	'Macedonia, The Former Yugoslav Republic of': 'República da Macedônia do Norte',
	'Madagascar': 'Madagáscar',
	'Malawi': 'Malawi',
	'Malaysia': 'Malásia',
	'Maldives': 'Maldivas',
	'Mali': 'Mali',
	'Malta': 'Malta',
	'Marshall Islands': 'Ilhas Marshall',
	'Mauritania': 'Mauritânia',
	'Mauritius': 'Maurícia',
	'Mexico': 'México',
	'Micronesia, Federated States of': 'Micronésia',
	'Moldova, Republic of': 'Moldávia',
	'Monaco': 'Mónaco',
	'Mongolia': 'Mongólia',
	'Montenegro': 'Montenegro',
	'Morocco': 'Marrocos',
	'Mozambique': 'Moçambique',
	'Myanmar': 'Myanmar',
	'Namibia': 'Namíbia',
	'Nauru': 'Nauru',
	'Nepal': 'Nepal',
	'Netherlands': 'Países Baixos',
	'New Caledonia': 'Nova Caledônia',
	'New Zealand': 'Nova Zelândia',
	'Nicaragua': 'Nicarágua',
	'Niger': 'Níger',
	'Nigeria': 'Nigéria',
	'Northern Mariana Islands': 'Ilhas Marianas do Norte',
	'Norway': 'Noruega',
	'Oman': 'Omã',
	'Pakistan': 'Paquistão',
	'Palau': 'Palau',
	'Palestinian Territory, Occupied': 'Território Palestino, Ocupado',
	'Panama': 'Panamá',
	'Papua New Guinea': 'Papua-Nova Guiné',
	'Paraguay': 'Paraguai',
	'Peru': 'Peru',
	'Philippines': 'Filipinas',
	'Poland': 'Polónia',
	'Portugal': 'Portugal',
	'Puerto Rico': 'Porto Rico',
	'Qatar': 'Catar',
	'Romania': 'Roménia',
	'Russian Federation': 'Rússia',
	'Rwanda': 'Ruanda',
	'Saint Kitts and Nevis': 'São Cristóvão e Nevis',
	'Saint Lucia': 'Santa Lúcia',
	'Saint Martin (French part)': 'São Martinho',
	'Saint Vincent and the Grenadines': 'São Vicente e Granadinas',
	'Samoa': 'Samoa',
	'San Marino': 'San Marino',
	'Sao Tome and Principe': 'São Tomé e Príncipe',
	'Saudi Arabia': 'Arábia Saudita',
	'Senegal': 'Senegal',
	'Serbia': 'Sérvia',
	'Seychelles': 'Seychelles',
	'Sierra Leone': 'Serra Leoa',
	'Singapore': 'Singapura',
	'Slovakia': 'Eslováquia',
	'Slovenia': 'Eslovénia',
	'Solomon Islands': 'Ilhas Salomão',
	'Somalia': 'Somália',
	'South Africa': 'África do Sul',
	'Spain': 'Espanha',
	'Sri Lanka': 'Sri Lanka',
	'Sudan': 'Sudão',
	'Suri"name"': 'Suriname',
	'Swaziland': 'Suazilândia',
	'Sweden': 'Suécia',
	'Switzerland': 'Suíça',
	'Syrian Arab Republic': 'Síria',
	'Tajikistan': 'Tajiquistão',
	'Tanzania, United Republic of': 'Tanzânia',
	'Thailand': 'Tailândia',
	'Timor-Leste': 'Timor-Leste',
	'Togo': 'Togo',
	'Tonga': 'Tonga',
	'Trinidad and Tobago': 'Trinidad e Tobago',
	'Tunisia': 'Tunísia',
	'Turkey': 'Turquia',
	'Turkmenistan': 'Turquemenistão',
	'Turks and Caicos Islands': 'Ilhas Turcas e Caicos',
	'Tuvalu': 'Tuvalu',
	'Uganda': 'Uganda',
	'Ukraine': 'Ucrânia',
	'United Arab Emirates': 'Emirados Árabes Unidos',
	'United Kingdom': 'Reino Unido',
	'United States': 'Estados Unidos',
	'Uruguay': 'Uruguai',
	'Uzbekistan': 'Uzbequistão',
	'Vanuatu': 'Vanuatu',
	'Venezuela': 'Venezuela',
	'Vietnam': 'Vietname',
	'Virgin Islands, British': 'Ilhas Virgens Britânicas',
	'Virgin Islands, U.S.': 'Ilhas Virgens Americanas',
	'Yemen': 'Iémen',
	'Zambia': 'Zâmbia',
	'Zimbabwe': 'Zimbabwe',
};

class BenchmarkEmbed extends Component {
	constructor(props) {
		super(props);
		let isGDP = this.props.location.pathname.indexOf('gdp') > -1;
		let isGINI = this.props.location.pathname.indexOf('gini') > -1;

		this.state = {
			dataIsFetched: false,
			wdi_data: [],
			country_order: [],
			isGDP: isGDP,
			isGINI: isGINI
		}
	}

	componentDidMount() {
		window.scrollTo(0,0);
		let country_order = [{iso2:'BR',iso3:'BRA'}];
		let query = '';
		if (this.state.isGDP) {
			query = 'indicator=GDPGrowth';
		} else if (this.state.isGINI) {
			query = 'indicator=GINI';
		}
		axios.get(`${API_URL}/api/wdi_data?${query}`).then(result => {
			this.setState({
				country_order: country_order,
				dataIsFetched: true,
				wdi_data: result.data
			})
		}).catch((error) => {
	    	if (error.response.status === 401) {
	    		localStorage.removeItem('auth');
				this.props.history.push('/');
	    	}
	    });
	}

	render() {
		let pt = false;
		let text = {};
		if (this.props.location.pathname.substring(0,4) === "/pt/") {
			pt = true;
		}

		let content = (<div className="loading-container"><ReactLoading type="spinningBubbles" color="#444" /></div>);
		if (this.state.dataIsFetched) {
			// GDP growth % (Annual)
			// GINI index (world bank estimate)
			if (this.state.isGDP) {
				content = 
					<div>
				        <div className="col-md-12 categoryContent">
							<h4 className="category-title">{this.state.wdi_data[this.state.country_order[0].iso3].indicators["NY.GDP.MKTP.KD.ZG"][pt ? 'name_pt' : 'name']}</h4>
						    <div className="row">
								{/*<div className="col-xs-12">
						    		<p className="benchmarking-text">{this.state.wdi_data[this.state.country_order[0].iso3].indicators["NY.GDP.MKTP.KD.ZG"][pt ? 'source_note_pt' : 'source_note']}}</p>
						    	</div>*/}
						    	<div className="col-xs-12">
								    <LineChart {...this.props} pt={pt} country_translation={country_translation} wdi_data={this.state.wdi_data} country_order={this.state.country_order} field="NY.GDP.MKTP.KD.ZG" values={20} />
									<br />
									<p className="footnote">{pt ? 'FONTE' : 'SOURCE'}: {this.state.wdi_data[this.state.country_order[0].iso3].indicators["NY.GDP.MKTP.KD.ZG"][pt ? 'source_pt' : 'source']}</p>
								</div>
						    </div>
						</div>
					</div>
			} else if (this.state.isGINI) {
				content = 
					<div>
				        <div className="col-md-12 categoryContent">
							<h4 className="category-title">{this.state.wdi_data[this.state.country_order[0].iso3].indicators["SI.POV.GINI"][pt ? 'name_pt' : 'name']}</h4>
						    <div className="row">
								{/*<div className="col-xs-12">
						    		<p className="benchmarking-text">{this.state.wdi_data[this.state.country_order[0].iso3].indicators["SI.POV.GINI"][pt ? 'source_note_pt' : 'source_note']}}</p>
						    	</div>*/}
						    	<div className="col-xs-12">
						    		<GraphTabs {...this.props} pt={pt} country_translation={country_translation} tab1Name={this.state.wdi_data[this.state.country_order[0].iso3].indicators["SI.POV.GINI"].data[this.state.wdi_data[this.state.country_order[0].iso3].indicators["SI.POV.GINI"].data.length-1].year} tab2Name={pt ? "Histórico" : "Historical"}
							    		tab1Content={
							    			<div>
							    				<GroupedBar2 {...this.props} pt={pt} country_translation={country_translation} wdi_data={this.state.wdi_data} country_order={this.state.country_order} field="SI.POV.GINI" values={1} formatter={'0.0a'}/>
												<p className="footnote">{pt ? 'FONTE' : 'SOURCE'}: {this.state.wdi_data[this.state.country_order[0].iso3].indicators["SI.POV.GINI"][pt ? 'source_pt' : 'source']}</p>
											</div>
										}
										tab2Content={
											<div>
							    				<GroupedBar2 {...this.props} pt={pt} country_translation={country_translation} wdi_data={this.state.wdi_data} country_order={this.state.country_order} field="SI.POV.GINI" values={5} formatter={'0.0a'}/>
												<p className="footnote">{pt ? 'FONTE' : 'SOURCE'}: {this.state.wdi_data[this.state.country_order[0].iso3].indicators["SI.POV.GINI"][pt ? 'source_pt' : 'source']}</p>
											</div>
										}
									/>
								</div>
							</div>
						</div>
					</div>
			}
		}
		return (
			content
		);
	}
};
export default withRouter(BenchmarkEmbed);
