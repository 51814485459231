import React, {useEffect, useState} from "react";
import Input from "../Input";
import Button from "../Button";

const textPortuguese = {
    description: 'Objetivo de desenvolvimento do projeto (Inglês)',
    description_pt: 'Objetivo de desenvolvimento do projeto (Português)',
    abstract: 'Sumário do projeto (Inglês)',
    abstract_pt: 'Resumo do projeto (Português)',
    amount_committed: 'Valor total comprometido',
    disbursements: 'Desembolsos totais',
    date_approved: 'Data de aprovação',
    date_closed: 'Data de fechamento',
    link: 'Link do site do projeto',
    link_pt: 'Link do site do projeto (Português)',
    image: 'Video URL',
    image_pt: 'Video URL (Português)',
    organization: 'Organização',
    save: 'Salvar'
}

const textEnglish = {
    description: 'Objetivo de desenvolvimento do projeto (English)',
    description_pt: 'Objetivo de desenvolvimento do projeto (Português)',
    abstract: 'Sumário do projeto (Inglês)',
    abstract_pt: 'Resumo do projeto (Português)',
    amount_committed: 'Valor total comprometido',
    disbursements: 'Desembolsos totais',
    date_approved: 'Data de aprovação',
    date_closed: 'Data de fechamento',
    link: 'Link do site do projeto',
    link_pt: 'Link do site do projeto (Português)',
    image: 'Video URL',
    image_pt: 'Video URL (Português)',
    organization: 'Organização',
    save: 'Save'
}

const ProjectInfo = (props) => {
    const handleFormChange = (field) => (value) => {
        props.setFormState({
            ...props.formState,
            [field]: value,
        });
    };

    useEffect(() => {
        props.setFormIsValid(props.validateForm(props.formState));
    }, [props.formState]);

    useEffect(() => {
        if (props.location.pathname.substring(0, 4) === '/pt/') {
            props.setText(textPortuguese);
        } else {
            props.setText(textEnglish);
        }
    }, []);

    return (
        <>
            <div className="modal-form">
                <div className="modal-control">
                    <label>{props.text?.description ?? ''}</label>
                    <Input
                        type="text"
                        disabled={true}
                        placeholder="Digite aqui"
                        value={props.formState.description ?? ''}
                        onChange={(e) => handleFormChange('description')(e.target.value)}
                    />
                </div>
                <div className="modal-control">
                    <label>{props.text?.description_pt ?? ''}<span>*</span></label>
                    <Input
                        type="text"
                        placeholder="Digite aqui"
                        value={props.formState.description_pt ?? ''}
                        onChange={(e) => handleFormChange('description_pt')(e.target.value)}
                    />
                </div>
                <div className="modal-control">
                    <label>{props.text?.abstract ?? ''}</label>
                    <Input
                        type="text"
                        disabled={true}
                        placeholder="Digite aqui"
                        value={props.formState.abstract ?? ''}
                        onChange={(e) => handleFormChange('abstract')(e.target.value)}
                    />
                </div>
                <div className="modal-control">
                    <label>{props.text?.abstract_pt ?? ''}<span>*</span></label>
                    <Input
                        type="text"
                        placeholder="Digite aqui"
                        value={props.formState.abstract_pt ?? ''}
                        onChange={(e) => handleFormChange('abstract_pt')(e.target.value)}
                    />
                </div>
                <div className="project-group-row">
                    <div className="modal-control">
                        <label>{props.text?.amount_committed ?? ''}</label>
                        <Input
                            type="text"
                            placeholder="$0"
                            isCurrency={true}
                            value={props.formState.amount_committed ?? ''}
                            onChange={(e) => handleFormChange('amount_committed')(e.target.value.replace(/[$,]/g, ''))}
                        />
                    </div>
                    <div className="modal-control">
                        <label>{props.text?.disbursements ?? ''}</label>
                        <Input
                            type="text"
                            placeholder="$0"
                            isCurrency={true}
                            value={props.formState.disbursements ?? ''}
                            onChange={(e) => handleFormChange('disbursements')(e.target.value.replace(/[$,]/g, ''))}
                        />
                    </div>
                </div>
                <div className="project-group-row">
                    <div className="modal-control">
                        <label>{props.text?.date_approved ?? ''}</label>
                        <Input
                            type="text"
                            mask="99/99/9999"
                            placeholder="00/00/0000"
                            value={props.formState.date_approved ?? ''}
                            onChange={(e) => handleFormChange('date_approved')(e.target.value)}
                        />
                    </div>
                    <div className="modal-control">
                        <label>{props.text?.date_closed ?? ''}</label>
                        <Input
                            type="text"
                            placeholder="00/00/0000"
                            value={props.formState.date_closed ?? ''}
                            onChange={(e) => handleFormChange('date_closed')(e.target.value)}
                        />
                    </div>
                </div>
                <div className="modal-control">
                    <label>{props.text?.link ?? ''}<span>*</span></label>
                    <Input
                        type="text"
                        placeholder="https://"
                        value={props.formState.link ?? ''}
                        onChange={(e) => handleFormChange('link')(e.target.value)}
                    />
                </div>
                <div className="modal-control">
                    <label>{props.text?.link_pt}</label>
                    <Input
                        type="text"
                        placeholder="https://"
                        value={props.formState.link_pt ?? ''}
                        onChange={(e) => handleFormChange('link_pt')(e.target.value)}
                    />
                </div>
                <div className="modal-control">
                    <label>{props.text?.image ?? ''}</label>
                    <Input
                        type="text"
                        placeholder="https://"
                        value={props.formState.image ?? ''}
                        onChange={(e) => handleFormChange('image')(e.target.value)}
                    />
                </div>
                <div className="modal-control">
                    <label>{props.text?.image_pt ?? ''}</label>
                    <Input
                        type="text"
                        placeholder="https://"
                        value={props.formState.image_pt ?? ''}
                        onChange={(e) => handleFormChange('image_pt')(e.target.value)}
                    />
                </div>
                <div className="modal-control">
                    <label>{props.text?.organization ?? ''}</label>
                    <Input
                        type="text"
                        placeholder="Digite aqui"
                        value={props.formState.organization ?? ''}
                        onChange={(e) => handleFormChange('organization')(e.target.value)}
                    />
                </div>
            </div>
            <div className="ButtonsWrapper" style={{ justifyContent: 'flex-end' }}>
                <Button
                    disabled={props.formIsValid === false || props.onLoading}
                    onClick={() => props.onSave(props.formState)}
                >{props.text?.save ?? ''}</Button>
            </div>
        </>
    );
};

export default ProjectInfo;
