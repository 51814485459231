import React, { useState, useEffect } from 'react';
import { withRouter, useHistory } from 'react-router-dom';

import '../strategy/Strategy.css'

import AltCard from '../../components/AltCard';
import Navbar from '../common/navbar/NavbarPLR';
import WelcomeFooter from '../menu/WelcomeFooter';
import AltButton from '../../components/AltButton';
import StrategyHeader from '../common/strategyheader';
import ChartLineIcon from '../strategy/img/chart-line.svg'
import StrategyCover from '../strategy/img/strategy-cover.png'
import PureNaturalIcon from '../strategy/img/pure-natural.svg'
import PaperMoneyTwoIcon from '../strategy/img/paper-money-two.svg'

const textEnglish = {
	"login": "Login",
	"logout": "Log Out",

	"med": "Focus Areas",
	"desc": "World Bank Group (WBG) initiatives in Brazil focus on three areas: (i) fiscal consolidation and government effectiveness; (ii) private sector investment, infrastructure, and productivity growth; and (iii) inclusive and sustainable development.",


	"focus_desc": {
		"focus_1": 	[
			"Restoring the sustainability of public finances and strengthening the institutional and legal framework for public financial management at all levels of government is critical to expand Brazil’s economic and social gains. The fiscal rule adopted in December 2016 provides the framework for fiscal consolidation but not the mechanisms for determining how expenditures will be cut.",
			/* "Reining in the level of public spending while continuing to expand access to services and improve quality will require improvements in the efficiency and effectiveness of public spending across the entire government. A central focus of the Bank’s efforts will be to argue for and demonstrate how to achieve fiscal adjustment without hurting the poor.", */
			"The specific objectives for this focus area are described below."
		],
		"focus_2": [
			"Unlocking private investment and productivity growth, and better infrastructure provision, are critical to raising employment and incomes in Brazil and highly complementary to fiscal adjustment. Extending the social gains of the past decade now requires increasing market competition to foster productive private sector investment and the creation of good jobs. Past policies aimed at correcting market failures have ended up creating additional distortions and preferential treatment for special interests. Public efforts to catalyze greater investment through flagship programs and the state development banks have had limited results but entailed high fiscal costs.",
			"The World Bank Group (WBG) will support the government in seeking to increase market competition and work closely with the national and state development banks to leverage WBG funding and improve the quality and efficiency of overall spending in the infrastructure.",
			[
				"Three of the ",
				{"text": "five WBG institutions", "url": "https://www.worldbank.org/en/who-we-are"},
				" will be instrumental in achieving this goal: the International Bank for Reconstruction and Development (",
				{"text": "IBRD", "url": "https://www.worldbank.org/en/who-we-are/ibrd"},
				") will contribute its experience in supporting improvement in regulatory frameworks; the International Finance Corporation (",
				{"text": "IFC", "url": "https://www.ifc.org/wps/wcm/connect/corp_ext_content/ifc_external_corporate_site/home"},
				") will bring its expertise in Public-Private Partnership (PPP) project financing and structuring; the Multilateral Investment Guarantee Agency (",
				{"text": "MIGA", "url": "https://www.miga.org/"},
				") can offer non-commercial and credit enhancement guarantees that would facilitate private sector participation in infrastructure investments."
			],
			/* "The WBG’s engagement will be based on the recognition that the value the private sector can bring through investments, PPPs and other instruments depends fundamentally on the quality of the regulatory environment.", */
				"The specific objectives for this focus area are described below.",
		],
		"focus_3": [
			"Brazil is rich in natural resources, which are an important source of income and a critical input for the country’s economic development. Brazil’s huge reserves of tropical forests and fresh water are especially important for some of the poor, for whom they constitute a significant share of their wealth. Agriculture is the single most important source of income for the rural poor, and makes Brazil a global leader in meat, soy, and other food exports.",
			/* "In addition to threats from land degradation and climate change, Brazil’s land regularization experience suggests that policy design for land use and sustainable development is running ahead of institutional capacity for implementation at the local level. Urban land and water use, pollution reduction, and vulnerability to climate change and natural disasters also remain key challenges for Brazilian cities, where 60 percent of the poor live.", */
			"The current Country Partnership Framework (CPF) is based on the principle that environment and development objectives need not be in conflict if Brazil makes adequate use of existing technologies such as the intensification of production systems, including among smallholder farmers. Hence, the World Bank Group (WBG) will emphasize an integrated approach for promoting economic, environmental, and social sustainability, including resolution of potential conflicts through better governance, as shown in the following four objectives.",
			"The specific objectives for this focus area are described below."
		]
	},
	"focus_icon_1": "Fiscal Consolidation Icon",
	"focus_label_1": "Focus Area 1",
	"focus_title_1": "Fiscal Consolidation and Government Effectiveness for Sustainable, Inclusive and Efficient Service Delivery",
	"focus_icon_2": "Private Sector Investment Icon",
	"focus_label_2": "Focus Area 2",
	"focus_title_2": "Private Sector Investment and Productive Growth",
	"focus_icon_3": "Inclusive and Sustainable Development Icon",
	"focus_label_3": "Focus Area 3",
	"focus_title_3": "Inclusive and Sustainable Development",
	"viewresults": "View Impact",
	"viewprogram": "View Program",
	"focus_1": [
		{
			"title": "1.1",
			"description": "Strengthen fiscal management at all levels of government"
		},
		{
			"title": "1.2",
			"description": "Increase fiscal sustainability and fairness of pension system, and effectiveness of social protection system"
		},
		{
			"title": "1.3",
			"description": "Increase effectiveness of service delivery in education"
		},
		{
			"title": "1.4",
			"description": "Increase effectiveness of service delivery in health"
		}
	],
	"focus_2": [
		{
			"title": "2.1",
			"description": "Reduce regulatory barriers and other microeconomic distortions to competition, investment, and trade"
		},
		{
			"title": "2.2",
			"description": "Reduce financial market distortions in order to improve the availability and allocation of credit"
		},
		{
			"title": "2.3",
			"description": "Mobilize greater investment in infrastructure to improve services, including through PPPs"
		}
	],
	"focus_3": [
		{
			"title": "3.1",
			"description": "Support the Achievement of Brazil’s NDC with a particular focus on land use"
		},
		{
			"title": "3.2",
			"description": "Provide more inclusive and sustainable urban services"
		},
		{
			"title": "3.3",
			"description": "Promote socio economic development of small rural producers and protect vulnerable groups"
		}
	]
}

const textPortuguese = {
	"login": "Login",
	"logout": "Log Out",

	"med": "Áreas de Foco",
	"desc": "As iniciativas do Grupo Banco Mundial (GBM) no Brasil concentram-se em três áreas: (i) consolidação fiscal e eficácia de governo; (ii) investimento, infraestrutura e crescimento da produtividade do setor privado; e (iii) desenvolvimento inclusivo e sustentável.",

	"focus_desc": {
		"focus_1": 	[
			"Para ampliar os ganhos sociais e econômicos do país, é fundamental restaurar a sustentabilidade das finanças públicas e fortalecer o marco institucional e jurídico que regula a gestão financeira em todas as esferas de governo. A regra fiscal adotada em dezembro de 2016 delineia a estrutura da consolidação fiscal, mas não os mecanismos que definem como as despesas serão cortadas.",
			"O controle do nível de gastos públicos, a expansão do acesso aos serviços públicos e o aumento da qualidade dos mesmos vão exigir melhorias na eficiência e na eficácia dos gastos em todo o governo. Um foco central dos esforços do Banco será demonstrar como realizar ajustes fiscais sem prejudicar os pobres.",
			"Os objetivos específicos dessa área de foco são descritos abaixo."
		],
		"focus_2": [
			"Mais investimentos privados, crescimento da produtividade e melhor infraestrutura são fundamentais para aumentar o emprego e a renda no Brasil, além de serem altamente complementares ao ajuste fiscal. A ampliação dos ganhos sociais da década passada agora exige um mercado mais competitivo para promover o investimento produtivo do setor privado e a criação de bons empregos. ",
			/* "Políticas anteriores, destinadas a corrigir falhas de mercado, acabaram gerando novas distorções e tratamento preferencial para interesses especiais. Os resultados dos esforços públicos para catalisar um maior investimento por meio de programas emblemáticos e de bancos estatais de desenvolvimento foram limitados, apesar de seus custos fiscais elevados.", */
			"O Grupo Banco Mundial (GBM) apoiará o governo na busca pelo aumento da concorrência no mercado. Além disso, trabalhará em estreita colaboração com os bancos nacionais e estaduais de desenvolvimento para alavancar o financiamento do GBM e melhorar a qualidade e a eficiência dos gastos gerais em infraestrutura.",
			[
				"Três das ",
				{"text": "cinco instituições do GBM", "url": "https://www.worldbank.org/en/who-we-are"},
				" serão fundamentais para a consecução desse objetivo: o Banco Internacional para Reconstrução e Desenvolvimento (",
				{"text": "BIRD", "url": "https://www.worldbank.org/en/who-we-are/ibrd"},
				") contribuirá com sua experiência no apoio à melhoria dos marcos regulatórios; a Corporação Financeira Internacional (",
				{"text": "IFC", "url": "https://www.ifc.org/wps/wcm/connect/corp_ext_content/ifc_external_corporate_site/home"},
				") agregará sua experiência em financiamento e estruturação de projetos de Parceria Público-Privadas (PPP); a Agência Multilateral de Garantia de Investimentos (",
				{"text": "MIGA", "url": "https://www.miga.org/"},
				") poderá oferecer garantias não-comerciais e de melhoria de crédito, facilitando a participação do setor privado nos investimentos em infraestrutura."
			],
			/* "O envolvimento do GBM será pautado pelo entendimento de que o valor que o setor privado pode trazer por meio de investimentos, PPPs e outros instrumentos depende, fundamentalmente, da qualidade do ambiente regulatório.", */
			"Os objetivos específicos dessa área de foco são descritos abaixo:",
		],
		"focus_3": [
			"O Brasil é rico em recursos naturais, que são uma importante fonte de renda e um insumo fundamental para o desenvolvimento econômico do país. As enormes reservas de floresta tropical e água doce do Brasil são particularmente importantes para algumas comunidades pobres, constituindo parte substancial de sua riqueza. A agricultura é a fonte de renda mais importante para a população rural pobre e faz do Brasil um líder global em exportações de carne, soja e outros alimentos.",
			/* "Além das ameaças da degradação da terra e da mudança climática, a experiência de regularização da terra no Brasil sugere que o desenho de políticas para o uso da terra e o desenvolvimento sustentável supera a capacidade institucional de implementação em nível local.", */
			/* "Já nas cidades brasileiras, onde vivem 60% dos pobres, o uso da terra e da água, a poluição e a vulnerabilidade às mudanças climáticas e desastres naturais continuam sendo os principais desafios.", */
			"A atual Estratégia de Parceria com o Brasil (CPF) baseia-se no princípio de que os objetivos de meio ambiente e de desenvolvimento não precisam entrar em conflito se o Brasil fizer uso adequado das tecnologias existentes, como a intensificação dos sistemas de produção, inclusive entre os pequenos agricultores. O Grupo Banco Mundial (GBM), portanto, enfatizará uma abordagem integrada para promover a sustentabilidade econômica, ambiental e social, incluindo a resolução de potenciais conflitos por meio de melhor governança, conforme ilustrado pelos quatro objetivos a seguir.",
			"Os objetivos específicos dessa área de foco são descritos abaixo:"
		]
	},
	"focus_icon_1": "Consolidação Fiscal Icon",
	"focus_label_1": "Área de Foco 1",
	"focus_title_1": "Consolidação fiscal e eficácia do governo para prestação de serviços sustentável, inclusiva e eficiente",
	"focus_icon_2": "Investimento do Setor Privado Icon",
	"focus_label_2": "Área de Foco 2",
	"focus_title_2": "Investimento do setor privado e crescimento produtivo",
	"focus_icon_3": "Desenvolvimento Sustentável e Inclusivo Icon",
	"focus_label_3": "Área de Foco 3",
	"focus_title_3": "Desenvolvimento Sustentável e Inclusivo",
	"viewresults": "Veja o Impacto",
	"viewprogram": "Veja o Programa",
	"focus_1": [
		{
			"title": "1.1",
			"description": "Fortalecer a gestão fiscal em todas as esferas de governo"
		},
		{
			"title": "1.2",
			"description": "Aumentar a sustentabilidade fiscal do sistema previdenciário e torná-lo mais justo, e aumentar a efetividade do sistema de proteção social"
		},
		{
			"title": "1.3",
			"description": "Aumentar a efetividade da prestação de serviços de educação"
		},
		{
			"title": "1.4",
			"description": "Aumentar a efetividade da prestação de serviços de saúde"
		}
	],
	"focus_2": [
		{
			"title": "2.1",
			"description": "Reduzir as barreiras regulatórias e outras distorções microeconômicas na concorrência, no investimento e no comércio"
		},
		{
			"title": "2.2",
			"description": "Reduzir as distorções de mercado para melhorar a disponibilidade e a alocação de crédito"
		},
		{
			"title": "2.3",
			"description": "Mobilizar mais investimentos em infraestrutura para melhorar a prestação de serviços, inclusive por meio de PPPs"
		}
	],
	"focus_3": [
		{
			"title": "3.1",
			"description": "Apoiar a implementação da NDC do Brasil, com foco específico no uso da terra"
		},
		{
			"title": "3.2",
			"description": "Prestação de serviços urbanos mais inclusivos e sustentáveis"
		},
		{
			"title": "3.3",
			"description": "Promoção do desenvolvimento socioeconômico de pequenos produtores rurais e proteção de grupos vulneráveis"
		}
	]
}

const Strategy = (props) => {
	const history = useHistory();
	const [selected, setSelected] = useState("focus_1");

	const handleLogout = () => {
		localStorage.removeItem('auth');
		history.push('/');
	};

	useEffect(() => {
		window.scrollTo(0, 0);
		const selected = localStorage.getItem('strategy_focus_area') ?? 'focus_1';
		setSelected(selected);
	}, []);

	const selectFocus = (selection) => {
		localStorage.setItem('strategy_focus_area', selection);
		setSelected(selection);
	};

	let text = {};
	let pt = true;

	if (props.location.pathname.substring(0, 4) !== '/pt/') {
		text = textEnglish;
		pt = false;
	} else {
		text = textPortuguese;
	}

	return (
		<div>
			<div className="container-fluid unpadify">
				<Navbar
					handleLogin={handleLogout}
					handleLogout={handleLogout}
					auth={props.auth}
					isLoggedIn={props.loggedIn}
					checkIsLoggedIn={props.checkIsLoggedIn}
					onChangeLoginState={props.onChangeLoginState}
					{...props}
					currentPage="strategy"
				/>
				<StrategyHeader
					title={pt ? "Estratégia" : "Strategy"}
					selectedTab={1}
					tabs={[
						{ link: pt ? '/pt/story' : '/story', label: pt ? 'Parceria' : "Partnership Framework" },
						{ link: pt ? '/pt/strategy' : '/strategy', label: pt ? 'Áreas de foco' : 'Focus Areas' },
						{ link: pt ? '/pt/leveraging-finance' : '/leveraging-finance', label: pt ? 'Alavancando o financiamento' : 'Leveraging Finance' },
						{ link: pt ? '/pt/history' : '/history', label: pt ? 'Contando a história' : 'Telling the Story' },
					]}
					backgroundImage={StrategyCover}
					backgroundPosition="0px 69%"
				/>
				<div id="strategy-container">
					<h2>{pt ? "Áreas de foco" : "Focus Areas"}</h2>
					<div className="divider"></div>
					<div className="focus-desc">
						{text.desc}
					</div>
					<div className="focus-areas-cards">
						<AltCard
							icon={PaperMoneyTwoIcon}
							title={text.focus_label_1}
							description={text.focus_title_1}
							buttonText={pt ? "Veja o programa" : "View program"}
							inverse={selected === "focus_1"}
							onClick={() => selectFocus("focus_1")}
							style={{ cursor: 'pointer' }}
							href={pt ? '/pt/portfolio' : '/portfolio'}
						/>
						<AltCard
							icon={ChartLineIcon}
							title={text.focus_label_2}
							description={text.focus_title_2}
							buttonText={pt ? "Veja o programa" : "View program"}
							inverse={selected === "focus_2"}
							onClick={() => selectFocus("focus_2")}
							style={{ cursor: 'pointer' }}
							href={pt ? '/pt/portfolio' : '/portfolio'}
						/>
						<AltCard
							icon={PureNaturalIcon}
							title={text.focus_label_3}
							description={text.focus_title_3}
							buttonText={pt ? "Veja o programa" : "View program"}
							inverse={selected === "focus_3"}
							onClick={() => selectFocus("focus_3")}
							style={{ cursor: 'pointer' }}
							href={pt ? '/pt/portfolio' : '/portfolio'}
						/>
					</div>
					<div>
						<div id="focus-desc">
							<div style={{ fontSize: '32px' }} id="focus-desc-title">
								{selected === "focus_1" ? text.focus_title_1 : selected === "focus_2" ? text.focus_title_2 : text.focus_title_3}
							</div>
							{text.focus_desc[selected].map((desc, index) => {
								let desc_text = desc;
								if (Array.isArray(desc)) {
									desc_text = desc.map((section, index) => {
										if (section.text) {
											return <a key={"section-"+index} href={section.url} target="_blank">{section.text}</a>
										} else {
											return <span key={"section-"+index}>{section}</span>
										}
									})
								}
								return <div style={{ fontSize: '18px', lineHeight: '25px' }} key={"focus-"+index} className="focus-paragraph">{desc_text}</div>
							})}
						</div>
					</div>
					<div>
						<div id="objective-columns">
							{selected != null ? text[selected].map((objective, index) => {
								return (
									<div key={objective.title} className="objective-card">
										<h3 style={{ fontSize: '28px' }}>{objective.description}</h3>
										<div className="objective-card-footer">
											<span style={{ fontSize: '18px', color: '#262626' }}>{pt ? `Objetivo ${objective.title}` : `Objective ${objective.title}`}</span>
											<AltButton href={pt ? '/pt/indicators' : '/indicators'} style={{ fontSize: '18px' }}>{text.viewresults}</AltButton>
										</div>
									</div>
								)
							}) : null}
						</div>
					</div>
				</div>

				<div style={{ height: '144px' }}></div>

				<WelcomeFooter {...props} />
			</div>
		</div>
	);
}

export default withRouter(Strategy);
