import React from 'react'
import propTypes from 'prop-types'
import classNames from 'classnames'
import styles from './styles.module.css'


const Card = ({ children, className, largeContent, ...props }) => {
 const cardClasses = classNames(className, styles.card, {
  [styles.largeContent]:largeContent 
 });
 return(<div className={cardClasses} {...props}>
    {children}
  </div>
)}

Card.propTypes = {
  children: propTypes.node,
  className: propTypes.string,
  largeContent: propTypes.bool
}

Card.defaultProps = {
  children: null,
  className: '',
  largeContent: false
}

export default Card
