import React, { useState } from 'react';
import numeral from 'numeral';
import classnames from 'classnames';
import './BenchmarkingHeaderTriple.css';
import closeIcon from '../img/fill-1.svg';
import CountryModal from '../../common/modals/CountryModal';
import styles from './benchmarking.module.css';

const textEnglish = {
  add: 'ADD COUNTRY',
};

const textPortuguese = {
  add: 'ADICIONAR PAÍS',
};

const BenchmarkingHeaderTriple = (props) => {
  const [showCountryModal, setShowCountryModal] = useState(false);

  const removeCountry = (countryId) => {
    props.removeCountry(countryId);
  };

  const changeCountry = () => {
    setShowCountryModal(true);
  };

  const closeCountryModal = () => {
    setShowCountryModal(false);
  };

  const formatValue = (value, formatter) => {
    if (formatter === 'pt' && value) {
      return value + 'º Lugar';
    }
    if (value) {
      return numeral(value).format(formatter);
    } else {
      return '--';
    }
  };

  let pt = false;
  let text = {};
  if (props.location.pathname.substring(0, 4) !== '/pt/') {
    text = textEnglish;
  } else {
    pt = true;
    text = textPortuguese;
  }

  let length = props.wdiData[props.countryOrder[0].iso3].indicators['SP.POP.TOTL'].data.length;
  let mostRecentPop = props.wdiData[props.countryOrder[0].iso3].indicators['SP.POP.TOTL'].data[length - 1].value;
  length = props.wdiData[props.countryOrder[0].iso3].indicators['NY.GDP.MKTP.CD'].data.length;
  let mostRecentGDP = props.wdiData[props.countryOrder[0].iso3].indicators['NY.GDP.MKTP.CD'].data[length - 1].value;

  let mostRecentPop2 = null;
  if (props.wdiData[props.countryOrder[1].iso3].indicators['SP.POP.TOTL'] !== undefined) {
    length = props.wdiData[props.countryOrder[1].iso3].indicators['SP.POP.TOTL'].data.length;
    if (length > 0) {
      mostRecentPop2 = props.wdiData[props.countryOrder[1].iso3].indicators['SP.POP.TOTL'].data[length - 1].value;
    }
  }
  let mostRecentGDP2 = null;
  if (props.wdiData[props.countryOrder[1].iso3].indicators['NY.GDP.MKTP.CD'] !== undefined) {
    length = props.wdiData[props.countryOrder[1].iso3].indicators['NY.GDP.MKTP.CD'].data.length;
    if (length > 0) {
      mostRecentGDP2 = props.wdiData[props.countryOrder[1].iso3].indicators['NY.GDP.MKTP.CD'].data[length - 1].value;
    }
  }

  let mostRecentPop3 = null;
  if (props.wdiData[props.countryOrder[2].iso3].indicators['SP.POP.TOTL'] !== undefined) {
    length = props.wdiData[props.countryOrder[2].iso3].indicators['SP.POP.TOTL'].data.length;
    if (length > 0) {
      mostRecentPop3 = props.wdiData[props.countryOrder[2].iso3].indicators['SP.POP.TOTL'].data[length - 1].value;
    }
  }
  let mostRecentGDP3 = null;
  if (props.wdiData[props.countryOrder[2].iso3].indicators['NY.GDP.MKTP.CD'] !== undefined) {
    length = props.wdiData[props.countryOrder[2].iso3].indicators[
      'NY.GDP.MKTP.CD'
    ].data.length;
    if (length > 0) {
      mostRecentGDP3 = props.wdiData[props.countryOrder[2].iso3]
        .indicators['NY.GDP.MKTP.CD'].data[length - 1].value;
    }
  }

  return (
    <div>
      {/* BENCHMARKING WRAPPER */}
      <div className={styles.sectionWrapper}>
        <div className="container">
          {/* BENCHMARKING TITLE */}
          <h3 className={styles.title}>Compare o Brasil com outros Países</h3>
          {/* BENCHMARKING ROW */}
          <div className={styles.countriesWrapper}>
            <div className={styles.country}>
              {/* Flag */}
              <div
                className={classnames(
                  'flag-icon',
                  `flag-icon-${props.countryOrder[0].iso2.toLowerCase()}`,
                  styles.flag
                )}
              />

              {/* Country Name */}
              <h2 className={styles.countryName}>
                {pt
                  ? props.country_translation[
                      props.wdiData[props.countryOrder[0].iso3]
                        .country_name
                    ]
                  : props.wdiData[props.countryOrder[0].iso3]
                      .country_name}
              </h2>

              {/* Population Total */}
              <div>
                <p className={styles.label}>
                  {
                    props.wdiData[props.countryOrder[0].iso3]
                      .indicators['SP.POP.TOTL'][pt ? 'name_pt' : 'name']
                  }
                </p>
                <p className={styles.value}>
                  {formatValue(mostRecentPop, '0.0a')}
                </p>
              </div>
              {/* GDP */}
              <div>
                <p className={styles.label}>
                  {
                    props.wdiData[props.countryOrder[0].iso3]
                      .indicators['NY.GDP.MKTP.CD'][pt ? 'name_pt' : 'name']
                  }
                </p>

                <p className={styles.value}>
                  {formatValue(mostRecentGDP, '0.0a')}
                </p>
              </div>
            </div>
            <div className={styles.country}>
              {/* Flag */}
              <div
                className={classnames(
                  'flag-icon',
                  `flag-icon-${props.countryOrder[1].iso2.toLowerCase()}`,
                  styles.flag
                )}
              >
                <button
                  className={styles.removeCountryButton}
                  onClick={() =>
                    removeCountry(props.countryOrder[1].iso3)
                  }
                >
                  <img src={closeIcon} alt="remove country" />
                </button>
              </div>

              {/* Country Name */}
              <h2 className={styles.countryName}>
                {pt
                  ? props.country_translation[
                      props.wdiData[props.countryOrder[1].iso3]
                        .country_name
                    ]
                  : props.wdiData[props.countryOrder[1].iso3]
                      .country_name}
              </h2>

              {/* Population Total */}
              <div>
                <p className={styles.label}>
                  {
                    props.wdiData[props.countryOrder[1].iso3]
                      .indicators['SP.POP.TOTL'][pt ? 'name_pt' : 'name']
                  }
                </p>
                <p className={styles.value}>
                  {formatValue(mostRecentPop2, '0.0a')}
                </p>
              </div>
              {/* GDP */}
              <div>
                <p className={styles.label}>
                  {
                    props.wdiData[props.countryOrder[1].iso3]
                      .indicators['NY.GDP.MKTP.CD'][pt ? 'name_pt' : 'name']
                  }
                </p>

                <p className={styles.value}>
                  {formatValue(mostRecentGDP2, '0.0a')}
                </p>
              </div>
            </div>
            <div className={styles.country}>
              {/* Flag */}
              <div
                className={classnames(
                  'flag-icon',
                  `flag-icon-${props.countryOrder[2].iso2.toLowerCase()}`,
                  styles.flag
                )}
              >
                <button
                  className={styles.removeCountryButton}
                  onClick={() =>
                    removeCountry(props.countryOrder[2].iso3)
                  }
                >
                  <img src={closeIcon} alt="remove country" />
                </button>
              </div>

              {/* Country Name */}
              <h2 className={styles.countryName}>
                {pt
                  ? props.country_translation[
                      props.wdiData[props.countryOrder[2].iso3]
                        .country_name
                    ]
                  : props.wdiData[props.countryOrder[2].iso3]
                      .country_name}
              </h2>

              {/* Population Total */}
              <div>
                <p className={styles.label}>
                  {
                    props.wdiData[props.countryOrder[2].iso3]
                      .indicators['SP.POP.TOTL'][pt ? 'name_pt' : 'name']
                  }
                </p>
                <p className={styles.value}>
                  {formatValue(mostRecentPop3, '0.0a')}
                </p>
              </div>
              {/* GDP */}
              <div>
                <p className={styles.label}>
                  {
                    props.wdiData[props.countryOrder[1].iso3]
                      .indicators['NY.GDP.MKTP.CD'][pt ? 'name_pt' : 'name']
                  }
                </p>

                <p className={styles.value}>
                  {formatValue(mostRecentGDP3, '0.0a')}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CountryModal
        showing={showCountryModal}
        closeCountryModal={closeCountryModal}
        country1={props.countryOrder[1].iso2}
        notNavbar={true}
        {...props}
      />
    </div>
  );
};

export default BenchmarkingHeaderTriple;
