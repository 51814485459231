import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { DropdownButton, MenuItem } from 'react-bootstrap';
import axios from 'axios';

import '../evidence/Evidence.css'

import GDILogo from '../evidence/img/gdi-logo.png';

import positiveIcon from '../evidence/img/positive-icon.svg';
import negativeIcon from '../evidence/img/negative-icon.svg';
import undefinedIcon from '../evidence/img/undefined-icon.svg';
import {API_URL} from "../../../config/api";

const textEnglish = {
	"login": "Login",
	"logout": "Log Out",
	"med": "Evidence",
	"desc": "Lorem Ipsum dolor quantum soremis nullaram ultra quantum ulramir nulari auro.",
	"page_desc": "To enhance development effectiveness, impact evaluations provide concrete, attributable information on the impact of specific project interventions on social or economic indicators. This page accumulates insights gathered from impact evaluations conducted in Brazil and the world. Caution is required, however, in generalizing the results from individual studies to different contexts (‘external validity’ in technical jargon). External validity is highest for projects examined through meta-analyses by AidGrade, an NGO, and listed under ‘what works in the world’.",
	"page_desc_sub": "To understand challenges in delivering projects, explore ‘evidence on implementation constraints’.",

	"impact_tab": "Impact Evaluations",

	"what_works": "What works",
	"brazil_tab": "in Brazil",
	"aidgrade_tab": "in the World",
	"brazil_instruct": "“What Works in Brazil” gathers results from impact evaluations carried out in various areas, including human development and private sector development policies. The list covers assessments conducted in collaboration with the World Bank team, as well as independent research. When using it, caution is recommended in generalizing the results, especially with respect to sub-national programs.",

	"required": "Fields marked with * are required.",
	"add_assessment": "Add New Assessment",
	"impact_sector_text": "Select Sector",
	"impact_policy_text": "Select Policy",
	"impact_mechanism_text": "Select Mechanism",
	"impact_outcome_text": "Select Outcome",
	"choose":"Choose Filters",
	"impact_random_yes": "Randomized",
	"impact_random_no": "Not Randomized",
	"impact_random_both": "Either",
	"submit": "Submit",
	"reset": "Reset",
	"noresults": "No Results",
	"impact_assessment_key": {'1': "Positive", '2': "Negative", '3': "Undefined"},
	"authors": "Author(s)",
	"assessment": "Assessment",
	"policy_singular": "Policy",
	"policy_plural": "Policies",
	"mechanism_singular": "Mechanism",
	"mechanism_plural": "Mechanisms",
	"outcome_singular": "Outcome",
	"outcome_plural": "Outcomes",
	"outcome_icon": [positiveIcon, negativeIcon, undefinedIcon],

	"aidgrade": "AidGrade",
	"program": "Select Program",
	"outcome": "Select Outcome",
	"effects": "Effects",
	"method": "What was the method used?",
	"random_yes": "Randomized",
	"random_no": "Not Randomized",
	"random_both": "Either",
	"blinded":"Was the study blinded?",
	"blinded_yes":"Blinded",
	"blinded_no":"Not Blinded",
	"blinded_both":"Either",
	"fixed":"Fixed or random effects?",
	"fixed_yes":"Fixed effects",
	"fixed_no":"Random effects",

	"gdi_tab": "Lições da Implementação de Projetos",
	"gdi_desc": "DeCODE is an evidence-based system that uses historical project data and advanced text-analytic techniques to help practitioners anticipate, analyze, and address delivery challenges. DeCODE is powered by thousands of projects from multiple GDI partners spanning the last 20 years. DeCODE is a product of the Global Delivery Initiative, a partnership of over 50 development organizations, focused on building an evidence base on what works and what doesn’t in the implementation of development interventions and reforms.",
	"gdi_instruct": "To explore Brazil’s delivery challenges, select a project sector and click DeCODE. A new tab will open at ",
	"gdi_link": "http://globaldeliveryinitiative.org/",
	// "gdi_credit": "Powered by the Global Delivery Initiative, a partnership of over 50 development organization, working to build evidence base and faciliated and enable more effective implementation of development programs.",
	"gdi_drop": "Select Project Sector",
	"gdi_button": "DeCODE",
	"gdi_country": "Brazil",
	"gdi_org": "All Organizations",
	"gdi_select_sector": "Select Sector",
	"gdi_select_country": "Country",
	"gdi_select_organization": "Organization",
	"gdi_data": [
		{
			"title": "Water",
			"url": "http://www.globaldeliveryinitiative.org/decode/time-allocation-or-task-sequencing?country=Brazil&sector=Water&org=all"
		},
		{
			"title": "Education",
			"url": "http://www.globaldeliveryinitiative.org/decode/reporting-and-supervision?country=Brazil&sector=Education&org=all"
		},
		{
			"title": "Environment and Natural Resource",
			"url": "http://www.globaldeliveryinitiative.org/decode/reporting-and-supervision?country=Brazil&sector=Environment%20and%20Natural%20Resources&org=all"
		},
		{
			"title": "Finance and Markets",
			"url": "http://www.globaldeliveryinitiative.org/decode/reporting-and-supervision?country=Brazil&sector=Finance%20and%20Markets&org=all"
		},
		{
			"title": "Trade and Competitiveness",
			"url": "http://www.globaldeliveryinitiative.org/decode/reporting-and-supervision?country=Brazil&sector=Trade%20and%20Competitiveness&org=all"
		},
		{
			"title": "Governance",
			"url": "http://www.globaldeliveryinitiative.org/decode/time-allocation-or-task-sequencing?country=Brazil&sector=Governance&org=all"
		},
		{
			"title": "Energy and Extracatives",
			"url": "http://www.globaldeliveryinitiative.org/decode/time-allocation-or-task-sequencing?country=Brazil&sector=Energy%20and%20Extractives&org=all"
		},
		{
			"title": "Health, Nutrition, and Population",
			"url": "http://www.globaldeliveryinitiative.org/decode/reporting-and-supervision?country=Brazil&sector=Health,%20Nutrition,%20and%20Population&org=all"
		},
		{
			"title": "Agriculture",
			"url": "http://www.globaldeliveryinitiative.org/decode/stakeholder-engagement?country=Brazil&sector=Agriculture&org=all"
		},
		{
			"title": "Transport and ICT",
			"url": "http://www.globaldeliveryinitiative.org/decode/time-allocation-or-task-sequencing?country=Brazil&sector=Transport%20and%20ICT&org=all"
		},
		{
			"title": "Social, Urban, Rural and Resilience",
			"url": "http://www.globaldeliveryinitiative.org/decode/reporting-and-supervision?country=Brazil&sector=Social,%20Urban,%20Rural%20and%20Resilience&org=all"
		},
		{
			"title": "Social Protection and Labor",
			"url": "http://www.globaldeliveryinitiative.org/decode/reporting-and-supervision?country=Brazil&sector=Social%20Protection%20and%20Labor&org=all"
		},
		{
			"title": "Fragile, Conflict & Violence",
			"url": "http://www.globaldeliveryinitiative.org/decode/reporting-and-supervision?country=Brazil&sector=Fragile,%20Conflict%20_and_%20Violence&org=all"
		},
		{
			"title": "Macro Economics and Fiscal Management",
			"url": "http://www.globaldeliveryinitiative.org/decode/reporting-and-supervision?country=Brazil&sector=Macro%20Economics%20and%20Fiscal%20Management&org=all"
		},
		{
			"title": "Poverty and Equity",
			"url": "http://www.globaldeliveryinitiative.org/decode/reporting-and-supervision?country=Brazil&sector=Poverty%20and%20Equity&org=all"
		}
	]
}

const textPortuguese = {
	"login": "Login",
	"logout": "Log Out",
	"med": "Evidências",
	"desc": "Lorem Ipsum dolor quantum soremis nullaram ultra quantum ulramir nulari auro.",
	"page_desc": "Para aumentar a eficácia do desenvolvimento, as avaliações de impacto oferecem informações concretas e atribuíveis sobre o impacto de intervenções específicas do projeto em indicadores sociais ou econômicos. Esta página reúne conclusões extraídas das avaliações de impacto realizadas no Brasil e no mundo. Porém, é necessário tomar cuidado ao generalizar os resultados de estudos individuais para diferentes contextos (a “validade externa” no jargão técnico). A validade externa é mais alta nos projetos examinados por meio de meta-análises realizadas pela AidGrade, uma ONG, e constantes na lista “O que funciona no mundo”.",
	"page_desc_sub": "Para entender os desafios na consecução de projetos, explore as “evidências sobre restrições de implementação”.",

	"impact_tab": "Avaliações de impacto",

	"what_works": "O que funciona",
	"brazil_tab": "no Brasil",
	"aidgrade_tab": "no mundo",
	"brazil_instruct": "“O que funciona no Brasil” reúne resultados de avaliações de impacto realizadas em várias áreas, incluindo políticas de desenvolvimento humano e de desenvolvimento do setor privado. A lista abrange avaliações realizadas em colaboração com a equipe do Banco Mundial, além de pesquisas independentes. Ao usá-la, recomenda-se cautela na generalização dos resultados, especialmente em relação a programas subnacionais.",

	"required": "Os campos marcados com * são obrigatórios.",
	"add_assessment": "Add New Assessment",
	"impact_sector_text": "Selecione o Setor",
	"impact_policy_text": "Selecione a Política",
	"impact_mechanism_text": "Selecione o Mecanismo",
	"impact_outcome_text": "Selecione o Resultado",
	"choose": "Escolha os Filtros",
	"impact_random_yes": "Randomizado",
	"impact_random_no": "Não Randomizado",
	"impact_random_both": "Qualquer Um",
	"submit": "Enviar",
	"reset": "Redefinir",
	"noresults": "Sem Resultados",
	"impact_assessment_key": {'1': "Positiva", '2': "Negativa", '3': "Indefinida"},
	"authors": "Autor(es)",
	"assessment": "Avaliação",
	"policy_singular": "Política",
	"policy_plural": "Políticas",
	"mechanism_singular": "Mecanismo",
	"mechanism_plural": "Mecanismos",
	"outcome_singular": "Resultado",
	"outcome_plural": "Resultados",
	"outcome_icon": [positiveIcon, negativeIcon, undefinedIcon],

	"aidgrade": "AidGrade",
	"program": "Selecionar o Programa",
	"outcome": "Selecionar o Resultado",
	"effects": "Efeitos",
	"method": "Qual foi o método usado?",
	"random_yes": "Randomizado",
	"random_no": "Não Randomizado",
	"random_both": "Qualquer Um",
	"blinded":"O estudo foi cego?",
	"blinded_yes":"Cego",
	"blinded_no":"Não-Cego",
	"blinded_both":"Qualquer Um",
	"fixed":"Efeitos fixos ou aleatórios?",
	"fixed_yes":"Efeitos fixos",
	"fixed_no":"Efeitos aleatórios",

	"gdi_tab": "Lições da Implementação de Projetos",
	"gdi_desc": "O DeCODE é um sistema baseado em evidências que usa dados históricos de projetos e técnicas avançadas de análise de texto para ajudar os profissionais a antecipar, analisar e resolver os desafios de implementação. O DeCODE é alimentado por milhares de projetos de vários parceiros da GDI dos últimos 20 anos. O DeCODE é um produto da Global Delivery Initiative, uma parceria de mais de 50 organizações de desenvolvimento focada na construção de uma base de evidências sobre o que funciona e o que não funciona na implementação de intervenções e reformas de desenvolvimento.",
	"gdi_instruct": "Para explorar os desafios de realização do Brasil, selecione um setor de projeto e clique em DeCODE. Uma nova aba será aberta e direcionada para ",
	"gdi_link": "http://globaldeliveryinitiative.org/",
	// "gdi_credit": "Powered by the Global Delivery Initiative, a partnership of over 50 development organization, working to build evidence base and faciliated and enable more effective implementation of development programs.",
	"gdi_drop": "Selecionar o Setor",
	"gdi_button": "DeCODE",
	"gdi_country": "Brasil",
	"gdi_org": "Todas as Organizações",
	"gdi_select_sector": "Selecionar o Setor",
	"gdi_select_country": "País",
	"gdi_select_organization": "Organização",
	"gdi_data": [
		{
			"title": "Recursos Hídricos",
			"url": "http://www.globaldeliveryinitiative.org/decode/time-allocation-or-task-sequencing?country=Brazil&sector=Water&org=all"
		},
		{
			"title": "Educação",
			"url": "http://www.globaldeliveryinitiative.org/decode/reporting-and-supervision?country=Brazil&sector=Education&org=all"
		},
		{
			"title": "Meio Ambiente e Recursos Naturais",
			"url": "http://www.globaldeliveryinitiative.org/decode/reporting-and-supervision?country=Brazil&sector=Environment%20and%20Natural%20Resources&org=all"
		},
		{
			"title": "Finanças e Mercados",
			"url": "http://www.globaldeliveryinitiative.org/decode/reporting-and-supervision?country=Brazil&sector=Finance%20and%20Markets&org=all"
		},
		{
			"title": "Comércio e Competitividade",
			"url": "http://www.globaldeliveryinitiative.org/decode/reporting-and-supervision?country=Brazil&sector=Trade%20and%20Competitiveness&org=all"
		},
		{
			"title": "Governança",
			"url": "http://www.globaldeliveryinitiative.org/decode/time-allocation-or-task-sequencing?country=Brazil&sector=Governance&org=all"
		},
		{
			"title": "Energia e Extrativos",
			"url": "http://www.globaldeliveryinitiative.org/decode/time-allocation-or-task-sequencing?country=Brazil&sector=Energy%20and%20Extractives&org=all"
		},
		{
			"title": "Saúde, Nutrição e População",
			"url": "http://www.globaldeliveryinitiative.org/decode/reporting-and-supervision?country=Brazil&sector=Health,%20Nutrition,%20and%20Population&org=all"
		},
		{
			"title": "Agricultura",
			"url": "http://www.globaldeliveryinitiative.org/decode/stakeholder-engagement?country=Brazil&sector=Agriculture&org=all"
		},
		{
			"title": "Transportes e TIC",
			"url": "http://www.globaldeliveryinitiative.org/decode/time-allocation-or-task-sequencing?country=Brazil&sector=Transport%20and%20ICT&org=all"
		},
		{
			"title": "Resiliência Social, Urbana e Rural",
			"url": "http://www.globaldeliveryinitiative.org/decode/reporting-and-supervision?country=Brazil&sector=Social,%20Urban,%20Rural%20and%20Resilience&org=all"
		},
		{
			"title": "Proteção Social e Trabalho",
			"url": "http://www.globaldeliveryinitiative.org/decode/reporting-and-supervision?country=Brazil&sector=Social%20Protection%20and%20Labor&org=all"
		},
		{
			"title": "Fragilidade, Conflito & Violência",
			"url": "http://www.globaldeliveryinitiative.org/decode/reporting-and-supervision?country=Brazil&sector=Fragile,%20Conflict%20_and_%20Violence&org=all"
		},
		{
			"title": "Macroeconomia e Gestão Fiscal",
			"url": "http://www.globaldeliveryinitiative.org/decode/reporting-and-supervision?country=Brazil&sector=Macro%20Economics%20and%20Fiscal%20Management&org=all"
		},
		{
			"title": "Pobreza e Equidade",
			"url": "http://www.globaldeliveryinitiative.org/decode/reporting-and-supervision?country=Brazil&sector=Poverty%20and%20Equity&org=all"
		}
	]
}

class Evidence extends Component {
	constructor(props) {
		super(props);
		let selected_tab = 0;
		if (props.location) {
			if (props.location.state) {
				if (props.location.state.selected_tab) {
					selected_tab = props.location.state.selected_tab;
				}
			}
		}
		this.state = {
			selected_tab: selected_tab,
			selected_index: 0,
			selected_bow: "brazil",

			impact_evaluations: null,
			impact_sector: null,
			impact_policy: null,
			impact_mechanism: null,
			impact_outcome: null,
			impact_random: 2,
			impact_sectors: [],
			impact_policies: [],
			impact_mechanisms: [],
			impact_outcomes: [],
			impact_display: [],
			impact_error: "",
			impact_show: false,

			impact_sectors_en: [],
			impact_policies_en: [],
			impact_mechanisms_en: [],
			impact_outcomes_en: [],
			impact_sectors_pt: [],
			impact_policies_pt: [],
			impact_mechanisms_pt: [],
			impact_outcomes_pt: [],

			showModal: false
		};

        this.handleLogout = this.handleLogout.bind(this);
        this.selectSector = this.selectSector.bind(this);

        this.impactSector = this.impactSector.bind(this);
        this.impactPolicy = this.impactPolicy.bind(this);
        this.impactMechanism = this.impactMechanism.bind(this);
        this.impactOutcome = this.impactOutcome.bind(this);
        this.impactRandom = this.impactRandom.bind(this);
        this.showImpactEvals = this.showImpactEvals.bind(this);
    }

	handleLogout() {
		localStorage.removeItem('auth');
		this.props.history.push('/');
	}

	componentDidMount(){
		window.scrollTo(0,0);
		axios.get(`${API_URL}/impact_eval`).then((response) => {
            let sectors = [];
            let policies = [];
            let mechanisms = [];
            let outcomes = [];

            let sectors_pt = [];
            let policies_pt = [];
            let mechanisms_pt = [];
            let outcomes_pt = [];
            for (let i = 0; i < response.data.length; i++){
            	for (let sector in response.data[i].sectors) {
            		if (sectors.indexOf(response.data[i].sectors[sector]) < 0){
            			sectors.push(response.data[i].sectors[sector])
            		}
            	}
            	for (let policy in response.data[i].policies) {
            		if (policies.indexOf(response.data[i].policies[policy]) < 0){
            			policies.push(response.data[i].policies[policy])
            		}
            	}
            	for (let mechanism in response.data[i].mechanisms) {
            		if (mechanisms.indexOf(response.data[i].mechanisms[mechanism]) < 0){
            			mechanisms.push(response.data[i].mechanisms[mechanism])
            		}
            	}
            	for (let outcome in Object.keys(response.data[i].outcomes)) {
            		if (outcomes.indexOf(Object.keys(response.data[i].outcomes)[outcome]) < 0){
            			outcomes.push(Object.keys(response.data[i].outcomes)[outcome])
            		}
            	}

            	for (let sector in response.data[i].sectors_pt) {
            		if (sectors_pt.indexOf(response.data[i].sectors_pt[sector]) < 0){
            			sectors_pt.push(response.data[i].sectors_pt[sector])
            		}
            	}
            	for (let policy in response.data[i].policies_pt) {
            		if (policies_pt.indexOf(response.data[i].policies_pt[policy]) < 0){
            			policies_pt.push(response.data[i].policies_pt[policy])
            		}
            	}
            	for (let mechanism in response.data[i].mechanisms_pt) {
            		if (mechanisms_pt.indexOf(response.data[i].mechanisms_pt[mechanism]) < 0){
            			mechanisms_pt.push(response.data[i].mechanisms_pt[mechanism])
            		}
            	}
            	for (let outcome in Object.keys(response.data[i].outcomes_pt)) {
            		if (outcomes_pt.indexOf(Object.keys(response.data[i].outcomes_pt)[outcome]) < 0){
            			outcomes_pt.push(Object.keys(response.data[i].outcomes_pt)[outcome])
            		}
            	}
            }
            // // console.log(sectors, policies, mechanisms, outcomes);
            let pt = this.props.location.pathname.substring(0,4) === "/pt/";
            this.setState({
            	impact_evaluations: response.data,
            	impact_sectors: pt ? sectors_pt : sectors,
				impact_policies: pt ? policies_pt : policies,
				impact_mechanisms: pt ? mechanisms_pt : mechanisms,
				impact_outcomes: pt ? outcomes_pt : outcomes,
            	impact_sectors_en: sectors,
				impact_policies_en: policies,
				impact_mechanisms_en: mechanisms,
				impact_outcomes_en: outcomes,
				impact_sectors_pt: sectors_pt,
				impact_policies_pt: policies_pt,
				impact_mechanisms_pt: mechanisms_pt,
				impact_outcomes_pt: outcomes_pt
            })
        }).catch((err) => {
            // console.log(err.message);
        });
	}

	selectSector(e) {
		// // console.log(e);
		this.setState({
			selected_index: e
		})
	}

	impactSector(e) {
		// // console.log(e);
		let evals = this.state.impact_evaluations;
		let policies = [];
		let mechanisms = [];
		let outcomes = [];
		let pt = this.props.location.pathname.substring(0,4) === "/pt/";
		for (let i = 0; i < evals.length; i++){
	    	if (evals[i][pt ? 'sectors_pt' : 'sectors'].indexOf(this.state.impact_sectors[e]) > -1) {
    			for (let policy in evals[i][pt ? 'policies_pt' : 'policies']) {
		    		if (policies.indexOf(evals[i][pt ? 'policies_pt' : 'policies'][policy]) < 0){
		    			policies.push(evals[i][pt ? 'policies_pt' : 'policies'][policy])
		    		}
		    	}
		    	for (let mechanism in evals[i][pt ? 'mechanisms_pt' : 'mechanisms']) {
		    		if (mechanisms.indexOf(evals[i][pt ? 'mechanisms_pt' : 'mechanisms'][mechanism]) < 0){
		    			mechanisms.push(evals[i][pt ? 'mechanisms_pt' : 'mechanisms'][mechanism])
		    		}
		    	}
		    	for (let outcome in Object.keys(evals[i][pt ? 'outcomes_pt' : 'outcomes'])) {
		    		if (outcomes.indexOf(Object.keys(evals[i][pt ? 'outcomes_pt' : 'outcomes'])[outcome]) < 0){
		    			outcomes.push(Object.keys(evals[i][pt ? 'outcomes_pt' : 'outcomes'])[outcome])
		    		}
		    	}
    		}
	    	
	    }
		this.setState({
			impact_sector: e,
			impact_policies: policies,
			impact_policy: null,
			impact_mechanisms: mechanisms,
			impact_mechanism: null,
			impact_outcomes: outcomes,
			impact_outcome: null
		})
	}

	impactPolicy(e) {
		// // console.log(e);
		let evals = this.state.impact_evaluations;
		let mechanisms = [];
		let outcomes = [];
		let pt = this.props.location.pathname.substring(0,4) === "/pt/";
		for (let i = 0; i < evals.length; i++){
	    	if (evals[i][pt ? 'sectors_pt' : 'sectors'].indexOf(this.state.impact_sectors[this.state.impact_sector]) > -1 && evals[i][pt ? 'policies_pt' : 'policies'].indexOf(this.state.impact_policies[e]) > -1) { //if we specify ALL fields must be selected, make sure to check previous levels as well. (also make sure dropdowns are disabled until higher levels are selected)
		    	for (let mechanism in evals[i][pt ? 'mechanisms_pt' : 'mechanisms']) {
		    		if (mechanisms.indexOf(evals[i][pt ? 'mechanisms_pt' : 'mechanisms'][mechanism]) < 0){
		    			mechanisms.push(evals[i][pt ? 'mechanisms_pt' : 'mechanisms'][mechanism])
		    		}
		    	}
		    	for (let outcome in Object.keys(evals[i][pt ? 'outcomes_pt' : 'outcomes'])) {
		    		if (outcomes.indexOf(Object.keys(evals[i][pt ? 'outcomes_pt' : 'outcomes'])[outcome]) < 0){
		    			outcomes.push(Object.keys(evals[i][pt ? 'outcomes_pt' : 'outcomes'])[outcome])
		    		}
		    	}
    		}
	    	
	    }
		this.setState({
			impact_policy: e,
			impact_mechanisms: mechanisms,
			impact_mechanism: null,
			impact_outcomes: outcomes,
			impact_outcome: null
		})
	}

	impactMechanism(e) {
		// // console.log(e);
		let evals = this.state.impact_evaluations;
		let outcomes = [];
		let pt = this.props.location.pathname.substring(0,4) === "/pt/";
		for (let i = 0; i < evals.length; i++){
	    	if (evals[i][pt ? 'sectors_pt' : 'sectors'].indexOf(this.state.impact_sectors[this.state.impact_sector]) > -1 && evals[i][pt ? 'policies_pt' : 'policies'].indexOf(this.state.impact_policies[this.state.impact_policy]) > -1 && evals[i][pt ? 'mechanisms_pt' : 'mechanisms'].indexOf(this.state.impact_mechanisms[e]) > -1) { //if we specify ALL fields must be selected, make sure to check previous levels as well. (also make sure dropdowns are disabled until higher levels are selected)
		    	for (let outcome in Object.keys(evals[i][pt ? 'outcomes_pt' : 'outcomes'])) {
		    		if (outcomes.indexOf(Object.keys(evals[i][pt ? 'outcomes_pt' : 'outcomes'])[outcome]) < 0){
		    			outcomes.push(Object.keys(evals[i][pt ? 'outcomes_pt' : 'outcomes'])[outcome])
		    		}
		    	}
    		}
	    }
		this.setState({
			impact_mechanism: e,
			impact_outcomes: outcomes,
			impact_outcome: null
		})
	}

	impactOutcome(e) {
		// // console.log(e);
		this.setState({
			impact_outcome: e
		})
	}

	impactRandom(e) {
		// // console.log(e);
		this.setState({
			impact_random: e
		})
	}

	showImpactEvals() {
		if (this.state.impact_sector === null /*|| this.state.impact_policy === null || this.state.impact_mechanism === null || this.state.impact_outcome === null*/) {
			this.setState({
				impact_error: "Error: At least the Sector dropdown must have a selection in order to display impact evaluations",
				impact_display: [],
				impact_show: false
			});
		} else {
			let impact_display = [];
			let all_evals = this.state.impact_evaluations;
			let pt = this.props.location.pathname.substring(0,4) === "/pt/";
			for (let i = 0; i < all_evals.length; i++) {
				let impact_eval = all_evals[i];
				let check = true;
				if (impact_eval[pt ? 'sectors_pt' : 'sectors'].indexOf(this.state.impact_sectors[this.state.impact_sector]) < 0) {
					check = false;
				}
				if (this.state.impact_policy !== null && impact_eval[pt ? 'policies_pt' : 'policies'].indexOf(this.state.impact_policies[this.state.impact_policy]) < 0) {
					check = false;
				}
				if (this.state.impact_mechanism !== null && impact_eval[pt ? 'mechanisms_pt' : 'mechanisms'].indexOf(this.state.impact_mechanisms[this.state.impact_mechanism]) < 0) {
					check = false;
				}
				if (this.state.impact_outcome !== null && Object.keys(impact_eval[pt ? 'outcomes_pt' : 'outcomes']).indexOf(this.state.impact_outcomes[this.state.impact_outcome]) < 0) {
					check = false;
				}
				let isRandom = this.state.impact_random === 0 ? true : false;
				if (impact_eval.randomized !== isRandom && this.state.impact_random !== 2) {
					check = false;
				}
				if (check) {
					impact_display.push(impact_eval);
				}
			}
			this.setState({
				impact_error: "",
				impact_display: impact_display,
				impact_show: true
			});
		}
	}

	componentWillMount() {
	    this.unlisten = this.props.history.listen((location, action) => {
	    	let pt = location.pathname.substring(0,4) === "/pt/";
            this.setState({
            	impact_sectors: pt ? this.state.impact_sectors_pt : this.state.impact_sectors_en,
				impact_policies: pt ? this.state.impact_policies_pt : this.state.impact_policies_en,
				impact_mechanisms: pt ? this.state.impact_mechanisms_pt : this.state.impact_mechanisms_en,
				impact_outcomes: pt ? this.state.impact_outcomes_pt : this.state.impact_outcomes_en,
				impact_sector: null,
				impact_policy: null,
				impact_mechanism: null,
				impact_outcome: null,
				impact_show: false
	        });
	    });
	}

	componentWillUnmount() {
	    this.unlisten();
	}
		
	render() {
		// // console.log(this.state)
		let isImpactEval = this.props.location.pathname.indexOf('impact-eval') > -1;
		let isGDI = this.props.location.pathname.indexOf('gdi') > -1;

		let text = {};
		if (this.props.location.pathname.substring(0,4) !== "/pt/") {
			text = textEnglish;
		} else {
			text = textPortuguese;
		}

		return (
			<div>
				<div className="container-fluid">
					<div id="content-container" className="evidence-page">
						<div className="impact-tab-section row">
							{isImpactEval ?
								<div className="impact-eval-section-embed">
									<div className="impact-eval-color-bg col-md-12">
										<div className="impact-eval row">{text.brazil_instruct}</div>
										<div className="impact-eval-drop-section row">
											<div className="col-md-6">
												<span className="impact-eval-num">1</span><span>{text.impact_sector_text}*</span>
												<div className="impact-eval-drop special">
													<DropdownButton
													    drop={'down'}
													    title={this.state.impact_sector === null ? text.impact_sector_text : this.state.impact_sectors[this.state.impact_sector]}
													    id={'impact-eval-dropdown-sector'}
													    key={'impact-eval-sector'}
													    onSelect={(e) => this.impactSector(e)}
													>
													   	{this.state.impact_sectors.map((sector, index) => {
												    		return <MenuItem eventKey={index} className={this.state.impact_sector == index ? "active" : ""}>{sector}</MenuItem>
												    	})}
													</DropdownButton>
												</div>
											</div>
											<div className="col-md-6">
												<span className="impact-eval-num">2</span><span>{text.impact_policy_text}</span>
												<div className="impact-eval-drop special">
													<DropdownButton
													    drop={'down'}
													    title={this.state.impact_policy === null ? text.impact_policy_text : this.state.impact_policies[this.state.impact_policy]}
													    id={'impact-eval-dropdown-policy'}
													    key={'impact-eval-policy'}
													    onSelect={(e) => this.impactPolicy(e)}
													    disabled={this.state.impact_sector === null ? true : false}
													>
													   	{this.state.impact_policies.map((policy, index) => {
												    		return <MenuItem eventKey={index} className={this.state.impact_policy == index ? "active" : ""}>{policy}</MenuItem>
												    	})}
													</DropdownButton>
												</div>
											</div>
										</div>
										<div className="impact-eval-drop-section">
											<div className="col-md-6">
												<span className="impact-eval-num">3</span><span>{text.impact_mechanism_text}</span>
												<div className="impact-eval-drop special">
													<DropdownButton
													    drop={'down'}
													    title={this.state.impact_mechanism === null ? text.impact_mechanism_text : this.state.impact_mechanisms[this.state.impact_mechanism]}
													    id={'impact-eval-dropdown-mechanism'}
													    key={'impact-eval-mechanism'}
													    onSelect={(e) => this.impactMechanism(e)}
													    disabled={this.state.impact_policy === null ? true : false}
													>
													   	{this.state.impact_mechanisms.map((mechanism, index) => {
												    		return <MenuItem eventKey={index} className={this.state.impact_mechanism == index ? "active" : ""}>{mechanism}</MenuItem>
												    	})}
													</DropdownButton>
												</div>
											</div>
											<div className="col-md-6">
												<span className="impact-eval-num">4</span><span>{text.impact_outcome_text}</span>
												<div className="impact-eval-drop special">
													<DropdownButton
													    drop={'down'}
													    title={this.state.impact_outcome === null ? text.impact_outcome_text : this.state.impact_outcomes[this.state.impact_outcome]}
													    id={'impact-eval-dropdown-outcome'}
													    key={'impact-eval-outcome'}
														onSelect={(e) => this.impactOutcome(e)}
														disabled={this.state.impact_mechanism === null ? true : false}
													>
													   	{this.state.impact_outcomes.map((outcome, index) => {
												    		return <MenuItem eventKey={index} className={this.state.impact_outcome == index ? "active" : ""}>{outcome}</MenuItem>
												    	})}
													</DropdownButton>
												</div>
											</div>
										</div>
										<div className="impact-eval-drop-section row">
											<div className="impact-eval-check-section col-md-9">
												<div className="impact-eval-check-title">Choose Filters:</div>
												<div className="impact-eval-check-text" onClick={() => this.impactRandom(0)}><i className={this.state.impact_random !== 0 ? "fa fa-circle-o" : "fa fa-check-circle-o"}></i> {text.impact_random_yes}</div>
												<div className="impact-eval-check-text" onClick={() => this.impactRandom(1)}><i className={this.state.impact_random !== 1 ? "fa fa-circle-o" : "fa fa-check-circle-o"}></i> {text.impact_random_no}</div>
												<div className="impact-eval-check-text" onClick={() => this.impactRandom(2)}><i className={this.state.impact_random !== 2 ? "fa fa-circle-o" : "fa fa-check-circle-o"}></i> {text.impact_random_both}</div>
											</div>
											<div className="col-md-3">
											    <a 
											    	className="btn impact-eval-button"
											    	id="impact-eval-button" 
											    	onClick={() => this.showImpactEvals()}
											    	target="_blank"
											    >
											    	{text.submit}
											    </a>
											</div>
										</div>
										<div className="impact-eval-error-section row">
											<div className="col-md-12">
												{text.required}
											</div>
										</div>
										<div className="impact-eval-error-section row">
											<div className="col-md-12">
												{this.state.impact_error}
											</div>
										</div>
									</div>
									<div className="impact-eval-display-section row">
										<div className="col-md-12">
											{
												this.state.impact_show ? 
													this.state.impact_display.length === 0 ? 
														
														<div className="impact-eval-noresults">{text.noresults}</div>

														:

														this.state.impact_display.map((evaluation, index) => {
															// // console.log(evaluation);
															let pt = this.props.location.pathname.substring(0,4) === "/pt/";
															let outcome = this.state.impact_outcomes[this.state.impact_outcome];
															if (outcome === null || outcome === undefined || Object.keys(evaluation[pt ? 'outcomes_pt' : 'outcomes']).indexOf(this.state.impact_outcome) < 0) {
																outcome = Object.keys(evaluation[pt ? 'outcomes_pt' : 'outcomes'])[0];
															}
															let assessment = text.impact_assessment_key[evaluation[pt ? 'outcomes_pt' : 'outcomes'][outcome]];
															return (<div className="impact-eval-display row">
																		<div className="col-md-8">
																			<div><a href={evaluation.link} target="_blank">{evaluation.paper_title}</a></div>
																			<div>{text.authors}: {evaluation.paper_authors.join(', ')}</div>
																			<div>{evaluation.paper_published_place}, {evaluation.paper_published_year}</div>
																			<div>{evaluation.policies.length === 1 ? text.policy_singular : text.policy_plural}: {evaluation[pt ? 'policies_pt' : 'policies'].join(', ')}</div>
																			<div>{evaluation.mechanisms.length === 1 ? text.mechanism_singular : text.mechanism_plural}: {evaluation[pt ? 'mechanisms_pt' : 'mechanisms'].join(', ')}</div>
																		</div>
																		<div className="col-md-4">
																			<div className={"impact-outcome-"+evaluation[pt ? 'outcomes_pt' : 'outcomes'][outcome]}>
																				<div className="col-md-8">
																					<div className="impact-eval-outcome-label">{text.assessment}:</div>
																					<div className="impact-eval-outcome-assessment">{assessment}</div>
																				</div>
																				<div className="col-md-4">
																					<img src={text.outcome_icon[evaluation[pt ? 'outcomes_pt' : 'outcomes'][outcome] - 1]}/>
																				</div>
																			</div>
																			<div className="impact-eval-assessment"><span>{evaluation[pt ? 'outcomes_pt' : 'outcomes'].length === 1 ? text.outcome_singular : text.outcome_plural}:</span> {Object.keys(evaluation[pt ? 'outcomes_pt' : 'outcomes']).join(', ')}</div>
																		</div>
																	</div>)
														})

												: 

												null
											}
										</div>
									</div>
								</div>

								: isGDI ?

								<div className="gdi-section-embed col-md-12">
									<div className="gdi-link row"><a href={text.gdi_link} target="_blank"><img id="gdi-logo" src={GDILogo} /></a></div>
									<div className="gdi row">{text.gdi_desc}</div>
									<div className="gdi-color-bg row">
										<div className="gdi row">{text.gdi_instruct}<a href={text.gdi_link}>{text.gdi_link}</a></div>
										<div className="gdi-drop-section row">
											<div className="col-md-6">
												<span className="gdi-num">1</span><span>{text.gdi_select_sector}</span>
												<div className="gdi-drop special">
													<DropdownButton
													    drop={'down'}
													    title={text.gdi_data[this.state.selected_index].title}
													    id={'gdi-dropdown'}
													    key={'gdi'}
													    onSelect={(e) => this.selectSector(e)}
													>
													   	{text.gdi_data.map((sector, index) => {
													    		return <MenuItem eventKey={index} className={this.state.selected_index == index ? "active" : ""}>{sector.title}</MenuItem>
													    })}
													</DropdownButton>
												</div>
											</div>
											
											<div className="col-md-6">
												<span className="gdi-num">2</span><span>{text.gdi_select_country}</span>
												<div className="gdi-drop">
													<DropdownButton
													    drop={'down'}
													    title={text.gdi_country}
													    id={'gdi-dropdown-country'}
													    key={'gdi-country'}
													    disabled={true}
													>
													   	<MenuItem eventKey={0} className="active">{text.gdi_country}</MenuItem>
													</DropdownButton>
												</div>
											</div>
											
										</div>
										<div className="gdi-drop-section">
											<div className="col-md-12">
												<span className="gdi-num">3</span><span>{text.gdi_select_organization}</span>
											</div>
										</div>
										<div className="gdi-drop-section">
											<div className="gdi-drop col-md-10">
												<DropdownButton
												    drop={'down'}
												    title={text.gdi_org}
												    id={'gdi-dropdown-org'}
												    key={'gdi-org'}
												    disabled={true}
												>
												   	<MenuItem eventKey={0} className="active">{text.gdi_org}</MenuItem>
												</DropdownButton>
											</div>
											<div className="col-md-2">
											    <a 
											    	className="btn gdi-button"
											    	id="gdi-button" 
											    	href={text.gdi_data[this.state.selected_index]["url"]} 
											    	target="_blank"
											    >
											    	{text.gdi_button}
											    </a>
										    </div>
										</div>
									</div>
								</div>

								: 

								null
							}
						</div>
	                </div>

				</div>
			</div>
		);
	}
};
export default withRouter(Evidence);
