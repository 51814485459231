import React from 'react';
import HumanCapital from './sections/HumanCapital';
import Economy from './sections/Economy';
import Institutions from './sections/Institutions';
import InfrastructureAndEnvironment from './sections/InfrastructureAndEnvironment';
import './BenchmarkingContent.css';

const BenchmarkingContentDouble = (props) => {
	const expandify = (sectionId) => {
		props.expandify(sectionId);
	};

	let isVisibleCustom = false;
	for (let section in props.visibleSections) {
		if (!props.visibleSections[section]) {
			isVisibleCustom = true;
		}
	}
	let text = "All Themes";
	if (isVisibleCustom) {
		text = "Custom Themes";
	}

	return (
		<div className="benchmark-container">
			<div className="categories col-md-12">
				<div className="row benchmark-desc">
					{props.text.desc}
				</div>
				<HumanCapital
					{...props}
					numCountries={2}
					visibleSections={props.visibleSections}
					country_order={props.country_order}
					wdi_data={props.wdi_data}
					sectionId="section-one"
					isOpen={props.sections['section-one']}
					expandify={expandify}
				/>
				<Economy
					{...props}
					numCountries={2}
					visibleSections={props.visibleSections}
					country_order={props.country_order}
					wdi_data={props.wdi_data}
					sectionId="section-two"
					isOpen={props.sections['section-two']}
					expandify={expandify}
				/>
				<Institutions
					{...props}
					numCountries={2}
					visibleSections={props.visibleSections}
					country_order={props.country_order}
					wdi_data={props.wdi_data}
					sectionId="section-three"
					isOpen={props.sections['section-three']}
					expandify={expandify}
				/>
				<InfrastructureAndEnvironment
					{...props}
					numCountries={2}
					visibleSections={props.visibleSections}
					country_order={props.country_order}
					wdi_data={props.wdi_data}
					sectionId="section-four"
					isOpen={props.sections['section-four']}
					expandify={expandify}
				/>

			</div>
		</div>
	);
};

export default BenchmarkingContentDouble;
