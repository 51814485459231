import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import '../projects/Projects.css';
import queryString from 'query-string';

import WelcomeFooter from '../menu/WelcomeFooter';
import Navbar from '../common/navbar/NavbarPLR';

import 'react-toggle/style.css';

import StrategyHeader from '../common/strategyheader';

import ActivitiesCover from '../projects/img/activities-cover.png'

import PaperMoneyTwoIcon from '../projects/img/paper-money-two.svg'
import ChartLineIcon from '../projects/img/chart-line.svg'
import PureNaturalIcon from '../projects/img/pure-natural.svg'
import AltCard from '../../components/AltCard';
import AltProjectCard from '../../components/AltProjectCard';
import AltButton from '../../components/AltButton';
import FilterProjectsModal from '../common/modals/FilterProjectsModal';
import {API_URL} from "../../../config/api";

const textEnglish = {
  login: 'Login',
  logout: 'Log Out',
  med: 'Projects',
  filters_title: 'Filter Projects by Focus Area:',
  focus_label_1: 'Focus Area 1',
  focus_title_1:
    'Fiscal Consolidation and Government Effectiveness for Sustainable, Inclusive and Efficient Service Delivery',
  focus_label_2: 'Focus Area 2',
  focus_title_2: 'Private Sector Investment and Productive Growth',
  focus_label_3: 'Focus Area 3',
  focus_title_3: 'Inclusive and Sustainable Development',
  readmore: 'Summary',
  viewimpact: 'View Impact Evaluation',
  add: 'Add Data',
  add_project: 'Add Project',
  status: 'Status',
  date_approved: 'Date Approved',
  criteria: 'Criteria: ',
  challenge: 'Complexity of the Development Challenge',
  innovation: 'Innovation / Scalability',
  public: 'Provision of Global Public Goods',
  institutions: 'Strengthening Institutions',
  finance: 'Leveraging Private Financing',
  covid_related: 'COVID-19 Related',
  filter_label:'FILTER PROJECTS BY ORGANIZATION:',
  ibrd:'IBRD',
};

const textPortuguese = {
  login: 'Login',
  logout: 'Log Out',
  med: 'Projetos',
  filters_title: 'Filtrar projetos por área de foco',
  
  focus_icon_1: 'Consolidação Fiscal Icon',
  focus_label_1: 'Área de Foco 1',
  focus_title_1:
    'Consolidação fiscal e eficácia do governo para prestação de serviços sustentável, inclusiva e eficiente',
  focus_icon_2: 'Investimento do Setor Privado Icon',
  focus_label_2: 'Área de Foco 2',
  focus_title_2: 'Investimento do setor privado e crescimento produtivo',
  focus_icon_3: 'Desenvolvimento Sustentável e Inclusivo Icon',
  focus_label_3: 'Área de Foco 3',
  focus_title_3: 'Desenvolvimento Sustentável e Inclusivo',
  readmore: 'Sumário',
  viewimpact: 'View Impact Evaluation',
  add: 'Add Data',
  add_project: 'Add Project',
  status: 'Status',
  date_approved: 'Data de aprovação',
  criteria: 'Critério: ',
  challenge: 'complexidade do desafio de desenvolvimento',
  innovation: 'inovação / escalabilidade',
  public: 'fornecimento de bens públicos globais',
  institutions: 'fortalecimento',
  finance: 'alavancagem do financiamento privado',
  covid_related: 'COVID-19 Related',
  filter_label:'FILTRAR PROJETOS POR ORGANIZAÇÃO',
  ibrd:'BIRD',
};

const Projects = (props) => {
  const [selected, setSelected] = useState(null);
  const [projects, setProjects] = useState([]);
  const [displayedProjects, setDisplayedProjects] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [projectsOnPage, setProjectsOnPage] = useState([]);
  const [filters, setFilters] = useState(['IFC', 'IBRD', 'MIGA']);
  const [isLoaded, setIsLoaded] = useState(false);
  const [state, setState] = useState(null);
  const [status, setStatus] = useState(1);
  const [approvalDate, setApprovalDate] = useState(null);
  const [isFilterModalVisible, setIsFilterModalVisible] = useState(false);

  const handleLogout = () => {
    localStorage.removeItem('auth');
    props.history.push('/');
  };

  const handlePageProjects = async (orientation) => {
    if (orientation === 'next') {
      await setCurrentPage((prevPage) => prevPage + 1);
      setProjectsOnPage(
          displayedProjects.slice(8 * (currentPage + 1), 8 * (currentPage + 2))
      );
    } else {
      await setCurrentPage((prevPage) => prevPage - 1);
      setProjectsOnPage(
          displayedProjects.slice(8 * (currentPage - 1), 8 * currentPage)
      );
    }
  };

  const selectFocus = async (selection) => {
    localStorage.setItem('focus_area', selection);

    await setSelected(selection);
    await setProjectsOnPage(displayedProjects.slice(0, 8));
    await setCurrentPage(0);
  };

  const handleFilterChange = async ({ status, ibrd, ifc, miga, date, state }) => {
    const newFilters = [];
    if (ibrd) newFilters.push('IBRD');
    if (ifc) newFilters.push('IFC');
    if (miga) newFilters.push('MIGA');

    await setFilters(newFilters);
    await setState(state);
    await setStatus(status);
    await setApprovalDate(date);
  };

  useEffect(() => {
    const fetchData = async () => {
      window.scrollTo(0, 0);
      try {
        const response = await axios.get(`${API_URL}/projects-activities`);

        let project = null;
        let focus = null;
        if (props.location) {
          if (props.location.state) {
            if (props.location.state.project) {
              let project_id = props.location.state.project;
              focus = props.location.state.focus;
              for (let i = 0; i < response.data.length; i++) {
                if (response.data[i].id === project_id) {
                  project = response.data[i];
                }
              }
            }
          } else if (props.location.search) {
            let params = queryString.parse(props.location.search);
            let project_id = params.project;
            focus = params.focus;
            for (let i = 0; i < response.data.length; i++) {
              if (response.data[i].id === project_id) {
                project = response.data[i];
              }
            }
          }
        }

        if (project === null || focus === null) {
          setProjects(response.data);
        } else {
          setProjects(response.data);
          setSelected(focus);
        }

        setIsLoaded(true);
      } catch (err) {
        // Handle error
      }
    };

    fetchData();

    const selected = localStorage.getItem('focus_area') ?? 'focus_area_1';
    setSelected(selected);
  }, [props.location]);

  useEffect(() => {
    if (isLoaded && selected) {
      filterProjects();
    }
  }, [isLoaded, selected])

  const filterProjectsByCode = async (code) => {
    const filteredProjects = projects.filter((project) =>
        !code || project.id.toLowerCase().includes(code.toLowerCase())
    );

    await setDisplayedProjects(filteredProjects);
    await setTotalPages(
        ~~(filteredProjects.length / 8) +
        (filteredProjects.length % 8 === 0 ? 0 : 1)
    );
    await setProjectsOnPage(filteredProjects.slice(0, 8));
  };

  const filterProjects = async () => {
    const filteredProjects = projects.filter(
        (project) =>
            filters.includes(project.organization) &&
            project[selected] &&
            (!state || project.states?.includes(state)) &&
            (status === 1 ||
                (project?.status === 'Active' && status === 2) ||
                (project?.status === 'Inactive' && status === 3)) &&
            (!approvalDate ||
                new Date(project.date_approved).getDate() ===
                new Date(approvalDate).getDate() &&
                new Date(project.date_approved).getMonth() ===
                new Date(approvalDate).getMonth() &&
                new Date(project.date_approved).getFullYear() ===
                new Date(approvalDate).getFullYear())
    );

    await setDisplayedProjects(filteredProjects);
    await setTotalPages(
        ~~(filteredProjects.length / 8) +
        (filteredProjects.length % 8 === 0 ? 0 : 1)
    );
    await setProjectsOnPage(filteredProjects.slice(0, 8));
  };

  let pt = false;
  let text = {};
  if (props.location.pathname.substring(0, 4) !== '/pt/') {
    text = textEnglish;
  } else {
    text = textPortuguese;
    pt = true;
  }

  return (
      <div className="container-fluid unpadify">
        <Navbar
            handleLogin={handleLogout}
            handleLogout={handleLogout}
            auth={props.auth}
            isLoggedIn={props.loggedIn}
            checkIsLoggedIn={props.checkIsLoggedIn}
            onChangeLoginState={props.onChangeLoginState}
            {...props}
            currentPage="program"
        />
        <StrategyHeader
            title={pt ? "Atividades" : "Activities"}
            selectedTab={1}
            tabs={[
              { link: pt ? '/pt/portfolio' : '/portfolio', label: pt ? 'Alcance do portfólio' : 'Portfolio Footprint' },
              { link: pt ? '/pt/projects' : '/projects', label: pt ? 'Projetos' : 'Projects' },
              { link: pt ? '/pt/reports' : '/reports', label: pt ? 'Relatórios' : 'Reports' },
            ]}
            backgroundImage={ActivitiesCover}
            backgroundPosition="0px 68%"
        />
        <div id="projects-container" style={{ marginBottom: '50px' }}>
          <h2 style={{ fontSize: '40px', fontFamily: 'Andes', color: '#1a1a1a' }}>{pt ? "Projetos" : "Projects"}</h2>
          <div className="divider"></div>
          <div style={{ display: 'flex', alignItems: 'center', position: 'relative', marginTop: '50px' }}>
            <div style={{
              position: 'absolute',
              top: 'calc(50% - 12px)',
              left: '10px',
            }}>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10 17C13.866 17 17 13.866 17 10C17 6.13401 13.866 3 10 3C6.13401 3 3 6.13401 3 10C3 13.866 6.13401 17 10 17Z" stroke="#CCCCCC" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M15 15L21 21" stroke="#CCCCCC" strokeWidth="2" strokeLinecap="square" strokeLinejoin="round"/>
              </svg>
            </div>
            <input
                type="text"
                className="projects-search-filter"
                style={{ marginRight: '16px', paddingLeft: '50px', fontSize: '14px' }}
                placeholder={pt ? "Pesquise pelo código do projeto" : "Search by project code"}
                onChange={(e) => {
                  filterProjectsByCode(e.target.value)
                }}
            />
          </div>
          <div className="focus-areas-cards">
            <AltCard
                icon={PaperMoneyTwoIcon}
                title={text.focus_label_1}
                description={text.focus_title_1}
                inverse={selected === "focus_area_1"}
                onClick={() => selectFocus("focus_area_1")}
                style={{ cursor: 'pointer' }}
                showButton={false}
            />
            <AltCard
                icon={ChartLineIcon}
                title={text.focus_label_2}
                description={text.focus_title_2}
                inverse={selected === "focus_area_2"}
                onClick={() => selectFocus("focus_area_2")}
                style={{ cursor: 'pointer' }}
                showButton={false}
            />
            <AltCard
                icon={PureNaturalIcon}
                title={text.focus_label_3}
                description={text.focus_title_3}
                buttonText="Veja o programa"
                inverse={selected === "focus_area_3"}
                onClick={() => selectFocus("focus_area_3")}
                style={{ cursor: 'pointer' }}
                showButton={false}
            />
          </div>
          <div className='projects-header' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '47px' }}>
            <div>
              <h2 style={{ margin: '0px' }}>{pt ? 'Portfólio' : 'Portfolio'}: R${(() => {
                let total = 0;
                if (projects?.length) {
                  for (let i = 0; i < projects.length; i++) {
                    if (projects[i].status === 'Active') {
                      total += parseFloat(projects[i][`${selected}_value`]);
                    }
                  }
                }
                let resultado = (total / 1e9);

                if (resultado < 1) {
                  return ' ' + Number(total / 1e6).toFixed(2) + ' Mi';
                } else {
                  return ' ' + Number(resultado).toFixed(2) + ' Bi';
                }
              })()}</h2>
              <div className="projects-title-divider"></div>
            </div>
            <AltButton
                inverse={true}
                style={{ fontSize: '18px', padding: '12px 24px' }}
                onClick={() => setIsFilterModalVisible(true)}
            >
              {pt ? "Filtrar" : "Filter"}
            </AltButton>
          </div>
          {Array.isArray(projects) && Array.isArray(projectsOnPage) &&
              projects.length > 0 && projectsOnPage.length > 0 && (
                  <div className="projects-cards-container">
                    {projectsOnPage.map((project, index) => {
                      {
                        let criteria = [];

                        if (project.criteria) {
                          if (project.criteria.indexOf('challenge') > -1) {
                            criteria.push({
                              criteria:
                                  'loop loop-regional criteria-icon-main-page',
                              criteria_title: text.criteria + text.challenge,
                            });
                          }
                          if (project.criteria.indexOf('innovation') > -1) {
                            criteria.push({
                              criteria:
                                  'loop loop-electricity-1 criteria-icon-main-page',
                              criteria_title: text.criteria + text.innovation,
                            });
                          }
                          if (project.criteria.indexOf('institutions') > -1) {
                            criteria.push({
                              criteria:
                                  'loop loop-government criteria-icon-main-page',
                              criteria_title: text.criteria + text.institutions,
                            });
                          }
                          if (project.criteria.indexOf('finance') > -1) {
                            criteria.push({
                              criteria: 'loop loop-money criteria-icon-main-page',
                              criteria_title: text.criteria + text.finance,
                            });
                          }
                          if (project.criteria.indexOf('public') > -1) {
                            criteria.push({
                              criteria: 'loop loop-road criteria-icon-main-page',
                              criteria_title: text.criteria + text.public,
                            });
                          }
                        }
                        //project.link = '/projectdetails'
                        return (
                            <AltProjectCard
                                id={project.id}
                                title={props.location.pathname.substring(0,4) !== '/pt/' ? (project.title ?? project.title_pt) : (project.title_pt ?? project.title)}
                                description={pt ? (project.description_pt ?? project.description) : (project.description ?? project.description_pt)}
                                approvalDate={(new Date(project.date_approved)).toISOString().substring(0, 10) || null}
                                href={props.location.pathname.substring(0,4 ) !== '/pt/' ? `/projectdetails/${project.id}`: `/pt/projectdetails/${project.id}`}
                                pt={pt}
                                status={project.status}
                            />
                        );
                      }
                    })}
                  </div>
              )}
          {/* Pagination */}
          {displayedProjects.length > 8 && (
              <div className="projects-pagination">
              <span
                  disabled={currentPage <= 0}
                  className={`projects-previous-button ${currentPage <= 0 ? 'disabled' : ''}`}
                  onClick={() => {
                    return currentPage > 0 ? handlePageProjects('prev') : () => {}
                  }}
              >
                {pt ? "Anterior" : "Previous"}
              </span>
                <span className="projects-current-page">
                {currentPage + 1}
              </span>
                <span
                    disabled={currentPage + 1 > totalPages}
                    className={`projects-next-button ${currentPage + 1 >= totalPages ? 'disabled' : ''}`}
                    onClick={() => {
                      return currentPage + 1 < totalPages ? handlePageProjects('next') : () => {}
                    }}
                >
                {pt ? "Próximo" : "Next"}
              </span>
              </div>
          )}
          <div>
          </div>
        </div>

        <FilterProjectsModal
            pt={pt}
            filter={filterProjects}
            handleChange={handleFilterChange}
            isVisible={isFilterModalVisible}
            onClose={() => setIsFilterModalVisible(false)}
        />
        <WelcomeFooter {...props} />
      </div>
  );
};


export default withRouter(Projects);
