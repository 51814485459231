import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

/* const firebaseConfig = {
  apiKey: "AIzaSyBIptdXL66UVR8UpTvkSE-GIguSnMIae58",
  authDomain: "openbr.firebaseapp.com",
  projectId: "openbr",
  storageBucket: "openbr.appspot.com",
  messagingSenderId: "1085900699675",
  appId: "1:1085900699675:web:9816dce14b5f1be18760be"
}; */


const firebaseConfig ={
  apiKey: "AIzaSyBiRahPdMZ_28Q9KDbUN7TjtVA6dOagHAY",
  authDomain: "qaopenbrapp.firebaseapp.com",
  projectId: "qaopenbrapp",
  storageBucket: "qaopenbrapp.appspot.com",
  messagingSenderId: "431984330239",
  appId: "1:431984330239:web:12166f74550b4915cc93ca"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
export const auth = firebase.auth();
export const firestore = firebase.firestore();
export const session_persistence = firebase.auth.Auth.Persistence.SESSION;
export const none_persistence = firebase.auth.Auth.Persistence.NONE;