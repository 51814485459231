import React, { Component } from 'react';
import * as d3 from "d3";
import './LineGraph.css';

export default class LineGraph extends Component {
	constructor() {
		super();

		this.createChart = this.createChart.bind(this);
		this.updateChart = this.updateChart.bind(this);
	}

	componentDidMount() {
		this.createChart();
		window.addEventListener("resize", this.updateChart);
	}

	componentDidUpdate() {
		this.updateChart();
	}

	componentWillUnmount() {
	    window.removeEventListener("resize", this.updateChart);
	}

	updateChart() {
		d3.select(this.node).selectAll("*").remove();
		this.createChart(); 
	}

	createChart() {
		const node = this.node;
		const data = this.props.data;
		const yAxesLabel = this.props.isPercentage ? '%' : this.props.unit ? this.props.unit : '';
		const pt = this.props.pt;

		//defaults to positive/blue
		let color = "#002245";
		if (this.props.trend === 'negative') { 
			color = "#790000"; 
		} else if (this.props.trend === 'neutral') {
			color = "#666666";
		} else if (this.props.trend === 'plr') {
			color = "#002245";
		}

		const margin = {top: 50, right: 125, bottom: 50, left: 100};
		const width = d3.select(node).node().getBoundingClientRect().width - margin.left - margin.right;
		const height = width * 0.45;

		const x = d3.scaleTime().range([5, width]);//.rangeRound([0, width]);
		let y = null;
		if (this.props.reverse) {
			y = d3.scaleLinear().range([0, height]);
		} else {
			y = d3.scaleLinear().range([height, 0]);
		}

		let start = new Date(data[0].year,0,1);
		if (data.length === 1) {
			start = new Date(data[0].year - 1,0,1);
		}
		if (this.props.isArea) {
			let end = new Date(data[data.length - 1].year + 1,0,1);
			//let end = new Date(data[data.length - 1].year,0,1);
			x.domain([start, end]);	
		} else {
			x.domain([start, new Date(2022,0,1)]);	
		}
		const mratio = 0.7;
		let ticks = 7;
		//if the target is > than the max value we need to modify the domain
		if (this.props.target !== undefined && this.props.target !==null) {
			const dataMax = d3.max(data.map(val => val.value));
			const max = (this.props.target > dataMax) ? this.props.target*1.1 : dataMax*1.1
			const dataMin = d3.min(data.map(val => val.value));
			let min = 0;
			if (this.props.target < dataMin) {
				min = this.props.target*mratio;
			} else {
				min = dataMin*mratio;
			}
			if (max - min < ticks) {
				ticks = max - min > 5 ? max - min : 5;
			}
			y.domain([min, max]);
		} else {
			const dataMin = d3.min(data.map(val => val.value));
			const min = dataMin*0.85;
			const max = d3.max(data.map(val => val.value*1.1));
			if (max - min < ticks) {
				ticks = max - min > 3 ? max - min : 3;
			}
			y.domain([min, max]);
		}
		
		let svgHeight = 1;
		let strokeWidth = 4;
		let textClass = "lineGraphTargetText7";
		let graphLabelsClass = "lineGraph7";
		let lineTranslation = 'translate(' + (12 * 3) + ', 0)';

		if (d3.select(node).node().getBoundingClientRect().width > 340) {
			strokeWidth = 5;
			svgHeight = .78;
			textClass = "lineGraphTargetText7";
			graphLabelsClass = "lineGraph7";
			lineTranslation = 'translate(' + (18 * 3) + ', 0)';
		}
		if (d3.select(node).node().getBoundingClientRect().width > 380) {
			strokeWidth = 6;
			svgHeight = .74;
			textClass = "lineGraphTargetText6";
			graphLabelsClass = "lineGraph6";
			lineTranslation = 'translate(' + (22 * 3) + ', 0)';
		}
		if (d3.select(node).node().getBoundingClientRect().width > 450) {
			strokeWidth = 6;
			svgHeight = .7;
			textClass = "lineGraphTargetText5";
			graphLabelsClass = "lineGraph5";
			lineTranslation = 'translate(' + (25 * 3) + ', 0)';
		}
		if (d3.select(node).node().getBoundingClientRect().width > 540) {
			strokeWidth = 7;
			svgHeight = .65;
			textClass = "lineGraphTargetText4";
			graphLabelsClass = "lineGraph4";
			lineTranslation = 'translate(' + (29 * 3) + ', 0)';
		}
		if (d3.select(node).node().getBoundingClientRect().width > 590) {
			strokeWidth = 8;
			svgHeight = .62;
			textClass = "lineGraphTargetText3";
			graphLabelsClass = "lineGraph3";
			lineTranslation = 'translate(' + (32 * 3) + ', 0)';
		} 
		if (d3.select(node).node().getBoundingClientRect().width > 640) {
			strokeWidth = 9;
			svgHeight = .6;
			textClass = "lineGraphTargetText2";
			graphLabelsClass = "lineGraph2";
			lineTranslation = 'translate(' + (35 * 3) + ', 0)';
		}
		if (d3.select(node).node().getBoundingClientRect().width > 700) {
			strokeWidth = 10;
			svgHeight = .5;
			textClass = "lineGraphTargetText";
			graphLabelsClass = "lineGraph";
			lineTranslation = 'translate(' + (38 * 3) + ', 0)';
		}
		svgHeight = svgHeight*1.15;
		const svg = d3.select(node).append("svg")
			.attr("width", d3.select(node).node().getBoundingClientRect().width)
            .attr("height", width * svgHeight + margin.bottom)
			.append("g")
			.attr("transform", `translate(${margin.left}, ${margin.top})`)
			.attr("class", graphLabelsClass);

		svg.append("g")
			.attr("transform", `translate(0,${height})`)
			.attr("class", "lineGraphAxis")
			.call(d3.axisBottom(x).ticks(d3.timeYear.every(1)) );

		svg.append("g")
			.call(d3.axisLeft(y).ticks(ticks).tickSize(-(width-10)).tickFormat(function(d) { let formatValue = d3.format("~s"); return formatValue(d).replace('G', 'B'); }))
			.attr("class", "lineGraphGrid")
			.append("text")
			.attr("y", '-4em')
			.attr("x", '-1em')
			.attr("text-anchor", "end")
			.attr("transform", "rotate(-90)")
			.text(yAxesLabel);
		//drop shadows
		// filters go in defs element
		// const defs = svg.append("defs");

		// let filter = defs.append("filter")
		// 	.attr("id","glow");
		// filter.append("feGaussianBlur")
		// 	.attr("stdDeviation","1")
		// 	.attr("in", "SourceAlpha")
		// 	.attr("result","blur");
		// let feMerge = filter.append("feMerge");
		// feMerge.append("feMergeNode")
		// 	.attr("in","blur");
		// feMerge.append("feMergeNode")
		// 	.attr("in","SourceGraphic");
		if (!d3.select("#impact-tooltip").node()) {
			var div = d3.select("body").append("div")	
			    .attr("class", "tooltip")
			    .attr("id", "impact-tooltip")				
			    .style("opacity", 0);
		} else {
			var div = d3.select("#impact-tooltip");
		}

		if (this.props.isArea) {
			let area = d3.area()
		    .x(function(d) { if (d.month === 0) {return x(new Date(d.year,0,1))} else {return x(new Date(d.year,d.month,1))} })
		    .y0(height)
		    .y1(function(d) { return y(d.value); });

		    let gradient = svg.append("linearGradient")
			   .attr("id", "area-gradient")
			   .attr("x1", "100%")
			   .attr("x2", "0%")
			   .attr("y1", "0%")
			   .attr("y2", "100%");

			gradient.append("stop")
			   .attr('class', 'start')
			   .attr("offset", "0%")
			   .attr("stop-color", "#4cbb88")
			   .attr("stop-opacity", 0.75);

			gradient.append("stop")
			   .attr('class', 'end')
			   .attr("offset", "100%")
			   .attr("stop-color", "#028294")
			   .attr("stop-opacity", 0.75);

			svg.append('g')
				.append("path")
			    .datum(data)
			    .attr("d", area)
			    .attr("fill", "url(#area-gradient)")
			    .attr("class", "area")

			svg.selectAll("areaCircles")
		      .data(data)
		      .enter()
		      .append("circle")
		        .attr("fill", '#028294')
		        .style('opacity', 0.75)
		        .attr("stroke", "none")
		        .attr("cx", function(d) { if (d.month === 0) {return x(new Date(d.year,0,1))} else {return x(new Date(d.year,d.month,1))} })
		        .attr("cy", function(d) { return y(d.value) })
		        .attr("r", strokeWidth / 2)
			    .on("mouseover", function(d) {
			    	let text = '';
			    	if (d.month === 0) {text = d.value.toLocaleString(pt ? 'pt-BR':'en-US') + ' (' + d.year + ')'} else {text = d.value.toLocaleString(pt ? 'pt-BR':'en-US') + ' (' + d.month + '/' + d.year + ')'}
		            div.transition()		
		                .duration(200)		
		                .style("opacity", .9);		
		            div.html(text)	
		                .style("left", (d3.event.pageX) + "px")		
		                .style("top", (d3.event.pageY - 28) + "px");	
		            })					
		        .on("mouseout", function(d) {		
		            div.transition()		
		                .duration(500)		
		                .style("opacity", 0);	
		        });
		} else { //line graph
			let line = d3.line()
		    .x(function(d) {
		    	
		    	if (d.month === 0) {return x(new Date(d.year,0,1))} else {return x(new Date(d.year,d.month,1))} 
		    })
		    .y(function(d) {
		    	
		    	return y(d.value);
		    });

		    let gradient = svg.append("linearGradient")
			   .attr("id", "line-gradient")
			   .attr("x1", "100%")
			   .attr("x2", "0%")
			   .attr("y1", "0%")
			   .attr("y2", "100%");

			gradient.append("stop")
			   .attr('class', 'start')
			   .attr("offset", "0%")
			   .attr("stop-color", "#028294")
			   .attr("stop-opacity", 1);

			gradient.append("stop")
			   .attr('class', 'end')
			   .attr("offset", "100%")
			   .attr("stop-color", "#002245")
			   .attr("stop-opacity", 1);

			svg.append('g')
				.append("path")
			    .datum(data)
			    .attr("fill", "none")
			    .attr("stroke", "url(#line-gradient)")
			    .attr("stroke-width", strokeWidth)
			    .attr("d", line)

			svg.selectAll("myCircles")
		      .data(data)
		      .enter()
		      .append("circle")
		        .attr("fill", color)
		        .attr("stroke", "none")
		        .attr("cx", function(d) {  if (d.month === 0) {return x(new Date(d.year,0,1))} else {return x(new Date(d.year,d.month,1))} })
		        .attr("cy", function(d) { return y(d.value) })
		        .attr("r", strokeWidth / 2)
		        .on("mouseover", function(d) {
		        	let text = '';
			    	if (d.month === 0) {text = d.value.toLocaleString(pt ? 'pt-BR':'en-US') + ' (' + d.year + ')'} else {text = d.value.toLocaleString(pt ? 'pt-BR':'en-US') + ' (' + d.month + '/' + d.year + ')'}
		            div.transition()		
		                .duration(200)		
		                .style("opacity", .9);		
		            div	.html(text)	
		                .style("left", (d3.event.pageX) + "px")		
		                .style("top", (d3.event.pageY - 28) + "px");	
		            })					
		        .on("mouseout", function(d) {		
		            div.transition()		
		                .duration(500)		
		                .style("opacity", 0);	
		        });
		}


		let target_color = color;
		if (this.props.isArea) {target_color = "#028294"}
		//if there is a target value, add the indicator line
		if (this.props.target !== undefined && this.props.target !==null) {
		    svg.append('g')
				.append('line')
				.attr("x1", 10)
				.attr("y1", y(this.props.target))
				.attr("x2", width)
				.attr("y2", y(this.props.target))
				.attr("stroke", target_color)
				.attr("stroke-width", 5)
				.attr("stroke-dasharray", ("1, 15"))
				.attr("stroke-linecap", "round")
			
			// if (this.props.reverse) {
			// 	svg.append("text")
			// 		.attr("class", textClass)
			// 		.attr("y", y(this.props.target) - 25)
			// 		.attr("x", 70)
			// 		.attr("dy", "0.71em")
			// 		.attr("text-anchor", "end")
			// 		.text("Target");
			// } else {
				svg.append("text")
					.attr("class", textClass)
					.attr("y", y(this.props.target) - 10)
					.attr("x", width + 5)
					.attr("dy", "0.71em")
					.attr("text-anchor", "start")
					.style("fill", target_color)
					.text((this.props.pt ? "Meta " : "Target ")+this.props.target_year);
			// }
			
		};
	}

	render() {
		return <div ref={node => this.node = node}></div>
	}
}
