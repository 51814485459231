import React from 'react';
import AltButton from '../../components/AltButton';
import HeaderCover from './img/header-cover.webp'
import './Header.css';

const textEnglish = {
    title: "Open Brazil",
    desc: "An open platform for monitoring the World Bank Group’s strategy for Brazil for the 2018-2023 period",
    share: "Share this page to:",
    more: "Learn more"
}

const textPortuguese = {
    title: "Brasil Aberto",
    desc: "Uma plataforma aberta para monitorar a estratégia do grupo Banco Mundial para o Brasil no período 2018 - 2023",
    share: "Compartilhe esta página:",
    more: "Saiba mais"
}

const Header = (props) => {
    const en = props.location.pathname.substring(0, 4) !== '/pt/';

    let textH = {};
    if (props.location) {
        if (props.location.pathname.substring(0, 4) !== '/pt/') {
            textH = textEnglish;
        } else {
            textH = textPortuguese;
        }
    } else {
        textH = textEnglish;
    }

    return (
        <div className="header-container" style={{ backgroundImage: `url(${HeaderCover})` }}>
            <div className="header-overlay"></div>
            <h2 className="header-title">{textH.title}</h2>
            <span className="header-desc">{textH.desc}</span>
            <AltButton
                style={{
                    fontSize: '18px',
                    height: '57px',
                    width: '155px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
                href={en ? '/about' : '/pt/about'}
            >
                {textH.more}
            </AltButton>
        </div>
    );
};

export default Header;
