import React from 'react';
import propTypes from 'prop-types';
import classNames from 'classnames';
import styles from './styles.module.css';

const Button = ({
  className,
  children,
  onClick,
  large,
  small,
  outline,
  hollow,
  disabled = false,
  type = 'primary',
  ...props
}) => {
  const buttonClasses = classNames(styles.button, {
    [styles.primary]: type === 'primary',
    [styles.secondary]: type === 'secondary',
    [styles.danger]: type === 'danger',
    [styles.large]: large,
    [styles.small]: small,
    [styles.outline]: outline,
    [styles.hollow]: hollow,
    [styles.disabled]: disabled,
  }, className);

  return (
    <button onClick={onClick} disabled={disabled} className={buttonClasses} style={{ fontSize: '16px' }} {...props}>
      {children}
    </button>
  );
};

Button.propTypes = {
  children: propTypes.node.isRequired,
  onClick: propTypes.func,
  className: propTypes.string,
  type: propTypes.oneOf(['primary', 'secondary', 'tertiary', 'danger']),
  outline: propTypes.bool,
  hollow: propTypes.bool,
  large: propTypes.bool,
  small: propTypes.bool,
  disabled: propTypes.bool,
};

export default Button;
