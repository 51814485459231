import React from 'react'
import propTypes from 'prop-types'
import classNames from 'classnames/bind'
import styles from './styles.module.css'
import CloseIcon from '../../../icons/close-icon.svg';

const TitleSection = ({ title = '', large = false, onClose, titleStyle = null }) => {
  const titleClasses = classNames.bind(styles)

  return (
    <>
      {title && (
        <h1
          className={titleClasses(styles.title, {
            large: large,
          })}
          style={titleStyle}
        >
          {title}
            <img onClick={onClose} src={CloseIcon} alt="Close"/>
        </h1>
      )}
      <hr></hr>
    </>
  )
}

TitleSection.propTypes = {
  title: propTypes.string.isRequired,
  large: propTypes.bool,
}

export default TitleSection
