import React, { useEffect, useRef } from 'react';
import * as d3 from "d3";

import './Impact.css';

import basemap from './img/br_states.geojson';

const ImpactMap = ({ data }) => {
	const nodeRef = useRef(null);

	useEffect(() => {
		const createChart = () => {
			const node = nodeRef.current;
			let highlighted_countries = [];
			for (let i = 0; i < data.length; i++) {
				highlighted_countries.push(data[i].state_code);
			}

			const margin = { top: 50, right: 50, bottom: 50, left: 50 };
			const width =
				d3.select(node).node().getBoundingClientRect().width -
				margin.left -
				margin.right;
			const height = width * 1.25;

			const svg = d3.select(node).append('svg').attr('width', width).attr('height', height).attr('fill', 'grey');

			const promises = [d3.json(basemap)];

			let gradient = svg
				.append('linearGradient')
				.attr('id', 'map-gradient')
				.attr('x1', '0%')
				.attr('x2', '0%')
				.attr('y1', '0%')
				.attr('y2', '100%');

			gradient
				.append('stop')
				.attr('class', 'start')
				.attr('offset', '0%')
				.attr('stop-color', '#b63410')
				.attr('stop-opacity', 1);

			gradient
				.append('stop')
				.attr('class', 'end')
				.attr('offset', '100%')
				.attr('stop-color', '#ce3e16')
				.attr('stop-opacity', 1);

			Promise.all(promises).then(ready);
			function ready([brazil_states]) {
				const projection = d3
					.geoMercator()
					.fitSize([width, height], { type: 'FeatureCollection', features: brazil_states.features });
				const path = d3.geoPath().projection(projection);

				svg
					.append('g')
					.attr('class', 'states')
					.selectAll('path')
					.data(brazil_states.features)
					.enter()
					.append('path')
					.attr('stroke', '#ccc')
					.attr('fill', function (d) {
						if (highlighted_countries.indexOf(d.properties.id) > -1) {
							return 'url(#map-gradient)';
						} else {
							return '#ffffff';
						}
					})
					.attr('title', function (d) {
						return d.properties.nome;
					})
					.attr('d', path);
			}
		};

		const updateChart = () => {
			d3.select(nodeRef.current).selectAll('*').remove();
			createChart();
		};

		createChart();
		window.addEventListener('resize', updateChart);

		return () => {
			window.removeEventListener('resize', updateChart);
		};
	}, [data]);

	return <div ref={nodeRef}></div>;
};

export default ImpactMap;
