import React, { useState } from 'react';
import numeral from 'numeral';
import classnames from 'classnames';

import './BenchmarkingHeader.css';

import Button from  '../../../components/Button';
import CountryModal from '../../common/modals/CountryModal';

import styles from './benchmarking.module.css';
import './benchmarking.css';

const textEnglish = {
  add: 'ADD COUNTRY',
};

const textPortuguese = {
  add: 'ADICIONAR PAÍS',
};

export const BenchmarkingHeader = (props) => {
  const [showCountryModal, setShowCountryModal] = useState(false);

  const addCountry = () => {
    setShowCountryModal(true);
  };

  const closeCountryModal = () => {
    setShowCountryModal(false);
  };

  const formatValue = (value, formatter) => {
    if (formatter === 'pt' && value) {
      return value + 'º Lugar';
    }
    if (value) {
      return numeral(value).format(formatter);
    } else {
      return '--';
    }
  };

  let pt = false;
  let text = {};
  if (props.location.pathname.substring(0, 4) !== '/pt/') {
    text = textEnglish;
  } else {
    pt = true;
    text = textPortuguese;
  }
  let length = props.wdiData[props.countryOrder[0].iso3].indicators['SP.POP.TOTL'].data.length;
  let mostRecentPop = props.wdiData[props.countryOrder[0].iso3].indicators['SP.POP.TOTL'].data[length - 1].value;

  length = props.wdiData[props.countryOrder[0].iso3].indicators['NY.GDP.MKTP.CD'].data.length;
  let mostRecentGDP = props.wdiData[props.countryOrder[0].iso3].indicators['NY.GDP.MKTP.CD'].data[length - 1].value;

  return (
      <div className={styles.sectionWrapper}>
        <div className="">
          <div>
            <h3 className={styles.title}>Compare o Brasil com outros Países</h3>
            <div className={classnames(styles.row, 'bench-row')}>
              <div style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                color: '#fff !important',
              }}>
                {/* Flag */}
                <div
                    className={classnames(
                        'flag-icon',
                        `flag-icon-${props.countryOrder[0].iso2.toLowerCase()}`,
                        styles.flag
                    )}
                />
                {/* Country Name */}
                <h2 style={{color: '#fff'}}>
                  {pt
                      ? props.country_translation[
                          props.wdiData[props.countryOrder[0].iso3]
                              .country_name
                          ]
                      : props.wdiData[props.countryOrder[0].iso3]
                          .country_name}
                </h2>
              </div>
              {/* Population Total */}
              <div id='population-total'>
                <p className={styles.label} id='population-total-title'>
                  {
                    props.wdiData[props.countryOrder[0].iso3]
                        .indicators['SP.POP.TOTL'][pt ? 'name_pt' : 'name']
                  }
                </p>
                <p className={styles.value} id='population-total-value'>
                  {formatValue(mostRecentPop, '0.0a')}
                </p>
              </div>
              {/* GDP */}
              <div id='population-total'>
                <p className={styles.label} id='population-total-title'>
                  {
                    props.wdiData[props.countryOrder[0].iso3]
                        .indicators['NY.GDP.MKTP.CD'][pt ? 'name_pt' : 'name']
                  }
                </p>

                <p className={styles.value} id='population-total-value'>
                  {formatValue(mostRecentGDP, '0.0a')}
                </p>
              </div>
            </div>

            {/* BENCHMARKING BUTTON */}
            <div>
              <Button className={styles.button} onClick={addCountry}>
                {text.add}
              </Button>
            </div>
          </div>
        </div>
        <CountryModal
            showing={showCountryModal}
            closeCountryModal={closeCountryModal}
            notNavbar={true}
            {...props}
        />
      </div>
  );
}
