export const COLORS = [
    // '#014677',
    // '#008fee',
    // '#065662',
    // '#058a8f',
    '#EC553A',
    '#002245',
    '#058A8F',
    '#4CBB88',
    '#009FDA'
]

export const BORDER_COLORS = [
    '#EC553A',
    '#002245',
    '#058A8F',
    '#4CBB88',
    '#009FDA'
]

export const AREA_COLORS = [
    'rgba(236, 85, 58, 0.75)',
    'rgba(0, 34, 69, 0.75)',
    'rgba(5, 138, 143, 0.75)',
    'rgba(76, 187, 136, 0.75)',
    'rgba(0, 159, 218, 0.75)'
]