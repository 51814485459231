/* istanbul ignore file */
import { createGlobalStyle } from 'styled-components'

const GlobalStyles = createGlobalStyle`
  :root {
    --color-primary: #002245;
    --color-secondary: #00538a;
    --color-turquoise: #028294;
    --color-green: #4cbb88;
    --color-red: #cd2c0f;
    --color-yellow: #f9a825;
    --color-table-even: #e9f7fd;
    --color-table-odd: #cde7f9;
    

    --transition-all: all 0.3s ease-in-out;
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  html {
    font-size: 16px;
  }

  html, body, #__next {
    height: 100%;
  }

  body {
    font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }
  
  p {
    margin-bottom: 16px
  }

  .container {
    margin: 0 auto;
    max-width: 960px;
  }

  .half-container {
    max-width: 50%;
    margin-bottom: 24px;
    position: relative;
  }

  .row {
    display: flex;
    margin-bottom: 24px;
    grid-gap: 30px;
    align-items: stretch;
  }

  .row > * {
    flex: 1;
    width: 100%;
  }

  .column {
    display: flex;
    flex-direction: column;
  }

  .column > * {
    margin-bottom: 24px;
  }

  .grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 30px;
    margin-bottom: 60px;
  }

  .grid-vertical {
    display: grid;
    grid-template-rows: 1fr 1fr 1fr;
    grid-gap: 30px;
    margin-bottom: 60px;
  }

  .grid-1-2 {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-gap: 30px;
    margin-bottom: 24px;
  }

  .grid-1-3 {
    display: grid;
    grid-template-columns: 1fr 3fr;
    grid-gap: 30px;
    margin-bottom: 24px;
  }

  .section {
    margin-bottom: 60px;
  }

  @media (min-width: 1140px) {
    .container {
      max-width: 1140px;
    }
  }

  @media (max-width: 960px) { 
    .container {
      padding: 0 30px;
    }

    .half-container {
      max-width: unset;
    }

    .row {
      flex-direction: column;
    }

    .grid, .grid-1-2, .grid-1-3 {
      grid-template-columns: 1fr;
      margin-bottom: 60px;
    }
    
  }


`

export default GlobalStyles
