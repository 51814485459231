import evidencePreview from '../../menu/img/decode.png';
import portfolioPreview from '../../menu/img/portfolio.png';
import temp2 from '../../impact/img/temp-indicator-2-map-only.svg';

export const textEnglish = {
  covid_large: 'Result indicators',
  login: 'Login',
  logout: 'Log Out',
  // "top": "The World Bank Group",
  big: 'Open Brazil',
  desc:
    'Welcome to an open platform for monitoring the World Bank Group’s strategy for Brazil for the 2018-2023 period',
  about_title: 'About Open Brazil',
  about_text_1: [
    'Open Brazil provides information about the current country strategy, or ',
    {
      text: 'Country Partnership Framework (CPF)',
      url:
        'http://documents.worldbank.org/curated/en/148141498229092629/Brazil-Country-partnership-framework-for-the-period-FY18-FY23',
    }, //link to plr doc
    ', of the ',
    {
      text: 'World Bank Group (WBG) in Brazil',
      url: 'https://www.worldbank.org/en/country/brazil',
    },
    ', covering fiscal years 2018-23. The CPF lays out three strategic focus areas: (i) fiscal consolidation and government effectiveness; (ii) private sector investment, infrastructure, and productivity growth; and (iii) inclusive and sustainable development.',
  ],
  about_text_2: [
    "Open Brazil is intended to make the work of the WBG more accessible—or open—to an external audience, providing intuitive insights into the rationale and logic of WBG activities and regular, and in some instances real-time, monitoring of results achieved on the ground. Open Brazil leverages new technologies including Big Data, Open Data, remote sensing (e.g. satellite images), social media, and smart supervision tools. For an interactive overview of the WBG's program and its impact using the capabilities of Open Brazil.",
  ],

  finance_image: '',
  finance_title: 'Leveraging Finance',
  finance_desc:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  finance_link: '',

  readmore: 'Read More',

  risk_image: '',
  risk_title: 'Risk',
  risk_desc:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  risk_link: '',

  carousel_title: 'EXPLORE OPEN BRAZIL’s FUNCTIONALITY',
  carousel: [
    {
      title: 'Portfolio',
      desc: 'Click here to explore the Portfolio Footprint Map',
      image: portfolioPreview,
      link: '/portfolio',
    },
    // {
    // 	"title": "Sentiment Snapshot",
    // 	"desc": "Click here to explore social media activity",
    // 	"image": socialPreview,
    // 	"link": "/sentiment"
    // },
    {
      title: 'Indicators',
      desc: 'Click here to explore Focus Area 1: Objective 1.1',
      image: temp2,
      link: { pathname: '/indicators', state: { objective: '1.1' } },
    },
    {
      title: 'Evidence',
      desc: 'Click here to explore Impact Evaluations including DeCODE by GDI',
      image: evidencePreview,
      link: '/evidence',
    },
  ],

  video_title: 'The Journey Ahead',
  video_imbed: 'Video Link',
  video_sub: 'Video',
  video_desc: "World Bank Group's Partnership with Brazil",

  strategy_intro: 'Explore our',
  strategy_large: 'Focus Areas',

  page_button: 'Learn More',

  benchmarking_intro: 'Benchmarking',
  benchmarking_large: 'Brazil',
  benchmarking_desc: 'Explore how Brazil compares globally.',

  story_large: 'Partnership Framework',
  story_desc: 'Brazil 2018-2023',

  downloads_title: 'Downloads',
  filename_1: 'Country Partnership Framework Report',
  filesize_1: '(2.546 MB)',
  filelink_1:
    'http://documents.worldbank.org/curated/en/148141498229092629/pdf/20170619-Brazil-CPF-draft-for-Board-with-CLR-Acknowledgement-Box-06202017.pdf',
  filename_2: 'Performance and Learning Review',
  filesize_2: '(300 MB)',
  filename_3: 'Systematic Country Diagnostic',
  filesize_3: '(4.859 MB)',
  filelink_3:
    'http://documents.worldbank.org/curated/en/180351467995438283/pdf/101431-REVISED-SCD-Brazil-SCD-Final-version-May-6-2016.pdf',
  filename_4: 'Covid-19 in Brazil: Impacts and Policy Responses',
  filesize_4: '(3.827 MB)',
  filelink_4:
    'http://documents1.worldbank.org/curated/en/152381594359001244/pdf/Main-Report.pdf',

  tutorial: 'Click here to watch a video tutorial on Open Brazil',

  report_band_sub_title: 'Learn more about research and analytics that the World Bank Group has prepared for Brazil',
  project_band_subtitle: 'Learn more about World Bank group projects in Brazil',
  portfolio_reach_block: 'This page maps active projects in the World Bank Group’s (WBG’s) portfolio, displaying geo-referenced committed loans data',
  about_portfolio_band: 'Learn more about the World Bank Group portfolio in Brazil by focus area and state',
  focus_area_header:'Focus Areas',
  telling_the_story:'Telling the Story',
};

export const textPortuguese = {
  covid_large: 'Indicadores de Resultado ',
  login: 'Login',
  logout: 'Log Out',
  // "top": "The World Bank Group",
  big: 'Brasil Aberto',
  desc:
    'Bem-vindo a uma plataforma aberta para monitorar a estratégia do Grupo Banco Mundial para o Brasil para o período 2018-2023',
  about_title: 'Sobre o Brasil Aberto',
  about_text_1: [
    'O Brasil Aberto traz informações sobre a estratégia atual para o Brasil, chamada de ',
    {
      text:
        'Estratégia de Parceria com o País (CPF, Country Partnership Framework)',
      url:
        'http://documents1.worldbank.org/curated/en/148141498229092629/pdf/20170619-Brazil-CPF-draft-for-Board-with-CLR-Acknowledgement-Box-06202017.pdf',
    }, //link to plr doc
    ' do ',
    {
      text: 'Grupo Banco Mundial (GBM) no Brasil',
      url: 'http://www.bancomundial.org.br ',
    },
    ', compreendendo os anos fiscais 2018-2023 (julho de 2017 a junho de 2022). A CPF delineia três áreas de foco estratégico: (i) consolidação fiscal e eficácia de governo; (ii) investimento, infraestrutura e crescimento da produtividade do setor privado; e (iii) desenvolvimento inclusivo e sustentável.',
  ],
  about_text_2: [
    'O Brasil Aberto visa deixar o trabalho do GBM mais acessível - ou aberto - ao público externo, com informações intuitivas sobre as motivações e a lógica das atividades do GBM, além de proporcionar o monitoramento regular - e, em alguns casos, em tempo real - dos resultados alcançados na prática. O Brasil Aberto lança mão de novas tecnologias, incluindo Big Data, Open Data, sensoriamento remoto (por exemplo, imagens de satélite), mídias sociais e ferramentas inteligentes de supervisão. Para ter uma visão interativa do programa do GBM e seu impacto no Brasil, convidamos você a navegar pelo site ! ',
  ],

  finance_image: '',
  finance_title: 'Leveraging Finance',
  finance_desc:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  finance_link: '',

  readmore: 'Leia Mais',

  risk_image: '',
  risk_title: 'Risk',
  risk_desc:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  risk_link: '',

  carousel_title: 'EXPLORE AS FUNCIONALIDADES DO BRASIL ABERTO',
  carousel: [
    {
      title: 'Portfólio',
      desc: 'Clique aqui para explorar o mapa de alcance do portfólio',
      image: portfolioPreview,
      link: '/pt/portfolio',
    },
    // {
    // 	"title": "Sentiment Snapshot",
    // 	"desc": "Click here to explore social media activity",
    // 	"image": socialPreview,
    // 	"link": "/sentiment"
    // },
    {
      title: 'Indicadores',
      desc: 'Clique aqui para explorar a Área de Foco 1: Objetivo 1.1',
      image: temp2,
      link: { pathname: '/pt/indicators', state: { objective: '1.1' } },
    },
    {
      title: 'Evidências',
      desc: 'Clique aqui para explorar avaliações de impacto',
      image: evidencePreview,
      link: '/pt/evidence',
    },
  ],

  video_title: 'A Jornada à Frente',
  video_imbed: 'Video Link',
  video_sub: 'Video',
  video_desc: 'Parceria do Grupo Banco Mundial com o Brasil',

  strategy_intro: 'Explore as nossas',
  strategy_large: 'Áreas de Foco',

  page_button: 'Saiba mais',

  benchmarking_intro: 'Comparativo Internacional ',
  benchmarking_large: 'Brazil',
  benchmarking_desc: 'Explore how Brazil compares globally.',
  

  story_large: 'Estratégia de Parceria',
  story_desc: 'Brasil 2018-2023',

  downloads_title: 'Downloads',
  filename_1: 'Relatório da Estratégia de Parceria com o País (em inglês)',
  filesize_1: '(2.546 MB)',
  filelink_1:
    'http://documents.worldbank.org/curated/en/148141498229092629/pdf/20170619-Brazil-CPF-draft-for-Board-with-CLR-Acknowledgement-Box-06202017.pdf',
  filename_2: 'Relatório da Estratégia de Parceria com o País (em inglês)',
  filesize_2: '(300 MB)',
  filename_3: 'Diagnóstico Sistemático de País (em inglês)',
  filesize_3: '(4.859 MB)',
  filelink_3:
    'http://documents.worldbank.org/curated/en/180351467995438283/pdf/101431-REVISED-SCD-Brazil-SCD-Final-version-May-6-2016.pdf',
  filename_4: 'Covid-19 no Brasil: Impactos e Respostas de Políticas Públicas',
  filesize_4: '(4.1 MB)',
  filelink_4:
    'http://documents1.worldbank.org/curated/en/106541594362022984/pdf/COVID-19-in-Brazil-Impacts-and-Policy-Responses.pdf',

  tutorial:
    'Clique aqui para assistir a um tutorial em vídeo sobre o Brasil Aberto',
    report_band_sub_title:'Saiba mais sobre as pesquisas e estudos analíticos do Grupo Banco Mundial para o Brasil.',
    project_band_subtitle:'Conheça mais sobre os projetos do Grupo Banco Mundial no Brasil',
    portifolio_reach_block:'Esta página mapeia os projetos ativos no portfólio do Grupo Banco Mundial (GBM), exibindo dados de desembolso atrelados a referências geográficas.',
    about_portfolio_band:'Saiba mais sobre o portfolio do Grupo Banco Mundial por área de foco e estado',
    focus_area_header:'Áreas de Foco ',
    telling_the_story:'Contando a História'

};
