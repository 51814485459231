import React, { useState, useEffect } from 'react';
import { withRouter } from "react-router";
import NavbarPLR from '../common/navbar/NavbarPLR';
import { WelcomeSearchContent } from './WelcomeSearchContent';
import axios from 'axios';
import { API_URL } from '../../../config/api';

const SearchProjectPage = (props) => {
  const [searchInput, setSearchInput] = useState('');
  const [projects, setProjects] = useState([]);

  const fetchProjects = async () => {
    const ret = await axios
      .get(`${API_URL}/projects`);

    setProjects(ret.data);
  }

  const handleLogout = () => {
    localStorage.removeItem('auth');
    props.history.push('/');
  };

  useEffect(() => {
    fetchProjects();
  }, []);

  return (
    <div className="container-fluid unpadify">
      <NavbarPLR
        handleLogin={handleLogout}
        handleLogout={handleLogout}
        auth={props.auth}
        isLoggedIn={props.loggedIn}
        checkIsLoggedIn={props.checkIsLoggedIn}
        onChangeLoginState={props.onChangeLoginState}
        searchInput={searchInput}
        setSearchInput={setSearchInput}
        shouldRedirectSearch={false}
        {...props}
      />
      <WelcomeSearchContent searchText={searchInput} projects={projects} pt={props.pt} />
    </div>
  );
}

export default withRouter(SearchProjectPage);