import React, {useEffect, useState} from "react";
import TitleSection from "../TitleSection";
import Button from "../Button";
import Modal from "../Modal";
import AltButton from "../AltButton";
import "./styles.css";

const textEnglish = {
    title: 'Atention!',
    description: 'Are you sure you want to leave?',
    cancel: 'Cancel',
    leave: 'Leave'
}

const textPortuguese = {
    title: 'Atenção!',
    description: 'Tem certeza que deseja sair?',
    cancel: 'Cancelar',
    leave: 'Sair',
}

const LogoutModal = (props) => {
    const [text, setText] = useState(null);

    const onLogout = () => {
        props.onLogout();
    }

    useEffect(() => {

        if (props.location.pathname.substring(0, 4) === '/pt/') {
            setText(textPortuguese);
        } else {
            setText(textEnglish);
        }
    }, []);

    return (
        <>
            {props.showModal && (
                <Modal modalVisibility={() => props.showModal} contentClassNames="logout-modal">
                    <TitleSection titleStyle={{ fontSize: '28px' }} onClose={props.closeModal} title={text?.title ?? ''} />
                    <p className="description" style={{ justifyContent: 'flex-start', paddingLeft: '10px' }}>{text?.description ?? ''}</p>
                    <div className="ButtonsWrapper">
                        <AltButton
                            inverse={true}
                            onClick={props.closeModal}
                        >{text?.cancel ?? ''}</AltButton>
                        <Button
                            type="danger"
                            onClick={onLogout}
                            style={{ opacity: 1 }}
                        >{text?.leave ?? ''}</Button>
                    </div>
                </Modal>
            )}
        </>
    );
}

export default LogoutModal;
