import React, {useEffect, useState} from "react";
import TitleSection from "../TitleSection";
import Button from "../Button";
import Modal from "../Modal";
import AltButton from "../AltButton";
import "./styles.css";

const textEnglish = {
    title: 'Atention!',
    description: 'Are you sure you want to delete?',
    cancel: 'Cancel',
    delete: 'delete'
}

const textPortuguese = {
    title: 'Atenção!',
    description: 'Tem certeza que deseja excluir?',
    cancel: 'Cancelar',
    delete: 'Excluir',
}

const ConfirmDeleteModal = (props) => {
    const [text, setText] = useState(null);

    const onDelete = () => {
        props.onDelete();
    }

    useEffect(() => {
        if (props.location.pathname.substring(0, 4) === '/pt/') {
            setText(textPortuguese);
        } else {
            setText(textEnglish);
        }
    }, []);

    return (
        <>
            {props.showModal && (
                <Modal modalVisibility={() => props.showModal} contentClassNames="confirm-delete-modal">
                    <TitleSection titleStyle={{ fontSize: '28px' }} onClose={props.closeModal} title={text?.title ?? ''} />
                    <p className="description" style={{ justifyContent: 'flex-start', paddingLeft: '10px' }}>{text?.description ?? ''}</p>
                    <div className="ButtonsWrapper">
                        <AltButton
                            inverse={true}
                            onClick={props.closeModal}
                        >{text?.cancel ?? ''}</AltButton>
                        <Button
                            type="danger"
                            onClick={onDelete}
                            style={{ opacity: 1 }}
                        >{text?.delete ?? ''}</Button>
                    </div>
                </Modal>
            )}
        </>
    );
}

export default ConfirmDeleteModal;
