export default function  abbreviateFromMillions(number, maxPlaces, forcePlaces, forceLetter) {
    number = Number(number);
    forceLetter = forceLetter || false;
    var abbr;
    if(number >= 1e12) {
        abbr = 'T';
    }
    else if(number >= 1e3) {
        abbr = 'B';
    }
    else if(number >= 1e0) {
        abbr = 'M';
    }
    else {
        abbr = '';
    }
    return annotate(number, maxPlaces, forcePlaces, abbr);
}

function annotate(number, maxPlaces, forcePlaces, abbr) {
    // set places to false to not round
    var rounded = 0;
    switch(abbr) {
    case 'T':
        rounded = number / 1e12;
        break;
    case 'B':
        rounded = number / 1e3;
        break;
    case 'M':
        rounded = number / 1e0;
        break;
    case 'K':
        rounded = number / 1e3;
        break;
    case '':
        rounded = number;
        break;
    }
    if (maxPlaces !== false) {
        var test = new RegExp('\\.\\d{' + (maxPlaces + 1) + ',}$');
        if(test.test(('' + rounded))) {
            rounded = rounded.toFixed(maxPlaces);
        }
    }
    if(forcePlaces !== false) {
        rounded = Number(rounded).toFixed(forcePlaces);
    }
    return rounded + abbr;
}



